import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'question-types',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './question-types.component.html',
  styleUrl: './question-types.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionTypesComponent {
  @Input({ required: true }) itemForm!: FormGroup;

  public answerQuestionTypes = [
    {
      id: 'question-number',
      title: 'Number',
    },
    {
      id: 'question-bool',
      title: 'Toggle',
    },
    {
      id: 'question-string',
      title: 'Text',
    },
    {
      id: 'question-date',
      title: 'Date',
    },
    {
      id: 'question-time',
      title: 'Time',
    },
  ];

  public currentAnswerQuestionType: string | undefined;

  /**
   * Set Answer Question Type
   */
  setAnswerQuestionType(id: string) {
    this.currentAnswerQuestionType = id;
  }
}
