import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { RoleEnhanced } from "src/app/models/role/role.model";

@Component({
  selector: "manage-roles-list-custom",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./manage-roles-list-custom.component.html",
  styleUrl: "./manage-roles-list-custom.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRolesListCustomComponent {
  @Input({ required: true }) roles!: RoleEnhanced[];
  @Input({ required: true }) workspaceId!: string;
}
