import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostListener, inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { User } from "cip";
import { filter, map, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";

@Component({
  selector: "account-profile",
  standalone: true,
  imports: [FormStateComponent, NgClass, ReactiveFormsModule, InfoViewComponent, AsyncPipe, LoadingSpinnerComponent],
  templateUrl: "./account-profile.component.html",
  styleUrl: "./account-profile.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountProfileComponent implements OnInit {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  // Services
  private profileService = inject(ProfileService);
  private authService = inject(AuthService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private formFormattingService = inject(FormFormattingService);
  private fb = inject(FormBuilder);

  // Properties
  public userProfile$!: Observable<User>;
  public userProfileForm: FormGroup;
  public infoViewProfileEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.ProfileEditor);
  private user: User;

  constructor() {
    this.user = this.authService.currentUser;
    this.userProfileForm = this.fb.group({
      user_id: null,
      name_first: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      name_last: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      job_title: ["", this.formFormattingService.noWhitespaceValidator()],
      company: ["", this.formFormattingService.noWhitespaceValidator()],
      phone: ["", this.formFormattingService.noWhitespaceValidator()],
      email: null,
    });
  }

  ngOnInit() {
    this.getUserProfileDoc();
  }

  /**
   * Get User Profile Document
   */
  private async getUserProfileDoc(): Promise<void> {
    try {
      this.userProfile$ = this.profileService.getUserProfile$(this.user.user_id).pipe(
        filter((data) => !!data),
        map((data) => {
          if (this.userProfileForm.pristine && this.userProfileForm.untouched) {
            this.userProfileForm.patchValue(data);
          }
          return data;
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save User Profile
   */
  async saveUserProfile() {
    try {
      await this.profileService.saveUserProfile(this.userProfileForm.getRawValue(), this.user);
      this.userProfileForm.markAsPristine();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Is Form Dirty
   * @returns
   */
  isFormDirty() {
    return this.userProfileForm.dirty ? false : true;
  }
}
