@if (categories) {
  <div class="mx-auto flex w-full max-w-7xl items-center justify-between px-4 pb-10 sm:px-6 lg:px-8">
    <div class="flex flex-row items-center space-x-4">
      <!-- Previous Category -->
      @if (currentCategoryIndex !== 0) {
        <div (click)="previous()" class="cip-transition flex cursor-pointer flex-row items-center justify-start text-sm font-medium text-brand_Primary hover:text-brand_Primary-hover dark:text-sky-500 dark:hover:text-sky-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <div class="ml-1">Previous Category</div>
        </div>
      }

      <!-- Category Selector -->
      <div class="relative w-[400px]">
        <button
          (click)="toggleCategoriesListDropDown()"
          type="button"
          class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-brand_Primary focus:outline-none focus:ring-1 focus:ring-brand_Primary sm:text-sm"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label">
          <div class="flex flex-row items-center">
            <div class="mr-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-sm">{{ currentCategoryIndex + 1 }}</div>
            <span class="block truncate font-normal"> {{ currentCategory.title }} </span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2">
            @if (!categoriesListDropDown) {
              <svg class="pointer-events-none h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            }
            @if (categoriesListDropDown) {
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            }
          </span>
        </button>

        @if (categoriesListDropDown) {
          <ul
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3">
            @for (category of categories; track $index; let i = $index) {
              <li class="cip-transition relative cursor-pointer select-none px-4 py-2 text-gray-900 hover:bg-gray-50" role="option" (click)="navigateCategoryItem(category.id)">
                <div class="flex flex-row items-center">
                  <div class="mr-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-sm">{{ i + 1 }}</div>
                  <span class="block truncate font-normal"> {{ category.title }} </span>
                </div>
                <span class="absolute inset-y-0 right-0 flex items-center pr-3.5 text-brand_Primary">
                  @if (category.id === categoryId) {
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                  }
                </span>
              </li>
            }
          </ul>
        }
      </div>
    </div>

    <!-- Next Category -->

    @if (categories.length !== currentCategoryIndex + 1) {
      <div (click)="next()" class="cip-transition flex cursor-pointer flex-row items-center justify-end text-sm font-medium text-brand_Primary hover:text-brand_Primary-hover dark:text-sky-500 dark:hover:text-sky-500">
        <div class="mr-1">Next Category</div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    }
  </div>
}
