import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ThemeStyleType } from "cip";
import { ThemeStyleEnhanced } from "src/app/models/theme/theme-style.model";
import { ManageThemeEditSettingsThemeStyleActionComponent } from "../styles/manage-theme-edit-settings-theme-style-action/manage-theme-edit-settings-theme-style-action.component";
import { ManageThemeEditSettingsThemeStyleCategoryComponent } from "../styles/manage-theme-edit-settings-theme-style-category/manage-theme-edit-settings-theme-style-category.component";
import { ManageThemeEditSettingsThemeStyleChartComponent } from "../styles/manage-theme-edit-settings-theme-style-chart/manage-theme-edit-settings-theme-style-chart.component";
import { ManageThemeEditSettingsThemeStyleCoverComponent } from "../styles/manage-theme-edit-settings-theme-style-cover/manage-theme-edit-settings-theme-style-cover.component";
import { ManageThemeEditSettingsThemeStyleItemComponent } from "../styles/manage-theme-edit-settings-theme-style-item/manage-theme-edit-settings-theme-style-item.component";
import { ManageThemeEditSettingsThemeStylePhotoComponent } from "../styles/manage-theme-edit-settings-theme-style-photo/manage-theme-edit-settings-theme-style-photo.component";
import { ManageThemeEditSettingsThemeStyleSignatureComponent } from "../styles/manage-theme-edit-settings-theme-style-signature/manage-theme-edit-settings-theme-style-signature.component";
import { ManageThemeEditSettingsThemeStyleSiteComponent } from "../styles/manage-theme-edit-settings-theme-style-site/manage-theme-edit-settings-theme-style-site.component";
import { ManageThemeEditSettingsThemeStyleSupplementaryComponent } from "../styles/manage-theme-edit-settings-theme-style-supplementary/manage-theme-edit-settings-theme-style-supplementary.component";

@Component({
  selector: "manage-theme-edit-settings-customisations",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ManageThemeEditSettingsThemeStyleCoverComponent,
    ManageThemeEditSettingsThemeStyleSiteComponent,
    ManageThemeEditSettingsThemeStyleChartComponent,
    ManageThemeEditSettingsThemeStyleCategoryComponent,
    ManageThemeEditSettingsThemeStyleActionComponent,
    ManageThemeEditSettingsThemeStyleItemComponent,
    ManageThemeEditSettingsThemeStylePhotoComponent,
    ManageThemeEditSettingsThemeStyleSignatureComponent,
    ManageThemeEditSettingsThemeStyleSupplementaryComponent,
  ],
  templateUrl: "./manage-theme-edit-settings-customisations.component.html",
  styleUrl: "./manage-theme-edit-settings-customisations.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsCustomisationsComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  @Input() themeStylesByType: { [key in ThemeStyleType]?: ThemeStyleEnhanced[] } = {};
  public toggleContent = false;

  public toggleContentOptions(): void {
    this.toggleContent = !this.toggleContent;
  }
}
