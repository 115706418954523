import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "manage-theme-edit-settings-toggle-other-options",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./manage-theme-edit-settings-toggle-other-options.component.html",
  styleUrl: "./manage-theme-edit-settings-toggle-other-options.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsToggleOtherOptionsComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  public toggleContent: boolean = false;
  public toggleContentOptions(): void {
    this.toggleContent = !this.toggleContent;
  }
}
