import { inject, Injectable } from "@angular/core";
import { Site, User, WriteEvent, WriteEventType } from "cip";
import { deleteField, WithFieldValue } from "firebase/firestore";
import { catchError, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class SiteDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Site Doc
   * @param workspaceId
   * @param siteId
   */

  public getSiteDoc$(workspaceId: string, siteId: string): Observable<SiteEnhanced> {
    if (siteId === "new") {
      return of({} as SiteEnhanced);
    }
    const path = `${this.collectionsService.sitesCol(workspaceId)}/${siteId}`;
    return this.firestoreUtilsService.getDocumentData<SiteEnhanced>(path).pipe(
      map((data) => data || ({} as SiteEnhanced)),
      catchError((error) => {
        return of({} as SiteEnhanced);
      })
    );
  }

  /**
   * Set Site Doc
   * @param workspaceId
   * @param siteId
   * @param siteForm
   * @param writeEventType
   * @param user
   */
  async setSiteDoc(workspaceId: string, siteId: string, siteForm: SiteEnhanced, writeEventType: WriteEventType, user: User) {
    const path = this.collectionsService.sitesCol(workspaceId);
    const doc = `${path}/${siteId}`;
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const siteObj = this.createSiteObject(siteForm, lastEvent, user);
    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Site>(doc, siteObj);
    return { ...siteObj, id: siteId };
  }

  /**
   * Delete Site
   * @param workspaceId
   * @param siteId
   * @param user
   */
  public async deleteSiteDoc(workspaceId: string, siteId: string, user: User): Promise<void> {
    const path = this.collectionsService.sitesCol(workspaceId);
    const doc = `${path}/${siteId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const obj = { is_deleted: true, last_event: lastEvent };
    return this.firestoreUtilsService.setDocumentData(doc, obj);
  }

  /**
   * Delete Batched Sites
   * @param workspaceId
   * @param batchedSites
   */
  async deleteBatchedSites(workspaceId: string, batchedSites: SiteEnhanced[], user: User): Promise<void> {
    const path = this.collectionsService.sitesCol(workspaceId);
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const operations: BatchOperation[] = [];

    batchedSites.forEach((site) => {
      const doc = `${path}/${site.id}`;
      operations.push({
        type: "update",
        documentPath: doc,
        data: { last_event: lastEvent, is_deleted: true },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Create Site Object
   * @param siteForm
   * @param lastEvent
   * @param user
   */
  private createSiteObject(siteForm: SiteEnhanced, lastEvent: WithFieldValue<WriteEvent>, user: User): WithFieldValue<Site> {
    return {
      title: siteForm.title.trim(),
      ref: siteForm.ref ? siteForm.ref.trim() : deleteField(),
      address: siteForm.address ? siteForm.address.trim() : deleteField(),
      map_url: siteForm.map_url ? siteForm.map_url : deleteField(),
      what_3_words: siteForm.what_3_words ? siteForm.what_3_words : deleteField(),
      latitude: siteForm.latitude ? siteForm.latitude : deleteField(),
      longitude: siteForm.longitude ? siteForm.longitude : deleteField(),
      image_url: siteForm.image_url ? siteForm.image_url : deleteField(),
      image_thumbnail_url: siteForm.image_thumbnail_url ? siteForm.image_thumbnail_url : deleteField(),
      created_by_id: siteForm.created_by_id ? siteForm.created_by_id : user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };
  }
}
