import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { RolePermissions, User } from "cip";
import { BehaviorSubject, filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { ActionEnhanced } from "src/app/models/action/action.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ActionDetailService } from "../../services/action-detail.service";
import { ActionsListService } from "../../services/actions-list.service";
import { ActionsListCompleteIncompleteComponent } from "../actions-list-complete-incomplete/actions-list-complete-incomplete.component";
import { ActionsListComponent } from "../actions-list/actions-list.component";

@Component({
  selector: "actions-list-wrapper",
  standalone: true,
  imports: [JsonPipe, AsyncPipe, LoadingSpinnerComponent, RouterModule, NgClass, EmptyListComponent, ActionsListComponent, DeleteOverlayComponent, ActionsListCompleteIncompleteComponent],
  templateUrl: "./actions-list-wrapper.component.html",
  styleUrl: "./actions-list-wrapper.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsListWrapperComponent {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  @Input({ required: true })
  set itemId(value: string) {
    this._itemId = value;
    // this.itemIdSubject.next(value);
    this.getActionsList(value);
  }
  get itemId(): string {
    return this._itemId;
  }

  // Services
  private actionsListService = inject(ActionsListService);
  private actionDetailService = inject(ActionDetailService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);
  private authService = inject(AuthService);

  // Properties
  public _workspaceId!: string;
  public _inspectionId!: string;
  public _itemId!: string;
  public actions$!: Observable<ActionEnhanced[]>;
  public editMode: boolean = false;
  public priorityDict = this.actionsListService.priorityDict;
  public actionsBulkEditArray$: BehaviorSubject<ActionEnhanced[]> = new BehaviorSubject<ActionEnhanced[]>([]);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  private user: User;
  private allActions: ActionEnhanced[] = [];
  public overlay_deleteAction$ = new BehaviorSubject<boolean>(false);
  public overlay_completeIncompleteAction$ = new BehaviorSubject<boolean>(false);
  public completeOverlayTitle: string = "";
  public completeOverlayDescription: string = "";
  private singleActionToDeleteId: string = "";

  constructor() {
    this.user = this.authService.currentUser;
  }

  /**
   * Get Actions LIst
   * @param itemId
   */
  private async getActionsList(itemId: string) {
    this.actions$ = this.actionsListService.getItemActions$(this.workspaceId, itemId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.allActions = data;
      })
    );
  }

  /**
   * Route To Action
   * @param actionId
   */
  async routeToAction(actionId: string) {
    const featureAction: keyof RolePermissions = "action_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.editMode = false;
      this.router.navigate([{ outlets: { actionEditor: ["action", actionId] } }], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Create Batch Edit
   * @param action
   */
  public createBatchEdit(action: ActionEnhanced): void {
    const currentArray = this.actionsBulkEditArray$.getValue();
    if (currentArray.some((e: ActionEnhanced) => e.id === action.id)) {
      this.removeFromBatchArray(action);
    } else {
      this.addToBatchArray(action);
    }
  }

  /**
   * Add To Batch Array
   * @param category
   */
  private addToBatchArray(category: ActionEnhanced): void {
    const updatedArray = [...this.actionsBulkEditArray$.getValue(), category];
    this.actionsBulkEditArray$.next(updatedArray);
  }
  /**
   * Remove From Batch Array
   * @param category
   */
  private removeFromBatchArray(category: ActionEnhanced): void {
    const updatedArray = this.actionsBulkEditArray$.getValue().filter((e) => e.id !== category.id);
    this.actionsBulkEditArray$.next(updatedArray);
  }

  /**
   * Set Delete Batch Action Properties
   */
  async setBulkDeleteActionProperties(): Promise<void> {
    const featureAction: keyof RolePermissions = "action_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const isBulkEdit = this.actionsBulkEditArray$.value.length > 1;
      const alertType = isBulkEdit ? AlertType.DeleteActions : AlertType.DeleteAction;
      const deleteString = this.stringsService.alertFilter(alertType);
      if (deleteString) {
        this.deleteOverlayTitle = deleteString.title;
        this.deleteOverlayDescription = deleteString.description;
      } else {
        console.error("Unknown string type:", AlertType);
      }
      this.toggleDeleteOverlay(limitationResult);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Set Delete Single Action Properties
   * @param actionId
   */
  public async setDeleteSingleActionProperties(actionId: string): Promise<void> {
    const featureAction: keyof RolePermissions = "action_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const deleteAction = this.stringsService.alertFilter(AlertType.DeleteAction);
      if (deleteAction) {
        this.deleteOverlayTitle = deleteAction.title;
        this.deleteOverlayDescription = deleteAction.description;
      } else {
        console.error("Unknown string type:", AlertType);
      }
      this.singleActionToDeleteId = actionId;
      this.toggleDeleteOverlay(limitationResult);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Bulk Action Complete Overlay
   */
  async toggleBulkActionCompleteOverlay(): Promise<void> {
    const featureAction: keyof RolePermissions = "action_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const completeIncompleteActions = this.stringsService.alertFilter(AlertType.CompleteIncompleteActions);
      if (completeIncompleteActions) {
        this.completeOverlayTitle = completeIncompleteActions.title;
        this.completeOverlayDescription = completeIncompleteActions.description;
      } else {
        console.error("Unknown string type:", AlertType.CompleteIncompleteActions);
      }
      this.toggleCompleteActionOverlay(limitationResult);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Complete Incomplete Batched ACtions
   * @param complete
   */
  async completeIncompleteBatchedActions(complete: boolean): Promise<void> {
    try {
      await this.actionsListService.completeIncompleteActions(this.workspaceId, this.actionsBulkEditArray$.value, complete, this.user);
      this.checkActionsCountForEditMode();
      this.actionsBulkEditArray$.next([]);
      this.toggleCompleteActionOverlay(false);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Single Or Multiple Actions
   */
  public deleteSingleOrMultipleActions(): void {
    if (this.actionsBulkEditArray$.value.length > 1) {
      this.deleteBatchedActions();
    } else {
      this.deleteSingleAction();
    }
  }

  /**
   * Delete Single Action
   */
  public async deleteSingleAction(): Promise<void> {
    let actionToDelete: string = "";

    if (this.actionsBulkEditArray$.value.length === 1) {
      actionToDelete = this.actionsBulkEditArray$.value[0].id;
    } else {
      actionToDelete = this.singleActionToDeleteId;
    }

    try {
      await this.actionDetailService.deleteActionDoc(this.user, this.workspaceId, this.inspectionId, this.itemId, actionToDelete);
      this.toggleDeleteOverlay(false);
      this.checkActionsCountForEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Batched Actions
   */
  private async deleteBatchedActions(): Promise<void> {
    try {
      await this.actionsListService.deleteBatchedActions(this.allActions, this.actionsBulkEditArray$.value, this.user, this.workspaceId, this.inspectionId, this.itemId);
      this.toggleDeleteOverlay(false);
      this.checkActionsCountForEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Reorder Actions
   * @param event
   */
  public async reorderActions(event: CdkDragDrop<string[]>): Promise<void> {
    moveItemInArray(this.allActions, event.previousIndex, event.currentIndex);
    try {
      await this.actionsListService.reorderActions(this.allActions, this.workspaceId, this.user);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Check Categories Count For Edit Mode
   */
  private checkActionsCountForEditMode(): void {
    if (this.allActions.length === 0) {
      this.editMode = false;
      this.actionsBulkEditArray$.next([]);
    }
  }

  /**
   * Toggle Delete Overlay
   */
  public toggleDeleteOverlay(value: boolean): void {
    this.overlay_deleteAction$.next(value);
  }

  /**
   * Toggle Complete Action Overlay
   */
  public toggleCompleteActionOverlay(value: boolean): void {
    this.overlay_completeIncompleteAction$.next(value);
  }

  /**
   * Can User Edit
   */
  public async canUserEdit(): Promise<void> {
    const featureAction: keyof RolePermissions = "action_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleEditMode();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.actionsBulkEditArray$.next([]);
  }
}
