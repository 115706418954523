import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { LimitationManagerService } from 'src/app/core/services/limitation-manager.service';

@Component({
  selector: 'limitation-manager',
  standalone: true,
  imports: [],
  templateUrl: './limitation-manager.component.html',
  styleUrl: './limitation-manager.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LimitationManagerComponent {
  public limitationManagerService = inject(LimitationManagerService);
  @Output() closeLimitationOverlayEmitter = new EventEmitter<void>();

  closeLimitationOverlay() {
    this.closeLimitationOverlayEmitter.emit();
  }
}
