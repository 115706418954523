<div class="rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
  <!-- List Component -->
  <ul id="inspectionsList" role="list" class="list-ul">
    @for (inspection of filteredInspections; track $index) {
      <li [class.hidden]="inspection.hidden">
        <div class="list-row">
          <a [routerLink]="['/', 'workspace', workspaceId, 'folders', folderId, 'inspections', inspection.id, 'overview']" class="list-a group items-center">
            <!-- Left Icon -->
            <div class="ml-2.5 flex flex-row sm:ml-0">
              @if (inspection.site_photo_thumbnail_url) {
                <div class="flex h-12 w-12 rounded-md bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + inspection.site_photo_thumbnail_url + ')' }"></div>
              } @else {
                <div class="flex h-12 w-12 items-center justify-center rounded bg-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
                    <path
                      fill-rule="evenodd"
                      d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              }
            </div>

            <!-- Left and Right Content -->
            <div class="flex w-full flex-col gap-x-4 sm:flex-row">
              <!-- Left Content -->
              <div class="min-w-0 flex-auto">
                <p class="list-title">{{ inspection.title }}</p>
                @if (inspection.ref) {
                  <p class="list-meta search-ref">{{ inspection.ref }}</p>
                }
                @if (inspection.date) {
                  <p class="list-meta">{{ inspection.date.toDate() | date: 'dd/MM/yyyy' }} at {{ inspection.date.toDate() | date: 'HH:mm' }}</p>
                }
              </div>

              <!-- Right Content -->
              <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                <div class="sm:flex sm:flex-col sm:items-end">
                  @if (inspection.site_title) {
                    <p class="list-meta search-site-title">{{ inspection.site_title }}</p>
                  }
                  @if (inspection.client_name) {
                    <p class="list-meta search-client-name">{{ inspection.client_name }}</p>
                  }
                </div>
              </div>
            </div>
            <!-- Right Icon -->
            <div class="flex">
              <div class="list-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </div>
            </div>
          </a>
        </div>
      </li>
    }
  </ul>
</div>

@if (allInspectionsHidden) {
  <search-empty [searchTerm]="_searchTerm" />
}
