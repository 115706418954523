@if (reportsService.reportData.signatures) {
  <div class="signature-wrapper">
    <div class="header text-group-1">
      <p class="title" [ngStyle]="{ color: reportsService.themeDetails.value.colours_brand }">{{ reportsService.reportData.signatures.length > 1 ? "Signatures" : "Signature" }}</p>

      <div class="divider" [ngStyle]="{ 'background-color': reportsService.themeDetails.value.colours_brand }"></div>
    </div>

    <ul class="signature-list-wrapper">
      @for (signature of reportsService.reportData.signatures; track signature) {
        <li class="signature-item">
          <div class="signature-row-1">
            <div class="signature-data-wrapper">
              <p class="signature-data-title text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">Name:</p>
              <p class="signature-data text-group-5">{{ signature.name }}</p>
            </div>
            <div class="signature-data-wrapper">
              <p class="signature-data-title text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">Date:</p>
              @if (signature.date_signed) {
                <p class="signature-data text-group-5">
                  {{ signature.date_signed.toDate() | dateFormat: reportsService.themeDetails.value.dates_order : reportsService.themeDetails.value.dates_style }}
                </p>
              }
            </div>
          </div>
          <div class="signature-row-2">
            <p class="signature-data-title text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">Signature:</p>
            <img [src]="signature.image_thumbnail_url" class="signature-image" alt="Signature Image" />
          </div>
        </li>
      }
    </ul>
  </div>
}
