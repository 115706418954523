import { NgStyle } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ManageThemeEditPreviewItemComponent } from "../manage-theme-edit-preview-item/manage-theme-edit-preview-item.component";

@Component({
  selector: "manage-theme-edit-preview-category",
  standalone: true,
  imports: [ManageThemeEditPreviewItemComponent, NgStyle],
  templateUrl: "./manage-theme-edit-preview-category.component.html",
  styleUrl: "./manage-theme-edit-preview-category.component.scss",
})
export class ManageThemeEditPreviewCategoryComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
}
