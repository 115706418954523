import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TooltipComponent } from 'src/app/shared/tooltip/tooltip.component';

@Component({
  selector: 'inspection-category-edit-details',
  standalone: true,
  imports: [ReactiveFormsModule, TooltipComponent],
  templateUrl: './inspection-category-edit-details.component.html',
  styleUrl: './inspection-category-edit-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionCategoryEditDetailsComponent {
  @Input({ required: true }) categoryForm!: FormGroup;
  @Input({ required: true }) toolTipCategoryNotes!: string;
}
