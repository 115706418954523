import { Injectable, signal } from "@angular/core";
import axios from "axios";
import { Supplementary } from "cip";
import { getAuth } from "firebase/auth";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { assertNonNull } from "src/app/core/util/assert";
import { ActionEnhanced } from "src/app/models/action/action.model";
import { CategoryEnhanced } from "src/app/models/category/category.model";
import { ChartsActionResults } from "src/app/models/charts/charts-action.model";
import { ChartsAssigneeResults } from "src/app/models/charts/charts-assignees.model";
import { ChartsTestResults } from "src/app/models/charts/charts-tests.model";
import { ClientEnhanced } from "src/app/models/client/client.model";
import { InspectionEnhanced } from "src/app/models/inspection/inspection.model";
import { SignatureEnhanced } from "src/app/models/inspection/signature.model";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { PhotoEnhanced } from "src/app/models/photo/photo.model";
import { ReportSettings } from "src/app/models/reports/report-settings.model";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { ThemeStyleEnhanced } from "src/app/models/theme/theme-style.model";
import { ThemeStyleModel } from "src/app/models/theme/theme.model";
import { WorkspaceEnhanced } from "src/app/models/workspace/workspace.model";
import { environment } from "src/environments/environment";

export interface ItemImages {
  image_url?: string;
  annotation_url?: string;
}

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  public themeDetails = new BehaviorSubject<ThemeStyleModel>({
    id: "",
    title: "",
    csv_images: true,
    footers_page_numbers: true,
    dates_order: 2,
    dates_style: 0,
    details_workspace: true,
    details_user: true,
    details_overall_score: true,
    details_action: true,
    details_client: true,
    details_site: true,
    details_site_photo: true,
    details_site_map: true,
    details_introduction: true,
    details_summary: true,
    details_signatures: true,
    charts_included: true,
    charts_score: true,
    charts_assignee: true,
    charts_action: true,
    photos_included: true,
    photos_timestamps: true,
    photos_size: 1,
    photos_squared: true,
    photos_quality: 100,
    styles_cover: "Cover1",
    styles_site: "Site1",
    styles_category: "Category1",
    styles_chart: "Chart1",
    styles_item: "Item1",
    styles_photo: "Photo1",
    styles_action: "Action1",
    styles_supplementary: "Supplementary1",
    styles_signature: "Signature1",
    fonts_size: 1,
    colours_brand: "#2d53a1",
    colours_subtitle: "#27323c",
    colours_content: "#61788d",
    created_by: "",
    created_by_id: "",
  });

  reportData: {
    workspace: WorkspaceEnhanced | undefined;
    inspection: InspectionEnhanced | undefined;
    client: ClientEnhanced | undefined;
    site: SiteEnhanced | undefined;
    categories: CategoryEnhanced[];
    items: ItemEnhanced[];
    actions: ActionEnhanced[];
    actionAssignees: ActionEnhanced[];
    photos: PhotoEnhanced[];
    signatures: SignatureEnhanced[];
    introduction: Supplementary | undefined;
    summary: Supplementary | undefined;
    chartsActions: ChartsActionResults[];
    chartsTests: ChartsTestResults[];
    chartsAssignees: ChartsAssigneeResults[];
  } = {
    workspace: undefined,
    inspection: undefined,
    client: undefined,
    site: undefined,
    categories: [],
    items: [],
    actions: [],
    actionAssignees: [],
    photos: [],
    signatures: [],
    introduction: undefined,
    summary: undefined,
    chartsActions: [],
    chartsTests: [],
    chartsAssignees: [],
  };

  public reportSettings: ReportSettings = {
    pdf_or_csv: "PDF",
    frontCover_overallScore: true,
    frontCover_clientName: true,
    frontCover_siteName: true,
    misc_introduction: true,
    misc_summary: true,
    misc_client: true,
    misc_site: true,
    csv_images: true,
    show_actions: "All",
    priority_all: true,
    priority_none: true,
    priority_trivial: true,
    priority_low: true,
    priority_medium: true,
    priority_high: true,
    priority_critical: true,
    date_all: true,
    date_none: true,
    date_overdue: true,
    date_1_week: true,
    date_1_to_2_weeks: true,
    date_2_weeks_to_1_month: true,
    date_1_month_to_6_months: true,
    date_6_months_to_1_year: true,
    date_1_year_plus: true,
    assignee_all: true,
    assignee_ids: [],
    priorities: [],
  };
  private reportSettingsSubject = new BehaviorSubject<ReportSettings>(this.reportSettings);
  public reportSettings$ = this.reportSettingsSubject.asObservable();
  public actionsFiltered: ActionEnhanced[] = [];
  public overlay_csvConfirmation = false;
  public csvDownloadURL!: string;
  public isCSVBeingGenerated = false;
  public themeStyles: ThemeStyleEnhanced[] = [];
  // public reportDownloadURL: string | null;
  public reportDownloadURL = signal<string>("");
  public overlay_reportGeneration: boolean = false;

  constructor() {
    // this.reportDownloadURL = null;
  }

  public shouldDisplayAction(action: ActionEnhanced): boolean {
    const showActions = this.showActionsFilter(action);
    const showAssignees = this.showAssigneesFilter(action);
    const showPriorities = this.showPrioritiesFilter(action);
    const showDates = this.showDueDatesFilter(action);

    // Combine the results of the filters using logical AND
    // return showActions && showAssignees && showPriorities && showDates;
    // Combine the results of the filters using logical AND
    // const shouldDisplay = showActions && showAssignees && showPriorities && showDates;
    const shouldDisplay = showActions && showAssignees && showDates && showPriorities;

    if (!shouldDisplay) {
      // Remove the action from the filtered array if it should not be displayed
      this.actionsFiltered = this.actionsFiltered.filter((filteredAction) => filteredAction !== action);
    }

    return shouldDisplay;
  }

  /**
   * Show Actions Filter
   * Filters actions based on the "Show Actions" setting in reportSettings.
   * @param action - The action to evaluate.
   * @returns True if the action should be displayed; otherwise, false.
   */
  private showActionsFilter(action: ActionEnhanced): boolean {
    if (this.reportSettings.show_actions === "None") {
      return false;
    }

    if (this.reportSettings.show_actions === "Complete" && !action.complete) {
      return false;
    }

    if (this.reportSettings.show_actions === "Incomplete" && action.complete) {
      return false;
    }

    return true;
  }

  /**
   * Show Assignees Filter
   * Filters actions based on the assignee(s) selected in reportSettings.
   * @param action - The action to evaluate.
   * @returns True if the action should be displayed; otherwise, false.
   */
  private showAssigneesFilter(action: ActionEnhanced): boolean {
    // if (this.reportSettings.assignee_ids.length === 0) {
    //   // If no assignee filter is specified, show the action
    //   return true;
    // }

    if (action.assignee_id === undefined && this.reportSettings.assignee_ids.includes("no-assignee")) {
      // If the action has no assignee and 'no-assignee' is selected, show the action
      return true;
    }

    if (action.assignee_id !== undefined && this.reportSettings.assignee_ids.includes(action.assignee_id)) {
      // If the action's assignee is in the selected assignee IDs, show the action
      return true;
    }

    return false; // Default: do not show the action
  }

  /**
   * Show Priorities Filter
   * Filters actions based on priorities selected in reportSettings.
   * @param action - The action to evaluate.
   * @returns True if the action should be displayed; otherwise, false.
   */
  private showPrioritiesFilter(action: ActionEnhanced): boolean {
    // If no priorities are selected, show the action
    // if (this.reportSettings.priorities.length === 0) {
    //   return true;
    // }

    if (this.reportSettings.priorities.length === 0) {
      return false;
    }

    // If action.priority is undefined and 'priority_none' is selected, show the action
    if (action.priority === undefined && this.reportSettings.priority_none) {
      return true;
    }

    // If action.priority is in the selected priorities, show the action
    if (action.priority !== undefined && this.reportSettings.priorities.includes(action.priority)) {
      return true;
    }

    return false; // Default: do not show the action
  }

  /**
   * Show Due Dates Filter
   * Filters actions based on due dates and reportSettings.
   * @param action - The action to evaluate.
   * @returns True if the action should be displayed; otherwise, false.
   */
  private showDueDatesFilter(action: ActionEnhanced): boolean {
    const dueDate = action.due_date?.toDate();
    const today = new Date();

    // Check if action has no due date and 'date_none' setting is enabled
    if (action.due_date === undefined && this.reportSettings.date_none) {
      return true;
    }

    // Due Date Defined? Calculate Days Difference
    const daysDifference = dueDate ? (dueDate.getTime() - today.getTime()) / (1000 * 3600 * 24) : undefined;

    // Check various date range settings
    if (dueDate && daysDifference !== undefined) {
      if (dueDate < today && this.reportSettings.date_overdue) {
        return true;
      } else if (daysDifference >= 0 && daysDifference <= 7 && this.reportSettings.date_1_week) {
        return true;
      } else if (daysDifference > 7 && daysDifference <= 13 && this.reportSettings.date_1_to_2_weeks) {
        return true;
      } else if (daysDifference > 13 && daysDifference <= 30 && this.reportSettings.date_2_weeks_to_1_month) {
        return true;
      } else if (daysDifference > 30 && daysDifference <= 182 && this.reportSettings.date_1_month_to_6_months) {
        return true;
      } else if (daysDifference > 182 && daysDifference <= 365 && this.reportSettings.date_6_months_to_1_year) {
        return true;
      } else if (daysDifference > 365 && this.reportSettings.date_1_year_plus) {
        return true;
      }
    }

    return false;
  }

  public updateReportSettings(newSettings: ReportSettings): void {
    this.reportSettingsSubject.next(newSettings);
  }

  public async generateCSVWithImages(workspaceId: string): Promise<void> {
    // Set flag to indicate CSV generation is in progress
    this.isCSVBeingGenerated = true;

    // Extract table data from the HTML table
    const table = document.getElementById("csvTable") as HTMLTableElement;
    const rows = Array.from(table.querySelectorAll("tbody tr"));
    const csvData: string[][] = [];

    // Process each table row to create CSV data
    rows.forEach((row) => {
      const rowData = Array.from(row.querySelectorAll("td")).map((cell) => {
        // Replace line breaks with a placeholder
        const cellContent = cell.textContent ? cell.textContent.trim().replace(/\n/g, "||") : "";
        // Enclose cell content in double quotes to handle commas
        return `"${cellContent}"`;
      });
      csvData.push(rowData);
    });

    // Convert the data to a CSV string
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Replace the placeholder with line breaks
    const finalCSVContent = csvContent.replace(/"\|\|"/g, "\n");

    // Prepare image data for upload
    const itemImages: ItemImages[] = [];
    this.reportData.photos?.forEach((photo) => {
      itemImages.push({
        image_url: photo.image_url,
        annotation_url: photo.annotation_url,
      });
    });

    // Retrieve Firebase authentication token
    const auth = getAuth();
    const currentUser = auth.currentUser;
    assertNonNull(currentUser, `CSV reports can be generated for authenticated users only.`);
    const idToken = await currentUser.getIdToken(true);

    // Construct the URL for the cloud service endpoint
    const projectId = environment.firebase.projectId;
    const cloudServicesUrl = environment.cloudServicesBaseUrl + "/v1/csv_image_export/" + projectId;

    // Make a POST request to upload CSV and image data
    const { data: responseBody } = await axios.request({
      url: cloudServicesUrl,
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: {
        fileName: this.reportData.inspection?.title,
        imageAnnotations: itemImages,
        csvData: finalCSVContent,
        workspaceId: workspaceId,
      },
    });

    // Store the generated CSV download URL
    this.csvDownloadURL = responseBody.url;
  }

  public generateCSVWithoutImages(): void {
    const table = document.getElementById("csvTable") as HTMLTableElement;
    const rows = Array.from(table.querySelectorAll("tbody tr"));

    const csvData: string[][] = [];

    rows.forEach((row) => {
      const rowData = Array.from(row.querySelectorAll("td")).map((cell) => {
        // Replace line breaks with a placeholder
        const cellContent = cell.textContent ? cell.textContent.trim().replace(/\n/g, "||") : "";
        // Enclose cell content in double quotes to handle commas
        return `"${cellContent}"`;
      });
      csvData.push(rowData);
    });

    // Convert the data to a CSV string
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Replace the placeholder with line breaks
    const finalCSVContent = csvContent.replace(/"\|\|"/g, "\n");

    // Create a blob containing the CSV data
    const blob = new Blob([finalCSVContent], { type: "text/csv" });

    // Create a download link and trigger the download
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = `${this.reportData.inspection?.title}` + ".csv";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.overlay_csvConfirmation = false;
  }

  public toggleGenerationOverlay(): void {
    this.overlay_reportGeneration = !this.overlay_reportGeneration;
  }
}
