import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TemplateEnhanced } from "src/app/models/template/template.model";

@Component({
  selector: "manage-template-overview-header",
  standalone: true,
  imports: [RouterModule, DatePipe],
  templateUrl: "./manage-template-overview-header.component.html",
  styleUrl: "./manage-template-overview-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateOverviewHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) template!: TemplateEnhanced;
}
