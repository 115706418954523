<div [formGroup]="memberForm">
  <div class="grid grid-cols-1 gap-y-6">
    <!-- Name -->
    <div class="col-span-1 flex flex-row items-center space-x-4">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-[18px] w-[18px] flex-shrink-0 text-brand_Primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
      </svg>
      <div class="flex flex-col">
        <div class="form-input-label">Name</div>
        <div class="text-base text-text_Title">{{ memberForm.value.name_first }} {{ memberForm.value.name_last }}</div>
      </div>
    </div>

    <!-- Email -->
    <div class="col-span-1 flex flex-row items-center space-x-4">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-[18px] w-[18px] flex-shrink-0 text-brand_Primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
      </svg>
      <div class="flex flex-col">
        <div class="form-input-label">Email</div>
        <div class="break-all text-text_Title">{{ memberForm.value.email }}</div>
      </div>
    </div>

    <!-- Phone -->
    @if (memberForm.value.phone) {
      <div class="col-span-1 flex flex-row items-center space-x-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-[18px] w-[18px] flex-shrink-0 text-brand_Primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
        </svg>
        <div class="flex flex-col">
          <div class="form-input-label">Phone</div>
          <div class="break-all text-text_Title">{{ memberForm.value.phone }}</div>
        </div>
      </div>
    }

    <!-- Job -->
    @if (memberForm.value.job_title) {
      <div class="col-span-1 flex flex-row items-center space-x-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-[18px] w-[18px] flex-shrink-0 text-brand_Primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
        <div class="flex flex-col">
          <div class="form-input-label">Job Title</div>
          <div class="break-all text-text_Title">{{ memberForm.value.job_title }}</div>
        </div>
      </div>
    }

    <!-- Company -->
    @if (memberForm.value.company) {
      <div class="col-span-1 flex flex-row items-center space-x-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-[18px] w-[18px] flex-shrink-0 text-brand_Primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
        </svg>
        <div class="flex flex-col">
          <div class="form-input-label">Company</div>
          <div class="break-all text-text_Title">{{ memberForm.value.company }}</div>
        </div>
      </div>
    }
  </div>
</div>
