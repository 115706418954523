import { inject, Injectable } from '@angular/core';
import { Signature, User, WriteEvent, WriteEventType } from 'cip';
import { deleteField, serverTimestamp, WithFieldValue } from 'firebase/firestore';
import { catchError, Observable, of } from 'rxjs';
import { GetCountFromServerService } from 'src/app/core/services/counts/get-count-from-server.service';
import { CollectionsService } from '../../../core/services/collections/collections.service';
import { FirestoreUtilsService } from '../../../core/services/firestore/firestore-utils.service';
import { LastEventService } from '../../../core/services/last-event/last-event.service';
import { SignatureEnhanced } from '../../../models/inspection/signature.model';
import { BatchOperation } from '../../../models/utils/batch';

@Injectable({
  providedIn: 'root',
})
export class SignOffDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);
  private getCountFromServerService = inject(GetCountFromServerService);

  /**
   * Get Signature Do
   * @param workspaceId
   * @param inspectionId
   * @param signatureId
   */
  public getSignatureDoc$(workspaceId: string, inspectionId: string, signatureId: string): Observable<SignatureEnhanced | null> {
    const path = `${this.collectionsService.signaturesCol(workspaceId, inspectionId)}/${signatureId}`;
    return this.firestoreUtilsService.getDocumentData<SignatureEnhanced>(path).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Set New Signature Doc (new signature)
   * @param workspaceId
   * @param inspectionId
   * @param signatureId
   * @param signatureForm
   * @param user
   */
  async setNewSignatureDoc(workspaceId: string, inspectionId: string, signatureId: string, signatureForm: SignatureEnhanced, user: User) {
    const path = this.collectionsService.signaturesCol(workspaceId, inspectionId);
    const documentPath = `${path}/${signatureId}`;
    const lastEvent = this.lastEventService.lastEvent('added', user);
    const signatureCount = await this.getCountFromServerService.getCountFromServer(path);
    const signatureObj = {
      name: signatureForm.name.trim(),
      date_signed: serverTimestamp(),
      order: signatureCount,
      image_url: signatureForm.image_url === null ? deleteField() : signatureForm.image_url,
      image_thumbnail_url: signatureForm.image_thumbnail_url === null ? deleteField() : signatureForm.image_thumbnail_url,
      created_by_id: user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };

    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Signature>(documentPath, signatureObj);
    return { ...signatureObj, id: signatureId };
  }

  /**
   * Set Current Signature Doc (existing signatures)
   * @param workspaceId
   * @param inspectionId
   * @param signatureId
   * @param currentSignatureImage
   * @param signatureForm
   * @param user
   */
  async setCurrentSignatureDoc(workspaceId: string, inspectionId: string, signatureId: string, currentSignatureImage: string, signatureForm: SignatureEnhanced, user: User) {
    const path = this.collectionsService.signaturesCol(workspaceId, inspectionId);
    const documentPath = `${path}/${signatureId}`;
    const writeEventType: WriteEventType = signatureId === 'new' ? 'added' : 'changed';
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const signatureObj = await this.createNewSignatureObject(signatureForm, user, currentSignatureImage, lastEvent, path);
    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Signature>(documentPath, signatureObj);
    return { ...signatureObj, id: signatureId };
  }

  /**
   * Create New Signature Object
   * @param signatureForm
   * @param user
   * @param currentSignatureImage
   * @param lastEvent
   * @param path
   */
  private async createNewSignatureObject(signatureForm: SignatureEnhanced, user: User, currentSignatureImage: string, lastEvent: WithFieldValue<WriteEvent>, path: string): Promise<WithFieldValue<Signature>> {
    const { name, order, image_url, image_thumbnail_url } = signatureForm;
    const createdById = signatureForm.created_by_id ?? user.user_id;
    const signatureCount = await this.getCountFromServerService.getCountFromServer(path);

    let date_signed;
    if (!image_thumbnail_url) {
      date_signed = deleteField();
    } else if (!currentSignatureImage && image_thumbnail_url) {
      date_signed = serverTimestamp();
    } else if (currentSignatureImage && currentSignatureImage !== image_thumbnail_url) {
      date_signed = serverTimestamp();
    }

    const signature: WithFieldValue<Signature> = {
      name: name.trim(),
      date_signed: date_signed ?? serverTimestamp(),
      order: order === null ? signatureCount : order,
      image_url: image_url === null ? deleteField() : image_url,
      image_thumbnail_url: image_thumbnail_url === null ? deleteField() : image_thumbnail_url,
      created_by_id: createdById,
      is_deleted: false,
      last_event: lastEvent,
    };

    if (image_thumbnail_url === null) {
      signature.date_signed = deleteField();
    } else if (signatureForm.image_thumbnail_url !== image_thumbnail_url && image_thumbnail_url !== null) {
      signature.date_signed = serverTimestamp();
    }

    return signature;
  }

  /**
   * Delete Signature Doc
   * @param workspaceId
   * @param inspectionId
   * @param signatureId
   * @param user
   */
  async deleteSignatureDoc(workspaceId: string, inspectionId: string, signatures: SignatureEnhanced[], signatureId: string, user: User): Promise<void> {
    const path = this.collectionsService.signaturesCol(workspaceId, inspectionId);
    const doc = `${path}/${signatureId}`;
    const lastEvent = this.lastEventService.lastEvent('deleted', user);
    const obj = { is_deleted: true, last_event: lastEvent };
    const operations: BatchOperation[] = [
      {
        type: 'set',
        documentPath: doc,
        data: obj,
      },
    ];
    const remainingSignatures = signatures.filter((existingSignature) => existingSignature.id !== signatureId);
    remainingSignatures.forEach((remainingSignature, index) => {
      const updatePath = `${path}/${remainingSignature.id}`;
      operations.push({
        type: 'update',
        documentPath: updatePath,
        data: { order: index },
      });
    });
    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }
}
