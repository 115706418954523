<div class="w-full rounded-md bg-white px-12 py-8 dark:bg-slate-700">
  <h1 class="text_Title prose dark:prose-invert mb-6 text-4xl font-bold">Create Workspace</h1>
  <p class="prose dark:prose-invert pb-8 font-normal text-[#878787] dark:text-slate-300">Create your own Workspace</p>
  <button type="button" aria-label="Create Workspace" class="btn btn-primary px-6 py-2.5" (click)="toggleCreateWorkspace()">Create Workspace</button>
</div>

@if (overlay_newWorkspace) {
  <div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex min-h-screen items-center justify-center text-center">
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
      <div class="inline-block h-[600px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
        <div class="flex h-full flex-1 flex-col justify-between">
          <!-- Header Title & New -->
          <div class="flex flex-row items-center bg-white px-6 py-6">
            <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">New Workspace</h3>
          </div>

          <div class="flex h-full w-full flex-col overflow-hidden">
            <div class="w-full flex-1 overflow-y-scroll">
              <div class="grid grid-cols-1 gap-y-8 px-6 py-8">
                <!-- Details -->
                <div class="content-card-wrapper">
                  <div class="content-card-header flex items-center justify-between">
                    <h3>Details</h3>
                    <tooltip [toolTipText]="'These are the details that will appear on the Report.'" />
                  </div>
                  <div class="px-6 py-5">
                    <div class="content-grid gap-x-4 gap-y-6">
                      <!-- Workspace Form -->
                      <workspace-editor-details [workspaceForm]="workspaceForm" />

                      <!-- Workspace Image -->
                      <div>
                        <div class="form-input-label mb-2 px-3">Workspace Photo</div>
                        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div class="sm:col-span-6">
                            <!-- Image Dropzone -->
                            @if (!workspaceForm.value.image_thumbnail_url && uploadFileArray.length < 1) {
                              <photo-dropzone [uploadSourceComponent]="'workspace'" (detectPhotosOutput)="detectPhotos($event)" />
                            }
                            @if (photoPreview && uploadFileArray.length > 0) {
                              <photo-preview [photosToPreview]="uploadFileArray" [uploadLimit]="1" [uploadSourceComponent]="'workspace'" (removePhotoFromArrayOutput)="removePhotoFromArray($event)" />
                            }
                            @if (overlay_uploadPhotos) {
                              @for (photo of uploadFileArray; track photo.id) {
                                <photo-upload-single
                                  [file]="photo.photo"
                                  [path]="'workspaces/' + newWorkspaceId"
                                  [fileType]="'jpeg'"
                                  [largeMaxWidth]="1200"
                                  [largeMaxHeight]="1200"
                                  [thumbnailMaxWidth]="300"
                                  [thumbnailMaxHeight]="300"
                                  [uploadSourceComponent]="'workspace'"
                                  (photoDataOutput)="patchFormWithPhotosAndSaveDoc($event)"
                                />
                              }
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Form State Bottom -->
            <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
              <button
                type="button"
                class="btn ml-3"
                [disabled]="workspaceForm.pristine || workspaceForm.controls['name'].errors"
                [ngClass]="workspaceForm.pristine || workspaceForm.controls['name'].errors ? 'btn-disabled cursor-not-allowed' : 'btn-primary'"
                (click)="saveWorkspace()"
              >
                Save
              </button>
              <button type="button" class="btn btn-cancel" (click)="toggleCreateWorkspace()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
