import { JsonPipe, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FolderEnhanced } from 'src/app/models/folder/folder.model';
import { SearchEmptyComponent } from 'src/app/shared/search-empty/search-empty.component';

@Component({
  selector: 'folders-list',
  standalone: true,
  imports: [JsonPipe, RouterModule, NgStyle, SearchEmptyComponent],
  templateUrl: './folders-list.component.html',
  styleUrl: './folders-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldersListComponent implements OnInit {
  @Input({ required: true })
  set folders(value: FolderEnhanced[]) {
    this._folders = value;
    this.updateFilteredFoldersForSearchResults();
  }

  get folders(): FolderEnhanced[] {
    return this._folders;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredFoldersForSearchResults();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Output() editFolderOutput = new EventEmitter<FolderEnhanced>();

  private _folders: FolderEnhanced[] = [];
  public _searchTerm: string = '';
  public _workspaceId!: string;
  public filteredFolders: FolderEnhanced[] = [];
  public allFoldersHidden: boolean = false;

  ngOnInit(): void {
    this.updateFilteredFoldersForSearchResults();
  }

  /**
   * Update Filtered Folders
   */
  updateFilteredFoldersForSearchResults(): void {
    // Apply the search logic directly here.
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredFolders = this.folders.map((folder) => ({
      ...folder,
      hidden: !folder.title.toLowerCase().includes(lowerSearchTerm),
    }));

    // Compute whether all folders are hidden.
    this.allFoldersHidden = this.filteredFolders.every((folder) => folder.hidden);
  }

  /**
   * Edit Folder
   * @param folder
   */
  public editFolder(folder: FolderEnhanced): void {
    this.editFolderOutput.emit(folder);
  }
}
