<!-- question-time -->
@switch (item.answer_type) {
  @case ("question-time") {
    @if (item.question_time) {
      <div class="list-meta-second">
        <div>{{ item.question_time.toDate() | date: "mediumTime" }}</div>
      </div>
    }
  }

  <!-- question-date -->
  @case ("question-date") {
    @if (item.question_date) {
      <div class="list-meta-second">
        <div>{{ item.question_date.toDate() | date: "dd MMM yyyy" }}</div>
      </div>
    }
  }

  <!-- question-string -->
  @case ("question-string") {
    <div class="list-meta-second line-clamp-2">{{ item.question_string }}</div>
  }

  <!-- question-bool -->
  @case ("question-bool") {
    <div class="list-meta-second capitalize">{{ item.question_bool }}</div>
  }

  <!-- question-number -->
  @case ("question-number") {
    <div class="list-meta-second">{{ item.question_number }}</div>
  }
}
