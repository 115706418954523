import { inject, Injectable } from "@angular/core";
import { Template, User } from "cip";
import { deleteField, PartialWithFieldValue, serverTimestamp, WithFieldValue } from "firebase/firestore";
import { catchError, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { FolderEnhanced } from "src/app/models/folder/folder.model";
import { TemplateEnhanced } from "src/app/models/template/template.model";

@Injectable({
  providedIn: "root",
})
export class TemplateDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  // Properties
  // Create Inspection From Template Overlay
  public overlay_folderSelector = false;
  public overlay_newFolder = false;
  public overlay_duplicateConfirmation = true;
  public overlay_duplicate = false;
  public overlay_foldersList = false;
  public overlay_deleteTemplate = false;
  public folderTitle!: string;
  public folderId!: string;
  public currentlySelectedFolder: FolderEnhanced | null;
  public duplicationTitle!: string;
  public duplicationDescription!: string;
  public inProgressText!: string;
  public successText!: string;
  public failedText!: string;

  constructor() {
    this.currentlySelectedFolder = null;
  }

  /**
   * Get Template Doc
   * @param workspaceId
   * @param templateId
   */
  public getTemplateDoc$(workspaceId: string, templateId: string): Observable<TemplateEnhanced | null> {
    const path = this.collectionsService.templatesCol(workspaceId);
    const doc = `${path}/${templateId}`;
    return this.firestoreUtilsService.getDocumentData<TemplateEnhanced>(doc).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Set Template Doc
   * @param workspaceId
   * @param templateId
   * @param templateForm
   * @param user
   */
  async setTemplateDoc(workspaceId: string, templateId: string, templateForm: Template, user: User) {
    const path = this.collectionsService.templatesCol(workspaceId);
    const templateIdToUse = templateId === "new" ? this.firestoreUtilsService.createFirestoreId() : templateId;
    const doc = `${path}/${templateIdToUse}`;
    const writeEventType = templateId === "new" ? "added" : "changed";
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const templateObj: WithFieldValue<Template> = {
      title: templateForm.title.trim(),
      ref: templateForm.ref ? templateForm.ref.trim() : deleteField(),
      version: templateForm.version.trim(),
      date_created: templateForm.date_created ?? serverTimestamp(),
      date_updated: serverTimestamp(),
      created_by: templateForm.created_by ?? `${user.name_first} ${user.name_last}`,
      created_by_id: templateForm.created_by_id ?? user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };
    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Template>(doc, templateObj);
    return { ...templateObj, id: templateIdToUse };
  }

  /**
   * Delete Template Doc
   * @param workspaceId
   * @param templateId
   * @param user
   */
  async deleteTemplateDoc(workspaceId: string, templateId: string, user: User): Promise<void> {
    const path = this.collectionsService.templatesCol(workspaceId);
    const doc = `${path}/${templateId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const templateObj: PartialWithFieldValue<Template> = {
      is_deleted: true,
      last_event: lastEvent,
    };
    await this.firestoreUtilsService.setPartialDocumentData<Template>(doc, templateObj);
  }
}
