import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "loading-spinner",
  standalone: true,
  imports: [],
  templateUrl: "./loading-spinner.component.html",
  styleUrl: "./loading-spinner.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {}
