import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SupplementaryType } from 'cip';

@Component({
  selector: 'inspection-overview-contents',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './inspection-overview-contents.component.html',
  styleUrl: './inspection-overview-contents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionOverviewContentsComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) totalTests!: number;
  @Input({ required: true }) totalTestsWithAnswers!: number;
  @Input({ required: true }) percentageOfAnsweredTests!: number;
  @Output() navigateToSupplementary = new EventEmitter<SupplementaryType>();

  /**
   * Limitation Manager Check
   * @param supplementaryType
   */
  public limitationManagerCheck(supplementaryType: SupplementaryType): void {
    this.navigateToSupplementary.emit(supplementaryType);
  }
}
