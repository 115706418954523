import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { BehaviorSubject, filter, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { AssigneeEnhanced } from "src/app/models/assignee/assignee.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ManageAssigneeNewOverlayComponent } from "../../edit/manage-assignee-new-overlay/manage-assignee-new-overlay.component";
import { AssigneeDetailService } from "../../services/assignee-detail.service";
import { AssigneesListService } from "../../services/assignees-list.service";
import { ManageAssigneesListHeaderComponent } from "../manage-assignees-list-header/manage-assignees-list-header.component";
import { ManageAssigneesListComponent } from "../manage-assignees-list/manage-assignees-list.component";

@Component({
  selector: "manage-assignees-list-wrapper",
  standalone: true,
  imports: [ManageAssigneesListHeaderComponent, ManageAssigneesListComponent, EmptyListComponent, AsyncPipe, LoadingSpinnerComponent, DeleteOverlayComponent, ManageAssigneeNewOverlayComponent],
  templateUrl: "./manage-assignees-list-wrapper.component.html",
  styleUrl: "./manage-assignees-list-wrapper.component.scss",
})
export class ManageAssigneesListWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private assigneesListService = inject(AssigneesListService);
  private assigneeDetailService = inject(AssigneeDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);

  // Properties
  private _workspaceId!: string;
  private user: User;
  public assignees$!: Observable<AssigneeEnhanced[]>;
  public editMode: boolean = false;
  public currentSearchTerm: string = "";
  public assigneesBulkEditArray$: BehaviorSubject<AssigneeEnhanced[]> = new BehaviorSubject<AssigneeEnhanced[]>([]);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  public assigneeToBeDeleted: string = "";
  public overlay_deleteAssignee = signal<boolean>(false);
  public overlay_newAssignee: boolean = false;
  public assigneeForm: FormGroup;

  constructor() {
    this.user = this.authService.currentUser;
    this.assigneeForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      email: new FormControl("", [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), this.formFormattingService.noWhitespaceValidator()]),
      company: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getAssigneesList();
  }

  /**
   * Get Assignees List
   */
  private async getAssigneesList(): Promise<void> {
    this.assignees$ = this.assigneesListService.getAssigneesList$(this.workspaceId).pipe(filter((data) => !!data));
  }

  /**
   * Save Assignee
   */
  public async saveAssignee(): Promise<void> {
    try {
      await this.assigneeDetailService.saveAssigneeDoc(this.workspaceId, "new", this.assigneeForm.value, this.user);
      this.toggleNewAssigneeOverlay();
      this.assigneeForm.reset();
      this.assigneeForm.markAsPristine();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Update Search Term
   * @param term
   */
  public searchTermChange(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   * Set Delete Batch Assignees Properties
   */
  public async setDeleteBatchAssigneesProperties(): Promise<void> {
    const featureAction: keyof RolePermissions = "assignee_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      if (this.assigneesBulkEditArray$.getValue().length > 0) {
        const deleteAssignees = this.stringsService.alertFilter(AlertType.RemoveAssignees);
        if (deleteAssignees) {
          this.deleteOverlayTitle = deleteAssignees.title;
          this.deleteOverlayDescription = deleteAssignees.description;
          this.toggleDeleteAssigneeOverlay(true);
        }
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Limitation Manager Check
   */
  public async limitationManagerCheck(featureAction: keyof RolePermissions): Promise<boolean> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    return limitationResult;
  }

  /**
   * Set Delete Single Assignee Properties
   * @param assigneeId
   */
  public async setDeleteSingleAssigneeProperties(assigneeId: string): Promise<void> {
    const featureAction: keyof RolePermissions = "assignee_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      const deleteAssignee = this.stringsService.alertFilter(AlertType.RemoveAssignee);
      if (deleteAssignee) {
        this.deleteOverlayTitle = deleteAssignee.title;
        this.deleteOverlayDescription = deleteAssignee.description;
      } else {
        console.error("Unknown string type:", AlertType.RemoveAssignee);
      }
      this.assigneeToBeDeleted = assigneeId;
      this.toggleDeleteAssigneeOverlay(true);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Single Or Multiple Assignees
   */
  public deleteSingleOrMultipleAssignees(): void {
    if (this.assigneesBulkEditArray$.getValue().length > 0) {
      this.deleteBatchedAssignees();
    } else {
      this.deleteSingleAssignee();
    }
  }

  /**
   * Delete Single Assignee
   * @param assigneeId
   */
  public async deleteSingleAssignee(): Promise<void> {
    try {
      await this.assigneeDetailService.deleteAssigneeDoc(this.workspaceId, this.assigneeToBeDeleted, this.user);
      this.toggleDeleteAssigneeOverlay(false);
      this.toggleEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Batched Assignees
   */
  private async deleteBatchedAssignees(): Promise<void> {
    try {
      await this.assigneeDetailService.deleteBatchedAssignees(this.workspaceId, this.assigneesBulkEditArray$.value, this.user);
      this.toggleDeleteAssigneeOverlay(false);
      this.toggleEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Create Batch Edit
   * @param assignee
   */
  public createBatchEdit(assignee: AssigneeEnhanced): void {
    const currentArray = this.assigneesBulkEditArray$.getValue();
    if (currentArray.some((e: AssigneeEnhanced) => e.id === assignee.id)) {
      this.removeFromBatchArray(assignee);
    } else {
      this.addToBatchArray(assignee);
    }
  }

  /**
   * Add To Batch Array
   * @param assignee
   */
  private addToBatchArray(assignee: AssigneeEnhanced): void {
    const updatedArray = [...this.assigneesBulkEditArray$.getValue(), assignee];
    this.assigneesBulkEditArray$.next(updatedArray);
  }

  /**
   * Remove From Batch Array
   * @param assignee
   */
  private removeFromBatchArray(assignee: AssigneeEnhanced): void {
    const updatedArray = this.assigneesBulkEditArray$.getValue().filter((e) => e.id !== assignee.id);
    this.assigneesBulkEditArray$.next(updatedArray);
  }

  /**
   * Route To Assignee Editor
   */
  public async routeToAssigneeEditor(assigneeId: string): Promise<void> {
    const featureAction: keyof RolePermissions = "assignee_create_update";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "assignees", assigneeId]);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.assigneesBulkEditArray$.next([]);
  }

  /**
   * Toggle Delete Assignee Overlay
   * @param value
   */
  public toggleDeleteAssigneeOverlay(value: boolean): void {
    this.overlay_deleteAssignee.set(value);
  }

  /**
   * Can User Create Assignee
   */
  public async canUserCreateAssignee(): Promise<void> {
    const featureAction: keyof RolePermissions = "assignee_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleNewAssigneeOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle New Assignee Overlay
   */
  public async toggleNewAssigneeOverlay(): Promise<void> {
    this.overlay_newAssignee = !this.overlay_newAssignee;
  }
}
