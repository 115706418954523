// Generated by CoffeeScript 2.4.1
(function () {
  // Represents a list of nodes
  var XMLNodeList;
  module.exports = XMLNodeList = function () {
    class XMLNodeList {
      // Initializes a new instance of `XMLNodeList`
      // This is just a wrapper around an ordinary
      // JS array.

      // `nodes` the array containing nodes.
      constructor(nodes) {
        this.nodes = nodes;
      }

      // Creates and returns a deep clone of `this`

      clone() {
        // this class should not be cloned since it wraps
        // around a given array. The calling function should check
        // whether the wrapped array is null and supply a new array
        // (from the clone).
        return this.nodes = null;
      }

      // DOM Level 1
      item(index) {
        return this.nodes[index] || null;
      }
    }
    ;

    // DOM level 1
    Object.defineProperty(XMLNodeList.prototype, 'length', {
      get: function () {
        return this.nodes.length || 0;
      }
    });
    return XMLNodeList;
  }.call(this);
}).call(this);