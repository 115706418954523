import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ClientEnhanced } from "src/app/models/client/client.model";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";

@Component({
  selector: "manage-clients-list-header",
  standalone: true,
  imports: [NgClass, SearchBarComponent],
  templateUrl: "./manage-clients-list-header.component.html",
  styleUrl: "./manage-clients-list-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageClientsListHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) clientsCount!: number;
  @Input({ required: true }) editMode!: boolean;
  @Input() clientsBulkEditArray: ClientEnhanced[] | null = [];
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() toggleNewClientOverlayOutput = new EventEmitter<void>();
  @Output() toggleEditModeOutput = new EventEmitter<void>();
  @Output() bulkDeleteClientsOutput = new EventEmitter<void>();

  /**
   * On Search
   * @param searchTerm
   */
  public onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Bulk Delete Clients
   */
  public bulkDeleteClients(): void {
    this.bulkDeleteClientsOutput.emit();
  }

  /**
   * Toggle New Client Overlay
   */
  public toggleNewClientOverlay(): void {
    this.toggleNewClientOverlayOutput.emit();
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.toggleEditModeOutput.emit();
  }
}
