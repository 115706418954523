@if (folders$ | async; as folders) {
  @if (foldersListService.reorderedFolders$ | async; as reorderedFolders) {
    <folders-list-header
      [folders]="folders"
      [workspaceId]="workspaceId"
      [currentlySelectedFolderSortChoice]="foldersListService.currentlySelectedFolderSortChoice"
      [isSortOrderAscending]="foldersListService.isSortOrderAscending"
      [sortDropdown]="foldersListService.sortDropdown"
      (sortDropdownOutput)="toggleSortDropdown()"
      (newFolderOutput)="canUserAddFolder()"
      (filterChoiceOutput)="changeFolderSortChoice($event)"
      (searchTermChange)="updateSearchTerm($event)" />

    @if (folders.length > 0) {
      <folders-list [folders]="reorderedFolders" [workspaceId]="workspaceId" [searchTerm]="currentSearchTerm" (editFolderOutput)="updateEditFolderForm($event)" />
    } @else {
      <empty-list [list]="'folder'" />
    }
  } @else {
    <loading-spinner />
  }
  @if (overlay_editFolder) {
    <folder-edit-wrapper
      [folderForm]="folderForm"
      [isNewFolder]="isNewFolder"
      [limitationResult]="limitationResult"
      (toggleEditFolderOverlayOutput)="toggleEditFolderOverlay()"
      (saveFolderOutput)="saveFolder()"
      (deleteFolderOutput)="checkIfUserCanDelete()" />
  }
  @if (overlay_deleteFolder) {
    <delete-confirmation [deleteTitle]="deleteTitle" [deleteMessage]="folderForm.value.title + ' ' + deleteDescription" (cancelCommandEmitter)="toggleDeleteFolderOverlay()" (deleteCommandEmitter)="deleteFolder()" />
  }
}
