import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "manage-sites-list",
  standalone: true,
  imports: [SearchEmptyComponent, NgStyle],
  templateUrl: "./manage-sites-list.component.html",
  styleUrl: "./manage-sites-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageSitesListComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true })
  set sites(value: SiteEnhanced[]) {
    this._sites = value;
    this.updateFilteredSitesForSearchResults();
  }

  get sites(): SiteEnhanced[] {
    return this._sites;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredSitesForSearchResults();
  }

  @Input({ required: true }) editMode!: boolean;
  @Input() sitesBulkEditArray: SiteEnhanced[] | null = [];
  @Output() deleteSingleSiteOutput = new EventEmitter<string>();
  @Output() createBatchEditOutput = new EventEmitter<SiteEnhanced>();
  @Output() routeToSiteEditorOutput = new EventEmitter<string>();

  // Properties
  private _sites: SiteEnhanced[] = [];
  public _searchTerm: string = "";
  public filteredSites: SiteEnhanced[] = [];
  public allSitesHidden: boolean = false;

  updateFilteredSitesForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredSites = this.sites.map((site) => ({
      ...site,
      hidden: !site.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allSitesHidden = this.filteredSites.every((site) => site.hidden);
  }

  public createBatchEdit(site: SiteEnhanced): void {
    this.createBatchEditOutput.emit(site);
  }

  public deleteSingleSite(site: string): void {
    this.deleteSingleSiteOutput.emit(site);
  }

  public routeToSiteEditor(siteId: string): void {
    this.routeToSiteEditorOutput.emit(siteId);
  }
}
