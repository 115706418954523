<header>
  <div class="h-[28px] px-6 pb-2"></div>

  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Assignees</h2>
      </div>
      <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
        @if (!editMode) {
          <search-bar [placeholder]="'Search assignees'" (searchTermChange)="onSearch($event)" />
        }
        <div class="flex flex-shrink-0 flex-col sm:flex-row">
          @if (assigneesBulkEditArray && assigneesBulkEditArray.length > 0 && editMode) {
            <button type="button" (click)="bulkDeleteAssignees()" class="btn btn-secondary">Bulk Remove</button>
          }

          @if (assigneesCount > 0) {
            <button class="btn ml-3" (click)="toggleEditMode()" [ngClass]="editMode ? 'btn-primary' : 'btn-secondary'">
              {{ editMode ? "Done" : "Edit" }}
            </button>
          }
          @if (!editMode) {
            <button type="button" class="btn btn-primary ml-3" type="button" aria-label="New Assignee" (click)="toggleNewAssigneeOverlay()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Assignee
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</header>
