import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "manage-theme-edit-settings-fonts",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./manage-theme-edit-settings-fonts.component.html",
  styleUrl: "./manage-theme-edit-settings-fonts.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsFontsComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  public toggleContent = false;

  fontSizes: { value: number; name: string }[] = [
    { value: 0, name: "Extra Small" },
    { value: 1, name: "Small" },
    { value: 2, name: "Regular" },
    { value: 3, name: "Large" },
    { value: 4, name: "Extra Large" },
  ];

  public currentFontSize: number | undefined;

  public toggleContentOptions(): void {
    this.toggleContent = !this.toggleContent;
  }
}
