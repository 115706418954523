import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "manage-template-overview-actions",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./manage-template-overview-actions.component.html",
  styleUrl: "./manage-template-overview-actions.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateOverviewActionsComponent {
  @Input({ required: true }) categoriesCount!: number;
  @Input({ required: true }) workspaceId!: string;
  @Output() startInspectionFromTemplateOverlayOutput = new EventEmitter<void>();
  @Output() duplicateTemplateOverlayOutput = new EventEmitter<void>();
  @Output() toggleDeleteTemplateOverlayOutput = new EventEmitter();

  public startInspectionFromTemplateOverlay(): void {
    this.startInspectionFromTemplateOverlayOutput.emit();
  }
  public duplicateTemplateOverlay(): void {
    this.duplicateTemplateOverlayOutput.emit();
  }
  public toggleDeleteTemplateOverlay(): void {
    this.toggleDeleteTemplateOverlayOutput.emit();
  }
}
