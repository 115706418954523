import { AsyncPipe, NgClass } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { AlertType } from "src/app/models/strings/strings.model";
import { TemplateEnhanced } from "src/app/models/template/template.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { TemplateDetailService } from "../../services/template-detail.service";
import { ManageTemplateEditDetailsComponent } from "../manage-template-edit-details/manage-template-edit-details.component";
import { ManageTemplateEditHeaderComponent } from "../manage-template-edit-header/manage-template-edit-header.component";

@Component({
  selector: "manage-template-edit-wrapper",
  standalone: true,
  imports: [AsyncPipe, ManageTemplateEditHeaderComponent, ManageTemplateEditDetailsComponent, LoadingSpinnerComponent, FormStateComponent, NgClass, DeleteOverlayComponent],
  templateUrl: "./manage-template-edit-wrapper.component.html",
  styleUrl: "./manage-template-edit-wrapper.component.scss",
})
export class ManageTemplateEditWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set templateId(value: string) {
    this._templateId = value;
  }
  get templateId(): string {
    return this._templateId;
  }

  // Services
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private templateDetailService = inject(TemplateDetailService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);

  // Properties
  private _workspaceId!: string;
  private _templateId!: string;
  private user: User;
  public templateForm: FormGroup;
  public template$!: Observable<TemplateEnhanced>;
  public overlay_deleteTemplate: boolean = false;
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";

  constructor() {
    this.user = this.authService.currentUser;
    this.templateForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      ref: null,
      version: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      date_created: null,
      date_updated: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit() {
    this.getTemplateDoc();
  }

  /**
   * Get the template document
   */
  private async getTemplateDoc(): Promise<void> {
    try {
      this.template$ = this.templateDetailService.getTemplateDoc$(this.workspaceId, this.templateId).pipe(
        filter((data) => !!data),
        tap((data) => {
          if (this.templateForm.pristine && this.templateForm.untouched) {
            this.templateForm.patchValue(data);
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Template
   */
  public async saveTemplate(): Promise<void> {
    try {
      await this.templateDetailService.setTemplateDoc(this.workspaceId, this.templateId, this.templateForm.value, this.user);
      this.templateForm.markAsPristine();
      this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", this.templateId, "overview"]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Template
   */
  public async deleteTemplate(): Promise<void> {
    try {
      await this.templateDetailService.deleteTemplateDoc(this.workspaceId, this.templateId, this.user);
      this.router.navigate(["/workspace", this.workspaceId, "manage", "templates"]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Delete Template
   */
  public async canUserDeleteTemplate(): Promise<void> {
    const featureAction: keyof RolePermissions = "template_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleDeleteTemplateOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Delete Template overlay
   */
  public toggleDeleteTemplateOverlay(): void {
    if (!this.overlay_deleteTemplate) {
      const deleteTemplate = this.stringsService.alertFilter(AlertType.DeleteTemplate);
      if (deleteTemplate) {
        this.deleteOverlayTitle = deleteTemplate.title;
        this.deleteOverlayDescription = deleteTemplate.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteTemplate);
      }
    }
    this.overlay_deleteTemplate = !this.overlay_deleteTemplate;
  }
}
