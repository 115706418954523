import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";

@Component({
  selector: "manage-sites-list-header",
  standalone: true,
  imports: [NgClass, SearchBarComponent],
  templateUrl: "./manage-sites-list-header.component.html",
  styleUrl: "./manage-sites-list-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageSitesListHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) sitesCount!: number;
  @Input({ required: true }) editMode!: boolean;
  @Input() sitesBulkEditArray: SiteEnhanced[] | null = [];
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() routeToSiteEditorOutput = new EventEmitter<void>();
  @Output() toggleEditModeOutput = new EventEmitter<void>();
  @Output() bulkDeleteSitesOutput = new EventEmitter<void>();

  /**
   * On Search
   * @param searchTerm
   */
  public onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Bulk Delete Sites
   */
  public bulkDeleteSites(): void {
    this.bulkDeleteSitesOutput.emit();
  }

  /**
   * Route To Site Editor
   */
  public routeToSiteEditor(): void {
    this.routeToSiteEditorOutput.emit();
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.toggleEditModeOutput.emit();
  }
}
