<form [formGroup]="themeSettingsForm">
  <div class="border-b border-gray-200 py-6">
    <fieldset>
      <span class="mb-4 block text-sm font-medium text-gray-900">Category</span>
      <div class="space-y-4">
        @for (category of themeStylesByType["category"]; track $index; let i = $index) {
          <div class="flex items-center">
            <label class="flex cursor-pointer items-center" for="category{{ i }}">
              <input type="radio" class="h-4 w-4 border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="styles_category" [value]="category.id" id="category{{ i }}" (change)="updateCustomThemeStyles()" />
              <span class="ml-3 cursor-pointer text-sm text-text_Title">{{ category.title }}</span>
            </label>
          </div>
        }
      </div>
    </fieldset>
  </div>
</form>
