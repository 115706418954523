import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
  selector: "manage-template-category-edit-header",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./manage-template-category-edit-header.component.html",
  styleUrl: "./manage-template-category-edit-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateCategoryEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) categoryId!: string;
  @Input({ required: true }) templateId!: string;
  @Output() duplicateCategoryOutput = new EventEmitter<void>();

  /**
   * Duplicate Category
   */
  public duplicateCategory(): void {
    this.duplicateCategoryOutput.emit();
  }
}
