import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BreadcrumbsComponent } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'inspection-signoff-edit-header',
  standalone: true,
  imports: [BreadcrumbsComponent],
  templateUrl: './inspection-signoff-edit-header.component.html',
  styleUrl: './inspection-signoff-edit-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionSignoffEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) signatureId!: string;
}
