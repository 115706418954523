import { JsonPipe, NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ManageThemeEditPreviewCategoryComponent } from "../manage-theme-edit-preview-category/manage-theme-edit-preview-category.component";
import { ManageThemeEditPreviewChartsCoverComponent } from "../manage-theme-edit-preview-charts-cover/manage-theme-edit-preview-charts-cover.component";
import { ManageThemeEditPreviewFrontCoverComponent } from "../manage-theme-edit-preview-front-cover/manage-theme-edit-preview-front-cover.component";
import { ManageThemeEditPreviewSignatureComponent } from "../manage-theme-edit-preview-signature/manage-theme-edit-preview-signature.component";
import { ManageThemeEditPreviewSiteCoverComponent } from "../manage-theme-edit-preview-site-cover/manage-theme-edit-preview-site-cover.component";
import { ManageThemeEditPreviewSupplementaryComponent } from "../manage-theme-edit-preview-supplementary/manage-theme-edit-preview-supplementary.component";

@Component({
  selector: "manage-theme-edit-preview-wrapper",
  standalone: true,
  imports: [
    JsonPipe,
    NgClass,
    ManageThemeEditPreviewFrontCoverComponent,
    ManageThemeEditPreviewSiteCoverComponent,
    ManageThemeEditPreviewChartsCoverComponent,
    ManageThemeEditPreviewSupplementaryComponent,
    ManageThemeEditPreviewSignatureComponent,
    ManageThemeEditPreviewCategoryComponent,
  ],
  templateUrl: "./manage-theme-edit-preview-wrapper.component.html",
  styleUrl: "./manage-theme-edit-preview-wrapper.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditPreviewWrapperComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
}
