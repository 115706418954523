<div class="action-wrapper">
  <p class="action-header text-group-2">Actions</p>
  <ul>
    <li>
      <div class="action-li-wrapper">
        <div class="action-content">
          <div class="action-content-left">
            <p class="action-title text-group-3">1. Check number of people work</p>
            <div class="action-assignee-wrapper" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">
              <p class="action-assignee-name text-group-6">John Doe</p>

              <span>&#8226;</span>
              <p class="action-assignee-email text-group-6">{{ emailAddress }}</p>
            </div>
            <p class="action-date text-group-6" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">{{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}</p>
          </div>
          <div class="action-content-right">
            <div class="action-priority text-group-5 action-priority-{{ actionsListService.priorityDict[0].priority }}">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 9.5C8.10457 9.5 9 8.60457 9 7.5C9 6.39543 8.10457 5.5 7 5.5C5.89543 5.5 5 6.39543 5 7.5C5 8.60457 5.89543 9.5 7 9.5Z" />
                  <rect x="0.5" y="1" width="13" height="13" rx="6.5" />
                </svg>
                <p>{{ actionsListService.priorityDict[0].priority }}</p>
              </div>
            </div>
            <!-- <ng-template [ngIf]="action.complete; then complete; else incomplete"></ng-template> -->
            <div class="action-incomplete text-group-5">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fill="none">
                  <rect y="0.5" width="14" height="14" rx="7" fill="#B9C5CE" />
                  <path d="M7 3.5C7 3.5 7 6.433 7 9.16667M7 11.5V10.5V10.1667" stroke="#F4F7FA" />
                </svg>
                <p>Incomplete</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="action-li-wrapper complete">
        <div class="action-content">
          <div class="action-content-left">
            <p class="action-title text-group-3">2. Check number of people work</p>
            <div class="action-assignee-wrapper" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">
              <p class="action-assignee-name text-group-6">John Doe</p>
              <span>&#8226;</span>
              <p class="action-assignee-email text-group-6">{{ emailAddress }}</p>
            </div>
            <p class="action-date text-group-6" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">{{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}</p>
          </div>
          <div class="action-content-right">
            <div class="action-priority text-group-5 action-priority-{{ actionsListService.priorityDict[25].priority }}">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 9.5C8.10457 9.5 9 8.60457 9 7.5C9 6.39543 8.10457 5.5 7 5.5C5.89543 5.5 5 6.39543 5 7.5C5 8.60457 5.89543 9.5 7 9.5Z" />
                  <rect x="0.5" y="1" width="13" height="13" rx="6.5" />
                </svg>
                <p>{{ actionsListService.priorityDict[25].priority }}</p>
              </div>
            </div>
            <div class="action-complete text-group-5">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                  <rect y="0.5" width="14" height="14" rx="7" fill="white" />
                  <path d="M10 5.5L5.91489 9.5L4 7.62792" stroke="#439457" stroke-linecap="square" stroke-linejoin="round" />
                </svg>
                <p>Complete</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
