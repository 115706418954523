import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PhotoType } from 'src/app/models/photo/photo-type';
import { SinglePhotoUpload } from 'src/app/models/photo/photo-upload.model';
import { LoadingSpinnerComponent } from 'src/app/shared/loading-spinner/loading-spinner.component';
import { PhotoDropzoneComponent } from 'src/app/shared/photo-dropzone/photo-dropzone.component';
import { PhotoPreviewComponent } from 'src/app/shared/photo-preview/photo-preview.component';
import { PhotoUploadSingleComponent } from 'src/app/shared/photo-upload-single/photo-upload-single.component';
import { TooltipComponent } from 'src/app/shared/tooltip/tooltip.component';
import { WorkspaceEditorDetailsComponent } from '../workspace-editor-details/workspace-editor-details.component';

@Component({
  selector: 'create-workspace',
  standalone: true,
  imports: [TooltipComponent, LoadingSpinnerComponent, NgClass, WorkspaceEditorDetailsComponent, PhotoDropzoneComponent, PhotoPreviewComponent, PhotoUploadSingleComponent],
  templateUrl: './create-workspace.component.html',
  styleUrl: './create-workspace.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateWorkspaceComponent {
  @Input({ required: true }) workspaceForm!: FormGroup;
  @Input({ required: true }) overlay_newWorkspace: boolean = false;
  @Input({ required: true }) uploadFileArray: SinglePhotoUpload[] = [];
  @Input({ required: true }) overlay_uploadPhotos: boolean = false;
  @Input({ required: true }) photoPreview: boolean = false;
  @Input({ required: true }) newWorkspaceId!: string;
  @Output() toggleCreateWorkspaceOutput = new EventEmitter<boolean>();
  @Output() saveWorkspaceOutput = new EventEmitter<FormGroup>();
  @Output() detectPhotosOutput = new EventEmitter<FileList>();
  @Output() removePhotoFromArrayOutput = new EventEmitter<string>();
  @Output() returnedPhotoOutput = new EventEmitter<PhotoType>();

  /**
   * Save the workspace
   */
  public saveWorkspace(): void {
    this.saveWorkspaceOutput.emit(this.workspaceForm.value);
  }

  /**
   * Patch form with photos and save doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.returnedPhotoOutput.emit(photos);
  }

  /**
   * Remove image from array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    this.removePhotoFromArrayOutput.emit(id);
  }

  /**
   * Detects photos
   * @param files
   */
  public detectPhotos(files: FileList): void {
    this.detectPhotosOutput.emit(files);
  }

  /**
   * Toggles the create workspace overlay
   */
  public toggleCreateWorkspace(): void {
    this.toggleCreateWorkspaceOutput.emit(true);
  }
}
