<form [formGroup]="siteForm" class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
  <div class="sm:col-span-6">
    <div class="form-input-wrapper">
      <label for="site-address" class="form-input-label">Address</label>
      <textarea type="text" name="site-address" id="site-address" class="form-input" placeholder="Address" formControlName="address"></textarea>
    </div>
  </div>

  <div class="col-span-6">
    <div class="flex flex-col">
      <img class="-ml-2 w-32" src="./../../../../../assets/images/what3words.png" />
      @if (siteForm.value.what_3_words || new3Words) {
        <div class="-mt-2 mb-2 flex flex-row items-center dark:text-slate-300">
          <div class="mr-1 text-[#e11f26]">///</div>
          <div class="cursor-pointer text-brand_Primary hover:text-brand_Primary-hover" title="Copy to clipboard" (click)="copyW3WText(siteForm.value.what_3_words!)">{{ siteForm.value.what_3_words }}</div>
          @if (w3wCopied) {
            <div class="text-xs text-text_Subtitle">&nbsp; - Copied!</div>
          }
        </div>
      }
    </div>

    <div class="sm:col-span-6 block" [ngClass]="inspectionOrSite === 'site' && site.map_url ? 'hidden' : 'block'">
      @if (!siteForm.value.what_3_words || !new3Words) {
        <p class="list-meta pb-2">We use what3words to find precise locations. Adding a map location will generate a unique combination of three words: a what3words address.</p>
      }

      <div class="grid grid-cols-1 gap-12">
        <div class="relative w-full" [ngClass]="inspectionOrSite === 'site' ? 'h-[500px]' : 'h-[350px]'">
          <what3words-map
            api_key="{{ w3w_api_key }}"
            map_api_key="{{ map_api_key }}"
            zoom="8"
            selected_zoom="15"
            lat="51.523758"
            lng="-0.158537"
            search_control_position="2"
            map_type_control
            zoom_control
            fullscreen_control
            fullscreen_control_position="3"
            current_location_control_position="9"
            disable_default_ui
            map_type_id="roadmap"
            street_view_control="true"
            id="w3w-map-element"
            words="{{ siteForm.value.what_3_words }}">
            <div slot="map" id="w3w-map" [ngClass]="inspectionOrSite === 'site' ? 'h-[500px]' : 'h-[350px]'"></div>

            <div slot="search-control">
              <what3words-autosuggest id="w3w-autosuggest-element">
                <input type="text" placeholder="what.three.words" formControlName="what_3_words" type="text" name="what_3_words" id="autosuggest" />
              </what3words-autosuggest>
            </div>
            <div slot="current-location-control">
              <button style="padding: 0; margin: 0px; border: none; border-radius: 50%; background: none">
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_7854_8356)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29 53C42.2548 53 53 42.2548 53 29C53 15.7452 42.2548 5 29 5C15.7452 5 5 15.7452 5 29C5 42.2548 15.7452 53 29 53Z" fill="white" />
                    <path d="M31.5 29.0007C31.5 30.3797 30.3807 31.5 29 31.5C27.6193 31.5 26.5 30.3797 26.5 29.0007C26.5 27.6201 27.6195 26.5 29 26.5C30.3805 26.5 31.5 27.6201 31.5 29.0007Z" fill="#00AFFF" stroke="#00AFFF" />
                    <path
                      d="M29 33.6667V37M35.2902 29.0865C35.2902 32.5841 32.4548 35.4195 28.9572 35.4195C25.4589 35.4195 22.6235 32.5841 22.6235 29.0865C22.6235 25.5883 25.4589 22.7529 28.9572 22.7529C32.4548 22.7529 35.2902 25.5883 35.2902 29.0865ZM21 29H24.3333H21ZM33.6667 29H37H33.6667ZM29 21V24.3333V21Z"
                      stroke="#00AFFF"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </g>
                  <defs>
                    <filter id="filter0_d_7854_8356" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7854_8356" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7854_8356" result="shape" />
                    </filter>
                  </defs>
                </svg>
              </button>
            </div>
          </what3words-map>
        </div>
      </div>
    </div>

    @if (site && site.map_url) {
      <div class="sm:col-span-6">
        <div class="relative h-[640px] w-[640px]">
          <img src="{{ site.map_url }}" class="h-full w-full" />

          <div class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500" (click)="toggleDeleteW3WOverlay()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
            </svg>
          </div>
        </div>
      </div>
    }
  </div>
</form>

@if (overlay_w3w) {
  <delete-overlay [deleteOverlayTitle]="'Remove Location?'" [deleteOverlayDescription]="'Are you sure you want to remove this Location?'" (cancelButtonClicked)="toggleDeleteW3WOverlay()" (deleteButtonClicked)="editWhat3Words()"></delete-overlay>
}
