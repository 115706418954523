<div class="flex h-auto lg:h-screen">
  <div class="subMenu-wrapper">
    <div class="space-y-8 py-10">
      <div>
        <div class="px-4 pb-2 text-base font-semibold leading-6 text-gray-900">Account</div>
        <ul class="subMenu-ul">
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'account', 'profile']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Profile </span>
                <p class="subMenu-description">Edit your Profile information</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'account', 'email']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25" />
              </svg>

              <div>
                <span class="subMenu-title"> Email </span>
                <p class="subMenu-description">Change your email address</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'account', 'subscription']" [routerLinkActive]="['active']" class="subMenu-a group group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Subscription </span>
                <p class="subMenu-description">View your subscription details</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'account', 'mydata']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
              </svg>

              <div>
                <span class="subMenu-title"> My Data </span>
                <p class="subMenu-description">Request your data or view T&C's and Privacy Policies</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'account', 'devices']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
              </svg>

              <div>
                <span class="subMenu-title"> Devices Connected </span>
                <p class="subMenu-description">Manage the devices currently using your account</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'account', 'deleteaccount']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Delete Account </span>
                <p class="subMenu-description">Completely delete your account across all Workspaces</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
