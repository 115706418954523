import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "test-percentage",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: "./test-percentage.component.html",
  styleUrl: "./test-percentage.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestPercentageComponent {
  @Input({ required: true }) testForm!: FormGroup;
  public answerType = [
    { title: "0%", value: "percent_0" },
    { title: "25%", value: "percent_25" },
    { title: "50%", value: "percent_50" },
    { title: "75%", value: "percent_75" },
    { title: "100%", value: "percent_100" },
  ];
}
