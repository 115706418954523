import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InfoViewStringsService } from 'src/app/core/services/strings/info-view-strings.service';
import { InfoViewFilter, InfoViewType } from 'src/app/models/strings/strings.model';
import { InfoViewComponent } from '../info-view/info-view.component';

@Component({
  selector: 'user-name-form',
  standalone: true,
  imports: [ReactiveFormsModule, InfoViewComponent],
  templateUrl: './user-name-form.component.html',
  styleUrl: './user-name-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNameFormComponent {
  // Services
  private infoViewStringsService = inject(InfoViewStringsService);

  // Inputs, Outputs
  @Input({ required: true }) signUpForm!: FormGroup;
  @Output() createUserDocEmitter = new EventEmitter();

  // Properties
  public infoView: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.AddYourName);
  public clicked = false;

  // Check if the form is valid and create a user document
  async checkFormValidationsOrCreateUserDoc() {
    if (this.signUpForm.invalid) {
      this.clicked = true;
    } else {
      this.createUserDocEmitter.emit();
    }
  }
}
