import { AsyncPipe, JsonPipe } from "@angular/common";
import { Component, HostListener, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Operation, RolePermissions, User } from "cip";
import { BehaviorSubject, filter, firstValueFrom, Observable, Subscription, switchMap, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { NavigationService } from "src/app/core/services/navigation.service";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { InspectionItemDetailService } from "../../services/inspection-item-detail.service";

import { MatSnackBar } from "@angular/material/snack-bar";
import { serverTimestamp, WithFieldValue } from "firebase/firestore";
import { DuplicationService } from "src/app/core/services/duplication/duplication.service";
import { ActionType, LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { OperationStringsService } from "src/app/core/services/strings/operation-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { ItemType } from "src/app/models/item/item-type.model";
import { OperationEnhanced } from "src/app/models/operation/operation.model";
import { AlertType, InfoViewFilter, InfoViewType, OperationType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { DuplicateOverlayComponent } from "src/app/shared/duplicate-overlay/duplicate-overlay.component";
import { inspectionItemsListService } from "../../services/inspection-items-list.service";
import { InspectionItemEditFormComponent } from "../inspection-item-edit-form/inspection-item-edit-form.component";
import { InspectionItemEditHeaderComponent } from "../inspection-item-edit-header/inspection-item-edit-header.component";

@Component({
  selector: "inspection-item-edit-wrapper",
  standalone: true,
  imports: [InspectionItemEditHeaderComponent, InspectionItemEditFormComponent, LoadingSpinnerComponent, AsyncPipe, JsonPipe, DuplicateOverlayComponent, DeleteOverlayComponent],
  templateUrl: "./inspection-item-edit-wrapper.component.html",
  styleUrl: "./inspection-item-edit-wrapper.component.scss",
})
export class InspectionItemEditWrapperComponent implements OnInit, OnDestroy {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  @Input({ required: true })
  set categoryId(value: string) {
    this._categoryId = value;
  }
  get categoryId(): string {
    return this._categoryId;
  }

  @Input({ required: true })
  set itemId(value: string) {
    this._itemId = value;
    this.itemIdSubject.next(value);
  }
  get itemId(): string {
    return this._itemId;
  }

  // Services
  private inspectionItemDetailService = inject(InspectionItemDetailService);
  private inspectionItemsListService = inject(inspectionItemsListService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private navigationService = inject(NavigationService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private _snackBar = inject(MatSnackBar);
  private operationStringsService = inject(OperationStringsService);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);
  public duplicationService = inject(DuplicationService);

  // Properties
  private user!: User;
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public _categoryId!: string;
  public _itemId!: string;
  private itemIdSubject = new BehaviorSubject<string>(this.itemId);
  public itemForm: FormGroup;
  public item$!: Observable<ItemEnhanced>;
  public infoViewItemEditorTest: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.ItemEditorTest);
  public infoViewItemEditorQuestion: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.ItemEditorQuestion);
  public hasTestAnswer!: boolean;
  private itemToDelete: ItemEnhanced | null = null;
  public deleteTitle: string = "";
  public deleteDescription: string = "";
  public overlay_deleteItem: boolean = false;

  // Duplicate item
  public itemToBeDuplicated!: ItemEnhanced;
  public duplicationObject!: OperationEnhanced;
  private _duplicationSubscription!: Subscription;
  public duplicationTitle!: string;
  public duplicationDescription!: string;
  public inProgressText!: string;
  public successText!: string;
  public failedText!: string;
  public navigateToText!: string;
  public duplicationType!: string;

  constructor() {
    this.navigationService.breadcrumbsPath = this.router.url;
    this.user = this.authService.currentUser;
    this.itemForm = this.fb.group({
      id: [{ value: null }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      answered: null,
      enabled: null,
      answer_type: [null, Validators.required],
      order: null,
      category_id: null,
      mode: [null, Validators.required],
      actions_count: null,
      photos_count: null,
      shows_on_report: null,
      test_answer: null,
      notes: null,
      question_number: null,
      question_string: null,
      question_bool: null,
      question_date: null,
      question_time: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.item$ = this.itemIdSubject.pipe(
      switchMap((itemId) => this.inspectionItemDetailService.getItemDoc$(this.workspaceId, this.inspectionId, itemId)),
      filter((data) => !!data),
      tap((data) => {
        if (this.itemForm.pristine && this.itemForm.untouched) {
          this.itemForm.patchValue(data);
        }
        this.hasTestAnswer = data.test_answer ? true : false;
      })
    );
  }

  ngOnDestroy(): void {
    this._duplicationSubscription?.unsubscribe();
  }

  /**
   * Save Item And Re-Route
   */
  public async saveItemAndReRoute(item: ItemEnhanced): Promise<void> {
    try {
      const returnedItemId = await this.inspectionItemDetailService.saveItem(this.workspaceId, this.inspectionId, this.categoryId, this.itemId, this.itemForm.value, this.hasTestAnswer, this.user, item);
      const route = ["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", returnedItemId.itemId];
      this.itemForm.markAsPristine();
      this.router.navigate(route);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Item And Add Another
   */
  public async saveItemAndAddAnother(item: ItemEnhanced): Promise<void> {
    const type = this.itemForm.value.mode === "test" ? "test" : "question";
    const route = ["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", "new", "editor"];
    try {
      const { itemObj: newItem } = await this.inspectionItemDetailService.saveItem(this.workspaceId, this.inspectionId, this.categoryId, this.itemId, this.itemForm.value, this.hasTestAnswer, this.user, item);
      this.setFormType(type);
      this.router.navigate(route);
      this.toggleSnackBarConfirmation(newItem.title);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Item And Add Different Mode
   */
  public async saveItemAndAddDifferentMode(item: ItemEnhanced): Promise<void> {
    const type = this.itemForm.value.mode === "test" ? "question" : "test";
    const route = ["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", "new", "editor"];
    try {
      const { itemObj: newItem } = await this.inspectionItemDetailService.saveItem(this.workspaceId, this.inspectionId, this.categoryId, this.itemId, this.itemForm.value, this.hasTestAnswer, this.user, item);
      this.setFormType(type);
      this.router.navigate(route);
      this.toggleSnackBarConfirmation(newItem.title);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Set Form Type
   * @param type
   */
  private setFormType(type: ItemType): void {
    this.itemForm.markAsPristine();
    this.itemForm.reset();
    this.itemForm.patchValue({ mode: type });
  }

  /**
   * Toggle And Set Duplicate item Overlay
   * @param item
   */
  async toggleAndSetDuplicateItemOverlay(item: ItemEnhanced): Promise<void> {
    const featureAction: keyof RolePermissions = "item_create_update";
    const actionType: ActionType = { type: "duplicate" };
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction, actionType);
    if (limitationResult) {
      this.itemToBeDuplicated = item;
      this.duplicationService.overlay_duplicate = true;
      this.duplicationService.overlay_duplicateConfirmation = true;
      const duplicateItem = this.operationStringsService.operationFilter(OperationType.DuplicateItem);
      if (duplicateItem) {
        this.duplicationTitle = duplicateItem.title;
        this.duplicationDescription = duplicateItem.description;
        this.inProgressText = duplicateItem.running;
        this.successText = duplicateItem.success;
        this.failedText = duplicateItem.failure;
        this.navigateToText = "item";
      } else {
        console.error("Unknown string type:", OperationType.Reinspection);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Duplicate item
   * @param itemTitle
   */
  async duplicateItem(itemTitle: string) {
    this.duplicationService.toggleDuplicationProcess();
    const duplicationInitiation: WithFieldValue<Operation> = {
      type: "duplicate-item",
      created_at: serverTimestamp(),
      status: "pending",
      request: {
        inspection_id: this.inspectionId,
        item_id: this.itemToBeDuplicated.id,
        title: itemTitle,
      },
    };
    const operation = (await this.duplicationService.duplicate(this.workspaceId, duplicationInitiation)) as OperationEnhanced;
    this.subscribeToDuplicationEvent(operation);
  }

  /**
   * Subscribe To Duplication Event
   * @param operation
   */
  subscribeToDuplicationEvent(operation: OperationEnhanced): void {
    this._duplicationSubscription = this.duplicationService.getDuplicationOperation$(this.workspaceId, operation.id).subscribe((result) => {
      if (result) {
        this.duplicationObject = result;
      }
    });
  }

  /**
   * Navigate To New item
   * @returns
   */
  async navigateToNewitem(): Promise<boolean> {
    this.duplicationService.overlay_duplicate = !this.duplicationService.overlay_duplicate;
    return this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", this.duplicationObject.response?.new_item_id]);
  }

  /**
   * Set Delete Single Item Properties
   * @param item
   */
  public setDeleteSingleItemProperties(item: ItemEnhanced): void {
    const deleteItem = this.stringsService.alertFilter(AlertType.DeleteItem);
    if (deleteItem) {
      this.deleteTitle = deleteItem.title;
      this.deleteDescription = deleteItem.description;
    } else {
      console.error("Unknown string type:", AlertType.DeleteItem);
    }
    this.itemToDelete = item;
    this.toggleDeleteItemOverlay();
  }

  /**
   * Delete Single Item
   */
  public async deleteSingleItem(): Promise<void> {
    try {
      const allItems = await firstValueFrom(this.inspectionItemsListService.getItemsList$(this.workspaceId, this.inspectionId, this.categoryId));
      await this.inspectionItemDetailService.deleteItemDoc(this.workspaceId, this.inspectionId, this.categoryId, this.itemToDelete!, allItems, this.user);
      this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items"]);
      this.toggleDeleteItemOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Delete Category Overlay
   */
  public toggleDeleteItemOverlay(): void {
    this.overlay_deleteItem = !this.overlay_deleteItem;
  }

  /**
   * Toggle Snack Bar Confirmation
   * @param docTitle
   */
  public toggleSnackBarConfirmation(docTitle: string): void {
    this._snackBar.open(`${docTitle} added`, "Close", {
      duration: 3000,
    });
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.itemForm.dirty ? false : true;
  }
}
