<div class="content-card-wrapper">
  <div class="content-card-header flex items-center justify-between">
    <h3>Site</h3>
    <tooltip [toolTipText]="toolTipInspectionDetailsSite.title" />
  </div>
  <div class="px-6 py-5">
    <form [formGroup]="inspectionForm" class="form-input-wrapper relative flex h-[60px] cursor-pointer items-center hover:ring-gray-400" (click)="toggleSiteSelectionOverlay()">
      <div class="text-brand_Primary-hover">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-4 w-4">
          <path
            fill-rule="evenodd"
            d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>

      <div class="w-full border-0 pl-3 text-base focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-700 sm:leading-6" [ngClass]="inspectionForm.value.site_title ? 'text-text_Title' : 'text-text_Placeholder'">
        {{ inspectionForm.value.site_title ? inspectionForm.value.site_title : 'No Site' }}
      </div>

      <div class="absolute left-0 top-0 flex h-full w-full items-center justify-end pr-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="-mr-1 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#C8C8C8" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </form>
  </div>
</div>

@if (overlay_siteSelector) {
  <inspection-details-site-selector
    [siteForm]="siteForm"
    [workspaceId]="workspaceId"
    [newSiteId]="newSiteId"
    [overlay_siteForm]="overlay_siteForm"
    [uploadFileArray]="uploadFileArray"
    [overlay_uploadPhotos]="overlay_uploadPhotos"
    [photoPreview]="photoPreview"
    [inspectionForm]="inspectionForm"
    [currentlySelectedSite]="currentlySelectedSite"
    [allOtherSites]="allOtherSites"
    [sites]="sites$ | async"
    (cancelSiteSelectionOutput)="toggleSiteSelectionOverlay()"
    (siteSelectionOutput)="siteSelection($event)"
    (unselectSiteOutput)="unselectSite()"
    (detectPhotosOutput)="detectPhotos($event)"
    (toggleNewSiteFormOutput)="toggleSiteFormOverlay()"
    (removePhotoFromArrayOutput)="removePhotoFromArray($event)"
    (returnedPhotoOutput)="patchFormWithPhotosAndSaveDoc($event)"
    (saveSiteOutput)="doesAPhotoNeedUploading()"
  />
}
