import { Component, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserNameFormComponent } from 'src/app/shared/user-name-form/user-name-form.component';

@Component({
  selector: 'profile-complete',
  standalone: true,
  imports: [UserNameFormComponent],
  templateUrl: './profile-complete.component.html',
  styleUrl: './profile-complete.component.scss',
})
export class ProfileCompleteComponent {
  // Services
  private fb = inject(FormBuilder);
  private auth = inject(Auth);
  private authService = inject(AuthService);
  private router = inject(Router);

  // Properties
  public signUpForm: FormGroup;

  constructor() {
    this.signUpForm = this.fb.group({
      name_first: ['', Validators.required],
      name_last: ['', Validators.required],
      terms: [false, Validators.requiredTrue],
      privacy: [false, Validators.requiredTrue],
    });
  }

  /**
   * Create User Doc
   */
  async createUserDoc() {
    try {
      const currentUser = this.auth.currentUser;
      const email = this.authService.getEmailFromLocalStorage();

      if (currentUser && email) {
        await this.authService.createUserDoc(this.signUpForm, email);
        this.handleSuccessfullSignIn();
      }
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Handle Successful Sign In
   */
  handleSuccessfullSignIn() {
    this.authService.removeEmailFromLocalStorage();
    this.authService.removeNewAccountFromLocalStorage();
    this.router.navigate(['workspaces']);
  }
}
