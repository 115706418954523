import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'action-edit-complete',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './action-edit-complete.component.html',
  styleUrl: './action-edit-complete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEditCompleteComponent {
  @Input({required: true}) actionForm!: FormGroup;
}
