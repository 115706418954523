import { inject, Injectable } from "@angular/core";
import { Role, User } from "cip";
import { PartialWithFieldValue, WithFieldValue } from "firebase/firestore";
import { catchError, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { RoleEnhanced } from "src/app/models/role/role.model";

@Injectable({
  providedIn: "root",
})
export class RoleDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Role Doc
   * @param workspaceId
   * @param roleId
   */
  public getRoleDoc$(workspaceId: string, roleId: string): Observable<RoleEnhanced | null> {
    if (roleId === "new") {
      return of({} as RoleEnhanced);
    }
    const path = this.collectionsService.rolesCol(workspaceId);
    const documentPath = `${path}/${roleId}`;
    return this.firestoreUtilsService.getDocumentData<RoleEnhanced>(documentPath).pipe(
      map((data) => data || ({} as RoleEnhanced)),
      catchError((error) => {
        return of({} as RoleEnhanced);
      })
    );
  }

  /**
   * Save Role Doc
   * @param workspaceId
   * @param roleForm
   * @param roleId
   * @param user
   */
  public async saveRoleDoc(workspaceId: string, roleForm: RoleEnhanced, user: User) {
    const path = this.collectionsService.rolesCol(workspaceId);
    const roleIdToUse = roleForm.id === "new" || roleForm.id === null ? this.firestoreUtilsService.createFirestoreId() : roleForm.id;
    const documentPath = `${path}/${roleIdToUse}`;
    const writeEventType = roleForm.id === "new" || roleForm.id === null ? "added" : "changed";
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const roleObj: WithFieldValue<Role> = {
      title: roleForm.title.trim(),
      description: roleForm.description.trim(),
      permissions: roleForm.permissions,
      type: "custom",
      created_by_id: roleForm.created_by_id ?? user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };
    await this.firestoreUtilsService.setPartialDocumentData<RoleEnhanced>(documentPath, roleObj);
    return { ...roleObj, id: roleIdToUse };
  }

  /**
   * Delete Role
   * @param workspaceId
   * @param roleId
   * @param user
   */
  public async deleteRoleDoc(workspaceId: string, roleId: string, user: User): Promise<void> {
    const path = this.collectionsService.rolesCol(workspaceId);
    const doc = `${path}/${roleId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const role: PartialWithFieldValue<RoleEnhanced> = {
      is_deleted: true,
      last_event: lastEvent,
    };
    await this.firestoreUtilsService.setPartialDocumentData<RoleEnhanced>(doc, role);
  }
}
