<header>
  <!-- Breadcrumbs-->
  <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" />

  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Signatures</h2>
      </div>

      <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
        @if (signatureBulkEditArray && signatureBulkEditArray.length > 0) {
          <button type="button" class="btn btn-secondary mr-3" aria-label="Bulk Delete" type="button" (click)="bulkDeleteSignatures()">Bulk Delete</button>
        }
        @if (signatures.length > 0) {
          <button class="btn btn-secondary" aria-label="Edit Signature" type="button" (click)="toggleEditMode()">{{ editMode ? 'Done' : 'Edit' }}</button>
        }
        @if (!editMode) {
          <button (click)="routeToSignatureEditor()" aria-label="New Signature" type="button" class="btn btn-primary ml-0 sm:ml-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Signature
          </button>
        }
      </div>
    </div>
  </div>
</header>
