import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RolePermissions, User } from 'cip';
import { filter, Observable, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormFormattingService } from 'src/app/core/services/forms/form-formatting.service';
import { LimitationManagerService } from 'src/app/core/services/limitation-manager.service';
import { InspectionSorting } from 'src/app/models/inspection/inspection-sorting.model';
import { InspectionEnhanced } from 'src/app/models/inspection/inspection.model';
import { EmptyListComponent } from 'src/app/shared/empty-list/empty-list.component';
import { LoadingSpinnerComponent } from 'src/app/shared/loading-spinner/loading-spinner.component';

import { InspectionNewOverlayComponent } from '../../edit/inspection-new-overlay/inspection-new-overlay.component';
import { InspectionDetailService } from '../../services/inspection-detail.service';
import { InspectionsListService } from '../../services/inspections-list.service';
import { InspectionsListHeaderComponent } from '../inspections-list-header/inspections-list-header.component';
import { InspectionsListComponent } from '../inspections-list/inspections-list.component';

@Component({
  selector: 'inspections-list-wrapper',
  standalone: true,
  imports: [JsonPipe, AsyncPipe, InspectionsListComponent, InspectionsListHeaderComponent, EmptyListComponent, LoadingSpinnerComponent, InspectionNewOverlayComponent],
  templateUrl: './inspections-list-wrapper.component.html',
  styleUrl: './inspections-list-wrapper.component.scss',
})
export class InspectionsListWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  // Services
  public inspectionsListService = inject(InspectionsListService);
  private inspectionDetailService = inject(InspectionDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private authService: AuthService = inject(AuthService);
  private router = inject(Router);

  // Properties
  public user!: User;
  public _workspaceId!: string;
  public _folderId!: string;
  public inspections$!: Observable<InspectionEnhanced[]>;
  public currentSearchTerm: string = '';
  public limitationResult: boolean = false;
  public overlay_newInspection: boolean = false;
  public inspectionForm: FormGroup;

  constructor() {
    this.user = this.authService.currentUser;
    this.inspectionForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ['', [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      ref: null,
      date: [null, [Validators.required]],
      folder_id: null,
      folder_title: null,
      date_created: null,
      overall_score: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getInspectionsList();
  }

  /**
   * Get Inspections List
   */
  private getInspectionsList(): void {
    try {
      this.inspections$ = this.inspectionsListService.getInspectionsList$(this.workspaceId, this.folderId).pipe(
        filter((data) => !!data),
        tap((data) => {
          this.inspectionsListService.updateReorderedInspections(data);
          if (this.inspectionsListService.areInspectionsSorted) {
            this.inspectionsListService.reorderInspectionsWithNewData();
          } else {
            this.inspectionsListService.currentlySelectedInspectionSortChoice = InspectionSorting.title;
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Inspection
   */
  public async saveInspection(): Promise<void> {
    try {
      const inspectionId = await this.inspectionDetailService.createNewInspectionDoc(this.inspectionForm.value, this.workspaceId, this.folderId, this.user);
      this.toggleNewInspectionOverlay();
      this.inspectionForm.reset();
      this.router.navigate(['/', 'workspace', this.workspaceId, 'folders', this.folderId, 'inspections', inspectionId, 'overview']);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Add Folder
   */
  public async canUserAddInspection(): Promise<void> {
    this.limitationResult = await this.canUserEdit();
    if (this.limitationResult) {
      this.toggleNewInspectionOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Can User Edit
   */
  async canUserEdit(): Promise<boolean> {
    return await this.canUserPerformAction('inspection_create_update');
  }

  /**
   * Limitation Manager Check
   */
  private async canUserPerformAction(action: keyof RolePermissions): Promise<boolean> {
    return await this.limitationManagerService.canUserPerformAction(action);
  }

  /**
   * Toggle Sort Dropdown
   */
  public toggleSortDropdown(): void {
    this.inspectionsListService.sortDropdown = !this.inspectionsListService.sortDropdown;
  }

  /**
   * Change Inspection Sort Choice
   * @param filterChoice
   */
  public changeInspectionSortChoice(filterChoice: string): void {
    this.inspectionsListService.areInspectionsSorted = true;
    this.inspectionsListService.sortDropdown = false;

    if (this.inspectionsListService.currentlySelectedInspectionSortChoice === filterChoice) {
      this.inspectionsListService.isSortOrderAscending = !this.inspectionsListService.isSortOrderAscending;
    } else {
      this.inspectionsListService.isSortOrderAscending = false;
    }

    this.inspectionsListService.currentlySelectedInspectionSortChoice = filterChoice;
    this.inspectionsListService.reorderInspectionsWithNewData();
  }

  /**
   * Update Search Term
   * @param term
   */
  updateSearchTerm(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   * Toggle New Inspection Overlay
   */
  public toggleNewInspectionOverlay(): void {
    this.overlay_newInspection = !this.overlay_newInspection;
    this.inspectionForm.reset();
  }
}
