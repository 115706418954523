import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TemplateItemEnhanced } from "src/app/models/item/template-item.model";
import { BreadcrumbsComponent } from "src/app/shared/breadcrumbs/breadcrumbs.component";

@Component({
  selector: "manage-template-item-edit-header",
  standalone: true,
  imports: [BreadcrumbsComponent, RouterModule],
  templateUrl: "./manage-template-item-edit-header.component.html",
  styleUrl: "./manage-template-item-edit-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateItemEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) templateId!: string;
  @Input({ required: true }) categoryId!: string;
  @Input({ required: true }) itemId!: string;
  @Input({ required: true }) item!: TemplateItemEnhanced;
  @Output() duplicateItemOutput = new EventEmitter<void>();

  /**
   * Duplicate Item
   */
  public duplicateItem() {
    this.duplicateItemOutput.emit();
  }
}
