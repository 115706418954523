@if (memberDoc) {
  <form [formGroup]="form">
    <div class="content-grid gap-y-6">
      @if (memberDoc.role_title === "Owner" && form.value.role_id === "Owner") {
        <div class="sm:col-span-6">
          <info-view [type]="infoViewMemberEditor.type" [message]="infoViewMemberEditor.message" />
        </div>
      }

      <div class="sm:col-span-6">
        <div class="form-input-wrapper relative flex h-[56px] items-center hover:ring-gray-400" [ngClass]="form.value.role_id === 'Owner' ? 'cursor-not-allowed opacity-25' : 'cursor-pointer'" (click)="toggleRoleSelector()">
          <div class="text-brand_Primary-hover">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
            </svg>
          </div>
          @if (currentRole) {
            <div class="w-full border-0 pl-3 text-base focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-700 sm:leading-6" [ngClass]="currentRole.title ? 'text-text_Title' : 'text-text_Placeholder'">
              {{ currentRole.title ? currentRole.title : "No Role" }}
            </div>
          }
          <div class="absolute left-0 top-0 flex h-full w-full items-center justify-end pr-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="-mr-1 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#C8C8C8" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </form>
}
