import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'delete-confirmation',
  standalone: true,
  imports: [NgClass, FormsModule],
  templateUrl: './delete-confirmation.component.html',
  styleUrl: './delete-confirmation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteConfirmationComponent {
  public deleteConfirmation = '';
  @Input({ required: true }) deleteTitle!: string;
  @Input({ required: true }) deleteMessage!: string;
  @Output() cancelCommandEmitter = new EventEmitter();
  @Output() deleteCommandEmitter = new EventEmitter();

  deleteCommand() {
    this.deleteCommandEmitter.emit();
  }

  cancelCommand() {
    this.cancelCommandEmitter.emit();
  }
}
