import { JsonPipe, NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ClientEnhanced } from "src/app/models/client/client.model";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { ManageClientEditDetailsComponent } from "src/app/sections/workspaces/current-workspace/manage-clients/edit/manage-client-edit-details/manage-client-edit-details.component";
import { DropzoneDirective } from "src/app/shared/directives/dropzone.directive";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { PhotoDropzoneComponent } from "src/app/shared/photo-dropzone/photo-dropzone.component";
import { PhotoPreviewComponent } from "src/app/shared/photo-preview/photo-preview.component";
import { PhotoUploadSingleComponent } from "src/app/shared/photo-upload-single/photo-upload-single.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "inspection-details-client-selector",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PhotoDropzoneComponent,
    PhotoPreviewComponent,
    PhotoUploadSingleComponent,
    DropzoneDirective,
    NgClass,
    LoadingSpinnerComponent,
    NgStyle,
    JsonPipe,
    ManageClientEditDetailsComponent,
    EmptyListComponent,
    SearchBarComponent,
    SearchEmptyComponent,
  ],
  templateUrl: "./inspection-details-client-selector.component.html",
  styleUrl: "./inspection-details-client-selector.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionDetailsClientSelectorComponent implements OnChanges {
  @Input({ required: true }) inspectionForm!: FormGroup;
  @Input({ required: true }) clientForm!: FormGroup;
  @Input({ required: true }) overlay_clientForm!: boolean;
  @Input({ required: true }) uploadFileArray: SinglePhotoUpload[] = [];
  @Input({ required: true }) overlay_uploadPhotos: boolean = false;
  @Input({ required: true }) photoPreview: boolean = false;
  @Input({ required: true }) newclientId!: string;
  @Input({ required: true }) workspaceId!: string;
  @Input() clients: ClientEnhanced[] | undefined | null;
  @Input() currentlySelectedClient!: ClientEnhanced | null;
  @Input() allOtherClients: Array<ClientEnhanced> = [];
  @Output() cancelClientSelectionOutput = new EventEmitter<void>();
  @Output() detectPhotosOutput = new EventEmitter<FileList>();
  @Output() removePhotoFromArrayOutput = new EventEmitter<string>();
  @Output() returnedPhotoOutput = new EventEmitter<PhotoType>();
  @Output() saveClientOutput = new EventEmitter();
  @Output() clientSelectionOutput = new EventEmitter<ClientEnhanced>();
  @Output() unselectClientOutput = new EventEmitter<boolean>();
  @Output() toggleNewClientFormOutput = new EventEmitter<boolean>();

  // Properties
  public filteredClients: Array<ClientEnhanced> = [];
  public allClientsHidden: boolean = false;
  public searchTerm: string = "";

  /**
   * ngOnChanges
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["allOtherClients"]) {
      this.applySearch();
    }
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredClients = this.allOtherClients.map((client) => ({
      ...client,
      hidden: !client.name.toLowerCase().includes(lowerSearchTerm),
    }));

    this.allClientsHidden = this.filteredClients.every((client) => client.hidden);
  }

  /**
   * Detects photos
   * @param files
   */
  public detectPhotos(files: FileList): void {
    this.detectPhotosOutput.emit(files);
  }

  /**
   * Save the client
   */
  public saveClient(): void {
    this.saveClientOutput.emit();
  }

  /**
   * Patch form with photos and save doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.returnedPhotoOutput.emit(photos);
  }

  /**
   * Remove image from array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    this.removePhotoFromArrayOutput.emit(id);
  }

  /**
   * Toggle New client Form
   */
  public toggleNewclientForm(): void {
    this.toggleNewClientFormOutput.emit();
  }

  /**
   * client Selection
   * @param client
   */
  public clientSelection(client: ClientEnhanced): void {
    this.clientSelectionOutput.emit(client);
  }

  /**
   * Unselect client
   */
  public unselectClient(): void {
    this.unselectClientOutput.emit();
  }

  /**
   * Cancel Button
   */
  public cancelButton(): void {
    this.cancelClientSelectionOutput.emit();
  }
}
