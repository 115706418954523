import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnDestroy, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "cip";
import * as ExifReader from "exifreader";
import { Timestamp, WithFieldValue } from "firebase/firestore";
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, firstValueFrom, from, map, Observable, Subscription, switchMap, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { GetCountFromServerService } from "src/app/core/services/counts/get-count-from-server.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { NavigationService } from "src/app/core/services/navigation.service";
import { RemoteConfigService } from "src/app/core/services/remote-config.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { CategoryEnhanced } from "src/app/models/category/category.model";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { ExifTags, MultiPhotoUpload } from "src/app/models/photo/photo-upload.model";
import { PhotoEnhanced } from "src/app/models/photo/photo.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { BreadcrumbsComponent } from "src/app/shared/breadcrumbs/breadcrumbs.component";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { InspectionCategoryDetailService } from "../../categories/services/inspection-category-detail.service";
import { InspectionItemDetailService } from "../../items/services/inspection-item-detail.service";
import { ItemPhotosService } from "../../items/services/item-photos.service";
import { QuestionFormWrapperComponent } from "../question-form/question-form-wrapper/question-form-wrapper.component";
import { TestFormWrapperComponent } from "../test-form/test-form-wrapper/test-form-wrapper.component";
import { TestHeaderComponent } from "../test-header/test-header.component";
import { TestNavigationComponent } from "../test-navigation/test-navigation.component";

@Component({
  selector: "test-wrapper",
  standalone: true,
  imports: [TestNavigationComponent, TestHeaderComponent, AsyncPipe, JsonPipe, BreadcrumbsComponent, LoadingSpinnerComponent, TestFormWrapperComponent, QuestionFormWrapperComponent, FormStateComponent, NgClass, DeleteOverlayComponent],
  templateUrl: "./test-wrapper.component.html",
  styleUrl: "./test-wrapper.component.scss",
})
export class TestWrapperComponent implements OnInit, OnDestroy {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  @Input({ required: true })
  set categoryId(value: string) {
    this._categoryId = value;
  }
  get categoryId(): string {
    return this._categoryId;
  }

  @Input({ required: true })
  set itemId(value: string) {
    this._itemId = value;
    this.itemIdSubject.next(value);
  }
  get itemId(): string {
    return this._itemId;
  }

  // Services
  private inspectionItemDetailService = inject(InspectionItemDetailService);
  private navigationService = inject(NavigationService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private inspectionCategoryDetailService = inject(InspectionCategoryDetailService);
  private collectionsService = inject(CollectionsService);
  private getCountFromServerService = inject(GetCountFromServerService);
  private limitationManagerService = inject(LimitationManagerService);
  private remoteConfigService = inject(RemoteConfigService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private itemPhotosService = inject(ItemPhotosService);
  private stringsService = inject(StringsService);

  // Properties
  public user!: User;
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public _categoryId!: string;
  public _itemId!: string;
  public testForm: FormGroup;
  public test$!: Observable<ItemEnhanced>;
  public category$!: Observable<CategoryEnhanced>;
  public actions$!: Observable<CategoryEnhanced>;

  public testCategoryPhoto$!: Observable<{
    category: CategoryEnhanced;
    item: ItemEnhanced;
    photos: PhotoEnhanced[];
    itemLimitation: boolean;
    photoLimitation: boolean;
  }>;

  public photos$!: Observable<PhotoEnhanced[]>;
  private itemIdSubject = new BehaviorSubject<string>(this.itemId);
  private testFormSubscription!: Subscription;
  public currentCategoryOrder!: number;
  public itemLimitationManagerResult: boolean = false;
  public photoLimitationManagerResult = signal<boolean>(false);
  public isTestNotesEditable: boolean = false;
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";

  // For navigation
  public currentItem!: ItemEnhanced;
  public totalItems = 0;

  // For Photos
  public webUploadLimit!: number;
  public photoPreview: boolean = false;
  private uploadFileArraySubject = new BehaviorSubject<MultiPhotoUpload[]>([]);
  public uploadFileArray$: Observable<MultiPhotoUpload[]> = this.uploadFileArraySubject.asObservable();
  public overlay_uploadPhotos = false;
  public overlay_deletePhoto = false;
  public uploadCount = 0;
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  private photoToDelete: { photoId: string; photos: PhotoEnhanced[] } = {
    photoId: "",
    photos: [],
  };

  constructor() {
    this.navigationService.breadcrumbsPath = this.router.url;
    this.user = this.authService.currentUser;
    this.testForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", Validators.required],
      enabled: null,
      answer_type: null,
      answered: null,
      order: null,
      category_id: null,
      mode: null,
      actions_count: null,
      photos_count: null,
      test_answer: null,
      notes: null,
      question_number: null,
      question_string: null,
      question_bool: null,
      question_date: null,
      question_time: null,
      created_by: null,
      created_by_id: null,
    });
    /**
     *  This function is triggered when the window finishes loading.
     * It retrieves the scroll position stored in the sessionStorage under the key 'action-edit-scroll-position' and assigns it to the variable 'y'.
     * If the retrieved value is null or undefined, it sets 'y' to 0.
     * Then, it uses the 'window.scrollTo()' method to scroll the window to the specified position (0 on the horizontal axis and 'y' on the vertical axis).
     */
    window.addEventListener("load", () => {
      const scrollPosition = sessionStorage.getItem("action-edit-scroll-position");
      const y = scrollPosition ? +scrollPosition : 0;
      window.scrollTo(0, y);
    });

    /**
     * This function is triggered when the user scrolls the document.
     * It captures the current scroll position using 'window.scrollY' and converts it to a string.
     * Then, it stores this value in the sessionStorage under the key 'action-edit-scroll-position' using the 'window.sessionStorage.setItem()' method.
     * The stored value represents the vertical scroll position of the document at any given time.
     */
    document.addEventListener("scroll", () => {
      window.sessionStorage.setItem("action-edit-scroll-position", window.scrollY.toString());
    });
  }

  ngOnInit(): void {
    this.fetchRemoteConfig();

    this.testCategoryPhoto$ = this.itemIdSubject.pipe(
      switchMap((itemId) => {
        const category$ = this.inspectionCategoryDetailService.getCategoryDoc$(this.workspaceId, this.inspectionId, this.categoryId).pipe(
          filter((data) => !!data),
          tap((data) => {
            this.currentCategoryOrder = data.order + 1;
            this.setTotalItemsCount(this.workspaceId, this.inspectionId, this.categoryId);
          })
        );

        const item$ = this.inspectionItemDetailService.getItemDoc$(this.workspaceId, this.inspectionId, itemId).pipe(
          filter((data) => !!data),
          map((data) => {
            this.currentItem = data;
            if (this.testForm.pristine && this.testForm.untouched) {
              const question_date = data.question_date?.toDate();
              const question_time = data.question_time?.toDate();
              const test = { ...data, question_date, question_time };
              this.testForm.patchValue(test);
            }
            return data;
          })
        );

        const photos$ = this.itemPhotosService.getPhotosList$(this.workspaceId, this.inspectionId, itemId).pipe(filter((data) => !!data));

        // Limitation Checks
        const itemLimitation$ = from(this.limitationManagerService.canUserPerformAction("item_create_update")).pipe(tap((value) => (this.itemLimitationManagerResult = value)));
        const photoLimitation$ = from(this.limitationManagerService.canUserPerformAction("photo_create_update")).pipe(tap((value) => this.photoLimitationManagerResult.set(value)));

        // Combine all three observables using combineLatest
        return combineLatest({
          category: category$,
          item: item$,
          photos: photos$,
          itemLimitation: itemLimitation$,
          photoLimitation: photoLimitation$,
        });
      })
    );

    const fieldsToMonitor = ["test_answer", "question_number", "question_string", "question_bool", "question_date", "question_time"];

    this.testFormSubscription = this.testForm.valueChanges
      .pipe(
        // Only emit when monitored fields change
        distinctUntilChanged((prev, curr) => {
          const prevMonitored = fieldsToMonitor.map((key) => prev[key]);
          const currMonitored = fieldsToMonitor.map((key) => curr[key]);
          return JSON.stringify(prevMonitored) === JSON.stringify(currMonitored);
        }),
        map((changes) => {
          // Extract monitored fields
          const monitoredValues = fieldsToMonitor.map((key) => changes[key]);
          return monitoredValues;
        })
      )
      .subscribe((monitoredValues) => {
        // Check if any monitored field has a value
        const isAnswered = monitoredValues.some((value) => value !== null && value !== undefined && value !== "");

        // Only update if the value has changed
        if (isAnswered !== this.testForm.get("answered")?.value) {
          this.testForm.patchValue({ answered: isAnswered }, { emitEvent: false });
        }
      });
  }

  ngOnDestroy(): void {
    this.testFormSubscription?.unsubscribe();
  }

  /**
   * Reorder Photos
   * @param photos
   */
  public async reorderPhotos(photos: PhotoEnhanced[]): Promise<void> {
    if (this.photoLimitationManagerResult()) {
      try {
        await this.itemPhotosService.reorderPhotos(photos, this.workspaceId, this.inspectionId, this.user);
      } catch (error) {
        alert(error);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Photo
   * @param photoId
   * @param allPhotos
   */
  public async deletePhoto(): Promise<void> {
    const { photoId, photos } = this.photoToDelete;
    try {
      await this.itemPhotosService.deletePhotoDoc(this.workspaceId, this.inspectionId, this.itemId, photoId, photos, this.user);
      this.toggleDeletePhotoOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Photo To Delete Overlay
   * @param event
   */
  setDeletePhotoProperties(event: { photoId: string; photos: PhotoEnhanced[] }): void {
    const { photoId, photos } = event;
    this.photoToDelete = { photoId, photos };
    this.toggleDeletePhotoOverlay();
    const deleteImage = this.stringsService.alertFilter(AlertType.DeleteTestImage);
    if (deleteImage) {
      this.deleteOverlayTitle = deleteImage.title;
      this.deleteOverlayDescription = deleteImage.description;
    } else {
      console.error("Unknown string type:", AlertType.DeleteTestImage);
    }
  }

  /**
   * Fetch Remote Config
   */
  private async fetchRemoteConfig() {
    const photos_maximum_external_drop_web = await this.remoteConfigService.getRemoteConfig("photos_maximum_external_drop_web");
    this.webUploadLimit = photos_maximum_external_drop_web ? photos_maximum_external_drop_web : 20;
  }

  /**
   * Item Answer Limitation Manager Check
   * @param answer
   * When selecting the answer radio buttons, we emit the choice via Outputs up the chain
   * We then perform the limitation manager check before patching the form
   */
  public itemAnswerLimitationManagerCheck(answer: string): void {
    try {
      const currentValue = this.testForm.get("test_answer")?.value;
      if (this.itemLimitationManagerResult) {
        this.testForm.patchValue({ test_answer: answer });
        this.testForm.markAsDirty();
      } else {
        this.limitationManagerService.overlay_limitationManager = true;
        if (currentValue !== undefined) {
          this.testForm.patchValue({ test_answer: currentValue });
        }
      }
    } catch (error) {
      alert(error);
      const currentValue = this.testForm.get("test_answer")?.value;
      if (currentValue !== undefined) {
        this.testForm.patchValue({ test_answer: currentValue });
      }
    }
  }

  /**
   * Set Total Items Count
   * @param workspaceId
   * @param inspectionId
   * @param categoryId
   */
  private async setTotalItemsCount(workspaceId: string, inspectionId: string, categoryId: string): Promise<void> {
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    this.totalItems = await this.getCountFromServerService.getItemsCount(itemPath, categoryId);
  }

  /**
   * Navigate To Next Item
   * @returns
   */
  public async navigateToNextItem() {
    if (!this.currentItem) return;
    const dirty = this.testForm.dirty;

    const proceedWithNavigation = async () => {
      this.resetFormState();
      const nextItem = await firstValueFrom(this.inspectionItemDetailService.getNextItem$(this.workspaceId, this.inspectionId, this.currentItem.order, this.currentItem.category_id));
      if (nextItem) {
        const path = ["/", "workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", nextItem.id];
        this.router.navigate(path);
      }
    };

    if (dirty) {
      firstValueFrom(this.showConfirmDialog()).then((confirmed) => {
        if (confirmed) {
          proceedWithNavigation();
        }
      });
    } else {
      proceedWithNavigation();
    }
  }

  /**
   * Navigate To Previous Item
   * @returns
   */
  public async navigateToPreviousItem() {
    if (!this.currentItem) return;
    const dirty = this.testForm.dirty;
    const proceedWithNavigation = async () => {
      this.resetFormState();
      const previousItem = await firstValueFrom(this.inspectionItemDetailService.getPreviousItem$(this.workspaceId, this.inspectionId, this.currentItem.order, this.currentItem.category_id));
      if (previousItem) {
        this.router.navigate(["..", previousItem.id], { relativeTo: this.activatedRoute });
      }
    };
    if (dirty) {
      firstValueFrom(this.showConfirmDialog()).then((confirmed) => {
        if (confirmed) {
          proceedWithNavigation();
        }
      });
    } else {
      proceedWithNavigation();
    }
  }

  /**
   * Enable or disable the test
   */
  public enableOrDisableTest(): void {
    if (this.itemLimitationManagerResult) {
      this.testForm.markAsDirty();
      this.testForm.patchValue({
        enabled: !this.testForm.value.enabled,
      });
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Remove Answer
   */
  public removeAnswer(): void {
    if (this.itemLimitationManagerResult) {
      this.testForm.patchValue({
        test_answer: null,
        answered: false,
      });
      this.testForm.markAsDirty();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Can User Edit Notes
   */
  canUserEditNotes(): void {
    this.itemLimitationManagerResult ? (this.isTestNotesEditable = true) : (this.limitationManagerService.overlay_limitationManager = true);
  }

  /**
   * On Notes Text Area Input
   * @param event
   */
  onNotesTextAreaInput(event: Event): void {
    if (!this.isTestNotesEditable) {
      event.preventDefault();
      (event.target as HTMLTextAreaElement).value = (event.target as HTMLTextAreaElement).value.slice(0, -1);
    }
  }

  /**
   * Show Confirm Dialog
   */
  private showConfirmDialog(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const result = confirm("WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.");
      observer.next(result);
      observer.complete();
    });
  }

  /**
   * Detect Photos
   * @param files
   * @returns
   */
  public async detectPhotos(files: FileList): Promise<void> {
    // Check if the upload file array is already at its maximum capacity
    if (this.uploadFileArraySubject.value.length >= this.webUploadLimit) {
      return;
    }
    this.testForm.markAsDirty();
    const remainingSlots = this.webUploadLimit - this.uploadFileArraySubject.value.length;
    const filesToProcess = files.length > remainingSlots ? Array.from(files).slice(0, remainingSlots) : Array.from(files);

    if (this.uploadFileArraySubject.value.length < 1) {
      this.togglePhotoPreview();
    }

    // Iterate through the selected files and process each one
    for (let i = 0; i < files.length; i++) {
      const file = filesToProcess[i];
      const fileType = file.type;
      const fileName = file.name;
      const fileExtension = fileType.split("/").pop();

      // Check if the file type is incorrect
      if (!(fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png")) {
        const incorrectFile: IncorrectFileType = { title: fileName };
        this.incorrectFileTypeArray.push(incorrectFile);
      } else {
        const tags: ExifTags = (await ExifReader.load(file)) as ExifTags;
        // const { createDate, formattedLatitude, formattedLongitude } = this.extractTagData(tags);
        const { createDate } = this.extractTagData(tags);
        const tempBlobImageURL = URL.createObjectURL(file);
        const newId = this.firestoreUtilsService.createFirestoreId();
        const photoObj: MultiPhotoUpload = {
          id: newId,
          photo: tempBlobImageURL,
          timestamp: Timestamp.fromDate(createDate),
        };
        const updatedPhotosList = [...this.uploadFileArraySubject.value, photoObj];
        this.uploadFileArraySubject.next(updatedPhotosList);
      }
    }
  }

  /**
   * Remove Photo From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const currentArray = this.uploadFileArraySubject.value;
    const indexOfObject = currentArray.findIndex((object) => object.id === id);
    if (indexOfObject !== -1) {
      const removedFiles = currentArray.splice(indexOfObject, 1);
      this.uploadFileArraySubject.next(currentArray);
      URL.revokeObjectURL(removedFiles[0].photo);
    }
    if (this.uploadFileArraySubject.value.length < 1) {
      this.togglePhotoPreview();
    }
  }

  /**
   * Photo Uploads Complete Save Test
   * @param photoDocs
   */
  async photoUploadsCompleteSaveTest(photoDocs: WithFieldValue<PhotoEnhanced>[]): Promise<void> {
    try {
      const photosPath = this.collectionsService.photosCol(this.workspaceId, this.inspectionId);
      const photoDocCountsChecker = await this.getCountFromServerService.getTestImagesCount(photosPath, this.itemId);
      this.testForm.patchValue({ photos_count: photoDocCountsChecker + photoDocs.length });
      await this.inspectionItemDetailService.savePhotoDocsAndItem(this.workspaceId, this.inspectionId, this.categoryId, this.itemId, this.testForm.value, this.user, photoDocs);
      this.togglePhotosUploadOverlay();
      this.resetPhotoValues();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Test
   */
  public async saveTest(): Promise<void> {
    try {
      await this.inspectionItemDetailService.saveItem(this.workspaceId, this.inspectionId, this.categoryId, this.itemId, this.testForm.value, this.testForm.value.answered, this.user, this.currentItem);
      this.resetFormState();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Question And Re Route
   */
  async saveQuestionAndReRoute(): Promise<void> {
    if (this.testForm.value.question_string == "") {
      this.testForm.patchValue({
        answered: false,
        question_string: null,
      });
    }

    try {
      await this.inspectionItemDetailService.saveItem(this.workspaceId, this.inspectionId, this.categoryId, this.itemId, this.testForm.value, this.testForm.value.answered, this.user, this.currentItem);
      const route = ["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items"];
      this.router.navigate(route);
      this.resetFormState();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Update Test Or Question
   */
  public updateTestOrQuestion(): void {
    if (this.testForm.value.mode === "test") {
      this.doPhotosNeedUpLoading();
    } else {
      this.saveQuestionAndReRoute();
    }
  }

  /**
   * Do Photos Need Uploading
   */
  public doPhotosNeedUpLoading(): void {
    const photos = this.uploadFileArraySubject.value;
    if (photos.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.saveTest();
    }
  }

  /**
   * Reset Photo Values
   */
  private resetPhotoValues(): void {
    this.testForm.markAsPristine();
    this.testForm.markAsUntouched();
    this.togglePhotoPreview();
    this.uploadFileArraySubject.next([]);
  }

  /**
   * Extract Tag Data
   * @param tags
   */
  private extractTagData(tags: ExifTags) {
    const createDate = tags.DateTime ? new Date(tags.DateTime.value[0]) : new Date();
    // const { formattedLatitude, formattedLongitude } = this.issueEditService.processGPSData(tags);
    return {
      createDate,
      // formattedLatitude,
      // formattedLongitude,
    };
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle Photos Upload Overlay
   */
  private togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Toggle Delete Photo Overlay
   */
  public toggleDeletePhotoOverlay(): void {
    this.overlay_deletePhoto = !this.overlay_deletePhoto;
  }

  /**
   * Reset Form State
   */
  private resetFormState(): void {
    this.testForm.markAsPristine();
    this.testForm.markAsUntouched();
    this.testForm.reset();
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.testForm.dirty ? false : true;
  }
}
