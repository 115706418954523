import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickedOutsideDropdownDirective } from 'src/app/core/directives/clicked-outside-dropdown.directive';
import { FolderEnhanced } from 'src/app/models/folder/folder.model';
import { SearchBarComponent } from 'src/app/shared/search-bar/search-bar.component';

@Component({
  selector: 'folders-list-header',
  standalone: true,
  imports: [JsonPipe, SearchBarComponent],
  templateUrl: './folders-list-header.component.html',
  styleUrl: './folders-list-header.component.scss',
  hostDirectives: [
    {
      directive: ClickedOutsideDropdownDirective,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldersListHeaderComponent {
  @Input({ required: true }) folders!: FolderEnhanced[];
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) currentlySelectedFolderSortChoice!: string;
  @Input({ required: true }) isSortOrderAscending!: boolean;
  @Input({ required: true }) sortDropdown!: boolean;
  @Output() filterChoiceOutput = new EventEmitter<string>();
  @Output() newFolderOutput = new EventEmitter<void>();
  @Output() sortDropdownOutput = new EventEmitter<boolean>();
  @Output() sortDropdownCloseOutput = new EventEmitter<void>();
  @Output() searchTermChange = new EventEmitter<string>();

  // Services

  // Properties
  public filterChoices = ['Title', 'Inspection Count', 'Date'];

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Can User Add Folder
   */
  public canUserAddFolder(): void {
    this.newFolderOutput.emit();
  }

  /**
   * Update Folders Array With Sort Selection
   * @param filterChoice
   */
  public updateFoldersArrayWithSortSelection(filterChoice: string): void {
    this.filterChoiceOutput.emit(filterChoice);
  }

  /**
   * Toggle Sort Dropdown
   * Triggered by clicking the sort button
   */
  public toggleSortDropdown(): void {
    this.sortDropdownOutput.emit();
  }

  /**
   * Close Sort Dropdown
   * Trigger by clicking outside the element
   * TODO
   */
  public closeSortDropdown() {}
}
