import { DatePipe, JsonPipe, NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "folder-edit-wrapper",
  standalone: true,
  imports: [JsonPipe, NgClass, NgStyle, ReactiveFormsModule, DatePipe],
  templateUrl: "./folder-edit-wrapper.component.html",
  styleUrl: "./folder-edit-wrapper.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderEditWrapperComponent {
  @Input({ required: true }) folderForm!: FormGroup;
  @Input({ required: true }) isNewFolder!: boolean;
  @Input({ required: true }) limitationResult!: boolean;
  @Output() deleteFolderOutput = new EventEmitter<void>();
  @Output() saveFolderOutput = new EventEmitter<void>();
  @Output() toggleEditFolderOverlayOutput = new EventEmitter<void>();
  public folderColours = ["#2F8559", "#2C7A7A", "#2B6DB0", "#4B52BF", "#6C46C1", "#B83380", "#B7781F", "#FF6B19", "#C4302F", "#4B5667"];

  /**
   * Save Folder
   */
  public saveFolder(): void {
    this.saveFolderOutput.emit();
  }

  /**
   * Delete Folder
   */
  public deleteFolder(): void {
    this.deleteFolderOutput.emit();
  }

  /**
   * Toggle Edit Folder Overlay
   */
  public toggleEditFolderOverlay(): void {
    this.toggleEditFolderOverlayOutput.emit();
  }
}
