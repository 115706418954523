<main class="overflow-hidden bg-gray-50">
  <div class="relative mx-auto max-w-7xl">
    <div class="absolute -right-60 -top-44 h-60 w-[36rem] rotate-[-10deg] transform-gpu rounded-full bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] blur-3xl md:right-0"></div>
  </div>
  <div class="isolate flex min-h-dvh items-center justify-center p-6 lg:p-8">
    @if (!hasEmailBeenSent) {
      <div class="w-full max-w-md rounded-xl bg-white shadow-md ring-1 ring-black/5">
        <form [formGroup]="signUpForm" class="p-7 sm:p-11">
          <h1 class="text-base/6 font-medium">Welcome back!</h1>
          <p class="mt-1 text-sm/5 text-gray-600">Sign in to your account to continue.</p>
          <div class="mt-8 space-y-3">
            <div class="form-input-wrapper bg-white">
              <label for="email" class="form-input-label">Email address</label>
              <input #email type="email" name="email" id="email" placeholder="Email" formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" class="form-input" />
            </div>
          </div>

          <div class="mt-8">
            <button
              type="button"
              (click)="checkFormValidations()"
              class="inline-flex w-full items-center justify-center whitespace-nowrap rounded-full border border-transparent bg-brand_Primary px-4 py-[calc(theme(spacing.2)-1px)] text-base font-medium text-white shadow-md data-[disabled]:bg-gray-950 data-[hover]:bg-gray-800 data-[disabled]:opacity-40">
              Sign in
            </button>
          </div>
        </form>
        <div class="m-1.5 rounded-lg bg-gray-50 py-4 text-center text-sm/5 ring-1 ring-black/5">
          Not a member?
          <a class="pl-1 font-medium hover:text-gray-600" [routerLink]="['/', 'signup']">Start your 7-day free trial</a>
        </div>
      </div>
    } @else {
      <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <p class="text-center text-sm text-gray-600">We have sent you an email to verify your account. Please check your inbox.</p>
      </div>
    }
  </div>
</main>
