import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "test-poor-fair-good",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: "./test-poor-fair-good.component.html",
  styleUrl: "./test-poor-fair-good.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestPoorFairGoodComponent {
  @Input({ required: true }) testForm!: FormGroup;
  public answerType = [
    { title: "Poor", value: "poor" },
    { title: "Fair", value: "fair" },
    { title: "Good", value: "good" },
  ];
}
