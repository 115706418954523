<form [formGroup]="itemForm" class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block h-[756px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold capitalize leading-6 text-text_Title" id="modal-title">New {{ type }}</h3>
        </div>

        <div class="flex h-full w-full flex-col overflow-hidden">
          <div class="w-full flex-1 overflow-y-scroll">
            <div class="grid grid-cols-1 gap-y-8 px-6 py-8">
              <div class="content-card-wrapper">
                <div class="px-6 py-5">
                  <div class="content-grid gap-y-6">
                    <!-- Info Views -->

                    @if (type === "test") {
                      <info-view [type]="infoViewItemEditorTest.type" [message]="infoViewItemEditorTest.message" />
                    }
                    @if (type === "question") {
                      <info-view [type]="infoViewItemEditorQuestion.type" [message]="infoViewItemEditorQuestion.message" />
                    }

                    <!-- Title -->
                    <div class="col-span-6">
                      <div class="form-input-wrapper">
                        <label for="item-title" class="form-input-label"
                          >Title*
                          @if (!itemForm.pristine && itemForm.controls["title"].errors) {
                            <span class="required-field"> - Item title is required</span>
                          }
                        </label>
                        <input type="text" name="item-title" id="item-title" class="form-input" placeholder="Title" formControlName="title" />
                      </div>
                    </div>

                    <!-- Test Choices -->
                    @if (type === "test") {
                      <test-types [itemForm]="itemForm" />
                    }

                    <!-- Question Choices -->
                    @if (type === "question") {
                      <question-types [itemForm]="itemForm" />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Form State Bottom -->
          <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
            <button
              type="button"
              (click)="saveItemAndReroute()"
              aria-label="Save Text"
              class="btn ml-3"
              [disabled]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors"
              [ngClass]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors ? 'btn-disabled' : 'btn-primary'">
              Save
            </button>
            <button
              type="button"
              class="btn ml-3"
              (click)="saveItemAndAddAnother()"
              aria-label="Save and Add Another Test"
              [disabled]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors"
              [ngClass]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors ? 'btn-disabled' : 'btn-secondary'">
              {{ type === "test" ? "Save and Add Another Test" : "Save and Add Another Question" }}
            </button>
            <button
              type="button"
              class="btn ml-3"
              (click)="saveItemAndAddDifferentMode()"
              aria-label="Save and"
              [disabled]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors"
              [ngClass]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors ? 'btn-disabled' : 'btn-secondary'">
              {{ type === "test" ? "Save and Add a Question" : "Save and Add a Test" }}
            </button>

            <button type="button" class="btn btn-cancel" aria-label="Cancel New Category" (click)="toggleNewItem()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
