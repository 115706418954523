import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ThemeStyleType } from "cip";
import { ThemeStyleEnhanced } from "src/app/models/theme/theme-style.model";
import { ThemeStylesAndSettingsService } from "../../../../services/theme-styles-and-settings.service";

@Component({
  selector: "manage-theme-edit-settings-theme-style-category",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./manage-theme-edit-settings-theme-style-category.component.html",
  styleUrl: "./manage-theme-edit-settings-theme-style-category.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsThemeStyleCategoryComponent {
  @Input() themeSettingsForm!: FormGroup;
  @Input() themeStylesByType: { [key in ThemeStyleType]?: ThemeStyleEnhanced[] } = {};
  private themeStylesAndSettingsService = inject(ThemeStylesAndSettingsService);

  updateCustomThemeStyles() {
    this.themeStylesAndSettingsService.clearStyles("styles_categoryCSS");
    const { styles_category } = this.themeSettingsForm.value;
    const allThemeStyles = Object.values(this.themeStylesByType).flatMap((typeArray) => typeArray);
    for (const data of allThemeStyles) {
      if (styles_category === data.id) {
        this.themeStylesAndSettingsService.applyCategoryStyles(data.css);
      }
    }
  }
}
