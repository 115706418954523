// Generated by CoffeeScript 2.4.1
(function () {
  var NodeType, XMLAttribute, XMLNode;
  NodeType = require('./NodeType');
  XMLNode = require('./XMLNode');

  // Represents an attribute
  module.exports = XMLAttribute = function () {
    class XMLAttribute {
      // Initializes a new instance of `XMLAttribute`

      // `parent` the parent node
      // `name` attribute target
      // `value` attribute value
      constructor(parent, name, value) {
        this.parent = parent;
        if (this.parent) {
          this.options = this.parent.options;
          this.stringify = this.parent.stringify;
        }
        if (name == null) {
          throw new Error("Missing attribute name. " + this.debugInfo(name));
        }
        this.name = this.stringify.name(name);
        this.value = this.stringify.attValue(value);
        this.type = NodeType.Attribute;
        // DOM level 3
        this.isId = false;
        this.schemaTypeInfo = null;
      }

      // Creates and returns a deep clone of `this`
      clone() {
        return Object.create(this);
      }

      // Converts the XML fragment to string

      // `options.pretty` pretty prints the result
      // `options.indent` indentation for pretty print
      // `options.offset` how many indentations to add to every line for pretty print
      // `options.newline` newline sequence for pretty print
      toString(options) {
        return this.options.writer.attribute(this, this.options.writer.filterOptions(options));
      }

      // Returns debug string for this node
      debugInfo(name) {
        name = name || this.name;
        if (name == null) {
          return "parent: <" + this.parent.name + ">";
        } else {
          return "attribute: {" + name + "}, parent: <" + this.parent.name + ">";
        }
      }
      isEqualNode(node) {
        if (node.namespaceURI !== this.namespaceURI) {
          return false;
        }
        if (node.prefix !== this.prefix) {
          return false;
        }
        if (node.localName !== this.localName) {
          return false;
        }
        if (node.value !== this.value) {
          return false;
        }
        return true;
      }
    }
    ;

    // DOM level 1
    Object.defineProperty(XMLAttribute.prototype, 'nodeType', {
      get: function () {
        return this.type;
      }
    });
    Object.defineProperty(XMLAttribute.prototype, 'ownerElement', {
      get: function () {
        return this.parent;
      }
    });

    // DOM level 3
    Object.defineProperty(XMLAttribute.prototype, 'textContent', {
      get: function () {
        return this.value;
      },
      set: function (value) {
        return this.value = value || '';
      }
    });

    // DOM level 4
    Object.defineProperty(XMLAttribute.prototype, 'namespaceURI', {
      get: function () {
        return '';
      }
    });
    Object.defineProperty(XMLAttribute.prototype, 'prefix', {
      get: function () {
        return '';
      }
    });
    Object.defineProperty(XMLAttribute.prototype, 'localName', {
      get: function () {
        return this.name;
      }
    });
    Object.defineProperty(XMLAttribute.prototype, 'specified', {
      get: function () {
        return true;
      }
    });
    return XMLAttribute;
  }.call(this);
}).call(this);