<div class="content-card-wrapper">
  <div class="content-card">
    <div class="content-grid gap-y-6">
      <div class="list-a">
        @if (workspaceToLeave.image_thumbnail_url) {
          <div class="flex h-12 w-12 rounded-md bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + workspaceToLeave.image_thumbnail_url + ')' }"></div>
        } @else {
          <div class="flex h-12 w-12 items-center justify-center rounded bg-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
              />
            </svg>
          </div>
        }
        <div class="flex w-full flex-col gap-x-4 sm:flex-row">
          <div class="min-w-0 flex-auto">
            <p class="list-title">{{ workspaceToLeave.name }}</p>
            <p class="list-meta">{{ workspaceToLeave.current_owner }}</p>
          </div>
        </div>
      </div>
      <info-view [type]="infoViewLeaveWorkspace1.type" [message]="infoViewLeaveWorkspace1.message" />
      <info-view [type]="infoViewLeaveWorkspace2.type" [message]="infoViewLeaveWorkspace2.message" />
      <label for="leaveWorkspace" class="flex cursor-pointer flex-row">
        <div class="flex h-5">
          <input id="leaveWorkspace" aria-describedby="leaveWorkspace" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" (change)="onIsConfirmLeaveCheckedChange($event)" />
        </div>
        <div class="ml-3 text-sm">
          <span class="form-input-label">Leave Workspace?</span>
        </div>
      </label>
    </div>
  </div>
</div>
