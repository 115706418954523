import { inject, Injectable } from "@angular/core";
import { Assignee, User } from "cip";
import { deleteField, WithFieldValue } from "firebase/firestore";
import { catchError, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { AssigneeEnhanced } from "src/app/models/assignee/assignee.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class AssigneeDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Assignee Doc
   * @param workspaceId
   * @param assigneeId
   */
  public getAssigneeDoc$(workspaceId: string, assigneeId: string): Observable<AssigneeEnhanced> {
    if (assigneeId === "new") {
      return of({} as AssigneeEnhanced);
    }
    const path = this.collectionsService.assigneesCol(workspaceId);
    const doc = `${path}/${assigneeId}`;
    return this.firestoreUtilsService.getDocumentData<AssigneeEnhanced>(doc).pipe(
      map((data) => data || ({} as AssigneeEnhanced)),
      catchError((error) => {
        return of({} as AssigneeEnhanced);
      })
    );
  }

  /**
   * Save Assignee Doc
   * @param workspaceId
   * @param assigneeId
   * @param assigneeForm
   * @param user
   */
  public async saveAssigneeDoc(workspaceId: string, assigneeId: string, assigneeForm: AssigneeEnhanced, user: User): Promise<AssigneeEnhanced> {
    const path = this.collectionsService.assigneesCol(workspaceId);
    const assigneeIdTouse = assigneeId === "new" ? this.firestoreUtilsService.createFirestoreId() : assigneeId;
    const documentPath = `${path}/${assigneeIdTouse}`;
    const writeEventType = assigneeId === "new" ? "added" : "changed";
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const assignee: WithFieldValue<Assignee> = {
      name: assigneeForm.name.trim(),
      email: assigneeForm.email === "" || assigneeForm.email === null || assigneeForm.email === undefined ? deleteField() : assigneeForm.email.trim(),
      company: assigneeForm.company === "" || assigneeForm.company === null ? deleteField() : assigneeForm.company,
      created_by_id: assigneeForm.created_by_id ?? user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };
    await this.firestoreUtilsService.setPartialDocumentData<AssigneeEnhanced>(documentPath, assignee);
    return { id: assigneeId, ...(assignee as Assignee) };
  }

  /**
   * Delete Assignee Doc
   * @param workspaceId
   * @param assigneeId
   */
  public async deleteAssigneeDoc(workspaceId: string, assigneeId: string, user: User): Promise<void> {
    const path = this.collectionsService.assigneesCol(workspaceId);
    const doc = `${path}/${assigneeId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const obj = { is_deleted: true, last_event: lastEvent };
    return this.firestoreUtilsService.setDocumentData(doc, obj);
  }

  /**
   * Delete Batched
   * @param workspaceId
   * @param batchedAssignees
   */
  async deleteBatchedAssignees(workspaceId: string, batchedAssignees: AssigneeEnhanced[], user: User): Promise<void> {
    const path = this.collectionsService.assigneesCol(workspaceId);
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const operations: BatchOperation[] = [];

    batchedAssignees.forEach((assignee) => {
      const doc = `${path}/${assignee.id}`;
      operations.push({
        type: "update",
        documentPath: doc,
        data: { last_event: lastEvent, is_deleted: true },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }
}
