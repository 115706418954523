<div class="flex justify-between p-6">
  <button
    (click)="navigateToPreviousItem()"
    type="button"
    class="pr-2 text-brand_Primary hover:bg-brand_Primary hover:text-white"
    aria-label="Next Item"
    [disabled]="currentItem.order === 0"
    [ngClass]="{
      'btn-tertiary': currentItem.order !== 0,
      invisible: currentItem.order === 0,
    }"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
    </svg>

    {{ currentItem.order !== 0 ? currentCategoryOrder + '.' + currentItem.order : '' }}
  </button>

  <button
    (click)="navigateToNextItem()"
    type="button"
    class="pl-2 text-brand_Primary hover:bg-brand_Primary hover:text-white"
    aria-label="Next Item"
    [disabled]="currentItem.order === totalItems - 1"
    [ngClass]="{
      'btn-tertiary': currentItem.order !== totalItems - 1,
      invisible: currentItem.order === totalItems - 1,
    }"
  >
    {{ currentItem.order !== totalItems - 1 ? currentCategoryOrder + '.' + (currentItem.order + 2) : '' }}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  </button>
</div>
