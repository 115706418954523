@if (themeSettingsForm.value.photos_included) {
  <div class="photo-wrapper" [ngClass]="'photo-' + themeSettingsForm.value.photos_size + (themeSettingsForm.value.photos_squared ? ' square-photos' : '')">
    @for (image of imageUrls; track $index; let i = $index) {
      <div class="photo-content">
        <div class="photo">
          <img [src]="image.url" [ngClass]="{ squareImg: themeSettingsForm.value.photos_squared }" />
        </div>
        <div class="photo-data-wrapper">
          <p class="photo-order">{{ i + 1 }}</p>
          @if (themeSettingsForm.value.photos_timestamps) {
            <div class="photo-timestamp-wrapper">
              <p class="photo-time" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">{{ themeStylesAndSettingsSerivce.demoDate | date: "h:mm:ss a" }}</p>
              <p class="photo-timestamp" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">
                {{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}
              </p>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
