import { inject, Injectable } from "@angular/core";
import { Folder, User, WriteEvent, WriteEventType } from "cip";
import { serverTimestamp, WithFieldValue } from "firebase/firestore";
import { catchError, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { FolderEnhanced } from "src/app/models/folder/folder.model";

@Injectable({
  providedIn: "root",
})
export class FolderDetailService {
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Delete Folder Doc
   * @param folderId
   * @param userId
   */
  async setFolderDoc(user: User, workspaceId: string, folderForm: FolderEnhanced, writeEventType: WriteEventType) {
    const path = this.collectionsService.foldersCol(workspaceId);
    const folderId = folderForm.id ?? this.firestoreUtilsService.createFirestoreId();
    const documentPath = `${path}/${folderId}`;
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const folderObj = this.createFolderObject(folderForm, user, lastEvent);
    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Folder>(documentPath, folderObj);
    return { ...folderForm, id: folderId };
  }

  /**
   * Get Folder Doc
   * @param workspaceId
   * @param folderId
   */
  public getFolderDoc$(workspaceId: string, folderId: string): Observable<FolderEnhanced | null> {
    const path = `${this.collectionsService.foldersCol(workspaceId)}/${folderId}`;
    return this.firestoreUtilsService.getDocumentData<FolderEnhanced>(path).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Create Folder Object
   * @param folderForm
   * @param user
   * @param lastEvent
   */
  createFolderObject(folderForm: Folder, user: User, lastEvent: WithFieldValue<WriteEvent>): WithFieldValue<Folder> {
    const newFolder: WithFieldValue<Folder> = {
      title: folderForm.title.trim(),
      colour: folderForm.colour,
      inspections_count: folderForm.inspections_count ?? 0,
      created_by: folderForm.created_by ?? `${user.name_first} ${user.name_last}`,
      created_by_id: folderForm.created_by_id ?? user.user_id,
      date_created: folderForm.date_created ?? serverTimestamp(),
      is_deleted: lastEvent.type === "deleted" ? true : false,
      last_event: lastEvent,
    };
    return newFolder;
  }
}
