import { JsonPipe, NgStyle } from "@angular/common";
import { Component, inject } from "@angular/core";
import { ChartsService } from "src/app/sections/inspections/services/charts.service";
import { ChartsActionsComponent } from "src/app/shared/charts/charts-actions/charts-actions.component";
import { ChartsAssigneesComponent } from "src/app/shared/charts/charts-assignees/charts-assignees.component";
import { ChartsOverallComponent } from "src/app/shared/charts/charts-overall/charts-overall.component";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-content-charts",
  standalone: true,
  imports: [NgStyle, ChartsOverallComponent, ChartsAssigneesComponent, ChartsActionsComponent, JsonPipe],
  templateUrl: "./report-content-charts.component.html",
  styleUrl: "./report-content-charts.component.scss",
})
export class ReportContentChartsComponent {
  public reportsService = inject(ReportsService);
  public chartsService = inject(ChartsService);
}
