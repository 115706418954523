@if (categories$ | async; as categories) {
  <manage-template-categories-list-header
    [categories]="categories"
    [workspaceId]="workspaceId"
    [templateId]="templateId"
    [editMode]="editMode"
    [categoriesBulkEditArray]="categoriesBulkEditArray$ | async"
    (searchTermChange)="searchTermChange($event)"
    (toggleNewCategoryOutput)="limitationManagerCheck()"
    (toggleEditModeOutput)="toggleEditMode()"
    (bulkDeleteCategoriesOutput)="setDeleteBatchCategoriesProperties()"
    (bulkEnableOrDisableCategoriesOutput)="toggleEnableDisableCategoryOverlay()" />

  @if (categories.length > 0) {
    <manage-template-categories-list
      [categories]="categories"
      [editMode]="editMode"
      [searchTerm]="currentSearchTerm"
      (routeToCategoryOrItemsOutput)="routeToCategoryEditorOrItems($event)"
      (reorderCategoriesOutput)="reorderCategories($event)"
      (deleteSingleCategoryOutput)="setDeleteSingleCategoryProperties($event)"
      (createBatchEditOutput)="createBatchEdit($event)"
      (bulkDeleteCategoriesOutput)="setDeleteBatchCategoriesProperties()"
      (toggleDuplicateOverlayOutput)="toggleAndSetDuplicateCategoryOverlay($event)" />
  } @else {
    <empty-list [list]="'category'" />
  }
} @else {
  <loading-spinner />
}

@if (overlay_deleteCategory) {
  <delete-overlay [deleteOverlayTitle]="deleteTitle" [deleteOverlayDescription]="deleteDescription" (cancelButtonClicked)="toggleDeleteCategoryOverlay()" (deleteButtonClicked)="deleteSingleOrMultipleCategories()" />
}

@if (duplicationService.overlay_duplicate) {
  <duplicate-overlay
    [duplicationObject]="duplicationObject"
    [originalDocumentTitle]="categoryToBeDuplicated.title + ' ' + '(Copy)'"
    [duplicationTitle]="duplicationTitle"
    [duplicationDescription]="duplicationDescription"
    [inProgressText]="inProgressText"
    [successText]="successText"
    [failedText]="failedText"
    [navigateToText]="navigateToText"
    (duplicateEmitter)="duplicateCategory($event)"
    (navigateToNewDocEmitter)="navigateToNewCategory()" />
}

@if (overlay_enableDisableCategory) {
  <enable-disable-overlay [overlayTitle]="enableDisableTitle" [overlayDescription]="enableDisableDescription" (enabled)="enableDisableCategory($event)" (cancelButtonClicked)="toggleEnableDisableCategoryOverlay()" />
}

@if (overlay_newCategory) {
  <manage-template-category-new-overlay
    [categoryForm]="categoryForm"
    (saveCategoryAndRerouteOutput)="saveCategoryAndReroute()"
    (saveCategoryAndAddAnotherOutput)="saveCategoryAndAddAnother()"
    (toggleNewCategoryOverlayOutput)="toggleNewCategoryOverlay()" />
}
