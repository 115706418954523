import { inject, Injectable } from "@angular/core";
import { Action, User, WriteEvent } from "cip";
import { deleteField, increment, WithFieldValue } from "firebase/firestore";
import { catchError, firstValueFrom, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { GetCountFromServerService } from "src/app/core/services/counts/get-count-from-server.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { ActionEnhanced } from "src/app/models/action/action.model";
import { BatchOperation } from "src/app/models/utils/batch";
import { InspectionCategoryDetailService } from "../../categories/services/inspection-category-detail.service";
import { InspectionDetailService } from "../../inspections/services/inspection-detail.service";
import { InspectionItemDetailService } from "../../items/services/inspection-item-detail.service";
import { ActionsListService } from "./actions-list.service";

@Injectable({
  providedIn: "root",
})
export class ActionDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);
  private getCountFromServerService = inject(GetCountFromServerService);
  private inspectionCategoryDetailService = inject(InspectionCategoryDetailService);
  private inspectionItemDetailService = inject(InspectionItemDetailService);
  private inspectionDetailService = inject(InspectionDetailService);
  private actionsListService = inject(ActionsListService);

  /**
   * Get action Doc
   * @param workspaceId
   * @param actionId
   * @returns
   */
  public getActionDoc$(workspaceId: string, actionId: string): Observable<ActionEnhanced> {
    if (actionId === "new") {
      return of({} as ActionEnhanced);
    }
    const path = `${this.collectionsService.actionsCol(workspaceId)}/${actionId}`;
    return this.firestoreUtilsService.getDocumentData<ActionEnhanced>(path).pipe(
      map((data) => data || ({} as ActionEnhanced)),
      catchError((error) => {
        return of({} as ActionEnhanced);
      })
    );
  }

  public async saveActionDoc(user: User, workspaceId: string, inspectionId: string, categoryId: string, itemId: string, actionId: string, actionForm: ActionEnhanced): Promise<void> {
    const actionIdToUse = actionId === "new" ? this.firestoreUtilsService.createFirestoreId() : actionId;
    const writeEventType = actionId === "new" ? "added" : "changed";

    // Define collection paths
    const actionsPath = this.collectionsService.actionsCol(workspaceId);
    const itemsPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const actionDoc = `${actionsPath}/${actionIdToUse}`;
    const itemDoc = `${itemsPath}/${itemId}`;

    const category = await firstValueFrom(this.inspectionCategoryDetailService.getCategoryDoc$(workspaceId, inspectionId, categoryId));
    const item = await firstValueFrom(this.inspectionItemDetailService.getItemDoc$(workspaceId, inspectionId, itemId));
    const inspection = await firstValueFrom(this.inspectionDetailService.getInspectionDoc$(workspaceId, inspectionId));
    const actionCount = await this.getCountFromServerService.getItemActionsCount(actionsPath, itemId);
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);

    // First validate all required data is present
    if (!inspection || !item || !category) {
      throw new Error(`Unable to save action - missing required data: ${[!inspection && "inspection", !item && "item", !category && "category"].filter(Boolean).join(", ")}`);
    }

    const action = this.createActionObject(user, actionForm, inspection.id, inspection.title, item.id, item.title, category.id, category.title, actionCount, lastEvent);

    const operations: BatchOperation[] = [];

    if (actionId === "new") {
      operations.push({
        type: "update",
        documentPath: itemDoc,
        data: { actions_count: increment(+1) },
      });
    }

    operations.push({
      type: "set",
      documentPath: actionDoc,
      data: action,
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Create Action Object
   * @param user
   * @param actionForm
   * @param inspectionId
   * @param inspectionTitle
   * @param itemId
   * @param itemTitle
   * @param categoryId
   * @param categoryTitle
   * @param actionCount
   * @param lastEvent
   */
  createActionObject(
    user: User,
    actionForm: ActionEnhanced,
    inspectionId: string,
    inspectionTitle: string,
    itemId: string,
    itemTitle: string,
    categoryId: string,
    categoryTitle: string,
    actionCount: number,
    lastEvent: WithFieldValue<WriteEvent>
  ): WithFieldValue<Action> {
    return {
      title: actionForm.title.trim(),
      inspection_id: inspectionId,
      inspection_title: inspectionTitle,
      notes: actionForm.notes === null || actionForm.notes === undefined || actionForm.notes === "" ? deleteField() : actionForm.notes.trim(),
      order: actionForm.order === null ? actionCount : actionForm.order,
      priority: actionForm.priority === null ? deleteField() : actionForm.priority,
      due_date: actionForm.due_date === undefined || actionForm.due_date === null ? deleteField() : actionForm.due_date,
      complete: actionForm.complete ? actionForm.complete : false,
      assignee_name: actionForm.assignee_name === null ? deleteField() : actionForm.assignee_name,
      assignee_email: actionForm.assignee_email === null ? deleteField() : actionForm.assignee_email,
      assignee_id: actionForm.assignee_id === null ? deleteField() : actionForm.assignee_id,
      assignee_type: actionForm.assignee_type === null ? deleteField() : actionForm.assignee_type,
      assignee_company: actionForm.assignee_company === null ? deleteField() : actionForm.assignee_company,
      item_id: itemId,
      item_title: itemTitle,
      category_id: categoryId,
      category_title: categoryTitle,
      created_by_id: actionForm.created_by_id ?? user.user_id,
      is_deleted: lastEvent.type === "deleted" ? true : false,
      last_event: lastEvent,
    };
  }

  /**
   * Delete Action Doc
   * @param user
   * @param workspaceId
   * @param inspectionId
   * @param itemId
   * @param actionId
   * @param allActions
   */
  async deleteActionDoc(user: User, workspaceId: string, inspectionId: string, itemId: string, actionId: string): Promise<void> {
    const lastEvent = this.lastEventService.lastEvent("deleted", user);

    // Define collection paths
    const actionPath = this.collectionsService.actionsCol(workspaceId);
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const actionDoc = `${actionPath}/${actionId}`;
    const itemDoc = `${itemPath}/${itemId}`;

    const allActions = await firstValueFrom(this.actionsListService.getItemActions$(workspaceId, itemId));

    // Initialize batch operations array
    const operations: BatchOperation[] = [];

    // Filter actions and update order for remaining ones
    const remainingActions = allActions.filter((existingAction) => existingAction.id !== actionId);
    remainingActions.forEach((remainingAction, index) => {
      operations.push({
        type: "update",
        documentPath: `${actionPath}/${remainingAction.id}`,
        data: { order: index },
      });
    });

    // Add operation to mark action as deleted
    operations.push({
      type: "update",
      documentPath: actionDoc,
      data: {
        is_deleted: true,
        last_event: lastEvent,
      },
    });

    // Add operation to decrement actions count
    operations.push({
      type: "update",
      documentPath: itemDoc,
      data: {
        actions_count: increment(-1),
      },
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }
}
