import { inject, Injectable } from '@angular/core';
import { fetchAndActivate, getValue, RemoteConfig } from '@angular/fire/remote-config';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigService {
  private remoteConfig: RemoteConfig;

  constructor() {
    this.remoteConfig = inject(RemoteConfig);
  }

  public async getRemoteConfig(remoteConfigKey: string): Promise<number | null> {
    try {
      // Fetch and activate the latest remote config values
      await fetchAndActivate(this.remoteConfig);

      // Get the value for the given key
      const value = getValue(this.remoteConfig, remoteConfigKey);
      return value.asNumber();
    } catch (err) {
      console.error('Error fetching remote config: ', err);
      return null;
    }
  }
}
