<form [formGroup]="clientForm" class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
  <div class="sm:col-span-1">
    <div class="form-input-wrapper">
      <label for="client-name" class="form-input-label"
        >Name*
        @if (!clientForm.pristine && clientForm.controls["name"].errors) {
          <span class="required-field"> - Name is required</span>
        }
      </label>
      <input type="text" name="client-name" id="client-name" class="form-input" placeholder="Name" formControlName="name" />
    </div>
  </div>
  <div class="sm:col-span-1">
    <div class="form-input-wrapper">
      <label for="client-branch" class="form-input-label">Branch</label>
      <input type="text" name="client-branch" id="client-branch" class="form-input" placeholder="Branch" formControlName="branch" />
    </div>
  </div>
</form>
