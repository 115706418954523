import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { combineLatest, filter, Observable, switchMap, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";
import { RoleCurrent, RoleDefault, RoleEnhanced } from "src/app/models/role/role.model";
import { AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { PermissionsGridComponent } from "src/app/shared/permissions/permissions-grid/permissions-grid.component";
import { RoleFieldComponent } from "src/app/shared/roles/role-field/role-field.component";
import { RoleSelectorWrapperComponent } from "src/app/shared/roles/role-selector-wrapper/role-selector-wrapper.component";
import { RoleDetailService } from "../../../../manage-roles/services/role-detail.service";
import { RolesListService } from "../../../../manage-roles/services/roles-list.service";
import { InviteDetailService } from "../../../services/invite-detail.service";
import { ManageMembersEditInviteDetailsComponent } from "../manage-members-edit-invite-details/manage-members-edit-invite-details.component";
import { ManageMembersEditInviteHeaderComponent } from "../manage-members-edit-invite-header/manage-members-edit-invite-header.component";

@Component({
  selector: "manage-members-edit-invite-wrapper",
  standalone: true,
  imports: [
    FormStateComponent,
    NgClass,
    InfoViewComponent,
    ManageMembersEditInviteHeaderComponent,
    ManageMembersEditInviteDetailsComponent,
    PermissionsGridComponent,
    AsyncPipe,
    JsonPipe,
    RoleFieldComponent,
    RoleSelectorWrapperComponent,
    DeleteOverlayComponent,
  ],
  templateUrl: "./manage-members-edit-invite-wrapper.component.html",
  styleUrl: "./manage-members-edit-invite-wrapper.component.scss",
})
export class ManageMembersEditInviteWrapperComponent implements OnInit {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set inviteId(value: string) {
    this._inviteId = value;
  }
  get inviteId(): string {
    return this._inviteId;
  }

  // Services
  private authService = inject(AuthService);
  private formFormattingService = inject(FormFormattingService);
  private fb = inject(FormBuilder);
  private infoViewStringsService = inject(InfoViewStringsService);
  private inviteDetailService = inject(InviteDetailService);
  private activatedRoute = inject(ActivatedRoute);
  private rolesListService = inject(RolesListService);
  private roleDetailService = inject(RoleDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private router = inject(Router);
  private stringsService = inject(StringsService);

  // Properties
  private _workspaceId!: string;
  private _inviteId!: string;
  private user!: User;
  public inviteForm: FormGroup;
  public roleForm: FormGroup;
  public overlay_roleSelector: boolean = false;
  public invite$!: Observable<InviteEnhanced>;
  public roles$!: Observable<RoleEnhanced[]>;
  public combinedData$!: Observable<{ invite: InviteEnhanced; roles: RoleEnhanced[] }>;
  public customRoles: RoleEnhanced[] = [];
  public currentRole: RoleCurrent | null = null;
  public infoViewMemberEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.MemberEditor);
  public infoViewPermissionsEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.PermissionsEditor);
  public infoViewInviteMember: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.InviteEditorMember);
  public memberDoc = this.limitationManagerService.memberDoc.value;
  public defaultRoles = this.rolesListService.defaultRoles;
  public overlay_roleForm: boolean = false;
  public overlay_revokeInvite = signal<boolean>(false);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";

  constructor() {
    // User
    this.user = this.authService.currentUser;

    // Form
    this.inviteForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      date_invited: null,
      email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), this.formFormattingService.noWhitespaceValidator()]),
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      role_id: ["", Validators.required],
      role_title: ["", Validators.required],
      workspace_id: null,
      workspace_name: null,
      workspace_invited_by: null,
      workspace_invited_by_id: null,
      status: "pending",
      created_by: null,
      created_by_id: null,
      permissions: this.fb.group({
        action_create_update: null,
        action_delete: null,
        assignee_create_update: null,
        assignee_delete: null,
        category_create_update: null,
        category_delete: null,
        client_create_update: null,
        client_delete: null,
        folder_create_update: null,
        folder_delete: null,
        inspection_create_update: null,
        inspection_delete: null,
        invite_create_update: null,
        invite_delete: null,
        item_create_update: null,
        item_delete: null,
        member_update: null,
        member_delete: null,
        photo_create_update: null,
        photo_delete: null,
        role_create_update: null,
        role_delete: null,
        signature_create_update: null,
        signature_delete: null,
        site_create_update: null,
        site_delete: null,
        template_create_update: null,
        template_delete: null,
        theme_create_update: null,
        theme_delete: null,
        workspace_update: null,
      }),
    });

    this.roleForm = this.fb.group({
      id: [{ value: null, disabled: null }],
      title: ["", Validators.required],
      description: ["", Validators.required],
      type: null,
      created_by: null,
      created_by_id: null,
      permissions: this.fb.group({
        action_create_update: false,
        action_delete: false,
        assignee_create_update: false,
        assignee_delete: false,
        category_create_update: false,
        category_delete: false,
        client_create_update: false,
        client_delete: false,
        folder_create_update: false,
        folder_delete: false,
        inspection_create_update: false,
        inspection_delete: false,
        invite_create_update: false,
        invite_delete: false,
        item_create_update: false,
        item_delete: false,
        member_update: false,
        member_delete: false,
        photo_create_update: false,
        photo_delete: false,
        role_create_update: false,
        role_delete: false,
        signature_create_update: false,
        signature_delete: false,
        site_create_update: false,
        site_delete: false,
        template_create_update: false,
        template_delete: false,
        theme_create_update: false,
        theme_delete: false,
        workspace_update: false,
      }),
    });
  }

  ngOnInit(): void {
    this.setInviteFormQueryParams();
    // this.getRolesList();
    // this.getInviteDoc();
    this.initializeCombinedData();
  }

  private initializeCombinedData(): void {
    // First get roles
    this.roles$ = this.rolesListService.getRolesList$(this.workspaceId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.customRoles = [];
        data.forEach((element) => {
          this.customRoles.push(element);
        });
      })
    );

    this.invite$ = this.roles$.pipe(
      switchMap(() => this.inviteDetailService.getInviteDoc$(this.inviteId)),
      filter((data) => !!data),
      tap((data) => {
        if (this.inviteId !== "new" && this.inviteForm.pristine && this.inviteForm.untouched) {
          this.inviteForm.patchValue(data);
          this.currentRole = this.getCurrentRole(data.role_id);
          this.inviteForm.patchValue({
            permissions: this.currentRole?.permissions,
          });
        }
      })
    );

    // Combine both observables
    this.combinedData$ = combineLatest({
      invite: this.invite$,
      roles: this.roles$,
    });
  }

  /**
   * Set Invite Form Query Params
   * Used when inviting from an assignee editor
   */
  private setInviteFormQueryParams(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const name = queryParams["name"];
    const email = queryParams["email"];

    if (name || email) {
      this.inviteForm.patchValue({
        name: name,
        email: email,
      });
    }
  }

  /**
   * Get Current Role
   * @param roleId
   */
  getCurrentRole(roleId: string): RoleCurrent | null {
    const allRoles = [...this.rolesListService.defaultRoles, ...this.customRoles];
    const role = allRoles.find((obj) => obj.id === roleId);
    return role ?? null;
  }

  /**
   * Select Default Role
   * @param role
   */
  public selectRole(role: RoleDefault): void {
    this.inviteForm.patchValue({
      role_id: role.id,
      role_title: role.title,
      permissions: role.permissions,
    });
    this.inviteForm.markAsDirty();
    this.toggleRoleSelectorOverlay();
    this.currentRole = role;
  }

  /**
   * Save Invite
   */
  public async saveInvite(): Promise<void> {
    try {
      await this.inviteDetailService.saveInviteDoc(this.workspaceId, this.inviteId, this.inviteForm.value, this.user);
      const route = ["/workspace", this.workspaceId, "manage", "members"];
      this.router.navigate(route);
      this.inviteForm.markAsPristine();
      this.toggleRoleSelectorOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Role
   */
  public async saveRole(): Promise<void> {
    try {
      const role = await this.roleDetailService.saveRoleDoc(this.workspaceId, this.roleForm.getRawValue(), this.user);
      this.inviteForm.patchValue({
        role_title: role.title,
        role_id: role.id,
        permissions: role.permissions,
      });
      this.inviteForm.markAsDirty();
      this.currentRole = role as RoleCurrent;
      // this.roleForm.reset(this.defaultRoleFormValues);
      this.toggleRoleFormOverlay();
      this.toggleRoleSelectorOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Revoke Invite
   */
  async canUserRevokeInvite(): Promise<void> {
    const featureAction: keyof RolePermissions = "invite_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleRevokeInviteOverlay(true);
      const revokeInvite = this.stringsService.alertFilter(AlertType.RevokeInvite);
      if (revokeInvite) {
        this.deleteOverlayTitle = revokeInvite.title;
        this.deleteOverlayDescription = revokeInvite.description;
      } else {
        console.error("Unknown string type:", AlertType.RevokeInvite);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Revoke Invite
   */
  public async revokeInvite(): Promise<void> {
    const route = ["/workspace", this.workspaceId, "manage", "members"];
    try {
      await this.inviteDetailService.revokeInvite(this.inviteId);
      this.inviteForm.markAsPristine();
      this.router.navigate(route);
      this.toggleRevokeInviteOverlay(false);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Revoke Invite
   * @param value
   */
  public toggleRevokeInviteOverlay(value: boolean): void {
    this.overlay_revokeInvite.set(value);
  }

  /**
   * Toggle Role Selector Overlay
   */
  public toggleRoleSelectorOverlay(): void {
    this.overlay_roleSelector = !this.overlay_roleSelector;
  }

  /**
   * Toggle Role Form Overlay
   */
  public toggleRoleFormOverlay(): void {
    this.overlay_roleForm = !this.overlay_roleForm;
    if (!this.overlay_roleForm) {
      this.roleForm.reset();
    }
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.inviteForm.dirty ? false : true;
  }
}
