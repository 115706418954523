import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
  selector: "manage-template-overview-contents",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./manage-template-overview-contents.component.html",
  styleUrl: "./manage-template-overview-contents.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateOverviewContentsComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) templateId!: string;
}
