<div
  [ngClass]="{
    'border-alert_Info bg-alert_Info': type === 'info',
    'border-alert_Success bg-alert_Success': type === 'success',
    'border-alert_Warning bg-alert_Warning': type === 'warning',
    'border-alert_Error bg-alert_Error': type === 'error',
    'cursor-pointer hover:opacity-75': clickable,
  }"
  class="w-full rounded-md border border-opacity-50 bg-opacity-5 p-4"
  (click)="clickable && navigateToLink()"
>
  <div class="flex items-center justify-between">
    <div class="flex-shrink-0">
      @switch (type) {
        @case ('info') {
          <div class="flex h-6 w-6 items-center justify-center rounded-full bg-alert_Info">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg>
          </div>
        }
        @case ('success') {
          <div class="flex h-6 w-6 items-center justify-center rounded-full bg-alert_Success">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        }
        @case ('warning') {
          <div class="flex h-6 w-6 items-center justify-center rounded-full bg-alert_Warning">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
          </div>
        }
        @case ('error') {
          <div class="flex h-6 w-6 items-center justify-center rounded-full bg-alert_Error">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
          </div>
        }
        @default {
          <div class="flex h-6 w-6 items-center justify-center rounded-full bg-alert_Info">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg>
          </div>
        }
      }
    </div>
    <div class="ml-3 flex flex-1 flex-shrink-0">
      <pre class="whitespace-pre-line font-sans text-sm text-black">{{ message }}</pre>
    </div>
    @if (clickable) {
      <div class="list-icon">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </div>
    }
  </div>
</div>
