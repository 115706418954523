import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { InfoViewStringsService } from 'src/app/core/services/strings/info-view-strings.service';
import { InfoViewFilter, InfoViewType } from 'src/app/models/strings/strings.model';
import { WorkspaceEnhanced } from 'src/app/models/workspace/workspace.model';
import { InfoViewComponent } from 'src/app/shared/info-view/info-view.component';

@Component({
  selector: 'leave-workspace',
  standalone: true,
  imports: [InfoViewComponent, NgStyle],
  templateUrl: './leave-workspace.component.html',
  styleUrl: './leave-workspace.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveWorkspaceComponent {
  @Input({ required: true }) workspaceToLeave!: WorkspaceEnhanced;
  @Output() isConfirmLeaveChecked = new EventEmitter<boolean>();

  // Services
  private infoViewStringsService = inject(InfoViewStringsService);

  // Properties
  public infoViewLeaveWorkspace1: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.LeaveWorkspace1);
  public infoViewLeaveWorkspace2: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.LeaveWorkspace2);

  /**
   * Is Confirm Leave Checked Change
   * @param event
   */
  onIsConfirmLeaveCheckedChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.isConfirmLeaveChecked.emit(checkbox.checked);
  }
}
