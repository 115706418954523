import { inject, Injectable } from "@angular/core";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { AssigneeEnhanced } from "src/app/models/assignee/assignee.model";

@Injectable({
  providedIn: "root",
})
export class AssigneesListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Assignees List
   * @param workspaceId
   * @param itemId
   */
  public getAssigneesList$(workspaceId: string): Observable<AssigneeEnhanced[]> {
    const path = this.collectionsService.assigneesCol(workspaceId);
    const queryConstraints = [where("is_deleted", "==", false), orderBy("name", "asc")];
    return this.firestoreUtilsService.getCollectionData<AssigneeEnhanced>(path, queryConstraints);
  }
}
