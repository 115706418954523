<form [formGroup]="templateForm">
  <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
    <div class="sm:col-span-6">
      <div class="form-input-wrapper">
        <label for="template-title" class="form-input-label"
          >Title*
          @if (!templateForm.pristine && templateForm.controls["title"].errors) {
            <span class="required-field"> - Title is required</span>
          }
        </label>
        <input type="text" name="template-title" id="template-title" class="form-input" placeholder="Title" formControlName="title" />
      </div>
      @if (templateForm.value.created_by !== null) {
        <div class="footer-text">Created by {{ templateForm.value.created_by }} on {{ templateForm.value.date_created.toDate() | date: "dd/MM/yyyy HH:mm" }}</div>
      }
    </div>
    <div class="sm:col-span-6">
      <div class="form-input-wrapper">
        <label for="ref" class="form-input-label">Reference</label>
        <input type="text" name="ref" id="ref" class="form-input" placeholder="Reference" formControlName="ref" />
      </div>
    </div>
    <div class="sm:col-span-6">
      <div class="form-input-wrapper">
        <label for="template-version" class="form-input-label"
          >Version*
          @if (!templateForm.pristine && templateForm.controls["version"].errors) {
            <span class="required-field"> - Version is required</span>
          }
        </label>
        <input type="text" name="template-version" id="template-version" class="form-input" formControlName="version" />
      </div>
      @if (templateForm.value.date_created) {
        <div class="footer-text">Last updated on {{ templateForm.value.date_created?.toDate() | date: "dd/MM/yyyy HH:mm" }}</div>
      }
    </div>
  </div>
</form>
