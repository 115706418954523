import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CollectionsService {
  // Users
  public usersCol() {
    return `${environment.col.USERS}`;
  }

  // Billing
  public userBillingCol() {
    return `${environment.col.USER_BILLING}`;
  }

  // Workspaces
  public workspacesCol() {
    return `${environment.col.WORKSPACES}`;
  }

  // Folders
  public foldersCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.FOLDERS}`;
  }

  // Inspections
  public inspectionsCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.INSPECTIONS}`;
  }

  // Supplementary
  public supplementaryCol(workspaceId: string, inspectionId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.INSPECTIONS}/${inspectionId}/${environment.col.SUPPLEMENTARY}`;
  }

  // Inspection Categories
  public inspectionCategoriesCol(workspaceId: string, inspectionId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.INSPECTIONS}/${inspectionId}/${environment.col.CATEGORIES}`;
  }

  // Items
  public inspectionItemsCol(workspaceId: string, inspectionId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.INSPECTIONS}/${inspectionId}/${environment.col.ITEMS}`;
  }

  // Photos
  public photosCol(workspaceId: string, inspectionId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.INSPECTIONS}/${inspectionId}/${environment.col.PHOTOS}`;
  }

  // Actions
  public actionsCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.ACTIONS}`;
  }

  // Signatures
  public signaturesCol(workspaceId: string, inspectionId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.INSPECTIONS}/${inspectionId}/${environment.col.SIGNATURES}`;
  }

  // Clients
  public clientsCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.CLIENTS}`;
  }

  // Sites
  public sitesCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.SITES}`;
  }

  // Operations
  public operationsCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.OPERATIONS}`;
  }

  // Assignees
  public assigneesCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.ASSIGNEES}`;
  }

  // Members
  public membersCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.MEMBERS}`;
  }

  // Roles
  public rolesCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.ROLES}`;
  }

  // Templates
  public templatesCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.TEMPLATES}`;
  }

  // Template Categories
  public templateCategoriesCol(workspaceId: string, templateId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.TEMPLATES}/${templateId}/${environment.col.TEMPLATE_CATEGORIES}`;
  }

  // Template Items
  public templateItemsCol(workspaceId: string, templateId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.TEMPLATES}/${templateId}/${environment.col.TEMPLATE_ITEMS}`;
  }

  // Themes
  public themesCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.THEMES}`;
  }

  // Theme Styles
  public themesStyleCol() {
    return `${environment.col.THEME_STYLES}`;
  }

  // Invites
  public invitesCol() {
    return `${environment.col.INVITES}`;
  }

  // Activities
  public auditLogsCol(workspaceId: string) {
    return `${environment.col.WORKSPACES}/${workspaceId}/${environment.col.AUDIT_LOGS}`;
  }

  // Exports
  // public exportsCol(userId: string) {
  //   return `${environment.col.USERS}/${userId}/${environment.col.EXPORTS}`;
  // }
  public exportsCol(userId: string) {
    return `${environment.col.USERS}/${userId}/${environment.col.EXPORTS}`;
  }
}
