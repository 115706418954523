import { NgStyle } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DateFormatPipe } from "src/app/core/pipes/date-format.pipe";
import { ActionsListService } from "src/app/sections/actions/services/actions-list.service";
import { ThemeStylesAndSettingsService } from "../../../services/theme-styles-and-settings.service";

@Component({
  selector: "manage-theme-edit-preview-action",
  standalone: true,
  imports: [NgStyle, DateFormatPipe],
  templateUrl: "./manage-theme-edit-preview-action.component.html",
  styleUrl: "./manage-theme-edit-preview-action.component.scss",
})
export class ManageThemeEditPreviewActionComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  public themeStylesAndSettingsSerivce = inject(ThemeStylesAndSettingsService);
  public actionsListService = inject(ActionsListService);
  public emailAddress = "john.doe@gmail.com";
}
