import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { ConnectedDevicesService } from "src/app/core/services/connected-devices.service";

@Component({
  selector: "main-menu-sign-out-overlay",
  standalone: true,
  imports: [],
  templateUrl: "./main-menu-sign-out-overlay.component.html",
  styleUrl: "./main-menu-sign-out-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuSignOutOverlayComponent {
  public authService = inject(AuthService);
  public connectedDevicesService = inject(ConnectedDevicesService);
}
