import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'actions-list-complete-incomplete',
  standalone: true,
  imports: [],
  templateUrl: './actions-list-complete-incomplete.component.html',
  styleUrl: './actions-list-complete-incomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsListCompleteIncompleteComponent {
  @Input({ required: true }) completeOverlayTitle!: string;
  @Input({ required: true }) completeOverlayDescription!: string;
  @Output() completeIncompleteOutput = new EventEmitter<boolean>();
  @Output() cancelButtonClicked = new EventEmitter();

  /**
   * Cancel
   */
  cancel(): void {
    this.cancelButtonClicked.emit();
  }

  /**
   * Enabled State
   */
  completeState(complete: boolean): void {
    this.completeIncompleteOutput.emit(complete);
  }
}
