import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmptyListService {
  public emptyFolders() {
    const emptyObject = {
      title: 'No folders',
      command: "To get started click the '+ Folder' button to add your first Folder.",
      question: 'What is a folder?',
      answer: 'A Folder contains your Inspections. Use Folders to separate your Inspections into logical groups.',
    };
    return emptyObject;
  }

  public emptyInspections() {
    const emptyObject = {
      title: 'No Inspections',
      command: "To get started click the '+ Inspection' button to add your first Inspection.",
      question: 'What is an Inspection?',
      answer: 'An Inspection is made up of Tests, Questions, Comments, and Actions. All of the answers, images, and info you collect for an Inspection can be included on your generated Report.',
    };
    return emptyObject;
  }

  public emptySignatures() {
    const emptyObject = {
      title: 'No Signatures',
      command: "To get started click the '+ Signature' button to add your first Signature.",
      question: 'What is a Signature?',
      answer: 'TODO',
    };
    return emptyObject;
  }

  public emptyCategories() {
    const emptyObject = {
      title: 'No Categories',
      command: "To get started click the '+ Category' button to add your first Category.",
      question: 'What is a Category?',
      answer: 'TODO',
    };
    return emptyObject;
  }

  public emptyItems() {
    const emptyObject = {
      title: 'No Items',
      command: "To get started click the '+ Item' button to add your first Item.",
      question: 'What is an Item?',
      answer: 'TODO',
    };
    return emptyObject;
  }

  public emptyThemes() {
    const emptyObject = {
      title: 'No Themes',
      command: "To get started click the '+ Theme' button to add your first Theme.",
      question: "What's a Theme?",
      answer: 'Use a Theme to provide specific branding to your Reports.',
    };
    return emptyObject;
  }

  public emptyAssignees() {
    const emptyObject = {
      title: 'No Assignees',
      command: "To get started click the '+ Assignee' button to add your first Assignee.",
      question: "What's an Assignee?",
      answer: 'TODO',
    };
    return emptyObject;
  }

  public emptyClients() {
    const emptyObject = {
      title: 'No Clients',
      command: "To get started click the '+ Client' button to add your first Client.",
      question: "What's a Client?",
      answer: 'TODO',
    };
    return emptyObject;
  }

  public emptySites() {
    const emptyObject = {
      title: 'No Sites',
      command: "To get started click the '+ Site' button to add your first Site.",
      question: "What's a Site?",
      answer: 'TODO',
    };
    return emptyObject;
  }

  public emptyActions() {
    const emptyObject = {
      title: 'No Actions',
      command: "To get started click the '+ Action' button to add your first Action.",
      question: 'What is an Action?',
      answer: 'TODO',
    };
    return emptyObject;
  }

  public emptyTemplates() {
    const emptyObject = {
      title: 'No Templates',
      command: "To get started click the '+ Template' button to add your first Template.",
      question: 'What is a Template?',
      answer: 'TODO',
    };
    return emptyObject;
  }

  public emptyInvites() {
    const emptyObject = {
      title: 'No Invites',
      command: 'There are no pending Invites for this Workspace',
    };
    return emptyObject;
  }

  public emptyCustomRoles() {
    const emptyObject = {
      title: 'No Custom Roles',
      command: 'To get started click the + Custom Role to add your Custom Role.',
      question: 'What is a Role?',
      answer: 'A Custom Role allows you to set the exact permissions and level of access you want.',
    };
    return emptyObject;
  }
}
