import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RolePermissions } from 'cip';
import { LimitationManagerService } from '../services/limitation-manager.service';

@Injectable({
  providedIn: 'root',
})
export class LimitationGuard implements CanActivate {
  constructor(
    private limitationManagerService: LimitationManagerService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const workspaceId = route.paramMap.get('workspaceId');
    if (!workspaceId) {
      this.router.navigate(['/']);
      return false;
    }

    const featureAction = route.data['featureAction'] as keyof RolePermissions;
    const checkSubscription = route.data['checkSubscription'] as boolean;

    // Construct navigation path once, use it in multiple places
    let navigationPath: string[];
    if (route.data['returnPathSegments']) {
      const segments = route.data['returnPathSegments'] as string[];
      navigationPath = ['workspace', workspaceId];

      segments.forEach((segment) => {
        if (segment.startsWith(':')) {
          const paramName = segment.substring(1);
          const paramValue = route.paramMap.get(paramName);
          if (paramValue) navigationPath.push(paramValue);
        } else {
          navigationPath.push(segment);
        }
      });
    } else if (route.data['returnPath']) {
      navigationPath = ['workspace', workspaceId, ...route.data['returnPath']];
    } else {
      navigationPath = ['workspace', workspaceId, 'folders'];
    }

    // Check permissions
    const hasPermission = await this.limitationManagerService.doesUserHaveCorrectPermissions(featureAction);
    if (!hasPermission) {
      this.router.navigate(navigationPath);
      return false;
    }

    // Check subscription if required
    if (checkSubscription) {
      const subscription = this.limitationManagerService.billingDoc;
      if (subscription?.status === 'free') {
        // Check if this is the theme editor route
        if (route.data['blockNewOnFreeSubscription']) {
          const themeId = route.paramMap.get('themeId');

          // Free users can only access 'default' theme
          if (themeId === 'default') {
            return true;
          }

          // Block access to 'new' theme or any non-default theme for free users
          this.limitationManagerService.setSubscriptionErrorMessage();
          this.limitationManagerService.overlay_limitationManager = true;
          this.router.navigate(navigationPath);
          return false;
        }

        // Regular subscription check for other routes
        this.router.navigate(navigationPath);
        return false;
      }
    }

    return true;
  }
}
