import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "manage-roles-list-header",
  standalone: true,
  imports: [],
  templateUrl: "./manage-roles-list-header.component.html",
  styleUrl: "./manage-roles-list-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRolesListHeaderComponent {
  @Input({ required: true }) selectedTab!: string;
  @Output() routeToRoleEditorOutput = new EventEmitter<void>();

  /**
   * Route To Role Editor
   */
  public routeToRoleEditor(): void {
    this.routeToRoleEditorOutput.emit();
  }
}
