import { inject, Injectable } from "@angular/core";
import { Invite } from "cip";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { InviteAcceptDecline, InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";

@Injectable({
  providedIn: "root",
})
export class InvitesListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Invites For Specific Workspace
   * @param workspaceId
   */
  public getInvitesForSpecificWorkspace$(workspaceId: string): Observable<InviteEnhanced[]> {
    const path = this.collectionsService.invitesCol();
    const queryConstraints = [where("workspace_id", "==", workspaceId), orderBy("date_invited", "desc")];
    return this.firestoreUtilsService.getCollectionData<InviteEnhanced>(path, queryConstraints);
  }

  /**
   * Get Invites List
   * @param userEmail
   */
  public getInvitesList$(userEmail: string): Observable<InviteEnhanced[]> {
    const path = this.collectionsService.invitesCol();
    const queryConstraints = [where("email", "==", userEmail), orderBy("date_invited", "desc")];
    return this.firestoreUtilsService.getCollectionData<InviteEnhanced>(path, queryConstraints);
  }

  /**
   * Get Workspace Invites By Role
   * @param workspaceId
   * @param roleId
   */
  public getWorkspaceInvitesByRole$(workspaceId: string, roleId: string): Observable<InviteEnhanced[]> {
    const path = this.collectionsService.invitesCol();
    const queryConstraints = [where("workspace_id", "==", workspaceId), where("role_id", "==", roleId), orderBy("date_invited", "desc")];
    return this.firestoreUtilsService.getCollectionData<InviteEnhanced>(path, queryConstraints);
  }

  /**
   *  Join Or Decline Invite
   */
  async joinOrDeclineInvite(inviteProperties: InviteAcceptDecline): Promise<InviteEnhanced> {
    const invitePath = `${this.collectionsService.invitesCol()}/${inviteProperties.invite.id}`;
    const inviteData: Invite = {
      date_invited: inviteProperties.invite.date_invited,
      email: inviteProperties.invite.email,
      name: inviteProperties.invite.name,
      role_id: inviteProperties.invite.role_id,
      role_title: inviteProperties.invite.role_title,
      workspace_id: inviteProperties.invite.workspace_id,
      workspace_name: inviteProperties.invite.workspace_name,
      workspace_invited_by: inviteProperties.invite.workspace_invited_by,
      workspace_invited_by_id: inviteProperties.invite.workspace_invited_by_id,
      status: inviteProperties.choice,
    };
    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Invite>(invitePath, inviteData);

    return inviteProperties.invite;
  }
}
