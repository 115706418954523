@if (!!item && !!currentCategory) {
  @switch (item.answer_type) {
    @case ('test-fail-pass') {
      <!-- test-fail-pass-->
      <div class="options-two relative flex h-[30px] min-w-[280px] flex-row justify-evenly overflow-hidden rounded-md border bg-[#F0F4F7]">
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('fail')">
          <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize" [ngClass]="item.test_answer === 'fail' ? 'text-white' : 'text-text_Title'">fail</div>
        </div>
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('pass')">
          <div class="flex h-[30px] w-full items-center justify-center text-xs font-medium capitalize" [ngClass]="item.test_answer === 'pass' ? 'text-white' : 'text-text_Title'">pass</div>
        </div>
        <div class="absolute left-0 top-0 z-[5] flex h-[30px] w-full flex-row">
          <div
            class="w-1/2 transition-all duration-300"
            [ngClass]="{
              'answerOne bg-score_0': item.test_answer === 'fail',
              'answerTwo bg-score_100': item.test_answer === 'pass',
            }"
          ></div>
        </div>
      </div>
    }

    <!-- test-no-yes-->
    @case ('test-no-yes') {
      <div>
        <div class="options-two relative flex h-[30px] min-w-[280px] flex-row justify-evenly overflow-hidden rounded-md border bg-[#F0F4F7]">
          <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('no')">
            <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize" [ngClass]="item.test_answer === 'no' ? 'text-white' : 'text-text_Title'">no</div>
          </div>
          <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('yes')">
            <div class="flex h-[30px] w-full items-center justify-center text-xs font-medium capitalize" [ngClass]="item.test_answer === 'yes' ? 'text-white' : 'text-text_Title'">yes</div>
          </div>
          <div class="absolute left-0 top-0 z-[5] flex h-[30px] w-full flex-row">
            <div
              class="w-1/2 transition-all duration-300"
              [ngClass]="{
                'answerOne bg-score_0': item.test_answer === 'no',
                'answerTwo bg-score_100': item.test_answer === 'yes',
              }"
            ></div>
          </div>
        </div>
      </div>
    }

    <!-- test-low-high-->
    @case ('test-low-high') {
      <div class="options-two relative flex h-[30px] min-w-[280px] flex-row justify-evenly overflow-hidden rounded-md border bg-[#F0F4F7]">
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('low')">
          <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize" [ngClass]="item.test_answer === 'low' ? 'text-white' : 'text-text_Title'">low</div>
        </div>
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('high')">
          <div class="flex h-[30px] w-full items-center justify-center text-xs font-medium capitalize" [ngClass]="item.test_answer === 'high' ? 'text-white' : 'text-text_Title'">high</div>
        </div>
        <div class="absolute left-0 top-0 z-[5] flex h-[30px] w-full flex-row">
          <div
            class="w-1/2 transition-all duration-300"
            [ngClass]="{
              'answerOne bg-score_0': item.test_answer === 'low',
              'answerTwo bg-score_100': item.test_answer === 'high',
            }"
          ></div>
        </div>
      </div>
    }
    <!-- test-red-amber-green-->
    @case ('test-red-amber-green') {
      <div class="options-three relative flex h-[30px] min-w-[280px] flex-row justify-evenly overflow-hidden rounded-md border bg-[#F0F4F7]">
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('red')">
          <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize" [ngClass]="item.test_answer === 'red' ? 'text-white' : 'text-text_Title'">red</div>
        </div>
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('amber')">
          <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize text-text_Title">amber</div>
        </div>
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('green')">
          <div class="flex h-[30px] w-full items-center justify-center text-xs font-medium capitalize" [ngClass]="item.test_answer === 'green' ? 'text-white' : 'text-text_Title'">green</div>
        </div>
        <div class="absolute left-0 top-0 z-[5] flex h-[30px] w-full flex-row">
          <div
            class="w-1/3 transition-all duration-300"
            [ngClass]="{
              'answerOne bg-score_0': item.test_answer === 'red',
              'answerTwo bg-score_50': item.test_answer === 'amber',
              'answerThree bg-score_100': item.test_answer === 'green',
            }"
          ></div>
        </div>
      </div>
    }

    <!-- test-poor-fair-good-->
    @case ('test-poor-fair-good') {
      <div class="options-three relative flex h-[30px] min-w-[280px] flex-row justify-evenly overflow-hidden rounded-md border bg-[#F0F4F7]">
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('poor')">
          <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize" [ngClass]="item.test_answer === 'poor' ? 'text-white' : 'text-text_Title'">poor</div>
        </div>
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('fair')">
          <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize text-text_Title">fair</div>
        </div>
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('good')">
          <div class="flex h-[30px] w-full items-center justify-center text-xs font-medium capitalize" [ngClass]="item.test_answer === 'good' ? 'text-white' : 'text-text_Title'">good</div>
        </div>
        <div class="absolute left-0 top-0 z-[5] flex h-[30px] w-full flex-row">
          <div
            class="w-1/3 transition-all duration-300"
            [ngClass]="{
              'answerOne bg-score_0': item.test_answer === 'poor',
              'answerTwo bg-score_50': item.test_answer === 'fair',
              'answerThree bg-score_100': item.test_answer === 'good',
            }"
          ></div>
        </div>
      </div>
    }

    <!-- test-progress-->
    @case ('test-progress') {
      <div class="options-three relative flex h-[30px] min-w-[280px] flex-row justify-evenly overflow-hidden rounded-md border bg-[#F0F4F7]">
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('progress-0')">
          <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize" [ngClass]="item.test_answer === 'progress-0' ? 'text-white' : 'text-text_Title'">not started</div>
        </div>
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('progress-50')">
          <div class="flex h-full w-full items-center justify-center border-r text-xs font-medium capitalize text-text_Title">in progress</div>
        </div>
        <div class="z-10 w-1/2 border-[#C8C8C8] transition-all duration-300" [ngClass]="{ 'cursor-pointer hover:opacity-40': item.enabled && currentCategory.enabled }" (click)="setItemAnswer('progress-100')">
          <div class="flex h-[30px] w-full items-center justify-center text-xs font-medium capitalize" [ngClass]="item.test_answer === 'progress-100' ? 'text-white' : 'text-text_Title'">completed</div>
        </div>
        <div class="absolute left-0 top-0 z-[5] flex h-[30px] w-full flex-row">
          <div
            class="w-1/3 transition-all duration-300"
            [ngClass]="{
              'answerOne bg-score_0': item.test_answer === 'progress-0',
              'answerTwo bg-score_50': item.test_answer === 'progress-50',
              'answerThree bg-score_100': item.test_answer === 'progress-100',
            }"
          ></div>
        </div>
      </div>
    }
  }
}
