@if (templates$ | async; as templates) {
  <div class="flex min-w-0 flex-1">
    <div class="flex h-full min-w-0 flex-1 flex-col overflow-hidden">
      <manage-templates-list-header
        [workspaceId]="workspaceId"
        [templatesCount]="templates.length"
        (searchTermChange)="searchTermChange($event)"
        (toggleNewTemplateOverlayOutput)="canUserCreateTemplate()"
        (toggleImportTemplateOverlayOutput)="toggleImportTemplateOverlay()" />

      @if (templates.length > 0) {
        <manage-templates-list [templates]="templates" [workspaceId]="workspaceId" [searchTerm]="currentSearchTerm" (routeToTemplateOverviewOutput)="routeToTemplateOverview($event)" />
      } @else {
        <empty-list [list]="'template'" />
      }
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_newTemplate) {
  <manage-template-new-overlay (toggleNewTemplateOverlayOutput)="toggleNewTemplateOverlay()" [templateForm]="templateForm" [workspaceId]="workspaceId" [newTemplateId]="newTemplateId" (saveTemplateOutput)="saveTemplate()" />
}

@if (overlay_importTemplate) {
  <manage-templates-list-import
    [workspaceId]="workspaceId"
    [newImportTemplateId]="newImportTemplateId"
    [fileUploading]="fileUploading"
    [fileUploadComplete]="fileUploadComplete"
    [fileAdded]="fileAdded"
    [fileName]="fileName"
    [importTemplateForm]="importTemplateForm"
    (toggleImportTemplateOverlayOutput)="closeImportScreen()"
    (fileSelectedOutput)="convertItmpToJson($event)"
    (importTemplateOutput)="importTemplate()" />
}
