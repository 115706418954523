<header>
  <!-- Dummy breadcrumb element-->
  <div class="h-[28px] px-6 pb-2"></div>
  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Edit Email</h2>
      </div>
    </div>
  </div>
</header>

<!-- Content -->
<div class="grid grid-cols-1 gap-y-8">
  <!-- Form State Top -->
  <div class="flex flex-row items-center justify-end px-6 sm:px-8">
    <form-state [pristine]="emailForm.pristine"></form-state>
    <button
      type="button"
      (click)="saveNewEmail()"
      class="btn"
      [disabled]="emailForm.pristine || !emailForm.valid || (newEmail?.value !== confirmNewEmail?.value && !emailForm.pristine)"
      [ngClass]="emailForm.pristine || !emailForm.valid || (newEmail?.value !== confirmNewEmail?.value && !emailForm.pristine) ? 'btn-disabled cursor-not-allowed' : 'btn-primary'">
      Update Email
    </button>
  </div>

  <!-- Content  -->

  <div class="content-card-wrapper">
    <div class="px-6 py-5">
      <div class="content-grid gap-x-4 gap-y-6">
        <info-view [type]="infoViewEmailEditor2.type" [message]="infoViewEmailEditor2.message + user.email" />

        <form [formGroup]="emailForm" class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
          <!-- New Email -->
          <div class="sm:col-span-1">
            <div class="form-input-wrapper">
              <label for="new_email" class="form-input-label"
                >New Email*
                @if ((newEmail?.invalid && newEmail?.touched) || newEmail?.dirty) {
                  @if (newEmail?.errors?.["required"]) {
                    <span class="required-field"> - New email is required </span>
                  }
                  @if (newEmail?.errors?.["required"]) {
                    <span class="required-field"> - Please provide a valid email address </span>
                  }
                }
              </label>
              <input type="email" name="new_email" id="new_email" [class.is-invalid]="emailForm.get('newEmail')?.invalid && emailForm.get('newEmail')?.touched" class="form-input" placeholder="New Email" formControlName="newEmail" />
            </div>
          </div>

          <!-- Confirm Email-->
          <div class="sm:col-span-1">
            <div class="form-input-wrapper">
              <label for="confirm_new_email" class="form-input-label"
                >Confirm New Email*
                @if ((confirmNewEmail?.invalid && confirmNewEmail?.touched) || confirmNewEmail?.dirty) {
                  @if (emailForm.value.newEmail !== emailForm.value.confirmNewEmail) {
                    <span class="required-field"> - Entered emails do not match </span>
                  }
                  @if (confirmNewEmail?.errors?.["required"]) {
                    <span class="required-field"> - New email is required </span>
                  }
                  @if (confirmNewEmail?.errors?.["pattern"]) {
                    <span class="required-field"> - Please provide a valid email address </span>
                  }
                }
              </label>
              <input
                type="email"
                name="confirm_new_email"
                id="confirm_new_email"
                [class.is-invalid]="emailForm.get('confirmNewEmail')?.invalid && emailForm.get('confirmNewEmail')?.touched"
                class="form-input"
                placeholder="New Email"
                formControlName="confirmNewEmail"
                (paste)="disablePaste($event)" />
            </div>
          </div>

          <!-- Error -->
          @if (emailError) {
            <div class="col-span-6">
              <info-view [type]="'error'" [message]="emailErrorInfo.code + ' ' + emailErrorInfo.message" />
            </div>
          }

          <!-- Success -->
          @if (emailUpdated) {
            <div class="col-span-6">
              <info-view [type]="'success'" [message]="'Email successfully updated!'" />
            </div>
          }
        </form>
      </div>
    </div>
  </div>

  <!-- Bottom Form State-->
  <div class="flex flex-row items-center justify-between px-6 sm:px-8">
    <div class="flex flex-1 flex-row items-center justify-end">
      <form-state [pristine]="emailForm.pristine"></form-state>
      <button
        type="button"
        (click)="saveNewEmail()"
        class="btn"
        [disabled]="emailForm.pristine || !emailForm.valid || (newEmail?.value !== confirmNewEmail?.value && !emailForm.pristine)"
        [ngClass]="emailForm.pristine || !emailForm.valid || (newEmail?.value !== confirmNewEmail?.value && !emailForm.pristine) ? 'btn-disabled cursor-not-allowed' : 'btn-primary'">
        Update Email
      </button>
    </div>
  </div>
</div>
