<div class="mx-auto w-full max-w-7xl px-6 pb-10 xl:px-0">
  <header>
    <!-- Breadcrumbs-->
    <div class="px-6 pb-2">
      <nav aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-2">
          <li>
            <div class="flex items-center">
              <a [routerLink]="['/workspace', workspaceId, 'auditlogs']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Audit Logs</a>
            </div>
          </li>
        </ol>
      </nav>
    </div>

    <!-- Header -->
    <div class="page-header-wrapper">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="page-header">Audit Log</h2>
        </div>
      </div>
    </div>
  </header>

  @if (auditDoc) {
    <div class="content-card-wrapper">
      <div class="px-6 py-5">
        <div class="content-grid gap-y-3">
          <div>
            <div class="text-sm text-text_Subtitle">Document</div>
            <div class="text-base text-text_Title">{{ auditDoc.document }}</div>
          </div>
          <div>
            <div class="text-sm text-text_Subtitle">Title</div>
            <div class="text-base text-text_Title">{{ auditDoc.title }}</div>
          </div>
          <div>
            <div class="text-sm text-text_Subtitle">Type</div>
            <div class="text-base text-text_Title">{{ auditDoc.type }}</div>
          </div>
          @if (auditDoc.changes) {
            <div>
              <div class="text-sm text-text_Subtitle">Changes</div>
              @for (change of auditDoc.changes; track change.key) {
                <ul class="pl-4">
                  <li class="list-disc text-base text-text_Title">{{ change.key }} changed to {{ change.value_after }}</li>
                </ul>
              }
            </div>
          }
          @if (auditDoc.to) {
            <div>
              <div class="text-sm text-text_Subtitle">To</div>
              <div class="text-base text-text_Title">{{ auditDoc.to }}</div>
            </div>
          }
          @if (auditDoc.image) {
            <div>
              <div class="text-sm text-text_Subtitle">To</div>
              <img [src]="auditDoc.image" alt="Image" class="mt-2 h-20 w-20 rounded-lg" />
            </div>
          }
          @if (auditDoc.author) {
            <div>
              <div class="text-sm text-text_Subtitle">Author</div>
              <div class="text-base text-text_Title">{{ auditDoc.author }}</div>
            </div>
          }
          <div>
            <div class="text-sm text-text_Subtitle">Date</div>
            <div class="text-base text-text_Title">{{ auditDoc.date.toDate() | date: "dd MMM yyyy" }} at {{ auditDoc.date.toDate() | date: "HH:mm" }}</div>
          </div>
          <div>
            <div class="text-sm text-text_Subtitle">Platform</div>
            <div class="text-base capitalize text-text_Title">{{ auditDoc.platform }}</div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
