import { inject, Injectable } from "@angular/core";
import { Invite, User, Workspace } from "cip";
import { serverTimestamp, WithFieldValue } from "firebase/firestore";
import { catchError, firstValueFrom, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";
import { WorkspacesService } from "../../../services/workspaces.service";

@Injectable({
  providedIn: "root",
})
export class InviteDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private workspacesService = inject(WorkspacesService);

  /**
   * Get Invite Doc
   * @param inviteId
   */
  public getInviteDoc$(inviteId: string): Observable<InviteEnhanced> {
    if (inviteId === "new") {
      return of({} as InviteEnhanced);
    }
    const path = this.collectionsService.invitesCol();
    const doc = `${path}/${inviteId}`;
    return this.firestoreUtilsService.getDocumentData<InviteEnhanced>(doc).pipe(
      map((data) => data || ({} as InviteEnhanced)),
      catchError((error) => {
        return of({} as InviteEnhanced);
      })
    );
  }

  /**
   * Save Invite Doc
   * @param workspaceId
   * @param inviteId
   * @param inviteForm
   * @param user
   */
  public async saveInviteDoc(workspaceId: string, inviteId: string, inviteForm: InviteEnhanced, user: User): Promise<void> {
    const path = this.collectionsService.invitesCol();
    const inviteIdToUse = inviteId === "new" ? this.firestoreUtilsService.createFirestoreId() : inviteId;
    const documentPath = `${path}/${inviteIdToUse}`;
    const workspace = (await firstValueFrom(this.workspacesService.getWorkspaceDoc$(workspaceId))) as Workspace;
    const inviteObj: WithFieldValue<Invite> = {
      date_invited: inviteForm.date_invited === null ? serverTimestamp() : inviteForm.date_invited,
      email: inviteForm.email.trim(),
      name: inviteForm.name.trim(),
      role_id: inviteForm.role_id,
      role_title: inviteForm.role_title,
      workspace_id: workspaceId,
      workspace_name: workspace.name,
      workspace_invited_by: inviteForm.workspace_invited_by ?? `${user.name_first} ${user.name_last}`,
      workspace_invited_by_id: inviteForm.workspace_invited_by_id ?? user.user_id,
      status: inviteForm.status,
    };
    return await this.firestoreUtilsService.setPartialDocumentData<InviteEnhanced>(documentPath, inviteObj);
  }

  /**
   * Revoke Invite
   * @param inviteId
   * @returns
   */
  public async revokeInvite(inviteId: string): Promise<void> {
    const path = this.collectionsService.invitesCol();
    const doc = `${path}/${inviteId}`;
    return this.firestoreUtilsService.deleteDocument(doc);
  }
}
