import { inject, Injectable } from "@angular/core";
import { Inspection, User, WriteEvent } from "cip";
import { deleteField, increment, serverTimestamp, WithFieldValue } from "firebase/firestore";
import { catchError, firstValueFrom, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { FolderEnhanced } from "src/app/models/folder/folder.model";
import { InspectionEnhanced } from "src/app/models/inspection/inspection.model";

import { SupplementaryEnhanced } from "src/app/models/inspection/supplementary.model";
import { BatchOperation } from "src/app/models/utils/batch";
import { FolderDetailService } from "../../folders/services/folder-detail.service";

@Injectable({
  providedIn: "root",
})
export class InspectionDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private lastEventService = inject(LastEventService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private folderDetailService = inject(FolderDetailService);

  // Properties for the varying duplication text (related to inspections)
  public duplicationTitle!: string;
  public duplicationDescription!: string;
  public inProgressText!: string;
  public successText!: string;
  public failedText!: string;
  public navigateToText!: string;
  public duplicationType!: string;

  /**
   * Get Inspection Doc
   * @param workspaceId
   * @param inspectionId
   */
  public getInspectionDoc$(workspaceId: string, inspectionId: string): Observable<InspectionEnhanced | null> {
    const path = `${this.collectionsService.inspectionsCol(workspaceId)}/${inspectionId}`;
    return this.firestoreUtilsService.getDocumentData<InspectionEnhanced>(path).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
  /**
   * Save Inspection
   * @param inspectionForm
   * @param workspaceId
   * @param folderId
   * @param user
   */
  public async createNewInspectionDoc(inspectionForm: InspectionEnhanced, workspaceId: string, folderId: string, user: User): Promise<string> {
    // Ids
    const introductionId = this.firestoreUtilsService.createFirestoreId();
    const internalId = this.firestoreUtilsService.createFirestoreId();
    const summaryId = this.firestoreUtilsService.createFirestoreId();

    // Last Event
    const lastEvent = this.lastEventService.lastEvent("added", user);

    // Folders
    const folder = await firstValueFrom(this.folderDetailService.getFolderDoc$(workspaceId, folderId));
    const folderPath = `${this.collectionsService.foldersCol(workspaceId)}/${folderId}`;

    // Inspections
    const inspectionId = this.firestoreUtilsService.createFirestoreId();
    const inspectionsPath = `${this.collectionsService.inspectionsCol(workspaceId)}/${inspectionId}`;
    const inspectionObj = this.createInspectionObject(inspectionForm, lastEvent, user, folder!);

    // Supplementary
    const supplementaryPath = this.collectionsService.supplementaryCol(workspaceId, inspectionId);
    const introductionPath = `${supplementaryPath}/${introductionId}`;
    const internalPath = `${supplementaryPath}/${internalId}`;
    const summaryPath = `${supplementaryPath}/${summaryId}`;
    const introduction = this.createIntroductionObject(lastEvent);
    const internal = this.createInternalObject(lastEvent);
    const summary = this.createSummaryObject(lastEvent);

    // Batch
    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: inspectionsPath,
        data: inspectionObj,
      },
      {
        type: "set",
        documentPath: folderPath,
        data: { inspections_count: increment(+1) },
      },
      {
        type: "set",
        documentPath: introductionPath,
        data: introduction,
      },
      {
        type: "set",
        documentPath: internalPath,
        data: internal,
      },
      {
        type: "set",
        documentPath: summaryPath,
        data: summary,
      },
    ];

    try {
      await this.firestoreUtilsService.batchWrite(operations);
      return inspectionId;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Update Inspection Doc
   * @param workspaceId
   * @param folderId
   * @param inspectionForm
   * @param user
   */
  public async updateInspectionDoc(workspaceId: string, folderId: string, inspectionId: string, inspectionForm: InspectionEnhanced, user: User): Promise<WithFieldValue<InspectionEnhanced>> {
    // Last Event
    const lastEvent = this.lastEventService.lastEvent("changed", user);

    // Inspection
    const inspectionsPath = `${this.collectionsService.inspectionsCol(workspaceId)}/${inspectionId}`;
    const inspectionObj = this.createInspectionObject(inspectionForm, lastEvent, user);

    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: inspectionsPath,
        data: inspectionObj,
      },
    ];

    if (folderId !== inspectionForm.folder_id) {
      const path = this.collectionsService.foldersCol(workspaceId);
      const currentFolderDoc = `${path}/${folderId}`;
      const newFolderDoc = `${path}/${inspectionForm.folder_id}`;

      operations.push(
        {
          type: "update",
          documentPath: currentFolderDoc,
          data: { inspections_count: increment(-1) },
        },
        {
          type: "update",
          documentPath: newFolderDoc,
          data: { inspections_count: increment(+1) },
        }
      );
    }

    try {
      await this.firestoreUtilsService.batchWrite(operations);
      return { ...inspectionObj, id: inspectionId };
    } catch (error) {
      throw error;
    }
  }

  public async updateInspectionOverallScore(workspaceId: string, inspectionId: string, overallScore: number): Promise<void> {
    const path = this.collectionsService.inspectionsCol(workspaceId);
    const doc = `${path}/${inspectionId}`;
    return await this.firestoreUtilsService.setDocumentData(doc, { overall_score: overallScore });
  }

  /**
   * Delete Inspection
   * @param workspaceId
   * @param folderId
   * @param inspectionId
   * @param user
   */
  public async deleteInspectionDoc(workspaceId: string, folderId: string, inspectionId: string, user: User) {
    // Inspection
    const inspectionsPath = this.collectionsService.inspectionsCol(workspaceId);
    const inspectionDoc = `${inspectionsPath}/${inspectionId}`;

    // Folder
    const foldersPath = this.collectionsService.foldersCol(workspaceId);
    const folderDoc = `${foldersPath}/${folderId}`;

    // Last Event
    const lastEvent = this.lastEventService.lastEvent("deleted", user);

    const operations: BatchOperation[] = [
      {
        type: "update",
        documentPath: inspectionDoc,
        data: { is_deleted: true, last_event: lastEvent },
      },
      {
        type: "update",
        documentPath: folderDoc,
        data: { inspections_count: increment(-1) },
      },
    ];

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Create Inspection Object
   * @param inspectionForm
   * @param lastEvent
   */
  createInspectionObject(inspectionForm: InspectionEnhanced, lastEvent: WithFieldValue<WriteEvent>, user: User, folder?: FolderEnhanced): WithFieldValue<Inspection> {
    const folderId = folder ? folder.id : inspectionForm.folder_id;
    const folderTitle = folder ? folder.title : inspectionForm.folder_title;

    return {
      title: inspectionForm.title.trim(),
      ref: inspectionForm.ref ? inspectionForm.ref.trim() : deleteField(),
      date: inspectionForm.date,
      site_title: inspectionForm.site_title ?? deleteField(),
      site_id: inspectionForm.site_id ?? deleteField(),
      site_photo_thumbnail_url: inspectionForm.site_photo_thumbnail_url || deleteField(),
      client_id: inspectionForm.client_id ?? deleteField(),
      client_name: inspectionForm.client_name ?? deleteField(),
      folder_id: folderId, // Always ensure a value is provided, using folder or inspectionForm data.
      folder_title: folderTitle, // Same logic for folder title.
      date_created: inspectionForm.date_created ?? serverTimestamp(),
      overall_score: inspectionForm.overall_score ?? 100,
      created_by: inspectionForm.created_by || `${user.name_first} ${user.name_last}`,
      created_by_id: inspectionForm.created_by_id || user.user_id,
      overall_score_lower: inspectionForm.overall_score_lower ?? 50,
      overall_score_upper: inspectionForm.overall_score_upper ?? 90,
      is_deleted: false,
      last_event: lastEvent,
    };
  }

  /**
   * Create Introduction Object
   */
  createIntroductionObject(lastEvent: WithFieldValue<WriteEvent>): WithFieldValue<SupplementaryEnhanced> {
    return {
      title: "Introduction",
      content: "",
      type: "introduction",
      order: 0,
      is_deleted: false,
      last_event: lastEvent,
    };
  }

  /**
   * Create Internal Object
   */
  createInternalObject(lastEvent: WithFieldValue<WriteEvent>): WithFieldValue<SupplementaryEnhanced> {
    return {
      title: "Internal",
      content: "",
      type: "internal",
      order: 0,
      is_deleted: false,
      last_event: lastEvent,
    };
  }

  /**
   * Create Summary Object
   */
  createSummaryObject(lastEvent: WithFieldValue<WriteEvent>): WithFieldValue<SupplementaryEnhanced> {
    return {
      title: "Summary",
      content: "",
      type: "summary",
      order: 0,
      is_deleted: false,
      last_event: lastEvent,
    };
  }

  /**
   * Save Preferred Theme To Inspection
   * @param workspaceId
   * @param inspectionId
   * @param preferredTheme
   */
  async savePreferredThemeToInspection(workspaceId: string, inspectionId: string, preferredTheme: string): Promise<void> {
    const path = this.collectionsService.inspectionsCol(workspaceId);
    const doc = `${path}/${inspectionId}`;
    return await this.firestoreUtilsService.setDocumentData(doc, { preferred_theme: preferredTheme });
  }
}
