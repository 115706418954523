<form [formGroup]="themeSettingsForm" class="px-4">
  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false" (click)="toggleContentOptions()">
        <span class="font-medium text-gray-900">Colour</span>
        <span class="ml-6 flex items-center">
          @if (!toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
          }
          @if (toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
            </svg>
          }
        </span>
      </button>
    </h3>
    @if (toggleContent) {
      <div class="pt-6">
        <div class="space-y-4">
          <div class="flex w-full cursor-pointer items-center" (click)="brandColourToggle = !brandColourToggle" #brandColourButton>
            <input
              #brandColourInput
              class="h-5 w-5 rounded-full"
              [ngStyle]="{ color: themeSettingsForm.value.colours_brand }"
              [style.background]="themeSettingsForm.value.colours_brand"
              [cpAlphaChannel]="'disabled'"
              [cpOKButton]="true"
              [cpOKButtonClass]="'btn btn-primary'"
              [cpOKButtonText]="'Ok'"
              [cpSaveClickOutside]="true"
              [(colorPicker)]="themeSettingsForm.value.colours_brand"
              [cpPosition]="'top'"
              [cpIgnoredElements]="[brandColourButton, brandColourInput]"
              [(cpToggle)]="brandColourToggle"
              (colorPickerSelect)="cmyk = onChangeColor($event)"
              (colorPickerChange)="setColourBrandOnForm($event)"
              formControlName="colours_brand" />
            <label class="ml-3 cursor-pointer text-sm text-text_Title">Brand</label>
          </div>

          <div class="flex w-full cursor-pointer items-center" (click)="subTitleColourToggle = !subTitleColourToggle" #subTitleColourButton>
            <input
              #subTitleColourInput
              class="h-5 w-5 rounded-full"
              [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }"
              [style.background]="themeSettingsForm.value.colours_subtitle"
              [cpAlphaChannel]="'disabled'"
              [cpOKButton]="true"
              [cpOKButtonClass]="'btn btn-primary'"
              [cpOKButtonText]="'Ok'"
              [cpSaveClickOutside]="true"
              [(colorPicker)]="themeSettingsForm.value.colours_subtitle"
              [cpPosition]="'top'"
              [cpIgnoredElements]="[subTitleColourButton, subTitleColourInput]"
              [(cpToggle)]="subTitleColourToggle"
              (colorPickerSelect)="cmyk = onChangeColor($event)"
              (colorPickerChange)="setColourSubTitleOnForm($event)"
              formControlName="colours_subtitle" />
            <label class="ml-3 cursor-pointer text-sm text-text_Title">Subtitle</label>
          </div>

          <div class="flex w-full cursor-pointer items-center" (click)="contentColourToggle = !contentColourToggle" #contentColourButton>
            <input
              #contentColourInput
              class="h-5 w-5 rounded-full"
              [ngStyle]="{ color: themeSettingsForm.value.colours_content }"
              [style.background]="themeSettingsForm.value.colours_content"
              [cpAlphaChannel]="'disabled'"
              [cpOKButton]="true"
              [cpOKButtonClass]="'btn btn-primary'"
              [cpOKButtonText]="'Ok'"
              [cpSaveClickOutside]="true"
              [(colorPicker)]="themeSettingsForm.value.colours_content"
              [cpPosition]="'top'"
              [cpIgnoredElements]="[contentColourButton, contentColourInput]"
              [(cpToggle)]="contentColourToggle"
              (colorPickerSelect)="cmyk = onChangeColor($event)"
              (colorPickerChange)="setColourContentOnForm($event)"
              formControlName="colours_content" />
            <label class="ml-3 cursor-pointer text-sm text-text_Title">Content</label>
          </div>
        </div>
      </div>
    }
  </div>
</form>
