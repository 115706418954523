import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TemplateCategoryEnhanced } from "src/app/models/category/template-category.model";

@Component({
  selector: "manage-template-items-list-categories-header",
  standalone: true,
  imports: [],
  templateUrl: "./manage-template-items-list-categories-header.component.html",
  styleUrl: "./manage-template-items-list-categories-header.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateItemsListCategoriesHeaderComponent implements OnInit {
  @Input({ required: true }) categories!: TemplateCategoryEnhanced[];
  @Input({ required: true }) categoryId!: string;
  @Output() categorySelectOutput = new EventEmitter<string>();

  public categoriesListDropDown = false;
  public currentCategoryIndex = 0;
  public currentCategory!: TemplateCategoryEnhanced;

  ngOnInit(): void {
    this.setCurrentCategory(this.categoryId);
  }

  private setCurrentCategory(categoryId: string): void {
    const foundCategory = this.categories.find((obj) => obj.id === categoryId);
    if (foundCategory) {
      this.currentCategory = foundCategory;
      this.currentCategoryIndex = this.categories.findIndex((object) => object.id === categoryId);
    } else {
      console.error(`Category with id ${categoryId} not found.`);
    }
  }

  public next() {
    this.currentCategoryIndex = this.currentCategoryIndex + 1;
    this.currentCategory = this.categories[this.currentCategoryIndex];
    this.navigateForNextAndPrevious(this.currentCategory.id);
    return this.categories[this.currentCategoryIndex];
  }

  public previous() {
    if (this.currentCategoryIndex === 0) {
      this.currentCategoryIndex = this.categories.length;
    }
    this.currentCategoryIndex = this.currentCategoryIndex - 1;
    this.currentCategory = this.categories[this.currentCategoryIndex];
    this.navigateForNextAndPrevious(this.currentCategory.id);
    return this.categories[this.currentCategoryIndex];
  }

  public navigateForNextAndPrevious(categoryId: string): void {
    this.categorySelectOutput.emit(categoryId);
    this.categoriesListDropDown = false;
  }

  public navigateCategoryItem(categoryId: string): void {
    this.categorySelectOutput.emit(categoryId);
    this.categoriesListDropDown = false;
    this.setCurrentCategory(categoryId);
  }

  public toggleCategoriesListDropDown(): void {
    this.categoriesListDropDown = !this.categoriesListDropDown;
  }
}
