import { NgClass } from "@angular/common";
import { Component, inject, Input, OnDestroy, signal } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from "@angular/router";
import { RolePermissions } from "cip";
import { filter, Subscription } from "rxjs";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { CurrentWorkspaceMenuComponent } from "../current-workspace-menu/current-workspace-menu.component";

@Component({
  selector: "current-workspace-wrapper",
  standalone: true,
  imports: [CurrentWorkspaceMenuComponent, RouterModule, NgClass],
  templateUrl: "./current-workspace-wrapper.component.html",
  styleUrl: "./current-workspace-wrapper.component.scss",
})
export class CurrentWorkspaceWrapperComponent implements OnDestroy {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private limitationManagerService = inject(LimitationManagerService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  // Properties
  public limitationResult: boolean = false;
  public memberRole = this.limitationManagerService.memberDoc.value?.role_id || "";
  private _workspaceId!: string;
  public isFullWidth = signal<boolean>(false);
  private routerSubscription: Subscription;

  constructor() {
    this.limitationManagerCheck();
    this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.checkChildRouteData();
    });
    this.checkChildRouteData();
  }

  private checkChildRouteData(): void {
    try {
      const children = this.activatedRoute.children;
      const hasFullWidthChild = children?.some((child) => child.snapshot?.data?.["widthStyle"] === "fullWidth");
      this.isFullWidth.set(!!hasFullWidthChild);
    } catch (error) {
      console.error("Error checking child route data:", error);
      this.isFullWidth.set(false);
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  /**
   * Limitation Manager Check
   */
  private async limitationManagerCheck(): Promise<void> {
    const featureAction: keyof RolePermissions = "workspace_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    limitationResult ? (this.limitationResult = true) : (this.limitationResult = false);
  }
}
