import { JsonPipe, NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { InfoViewFilter } from "src/app/models/strings/strings.model";
import { InfoViewComponent } from "../../info-view/info-view.component";

@Component({
  selector: "permissions-grid",
  standalone: true,
  imports: [ReactiveFormsModule, InfoViewComponent, NgTemplateOutlet, JsonPipe],
  templateUrl: "./permissions-grid.component.html",
  styleUrl: "./permissions-grid.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsGridComponent {
  // Role form
  // When used on the member editor, this is actually the member form...
  // ...however, as both the member and role documents contain a `permissions` object, this works out nicely
  @Input({ required: true }) roleForm!: FormGroup;

  // Toggles the UI if the value is true
  // The value will be true if on the members document, as the permissions aren't editable directly there
  @Input({ required: true }) isDisabled!: boolean;

  @Input() infoViewPermissionsEditor!: InfoViewFilter;
}
