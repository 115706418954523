<div class="content-card-wrapper">
  <div class="content-card-header flex items-center justify-between">
    <h3>Assignee</h3>
  </div>

  <div class="px-6 py-5">
    <form [formGroup]="actionForm" class="form-input-wrapper relative flex h-[60px] cursor-pointer items-center hover:ring-gray-400" (click)="toggleAssigneeSelectorOverlay()">
      <div class="text-brand_Primary-hover">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
        </svg>
      </div>

      <div class="w-full border-0 pl-3 text-base focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-700 sm:leading-6" [ngClass]="actionForm.value.assignee_name ? 'text-text_Title' : 'text-text_Placeholder'">
        {{ actionForm.value.assignee_name ? actionForm.value.assignee_name : "No Assignee" }}
      </div>

      <div class="absolute left-0 top-0 flex h-full w-full items-center justify-end pr-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="-mr-1 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#C8C8C8" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </form>
  </div>
</div>

@if (overlay_assignee) {
  <action-edit-assignee-selector-wrapper [actionForm]="actionForm" [workspaceId]="workspaceId" [user]="user" (toggleAssigneeSelectorWrapperOverlayOutput)="toggleAssigneeSelectorOverlay()" />
}
