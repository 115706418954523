import { inject, Injectable } from '@angular/core';
import { Auth, user, User } from '@angular/fire/auth';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileCompleteGuard implements CanActivate {
  private auth = inject(Auth);
  private router = inject(Router);
  private usersService = inject(UsersService);

  canActivate(): Observable<boolean> {
    return user(this.auth).pipe(
      take(1),
      switchMap((authState: User | null) => {
        if (!authState) {
          // User not authenticated
          return of(false);
        }
        return this.usersService.getUserById(authState.uid).pipe(
          take(1),
          map((user) => {
            if (user && user.name_first && user.name_last) {
              // Profile is complete
              return true;
            } else {
              // Profile is incomplete
              return false;
            }
          }),
          tap((profileComplete) => {
            if (!profileComplete) {
              this.router.navigate(['/profilecomplete']);
            }
          })
        );
      })
    );
  }
}
