import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SignatureEnhanced } from 'src/app/models/inspection/signature.model';

@Component({
  selector: 'inspection-overview-sign-off',
  standalone: true,
  imports: [RouterModule, NgClass, DatePipe],
  templateUrl: './inspection-overview-sign-off.component.html',
  styleUrl: './inspection-overview-sign-off.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionOverviewSignOffComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) signatures: SignatureEnhanced[] = [];
}
