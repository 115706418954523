<header>
  <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" />
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Categories</h2>
      </div>
      <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
        @if (categories.length > 0 && !editMode) {
          <search-bar [placeholder]="'Search Categories'" (searchTermChange)="onSearch($event)" />
        }

        <div class="ml-0 flex flex-shrink-0 flex-col sm:ml-3 sm:flex-row">
          @if (categories.length > 0) {
            @if (!editMode) {
              <button class="btn mr-3 border-gray-200 bg-white hover:bg-gray-100" aria-label="Toggle Enabled Categories" (click)="toggleDisabledCategories()" title="Hide and Show Disabled Categories" alt="Hide and Show Disabled Categories">
                @if (!toggleDisabled) {
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                  </svg>
                }
                @if (toggleDisabled) {
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5" title="Show disabled Categories" alt="Show disabled Categories">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                }
              </button>
            }
            @if (categoriesBulkEditArray && categoriesBulkEditArray.length > 0) {
              <div class="flex">
                <button type="button" class="btn btn-secondary mr-3" aria-label="Bulk Delete" (click)="bulkDeleteCategories()">Bulk Delete</button>
                <button type="button" class="btn btn-secondary mr-3" aria-label="Enable or Disable" (click)="bulkEnableOrDisableCategories()">Enable/Disable</button>
              </div>
            }
            @if (categories.length > 0) {
              <button class="btn mr-3" aria-label="Edit Mode" (click)="toggleEditMode()" [ngClass]="editMode ? 'btn-primary' : 'btn-secondary'">
                {{ editMode ? "Done" : "Edit" }}
              </button>
            }
          }
          @if (!editMode) {
            <button type="button" class="btn btn-primary" aria-label="New Category" (click)="toggleNewCategory()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Category
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</header>
