@switch (item.answer_type) {
  @case ("test-fail-pass") {
    <div class="list-meta uppercase">Fail | Pass</div>
  }
  @case ("test-no-yes") {
    <div class="list-meta uppercase">No | Yes</div>
  }
  @case ("test-low-high") {
    <div class="list-meta uppercase">Low | High</div>
  }
  @case ("test-red-amber-green") {
    <div class="list-meta uppercase">Red | Amber | Green</div>
  }
  @case ("test-poor-fair-good") {
    <div class="list-meta uppercase">Poor | Fair | Good</div>
  }
  @case ("test-progress") {
    <div class="list-meta uppercase">Not Started | In progress | Completed</div>
  }
}
