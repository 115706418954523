import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TooltipComponent } from "src/app/shared/tooltip/tooltip.component";

@Component({
  selector: "test-form-title",
  standalone: true,
  imports: [ReactiveFormsModule, TooltipComponent],
  templateUrl: "./test-form-title.component.html",
  styleUrl: "./test-form-title.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestFormTitleComponent {
  @Input({ required: true }) testForm!: FormGroup;
  @Input({ required: true }) currentCategoryOrder!: number;
  @Output() enableOrDisableTestOutput = new EventEmitter<void>();

  /**
   * Enable or disable the test
   */
  public enableOrDisableTest(): void {
    this.enableOrDisableTestOutput.emit();
  }
}
