@if (inspections$ | async; as inspections) {
  @if (inspectionsListService.reorderedInspections$ | async; as reorderedInspections) {
    <inspections-list-header
      [inspections]="inspections"
      [workspaceId]="workspaceId"
      [folderId]="folderId"
      [currentlySelectedInspectionSortChoice]="inspectionsListService.currentlySelectedInspectionSortChoice"
      [isSortOrderAscending]="inspectionsListService.isSortOrderAscending"
      [sortDropdown]="inspectionsListService.sortDropdown"
      (sortDropdownOutput)="toggleSortDropdown()"
      (newInspectionOutput)="canUserAddInspection()"
      (filterChoiceOutput)="changeInspectionSortChoice($event)"
      (searchTermChange)="updateSearchTerm($event)"
    />
    @if (inspections.length > 0) {
      <inspections-list [inspections]="reorderedInspections" [workspaceId]="workspaceId" [folderId]="folderId" [searchTerm]="currentSearchTerm" />
    } @else {
      <empty-list [list]="'inspection'" />
    }
  } @else {
    <loading-spinner />
  }
}
@if (overlay_newInspection) {
  <inspection-new-overlay [inspectionForm]="inspectionForm" (toggleNewInspectionOverlayOutput)="toggleNewInspectionOverlay()" (saveInspectionOutput)="saveInspection()" />
}
