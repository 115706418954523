<div class="supplementary-wrapper">
  <div class="header">
    <p class="title text-group-1" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">{{ title }}</p>
    <div class="divider" [ngStyle]="{ 'background-color': themeSettingsForm.value.colours_brand }"></div>
  </div>
  <pre class="supplementary-content text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">
      This category focuses on the evaluation of the bank's workforce. It encompasses aspects such as staffing levels, staff training and development, compliance with HR policies, and adherence to code of conduct.
      Ensuring a well-trained, motivated, and compliant workforce is crucial for maintaining top-notch customer service and operational efficiency.
    </pre
  >
</div>
