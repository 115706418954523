import { DatePipe, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TemplateEnhanced } from "src/app/models/template/template.model";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "manage-templates-list",
  standalone: true,
  imports: [SearchEmptyComponent, NgStyle, DatePipe],
  templateUrl: "./manage-templates-list.component.html",
  styleUrl: "./manage-templates-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplatesListComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true })
  set templates(value: TemplateEnhanced[]) {
    this._templates = value;
    this.updateFilteredTemplatesForSearchResults();
  }

  get templates(): TemplateEnhanced[] {
    return this._templates;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredTemplatesForSearchResults();
  }

  @Output() routeToTemplateOverviewOutput = new EventEmitter<string>();

  // Properties
  private _templates: TemplateEnhanced[] = [];
  public _searchTerm: string = "";
  public filteredTemplates: TemplateEnhanced[] = [];
  public allTemplatesHidden: boolean = false;

  updateFilteredTemplatesForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredTemplates = this.templates.map((template) => ({
      ...template,
      hidden: !template.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allTemplatesHidden = this.filteredTemplates.every((template) => template.hidden);
  }

  public routeToTemplateOverview(templateId: string): void {
    this.routeToTemplateOverviewOutput.emit(templateId);
  }
}
