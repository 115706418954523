<form [formGroup]="categoryForm" class="grid gap-x-4 gap-y-6">
  <div class="sm:col-span-6">
    <div class="form-input-wrapper">
      <label for="category-title" class="form-input-label"
        >Title*
        @if (!categoryForm.pristine && categoryForm.controls["title"].errors) {
          <span class="required-field"> - Title is required</span>
        }
      </label>
      <input type="text" name="category-title" id="category-title" class="form-input" placeholder="Title" formControlName="title" />
    </div>
  </div>
  <div class="sm:col-span-6">
    <div class="form-input-wrapper">
      <label for="category-notes" class="form-input-label flex flex-row items-center">
        <span class="flex flex-row gap-x-2">
          <span>Notes</span>
          <tooltip [toolTipText]="toolTipCategoryNotes" />
        </span>
      </label>
      <textarea type="text" name="category-notes" id="category-notes" class="form-input" placeholder="Notes" formControlName="notes"></textarea>
    </div>
  </div>
</form>
