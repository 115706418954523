import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MemberEnhanced } from "src/app/models/member/member.model";

@Component({
  selector: "manage-role-assigned-members",
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: "./manage-role-assigned-members.component.html",
  styleUrl: "./manage-role-assigned-members.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRoleAssignedMembersComponent {
  @Input({ required: true }) assignedMembers!: MemberEnhanced[];

  // Properties
  public assignedMembersAccordion = false;

  /**
   * Display Assigned Members
   */
  displayAssignedMembers(): void {
    this.assignedMembersAccordion = !this.assignedMembersAccordion;
  }
}
