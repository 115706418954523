import { inject, Injectable } from '@angular/core';
import { orderBy, where } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { CollectionsService } from 'src/app/core/services/collections/collections.service';
import { FirestoreUtilsService } from 'src/app/core/services/firestore/firestore-utils.service';
import { ClientEnhanced } from 'src/app/models/client/client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientsListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Clients List
   * @param workspaceId
   */
  public getClientsList$(workspaceId: string): Observable<ClientEnhanced[]> {
    const path = this.collectionsService.clientsCol(workspaceId);
    const queryConstraints = [where('is_deleted', '==', false), orderBy('name', 'asc')];
    return this.firestoreUtilsService.getCollectionData<ClientEnhanced>(path, queryConstraints);
  }
}
