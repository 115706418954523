import { DatePipe, NgClass, NgStyle } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { DateFormatPipe } from "src/app/core/pipes/date-format.pipe";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-content-photos",
  standalone: true,
  imports: [NgClass, DateFormatPipe, DatePipe, NgStyle],
  templateUrl: "./report-content-photos.component.html",
  styleUrl: "./report-content-photos.component.scss",
})
export class ReportContentPhotosComponent {
  @Input({ required: true }) itemId!: string;

  // Ser
  public reportsService = inject(ReportsService);
}
