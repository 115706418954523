import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";

@Component({
  selector: "account-subscription",
  standalone: true,
  imports: [DatePipe, InfoViewComponent],
  templateUrl: "./account-subscription.component.html",
  styleUrl: "./account-subscription.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSubscriptionComponent {
  // Services
  private infoViewStringsService = inject(InfoViewStringsService);
  public limitationManagerService = inject(LimitationManagerService);

  // Properties
  public infoViewSubscription: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.Subscription);
}
