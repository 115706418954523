import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";

@Component({
  selector: "manage-templates-list-header",
  standalone: true,
  imports: [NgClass, SearchBarComponent],
  templateUrl: "./manage-templates-list-header.component.html",
  styleUrl: "./manage-templates-list-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplatesListHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) templatesCount!: number;
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() toggleNewTemplateOverlayOutput = new EventEmitter<void>();
  @Output() toggleImportTemplateOverlayOutput = new EventEmitter<void>();
  /**
   * On Search
   * @param searchTerm
   */
  public onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Toggle New Template Overlay
   */
  public toggleNewTemplateOverlay(): void {
    this.toggleNewTemplateOverlayOutput.emit();
  }

  /**
   * Toggle Import Template Overlay
   */
  public toggleImportTemplateOverlay(): void {
    this.toggleImportTemplateOverlayOutput.emit();
  }
}
