import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-update",
  standalone: true,
  imports: [],
  templateUrl: "./app-update.component.html",
  styleUrl: "./app-update.component.scss",
})
export class AppUpdateComponent {
  public currentAppVersion = environment.version;
  activateUpdate() {
    window.location.reload();
  }
}
