import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemeEnhanced } from "src/app/models/theme/theme.model";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";

@Component({
  selector: "manage-themes-list-header",
  standalone: true,
  imports: [SearchBarComponent, NgClass],
  templateUrl: "./manage-themes-list-header.component.html",
  styleUrl: "./manage-themes-list-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemesListHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) themesCount!: number;
  @Input({ required: true }) editMode!: boolean;
  @Input() themesBulkEditArray: ThemeEnhanced[] | null = [];
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() routeToThemeEditorOutput = new EventEmitter<void>();
  @Output() toggleEditModeOutput = new EventEmitter<void>();
  @Output() bulkDeleteThemesOutput = new EventEmitter<void>();

  /**
   * On Search
   * @param searchTerm
   */
  public onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Bulk Delete Themes
   */
  public bulkDeleteThemes(): void {
    this.bulkDeleteThemesOutput.emit();
  }

  /**
   * Route To Theme Editor
   */
  public routeToThemeEditor(): void {
    this.routeToThemeEditorOutput.emit();
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.toggleEditModeOutput.emit();
  }
}
