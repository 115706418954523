@if (inspection$ | async; as inspection) {
  <inspection-details-header [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" />

  <div class="grid grid-cols-1 gap-y-8">
    <!-- Form State Top-->
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="inspectionForm.pristine"></form-state>
      <button
        type="button"
        (click)="saveInspection()"
        class="btn"
        [disabled]="inspectionForm.pristine || inspectionForm.controls['title'].errors"
        [ngClass]="inspectionForm.pristine || inspectionForm.controls['title'].errors ? 'btn-disabled' : 'btn-primary'"
      >
        Save
      </button>
    </div>

    <!-- Details -->
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Details</h3>
      </div>
      <div class="px-6 py-5">
        <inspection-edit-details [inspectionForm]="inspectionForm" />
      </div>
    </div>

    <!-- Site -->
    <inspection-details-site-wrapper [inspectionForm]="inspectionForm" [workspaceId]="workspaceId" [user]="user" />

    <!-- Client -->
    <inspection-details-Client-wrapper [inspectionForm]="inspectionForm" [workspaceId]="workspaceId" [user]="user" />

    <!-- Folder -->
    <inspection-details-folder-wrapper [inspectionForm]="inspectionForm" [workspaceId]="workspaceId" [user]="user" />

    <!-- <inspection-form-client [user]="user" [inspectionForm]="inspectionForm" [workspaceId]="data.routeParams.workspaceId"></inspection-form-client> -->

    <!-- Form State Bottom-->
    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="inspectionForm.pristine"></form-state>
        <button
          type="button"
          (click)="saveInspection()"
          class="btn"
          [disabled]="inspectionForm.pristine || inspectionForm.controls['title'].errors"
          [ngClass]="inspectionForm.pristine || inspectionForm.controls['title'].errors ? 'btn-disabled' : 'btn-primary'"
        >
          Save
        </button>
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}
