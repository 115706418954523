<div class="mb-6 w-full rounded-md bg-white px-12 py-8 dark:bg-slate-700">
  <h1 class="text_Title prose dark:prose-invert mb-6 text-4xl font-bold">Your Workspaces</h1>
  <p class="prose dark:prose-invert pb-8 font-normal text-[#878787] dark:text-slate-300">
    A Workspace allows you and your team to all access the same projects. When you invite other users to this workspace they will be able to see the Inspections and edit them based on the permissions you give them.
  </p>

  @if (workspaces) {
    <ul id="workspaceList" role="list" class="list-ul">
      @for (workspace of workspaces; track $index) {
        <li class="list-row">
          <a [routerLink]="['/', 'workspace', workspace.id, 'folders']" class="list-a group">
            @if (workspace.image_thumbnail_url) {
              <div class="flex h-12 w-12 rounded-md bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + workspace.image_thumbnail_url + ')' }"></div>
            } @else {
              <div class="flex h-12 w-12 items-center justify-center rounded bg-gray-200">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                  />
                </svg>
              </div>
            }

            <div class="flex w-full flex-col gap-x-4 sm:flex-row">
              <div class="min-w-0 flex-auto">
                <p class="list-title">{{ workspace.name }}</p>
                <p class="list-meta">{{ workspace.current_owner }}</p>
              </div>
            </div>
          </a>
          <div class="flex">
            <button type="button" class="list-icon" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true" (click)="toggleLeaveWorkspaceOverlay(workspace)">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
              </svg>
            </button>
          </div>
        </li>
      }
    </ul>
  } @else {
    <div class="flex w-full items-center justify-center">
      <loading-spinner />
    </div>
  }
</div>

<!-- <ng-container *ngIf="workspacesService.overlay_leaveWorkspace && workspaceToLeave">
  <workspace-leave-wrapper [user]="user" [workspace]="workspaceToLeave"></workspace-leave-wrapper>
</ng-container> -->
