import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InspectionEditDetailsComponent } from '../inspection-edit-details/inspection-edit-details.component';

@Component({
  selector: 'inspection-new-overlay',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, InspectionEditDetailsComponent],
  templateUrl: './inspection-new-overlay.component.html',
  styleUrl: './inspection-new-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionNewOverlayComponent {
  @Input({ required: true }) inspectionForm!: FormGroup;
  @Output() toggleNewInspectionOverlayOutput = new EventEmitter<void>();
  @Output() saveInspectionOutput = new EventEmitter<void>();

  /**
   * Save Inspection
   */
  public saveInspection(): void {
    this.saveInspectionOutput.emit();
  }

  /**
   * Toggle New Inspection Overlay
   */
  public toggleNewInspectionOverlay(): void {
    this.toggleNewInspectionOverlayOutput.emit();
  }
}
