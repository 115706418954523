@if (clients$ | async; as clients) {
  <div class="flex min-w-0 flex-1">
    <div class="flex h-full min-w-0 flex-1 flex-col overflow-hidden">
      @if (clientsBulkEditArray$ | async; as clientsBulkEditArray) {
        <manage-clients-list-header
          [workspaceId]="workspaceId"
          [clientsCount]="clients.length"
          [editMode]="editMode"
          [clientsBulkEditArray]="clientsBulkEditArray"
          (searchTermChange)="searchTermChange($event)"
          (toggleNewClientOverlayOutput)="canUserCreateClient()"
          (toggleEditModeOutput)="toggleEditMode()"
          (bulkDeleteClientsOutput)="setDeleteBatchClientsProperties()" />

        @if (clients.length > 0) {
          <manage-clients-list
            [clients]="clients"
            [workspaceId]="workspaceId"
            [searchTerm]="currentSearchTerm"
            [editMode]="editMode"
            [clientsBulkEditArray]="clientsBulkEditArray"
            (deleteSingleClientOutput)="setDeleteSingleClientProperties($event)"
            (createBatchEditOutput)="createBatchEdit($event)"
            (routeToClientEditorOutput)="routeToClientEditor($event)" />
        } @else {
          <empty-list [list]="'client'" />
        }
      }
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteClient()) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (deleteButtonClicked)="deleteSingleOrMultipleClients()" (cancelButtonClicked)="toggleDeleteClientOverlay(false)" />
}

@if (overlay_newClient) {
  <manage-client-new-overlay
    (toggleNewClientOverlayOutput)="toggleNewClientOverlay()"
    [clientForm]="clientForm"
    [workspaceId]="workspaceId"
    [newclientId]="newclientId"
    [uploadFileArray]="uploadFileArray"
    [overlay_uploadPhotos]="overlay_uploadPhotos"
    [photoPreview]="photoPreview"
    (detectPhotosOutput)="detectPhotos($event)"
    (removePhotoFromArrayOutput)="removePhotoFromArray($event)"
    (saveClientOutput)="doesAPhotoNeedUploading()"
    (returnedPhotoOutput)="patchFormWithPhotosAndSaveDoc($event)" />
}
