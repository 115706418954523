<div class="item-wrapper">
  <div class="item-wrapper-inner">
    <div class="item-inner-content">
      <div class="item-header">
        <p class="item-title text-group-2">1.1. Efforts to maintain a skilled and motivated team are evident</p>
      </div>
      <div class="item-pills">
        <p class="item-mode text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Test</p>
        <div class="item-photos text-group-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11" fill="none">
            <rect x="1.28564" y="1.21431" width="11.1429" height="8.57143" rx="0.857143" fill="white" [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }" stroke-width="0.857143" />
            <path
              d="M8.99993 7.64293L8.14279 6.78578M8.14279 6.78578L5.8744 4.5174C5.68037 4.32336 5.4172 4.21436 5.14279 4.21436V4.21436V4.21436C4.86838 4.21436 4.60521 4.32336 4.41117 4.5174L1.71422 7.21436L1.28564 7.64293M8.14279 6.78578L9.12546 5.80312C9.31949 5.60908 9.58266 5.50007 9.85707 5.50007V5.50007C10.1315 5.50007 10.3947 5.60908 10.5887 5.80312L11.9999 7.21436L12.4285 7.64293"
              [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }"
              stroke-width="0.857143"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <circle cx="8.14279" cy="3.35722" r="0.857143" [ngStyle]="{ fill: themeSettingsForm.value.colours_subtitle }" />
          </svg>
          <p [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">2 Photos</p>
        </div>
        <div class="item-pills-split"></div>
        <div class="item-actions text-group-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" fill="none">
            <circle cx="6.78564" cy="6.14279" r="5.5" fill="white" [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }" />
            <path d="M4.6713 6.17216L6.15617 7.65718L9.04294 4.77061" [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }" stroke-width="0.857143" />
          </svg>
          <p [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">2 Actions</p>
        </div>

        <!-- Demo Content -->
        <div class="item-result text-group-5 item-result-amber">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
            <rect x="0.921875" y="0.491211" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
            <path d="M8.54688 3.54199V8.11771L11.0889 10.1514" stroke="#F1AB42" stroke-width="1.52524" stroke-linecap="square" stroke-linejoin="round" />
          </svg>
          <p>In Progress</p>
        </div>
        <!-- Actual Conditions -->
        <!-- <ng-template [ngIf]="action.test_answer === 'pass'" #pass></ng-template>
      <ng-template [ngIf]="action.test_answer === 'yes'" #yes></ng-template>
      <ng-template [ngIf]="action.test_answer === 'high'" #high></ng-template>
      <ng-template [ngIf]="action.test_answer === 'green'" #green></ng-template>
      <ng-template [ngIf]="action.test_answer === 'good'" #good></ng-template>
      <ng-template [ngIf]="action.test_answer === 'progress-100'" #completed></ng-template>

      <ng-template [ngIf]="action.test_answer === 'amber'" #amber></ng-template>
      <ng-template [ngIf]="action.test_answer === 'fair'" #fair></ng-template>
      <ng-template [ngIf]="action.test_answer === 'progress-50'" #inProgress></ng-template>

      <ng-template [ngIf]="action.test_answer === 'fail'" #fail></ng-template>
      <ng-template [ngIf]="action.test_answer === 'no'" #no></ng-template>
      <ng-template [ngIf]="action.test_answer === 'low'" #low></ng-template>
      <ng-template [ngIf]="action.test_answer === 'red'" #red></ng-template>
      <ng-template [ngIf]="action.test_answer === 'poor'" #poor></ng-template>
      <ng-template [ngIf]="action.test_answer === 'progress-0'" #notStarted></ng-template>

      --></div>
      <p class="item-notes text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">
        Efforts to maintain a skilled and motivated team are evident in our ongoing training and development initiatives. Ensuring that each employee is equipped with the necessary skills and knowledge not only elevates our customer service but also
        enhances our compliance with HR policies and industry regulations.
      </p>
    </div>
    <div class="item-photos-wrapper text-group-5">
      <manage-theme-edit-preview-item-photos [themeSettingsForm]="themeSettingsForm" />
    </div>
  </div>
  <manage-theme-edit-preview-action [themeSettingsForm]="themeSettingsForm" />
</div>

<div class="item-wrapper">
  <div class="item-wrapper-inner">
    <div class="item-inner-content">
      <div class="item-header">
        <p class="item-title text-group-2">1.2. Efforts to maintain a skilled and motivated team are evident</p>
      </div>
      <div class="item-pills">
        <p class="item-mode text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Question</p>
      </div>
      <p class="item-notes text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">
        Efforts to maintain a skilled and motivated team are evident in our ongoing training and development initiatives. Ensuring that each employee is equipped with the necessary skills and knowledge not only elevates our customer service but also
        enhances our compliance with HR policies and industry regulations.
      </p>
      <div class="item-answer-wrapper">
        <p class="item-answer-title text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">Answer</p>
        <p class="item-answer text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">Efforts to maintain a skilled and motivated team are evident in our ongoing training and development initiatives.</p>
      </div>
    </div>
  </div>
</div>

<!-- Pass -->
<ng-template #pass>
  <div class="item-result text-group-5 item-result-green">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" fill="none">
      <circle cx="6.78564" cy="6.14279" r="5.5" fill="white" stroke="#fff" />
      <path d="M4.6713 6.17216L6.15617 7.65718L9.04294 4.77061" stroke="#61788D" stroke-width="0.857143" />
    </svg>
    <p>Pass</p>
  </div>
</ng-template>

<!-- Yes -->
<ng-template #yes>
  <div class="item-result text-group-5 item-result-green">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" fill="none">
      <circle cx="6.78564" cy="6.14279" r="5.5" fill="white" stroke="#fff" />
      <path d="M4.6713 6.17216L6.15617 7.65718L9.04294 4.77061" stroke="#61788D" stroke-width="0.857143" />
    </svg>
    <p>Yes</p>
  </div>
</ng-template>

<!-- High -->
<ng-template #high>
  <div class="item-result text-group-5 item-result-green">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="15.2524" height="15.2524" rx="7.6262" transform="matrix(1 0 0 -1 0.224609 15.624)" fill="white" />
      <path d="M7.85081 12.5732V4.18442M7.85081 4.18442L4.35547 7.67976M7.85081 4.18442L11.3462 7.67976" stroke="#439457" stroke-width="1.52524" stroke-linejoin="round" />
    </svg>
    <p>High</p>
  </div>
</ng-template>

<!-- Green -->
<ng-template #green>
  <div class="item-result text-group-5 item-result-green">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.224609" y="0.078125" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <circle cx="7.85231" cy="7.70485" r="2.28786" fill="#439457" />
    </svg>
    <p>Green</p>
  </div>
</ng-template>

<!-- Good -->
<ng-template #good>
  <div class="item-result text-group-5 item-result-green">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <rect width="15.2524" height="15.2524" rx="7.6262" transform="matrix(1 0 0 -1 0.224609 16.0371)" fill="white" />
      <path d="M7.85081 12.9873V4.59848M7.85081 4.59848L4.35547 8.09383M7.85081 4.59848L11.3462 8.09383" stroke="#439457" stroke-width="1.52524" stroke-linejoin="round" />
    </svg>
    <p>Good</p>
  </div>
</ng-template>

<!-- Completed -->
<ng-template #completed>
  <div class="item-result text-group-5 item-result-green">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.224609" y="0.0166016" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <path d="M11.6644 5.35547L6.99129 10.036L4.80078 7.8454" stroke="#439457" stroke-width="1.52524" stroke-linecap="square" stroke-linejoin="round" />
    </svg>
    <p>Completed</p>
  </div>
</ng-template>

<!-- Amber -->
<ng-template #amber>
  <div class="item-result text-group-5 item-result-amber">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
      <rect x="0.921875" y="0.078125" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <circle cx="8.54763" cy="7.70485" r="2.28786" fill="#F1AB42" />
    </svg>
    <p>Amber</p>
  </div>
</ng-template>
<!-- Fair -->
<ng-template #fair>
  <div class="item-result text-group-5 item-result-amber">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <rect x="0.921875" y="0.785156" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <path d="M3.97266 7.96657H12.3615M12.3615 7.96657L8.86613 11.4619M12.3615 7.96657L8.86613 4.47123" stroke="#F1AB42" stroke-width="1.52524" stroke-linejoin="round" />
    </svg>
    <p>Fair</p>
  </div>
</ng-template>

<!-- In Progress -->
<ng-template #inProgress>
  <div class="item-result text-group-5 item-result-amber">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
      <rect x="0.921875" y="0.491211" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <path d="M8.54688 3.54199V8.11771L11.0889 10.1514" stroke="#F1AB42" stroke-width="1.52524" stroke-linecap="square" stroke-linejoin="round" />
    </svg>
    <p>In Progress</p>
  </div>
</ng-template>

<!-- Fail -->
<ng-template #fail>
  <div class="item-result text-group-5 item-result-red">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <rect x="0.617188" y="0.958008" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <path d="M5.19336 5.53418L11.2943 11.6351" stroke="#F23D3D" stroke-width="1.52524" stroke-linejoin="round" />
      <path d="M11.293 5.53418L5.19201 11.6351" stroke="#F23D3D" stroke-width="1.52524" stroke-linejoin="round" />
    </svg>
    <p>Fail</p>
  </div>
</ng-template>

<!-- No -->
<ng-template #no>
  <div class="item-result text-group-5 item-result-red">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <rect x="0.617188" y="0.958008" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <path d="M5.19336 5.53418L11.2943 11.6351" stroke="#F23D3D" stroke-width="1.52524" stroke-linejoin="round" />
      <path d="M11.293 5.53418L5.19201 11.6351" stroke="#F23D3D" stroke-width="1.52524" stroke-linejoin="round" />
    </svg>
    <p>No</p>
  </div>
</ng-template>

<!-- Low -->
<ng-template #low>
  <div class="item-result text-group-5 item-result-red">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.617188" y="0.371094" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <path d="M8.24339 3.42188V11.8107M8.24339 11.8107L4.74805 8.31535M8.24339 11.8107L11.7387 8.31535" stroke="#F23D3D" stroke-width="1.52524" stroke-linejoin="round" />
    </svg>
    <p>Low</p>
  </div>
</ng-template>

<!-- Red -->
<ng-template #red>
  <div class="item-result text-group-5 item-result-red">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.617188" y="0.078125" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <circle cx="8.24294" cy="7.70388" r="2.28786" fill="#F23D3D" />
    </svg>
    <p>Red</p>
  </div>
</ng-template>

<!-- Poor -->
<ng-template #poor>
  <div class="item-result text-group-5 item-result-red">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <rect x="0.617188" y="0.785156" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <path d="M8.24339 3.83594V12.2248M8.24339 12.2248L4.74805 8.72942M8.24339 12.2248L11.7387 8.72942" stroke="#F23D3D" stroke-width="1.52524" stroke-linejoin="round" />
    </svg>
    <p>Poor</p>
  </div>
</ng-template>

<!-- Not Started -->
<ng-template #notStarted>
  <div class="item-result text-group-5 item-result-red">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <rect x="0.617188" y="0.785156" width="15.2524" height="15.2524" rx="7.6262" fill="white" />
      <path d="M8.24339 3.83594V12.2248M8.24339 12.2248L4.74805 8.72942M8.24339 12.2248L11.7387 8.72942" stroke="#F23D3D" stroke-width="1.52524" stroke-linejoin="round" />
    </svg>
    <p>Not Started</p>
  </div>
</ng-template>
