import { NgClass } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "cip";
import { firstValueFrom } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { WorkspaceEnhanced } from "src/app/models/workspace/workspace.model";
import { DeleteConfirmationComponent } from "src/app/shared/delete-confirmation/delete-confirmation.component";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { WorkspacesService } from "../../services/workspaces.service";

@Component({
  selector: "current-workspace-delete",
  standalone: true,
  imports: [InfoViewComponent, NgClass, DeleteConfirmationComponent, FormsModule],
  templateUrl: "./current-workspace-delete.component.html",
  styleUrl: "./current-workspace-delete.component.scss",
})
export class CurrentWorkspaceDeleteComponent {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
    this.getCurrentWorkspace(value);
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private stringsService = inject(StringsService);
  private workspacesService = inject(WorkspacesService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private infoViewStringsService = inject(InfoViewStringsService);
  private authService = inject(AuthService);

  // Properties
  public deleteWorkspaceConfirmation = false;
  public overlay_deleteWorkspace = false;
  public deleteOverlayTitle!: string;
  public deleteOverlayDescription!: string;
  private workspace!: WorkspaceEnhanced;
  public infoViewDeleteWorkspace2: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.DeleteWorkspace2);
  private user: User;
  private _workspaceId!: string;

  constructor() {
    this.user = this.authService.currentUser;
  }

  async getCurrentWorkspace(workspaceId: string): Promise<void> {
    const workspace = await firstValueFrom(this.workspacesService.getWorkspaceDoc$(workspaceId));
    if (workspace) {
      this.workspace = workspace;
    } else {
      console.error("Workspace not found:", workspaceId);
    }
  }
  /**
   * Set Delete Confirmation Text and Toggle Delete Overlay
   */
  setDeleteConfirmationTextAndToggleDeleteOverlay(): void {
    this.toggleDeleteWorkspaceOverlay();
    const deleteWorkspace = this.stringsService.alertFilter(AlertType.DeleteWorkspace);
    if (deleteWorkspace) {
      this.deleteOverlayTitle = deleteWorkspace.title;
      this.deleteOverlayDescription = `${this.workspace.name} ${deleteWorkspace.description}`;
    } else {
      console.error("Unknown string type:", AlertType.DeleteWorkspace);
    }
  }

  /**
   * Delete Workspace
   */
  async deleteWorkspaceAndRouteToWorkspaces() {
    try {
      await this.workspacesService.deleteWorkspaceDoc(this.workspace.id, this.user);
      this.router.navigate(["/workspaces"]);
    } catch (error) {
      alert(error);
    } finally {
      this.toggleDeleteWorkspaceOverlay();
    }
  }

  /**
   * Toggle Delete Workspace Overlay
   */
  toggleDeleteWorkspaceOverlay() {
    this.overlay_deleteWorkspace = !this.overlay_deleteWorkspace;
  }
}
