<div class="px-4">
  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <div class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
        <span class="font-medium text-gray-900">View</span>
      </div>
    </h3>
    <div class="pt-6">
      <div class="space-y-4">
        <fieldset>
          <div class="space-y-4">
            @for (view of currentview; track $index; let i = $index; let last = $last) {
              <div class="flex items-center">
                <label class="flex cursor-pointer items-center" for="view{{ i }}">
                  <input type="radio" class="h-4 w-4 border-gray-300 text-brand_Primary focus:ring-brand_Primary" [(ngModel)]="reportsService.reportSettings.pdf_or_csv" [value]="view" id="view{{ i }}" />
                  <span class="ml-3 cursor-pointer text-sm text-text_Title">{{ view }}</span>
                </label>
              </div>
            }
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>
