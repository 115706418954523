<form [formGroup]="signatureForm" class="col-span-6">
  <div class="form-input-wrapper">
    <label for="signature-name" class="form-input-label"
      >Name*
      @if (!signatureForm.pristine && signatureForm.controls['name'].errors) {
        <span class="required-field"> - Name is required</span>
      }
    </label>
    <input type="text" name="signature-name" id="signature-name" class="form-input" placeholder="Name" formControlName="name" />
  </div>
</form>
