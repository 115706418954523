import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "manage-members-edit-invite-details",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: "./manage-members-edit-invite-details.component.html",
  styleUrl: "./manage-members-edit-invite-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageMembersEditInviteDetailsComponent {
  @Input({ required: true }) inviteForm!: FormGroup;
  @Input({ required: true }) inviteId!: string;
}
