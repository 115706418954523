import { inject, Injectable } from '@angular/core';
import { collection, CollectionReference, deleteDoc, doc, DocumentData, DocumentReference, Firestore, onSnapshot, PartialWithFieldValue, query, QueryConstraint, setDoc, WithFieldValue, writeBatch } from '@angular/fire/firestore';
import { deleteObject, ref, Storage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BatchOperation } from 'src/app/models/utils/batch';

@Injectable({
  providedIn: 'root',
})
export class FirestoreUtilsService {
  private firestore = inject(Firestore);
  private storage = inject(Storage);

  getCollectionData<T>(collectionName: string, queryConstraints: QueryConstraint[] = []): Observable<T[]> {
    const itemCollection = collection(this.firestore, collectionName) as CollectionReference<DocumentData>;
    const queryFn = query(itemCollection, ...queryConstraints);
    return new Observable<T[]>((observer) => {
      const unsubscribe = onSnapshot(
        queryFn,
        (querySnapshot) => {
          const data: T[] = [];
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() } as T);
          });
          observer.next(data);
        },
        (error) => {
          observer.error(error);
        }
      );
      return { unsubscribe };
    }).pipe(shareReplay(1));
  }

  getDocumentData<T>(documentPath: string): Observable<T> {
    const documentRef = doc(this.firestore, documentPath);
    return new Observable<T>((observer) => {
      const unsubscribe = onSnapshot(documentRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = { id: docSnapshot.id, ...docSnapshot.data() } as T;
          observer.next(data);
        } else {
          observer.error(new Error('Document does not exist'));
        }
      });
      return { unsubscribe };
    }).pipe(shareReplay(1));
  }

  async setDocumentDataWithFieldValue<T extends DocumentData>(documentPath: string, data: WithFieldValue<T>): Promise<void> {
    const documentRef = doc(this.firestore, documentPath) as DocumentReference<T>;
    await setDoc(documentRef, data, { merge: true });
  }

  async setPartialDocumentData<T extends DocumentData>(documentPath: string, data: PartialWithFieldValue<T>): Promise<void> {
    const documentRef = doc(this.firestore, documentPath) as DocumentReference<T>;
    await setDoc(documentRef, data, { merge: true });
  }

  async setDocumentData<T extends DocumentData>(documentPath: string, data: T): Promise<void> {
    const documentRef = doc(this.firestore, documentPath);
    await setDoc(documentRef, data, { merge: true });
  }

  async deleteDocument(documentPath: string): Promise<void> {
    const documentRef = doc(this.firestore, documentPath);
    await deleteDoc(documentRef);
  }

  async batchWrite(operations: BatchOperation[]): Promise<void> {
    const batch = writeBatch(this.firestore);

    operations.forEach((operation) => {
      const documentRef = doc(this.firestore, operation.documentPath);

      switch (operation.type) {
        case 'set':
          if (operation.data) {
            batch.set(documentRef, operation.data, { merge: true });
          }
          break;
        case 'update':
          if (operation.data) {
            batch.update(documentRef, operation.data);
          }
          break;
        case 'delete':
          batch.delete(documentRef);
          break;
        default:
          throw new Error(`Unsupported operation type: ${operation.type}`);
      }
    });

    try {
      await batch.commit();
    } catch (error) {
      throw error;
    }
  }

  /** TODO
   * Not sure if this function is necessary if cloud functions performs a clean up
   */
  async deleteFilesFromStorage(filePaths: string[]): Promise<void> {
    try {
      const deletionPromises = filePaths.map((filePath) => {
        const fileRef = ref(this.storage, filePath);
        return deleteObject(fileRef);
      });
      await Promise.all(deletionPromises);
    } catch (error) {
      console.error('Error deleting files:', error);
      throw error;
    }
  }

  public createFirestoreId(): string {
    return doc(collection(this.firestore, 'id')).id;
  }
}
