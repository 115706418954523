import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, NgZone, OnInit, Output } from '@angular/core';
import { getDownloadURL, ref, Storage, uploadBytes } from '@angular/fire/storage';
import Compressor from 'compressorjs';
import { FirestoreUtilsService } from 'src/app/core/services/firestore/firestore-utils.service';
import { PhotoType } from 'src/app/models/photo/photo-type';
import { environment } from 'src/environments/environment';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
@Component({
  selector: 'photo-upload-single',
  standalone: true,
  imports: [LoadingSpinnerComponent],
  templateUrl: './photo-upload-single.component.html',
  styleUrl: './photo-upload-single.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoUploadSingleComponent implements OnInit {
  @Input({ required: true }) largeMaxWidth!: number;
  @Input({ required: true }) largeMaxHeight!: number;
  @Input() thumbnailMaxWidth!: number;
  @Input() thumbnailMaxHeight!: number;
  @Input({ required: true }) path!: string;
  @Input({ required: true }) fileType!: string;
  @Input({ required: true }) uploadSourceComponent!: 'workspace' | 'client' | 'site' | 'signature' | 'item';
  @Input() file!: string | URL | Request;
  @Output() photoDataOutput = new EventEmitter();

  private ngZone = inject(NgZone);
  private fbStorage = inject(Storage);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  private metadata = {
    contentType: 'image/jpeg',
    cacheControl: 'public, max-age=31536000',
    customMetadata: {
      platform: 'Web: v' + environment.version,
    },
  };

  ngOnInit() {
    this.resizeImage();
  }

  async resizeImage() {
    const blob = await fetch(this.file).then((r) => r.blob());
    const photoId = this.firestoreUtilsService.createFirestoreId();

    this.ngZone.runOutsideAngular(async () => {
      const original = await this.compressPhoto(blob, {
        quality: 0.75,
        maxWidth: this.largeMaxWidth,
        maxHeight: this.largeMaxHeight,
        checkOrientation: true,
      });

      let thumb: Blob | null = null;
      // if (this.uploadSourceComponent === "workspace") {
      thumb = await this.compressPhoto(blob, {
        quality: 0.75,
        maxWidth: this.thumbnailMaxWidth,
        maxHeight: this.thumbnailMaxHeight,
        checkOrientation: true,
      });
      // }

      this.startUpload(photoId, { original, thumb });
    });
  }

  async startUpload(photoId: string, results: { original: Blob; thumb: Blob | null }) {
    const randomIdForiOSRefresh = this.firestoreUtilsService.createFirestoreId();

    let fileNamePrefix: string;
    switch (this.uploadSourceComponent) {
      case 'workspace':
        fileNamePrefix = 'workspace_photo';
        break;
      case 'site':
        fileNamePrefix = 'site_photo';
        break;
      case 'client':
        fileNamePrefix = 'client_photo';
        break;
      case 'signature':
        fileNamePrefix = 'signature_photo';
        break;

      default:
        throw new Error(`Unknown upload source component: ${this.uploadSourceComponent}`);
    }

    const originalStorageRef = ref(this.fbStorage, `${this.path}/${photoId}_${fileNamePrefix}_${randomIdForiOSRefresh}.${this.fileType}`);
    const uploadOriginal = uploadBytes(originalStorageRef, results.original, this.metadata);

    let uploadTasks = [uploadOriginal];
    let thumbStorageRef;

    // if (this.uploadSourceComponent === "workspace") {
    if (!results.thumb) {
      throw new Error('Thumbnail is required for project photos but was not provided.');
    }
    thumbStorageRef = ref(this.fbStorage, `${this.path}/${photoId}}_${fileNamePrefix}_${randomIdForiOSRefresh}.${this.fileType}`);
    const uploadThumb = uploadBytes(thumbStorageRef, results.thumb, this.metadata);
    uploadTasks.push(uploadThumb);
    // }

    await Promise.all(uploadTasks);

    const originalDownloadURL = await getDownloadURL(originalStorageRef);
    let thumbDownloadURL;
    if (thumbStorageRef) {
      thumbDownloadURL = await getDownloadURL(thumbStorageRef);
    }

    let photosObject: PhotoType;

    if (!thumbStorageRef || !thumbDownloadURL) {
      throw new Error('Thumbnail information is required for project photos but was not available.');
    }

    switch (this.uploadSourceComponent) {
      case 'workspace':
      case 'site':
      case 'client':
      case 'signature':
        photosObject = {
          type: this.uploadSourceComponent,
          image_url: originalDownloadURL,
          image_thumbnail_url: thumbDownloadURL,
        };
        break;

      default:
        throw new Error(`Unknown upload source component: ${this.uploadSourceComponent}`);
    }

    this.uploadPhoto(photosObject);
  }

  async uploadPhoto(photos: object) {
    if (typeof this.file === 'string') {
      URL.revokeObjectURL(this.file as string);
    }
    this.photoDataOutput.emit(photos);
  }

  compressPhoto(file: Blob, options: Compressor.Options | undefined): Promise<Blob> {
    return new Promise((resolve, reject) => {
      return new Compressor(file, {
        ...options,
        success: (result: Blob) => resolve(result),
        error: reject,
      });
    });
  }
}
