<div class="px-4">
  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <div class="flex w-full items-center justify-between bg-white py-3 text-sm font-medium text-gray-900">CSV Images</div>
    </h3>

    <div class="pt-6">
      <div class="space-y-4">
        <div class="flex items-center">
          <input id="csv-images" name="csv-images" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" [(ngModel)]="reportService.reportSettings.csv_images" (change)="onCSVImagesCheckboxChange()" />
          <label for="csv-images" class="ml-3 cursor-pointer text-sm text-text_Title">Include Images In Export</label>
        </div>
      </div>
    </div>
  </div>
</div>
