import { DatePipe, NgClass, NgStyle } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DateFormatPipe } from "src/app/core/pipes/date-format.pipe";
import { ThemeStylesAndSettingsService } from "../../../services/theme-styles-and-settings.service";

@Component({
  selector: "manage-theme-edit-preview-item-photos",
  standalone: true,
  imports: [NgStyle, NgClass, DatePipe, DateFormatPipe],
  templateUrl: "./manage-theme-edit-preview-item-photos.component.html",
  styleUrl: "./manage-theme-edit-preview-item-photos.component.scss",
})
export class ManageThemeEditPreviewItemPhotosComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  public themeStylesAndSettingsSerivce = inject(ThemeStylesAndSettingsService);
  public imageUrls = [
    {
      url: "./assets/images/theme-preview/1.jpeg",
    },
    {
      url: "./assets/images/theme-preview/2.jpeg",
    },
    {
      url: "./assets/images/theme-preview/3.jpeg",
    },
    {
      url: "./assets/images/theme-preview/4.jpeg",
    },
    {
      url: "./assets/images/theme-preview/5.jpeg",
    },
    {
      url: "./assets/images/theme-preview/6.jpeg",
    },
  ];

  public photoSizeClass = "photo_0";
  setPhotoSizeClass(size: string) {
    this.photoSizeClass = size;
  }
}
