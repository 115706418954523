import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-csv-images",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./report-settings-csv-images.component.html",
  styleUrl: "./report-settings-csv-images.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsCsvImagesComponent {
  // Services
  public reportService = inject(ReportsService);

  /**
   * On CSV images checkbox change
   */
  public onCSVImagesCheckboxChange() {
    this.reportService.updateReportSettings(this.reportService.reportSettings);
  }
}
