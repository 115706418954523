import { NgStyle } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DateFormatPipe } from "src/app/core/pipes/date-format.pipe";
import { ThemeStylesAndSettingsService } from "../../../services/theme-styles-and-settings.service";

@Component({
  selector: "manage-theme-edit-preview-front-cover",
  standalone: true,
  imports: [NgStyle, DateFormatPipe],
  templateUrl: "./manage-theme-edit-preview-front-cover.component.html",
  styleUrl: "./manage-theme-edit-preview-front-cover.component.scss",
})
export class ManageThemeEditPreviewFrontCoverComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  public themeStylesAndSettingsSerivce = inject(ThemeStylesAndSettingsService);
}
