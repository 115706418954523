import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ManageAssigneeEditDetailsComponent } from "../manage-assignee-edit-details/manage-assignee-edit-details.component";

@Component({
  selector: "manage-assignee-new-overlay",
  standalone: true,
  imports: [NgClass, ManageAssigneeEditDetailsComponent],
  templateUrl: "./manage-assignee-new-overlay.component.html",
  styleUrl: "./manage-assignee-new-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageAssigneeNewOverlayComponent {
  @Input({ required: true }) assigneeForm!: FormGroup;
  @Output() toggleNewAssigneeOverlayOutput = new EventEmitter<void>();
  @Output() saveAssigneeOutput = new EventEmitter<void>();

  /**
   * Save Assignee And Reroute
   */
  public saveAssignee(): void {
    this.saveAssigneeOutput.emit();
  }

  /**
   * Toggle New Assignee Overlay
   */
  public toggleNewAssigneeOverlay(): void {
    this.toggleNewAssigneeOverlayOutput.emit();
  }
}
