import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SignatureEnhanced } from 'src/app/models/inspection/signature.model';
import { BreadcrumbsComponent } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'inspection-signoff-list-header',
  standalone: true,
  imports: [BreadcrumbsComponent, JsonPipe],
  templateUrl: './inspection-signoff-list-header.component.html',
  styleUrl: './inspection-signoff-list-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionSignoffListHeaderComponent {
  @Input({ required: true }) signatures!: SignatureEnhanced[];
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) editMode!: boolean;
  @Input() signatureBulkEditArray: SignatureEnhanced[] | null = [];
  @Output() routeToSignatureEditorOutput = new EventEmitter<void>();
  @Output() toggleEditModeOutput = new EventEmitter<void>();
  @Output() bulkDeleteSignaturesOutput = new EventEmitter<void>();

  /**
   * Route to the signature editor
   */
  public routeToSignatureEditor(): void {
    this.routeToSignatureEditorOutput.emit();
  }

  /**
   * Bulk delete signatures
   */
  public bulkDeleteSignatures(): void {
    this.bulkDeleteSignaturesOutput.emit();
  }

  /**
   * Toggle edit mode
   */
  public toggleEditMode(): void {
    this.toggleEditModeOutput.emit();
  }
}
