<header>
  <!-- Breadcrumbs-->
  <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [signatureId]="signatureId" />

  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">{{ signatureId === 'new' ? 'Add ' : 'Edit ' }} Signature</h2>
      </div>
    </div>
  </div>
</header>
