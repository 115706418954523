import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { DropzoneDirective } from "src/app/shared/directives/dropzone.directive";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { PhotoDropzoneComponent } from "src/app/shared/photo-dropzone/photo-dropzone.component";
import { PhotoPreviewComponent } from "src/app/shared/photo-preview/photo-preview.component";
import { PhotoUploadSingleComponent } from "src/app/shared/photo-upload-single/photo-upload-single.component";
import { ManageClientEditDetailsComponent } from "../manage-client-edit-details/manage-client-edit-details.component";

@Component({
  selector: "manage-client-new-overlay",
  standalone: true,
  imports: [NgClass, FormStateComponent, ManageClientEditDetailsComponent, DropzoneDirective, PhotoDropzoneComponent, PhotoPreviewComponent, PhotoUploadSingleComponent],
  templateUrl: "./manage-client-new-overlay.component.html",
  styleUrl: "./manage-client-new-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageClientNewOverlayComponent {
  @Input({ required: true }) clientForm!: FormGroup;
  @Input({ required: true }) uploadFileArray: SinglePhotoUpload[] = [];
  @Input({ required: true }) overlay_uploadPhotos: boolean = false;
  @Input({ required: true }) photoPreview: boolean = false;
  @Input({ required: true }) newclientId!: string;
  @Input({ required: true }) workspaceId!: string;
  @Output() toggleNewClientOverlayOutput = new EventEmitter<void>();
  @Output() saveClientOutput = new EventEmitter<void>();
  @Output() detectPhotosOutput = new EventEmitter<FileList>();
  @Output() removePhotoFromArrayOutput = new EventEmitter<string>();
  @Output() returnedPhotoOutput = new EventEmitter<PhotoType>();

  /**
   * Save Client And Reroute
   */
  public saveClient(): void {
    this.saveClientOutput.emit();
  }

  /**
   * Patch form with photos and save doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.returnedPhotoOutput.emit(photos);
  }

  /**
   * Remove image from array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    this.removePhotoFromArrayOutput.emit(id);
  }

  /**
   * Detects photos
   * @param files
   */
  public detectPhotos(files: FileList): void {
    this.detectPhotosOutput.emit(files);
  }

  /**
   * Toggle New Client Overlay
   */
  public toggleNewClientOverlay(): void {
    this.toggleNewClientOverlayOutput.emit();
  }
}
