<div class="grid grid-cols-1 gap-y-4">
  <div class="mx-auto w-full max-w-7xl sm:px-6 lg:px-8">
    <div class="form-header-wrapper">
      <h3 class="form-header-text">All Signatures</h3>
    </div>
  </div>

  <!-- List -->
  <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
    <!-- List Component -->
    <div id="signaturesList" role="list">
      <div cdkDropList (cdkDropListDropped)="reorderSignatures($event)" class="list-ul">
        @for (signature of signatures; track $index) {
          <div class="list-row" cdkDrag>
            <!-- List Editing Tools -->
            <div class="flex flex-row items-center">
              <div class="mr-3 flex items-center justify-end hover:text-brand_Primary-hover dark:text-slate-300 dark:hover:text-sky-500">
                <div class="flex items-center">
                  <input type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" (change)="createBatchEdit(signature)" />
                </div>
              </div>
              <div class="flex items-center">
                @if (signatureBulkEditArray && signatureBulkEditArray.length <= 0) {
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" (click)="deleteSingleSignature(signature.id)" class="cip-transition h-5 w-5 cursor-pointer text-alert_Error hover:opacity-75">
                    <path
                      fill-rule="evenodd"
                      d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                }
              </div>
            </div>

            <a [routerLink]="['/', 'workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'signoff', signature.id, 'editor']" class="list-a group">
              <!-- Image -->

              @if (signature.image_thumbnail_url) {
                <div class="ml-2.5 flex flex-row sm:ml-0">
                  <img class="flex h-auto w-[250px] rounded-md border border-gray-50" src="{{ signature.image_thumbnail_url }}" />
                </div>
              }

              <!-- Content -->
              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                <div class="min-w-0 flex-auto">
                  <p class="list-title">{{ signature.name }}</p>
                  @if (signature.date_signed) {
                    <p class="list-meta">Signed {{ signature.date_signed.toDate() | date: 'dd/MM/yyyy' }} at {{ signature.date_signed.toDate() | date: 'HH:mm' }}</p>
                  }
                </div>
              </div>

              <!-- Right Icon -->
              <div class="flex">
                <button type="button" class="mr-2.5 block h-full py-2.5 pl-2.5 text-text_Subtitle dark:text-gray-400 sm:mr-0" aria-expanded="false" aria-haspopup="true">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                  </svg>
                </button>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  </div>
</div>
