import { NgStyle } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "manage-theme-edit-preview-site-cover",
  standalone: true,
  imports: [NgStyle],
  templateUrl: "./manage-theme-edit-preview-site-cover.component.html",
  styleUrl: "./manage-theme-edit-preview-site-cover.component.scss",
})
export class ManageThemeEditPreviewSiteCoverComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
}
