@if (totalActions > 0) {
  <div class="chart-total text-group-5">{{ totalActions }} {{ totalActions === 1 ? 'Action' : 'Actions' }}</div>
  <charts-actions [actions]="actions" />
} @else {
  <div class="w-full p-8 dark:bg-gray-800">
    <div class="relative block w-full rounded-lg p-12 text-center">
      <span class="mt-2 block text-sm font-medium text-gray-900 dark:text-white">No Actions</span>
      <p class="mt-1 text-sm text-text_Subtitle dark:text-gray-400">There are currently no Actions for the Tests in this Inspection. Action statistics will appear here as you add Actions to your Tests.</p>
    </div>
  </div>
}
