import { AsyncPipe, JsonPipe } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { ToolTipsStringService } from "src/app/core/services/strings/tooltips.service";
import { FolderEnhanced } from "src/app/models/folder/folder.model";
import { ToolTipFilter, ToolTipType } from "src/app/models/strings/strings.model";
import { FolderEditWrapperComponent } from "src/app/sections/folders/edit/folder-edit-wrapper/folder-edit-wrapper.component";
import { FolderDetailService } from "src/app/sections/folders/services/folder-detail.service";
import { FoldersListService } from "src/app/sections/folders/services/folders-list.service";
import { TooltipComponent } from "src/app/shared/tooltip/tooltip.component";
import { InspectionDetailsFolderSelectorComponent } from "../inspection-details-folder-selector/inspection-details-folder-selector.component";

@Component({
  selector: "inspection-details-folder-wrapper",
  standalone: true,
  imports: [InspectionDetailsFolderSelectorComponent, TooltipComponent, ReactiveFormsModule, AsyncPipe, JsonPipe, FolderEditWrapperComponent],
  templateUrl: "./inspection-details-folder-wrapper.component.html",
  styleUrl: "./inspection-details-folder-wrapper.component.scss",
})
export class InspectionDetailsFolderWrapperComponent {
  @Input({ required: true }) inspectionForm!: FormGroup;
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) user!: User;

  // Services
  private toolTipsStringService = inject(ToolTipsStringService);
  private foldersListService = inject(FoldersListService);
  private folderDetailService = inject(FolderDetailService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private limitationManagerService = inject(LimitationManagerService);

  // Properties
  public isNewFolder = true;
  public toolTipInspectionDetailsFolder: ToolTipFilter = this.toolTipsStringService.getToolTip(ToolTipType.InspectionDetailsFolder);
  public overlay_folderSelector: boolean = false;
  public overlay_newFolder: boolean = false;
  public currentlySelectedFolder!: FolderEnhanced | null;
  public allOtherFolders: Array<FolderEnhanced> = [];
  public folders$!: Observable<FolderEnhanced[]>;
  public newFolderId!: string;
  public folderForm: FormGroup;
  public limitationResult!: boolean;

  constructor() {
    this.folderForm = this.fb.group({
      id: null,
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      colour: "#2F8559",
      date_created: null,
      created_by: null,
      created_by_id: null,
      inspections_count: null,
      is_deleted: null,
    });
  }

  private async getFoldersList(): Promise<void> {
    this.folders$ = this.foldersListService.getFoldersList$(this.workspaceId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.allOtherFolders = [];
        this.currentlySelectedFolder = null;
        data.forEach((element) => {
          if (element.id === this.inspectionForm.value["folder_id"]) {
            this.currentlySelectedFolder = element;
          } else {
            this.allOtherFolders.push(element);
          }
        });
      })
    );
  }

  /**
   * Toggle Folder Selection Overlay
   */
  public async toggleFolderSelectionOverlay(): Promise<void> {
    this.toggleFolderSelectorOverlay();
    if (!this.folders$) {
      this.getFoldersList();
    }
    if (this.overlay_folderSelector && !this.newFolderId) {
      this.newFolderId = this.firestoreUtilsService.createFirestoreId();
    }
  }

  /**
   * Save New Folder
   * @param folder
   */
  async saveNewFolder(): Promise<void> {
    try {
      const writeEventType = "added";
      const newFolder = await this.folderDetailService.setFolderDoc(this.user, this.workspaceId, this.folderForm.value, writeEventType);
      if (newFolder) {
        this.inspectionForm.patchValue({
          folder_id: newFolder.id,
          folder_title: newFolder.title,
        });
        this.currentlySelectedFolder = newFolder as FolderEnhanced;
        this.resetFolderForm();
        this.toggleFolderSelectionOverlay();
        this.toggleNewFolderOverlay();
      }
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Folder Selection
   * @param folder
   */
  public folderSelection(folder: FolderEnhanced): void {
    this.inspectionForm.patchValue({
      folder_id: folder.id,
      folder_title: folder.title,
    });
    this.currentlySelectedFolder = folder;
    this.inspectionForm.markAsDirty();
    this.toggleFolderSelectorOverlay();
    this.getFoldersList();
  }

  /**
   * Can User Add Folder
   */
  public async canUserAddFolder(): Promise<void> {
    this.limitationResult = await this.limitationManagerService.canUserPerformAction("folder_create_update");
    if (this.limitationResult) {
      this.toggleNewFolderOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle New Folder Overlay
   */
  public toggleNewFolderOverlay(): void {
    this.overlay_newFolder = !this.overlay_newFolder;
    this.toggleFolderSelectorOverlay();
  }

  /**
   * Toggle Folder Selector Overlay
   */
  private toggleFolderSelectorOverlay(): void {
    this.overlay_folderSelector = !this.overlay_folderSelector;
  }

  /**
   * Reset Folder Form
   */
  private resetFolderForm(): void {
    this.folderForm.reset();
    this.folderForm.markAsPristine();
    this.folderForm.markAsPristine;
  }
}
