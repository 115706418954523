import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TemplateEnhanced } from "src/app/models/template/template.model";

@Component({
  selector: "manage-template-overview-details",
  standalone: true,
  imports: [DatePipe],
  templateUrl: "./manage-template-overview-details.component.html",
  styleUrl: "./manage-template-overview-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateOverviewDetailsComponent {
  @Input({ required: true }) template!: TemplateEnhanced;
  @Output() routeToTemplateEditorOutput = new EventEmitter<void>();

  /**
   * Route to Template Editor
   */
  public routeToTemplateEditor(): void {
    this.routeToTemplateEditorOutput.emit();
  }
}
