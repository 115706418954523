import { AsyncPipe, JsonPipe } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { filter, Observable, tap } from "rxjs";
import { assertNever } from "src/app/core/util/assert";
import {
  AuditLogAssigneeAdded,
  AuditLogAssigneeDeleted,
  AuditLogClientAdded,
  AuditLogClientDeleted,
  AuditLogFolderAdded,
  AuditLogFolderDeleted,
  AuditLogInspectionAdded,
  AuditLogInspectionDeleted,
  AuditLogMemberActivated,
  AuditLogMemberDeactivated,
  AuditLogMemberNameChanged,
  AuditLogMemberRemoved,
  AuditLogMemberRoleChanged,
  AuditLogRoleAdded,
  AuditLogRoleDeleted,
  AuditLogRolePermissionsChanged,
  AuditLogRoleTitleChanged,
  AuditLogSiteAdded,
  AuditLogSiteDeleted,
  AuditLogTemplateAdded,
  AuditLogTemplateDeleted,
  AuditLogWorkspaceImageUrlChanged,
  AuditLogWorkspaceNameChanged,
  AuditLogWorkspaceOwnerChanged,
  AuditLogWorkspaceWebsiteChanged,
} from "src/app/core/util/models";
import { AuditLogDoc } from "src/app/models/audit-log/audit-log-doc.model";
import { AuditLogEnhanced } from "src/app/models/audit-log/audit-log.model";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { AuditLogDetailService } from "../../services/audit-log-detail.service";
import { AuditLogOverviewComponent } from "../audit-log-overview/audit-log-overview.component";

@Component({
  selector: "audit-log-overview-wrapper",
  standalone: true,
  imports: [AsyncPipe, JsonPipe, AuditLogOverviewComponent, LoadingSpinnerComponent],
  templateUrl: "./audit-log-overview-wrapper.component.html",
  styleUrl: "./audit-log-overview-wrapper.component.scss",
})
export class AuditLogOverviewWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set auditLogId(value: string) {
    this._auditLogId = value;
  }
  get auditLogId(): string {
    return this._auditLogId;
  }

  // Services
  private auditLogDetailService = inject(AuditLogDetailService);

  // Properties
  public _workspaceId!: string;
  public _auditLogId!: string;
  public auditLogDoc$!: Observable<AuditLogEnhanced>;
  public convertedAuditLog!: AuditLogDoc;

  ngOnInit(): void {
    this.getAuditLogDoc();
  }

  private async getAuditLogDoc(): Promise<void> {
    try {
      this.auditLogDoc$ = this.auditLogDetailService.getAuditLogDoc$(this.workspaceId, this.auditLogId).pipe(
        filter((data) => !!data),
        tap((data) => {
          this.convertedAuditLog = this.convertToAuditLogDoc(data);
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  private convertToAuditLogDoc(auditLog: AuditLogEnhanced): AuditLogDoc {
    switch (auditLog.info.type) {
      case "assignee-added":
        return this.convertAssigneeAdded(auditLog as AuditLogAssigneeAdded);
      case "assignee-deleted":
        return this.convertAssigneeDeleted(auditLog as AuditLogAssigneeDeleted);
      case "client-added":
        return this.convertClientAdded(auditLog as AuditLogClientAdded);
      case "client-deleted":
        return this.convertClientDeleted(auditLog as AuditLogClientDeleted);
      case "folder-added":
        return this.convertFolderAdded(auditLog as AuditLogFolderAdded);
      case "folder-deleted":
        return this.convertFolderDeleted(auditLog as AuditLogFolderDeleted);
      case "inspection-added":
        return this.convertInspectionAdded(auditLog as AuditLogInspectionAdded);
      case "inspection-deleted":
        return this.convertInspectionDeleted(auditLog as AuditLogInspectionDeleted);
      case "member-activated":
        return this.convertMemberActivated(auditLog as AuditLogMemberActivated);
      case "member-deactivated":
        return this.convertMemberDeactivated(auditLog as AuditLogMemberDeactivated);
      case "member-name-changed":
        return this.convertMemberNameChanged(auditLog as AuditLogMemberNameChanged);
      case "member-removed":
        return this.convertMemberRemoved(auditLog as AuditLogMemberRemoved);
      case "member-role-changed":
        return this.convertMemberRoleChanged(auditLog as AuditLogMemberRoleChanged);
      case "role-added":
        return this.convertRoleAdded(auditLog as AuditLogRoleAdded);
      case "role-deleted":
        return this.convertRoleDeleted(auditLog as AuditLogRoleDeleted);
      case "role-permissions-changed":
        return this.convertRolePermissionsChanged(auditLog as AuditLogRolePermissionsChanged);
      case "role-title-changed":
        return this.convertRoleTitleChanged(auditLog as AuditLogRoleTitleChanged);
      case "site-added":
        return this.convertSiteAdded(auditLog as AuditLogSiteAdded);
      case "site-deleted":
        return this.convertSiteDeleted(auditLog as AuditLogSiteDeleted);
      case "template-added":
        return this.convertTemplateAdded(auditLog as AuditLogTemplateAdded);
      case "template-deleted":
        return this.convertTemplateDeleted(auditLog as AuditLogTemplateDeleted);
      case "workspace-current-owner-id-changed":
        return this.convertWorkspaceOwnerChanged(auditLog as AuditLogWorkspaceOwnerChanged);
      case "workspace-image-url-changed":
        return this.convertWorkspaceImageUrlChanged(auditLog as AuditLogWorkspaceImageUrlChanged);
      case "workspace-name-changed":
        return this.convertWorkspaceNameChanged(auditLog as AuditLogWorkspaceNameChanged);
      case "workspace-website-changed":
        return this.convertWorkspaceWebsiteChanged(auditLog as AuditLogWorkspaceWebsiteChanged);
      default:
        assertNever(auditLog.info);
    }
  }

  private convertAssigneeAdded(auditLog: AuditLogAssigneeAdded): AuditLogDoc {
    return {
      document: "Assignee",
      title: auditLog.info.assignee_name,
      type: "Added",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertAssigneeDeleted(auditLog: AuditLogAssigneeDeleted): AuditLogDoc {
    return {
      document: "Assignee",
      title: auditLog.info.assignee_name,
      type: "Deleted",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertClientAdded(auditLog: AuditLogClientAdded): AuditLogDoc {
    return {
      document: "Client",
      title: auditLog.info.client_name,
      type: "Added",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertClientDeleted(auditLog: AuditLogClientDeleted): AuditLogDoc {
    return {
      document: "Client",
      title: auditLog.info.client_name,
      type: "Deleted",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertFolderAdded(auditLog: AuditLogFolderAdded): AuditLogDoc {
    return {
      document: "Folder",
      title: auditLog.info.folder_title,
      type: "Added",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertFolderDeleted(auditLog: AuditLogFolderDeleted): AuditLogDoc {
    return {
      document: "Folder",
      title: auditLog.info.folder_title,
      type: "Deleted",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertInspectionAdded(auditLog: AuditLogInspectionAdded): AuditLogDoc {
    return {
      document: "Inspection",
      title: auditLog.info.inspection_title,
      type: "Added",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertInspectionDeleted(auditLog: AuditLogInspectionDeleted): AuditLogDoc {
    return {
      document: "Inspection",
      title: auditLog.info.inspection_title,
      type: "Deleted",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertMemberActivated(auditLog: AuditLogMemberActivated): AuditLogDoc {
    const memberFirstName = auditLog.info.member_name_first;
    const memberLastName = auditLog.info.member_name_last;
    return {
      document: "Member",
      title: `${memberFirstName} ${memberLastName}`,
      type: "Joined",
      date: auditLog.date,
      author: `${memberFirstName} ${memberLastName}`,
      platform: auditLog.platform,
    };
  }

  private convertMemberDeactivated(auditLog: AuditLogMemberDeactivated): AuditLogDoc {
    return {
      document: "Member",
      title: auditLog.info.member_name_first + " " + auditLog.info.member_name_last,
      type: "Deactivated",
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertMemberNameChanged(auditLog: AuditLogMemberNameChanged): AuditLogDoc {
    const author = auditLog.author?.user_name;
    return {
      document: "Member",
      title: auditLog.info.changed_from,
      type: "Changed",
      to: auditLog.info.changed_to,
      author: author,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertMemberRemoved(auditLog: AuditLogMemberRemoved): AuditLogDoc {
    const memberNameFirst = auditLog.info.member_name_first;
    const memberNameLast = auditLog.info.member_name_last;
    return {
      document: "Member",
      title: `${memberNameFirst} ${memberNameLast}`,
      type: "Removed",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertMemberRoleChanged(auditLog: AuditLogMemberRoleChanged): AuditLogDoc {
    const memberNameFirst = auditLog.info.member_name_first;
    const memberNameLast = auditLog.info.member_name_last;
    const roleTitleBefore = auditLog.info.role_title_before;
    return {
      document: "Member",
      title: `${memberNameFirst} ${memberNameLast} was ${roleTitleBefore}`,
      type: "Removed",
      to: auditLog.info.role_title_after,
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertRoleAdded(auditLog: AuditLogRoleAdded): AuditLogDoc {
    return {
      document: "Role",
      title: auditLog.info.role_title,
      type: "Added",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertRoleDeleted(auditLog: AuditLogRoleDeleted): AuditLogDoc {
    return {
      document: "Role",
      title: auditLog.info.role_title,
      type: "Deleted",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  // Role Permissions Changed TODO
  private convertRolePermissionsChanged(auditLog: AuditLogRolePermissionsChanged): AuditLogDoc {
    const roleTitle = auditLog.info.role_title;
    const changeFields = auditLog.info.changed_fields;

    // Construct the changes array
    const changesArray = changeFields.map((field) => ({
      key: field.key,
      value_before: field.value_before,
      value_after: field.value_after,
    }));

    return {
      document: "Role",
      title: `${roleTitle} had ${changeFields.length} permissions changed`,
      type: "Multiple",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
      changes: changesArray,
    };
  }

  private convertRoleTitleChanged(auditLog: AuditLogRoleTitleChanged): AuditLogDoc {
    return {
      document: "Role",
      title: auditLog.info.role_title_before,
      type: "Added",
      to: auditLog.info.role_title_after,
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertSiteAdded(auditLog: AuditLogSiteAdded): AuditLogDoc {
    return {
      document: "Site",
      title: auditLog.info.site_title,
      type: "Added",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertSiteDeleted(auditLog: AuditLogSiteDeleted): AuditLogDoc {
    return {
      document: "Site",
      title: auditLog.info.site_title,
      type: "Deleted",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertTemplateAdded(auditLog: AuditLogTemplateAdded): AuditLogDoc {
    return {
      document: "Template",
      title: auditLog.info.template_title,
      type: "Added",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertTemplateDeleted(auditLog: AuditLogTemplateDeleted): AuditLogDoc {
    return {
      document: "Template",
      title: auditLog.info.template_title,
      type: "Deleted",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertWorkspaceOwnerChanged(auditLog: AuditLogWorkspaceOwnerChanged): AuditLogDoc {
    const workspaceCurrentOwnerBefore = auditLog.info.workspace_current_owner_before;
    const workspaceName = auditLog.info.workspace_name;
    return {
      document: "Workspace",
      title: `${workspaceCurrentOwnerBefore} was Owner of ${workspaceName}`,
      type: "Changed",
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertWorkspaceImageUrlChanged(auditLog: AuditLogWorkspaceImageUrlChanged): AuditLogDoc {
    const workspaceImageUrlAfter = auditLog.info.workspace_image_url_after;
    return {
      document: "Workspace",
      title: auditLog.info.workspace_name,
      type: "Changed",
      image: workspaceImageUrlAfter,
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertWorkspaceNameChanged(auditLog: AuditLogWorkspaceNameChanged): AuditLogDoc {
    return {
      document: "Workspace",
      title: auditLog.info.workspace_name_before,
      type: "Changed",
      to: auditLog.info.workspace_name_after,
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }

  private convertWorkspaceWebsiteChanged(auditLog: AuditLogWorkspaceWebsiteChanged): AuditLogDoc {
    return {
      document: "Workspace",
      title: auditLog.info.workspace_website_before ? auditLog.info.workspace_website_before : "No previous website url",
      type: "Changed",
      to: auditLog.info.workspace_website_after,
      author: auditLog.author?.user_name,
      date: auditLog.date,
      platform: auditLog.platform,
    };
  }
}
