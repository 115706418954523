import { inject, Injectable } from "@angular/core";
import { Client, User, WriteEvent, WriteEventType } from "cip";
import { deleteField, WithFieldValue } from "firebase/firestore";
import { catchError, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { ClientEnhanced } from "src/app/models/client/client.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class ClientDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Client Doc
   * @param workspaceId
   * @param clientId
   */
  public getClientDoc$(workspaceId: string, clientId: string): Observable<ClientEnhanced | null> {
    const path = `${this.collectionsService.clientsCol(workspaceId)}/${clientId}`;
    return this.firestoreUtilsService.getDocumentData<ClientEnhanced>(path).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Set Client Doc
   * @param workspaceId
   * @param clientId
   * @param clientForm
   * @param writeEventType
   * @param user
   */
  async saveClientDoc(workspaceId: string, clientId: string, clientForm: ClientEnhanced, writeEventType: WriteEventType, user: User) {
    const path = this.collectionsService.clientsCol(workspaceId);
    const clientIdToUse = clientId === "new" ? this.firestoreUtilsService.createFirestoreId() : clientId;
    const doc = `${path}/${clientIdToUse}`;
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const clientObj = this.createClientDoc(clientForm, lastEvent, user);
    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Client>(doc, clientObj);
    return { ...clientObj, id: clientIdToUse };
  }

  /**
   * Delete Client Doc
   * @param workspaceId
   * @param clientId
   */
  public async deleteClientDoc(workspaceId: string, clientId: string, user: User): Promise<void> {
    const path = this.collectionsService.clientsCol(workspaceId);
    const doc = `${path}/${clientId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const obj = { is_deleted: true, last_event: lastEvent };
    return this.firestoreUtilsService.setDocumentData(doc, obj);
  }

  /**
   * Delete Batched
   * @param workspaceId
   * @param batchedClients
   */
  async deleteBatchedClients(workspaceId: string, batchedClients: ClientEnhanced[], user: User): Promise<void> {
    const path = this.collectionsService.clientsCol(workspaceId);
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const operations: BatchOperation[] = [];

    batchedClients.forEach((client) => {
      const doc = `${path}/${client.id}`;
      operations.push({
        type: "update",
        documentPath: doc,
        data: { last_event: lastEvent, is_deleted: true },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Create Client Doc
   * @param clientForm
   * @param lastEvent
   * @param user
   */
  private createClientDoc(clientForm: ClientEnhanced, lastEvent: WithFieldValue<WriteEvent>, user: User) {
    return {
      name: clientForm.name.trim(),
      branch: clientForm.branch ? clientForm.branch.trim() : deleteField(),
      image_url: clientForm.image_url ? clientForm.image_url.trim() : deleteField(),
      image_thumbnail_url: clientForm.image_thumbnail_url ? clientForm.image_thumbnail_url.trim() : deleteField(),
      created_by_id: clientForm.created_by_id ?? user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };
  }
}
