import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemTestAnswer } from 'cip';
import { CategoryEnhanced } from 'src/app/models/category/category.model';
import { ItemAnswerOutput } from 'src/app/models/item/item-from-list.model';
import { ItemEnhanced } from 'src/app/models/item/item.model';

@Component({
  selector: 'inspection-items-list-test-types',
  standalone: true,
  imports: [NgClass],
  templateUrl: './inspection-items-list-test-types.component.html',
  styleUrl: './inspection-items-list-test-types.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionItemsListTestTypesComponent {
  @Input({ required: true }) item!: ItemEnhanced;
  @Input({ required: true }) currentCategory!: CategoryEnhanced;
  @Output() setItemAnswerOutput = new EventEmitter<ItemAnswerOutput>();

  /**
   * Set Item Answer
   **/
  public setItemAnswer(answer: ItemTestAnswer): void {
    this.setItemAnswerOutput.emit({ answer, item: this.item });
  }
}
