<div class="content-card-wrapper flex flex-col">
  <div class="content-card-header">
    <h3>Template Contents</h3>
  </div>
  <div class="content-card flex w-full flex-1">
    <div class="flex w-full flex-col">
      <!-- Tests and Questions -->

      <a class="flex flex-row items-center justify-between" [routerLink]="['/', 'workspace', workspaceId, 'manage', 'templates', templateId, 'categories']">
        <div class="flex flex-row">
          <div class="mr-4 flex h-12 w-12 items-center justify-center rounded-md border border-brand_Primary border-opacity-25 bg-brand_Primary bg-opacity-10 text-brand_Primary">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
            </svg>
          </div>
          <div class="flex flex-col justify-between">
            <div class="font-semibold tracking-wide">Tests and Questions</div>
            <p class="text-sm text-gray-400">Write content to answer</p>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </a>
    </div>
  </div>
</div>
