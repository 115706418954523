import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Operation, RolePermissions, User } from "cip";
import { serverTimestamp, WithFieldValue } from "firebase/firestore";
import { BehaviorSubject, filter, Observable, Subscription, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { DuplicationService } from "src/app/core/services/duplication/duplication.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { ActionType, LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { NavigationService } from "src/app/core/services/navigation.service";
import { OperationStringsService } from "src/app/core/services/strings/operation-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { TemplateCategoryEnhanced } from "src/app/models/category/template-category.model";
import { OperationEnhanced } from "src/app/models/operation/operation.model";
import { AlertType, OperationType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { DuplicateOverlayComponent } from "src/app/shared/duplicate-overlay/duplicate-overlay.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { EnableDisableOverlayComponent } from "src/app/shared/enable-disable-overlay/enable-disable-overlay.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { TemplateCategoriesListService } from "../../../services/template-categories-list.service";
import { TemplateCategoryDetailService } from "../../../services/template-category-detail.service";
import { ManageTemplateCategoryNewOverlayComponent } from "../../edit/manage-template-category-new-overlay/manage-template-category-new-overlay.component";
import { ManageTemplateCategoriesListHeaderComponent } from "../manage-template-categories-list-header/manage-template-categories-list-header.component";
import { ManageTemplateCategoriesListComponent } from "../manage-template-categories-list/manage-template-categories-list.component";

@Component({
  selector: "manage-template-categories-list-wrapper",
  standalone: true,
  imports: [
    LoadingSpinnerComponent,
    AsyncPipe,
    EmptyListComponent,
    DeleteOverlayComponent,
    DuplicateOverlayComponent,
    EnableDisableOverlayComponent,
    ManageTemplateCategoriesListHeaderComponent,
    ManageTemplateCategoriesListComponent,
    ManageTemplateCategoryNewOverlayComponent,
  ],
  templateUrl: "./manage-template-categories-list-wrapper.component.html",
  styleUrl: "./manage-template-categories-list-wrapper.component.scss",
})
export class ManageTemplateCategoriesListWrapperComponent implements OnInit, OnDestroy {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set templateId(value: string) {
    this._templateId = value;
  }

  get templateId(): string {
    return this._templateId;
  }

  // Services
  private templateCategoriesListService = inject(TemplateCategoriesListService);
  private templateCategoryDetailService = inject(TemplateCategoryDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private navigationService = inject(NavigationService);
  private stringsService = inject(StringsService);
  private operationStringsService = inject(OperationStringsService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  public duplicationService = inject(DuplicationService);
  private _snackBar = inject(MatSnackBar);

  // Properties
  public _workspaceId!: string;
  public _folderId!: string;
  public _templateId!: string;
  public categories$!: Observable<TemplateCategoryEnhanced[]>;
  public allCategories: TemplateCategoryEnhanced[] = [];
  public editMode: boolean = false;
  public categoriesBulkEditArray$: BehaviorSubject<TemplateCategoryEnhanced[]> = new BehaviorSubject<TemplateCategoryEnhanced[]>([]);
  public currentSearchTerm: string = "";
  public overlay_newCategory: boolean = false;
  public overlay_deleteCategory: boolean = false;
  public overlay_enableDisableCategory: boolean = false;
  public deleteTitle: string = "";
  public deleteDescription: string = "";
  private categoryToDeleteId: string = "";
  private user: User;
  public enableDisableTitle: string = "";
  public enableDisableDescription: string = "";

  // Duplicate Category
  public categoryToBeDuplicated!: TemplateCategoryEnhanced;
  public duplicationObject!: OperationEnhanced;
  private _duplicationSubscription!: Subscription;

  // Properties for the varying duplication text (related to templates)
  public duplicationTitle!: string;
  public duplicationDescription!: string;
  public inProgressText!: string;
  public successText!: string;
  public failedText!: string;
  public navigateToText!: string;
  public duplicationType!: string;

  public categoryForm: FormGroup;

  constructor() {
    // User
    this.user = this.authService.currentUser;

    this.categoryForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      order: null,
      enabled: true,
      items_completed_count: null,
      items_count: null,
      notes: ["", this.formFormattingService.noWhitespaceValidator()],
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getCategoriesList();
  }

  ngOnDestroy(): void {
    this._duplicationSubscription?.unsubscribe();
  }

  /**
   *
   */
  private getCategoriesList(): void {
    try {
      this.categories$ = this.templateCategoriesListService.getCategoriesList$(this.workspaceId, this.templateId).pipe(
        filter((data) => !!data),
        tap((categories) => {
          this.allCategories = [];
          this.allCategories.push(...categories);
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Search Term Change
   * @param term
   */
  public searchTermChange(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   *
   */
  public async limitationManagerCheck(): Promise<void> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction("category_create_update");
    if (limitationResult) {
      this.toggleNewCategoryOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Route to Category Editor or Items
   * @param categoryId
   */
  public async routeToCategoryEditorOrItems(categoryId: string): Promise<void> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction("category_create_update");
    if (this.editMode) {
      if (limitationResult) {
        this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", this.templateId, "categories", categoryId, "editor"]);
      } else {
        this.limitationManagerService.overlay_limitationManager = true;
      }
    } else {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", this.templateId, "categories", categoryId, "items"]);
    }
  }

  /**
   * Reorder Categories
   * @param event
   */
  public async reorderCategories(event: CdkDragDrop<string[]>): Promise<void> {
    moveItemInArray(this.allCategories, event.previousIndex, event.currentIndex);
    try {
      await this.templateCategoriesListService.reorderCategories(this.allCategories, this.workspaceId, this.templateId, this.user);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Single or Multiple Categories
   */
  public deleteSingleOrMultipleCategories(): void {
    if (this.categoriesBulkEditArray$.getValue().length > 0) {
      this.deleteBatchedCategories();
    } else {
      this.deleteSingleCategory();
    }
  }

  /**
   * Set Delete Single Category Properties
   * @param categoryId
   */
  public setDeleteSingleCategoryProperties(categoryId: string): void {
    const deleteCategory = this.stringsService.alertFilter(AlertType.DeleteCategory);
    if (deleteCategory) {
      this.deleteTitle = deleteCategory.title;
      this.deleteDescription = deleteCategory.description;
    } else {
      console.error("Unknown string type:", AlertType.DeleteTemplateCategory);
    }
    this.categoryToDeleteId = categoryId;
    this.toggleDeleteCategoryOverlay();
  }

  /**
   * Delete Single Category
   */
  public async deleteSingleCategory(): Promise<void> {
    try {
      await this.templateCategoryDetailService.deleteCategoryDoc(this.workspaceId, this.templateId, this.allCategories, this.categoryToDeleteId, this.user);
      this.toggleDeleteCategoryOverlay();
      this.checkCategoriesCountForEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Batched Categories
   */
  private async deleteBatchedCategories(): Promise<void> {
    try {
      await this.templateCategoriesListService.deleteBatchedCategories(this.workspaceId, this.templateId, this.categoriesBulkEditArray$.getValue(), this.allCategories, this.user);
      this.toggleDeleteCategoryOverlay();
      this.checkCategoriesCountForEditMode();
      this.categoriesBulkEditArray$.next([]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Check Categories Count For Edit Mode
   */
  private checkCategoriesCountForEditMode(): void {
    if (this.allCategories.length === 0) {
      this.editMode = false;
      this.categoriesBulkEditArray$.next([]);
    }
  }

  /**
   * Create Batch Edit
   * @param category
   */
  public createBatchEdit(category: TemplateCategoryEnhanced): void {
    const currentArray = this.categoriesBulkEditArray$.getValue();
    if (currentArray.some((e: TemplateCategoryEnhanced) => e.id === category.id)) {
      this.removeFromBatchArray(category);
    } else {
      this.addToBatchArray(category);
    }
  }

  /**
   * Add To Batch Array
   * @param category
   */
  private addToBatchArray(category: TemplateCategoryEnhanced): void {
    const updatedArray = [...this.categoriesBulkEditArray$.getValue(), category];
    this.categoriesBulkEditArray$.next(updatedArray);
  }

  /**
   * Remove From Batch Array
   * @param category
   */
  private removeFromBatchArray(category: TemplateCategoryEnhanced): void {
    const updatedArray = this.categoriesBulkEditArray$.getValue().filter((e) => e.id !== category.id);
    this.categoriesBulkEditArray$.next(updatedArray);
  }

  /**
   * Set Delete Batch Categories Properties
   */
  public setDeleteBatchCategoriesProperties(): void {
    if (this.categoriesBulkEditArray$.getValue().length > 0) {
      const deleteCategories = this.stringsService.alertFilter(AlertType.DeleteCategories);
      if (deleteCategories) {
        this.deleteTitle = deleteCategories.title;
        this.deleteDescription = deleteCategories.description;
        this.toggleDeleteCategoryOverlay();
      }
    }
  }

  /**
   * Toggle And Set Duplicate Category Overlay
   * @param category
   */
  async toggleAndSetDuplicateCategoryOverlay(category: TemplateCategoryEnhanced): Promise<void> {
    const featureAction: keyof RolePermissions = "category_create_update";
    const actionType: ActionType = { type: "duplicate" };
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction, actionType);
    if (limitationResult) {
      this.categoryToBeDuplicated = category;
      this.duplicationService.overlay_duplicate = true;
      this.duplicationService.overlay_duplicateConfirmation = true;
      const duplicateCategory = this.operationStringsService.operationFilter(OperationType.DuplicateCategory);
      if (duplicateCategory) {
        this.duplicationTitle = duplicateCategory.title;
        this.duplicationDescription = duplicateCategory.description;
        this.inProgressText = duplicateCategory.running;
        this.successText = duplicateCategory.success;
        this.failedText = duplicateCategory.failure;
        this.navigateToText = "Category";
      } else {
        console.error("Unknown string type:", OperationType.Reinspection);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Duplicate Category
   * @param categoryTitle
   */
  async duplicateCategory(categoryTitle: string) {
    this.duplicationService.toggleDuplicationProcess();
    const duplicationInitiation: WithFieldValue<Operation> = {
      type: "duplicate-template-category",
      created_at: serverTimestamp(),
      status: "pending",
      request: {
        template_id: this.templateId,
        category_id: this.categoryToBeDuplicated.id,
        title: categoryTitle,
      },
    };
    const operation = (await this.duplicationService.duplicate(this.workspaceId, duplicationInitiation)) as OperationEnhanced;
    this.subscribeToDuplicationEvent(operation);
  }

  /**
   * Subscribe To Duplication Event
   * @param operation
   */
  private subscribeToDuplicationEvent(operation: OperationEnhanced): void {
    this._duplicationSubscription = this.duplicationService.getDuplicationOperation$(this.workspaceId, operation.id).subscribe((result) => {
      if (result) {
        this.duplicationObject = result;
      }
    });
  }

  /**
   * Navigate To New Category
   * @returns
   */
  async navigateToNewCategory(): Promise<boolean> {
    this.duplicationService.overlay_duplicate = !this.duplicationService.overlay_duplicate;
    return this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", this.templateId, "categories", this.duplicationObject.response?.new_category_id, "items"]);
  }

  /**
   * Enable/Disable Category
   * @param value
   */
  public async enableDisableCategory(value: boolean): Promise<void> {
    try {
      await this.templateCategoriesListService.enableDisableCategories(this.workspaceId, this.templateId, this.categoriesBulkEditArray$.value, value, this.user);
    } catch (error) {
      alert(error);
    } finally {
      this.categoriesBulkEditArray$.next([]);
      this.toggleEnableDisableCategoryOverlay();
      this.toggleEditMode();
    }
  }

  /**
   * Save Category And Reroute
   */
  public async saveCategoryAndReroute(): Promise<void> {
    try {
      const category = await this.templateCategoryDetailService.setCategoryDoc(this.workspaceId, this.templateId, this.categoryForm.value, "added", this.user);
      const route = ["/workspace", this.workspaceId, "manage", "templates", this.templateId, "categories", category.id, "items"];
      this.router.navigate(route);
      this.resetNewCategoryForm();
      this.toggleNewCategoryOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Category And Add Another
   */
  public async saveCategoryAndAddAnother(): Promise<void> {
    try {
      const category = await this.templateCategoryDetailService.setCategoryDoc(this.workspaceId, this.templateId, this.categoryForm.value, "added", this.user);
      this.resetNewCategoryForm();
      this.categoryForm.markAsPristine();
      this.categoryForm.markAsUntouched();
      this._snackBar.open(`Category '${category.title}' added"`, "Close", {
        duration: 3000,
      });
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Reset New Category Form
   */
  private resetNewCategoryForm(): void {
    if (this.categoryForm.valid) {
      this.categoryForm.patchValue({
        id: null,
        title: "",
        order: null,
        enabled: true,
        items_completed_count: null,
        items_count: null,
        notes: "",
        created_by: null,
        created_by_id: null,
      });

      this.categoryForm.markAsPristine();
      this.categoryForm.markAsUntouched();
    }
  }

  /**
   * Toggle Enable Disable Category Overlay
   */
  public toggleEnableDisableCategoryOverlay(): void {
    if (!this.overlay_enableDisableCategory) {
      const enableDisableCategory = this.stringsService.alertFilter(AlertType.EnableDisableCategories);
      if (enableDisableCategory) {
        this.enableDisableTitle = enableDisableCategory.title;
        this.enableDisableDescription = enableDisableCategory.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteTemplateCategory);
      }
    }
    this.overlay_enableDisableCategory = !this.overlay_enableDisableCategory;
  }

  /**
   * Toggle New Category Overlay
   */
  public toggleNewCategoryOverlay(): void {
    this.overlay_newCategory = !this.overlay_newCategory;
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  /**
   * Toggle Delete Category Overlay
   */
  public toggleDeleteCategoryOverlay(): void {
    this.overlay_deleteCategory = !this.overlay_deleteCategory;
  }
}
