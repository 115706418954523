import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartsTestResults } from 'src/app/models/charts/charts-tests.model';
@Component({
  selector: 'charts-overall',
  standalone: true,
  imports: [],
  templateUrl: './charts-overall.component.html',
  styleUrl: './charts-overall.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartsOverallComponent {
  @Input({ required: true }) items!: ChartsTestResults[];
  @Input({ required: true }) widthHeight = 0;
}
