import { inject, Injectable } from "@angular/core";
import { Category, TemplateCategory, User, WriteEventType } from "cip";
import { deleteField } from "firebase/firestore";
import { catchError, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { GetCountFromServerService } from "src/app/core/services/counts/get-count-from-server.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { TemplateCategoryEnhanced } from "src/app/models/category/template-category.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class TemplateCategoryDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);
  private getCountFromServerService = inject(GetCountFromServerService);

  /**
   * Get Category Doc
   * @param workspaceId
   * @param templateId
   */
  public getCategoryDoc$(workspaceId: string, templateId: string, categoryId: string): Observable<TemplateCategoryEnhanced | null> {
    const path = `${this.collectionsService.templateCategoriesCol(workspaceId, templateId)}/${categoryId}`;
    return this.firestoreUtilsService.getDocumentData<TemplateCategoryEnhanced>(path).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Set Category Doc
   * @param workspaceId
   * @param templateId
   * @param categoryForm
   * @param writeEventType
   * @param user
   */
  public async setCategoryDoc(workspaceId: string, templateId: string, categoryForm: TemplateCategoryEnhanced, writeEventType: WriteEventType, user: User) {
    const path = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const categoryId = categoryForm.id ?? this.firestoreUtilsService.createFirestoreId();
    const documentPath = `${path}/${categoryId}`;
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const categoriesCount = await this.getCountFromServerService.getCountFromServer(path);
    const categoryObj = {
      title: categoryForm.title.trim(),
      order: categoryForm.order === null ? categoriesCount : categoryForm.order,
      items_count: categoryForm.items_count === null ? 0 : categoryForm.items_count,
      notes: categoryForm.notes === null || categoryForm.notes === undefined || categoryForm.notes === "" ? deleteField() : categoryForm.notes.trim(),
      created_by_id: categoryForm.created_by_id ?? user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };
    await this.firestoreUtilsService.setDocumentDataWithFieldValue<TemplateCategory>(documentPath, categoryObj);
    return { ...categoryObj, id: categoryId };
  }

  /**
   * Delete Category Doc
   * @param workspaceId
   * @param templateId
   * @param categories
   * @param categoryId
   * @param user
   */
  public async deleteCategoryDoc(workspaceId: string, templateId: string, categories: TemplateCategoryEnhanced[], categoryId: string, user: User): Promise<void> {
    const path = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const doc = `${path}/${categoryId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const obj = { is_deleted: true, last_event: lastEvent };
    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: doc,
        data: obj,
      },
    ];
    const remainingCategories = categories.filter((existingCategory) => existingCategory.id !== categoryId);
    remainingCategories.forEach((remainingCategory, index) => {
      const updatePath = `${path}/${remainingCategory.id}`;
      operations.push({
        type: "update",
        documentPath: updatePath,
        data: { order: index },
      });
    });
    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Enable/Disable Category
   * @param enabled
   * @param workspaceId
   * @param templateId
   * @param categoryId
   */
  async enableDisableCategory(enabled: boolean, workspaceId: string, templateId: string, categoryId: string): Promise<void> {
    const path = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const doc = `${path}/${categoryId}`;
    await this.firestoreUtilsService.setPartialDocumentData<Category>(doc, { enabled: enabled });
  }
}
