@if (template$ | async; as template) {
  <manage-template-overview-header [workspaceId]="workspaceId" [template]="template" />

  <div class="space-y-4">
    <div class="grid gap-4 md:grid-cols-6">
      <div class="col-span-4">
        <div class="grid gap-y-4">
          <manage-template-overview-contents [templateId]="templateId" [workspaceId]="workspaceId" />
          <manage-template-overview-details [template]="template" (routeToTemplateEditorOutput)="routeToTemplateEditor()" />
        </div>
      </div>
      <div class="col-span-4 md:col-span-2">
        <manage-template-overview-actions
          [categoriesCount]="categoriesCount"
          [workspaceId]="workspaceId"
          (startInspectionFromTemplateOverlayOutput)="canUserCreateInspections()"
          (duplicateTemplateOverlayOutput)="duplicateTemplateOverlay()"
          (toggleDeleteTemplateOverlayOutput)="canUserDeleteTemplate()" />
      </div>
    </div>

    @if (duplicationService.overlay_duplicate) {
      <duplicate-overlay
        [duplicationObject]="duplicationObject!"
        [originalDocumentTitle]="template.title + ' ' + '(Copy)'"
        [duplicationTitle]="templateDetailService.duplicationTitle"
        [duplicationDescription]="templateDetailService.duplicationDescription"
        [inProgressText]="templateDetailService.inProgressText"
        [successText]="templateDetailService.successText"
        [failedText]="templateDetailService.failedText"
        [navigateToText]="'Template'"
        (duplicateEmitter)="duplicateTemplate($event)"
        (navigateToNewDocEmitter)="navigateToNewTemplate()" />
    }

    @if (templateDetailService.overlay_duplicate) {
      <manage-template-start-inspection
        [workspaceId]="workspaceId"
        [duplicationObject]="duplicationObject!"
        [template]="template"
        [canUserCreateFoldersLimitationResult]="canUserCreateFoldersLimitationResult"
        (templateToInspectionEmitter)="templateToInspectionDuplication($event)"
        (navigateToNewInspectionEmitter)="navigateToNewInspection()"
        [navigateToText]="'Inspection'" />
    }
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteTemplate) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (deleteButtonClicked)="deleteTemplate()" (cancelButtonClicked)="toggleDeleteTemplateOverlay()" />
}
@if (templateDetailService.overlay_newFolder) {
  <folder-edit-wrapper [folderForm]="folderForm" [limitationResult]="canUserCreateFoldersLimitationResult" [isNewFolder]="true" (toggleEditFolderOverlayOutput)="cancelNewFolder()" (saveFolderOutput)="saveNewFolder()" />
}
