import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuditLogDoc } from "src/app/models/audit-log/audit-log-doc.model";

@Component({
  selector: "audit-log-overview",
  standalone: true,
  imports: [DatePipe, RouterModule],
  templateUrl: "./audit-log-overview.component.html",
  styleUrl: "./audit-log-overview.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditLogOverviewComponent {
  @Input({ required: true }) auditDoc!: AuditLogDoc;
  @Input({ required: true }) workspaceId!: string;
}
