import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemEnhanced } from 'src/app/models/item/item.model';
import { BreadcrumbsComponent } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'inspection-item-edit-header',
  standalone: true,
  imports: [BreadcrumbsComponent],
  templateUrl: './inspection-item-edit-header.component.html',
  styleUrl: './inspection-item-edit-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionItemEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) categoryId!: string;
  @Input({ required: true }) itemId!: string;
  @Input({ required: true }) item!: ItemEnhanced;
  @Output() duplicateItemOutput = new EventEmitter<void>();

  /**
   * Duplicate Item
   */
  public duplicateItem() {
    this.duplicateItemOutput.emit();
  }
}
