import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { User } from "cip";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "manage-members-list",
  standalone: true,
  imports: [SearchBarComponent, SearchEmptyComponent, RouterModule],
  templateUrl: "./manage-members-list.component.html",
  styleUrl: "./manage-members-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageMembersListComponent {
  @Input({ required: true })
  set members(value: MemberEnhanced[]) {
    this._members = value;
    this.updateFilteredMembersForSearchResults();
  }

  get members(): MemberEnhanced[] {
    return this._members;
  }

  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) user!: User;
  private _members: MemberEnhanced[] = [];
  public filteredMembers: Array<MemberEnhanced> = [];
  public allMembersHidden: boolean = false;
  public searchTerm: string = "";

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.updateFilteredMembersForSearchResults();
  }

  /**
   * Apply Search
   */
  updateFilteredMembersForSearchResults(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredMembers = this.members.map((member) => ({
      ...member,
      hidden: !(member.name_first.toLowerCase().includes(lowerSearchTerm) || member.name_last.toLowerCase().includes(lowerSearchTerm)),
    }));
    this.allMembersHidden = this.filteredMembers.every((member) => member.hidden);
  }
}
