<ul class="list-ul">
  @for (connectedDevice of connectedDevicesService.connectedDevices; track $index) {
    <li class="list-row">
      <div class="flex flex-col">
        <div class="list-title">{{ connectedDevice.device }}</div>
        <div class="list-meta">{{ connectedDevice.date_connected | date: "dd MMM yyyy" }}, {{ connectedDevice.date_connected | date: "HH:mm" }}</div>
        @if (connectedDevice.id === connectedDevicesService.uniqueId) {
          <div>
            <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Current Device</span>
          </div>
        }
      </div>
      <div class="flex items-center">
        <button class="btn btn-secondary" (click)="connectedDevicesService.setSelectedDeviceActionToSignOut(connectedDevice.id)">Sign Out</button>
      </div>
    </li>
  }
</ul>
