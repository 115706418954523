import { Component, inject, Input, OnDestroy } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { Operation, RolePermissions, User } from "cip";
import { serverTimestamp, WithFieldValue } from "firebase/firestore";
import { Subscription } from "rxjs";
import { DuplicationService } from "src/app/core/services/duplication/duplication.service";
import { ActionType, LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { OperationStringsService } from "src/app/core/services/strings/operation-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { InspectionEnhanced } from "src/app/models/inspection/inspection.model";
import { OperationEnhanced } from "src/app/models/operation/operation.model";
import { AlertType, OperationType } from "src/app/models/strings/strings.model";
import { DeleteConfirmationComponent } from "src/app/shared/delete-confirmation/delete-confirmation.component";
import { DuplicateOverlayComponent } from "src/app/shared/duplicate-overlay/duplicate-overlay.component";
import { InspectionDetailService } from "../../services/inspection-detail.service";

@Component({
  selector: "inspection-overview-actions",
  standalone: true,
  imports: [RouterModule, DeleteConfirmationComponent, DuplicateOverlayComponent],
  templateUrl: "./inspection-overview-actions.component.html",
  styleUrl: "./inspection-overview-actions.component.scss",
})
export class InspectionOverviewActionsComponent implements OnDestroy {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) inspection!: InspectionEnhanced;
  @Input({ required: true }) user!: User;

  // Services
  private limitationManagerService = inject(LimitationManagerService);
  public inspectionDetailService = inject(InspectionDetailService);
  private router = inject(Router);
  public duplicationService = inject(DuplicationService);
  private operationStringsService = inject(OperationStringsService);
  private stringsService = inject(StringsService);

  // A subscription to the operation document for duplications
  private _duplicationSubscription!: Subscription;

  // An object containing the state of the duplication operation
  public duplicationObject!: OperationEnhanced;

  // Deletes
  public deleteOverlayTitle!: string;
  public deleteOverlayDescription!: string;

  public overlay_deleteInspection = false;

  /**
   * TODO - Optimise the similar functions
   */

  ngOnDestroy(): void {
    this._duplicationSubscription?.unsubscribe();
  }

  // Set Duplicate Inspection Overlay
  async setDuplicateInspectionOverlay(): Promise<void> {
    const featureAction: keyof RolePermissions = "inspection_create_update";
    const actionType: ActionType = { type: "duplicate" };
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction, actionType);
    if (limitationResult) {
      this.duplicationService.overlay_duplicate = true;
      this.duplicationService.overlay_duplicateConfirmation = true;
      const duplicateInspection = this.operationStringsService.operationFilter(OperationType.DuplicateInspection);
      if (duplicateInspection) {
        this.inspectionDetailService.duplicationTitle = duplicateInspection.title;
        this.inspectionDetailService.duplicationDescription = duplicateInspection.description;
        this.inspectionDetailService.inProgressText = duplicateInspection.running;
        this.inspectionDetailService.successText = duplicateInspection.success;
        this.inspectionDetailService.failedText = duplicateInspection.failure;
        this.inspectionDetailService.navigateToText = "Inspection";
        this.inspectionDetailService.duplicationType = "duplicate-inspection";
      } else {
        console.error("Unknown string type:", OperationType.DuplicateInspection);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  // Set Reinspection Overlay
  async setReinspectionOverlay(): Promise<void> {
    const featureAction: keyof RolePermissions = "inspection_create_update";
    const actionType: ActionType = { type: "duplicate" };
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction, actionType);
    if (limitationResult) {
      this.duplicationService.overlay_duplicate = true;
      this.duplicationService.overlay_duplicateConfirmation = true;
      const reinspection = this.operationStringsService.operationFilter(OperationType.Reinspection);
      if (reinspection) {
        this.inspectionDetailService.duplicationTitle = reinspection.title;
        this.inspectionDetailService.duplicationDescription = reinspection.description;
        this.inspectionDetailService.inProgressText = reinspection.running;
        this.inspectionDetailService.successText = reinspection.success;
        this.inspectionDetailService.failedText = reinspection.failure;
        this.inspectionDetailService.navigateToText = "Inspection";
        this.inspectionDetailService.duplicationType = "reinspection";
      } else {
        console.error("Unknown string type:", OperationType.Reinspection);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  // Set Save Inspection As Template Overlay
  async setSaveInspectionAsTemplateOverlay(): Promise<void> {
    const featureAction: keyof RolePermissions = "template_create_update";
    const actionType: ActionType = { type: "duplicate" };
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction, actionType);
    if (limitationResult) {
      this.duplicationService.overlay_duplicate = true;
      this.duplicationService.overlay_duplicateConfirmation = true;
      const saveInspectionAsTemplate = this.operationStringsService.operationFilter(OperationType.SaveInspectionAsTemplate);
      if (saveInspectionAsTemplate) {
        this.inspectionDetailService.duplicationTitle = saveInspectionAsTemplate.title;
        this.inspectionDetailService.duplicationDescription = saveInspectionAsTemplate.description;
        this.inspectionDetailService.inProgressText = saveInspectionAsTemplate.running;
        this.inspectionDetailService.successText = saveInspectionAsTemplate.success;
        this.inspectionDetailService.failedText = saveInspectionAsTemplate.failure;
        this.inspectionDetailService.navigateToText = "Template";
        this.inspectionDetailService.duplicationType = "create-template-from-inspection";
      } else {
        console.error("Unknown string type:", OperationType.SaveInspectionAsTemplate);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  // Which Duplication Route To Navigate To
  whichDuplicationRouteToNavigateTo(): void {
    switch (this.inspectionDetailService.duplicationType) {
      case "duplicate-inspection":
      case "reinspection":
        return this.navigateToNewInspection();
      case "create-template-from-inspection":
        return this.navigateToNewTemplate();
      default:
        return;
    }
  }

  //
  whichDuplicationProcessToRun(inspectionTitle: string) {
    this.duplicationService.toggleDuplicationProcess();
    switch (this.inspectionDetailService.duplicationType) {
      case "duplicate-inspection":
        this.duplicateInspectionAndSubscribe(inspectionTitle);
        break;
      case "reinspection":
        this.reinspectInspectionAndSubscribe(inspectionTitle);
        break;
      case "create-template-from-inspection":
        this.createTemplateFromInspectionAndSubscribe(inspectionTitle);
        break;
      default:
        break;
    }
  }

  // Duplicate Inspection and Subscribe
  async duplicateInspectionAndSubscribe(inspectionTitle: string) {
    const duplicateInspection: WithFieldValue<Operation> = {
      type: "duplicate-inspection",
      created_at: serverTimestamp(),
      status: "pending",
      request: {
        title: inspectionTitle,
        inspection_id: this.inspectionId,
        created_by: `${this.user.name_first} ${this.user.name_last}`,
        created_by_id: `${this.user.user_id}`,
      },
    };
    const operation = (await this.duplicationService.duplicate(this.workspaceId, duplicateInspection)) as OperationEnhanced;
    this.subscribeToDuplicationEvent(operation);
  }

  // Reinspect Inspection and Subscribe
  async reinspectInspectionAndSubscribe(inspectionTitle: string) {
    const reinspection: WithFieldValue<Operation> = {
      type: "reinspection",
      created_at: serverTimestamp(),
      status: "pending",
      request: {
        title: inspectionTitle,
        inspection_id: this.inspectionId,
        created_by: `${this.user.name_first} ${this.user.name_last}`,
        created_by_id: `${this.user.user_id}`,
      },
    };
    const operation = (await this.duplicationService.duplicate(this.workspaceId, reinspection)) as OperationEnhanced;
    this.subscribeToDuplicationEvent(operation);
  }

  // Create Template From Inspection and Subscribe
  async createTemplateFromInspectionAndSubscribe(inspectionTitle: string) {
    const createTemplateFromInspection: WithFieldValue<Operation> = {
      type: "create-template-from-inspection",
      created_at: serverTimestamp(),
      status: "pending",
      request: {
        title: inspectionTitle,
        inspection_id: this.inspectionId,
        created_by: `${this.user.name_first} ${this.user.name_last}`,
        created_by_id: `${this.user.user_id}`,
      },
    };
    const operation = (await this.duplicationService.duplicate(this.workspaceId, createTemplateFromInspection)) as OperationEnhanced;
    this.subscribeToDuplicationEvent(operation);
  }

  // Subscribe to Duplication Event
  subscribeToDuplicationEvent(operation: OperationEnhanced) {
    this._duplicationSubscription = this.duplicationService.getDuplicationOperation$(this.workspaceId, operation.id).subscribe((result) => {
      if (result) {
        this.duplicationObject = result;
      }
    });
  }

  // Navigate to New Inspection
  navigateToNewInspection(): void {
    this.duplicationService.overlay_duplicate = !this.duplicationService.overlay_duplicate;
    this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.duplicationObject.response?.new_inspection_id, "overview"]);
  }

  // Navigate to New Template
  navigateToNewTemplate(): void {
    this.duplicationService.overlay_duplicate = !this.duplicationService.overlay_duplicate;
    this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", this.duplicationObject.response?.new_template_id, "overview"]);
  }

  // Toggle Delete Inspection Overlay
  async deleteInspectionLimitationManagerCheck(): Promise<void> {
    const featureAction: keyof RolePermissions = "inspection_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const deleteInspection = this.stringsService.alertFilter(AlertType.DeleteInspection);
      if (deleteInspection) {
        this.toggleDeleteInspectionOverlay();
        this.deleteOverlayTitle = deleteInspection.title;
        this.deleteOverlayDescription = deleteInspection.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteInspection);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  toggleDeleteInspectionOverlay(): void {
    this.overlay_deleteInspection = !this.overlay_deleteInspection;
  }

  /**
   * Delete Inspection
   */
  async deleteInspection(): Promise<void> {
    try {
      await this.inspectionDetailService.deleteInspectionDoc(this.workspaceId, this.folderId, this.inspectionId, this.user);
      this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections"]);
      this.overlay_deleteInspection = false;
    } catch (error) {
      alert(error);
    }
  }

  // Route to Supplementary Editor
  async routeToSupplementaryEditor(): Promise<void> {
    const featureAction: keyof RolePermissions = "inspection_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const route = ["/", "workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "internalnotes"];
      limitationResult ? this.router.navigate(route) : (this.limitationManagerService.overlay_limitationManager = true);
    }
  }
}
