@if (sites$ | async; as sites) {
  <div class="flex min-w-0 flex-1">
    <div class="flex h-full min-w-0 flex-1 flex-col overflow-hidden">
      @if (sitesBulkEditArray$ | async; as sitesBulkEditArray) {
        <manage-sites-list-header
          [workspaceId]="workspaceId"
          [sitesCount]="sites.length"
          [editMode]="editMode"
          [sitesBulkEditArray]="sitesBulkEditArray"
          (searchTermChange)="searchTermChange($event)"
          (routeToSiteEditorOutput)="canUserCreateSite()"
          (toggleEditModeOutput)="toggleEditMode()"
          (bulkDeleteSitesOutput)="setDeleteBatchSitesProperties()" />

        @if (sites.length > 0) {
          <manage-sites-list
            [sites]="sites"
            [workspaceId]="workspaceId"
            [searchTerm]="currentSearchTerm"
            [editMode]="editMode"
            [sitesBulkEditArray]="sitesBulkEditArray"
            (deleteSingleSiteOutput)="setDeleteSingleSiteProperties($event)"
            (createBatchEditOutput)="createBatchEdit($event)"
            (routeToSiteEditorOutput)="routeToSiteEditor($event)" />
        } @else {
          <empty-list [list]="'site'" />
        }
      }
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteSite()) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (deleteButtonClicked)="deleteSingleOrMultipleSites()" (cancelButtonClicked)="toggleDeleteSiteOverlay(false)" />
}
