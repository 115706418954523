import { JsonPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, FormsModule } from "@angular/forms";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ManageWorkspaceTransferOwnershipMembersListComponent } from "../manage-workspace-transfer-ownership-members-list/manage-workspace-transfer-ownership-members-list.component";

@Component({
  selector: "manage-workspace-transfer-ownership-wrapper",
  standalone: true,
  imports: [ManageWorkspaceTransferOwnershipMembersListComponent, NgClass, LoadingSpinnerComponent, InfoViewComponent, FormsModule, JsonPipe],
  templateUrl: "./manage-workspace-transfer-ownership-wrapper.component.html",
  styleUrl: "./manage-workspace-transfer-ownership-wrapper.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageWorkspaceTransferOwnershipWrapperComponent {
  @Input({ required: true }) workspaceForm!: FormGroup;
  @Input({ required: true }) overlay_selectOwner!: boolean;
  @Input({ required: true }) transferOwnershipResponseLoadingIndicator!: boolean;
  @Input({ required: true }) transferConfirmation!: string;
  @Input() members: MemberEnhanced[] | undefined | null;
  @Input() allOtherMembers: Array<MemberEnhanced> = [];
  @Input() currentlySelectedMember: MemberEnhanced | null = null;
  @Output() toggleEditOwnershipOverlayOutput = new EventEmitter<void>();
  @Output() toggleNewOwnerPanelOutput = new EventEmitter<void>();
  @Output() transferOwnershipAndCloseOverlaysOutput = new EventEmitter<void>();
  @Output() resetTransferOwnershipPropertiesOutput = new EventEmitter<void>();
  @Output() memberSelectionOutput = new EventEmitter<MemberEnhanced>();

  /**
   * Toggle the edit ownership overlay
   */
  toggleEditOwnershipOverlay(): void {
    this.toggleEditOwnershipOverlayOutput.emit();
  }

  /**
   * Toggle the new owner panel
   */
  toggleNewOwnerPanel(): void {
    this.toggleNewOwnerPanelOutput.emit();
  }

  /**
   * Transfer ownership of the workspace to the selected member
   */
  async transferOwnershipAndCloseOverlays(): Promise<void> {
    this.transferOwnershipAndCloseOverlaysOutput.emit();
  }

  /**
   * Reset the transfer ownership properties
   */
  resetTransferOwnershipProperties(): void {
    this.resetTransferOwnershipPropertiesOutput.emit();
  }

  /**
   * Member Selection
   * @param member
   */
  public memberSelection(member: MemberEnhanced): void {
    this.memberSelectionOutput.emit(member);
  }
}
