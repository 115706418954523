<header class="pt-10">
  <div class="px-6 pb-2">
    <nav aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-2">
        <li>
          <div class="flex items-center">
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'themes']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Themes</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="px-6">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">{{ themeId === "new" ? "Add " : "Edit " }} Theme</h2>
      </div>
    </div>

    @if (themeId === "default") {
      <div class="my-5">
        <info-view [type]="infoViewThemeEditor.type" [message]="infoViewThemeEditor.message" />
      </div>
    }
  </div>
</header>
