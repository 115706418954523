import { DatePipe, NgStyle, NgTemplateOutlet } from "@angular/common";
import { Component, inject } from "@angular/core";
import { User } from "cip";
import { AuthService } from "src/app/core/services/auth.service";
import { CategoryEnhanced } from "src/app/models/category/category.model";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { ReportsCsvService } from "../../services/report-csv-service.service";
import { ReportsService } from "../../services/reports.service";
import { CSVActionRow, CSVCategoryRow, CSVClientRow, CSVData, CSVInspectionRow, CSVIntroductionRow, CSVItemRow, CSVPhotoRow, CSVRow, CSVSignatureRow, CSVSiteRow, CSVSummaryRow, CSVUserRow, CSVWorkspaceRow } from "../../types/types";

@Component({
  selector: "report-content-csv",
  standalone: true,
  imports: [DatePipe, NgTemplateOutlet, NgStyle],
  templateUrl: "./report-content-csv.component.html",
  styleUrl: "./report-content-csv.component.scss",
})
export class ReportContentCsvComponent {
  // Services
  private authService = inject(AuthService);
  public reportsService = inject(ReportsService);
  public reportsCsvService = inject(ReportsCsvService);

  // Properties
  public csvData!: CSVData;
  private user: User;

  constructor() {
    this.user = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.csvData = this.buildCSVFromReportData();
  }

  public buildCSVFromReportData(): CSVData {
    const { workspace, inspection, site, client, categories, signatures, introduction, summary } = this.reportsService.reportData;
    const rows: CSVRow[] = [];

    if (workspace) rows.push({ type: "workspace", workspace });
    if (inspection) rows.push({ type: "inspection", inspection });
    if (site) rows.push({ type: "site", site });
    if (client) rows.push({ type: "client", client });
    if (introduction) rows.push({ type: "introduction", introduction });

    const rowsForEachCategory = categories.map((category) => this.buildRowsForCategory(category));
    rows.push(...rowsForEachCategory.flat(1));

    if (summary) rows.push({ type: "summary", summary });

    signatures.forEach((signature) => {
      rows.push({ type: "signature", signature });
    });

    rows.push({ type: "user", user: this.user });

    return { rows: rows.map((row, rowIdx) => ({ row, index: rowIdx + 1 })) };
  }

  private buildRowsForCategory(category: CategoryEnhanced) {
    const { items } = this.reportsService.reportData;
    const rows: CSVRow[] = [{ type: "category", category }];
    const categoryItems = items.filter((item) => item.category_id === category.id);
    const rowsForEachItem = categoryItems.map((item) => this.buildRowsForItem(item));
    rows.push(...rowsForEachItem.flat(1));
    return rows;
  }

  private buildRowsForItem(item: ItemEnhanced) {
    const { actions, photos } = this.reportsService.reportData;
    const rows: CSVRow[] = [{ type: "item", item }];
    const itemActions = actions.filter((action) => action.item_id === item.id);
    itemActions.forEach((action) => {
      rows.push({ type: "action", action });
    });
    const itemPhotos = photos.filter((photo) => photo.item_id === item.id);
    itemPhotos.forEach((photo) => {
      rows.push({ type: "photo", photo });
    });
    return rows;
  }

  public isWorkspaceRow(row: CSVRow): row is CSVWorkspaceRow {
    return row.type === "workspace";
  }

  public isInspectionRow(row: CSVRow): row is CSVInspectionRow {
    return row.type === "inspection";
  }

  public isSiteRow(row: CSVRow): row is CSVSiteRow {
    return row.type === "site";
  }

  public isClientRow(row: CSVRow): row is CSVClientRow {
    return row.type === "client";
  }

  public isIntroductionRow(row: CSVRow): row is CSVIntroductionRow {
    return row.type === "introduction";
  }

  public isSummaryRow(row: CSVRow): row is CSVSummaryRow {
    return row.type === "summary";
  }

  public isCategoryRow(row: CSVRow): row is CSVCategoryRow {
    return row.type === "category";
  }

  public isItemRow(row: CSVRow): row is CSVItemRow {
    return row.type === "item";
  }

  public isActionRow(row: CSVRow): row is CSVActionRow {
    return row.type === "action";
  }

  public isPhotoRow(row: CSVRow): row is CSVPhotoRow {
    return row.type === "photo";
  }

  public isSignatureRow(row: CSVRow): row is CSVSignatureRow {
    return row.type === "signature";
  }

  public isUserRow(row: CSVRow): row is CSVUserRow {
    return row.type === "user";
  }
}
