<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block h-[600px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between">
        <!-- Header & New-->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">{{ overlay_roleForm ? "Add Role" : "Role Selector" }}</h3>
        </div>

        <div class="flex h-full flex-1 overflow-hidden">
          <div class="w-full space-y-4 overflow-y-scroll overscroll-contain">
            <!-- Tabs -->
            @if (!overlay_roleForm) {
              <div class="mt-4 flex w-full flex-col px-12 sm:flex-row">
                <nav class="flex space-x-4" aria-label="Tabs">
                  <div class="cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-700" [ngClass]="selectedTab === 'Default' ? 'bg-gray-200' : 'bg-gray-100'" (click)="selectTab('Default')">Default Roles</div>
                  <div class="cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-700" [ngClass]="selectedTab === 'Custom' ? 'bg-gray-200' : 'bg-gray-100'" (click)="selectTab('Custom')">Custom Roles</div>
                </nav>
              </div>
            }

            <div>
              @if (!overlay_roleForm) {
                @if (selectedTab === "Default") {
                  <role-selector-default [defaultRoles]="defaultRoles" [currentRole]="currentRole" (selectRoleOutput)="selectRole($event)" />
                } @else {
                  <role-selector-custom [customRoles]="customRoles" [currentRole]="currentRole" (selectRoleOutput)="selectRole($event)" (toggleRoleFormOverlayOutput)="toggleRoleFormOverlay()" />
                }
              } @else {
                <div class="grid grid-cols-1 gap-y-8 p-6">
                  <div class="content-card-wrapper">
                    <div class="content-card-header">
                      <h3>Details</h3>
                    </div>

                    <div class="content-card">
                      <div class="content-grid gap-y-6">
                        <role-edit-details [roleForm]="roleForm" />
                      </div>
                    </div>
                  </div>

                  <div class="content-card-wrapper">
                    <div class="content-card-header">
                      <h3>Permissions</h3>
                    </div>
                    <div class="content-card">
                      <div class="content-grid gap-y-6">
                        <permissions-grid [roleForm]="roleForm" [isDisabled]="false" [infoViewPermissionsEditor]="infoViewPermissionsEditor" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        @if (overlay_roleForm) {
          <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
            <button
              type="button"
              class="btn ml-3"
              [disabled]="roleForm.pristine || roleForm.controls['title'].errors || roleForm.controls['description'].errors"
              [ngClass]="roleForm.pristine || roleForm.controls['title'].errors || roleForm.controls['description'].errors ? 'btn-disabled cursor-not-allowed' : 'btn-primary'"
              (click)="saveRole()">
              Save
            </button>
            <button type="button" class="btn btn-cancel" (click)="toggleRoleFormOverlay()">Cancel</button>
          </div>
        } @else {
          <div class="flex justify-end bg-gray-100 px-6 py-6">
            <button type="button" class="btn btn-cancel" (click)="toggleRoleSelector()">Close</button>
          </div>
        }
      </div>
    </div>
  </div>
</div>
