type Theme = 'dark' | 'light';

const KEYS = {
  themeMode: 'themeMode',
  emailForSignIn: 'emailForSignIn',
  cipIsNewAccount: 'cipIsNewAccount',
  cipAuthUniqueSessionId: 'cipAuthUniqueSessionId',
  cipAuthUniqueSessionLock: 'cipAuthUniqueSessionLock',
};

class AppStorage {
  private get storage() {
    return window.localStorage;
  }

  public setEmailForSignin(email: string) {
    this.storage.setItem(KEYS.emailForSignIn, email);
  }

  public getEmailForSignin() {
    const rawVal = this.storage.getItem(KEYS.emailForSignIn);
    if (rawVal === null) return null;
    return rawVal;
  }

  public removeEmailForSignin() {
    this.storage.removeItem(KEYS.emailForSignIn);
  }

  public setThemeMode(theme: Theme) {
    this.storage.setItem(KEYS.themeMode, theme);
  }

  public getThemeMode() {
    const rawVal = this.storage.getItem(KEYS.themeMode);
    if (rawVal === null) return null;
    return rawVal as Theme;
  }

  public removeThemeMode() {
    this.storage.removeItem(KEYS.themeMode);
  }

  public setCipIsNewAccount(val: boolean) {
    this.storage.setItem(KEYS.cipIsNewAccount, `${val}`);
  }

  public getCipIsNewAccount() {
    const rawVal = this.storage.getItem(KEYS.cipIsNewAccount);
    if (rawVal === null) return null;
    return this.parseBoolean(rawVal);
  }

  public removeCipIsNewAccount() {
    this.storage.removeItem(KEYS.cipIsNewAccount);
  }

  // Unique Session ID
  public setCipAuthUniqueSessionId(val: string) {
    this.storage.setItem(KEYS.cipAuthUniqueSessionId, `${val}`);
  }

  public getCipAuthUniqueSessionId() {
    const rawVal = this.storage.getItem(KEYS.cipAuthUniqueSessionId);
    if (rawVal === null) return null;
    return rawVal;
  }

  public removeCipAuthUniqueSessionId() {
    this.storage.removeItem(KEYS.cipAuthUniqueSessionId);
  }

  public setCipAuthUniqueSessionLock(val: string) {
    this.storage.setItem(KEYS.cipAuthUniqueSessionLock, `${val}`);
  }

  public getCipAuthUniqueSessionLock() {
    const rawVal = this.storage.getItem(KEYS.cipAuthUniqueSessionLock);
    if (rawVal === null) return null;
    return rawVal;
  }

  public removecipAuthUniqueSessionLock() {
    this.storage.removeItem(KEYS.cipAuthUniqueSessionLock);
  }

  private parseBoolean(rawVal: string) {
    if (rawVal === 'true') return true;
    if (rawVal === 'false') return false;
    throw new Error(`The value '${rawVal}' cannot be parsed to a boolean.`);
  }

  public removeAllItems() {
    Object.entries(KEYS).forEach(([, key]) => {
      this.storage.removeItem(key);
    });
  }
}

export const appStorage = new AppStorage();
