import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

@Component({
  selector: "manage-templates-list-import",
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, NgClass],
  templateUrl: "./manage-templates-list-import.component.html",
  styleUrl: "./manage-templates-list-import.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplatesListImportComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) importTemplateForm!: FormGroup;
  @Input({ required: true }) fileAdded!: boolean;
  @Input() fileName!: string;
  @Input() newImportTemplateId!: string;
  @Input() fileUploading!: boolean;
  @Input() fileUploadComplete!: boolean;

  @Output() toggleImportTemplateOverlayOutput = new EventEmitter<void>();
  @Output() fileSelectedOutput = new EventEmitter<Event>();
  @Output() importTemplateOutput = new EventEmitter<void>();

  /**
   * Toggles the import template overlay
   */
  public toggleImportTemplateOverlay(): void {
    this.toggleImportTemplateOverlayOutput.emit();
  }

  /**
   * Handles file selection and emits the event to parent
   * @param event The file input change event
   */
  public onFileSelected(event: Event): void {
    this.fileSelectedOutput.emit(event);
  }

  /**
   * Emits event to trigger template import in parent
   */
  public onImportTemplate(): void {
    this.importTemplateOutput.emit();
  }
}
