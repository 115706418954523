import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-csv",
  standalone: true,
  imports: [],
  templateUrl: "./report-settings-csv.component.html",
  styleUrl: "./report-settings-csv.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsCsvComponent {
  @Input({ required: true }) workspaceId!: string;

  // Services
  public reportsService = inject(ReportsService);

  public toggleCsvConfirmation(): void {
    this.reportsService.overlay_csvConfirmation = !this.reportsService.overlay_csvConfirmation;
    this.reportsService.isCSVBeingGenerated = false;
  }

  public setCSVGenerationChoice(): void {
    this.toggleCsvConfirmation();
    this.reportsService.reportSettings.csv_images ? this.reportsService.generateCSVWithImages(this.workspaceId) : this.reportsService.generateCSVWithoutImages();
  }

  public resetCSVDownload(): void {
    this.reportsService.csvDownloadURL = "";
    this.reportsService.isCSVBeingGenerated = false;
    this.reportsService.overlay_csvConfirmation = false;
  }
}
