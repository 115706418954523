<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block h-[600px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">Add template</h3>
        </div>

        <!-- New template -->
        <div class="flex h-full w-full flex-col overflow-hidden">
          <div class="w-full flex-1 overflow-y-scroll">
            <div class="grid gap-y-8 px-6 py-5">
              <div class="content-card-wrapper">
                <div class="content-card-header">
                  <h3>Details</h3>
                </div>
                <div class="px-6 py-5">
                  <div class="content-grid gap-x-4 gap-y-6">
                    <!-- Details -->
                    <manage-template-edit-details [templateForm]="templateForm" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Actions -->
          <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
            <button type="button" class="btn ml-3" [disabled]="templateForm.pristine || templateForm.invalid" [ngClass]="templateForm.pristine || templateForm.invalid ? 'btn-disabled' : 'btn-primary'" (click)="saveTemplate()">Save</button>
            <button type="button" class="btn btn-cancel" (click)="toggleNewTemplateOverlay()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
