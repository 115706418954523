<form [formGroup]="inspectionForm" class="content-grid grid-cols-2 gap-x-4 gap-y-6">
  <div class="sm:col-span-1">
    <div class="form-input-wrapper">
      <label for="inspection-title" class="form-input-label"
        >Title*
        @if (!inspectionForm.pristine && inspectionForm.controls['title'].errors) {
          <span class="required-field"> - Title is required</span>
        }
      </label>
      <input type="text" name="inspection-title" id="inspection-title" class="form-input" placeholder="Title" formControlName="title" />
    </div>
  </div>
  <div class="sm:col-span-1">
    <div class="form-input-wrapper">
      <label for="inspection-reference" class="form-input-label">Reference</label>
      <input type="text" name="inspection-reference" id="inspection-reference" class="form-input" placeholder="Reference" formControlName="ref" />
    </div>
  </div>
  <div class="sm:col-span-1">
    <div class="form-input-wrapper" id="cip-date-picker">
      <label for="inspection-date" class="form-input-label"
        >Date*
        @if (!inspectionForm.pristine && inspectionForm.controls['date'].errors) {
          <span class="required-field"> - Date is required</span>
        }
      </label>
      <div class="sm:col-span-1">
        <div class="flex justify-between">
          <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date" class="form-input" formControlName="date" id="date" />
          @if (inspectionForm.value.date) {
            <button (click)="clearInspectionDate()" class="rounded-md hover:bg-brand_Primary-hover hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          }
        </div>
        <owl-date-time #dt1 [pickerType]="'both'"></owl-date-time>
      </div>
    </div>
  </div>
</form>
