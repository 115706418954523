import { inject, Injectable } from "@angular/core";
import { where } from "firebase/firestore";
import { BehaviorSubject, Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { ThemeStyleEnhanced } from "src/app/models/theme/theme-style.model";
import { ThemeEnhanced } from "src/app/models/theme/theme.model";

@Injectable({
  providedIn: "root",
})
export class ThemeStylesAndSettingsService {
  // Services
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private collectionsService = inject(CollectionsService);

  // To contain the same data as the Theme Settings form, allowing it to be accessible by other components
  public themeSettings = new BehaviorSubject<ThemeEnhanced | null>(null);

  // A string that contains the combined custom css from the firestore theme_styles collection / docments
  public combined_themeCSS = "";

  // The ID of the style tag
  styleTagId = "custom-theme-styles";

  // Custom theme strings segregated by type
  public styles_coverCSS = "";
  public styles_siteCSS = "";
  public styles_categoryCSS = "";
  public styles_chartCSS = "";
  public styles_itemCSS = "";
  public styles_photoCSS = "";
  public styles_actionCSS = "";
  public styles_supplementaryCSS = "";
  public styles_signatureCSS = "";

  // A style tags to add to the DOM
  private styleTags: { [id: string]: HTMLStyleElement } = {};

  // Demo Date
  public demoDate!: Date;

  constructor() {
    this.demoDate = new Date();
  }

  public getThemeStyles$(): Observable<ThemeStyleEnhanced[]> {
    const path = this.collectionsService.themesStyleCol();
    const queryConstraints = [where("availability", "==", true)];
    return this.firestoreUtilsService.getCollectionData<ThemeStyleEnhanced>(path, queryConstraints);
  }

  /**
   * Get Or Create Style Tag
   * Checks if the style tag exists in the head of the document
   * If it does not exist, it creates it and appends it to the head
   * If it does exist, it returns the existing style tag
   */
  private getOrCreateStyleTag(id: string): HTMLStyleElement {
    let styleTag = this.styleTags[id];
    if (!styleTag) {
      styleTag = document.createElement("style");
      styleTag.id = id;
      this.styleTags[id] = styleTag;
      document.head.appendChild(styleTag);
    }
    return styleTag;
  }

  private combineStyles(): void {
    this.combined_themeCSS = "";
    this.combined_themeCSS = this.styles_coverCSS + this.styles_siteCSS + this.styles_chartCSS + this.styles_categoryCSS + this.styles_itemCSS + this.styles_photoCSS + this.styles_actionCSS + this.styles_supplementaryCSS + this.styles_signatureCSS;
  }

  applyCoverStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_coverCSS");
    styleTag.textContent = css;
    this.styles_coverCSS = css;
    this.combineStyles();
  }

  applySiteStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_siteCSS");
    styleTag.textContent = css;
    this.styles_siteCSS = css;
    this.combineStyles();
  }

  applyCategoryStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_categoryCSS");
    styleTag.textContent = css;
    this.styles_categoryCSS = css;
    this.combineStyles();
  }

  applyChartStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_chartCSS");
    styleTag.textContent = css;
    this.styles_chartCSS = css;
    this.combineStyles();
  }

  applyItemStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_itemCSS");
    styleTag.textContent = css;
    this.styles_itemCSS = css;
    this.combineStyles();
  }

  applyPhotoStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_photoCSS");
    styleTag.textContent = css;
    this.styles_photoCSS = css;
    this.combineStyles();
  }

  applyActionStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_actionCSS");
    styleTag.textContent = css;
    this.styles_actionCSS = css;
    this.combineStyles();
  }

  applySupplementaryStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_supplementaryCSS");
    styleTag.textContent = css;
    this.styles_supplementaryCSS = css;
    this.combineStyles();
  }

  applySignatureStyles(css: string): void {
    const styleTag = this.getOrCreateStyleTag("styles_signatureCSS");
    styleTag.textContent = css;
    this.styles_signatureCSS = css;
    this.combineStyles();
  }

  /**
   * Clear Styles
   * Removes the style tag from the head of the document
   * Resets the combined_themeCSS string
   * Resets the styleTag reference
   */
  clearStyles(id: string): void {
    const styleTag = document.getElementById(id);
    if (styleTag && styleTag.parentNode === document.head) {
      document.head.removeChild(styleTag);
      delete this.styleTags[id];
      this.combined_themeCSS = "";
    }
  }
}
