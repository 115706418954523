import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from "@angular/core";
import { EmptyListService } from "src/app/core/services/empty-list.service";
export type EmptyListType = {
  title: string;
  command: string;
  question?: string;
  answer?: string;
};
@Component({
  selector: "empty-list",
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: "./empty-list.component.html",
  styleUrl: "./empty-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyListComponent implements OnInit {
  @Input({ required: true }) list!: "folder" | "inspection" | "signature" | "category" | "item" | "theme" | "assignee" | "client" | "site" | "action" | "template" | "invite" | "role";

  // Service
  private emptyListService = inject(EmptyListService);

  // Properties
  public emptyObject!: EmptyListType;

  ngOnInit(): void {
    switch (this.list) {
      case "folder":
        this.emptyObject = this.emptyListService.emptyFolders();
        break;
      case "inspection":
        this.emptyObject = this.emptyListService.emptyInspections();
        break;
      case "signature":
        this.emptyObject = this.emptyListService.emptySignatures();
        break;
      case "category":
        this.emptyObject = this.emptyListService.emptyCategories();
        break;
      case "item":
        this.emptyObject = this.emptyListService.emptyItems();
        break;
      case "theme":
        this.emptyObject = this.emptyListService.emptyThemes();
        break;
      case "assignee":
        this.emptyObject = this.emptyListService.emptyAssignees();
        break;
      case "client":
        this.emptyObject = this.emptyListService.emptyClients();
        break;
      case "site":
        this.emptyObject = this.emptyListService.emptySites();
        break;
      case "action":
        this.emptyObject = this.emptyListService.emptyActions();
        break;
      case "template":
        this.emptyObject = this.emptyListService.emptyTemplates();
        break;
      case "invite":
        this.emptyObject = this.emptyListService.emptyInvites();
        break;
      case "role":
        this.emptyObject = this.emptyListService.emptyCustomRoles();
        break;
      default:
        break;
    }
  }
}
