@if (combinedData$ | async; as combinedData) {
  <manage-template-items-list-header
    [workspaceId]="workspaceId"
    [templateId]="templateId"
    [categoryId]="categoryId"
    [items]="combinedData.items"
    [itemsBulkEditArray]="itemsBulkEditArray$ | async"
    [currentCategory]="currentCategory"
    [editMode]="editMode"
    (searchTermChange)="searchTermChange($event)"
    (toggleNewItemOutput)="canUserAddItem($event)"
    (toggleEditModeOutput)="toggleEditMode()"
    (bulkDeleteItemsOutput)="setDeleteBatchItemsProperties()" />

  @if (!editMode) {
    <manage-template-items-list-categories-header [categories]="combinedData.categories" [categoryId]="categoryId" (categorySelectOutput)="routeToSelectedCategory($event)" />
  }

  @if (combinedData.items.length > 0) {
    <manage-template-items-list
      [items]="combinedData.items"
      [searchTerm]="currentSearchTerm"
      [itemsBulkEditArray]="itemsBulkEditArray$ | async"
      [editMode]="editMode"
      [currentCategory]="currentCategory"
      (reorderItemsOutput)="reorderItems($event)"
      (createBatchEditOutput)="createBatchEdit($event)"
      (deleteSingleItemOutput)="setDeleteSingleItemProperties($event)"
      (toggleDuplicateOverlayOutput)="toggleAndSetDuplicateItemOverlay($event)"
      (routeToItemEditorOutput)="routeToItemEditor($event)" />
  } @else {
    <empty-list [list]="'item'" />
  }
}

<!-- New -->
@if (overlay_newItem) {
  <manage-template-item-new-overlay
    [type]="newItemType"
    [itemForm]="itemForm"
    [infoViewItemEditorTest]="infoViewItemEditorTest"
    [infoViewItemEditorQuestion]="infoViewItemEditorQuestion"
    (toggleNewItemOutput)="toggleNewItemOverlay()"
    (saveItemAndRerouteOutput)="saveItem()"
    (saveItemAndAddAnotherOutput)="saveItemAndAddAnother()"
    (saveItemAndAddDifferentModeOutput)="saveItemAndAddDifferentMode()" />
}

<!-- Delete -->
@if (overlay_deleteItem) {
  <delete-overlay [deleteOverlayTitle]="deleteTitle" [deleteOverlayDescription]="deleteDescription" (cancelButtonClicked)="toggleDeleteItemOverlay()" (deleteButtonClicked)="deleteSingleOrMultipleItems()" />
}

<!-- Duplicate -->
@if (duplicationService.overlay_duplicate) {
  <duplicate-overlay
    [duplicationObject]="duplicationObject"
    [originalDocumentTitle]="itemToBeDuplicated.title + ' ' + '(Copy)'"
    [duplicationTitle]="duplicationTitle"
    [duplicationDescription]="duplicationDescription"
    [inProgressText]="inProgressText"
    [successText]="successText"
    [failedText]="failedText"
    [navigateToText]="navigateToText"
    (duplicateEmitter)="duplicateItem($event)"
    (navigateToNewDocEmitter)="navigateToNewitem()" />
}
