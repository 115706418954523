import { NgClass } from "@angular/common";
import { Component, inject } from "@angular/core";
import { User } from "cip";
import { AuthService } from "src/app/core/services/auth.service";
import { ReportsService } from "../../services/reports.service";
import { ReportContentCategoriesComponent } from "../report-content-categories/report-content-categories.component";
import { ReportContentChartsComponent } from "../report-content-charts/report-content-charts.component";
import { ReportContentCoverComponent } from "../report-content-cover/report-content-cover.component";
import { ReportContentCsvComponent } from "../report-content-csv/report-content-csv.component";
import { ReportContentIntroductionComponent } from "../report-content-introduction/report-content-introduction.component";
import { ReportContentSignatureComponent } from "../report-content-signature/report-content-signature.component";
import { ReportContentSiteComponent } from "../report-content-site/report-content-site.component";
import { ReportContentSummaryComponent } from "../report-content-summary/report-content-summary.component";

@Component({
  selector: "report-content-wrapper",
  standalone: true,
  imports: [
    ReportContentCoverComponent,
    ReportContentSiteComponent,
    ReportContentChartsComponent,
    ReportContentIntroductionComponent,
    ReportContentCategoriesComponent,
    ReportContentSummaryComponent,
    ReportContentSiteComponent,
    ReportContentCsvComponent,
    ReportContentSignatureComponent,
    NgClass,
  ],
  templateUrl: "./report-content-wrapper.component.html",
  styleUrl: "./report-content-wrapper.component.scss",
})
export class ReportContentWrapperComponent {
  // Services
  public reportsService = inject(ReportsService);
  private authService = inject(AuthService);

  // Properties
  public user: User;

  constructor() {
    this.user = this.authService.currentUser;
  }
}
