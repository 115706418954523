import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { filter, Observable, tap } from "rxjs";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { MembersListService } from "src/app/sections/workspaces/current-workspace/manage-members/services/members-list.service";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "action-edit-assignee-member-selector",
  standalone: true,
  imports: [AsyncPipe, JsonPipe, NgClass, SearchBarComponent, SearchEmptyComponent, EmptyListComponent, LoadingSpinnerComponent],
  templateUrl: "./action-edit-assignee-member-selector.component.html",
  styleUrl: "./action-edit-assignee-member-selector.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEditAssigneeMemberSelectorComponent implements OnInit {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) actionForm!: FormGroup;
  @Output() toggleAssigneeFormOverlayOutput = new EventEmitter<void>();
  @Output() memberSelectionOutput = new EventEmitter<MemberEnhanced>();

  // Services
  private membersListService = inject(MembersListService);

  // Properties
  public currentlySelectedMember!: MemberEnhanced | null;
  public allOtherMembers: Array<MemberEnhanced> = [];
  public members$!: Observable<MemberEnhanced[]>;
  public filteredMembers: Array<MemberEnhanced> = [];
  public allMembersHidden: boolean = false;
  public searchTerm: string = "";

  ngOnInit(): void {
    this.getMembersList();
  }

  /**
   * Get members List
   */
  private async getMembersList(): Promise<void> {
    this.members$ = this.membersListService.getMembersList$(this.workspaceId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.filteredMembers = [];
        this.currentlySelectedMember = null;
        data.forEach((element) => {
          if (element.id === this.actionForm.value["assignee_id"]) {
            this.currentlySelectedMember = element;
          } else {
            this.filteredMembers.push(element);
            this.allOtherMembers.push(element);
          }
        });
      })
    );
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredMembers = this.allOtherMembers.map((member) => ({
      ...member,
      hidden: !member.name_first.toLowerCase().includes(lowerSearchTerm),
    }));

    this.allMembersHidden = this.filteredMembers.every((member) => member.hidden);
  }

  /**
   * Unselect Assignee
   */
  public unselectMember(): void {
    this.actionForm.patchValue({
      assignee_id: null,
      assignee_name: null,
      assignee_type: null,
      assignee_email: null,
      assignee_company: null,
    });
    this.currentlySelectedMember = null;
    this.actionForm.markAsDirty();
    this.getMembersList();
  }

  /**
   * Select Member
   * @param assignee
   */
  public selectMember(member: MemberEnhanced): void {
    this.memberSelectionOutput.emit(member);
  }
}
