import { AsyncPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { AssigneeEnhanced } from "src/app/models/assignee/assignee.model";
import { AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { AssigneeDetailService } from "../../services/assignee-detail.service";
import { ManageAssigneeEditDetailsComponent } from "../manage-assignee-edit-details/manage-assignee-edit-details.component";
import { ManageAssigneeEditHeaderComponent } from "../manage-assignee-edit-header/manage-assignee-edit-header.component";

@Component({
  selector: "manage-assignee-edit-wrapper",
  standalone: true,
  imports: [ManageAssigneeEditHeaderComponent, ManageAssigneeEditDetailsComponent, DeleteOverlayComponent, LoadingSpinnerComponent, FormStateComponent, AsyncPipe, NgClass, InfoViewComponent, DeleteOverlayComponent],
  templateUrl: "./manage-assignee-edit-wrapper.component.html",
  styleUrl: "./manage-assignee-edit-wrapper.component.scss",
})
export class ManageAssigneeEditWrapperComponent implements OnInit {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set assigneeId(value: string) {
    this._assigneeId = value;
  }
  get assigneeId(): string {
    return this._assigneeId;
  }

  // Services
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private assigneeDetailService = inject(AssigneeDetailService);
  private router = inject(Router);
  private limitationManagerService = inject(LimitationManagerService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private stringsService = inject(StringsService);

  // Properties
  private _workspaceId!: string;
  private _assigneeId!: string;
  public assignee$!: Observable<AssigneeEnhanced>;
  private user: User;
  public assigneeForm: FormGroup;
  public overlay_deleteAssignee: boolean = false;
  public infoViewAssigneeEditorReport: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.AssigneeEditorReport);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";

  constructor() {
    this.user = this.authService.currentUser;
    this.assigneeForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      email: new FormControl("", [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), this.formFormattingService.noWhitespaceValidator()]),
      company: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getAssigneeDoc();
  }

  /**
   * Get Assignee Doc
   */
  private async getAssigneeDoc(): Promise<void> {
    try {
      this.assignee$ = this.assigneeDetailService.getAssigneeDoc$(this.workspaceId, this.assigneeId).pipe(
        filter((data) => !!data),
        tap((data) => {
          if (this.assigneeForm.pristine && this.assigneeForm.untouched) {
            this.assigneeForm.patchValue(data);
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Assignee
   */
  public async saveAssignee(): Promise<void> {
    try {
      await this.assigneeDetailService.saveAssigneeDoc(this.workspaceId, this.assigneeId, this.assigneeForm.value, this.user);
      this.assigneeForm.markAsPristine();
      this.router.navigate(["/workspace", this.workspaceId, "manage", "assignees"]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Assignee
   */
  public async deleteAssignee(): Promise<void> {
    try {
      await this.assigneeDetailService.deleteAssigneeDoc(this.workspaceId, this.assigneeId, this.user);
      this.router.navigate(["/workspace", this.workspaceId, "manage", "assignees"]);
      this.toggleDeleteAssigneeOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Delete Assignee
   */
  public async canUserDeleteAssignee(): Promise<void> {
    const featureAction: keyof RolePermissions = "assignee_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const deleteAssignee = this.stringsService.alertFilter(AlertType.RemoveAssignee);
      if (deleteAssignee) {
        this.deleteOverlayTitle = deleteAssignee.title;
        this.deleteOverlayDescription = deleteAssignee.description;
      } else {
        console.error("Unknown string type:", AlertType.RemoveAssignee);
      }
      this.toggleDeleteAssigneeOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Invite As Member
   */
  async inviteAsMember(): Promise<void> {
    const featureAction: keyof RolePermissions = "invite_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const queryParams = {
        name: this.assigneeForm.value.name,
        email: this.assigneeForm.value.email,
      };
      const route = ["/workspace", this.workspaceId, "manage", "members", "invites", "new"];
      this.router.navigate(route, { queryParams });
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Delete Assignee Overlay
   */
  public toggleDeleteAssigneeOverlay(): void {
    this.overlay_deleteAssignee = !this.overlay_deleteAssignee;
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.assigneeForm.dirty ? false : true;
  }
}
