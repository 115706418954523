import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@Component({
  selector: 'inspection-edit-details',
  standalone: true,
  imports: [ReactiveFormsModule, OwlDateTimeModule, OwlNativeDateTimeModule],
  templateUrl: './inspection-edit-details.component.html',
  styleUrl: './inspection-edit-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionEditDetailsComponent {
  @Input({ required: true }) inspectionForm!: FormGroup;

  /**
   * Clear Inspection Date
   */
  public clearInspectionDate(): void {
    this.inspectionForm.patchValue({ date: null });
  }
}
