<div class="content-card-wrapper">
  <div class="content-card-header flex cursor-pointer flex-row justify-between hover:bg-gray-50" (click)="displayAssignedMembers()">
    <h3>Assigned Members</h3>

    @if (assignedMembersAccordion) {
      <ng-container [ngTemplateOutlet]="accordionUp"></ng-container>
    } @else {
      <ng-container [ngTemplateOutlet]="accordionDown"></ng-container>
    }
  </div>
  @if (assignedMembersAccordion) {
    <div class="px-6">
      <div class="content-grid gap-y-6">
        <ul class="list-ul" role="list">
          @for (member of assignedMembers; track $index) {
            <li class="list-row">
              <div class="w-full">
                <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                  <div class="min-w-0 flex-auto">
                    <p class="list-title">{{ member.name_first }} {{ member.name_last }}</p>
                    <p class="list-meta">{{ member.email }}</p>
                  </div>
                  <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                    <div class="sm:flex sm:flex-col sm:items-end">
                      <p class="list-meta">{{ member.job_title }}</p>
                      <p class="list-meta-second">{{ member.role_title }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          }
        </ul>
      </div>
    </div>
  }
</div>

<!-- Accordion Icons -->
<ng-template #accordionDown>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
</ng-template>

<ng-template #accordionUp>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
  </svg>
</ng-template>
