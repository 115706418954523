<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 class="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Add your Name</h2>
          </div>

          <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <info-view [type]="infoView.type" [message]="infoView.message" />
          </div>

          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" [formGroup]="signUpForm">
              <div class="space-y-6 bg-white py-5">
                <div class="form-input-wrapper">
                  <label for="name_first" class="form-input-label"
                    >First Name*
                    @if (signUpForm.controls['name_first'].errors && clicked) {
                      <span class="required-field"> - Please provide a First Name</span>
                    }
                  </label>
                  <input id="name_first" name="name_first" type="text" required class="form-input" formControlName="name_first" />
                </div>

                <div class="form-input-wrapper">
                  <label for="name_last" class="form-input-label"
                    >Last Name*
                    @if (signUpForm.controls['name_first'].errors && clicked) {
                      <span class="required-field"> - Please provide a Last Name</span>
                    }
                  </label>
                  <input id="name_last" name="name_last" type="text" required class="form-input" formControlName="name_last" />
                </div>

                <div class="flex items-center">
                  @if (signUpForm.controls['terms'].errors && clicked) {
                    <div class="required-field">*</div>
                  }
                  <input id="terms" name="terms" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="terms" />
                  <label for="terms" class="ml-3 block text-sm leading-6 text-gray-900">I agree to Terms of Use </label>
                </div>

                <div class="flex items-center">
                  @if (signUpForm.controls['privacy'].errors && clicked) {
                    <div class="required-field">*</div>
                  }
                  <input id="privacy" name="privacy" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="privacy" />
                  <label for="privacy" class="ml-3 block text-sm leading-6 text-gray-900">I agree to Privacy Policy</label>
                </div>
              </div>

              <div>
                <button
                  (click)="checkFormValidationsOrCreateUserDoc()"
                  type="button"
                  class="flex w-full justify-center rounded-md bg-brand_Primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand_Primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand_Primary"
                >
                  Let's get started!
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
