<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">New Assignee</h3>
        </div>

        <div class="flex h-full w-full flex-col overflow-hidden">
          <div class="w-full flex-1 overflow-y-scroll">
            <div class="grid grid-cols-1 gap-y-8 px-6 py-8">
              <manage-assignee-edit-details [assigneeForm]="assigneeForm" />
            </div>
          </div>

          <!-- Form State Bottom -->
          <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
            <button
              type="button"
              class="btn ml-3"
              aria-label="Save Assignee"
              [disabled]="assigneeForm.pristine || assigneeForm.controls['name'].errors"
              [ngClass]="assigneeForm.pristine || assigneeForm.controls['name'].errors ? 'btn-disabled cursor-not-allowed' : 'btn-primary'"
              (click)="saveAssignee()">
              Save
            </button>

            <button type="button" class="btn btn-cancel" aria-label="Cancel New Assignee" (click)="toggleNewAssigneeOverlay()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
