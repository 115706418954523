import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { combineLatest, filter, Observable, switchMap, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { RoleCurrent, RoleDefault, RoleEnhanced } from "src/app/models/role/role.model";
import { AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { PermissionsGridComponent } from "src/app/shared/permissions/permissions-grid/permissions-grid.component";
import { RoleFieldComponent } from "src/app/shared/roles/role-field/role-field.component";
import { RoleSelectorWrapperComponent } from "src/app/shared/roles/role-selector-wrapper/role-selector-wrapper.component";
import { RoleDetailService } from "../../../../manage-roles/services/role-detail.service";
import { RolesListService } from "../../../../manage-roles/services/roles-list.service";
import { InviteDetailService } from "../../../services/invite-detail.service";
import { MemberDetailService } from "../../../services/member-detail.service";
import { ManageMemberEditDetailsComponent } from "../manage-member-edit-details/manage-member-edit-details.component";
import { ManageMemberEditHeaderComponent } from "../manage-member-edit-header/manage-member-edit-header.component";

@Component({
  selector: "manage-member-edit-wrapper",
  standalone: true,
  imports: [
    FormStateComponent,
    RoleSelectorWrapperComponent,
    RoleFieldComponent,
    AsyncPipe,
    JsonPipe,
    PermissionsGridComponent,
    DeleteOverlayComponent,
    NgClass,
    ManageMemberEditDetailsComponent,
    ManageMemberEditHeaderComponent,
    LoadingSpinnerComponent,
  ],
  templateUrl: "./manage-member-edit-wrapper.component.html",
  styleUrl: "./manage-member-edit-wrapper.component.scss",
})
export class ManageMemberEditWrapperComponent implements OnInit {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set memberId(value: string) {
    this._memberId = value;
  }
  get memberId(): string {
    return this._memberId;
  }

  // Services
  private authService = inject(AuthService);
  private formFormattingService = inject(FormFormattingService);
  private fb = inject(FormBuilder);
  private infoViewStringsService = inject(InfoViewStringsService);
  private inviteDetailService = inject(InviteDetailService);
  private activatedRoute = inject(ActivatedRoute);
  private rolesListService = inject(RolesListService);
  private roleDetailService = inject(RoleDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private router = inject(Router);
  private stringsService = inject(StringsService);
  private memberDetailService = inject(MemberDetailService);

  // Properties
  private _workspaceId!: string;
  private _memberId!: string;
  public memberForm: FormGroup;
  public roleForm: FormGroup;
  public member$!: Observable<MemberEnhanced>;
  public roles$!: Observable<RoleEnhanced[]>;
  public combinedData$!: Observable<{ member: MemberEnhanced; roles: RoleEnhanced[] }>;
  public user: User;
  public customRoles: RoleEnhanced[] = [];
  public defaultRoles = this.rolesListService.defaultRoles;
  public currentRole: RoleCurrent | null = null;
  public infoViewPermissionsEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.PermissionsEditor);
  public infoViewMemberEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.MemberEditor);
  public memberDoc = this.limitationManagerService.memberDoc.value;
  public overlay_roleSelector: boolean = false;
  public overlay_roleForm: boolean = false;
  public overlay_removeMember = signal<boolean>(false);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  constructor() {
    // User
    this.user = this.authService.currentUser;

    // Form
    this.memberForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name_first: null,
      name_last: null,
      email: null,
      job_title: null,
      company: null,
      phone: null,
      role_title: null,
      role_id: null,
      created_by: null,
      created_by_id: null,
      status: null,
      permissions: this.fb.group({
        action_create_update: null,
        action_delete: null,
        assignee_create_update: null,
        assignee_delete: null,
        category_create_update: null,
        category_delete: null,
        client_create_update: null,
        client_delete: null,
        folder_create_update: null,
        folder_delete: null,
        inspection_create_update: null,
        inspection_delete: null,
        invite_create_update: null,
        invite_delete: null,
        item_create_update: null,
        item_delete: null,
        member_update: null,
        member_delete: null,
        photo_create_update: null,
        photo_delete: null,
        role_create_update: null,
        role_delete: null,
        signature_create_update: null,
        signature_delete: null,
        site_create_update: null,
        site_delete: null,
        template_create_update: null,
        template_delete: null,
        theme_create_update: null,
        theme_delete: null,
        workspace_update: null,
      }),
    });

    this.roleForm = this.fb.group({
      id: [{ value: null, disabled: null }],
      title: ["", Validators.required],
      description: ["", Validators.required],
      type: null,
      created_by: null,
      created_by_id: null,
      permissions: this.fb.group({
        action_create_update: false,
        action_delete: false,
        assignee_create_update: false,
        assignee_delete: false,
        category_create_update: false,
        category_delete: false,
        client_create_update: false,
        client_delete: false,
        folder_create_update: false,
        folder_delete: false,
        inspection_create_update: false,
        inspection_delete: false,
        invite_create_update: false,
        invite_delete: false,
        item_create_update: false,
        item_delete: false,
        member_update: false,
        member_delete: false,
        photo_create_update: false,
        photo_delete: false,
        role_create_update: false,
        role_delete: false,
        signature_create_update: false,
        signature_delete: false,
        site_create_update: false,
        site_delete: false,
        template_create_update: false,
        template_delete: false,
        theme_create_update: false,
        theme_delete: false,
        workspace_update: false,
      }),
    });
  }

  ngOnInit(): void {
    this.initializeCombinedData();
  }

  private initializeCombinedData(): void {
    // First get roles
    this.roles$ = this.rolesListService.getRolesList$(this.workspaceId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.customRoles = [];
        data.forEach((element) => {
          this.customRoles.push(element);
        });
      })
    );

    this.member$ = this.roles$.pipe(
      switchMap(() => this.memberDetailService.getMemberDoc$(this.workspaceId, this.memberId)),
      filter((data) => !!data),
      tap((data) => {
        if (this.memberForm.pristine && this.memberForm.untouched) {
          this.memberForm.patchValue(data);
          this.currentRole = this.getCurrentRole(data.role_id);
        }
      })
    );

    // Combine both observables
    this.combinedData$ = combineLatest({
      member: this.member$,
      roles: this.roles$,
    });
  }

  /**
   * Get Current Role
   * @param roleId
   */
  getCurrentRole(roleId: string): RoleCurrent | null {
    const allRoles = [...this.rolesListService.defaultRoles, ...this.customRoles];
    const role = allRoles.find((obj) => obj.id === roleId);
    return role ?? null;
  }

  /**
   * Save Member
   */
  public async saveMember(): Promise<void> {
    const route = ["/workspace", this.workspaceId, "manage", "members"];
    try {
      await this.memberDetailService.saveMemberDoc(this.workspaceId, this.memberId, this.memberForm.value, this.user);
      this.memberForm.markAsPristine();
      this.router.navigate(route);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Role
   */
  public async saveRole(): Promise<void> {
    try {
      const role = await this.roleDetailService.saveRoleDoc(this.workspaceId, this.roleForm.getRawValue(), this.user);
      this.memberForm.patchValue({
        role_title: role.title,
        role_id: role.id,
        permissions: role.permissions,
      });
      this.memberForm.markAsDirty();
      this.currentRole = role as RoleCurrent;
      this.toggleRoleFormOverlay();
      this.toggleRoleSelectorOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Select Default Role
   * @param role
   */
  public selectRole(role: RoleDefault): void {
    this.memberForm.patchValue({
      role_id: role.id,
      role_title: role.title,
      permissions: role.permissions,
    });
    this.memberForm.markAsDirty();
    this.toggleRoleSelectorOverlay();
    this.currentRole = role;
  }

  /**
   * Can User Remove Member
   */
  public async canUserRemoveMember(): Promise<void> {
    const featureAction: keyof RolePermissions = "member_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleRemoveMemberOverlay(true);
      const removeMember = this.stringsService.alertFilter(AlertType.RemoveMember);
      if (removeMember) {
        this.deleteOverlayTitle = removeMember.title;
        this.deleteOverlayDescription = removeMember.description;
      } else {
        console.error("Unknown string type:", AlertType.RevokeInvite);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Remove Member
   */
  public async removeMember(): Promise<void> {
    const route = ["/workspace", this.workspaceId, "manage", "members"];
    try {
      await this.memberDetailService.removeMemberOrMemberLeft(this.workspaceId, this.memberId, this.user);
      this.memberForm.markAsPristine();
      this.router.navigate(route);
      this.toggleRemoveMemberOverlay(false);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Remove Member Overlay
   */
  public toggleRemoveMemberOverlay(value: boolean): void {
    this.overlay_removeMember.set(value);
  }

  public async canUserEditMember(): Promise<void> {
    const featureAction: keyof RolePermissions = "member_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleRoleSelectorOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Role Selector Overlay
   */
  public toggleRoleSelectorOverlay(): void {
    this.overlay_roleSelector = !this.overlay_roleSelector;
  }

  /**
   * Toggle Role Form Overlay
   */
  public toggleRoleFormOverlay(): void {
    this.overlay_roleForm = !this.overlay_roleForm;
    if (!this.overlay_roleForm) {
      this.roleForm.reset();
    }
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.memberForm.dirty ? false : true;
  }
}
