import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-priority",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./report-settings-priority.component.html",
  styleUrl: "./report-settings-priority.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsPriorityComponent {
  // Services
  public reportsService = inject(ReportsService);

  // Properties
  public toggleContent = false;

  /**
   * Toggle Content Options
   **/
  public toggleContentOptions() {
    this.toggleContent = !this.toggleContent;
  }

  /**
   * On Prirority Checkbox Chnage
   * Handles the change in state of an priority checkbox in the UI.
   * - If the checkbox is checked (isChecked === true), the priority is added to the list of selected priorities.
   * - If the checkbox is unchecked (isChecked === false), the priority is removed from the list of selected priorities.
   *
   * @param priority - The unique identifier of the priority.
   * @param isChecked - A boolean indicating whether the checkbox is checked (true) or unchecked (false).
   */
  public onPriorityCheckboxChange(priority: number, isChecked: boolean) {
    if (isChecked) {
      // Check if the number is not already in the array
      if (!this.reportsService.reportSettings.priorities.includes(priority)) {
        // Push the number into the array
        this.reportsService.reportSettings.priorities.push(priority);
      }
    } else {
      // Find the index of the number and remove it
      const index = this.reportsService.reportSettings.priorities.indexOf(priority);
      if (index !== -1) {
        this.reportsService.reportSettings.priorities.splice(index, 1);
      }
    }
    this.reportsService.updateReportSettings(this.reportsService.reportSettings);
  }

  /**
   * On Priority All Checkbox Change
   * Handles the change in state of the "All" priority checkbox in the UI.
   * - If the checkbox is checked (isChecked === true), all priority checkboxes are checked and all priorities are added to the list of selected priorities.
   * - If the checkbox is unchecked (isChecked === false), all priority checkboxes are unchecked and all priorities are removed from the list of selected priorities.
   *
   * @param isChecked - A boolean indicating whether the checkbox is checked (true) or unchecked (false).
   */
  public toggleAllPriorityCheckboxes() {
    const priorityLevels = ["none", "critical", "high", "medium", "low", "trivial"];

    if (this.reportsService.reportSettings.priority_all) {
      this.reportsService.reportSettings.priorities = [-1, 0, 25, 50, 75, 100];
    } else {
      // Remove all elements from the array
      this.reportsService.reportSettings.priorities.splice(0, this.reportsService.reportSettings.priorities.length);
    }

    priorityLevels.forEach((level) => {
      const priority = `priority_${level}`;
      this.reportsService.reportSettings[priority] = this.reportsService.reportSettings.priority_all;
    });
  }
}
