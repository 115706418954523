@if (appUpdateService.updateAvailable) {
  <app-update />
}

<w3w-map [w3w_api_key]="w3w_api_key" [map_api_key]="map_api_key" />

<router-outlet />

@if (connectedDevicesService.overlay_connectedDevices) {
  <connected-devices-overlay />
}
