<div class="grid grid-cols-1 gap-y-4">
  <!-- List -->
  <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
    <!-- List Component -->
    <ul class="list-ul" id="templatesList">
      @for (template of filteredTemplates; track $index) {
        <li [class.hidden]="template.hidden">
          <div class="list-row cursor-pointer">
            <div (click)="routeToTemplateOverview(template.id)" class="list-a group">
              <div class="ml-2.5 flex flex-row sm:ml-0">
                <div class="flex h-12 w-12 items-center justify-center rounded-md bg-gray-100 text-text_Subtitle">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                  </svg>
                </div>
              </div>

              <!-- Left and Right Content -->
              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                <!-- Left Content -->
                <div class="min-w-0 flex-auto">
                  <p class="list-title">{{ template.title }}</p>
                  <p class="list-meta search-template-ref">{{ template.ref }}</p>
                </div>
                <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                  <div class="sm:flex sm:flex-col sm:items-end">
                    @if (template.date_created) {
                      <p class="list-meta">{{ template.date_created.toDate() | date: "dd MMM yyyy" }}</p>
                    }
                    <p class="list-meta">{{ template.created_by }}</p>
                  </div>
                </div>
              </div>

              <!-- Right Icon -->
              <div class="flex">
                <button type="button" class="list-icon" aria-expanded="false" aria-haspopup="true">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </li>
      }
    </ul>
    @if (allTemplatesHidden) {
      <search-empty [searchTerm]="_searchTerm" />
    }
  </div>
</div>
