<div class="w-full rounded-md bg-gray-50 px-6 py-4">
  <div class="flex justify-between">
    <h2 class="pb-2 text-xl font-semibold text-text_Title">Pending Uploads</h2>
    @if (uploadSourceComponent === 'item') {
      <div [ngClass]="photosToPreview.length === uploadLimit ? 'text-sapBrightRed' : 'text-text_Subtitle'">{{ uploadLimit - photosToPreview.length }} of {{ uploadLimit }} slots remaining</div>
    }
  </div>

  <ul role="list" class="grid grid-cols-6 gap-x-8 gap-y-8">
    @for (photo of photosToPreview; track photo.id) {
      <li>
        @if (photo && photo.id !== '') {
          <div class="relative aspect-[1/1] w-full rounded-lg bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + photo.photo + ')' }">
            <div class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-red-600 text-white hover:bg-red-500" (click)="removePhotoFromArray(photo.id)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6"></path>
              </svg>
            </div>
          </div>
        }
      </li>
    }
  </ul>
</div>
