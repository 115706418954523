import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InspectionEnhanced } from 'src/app/models/inspection/inspection.model';
import { BreadcrumbsComponent } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'inspection-overview-header',
  standalone: true,
  imports: [BreadcrumbsComponent],
  templateUrl: './inspection-overview-header.component.html',
  styleUrl: './inspection-overview-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionOverviewHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) inspection!: InspectionEnhanced;
}
