import { Injectable } from "@angular/core";
import { AlertFilter, AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";

@Injectable({
  providedIn: "root",
})
export class StringsService {
  getInfoView(InviteEditorMember: InfoViewType): InfoViewFilter {
    throw new Error("Method not implemented.");
  }
  private acceptInvite = {
    title: "Accept Invite?",
    description: "Are you sure you to join this Workspace?",
  };
  private changeWorkspace = {
    title: "Change Workspace?",
    description: "Are you sure you want to leave the current Workspace?",
  };
  private completeIncompleteActions = {
    title: "Complete / Incomplete Actions",
    description: "Choose whether to batch assign a complete or incomplete state to the selected Actions.",
  };
  private confirmTemplate = {
    title: "Confirm Selection?",
    description: "Are you sure you want to use this Template? This will remove all existing data from the Inspection.",
  };
  private deleteAccount = {
    title: "Delete Account?",
    description: "Are you sure you want to delete your account? Type DELETE below to confirm you are happy to permanently lose all access to your data. This cannot be undone.",
  };
  private deleteAction = {
    title: "Delete Action?",
    description: "Are you sure you want to delete this Action?",
  };
  private deleteActions = {
    title: "Delete Actions?",
    description: "Are you sure you want to delete these Actions?",
  };

  private removeAssignee = {
    title: "Remove Assignee?",
    description: "Are you sure you want to remove this Assignee?",
  };
  private removeAssignees = {
    title: "Remove Assignees?",
    description: "Are you sure you want to remove these Assignees?",
  };
  private deleteCategories = {
    title: "Delete Categories?",
    description: "Are you sure you want to delete these Categories?",
  };
  private deleteCategory = {
    title: "Delete Category?",
    description: "Are you sure you want to delete this Category?",
  };
  private deleteClient = {
    title: "Delete Client?",
    description: "Are you sure you want to delete this Client?",
  };
  private deleteClients = {
    title: "Delete Clients?",
    description: "Are you sure you want to delete these Clients?",
  };
  private deleteClientImage = {
    title: "Delete Client Image?",
    description: "Are you sure you want to delete this Client Image?",
  };
  private deleteFolder = {
    title: "Delete Folder?",
    description: "and all of its Inspections, Categories and Photos will be removed from all of your devices. This cannot be undone. Type DELETE below to confirm.",
  };
  private deleteInspection = {
    title: "Delete Inspection?",
    description: "and all of its Categories, Tests, Questions, Actions and Photos will be removed from all of your devices. This cannot be undone. Type DELETE below to confirm.",
  };
  private deleteItem = {
    title: "Delete Item?",
    description: "Are you sure you want to delete this Item?",
  };
  private deleteItems = {
    title: "Delete Items?",
    description: "Are you sure you want to delete these Items?",
  };
  private deleteLocation = {
    title: "Remove Location?",
    description: "Are you sure you want to remove this Location?",
  };
  private deleteRole = {
    title: "Delete Role?",
    description: "Are you sure you want to delete this Role?",
  };
  private deleteSignature = {
    title: "Delete Signature?",
    description: "Are you sure you want to delete this Signature?",
  };
  private deleteSignatures = {
    title: "Delete Signatures?",
    description: "Are you sure you want to delete these Signatures?",
  };
  private DeleteSignaturePhoto = {
    title: "Delete Signature Photo?",
    description: "Are you sure you want to delete the Signature photo?",
  };
  private deleteSite = {
    title: "Delete Site?",
    description: "Are you sure you want to delete this Site?",
  };
  private deleteSites = {
    title: "Delete Sites?",
    description: "Are you sure you want to delete these Sites?",
  };
  private deleteSitePhoto = {
    title: "Delete Site Photo?",
    description: "Are you sure you want to delete this Site photo?",
  };
  private deleteTemplate = {
    title: "Delete Template?",
    description: "Are you sure you want to delete this Template?",
  };
  private deleteTemplateCategory = {
    title: "Delete Template Category?",
    description: "Are you sure you want to delete this Template Category?",
  };
  private deleteTemplateCategories = {
    title: "Delete Template Categories?",
    description: "Are you sure you want to delete these Template Categories?",
  };
  private deleteTemplateItem = {
    title: "Delete Template Item?",
    description: "Are you sure you want to delete this Template Item?",
  };
  private deleteTemplateItems = {
    title: "Delete Template Items?",
    description: "Are you sure you want to delete these Template Items?",
  };
  private deleteTestImage = {
    title: "Delete Image?",
    description: "Are you sure you want to delete this Image?",
  };
  private deleteTheme = {
    title: "Delete Theme?",
    description: "Are you sure you want to delete this Theme?",
  };
  private deleteThemes = {
    title: "Delete Themes?",
    description: "Are you sure you want to delete these Themes?",
  };
  private deleteWorkspace = {
    title: "Delete Workspace?",
    description: "and all of its Folders, Inspections, Categories and Photos will be removed from all of your devices. This cannot be undone. Type DELETE below to confirm.",
  };
  private deleteWorkspacePhoto = {
    title: "Delete Workspace Image?",
    description: "Are you sure you want to delete this Workspace Image?",
  };
  private enableDisableCategories = {
    title: "Enable / Disable Categories",
    description: "Choose whether to batch assign a complete or incomplete state to the selected Categories.",
  };
  private enableDisableItems = {
    title: "Enable / Disable Items",
    description: "Choose whether to batch assign a complete or incomplete state to the selected Items.",
  };
  private leaveWorkspace = {
    title: "Leave Workspace?",
    description: "Are you sure you want to leave this Workspace?",
  };

  private removeLocation = {
    title: "Remove Location?",
    description: "Are you sure you want to remove this Location?",
  };
  private removeMember = {
    title: "Remove Member?",
    description: "Are you sure you want to remove this Member from the Workspace?",
  };
  private revokeInvite = {
    title: "Revoke Invite?",
    description: "Are you sure you want to revoke this Invite?",
  };
  private signOut = {
    title: "Sign Out?",
    description: "Are you sure you want to sign out?",
  };
  private transferOwnership = {
    title: "Transfer Ownership?",
    description: "Are you sure you want to transfer Ownership?",
  };

  /**
   * Strings for alerts (delets / enabled, disabled / complete, incomplete / etc. )
   * @param type
   * @returns
   */
  alertFilter(type: AlertType): AlertFilter | undefined {
    switch (type) {
      case AlertType.AcceptInvite:
        return this.acceptInvite;
      case AlertType.ChangeWorkspace:
        return this.changeWorkspace;
      case AlertType.CompleteIncompleteActions:
        return this.completeIncompleteActions;
      case AlertType.ConfirmTemplate:
        return this.confirmTemplate;
      case AlertType.DeleteAccount:
        return this.deleteAccount;
      case AlertType.DeleteAction:
        return this.deleteAction;
      case AlertType.DeleteActions:
        return this.deleteActions;
      case AlertType.DeleteCategories:
        return this.deleteCategories;
      case AlertType.DeleteCategory:
        return this.deleteCategory;
      case AlertType.DeleteClient:
        return this.deleteClient;
      case AlertType.DeleteClients:
        return this.deleteClients;
      case AlertType.DeleteClientImage:
        return this.deleteClientImage;
      case AlertType.DeleteFolder:
        return this.deleteFolder;
      case AlertType.DeleteInspection:
        return this.deleteInspection;
      case AlertType.DeleteItem:
        return this.deleteItem;
      case AlertType.DeleteItems:
        return this.deleteItems;
      case AlertType.DeleteLocation:
        return this.deleteLocation;
      case AlertType.DeleteRole:
        return this.deleteRole;
      case AlertType.DeleteSignature:
        return this.deleteSignature;
      case AlertType.DeleteSignatures:
        return this.deleteSignatures;
      case AlertType.DeleteSignaturePhoto:
        return this.DeleteSignaturePhoto;
      case AlertType.DeleteSite:
        return this.deleteSite;
      case AlertType.DeleteSites:
        return this.deleteSites;
      case AlertType.DeleteSitePhoto:
        return this.deleteSitePhoto;
      case AlertType.DeleteTemplate:
        return this.deleteTemplate;
      case AlertType.DeleteTemplateCategory:
        return this.deleteTemplateCategory;
      case AlertType.DeleteTemplateCategories:
        return this.deleteTemplateCategories;
      case AlertType.DeleteTemplateItem:
        return this.deleteTemplateItem;
      case AlertType.DeleteTemplateItems:
        return this.deleteTemplateItems;
      case AlertType.DeleteTestImage:
        return this.deleteTestImage;
      case AlertType.DeleteTheme:
        return this.deleteTheme;
      case AlertType.DeleteThemes:
        return this.deleteThemes;
      case AlertType.DeleteWorkspace:
        return this.deleteWorkspace;
      case AlertType.DeleteWorkspacePhoto:
        return this.deleteWorkspacePhoto;
      case AlertType.EnableDisableCategories:
        return this.enableDisableCategories;
      case AlertType.EnableDisableItems:
        return this.enableDisableItems;
      case AlertType.LeaveWorkspace:
        return this.leaveWorkspace;
      case AlertType.RemoveAssignee:
        return this.removeAssignee;
      case AlertType.RemoveAssignees:
        return this.removeAssignees;
      case AlertType.RemoveLocation:
        return this.removeLocation;
      case AlertType.RemoveMember:
        return this.removeMember;
      case AlertType.RevokeInvite:
        return this.revokeInvite;
      case AlertType.SignOut:
        return this.signOut;
      case AlertType.TransferOwnership:
        return this.transferOwnership;
      default:
        return undefined;
    }
  }
}
