import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { DecimalPipe, NgClass, NgStyle, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryEnhanced } from 'src/app/models/category/category.model';
import { SearchEmptyComponent } from 'src/app/shared/search-empty/search-empty.component';

@Component({
  selector: 'inspection-categories-list',
  standalone: true,
  imports: [SearchEmptyComponent, NgClass, NgStyle, PercentPipe, CdkDrag, CdkDropList, DecimalPipe],
  templateUrl: './inspection-categories-list.component.html',
  styleUrl: './inspection-categories-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionCategoriesListComponent {
  @Input({ required: true })
  set categories(value: CategoryEnhanced[]) {
    this._categories = value;
    this.updateFilteredCategoriesForSearchResults();
  }

  get categories(): CategoryEnhanced[] {
    return this._categories;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredCategoriesForSearchResults();
  }

  @Input({ required: true }) editMode!: boolean;
  @Input({ required: true }) toggleDisabled!: boolean;
  @Input() categoriesBulkEditArray: CategoryEnhanced[] | null = [];
  @Output() reorderCategoriesOutput = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() deleteSingleCategoryOutput = new EventEmitter<string>();
  @Output() createBatchEditOutput = new EventEmitter<CategoryEnhanced>();
  @Output() routeToCategoryOrItemsOutput = new EventEmitter<string>();
  @Output() toggleDuplicateOverlayOutput = new EventEmitter<CategoryEnhanced>();

  // Properites
  private _categories: CategoryEnhanced[] = [];
  public _searchTerm: string = '';
  public filteredCategories: CategoryEnhanced[] = [];
  public allCategoriesHidden: boolean = false;

  /**
   * Update Filtered inspections
   */
  updateFilteredCategoriesForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredCategories = this.categories.map((category) => ({
      ...category,
      hidden: !category.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allCategoriesHidden = this.filteredCategories.every((category) => category.hidden);
  }

  /**
   * Reorder Categories
   * @param event
   */
  public reorderCategories(event: CdkDragDrop<string[]>): void {
    this.reorderCategoriesOutput.emit(event);
  }

  /**
   * Create Batch Edit
   * @param category
   */
  public createBatchEdit(category: CategoryEnhanced): void {
    this.createBatchEditOutput.emit(category);
  }

  /**
   * Delete Single Category
   * @param categoryId
   */
  public deleteSingleCategory(categoryId: string): void {
    this.deleteSingleCategoryOutput.emit(categoryId);
  }

  /**
   * Route to Category or Items
   * @param category
   */
  public routeToCategoryOrItems(category: CategoryEnhanced): void {
    this.routeToCategoryOrItemsOutput.emit(category.id);
  }

  /**
   * Toggle Duplicate Overlay
   */
  public toggleDuplicateOverlay(category: CategoryEnhanced): void {
    this.toggleDuplicateOverlayOutput.emit(category);
  }
}
