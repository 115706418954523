<header>
  <div class="px-6 pb-2">
    <nav aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-2">
        <li>
          <div class="flex items-center">
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'templates']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Templates</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>

  <div class="page-header-wrapper">
    <div class="md:flex md:items-center">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">
          {{ template.title }}
        </h2>
      </div>
    </div>
  </div>
</header>
