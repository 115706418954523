import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartsActionResults } from 'src/app/models/charts/charts-action.model';
import { ChartsActionsComponent } from 'src/app/shared/charts/charts-actions/charts-actions.component';

@Component({
  selector: 'inspection-overview-stats-actions',
  standalone: true,
  imports: [InspectionOverviewStatsActionsComponent, ChartsActionsComponent, JsonPipe],
  templateUrl: './inspection-overview-stats-actions.component.html',
  styleUrls: ['./inspection-overview-stats-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionOverviewStatsActionsComponent {
  @Input({ required: true }) actions!: ChartsActionResults[];
  @Input({ required: true }) totalActions!: number;
}
