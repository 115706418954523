<div class="flex h-full w-full flex-col px-6 lg:flex-row lg:space-x-14 xl:px-0">
  <!-- Sub Menu -->
  <div class="w-auto lg:w-[270px]">
    <account-menu [workspaceId]="workspaceId" />
  </div>

  <!-- Router Content -->
  <div class="flex w-full flex-1 flex-shrink-0 justify-center overflow-y-auto">
    <router-outlet></router-outlet>
  </div>
</div>
