import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActionCodeSettings, Auth, sendSignInLinkToEmail, User, user } from '@angular/fire/auth';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'login-panel',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule],
  templateUrl: './login-panel.component.html',
  styleUrl: './login-panel.component.scss',
})
export class LoginPanelComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private auth = inject(Auth);
  private router = inject(Router);
  private authService = inject(AuthService);
  public hasEmailBeenSent = false;
  public isFormInvalid = false;
  public noAccount = false;
  public signUpForm: FormGroup;
  private authSubscription!: Subscription;
  constructor() {
    this.signUpForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
    });
  }

  ngOnInit(): void {
    this.authSubscription = user(this.auth).subscribe((currentUser: User | null) => {
      if (currentUser) {
        this.router.navigate(['/workspaces']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  /**
   * Check Form Validations
   */
  checkFormValidations() {
    if (this.signUpForm.invalid) {
      this.isFormInvalid = true;
    } else {
      this.sendEmailLink();
    }
  }

  /**
   * Send Email Link
   */
  sendEmailLink() {
    const actionCodeSettings: ActionCodeSettings = {
      url: environment.loginUrl,
      handleCodeInApp: true,
    };

    const email = this.signUpForm.value.email;
    if (email) {
      sendSignInLinkToEmail(this.auth, email, actionCodeSettings)
        .then(() => {
          this.hasEmailBeenSent = true;
          this.authService.setEmailForSignInToLocalStorage(email);
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert('Email is required');
    }
  }
}
