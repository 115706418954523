import { NgClass } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToolTipsStringService } from 'src/app/core/services/strings/tooltips.service';
import { ToolTipFilter, ToolTipType } from 'src/app/models/strings/strings.model';
import { InspectionCategoryEditDetailsComponent } from '../inspection-category-edit-details/inspection-category-edit-details.component';
@Component({
  selector: 'inspection-category-new-overlay',
  standalone: true,
  imports: [InspectionCategoryEditDetailsComponent, NgClass],
  templateUrl: './inspection-category-new-overlay.component.html',
  styleUrl: './inspection-category-new-overlay.component.scss',
})
export class InspectionCategoryNewOverlayComponent {
  @Input({ required: true }) categoryForm!: FormGroup;
  @Output() saveCategoryAndRerouteOutput = new EventEmitter<void>();
  @Output() saveCategoryAndAddAnotherOutput = new EventEmitter<void>();
  @Output() toggleNewCategoryOverlayOutput = new EventEmitter<void>();

  // Services
  private toolTipsStringService = inject(ToolTipsStringService);

  // Properties
  public toolTipCategoryNotes: ToolTipFilter = this.toolTipsStringService.getToolTip(ToolTipType.CategoryNotes);

  /**
   * Save the category
   */
  public saveCategoryAndReroute() {
    this.saveCategoryAndRerouteOutput.emit();
  }

  /**
   * Save the category and add another
   */
  public saveCategoryAndAddAnother(): void {
    this.saveCategoryAndAddAnotherOutput.emit();
  }

  /**
   * Toggle the new category overlay
   */
  public toggleNewCategoryOverlay(): void {
    this.toggleNewCategoryOverlayOutput.emit();
  }
}
