import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'test-types',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule],
  templateUrl: './test-types.component.html',
  styleUrl: './test-types.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestTypesComponent {
  @Input({ required: true }) itemForm!: FormGroup;

  // Properties
  public answerTestTypes = [
    {
      id: 'test-fail-pass',
      title: 'Fail | Pass',
    },
    {
      id: 'test-no-yes',
      title: 'No | Yes',
    },
    {
      id: 'test-low-high',
      title: 'Low | High',
    },
    {
      id: 'test-poor-fair-good',
      title: 'Poor | Fair | Good',
    },
    {
      id: 'test-red-amber-green',
      title: 'Red | Amber | Green',
    },
    {
      id: 'test-progress',
      title: 'Not Started | In Progress | Completed',
    },
  ];

  public currentAnswerTestType: string | undefined;

  /**
   * Set Answer Test Type
   */
  setAnswerTestType(id: string) {
    this.currentAnswerTestType = id;
  }
}
