<div class="mx-auto max-w-7xl rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
  <!-- List Component -->
  <ul id="foldersList" role="list" class="list-ul">
    @for (folder of filteredFolders; track $index) {
      <li [class.hidden]="folder.hidden">
        <div class="list-row">
          <a [routerLink]="['/', 'workspace', workspaceId, 'folders', folder.id, 'inspections']" class="list-a group items-center">
            <!-- Left Icon -->
            <div class="flex flex-row" [ngStyle]="{ color: folder.colour }">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="ml-2.5 h-6 w-6 sm:ml-0">
                <path
                  d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z"
                />
              </svg>
            </div>

            <!-- Left and Right Content -->
            <div class="flex w-full flex-col gap-x-4 sm:flex-row">
              <!-- Left Content -->
              <div class="min-w-0 flex-auto">
                <p class="list-title">{{ folder.title }}</p>
              </div>

              <!-- Right Content -->
              <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                <div class="sm:flex sm:flex-col sm:items-end">
                  <p class="list-meta">{{ folder.inspections_count }} {{ folder.inspections_count === 1 ? 'Inspection' : 'Inspections' }}</p>
                </div>
              </div>
            </div>
          </a>

          <!-- Right Icon -->
          <div class="flex">
            <button type="button" class="list-icon" aria-expanded="false" aria-haspopup="true" (click)="editFolder(folder)">
              <span class="sr-only">Edit Folder</span>
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
              </svg>
            </button>
          </div>
        </div>
      </li>
    }
  </ul>
</div>

@if (allFoldersHidden) {
  <search-empty [searchTerm]="_searchTerm" />
}
