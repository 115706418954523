import { DatePipe, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InspectionEnhanced } from 'src/app/models/inspection/inspection.model';
import { SiteEnhanced } from 'src/app/models/site/site.model';

@Component({
  selector: 'inspection-overview-details',
  standalone: true,
  imports: [DatePipe, NgStyle],
  templateUrl: './inspection-overview-details.component.html',
  styleUrl: './inspection-overview-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionOverviewDetailsComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) inspection!: InspectionEnhanced;
  @Input() site: SiteEnhanced | undefined | null;
  @Input() showSiteInfo!: boolean;
  @Output() editDetailsOutput = new EventEmitter();
  @Output() toggleSiteOutput = new EventEmitter<string>();

  /**
   * Limitation Manager Check
   */
  public limitationManagerCheck(): void {
    this.editDetailsOutput.emit();
  }

  /**
   * Toggle Site Info
   * @param siteId
   */
  public toggleSiteInfo(siteId: string): void {
    this.toggleSiteOutput.emit(siteId);
  }
}
