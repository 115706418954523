import { AsyncPipe, NgClass, NgStyle } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User, WriteEventType } from "cip";
import { getFunctions, httpsCallable } from "firebase/functions";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { ToolTipsStringService } from "src/app/core/services/strings/tooltips.service";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { AlertType, ToolTipFilter, ToolTipType } from "src/app/models/strings/strings.model";
import { WorkspaceEnhanced } from "src/app/models/workspace/workspace.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { DropzoneDirective } from "src/app/shared/directives/dropzone.directive";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { PhotoDropzoneComponent } from "src/app/shared/photo-dropzone/photo-dropzone.component";
import { PhotoPreviewComponent } from "src/app/shared/photo-preview/photo-preview.component";
import { PhotoUploadSingleComponent } from "src/app/shared/photo-upload-single/photo-upload-single.component";
import { TooltipComponent } from "src/app/shared/tooltip/tooltip.component";
import { WorkspaceEditorDetailsComponent } from "../../../all-workspaces/workspace-editor-details/workspace-editor-details.component";
import { WorkspacesService } from "../../../services/workspaces.service";
import { MembersListService } from "../../manage-members/services/members-list.service";
import { ManageWorkspaceTransferOwnershipWrapperComponent } from "../manage-workspace-transfer-ownership-wrapper/manage-workspace-transfer-ownership-wrapper.component";

interface TransferOwnershipRequest {
  workspace_id: string;
  to_user_id: string;
}

interface TransferOwnershipResponse {
  transferred: boolean;
  previous_owner_id: string;
}

@Component({
  selector: "manage-workspace-wrapper",
  standalone: true,
  imports: [
    WorkspaceEditorDetailsComponent,
    LoadingSpinnerComponent,
    NgClass,
    ReactiveFormsModule,
    FormStateComponent,
    TooltipComponent,
    PhotoDropzoneComponent,
    DropzoneDirective,
    PhotoPreviewComponent,
    PhotoUploadSingleComponent,
    AsyncPipe,
    NgStyle,
    DeleteOverlayComponent,
    ManageWorkspaceTransferOwnershipWrapperComponent,
  ],
  templateUrl: "./manage-workspace-wrapper.component.html",
  styleUrl: "./manage-workspace-wrapper.component.scss",
})
export class ManageWorkspaceWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private workspacesService = inject(WorkspacesService);
  private stringsService = inject(StringsService);
  private toolTipsStringService = inject(ToolTipsStringService);
  private membersListService = inject(MembersListService);

  // Properties
  public workspace$!: Observable<WorkspaceEnhanced>;
  public members$!: Observable<MemberEnhanced[]>;
  public allOtherMembers: Array<MemberEnhanced> = [];
  private _workspaceId!: string;
  public workspaceForm: FormGroup;
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos: boolean = false;
  public overlay_deletePhoto: boolean = false;
  private tempBlobPhotoURL!: string;
  public user: User;
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  public overlay_editOwnership: boolean = false;
  public toolTipWorkspaceOwnership: ToolTipFilter = this.toolTipsStringService.getToolTip(ToolTipType.WorkspaceOwnership);
  public transferConfirmation!: string;
  public transferOwnershipResponseLoadingIndicator!: boolean;
  public overlay_selectOwner: boolean = false;
  public currentlySelectedMember: MemberEnhanced | null = null;

  constructor() {
    this.user = this.authService.currentUser;
    this.workspaceForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      image_url: null,
      image_thumbnail_url: null,
      website: ["", this.formFormattingService.noWhitespaceValidator()],
      created_by: null,
      created_by_id: null,
      current_owner: null,
      current_owner_id: null,
    });
  }

  ngOnInit(): void {
    this.getWorkspaceDoc();
  }

  /**
   * Get Inspection Doc
   */
  private async getWorkspaceDoc(): Promise<void> {
    try {
      this.workspace$ = this.workspacesService.getWorkspaceDoc$(this.workspaceId).pipe(
        filter((data) => !!data),
        tap((data) => {
          if (this.workspaceForm.pristine && this.workspaceForm.untouched) {
            this.workspaceForm.patchValue(data);
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Check If Photos Are Uploading Or Create Workspace
   */
  public async checkIfPhotosAreUploadingOrCreateWorkspace(): Promise<void> {
    if (this.uploadFileArray.length > 0) {
      this.overlay_uploadPhotos = true;
    } else {
      this.saveWorkspaceDoc();
    }
  }

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    if (photos.type === "workspace") {
      this.workspaceForm.patchValue({
        image_url: photos.image_url,
        image_thumbnail_url: photos.image_thumbnail_url,
      });
    }
    await this.saveWorkspaceDoc();
    this.emptyUploadFileArray();
    this.revokeObjectURL();
  }

  /**
   * Create Workspace Doc
   */
  private async saveWorkspaceDoc(): Promise<void> {
    try {
      const writeEventType: WriteEventType = "changed";
      const isDeleted = false;
      await this.workspacesService.saveWorkspaceDoc(this.workspaceId, this.workspaceForm.value, this.user, writeEventType, isDeleted);
      this.resetForm();
      this.overlay_uploadPhotos = false;
      this.photoPreview = false;
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split("/").pop();
    if (!(fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png")) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.photoPreview = true;
      this.workspaceForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * Remove Photo From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Remove Workspace Photo From Form
   */
  public removeWorkspacePhotoFromForm(): void {
    this.workspaceForm.patchValue({
      image_url: null,
      image_thumbnail_url: null,
    });
    this.workspaceForm.markAsDirty();
    this.toggleDeletePhotoOverlay();
  }

  async transferOwnershipAndCloseOverlays(): Promise<void> {
    this.transferOwnershipResponseLoadingIndicator = true;
    const functions = getFunctions();
    const transferWorkspaceOwnership = httpsCallable<TransferOwnershipRequest, TransferOwnershipResponse>(functions, "transferWorkspaceOwnership");

    if (this.currentlySelectedMember?.id) {
      const requestData: TransferOwnershipRequest = {
        workspace_id: this.workspaceId,
        to_user_id: this.currentlySelectedMember.id,
      };
      try {
        const response = await transferWorkspaceOwnership(requestData);
        if (response.data.transferred) {
          this.resetTransferOwnershipProperties();
        }
      } catch (error) {
        alert(`Error transferring workspace ownership', ${error}`);
      }
    }
  }

  public memberSelection(member: MemberEnhanced): void {
    this.currentlySelectedMember = member;
    this.toggleSelectOwnerOverlay();
  }

  /**
   * Toggle Select Owner Overlay
   */
  private toggleSelectOwnerOverlay(): void {
    this.overlay_selectOwner = !this.overlay_selectOwner;
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle Delete Workspace Image Overlay
   */
  public toggleDeletePhotoOverlay(): void {
    const deleteString = this.stringsService.alertFilter(AlertType.DeleteWorkspacePhoto);
    if (deleteString) {
      this.deleteOverlayTitle = deleteString.title;
      this.deleteOverlayDescription = deleteString.description;
    } else {
      console.error("Unknown string type:", AlertType);
    }

    this.overlay_deletePhoto = !this.overlay_deletePhoto;
  }

  /**
   * Toggle the edit ownership overlay
   */
  toggleEditOwnershipOverlay(): void {
    this.overlay_editOwnership = !this.overlay_editOwnership;
  }

  /**
   * Toggle the new owner panel
   */
  toggleNewOwnerPanel(): void {
    this.toggleSelectOwnerOverlay();

    if (!this.members$) {
      this.members$ = this.membersListService.getMembersList$(this.workspaceId).pipe(
        filter((data) => !!data),
        tap((data) => {
          this.allOtherMembers = [];
          data.forEach((member) => {
            if (member.id !== this.workspaceForm.value["current_owner_id"] && member.status === "activated") {
              this.allOtherMembers.push(member);
            }
          });
        })
      );
    }
  }

  /**
   * Reset the transfer ownership properties
   */
  resetTransferOwnershipProperties(): void {
    this.transferOwnershipResponseLoadingIndicator = false;
    this.overlay_editOwnership = false;
  }

  /**
   * Reset Form
   */
  private resetForm(): void {
    this.workspaceForm.markAsPristine();
    this.workspaceForm.markAsUntouched();
  }

  /**
   * Empty Upload File Array
   */
  private emptyUploadFileArray(): void {
    this.uploadFileArray = [];
  }

  /**
   * Revoke Object URL
   */
  private revokeObjectURL(): void {
    URL.revokeObjectURL(this.tempBlobPhotoURL);
  }
}
