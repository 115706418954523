<div class="dropzone flex justify-center rounded-lg border border-dashed border-image_Dropzone_Border bg-image_Dropzone px-6 py-10" appDropzone (hovered)="toggleHover($event)" (dropped)="detectPhotos($event)" [class.hovering]="isHovering">
  <!-- Base case: Always show this content -->
  @if (!(uploadSourceComponent === "test") || (uploadSourceComponent === "test" && doesUserHavePermissions)) {
    <div class="text-center">
      <svg class="mx-auto h-12 w-12 text-text_Icon" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
        <path
          fill-rule="evenodd"
          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
          clip-rule="evenodd" />
      </svg>
      <div class="mt-4 flex text-sm leading-6 text-gray-600">
        <label for="photo-input" class="relative cursor-pointer rounded-md font-semibold text-brand_Primary focus-within:outline-none focus-within:ring-2 focus-within:ring-brand_Primary focus-within:ring-offset-2 hover:text-brand_Primary">
          <span>Click to upload</span>
          <input #imageUploadInputFile type="file" id="photo-input" class="sr-only" (change)="detectPhotosFromInput($event)" accept="image/png,image/jpg,image/jpeg" multiple />
        </label>
        <p class="pl-1">or drag and drop photos here</p>
      </div>

      <p class="text-xs leading-5 text-gray-600">
        PNG and JPG files supported
        @if (uploadSourceComponent === "test") {
          (max 20 per upload)
        }
      </p>
    </div>
  }

  @if (uploadSourceComponent === "test" && !doesUserHavePermissions) {
    <div class="text-center text-gray-600">
      <svg class="mx-auto h-12 w-12 text-text_Icon" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
        <path
          fill-rule="evenodd"
          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
          clip-rule="evenodd" />
      </svg>
      <p class="mt-4 mb-1 font-semibold">Photo Upload: {{ permissionErrorTitle }}</p>
      <p>{{ permissionErrorDescription }}</p>
    </div>
  }
</div>
