import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RoleCurrent, RoleDefault, RoleEnhanced } from "src/app/models/role/role.model";
import { InfoViewFilter } from "src/app/models/strings/strings.model";
import { PermissionsGridComponent } from "../../permissions/permissions-grid/permissions-grid.component";
import { RoleEditDetailsComponent } from "../role-edit-details/role-edit-details.component";
import { RoleSelectorCustomComponent } from "../role-selector-custom/role-selector-custom.component";
import { RoleSelectorDefaultComponent } from "../role-selector-default/role-selector-default.component";

@Component({
  selector: "role-selector-wrapper",
  standalone: true,
  imports: [NgClass, PermissionsGridComponent, RoleSelectorDefaultComponent, RoleSelectorCustomComponent, RoleEditDetailsComponent],
  templateUrl: "./role-selector-wrapper.component.html",
  styleUrl: "./role-selector-wrapper.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleSelectorWrapperComponent {
  @Input({ required: true }) defaultRoles!: RoleDefault[];
  @Input({ required: true }) customRoles!: RoleEnhanced[];
  @Input({ required: true }) currentRole: RoleCurrent | null = null;
  @Input({ required: true }) overlay_roleForm!: boolean;
  @Input({ required: true }) roleForm!: FormGroup;
  @Input({ required: true }) infoViewPermissionsEditor!: InfoViewFilter;
  @Output() toggleRoleSelectorOverlayOutput = new EventEmitter<void>();
  @Output() selectRoleOutput = new EventEmitter<RoleDefault>();
  @Output() toggleRoleFormOverlayOutput = new EventEmitter<void>();
  @Output() saveRoleOutput = new EventEmitter<void>();

  // Properties
  public selectedTab = "Default";

  public selectTab(value: string): void {
    this.selectedTab = value;
  }

  /**
   * Save Role
   */
  public saveRole(): void {
    this.saveRoleOutput.emit();
  }

  /**
   * Toggle Role Selector
   */
  public toggleRoleSelector(): void {
    this.toggleRoleSelectorOverlayOutput.emit();
  }

  /**
   * Select Role
   * @param role
   */
  public selectRole(role: RoleDefault): void {
    this.selectRoleOutput.emit(role);
  }

  /**
   * Toggle New Role Form
   */
  public toggleRoleFormOverlay(): void {
    this.toggleRoleFormOverlayOutput.emit();
  }
}
