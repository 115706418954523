<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:max-w-[625px] sm:align-middle">
      <div class="sm:flex sm:items-start">
        <div class="flex w-full flex-col space-y-4 overscroll-contain">
          <div class="flex flex-row items-center px-6 pt-6">
            <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">Generate Report</h3>
          </div>

          <ng-container [ngTemplateOutlet]="generating"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Duplicating -->
<ng-template #generating>
  <div class="flex flex-col items-center">
    @if (reportsService.reportDownloadURL() === "" && !reportError) {
      <ng-container [ngTemplateOutlet]="inProgress"></ng-container>
    }
    @if (reportsService.reportDownloadURL()) {
      <ng-container [ngTemplateOutlet]="success"></ng-container>
    }
    @if (reportsService.reportDownloadURL() === "" && reportError) {
      <ng-container [ngTemplateOutlet]="failed"></ng-container>
    }
  </div>

  @if (reportsService.reportDownloadURL()) {
    <div class="bg-gray-50 p-6 sm:flex sm:flex-row-reverse">
      <a [href]="reportsService.reportDownloadURL()" target="_blank" class="btn btn-primary ml-3 cursor-pointer" (click)="reportsService.toggleGenerationOverlay()">Download</a>
      <button type="button" class="btn btn-cancel" (click)="reportsService.toggleGenerationOverlay()">Close</button>
    </div>
  }
</ng-template>

<!-- In Progress -->
<ng-template #inProgress>
  <div class="my-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
    <div class="col-span-6 flex justify-center">
      <svg class="-ml-1 h-10 w-10 animate-spin text-brand_Primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <div class="col-span-6 flex justify-center">
      <p class="text-sm text-text_Subtitle">Generating...</p>
    </div>
  </div>
</ng-template>

<!-- Success -->
<ng-template #success>
  <div class="my-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
    <div class="col-span-6 flex flex-row justify-center">
      <div class="flex h-10 w-10 items-center justify-center rounded-full bg-brand_Primary text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
        </svg>
      </div>
    </div>
    <div class="col-span-6 flex flex-col justify-center">
      <p class="text-sm text-text_Subtitle">Generation Complete</p>
    </div>
  </div>
</ng-template>

<!-- Error -->
<ng-template #failed>
  <div class="my-8 flex flex-col items-center space-y-4">
    <!-- Error Icon-->
    <div class="flex h-10 w-10 items-center justify-center rounded-full bg-red-500 text-white">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
    <p class="text-sm text-text_Subtitle">Generation Failed</p>

    <!-- Error Code -->
    <p class="text-sm text-text_Subtitle">{{ reportError.code }}</p>

    <!-- Error Message -->
    <p class="text-sm text-text_Subtitle">{{ reportError.message }}</p>
  </div>
  <div class="w-full bg-gray-50 p-6 sm:flex sm:flex-row-reverse">
    <button type="button" class="btn btn-cancel" (click)="reportsService.toggleGenerationOverlay()">Close</button>
  </div>
</ng-template>
