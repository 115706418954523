<div class="content-card-wrapper">
  <div class="content-card-header">
    <h3>Template Actions</h3>
  </div>
  <div class="content-card px-6 py-5">
    <div class="grid gap-y-2">
      <div class="flex flex-col border-b border-gray-200 pb-2">
        <button type="button" class="btn items-center" [disabled]="categoriesCount < 1" [ngClass]="categoriesCount < 1 ? 'btn-disabled cursor-not-allowed' : 'btn-primary'" (click)="startInspectionFromTemplateOverlay()">Create Inspection</button>
      </div>

      <button class="btn" [disabled]="categoriesCount < 1" [ngClass]="categoriesCount < 1 ? 'btn-disabled cursor-not-allowed' : 'btn-secondary'" (click)="duplicateTemplateOverlay()">Duplicate</button>
      <button type="button" (click)="toggleDeleteTemplateOverlay()" class="btn btn-secondary">Delete Template</button>
    </div>
  </div>
</div>
