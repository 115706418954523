@if (reportsService.themeDetails.value.photos_included) {
  <div class="photo-wrapper" [ngClass]="'photo-' + reportsService.themeDetails.value.photos_size + (reportsService.themeDetails.value.photos_squared ? ' square-photos' : '')">
    @for (photo of reportsService.reportData.photos; track photo.id; let i = $index; let first = $first) {
      @if (photo.item_id === itemId) {
        <div class="photo-content" style="page-break-inside: avoid">
          <div class="photo">
            <img [src]="photo.image_url" [ngClass]="{ squareImg: reportsService.themeDetails.value.photos_squared }" />
          </div>
          <div class="photo-data-wrapper">
            <p class="photo-order">{{ photo.order + 1 }}</p>
            @if (reportsService.themeDetails.value.photos_timestamps) {
              <div class="photo-timestamp-wrapper" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">
                @if (photo.timestamp) {
                  <p class="photo-time">{{ photo.timestamp.toDate() | date: "h:mm:ss a" }}</p>
                  <p class="photo-timestamp">
                    {{ photo.timestamp.toDate() | dateFormat: reportsService.themeDetails.value.dates_order : reportsService.themeDetails.value.dates_style }}
                  </p>
                }
              </div>
            }
          </div>
        </div>
      }
    }
  </div>
}
