import { Injectable } from '@angular/core';
import { ToolTipFilter, ToolTipType } from 'src/app/models/strings/strings.model';

@Injectable({
  providedIn: 'root',
})
export class ToolTipsStringService {
  private InspectionDetailsSite: ToolTipFilter = {
    title: 'Add a Site to this Inspection to make its location and details appear on the Report.',
  };
  private InspectionDetailsClient: ToolTipFilter = {
    title: 'Add a Client to this Inspection to make its details appear on the Report.',
  };
  private InspectionDetailsFolder: ToolTipFilter = {
    title: 'The Folder where this Inspection is stored.',
  };
  private WorkspaceOwnership: ToolTipFilter = {
    title: 'Transferring Ownership of the Workspace gives overall control to the individual you select. There can only be one Owner of a Workspace.',
  };
  private RoleDefault: ToolTipFilter = {
    title: 'These Roles are predefined in all Workspaces. They cannot be edited.',
  };
  private RoleCustom: ToolTipFilter = {
    title: 'These are custom Roles, created by the Workspace Owner or Admin.',
  };
  private CategoryNotes: ToolTipFilter = {
    title: 'Add notes here to describe, explain or give context to this Category. These notes will only be shown on Reports.',
  };

  /**
   * Strings for alerts (delets / enabled, disabled / complete, incomplete / etc. )
   * @param type
   * @returns
   */
  toolTipFilter(type: ToolTipType): ToolTipFilter | undefined {
    switch (type) {
      case ToolTipType.InspectionDetailsSite:
        return this.InspectionDetailsSite;
      case ToolTipType.InspectionDetailsClient:
        return this.InspectionDetailsClient;
      case ToolTipType.InspectionDetailsFolder:
        return this.InspectionDetailsFolder;
      case ToolTipType.WorkspaceOwnership:
        return this.WorkspaceOwnership;
      case ToolTipType.RoleDefault:
        return this.RoleDefault;
      case ToolTipType.RoleCustom:
        return this.RoleCustom;
      case ToolTipType.CategoryNotes:
        return this.CategoryNotes;

      default:
        return undefined;
    }
  }

  public getToolTip(type: ToolTipType): ToolTipFilter {
    const toolTip = this.toolTipFilter(type);
    if (!toolTip) {
      throw new Error(`Unknown string type: ${type}`);
    }
    return toolTip;
  }
}
