import { Injectable } from '@angular/core';
import { Firestore, doc, docData, setDoc } from '@angular/fire/firestore';
import { User } from 'cip';
import { Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private _firestore: Firestore) {}

  public getUserById(id: string): Observable<User | undefined> {
    const userDocRef = doc(this._firestore, `${environment.col.USERS}/${id}`);
    return docData(userDocRef, { idField: 'user_id' }).pipe(
      shareReplay(1) // Share the latest emitted value with future subscribers
    ) as Observable<User | undefined>;
  }

  public async saveUser(user: User): Promise<User> {
    const userDocRef = doc(this._firestore, `${environment.col.USERS}/${user.user_id}`);
    await setDoc(userDocRef, user, { merge: true });
    return user;
  }
}
