<div class="grid grid-cols-1 gap-y-4">
  <!-- List -->
  <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
    <!-- List Component -->
    <ul class="list-ul" id="categoriesList" cdkDropList (cdkDropListDropped)="reorderCategories($event)">
      @for (category of filteredCategories; track $index; let i = $index) {
        <li class="list-none" [ngClass]="category.enabled ? 'opacity-100' : 'opacity-50'" cdkDrag [cdkDragDisabled]="!editMode" [class.hidden]="category.hidden">
          <!-- !categoriesListService.toggleEnabledCategories || (categoriesListService.toggleEnabledCategories && category.enabled)-->
          @if (!toggleDisabled || (toggleDisabled && category.enabled)) {
            <div class="list-row">
              <!-- List Editing Tools -->
              @if (editMode) {
                <div class="ml-2.5 flex flex-row items-center sm:ml-0">
                  <div class="mr-3 flex items-center justify-end hover:text-brand_Primary-hover dark:text-slate-300 dark:hover:text-sky-500">
                    <div class="flex items-center">
                      @if (categoriesBulkEditArray) {
                        <input type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" (change)="createBatchEdit(category)" [checked]="categoriesBulkEditArray.includes(category)" />
                      }
                    </div>
                  </div>
                  <div class="flex items-center">
                    @if (categoriesBulkEditArray && categoriesBulkEditArray.length <= 0) {
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" (click)="deleteSingleCategory(category.id)" class="cip-transition mr-3 h-5 w-5 cursor-pointer text-alert_Error hover:opacity-75">
                        <path
                          fill-rule="evenodd"
                          d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    }
                  </div>
                  @if (categoriesBulkEditArray && categoriesBulkEditArray.length <= 0) {
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5 cursor-pointer text-text_Subtitle" (click)="toggleDuplicateOverlay(category)">
                      <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                      <path
                        d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z"
                      />
                    </svg>
                  }
                </div>
              }

              <!-- Left Index-->
              <div class="ml-2.5 flex flex-row sm:ml-0">
                <div class="flex h-6 w-6 font-medium text-brand_Primary-hover dark:text-white">{{ i + 1 }}</div>
              </div>

              <div (click)="routeToCategoryOrItems(category)" class="list-a group cursor-pointer">
                <!-- Content -->
                <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                  <!-- Left -->
                  <div class="min-w-0 flex-auto">
                    <p class="list-title">{{ category.title }}</p>

                    <div class="my-2 h-1 w-full overflow-hidden rounded-md bg-gray-200">
                      <div
                        class="h-full"
                        [ngClass]="{
                          'bg-brand_Primary': category.items_count && category.items_count > 0,
                          'bg-[#E4E5E6]': category.items_count === 0 || !category.items_count,
                          'bg-score_100': category.items_count === category.items_completed_count,
                        }"
                        [ngStyle]="{ width: (category.items_completed_count * 100) / category.items_count + '%' }"
                      ></div>
                    </div>
                    <p class="list-meta">
                      {{ category.items_count > 0 ? category.items_completed_count + ' of ' + category.items_count + ' Items completed - ' + ((category.items_completed_count * 100) / category.items_count | number: '1.0-0') + '%' : '0 Items' }}
                    </p>
                  </div>
                </div>

                <!-- Right Icon - Default -->
                @if (!editMode) {
                  <div class="flex">
                    <button type="button" class="list-icon" aria-expanded="false" aria-haspopup="true">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                    </button>
                  </div>
                }

                <!-- Right Icon - Edit -->
                @if (editMode) {
                  <div class="flex">
                    <button type="button" class="list-icon" aria-expanded="false" aria-haspopup="true">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                      </svg>
                    </button>
                  </div>
                }
              </div>
            </div>
          }
        </li>
      }
    </ul>
  </div>
</div>

@if (allCategoriesHidden) {
  <search-empty [searchTerm]="_searchTerm" />
}
