import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemEnhanced } from 'src/app/models/item/item.model';

@Component({
  selector: 'test-header',
  standalone: true,
  imports: [],
  templateUrl: './test-header.component.html',
  styleUrl: './test-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestHeaderComponent {
  @Input({ required: true }) item!: ItemEnhanced;
}
