<form [formGroup]="roleForm">
  <div class="grid grid-cols-1 gap-y-6">
    <div class="form-input-wrapper">
      <label for="role-title" class="form-input-label"
        >Title*
        @if (!roleForm.pristine && roleForm.controls["title"].errors) {
          <span class="required-field"> - Title is required</span>
        }
      </label>
      <input type="text" name="role-title" id="role-title" class="form-input" placeholder="Title" formControlName="title" />
    </div>
    <div class="form-input-wrapper relative">
      <label for="role-description" class="form-input-label"
        >Description*
        @if (!roleForm.pristine && roleForm.controls["description"].errors) {
          <span class="required-field"> - Description is required</span>
        }
      </label>
      <textarea type="text" name="role-description" id="role-description" class="form-input" formControlName="description" (input)="characterLimit($event)" (paste)="onPaste($event)" style="min-height: 60px"></textarea>
      <div class="absolute right-0 top-0 block pr-5 pt-2 text-xs font-normal">{{ charactersLeft }} characters remaining</div>
    </div>
  </div>
</form>
