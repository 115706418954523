// Generated by CoffeeScript 2.4.1
(function () {
  var NodeType, XMLDOMConfiguration, XMLDOMImplementation, XMLDocument, XMLNode, XMLStringWriter, XMLStringifier, isPlainObject;
  ({
    isPlainObject
  } = require('./Utility'));
  XMLDOMImplementation = require('./XMLDOMImplementation');
  XMLDOMConfiguration = require('./XMLDOMConfiguration');
  XMLNode = require('./XMLNode');
  NodeType = require('./NodeType');
  XMLStringifier = require('./XMLStringifier');
  XMLStringWriter = require('./XMLStringWriter');

  // Represents an XML builder
  module.exports = XMLDocument = function () {
    class XMLDocument extends XMLNode {
      // Initializes a new instance of `XMLDocument`

      // `options.keepNullNodes` whether nodes with null values will be kept
      //     or ignored: true or false
      // `options.keepNullAttributes` whether attributes with null values will be
      //     kept or ignored: true or false
      // `options.ignoreDecorators` whether decorator strings will be ignored when
      //     converting JS objects: true or false
      // `options.separateArrayItems` whether array items are created as separate
      //     nodes when passed as an object value: true or false
      // `options.noDoubleEncoding` whether existing html entities are encoded:
      //     true or false
      // `options.stringify` a set of functions to use for converting values to
      //     strings
      // `options.writer` the default XML writer to use for converting nodes to
      //     string. If the default writer is not set, the built-in XMLStringWriter
      //     will be used instead.
      constructor(options) {
        super(null);
        this.name = "#document";
        this.type = NodeType.Document;
        this.documentURI = null;
        this.domConfig = new XMLDOMConfiguration();
        options || (options = {});
        if (!options.writer) {
          options.writer = new XMLStringWriter();
        }
        this.options = options;
        this.stringify = new XMLStringifier(options);
      }

      // Ends the document and passes it to the given XML writer

      // `writer` is either an XML writer or a plain object to pass to the
      // constructor of the default XML writer. The default writer is assigned when
      // creating the XML document. Following flags are recognized by the
      // built-in XMLStringWriter:
      //   `writer.pretty` pretty prints the result
      //   `writer.indent` indentation for pretty print
      //   `writer.offset` how many indentations to add to every line for pretty print
      //   `writer.newline` newline sequence for pretty print
      end(writer) {
        var writerOptions;
        writerOptions = {};
        if (!writer) {
          writer = this.options.writer;
        } else if (isPlainObject(writer)) {
          writerOptions = writer;
          writer = this.options.writer;
        }
        return writer.document(this, writer.filterOptions(writerOptions));
      }

      // Converts the XML document to string

      // `options.pretty` pretty prints the result
      // `options.indent` indentation for pretty print
      // `options.offset` how many indentations to add to every line for pretty print
      // `options.newline` newline sequence for pretty print
      toString(options) {
        return this.options.writer.document(this, this.options.writer.filterOptions(options));
      }

      // DOM level 1 functions to be implemented later
      createElement(tagName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createDocumentFragment() {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createTextNode(data) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createComment(data) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createCDATASection(data) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createProcessingInstruction(target, data) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createAttribute(name) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createEntityReference(name) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      getElementsByTagName(tagname) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }

      // DOM level 2 functions to be implemented later
      importNode(importedNode, deep) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createElementNS(namespaceURI, qualifiedName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createAttributeNS(namespaceURI, qualifiedName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      getElementsByTagNameNS(namespaceURI, localName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      getElementById(elementId) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }

      // DOM level 3 functions to be implemented later
      adoptNode(source) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      normalizeDocument() {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      renameNode(node, namespaceURI, qualifiedName) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }

      // DOM level 4 functions to be implemented later
      getElementsByClassName(classNames) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createEvent(eventInterface) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createRange() {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createNodeIterator(root, whatToShow, filter) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      createTreeWalker(root, whatToShow, filter) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
    }
    ;

    // DOM level 1
    Object.defineProperty(XMLDocument.prototype, 'implementation', {
      value: new XMLDOMImplementation()
    });
    Object.defineProperty(XMLDocument.prototype, 'doctype', {
      get: function () {
        var child, i, len, ref;
        ref = this.children;
        for (i = 0, len = ref.length; i < len; i++) {
          child = ref[i];
          if (child.type === NodeType.DocType) {
            return child;
          }
        }
        return null;
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'documentElement', {
      get: function () {
        return this.rootObject || null;
      }
    });

    // DOM level 3
    Object.defineProperty(XMLDocument.prototype, 'inputEncoding', {
      get: function () {
        return null;
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'strictErrorChecking', {
      get: function () {
        return false;
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'xmlEncoding', {
      get: function () {
        if (this.children.length !== 0 && this.children[0].type === NodeType.Declaration) {
          return this.children[0].encoding;
        } else {
          return null;
        }
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'xmlStandalone', {
      get: function () {
        if (this.children.length !== 0 && this.children[0].type === NodeType.Declaration) {
          return this.children[0].standalone === 'yes';
        } else {
          return false;
        }
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'xmlVersion', {
      get: function () {
        if (this.children.length !== 0 && this.children[0].type === NodeType.Declaration) {
          return this.children[0].version;
        } else {
          return "1.0";
        }
      }
    });

    // DOM level 4
    Object.defineProperty(XMLDocument.prototype, 'URL', {
      get: function () {
        return this.documentURI;
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'origin', {
      get: function () {
        return null;
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'compatMode', {
      get: function () {
        return null;
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'characterSet', {
      get: function () {
        return null;
      }
    });
    Object.defineProperty(XMLDocument.prototype, 'contentType', {
      get: function () {
        return null;
      }
    });
    return XMLDocument;
  }.call(this);
}).call(this);