import { inject, Injectable } from "@angular/core";
// import { AngularFirestore } from "@angular/fire/compat/firestore";
// import { setDoc } from '@core/util/firestore';
import { User, Workspace, WriteEvent, WriteEventType } from "cip";
import { arrayUnion, deleteField, orderBy, where, WithFieldValue } from "firebase/firestore";
import { catchError, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { BatchOperation } from "src/app/models/utils/batch";
import { WorkspaceEnhanced } from "src/app/models/workspace/workspace.model";
import { MemberDetailService } from "../current-workspace/manage-members/services/member-detail.service";
import { ThemeDetailService } from "../current-workspace/manage-themes/services/theme-detail.service";

export interface CurrentOwner {
  current_owner: string;
  current_owner_id: string;
  // job_title: string | FieldValue;
}

@Injectable({
  providedIn: "root",
})
export class WorkspacesService {
  private collectionsService = inject(CollectionsService);
  private lastEventService = inject(LastEventService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private memberDetailService = inject(MemberDetailService);
  private themeDetailService = inject(ThemeDetailService);

  // Overlays for workspace edit components
  public overlay_newWorkspace = false;
  public overlay_leaveWorkspace = false;
  public overlay_deleteWorkspace = false;
  public overlay_deleteWorkspaceConfirmation = false;

  /**
   * Get Workspaces
   * @param userId
   * @returns any documents where the `userId` exists in the `users` array of a workspace document
   */
  public getWorkspacesList$(userId: string): Observable<WorkspaceEnhanced[]> {
    const path = this.collectionsService.workspacesCol();
    const queryConstraints = [where(`workspace_users`, `array-contains`, `${userId}`), where("is_deleted", "==", false), orderBy("name", "asc")];
    return this.firestoreUtilsService.getCollectionData<WorkspaceEnhanced>(path, queryConstraints);
  }

  /**
   * Get Workspace Doc
   * @param workspaceId
   */
  public getWorkspaceDoc$(workspaceId: string): Observable<WorkspaceEnhanced | null> {
    const path = `${this.collectionsService.workspacesCol()}`;
    const doc = `${path}/${workspaceId}`;
    return this.firestoreUtilsService.getDocumentData<WorkspaceEnhanced>(doc).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Delete Workspace Doc
   * @param workspaceId
   * @param user
   */
  public async deleteWorkspaceDoc(workspaceId: string, user: User): Promise<void> {
    const path = `${this.collectionsService.workspacesCol()}`;
    const doc = `${path}/${workspaceId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const workspaceObj = { is_deleted: true, last_event: lastEvent };
    return await this.firestoreUtilsService.setDocumentData(doc, workspaceObj);
  }

  /**
   * Create Workspace Doc
   * @param workspace
   * @param newWorkspaceId
   * @param user
   */
  public async createWorkspaceDoc(workspace: WorkspaceEnhanced, newWorkspaceId: string, user: User) {
    // Write event
    const writeEventType = "added";
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);

    // Workspace
    const workspacePath = `${this.collectionsService.workspacesCol()}/${newWorkspaceId}`;
    const isDeleted = false;
    const workspaceObj = this.createWorkspaceObject(workspace, user, isDeleted, lastEvent);

    // Member
    const memberPath = `${this.collectionsService.membersCol(newWorkspaceId)}/${user.user_id}`;
    const memberObj = this.memberDetailService.createMemberObject(lastEvent, user);

    // Theme
    const themePath = `${this.collectionsService.themesCol(newWorkspaceId)}/default`;
    const themeObj = this.themeDetailService.createThemeObject(user);

    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: workspacePath,
        data: workspaceObj,
      },
      {
        type: "set",
        documentPath: memberPath,
        data: memberObj,
      },
      {
        type: "set",
        documentPath: themePath,
        data: themeObj,
      },
    ];
    try {
      return await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Save Workspace Doc
   * @param workspaceId
   * @param workspaceForm
   * @param user
   * @param writeEventType
   * @param isDeleted
   */
  public async saveWorkspaceDoc(workspaceId: string, workspaceForm: Workspace, user: User, writeEventType: WriteEventType, isDeleted: boolean): Promise<void> {
    const path = this.collectionsService.workspacesCol();
    const doc = `${path}/${workspaceId}`;
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const workspaceObj = this.createWorkspaceObject(workspaceForm, user, isDeleted, lastEvent);
    return this.firestoreUtilsService.setDocumentData(doc, workspaceObj);
  }

  /**
   * Create Workspace Object
   * @param workspaceForm
   * @param user
   * @returns
   */
  createWorkspaceObject(workspaceForm: Workspace, user: User, isDeleted: boolean, lastEvent: WithFieldValue<WriteEvent>): WithFieldValue<Workspace> {
    return {
      name: workspaceForm.name.trim(),
      website: workspaceForm.website ? workspaceForm.website.trim() : deleteField(),
      workspace_users: arrayUnion(user.user_id),
      image_url: workspaceForm.image_url ?? deleteField(),
      image_thumbnail_url: workspaceForm.image_thumbnail_url ?? deleteField(),
      created_by_id: user.user_id,
      current_owner: workspaceForm.current_owner ?? `${user.name_first} ${user.name_last}`,
      current_owner_id: workspaceForm.current_owner_id ?? user.user_id,
      is_deleted: isDeleted,
      last_event: lastEvent,
    };
  }

  /**
   * Leave Workspace
   * @param workspaceId
   * @param user
   */
  async leaveWorkspace(workspaceId: string, user: User): Promise<void> {
    try {
      await this.memberDetailService.removeMemberOrMemberLeft(workspaceId, user.user_id, user);
    } catch (error) {
      alert(error);
    }
  }
}
