@if (exportData$ | async; as exportData) {
  <header>
    <!-- Dummy breadcrumb element-->
    <div class="h-[28px] px-6 pb-2"></div>
    <!-- Header -->
    <div class="page-header-wrapper">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="page-header">Export Your Data</h2>
        </div>
      </div>
    </div>
  </header>

  <div class="grid grid-cols-1 gap-y-8">
    <div class="content-card-wrapper">
      <div class="content-card">
        <div class="content-grid gap-x-4 gap-y-6">
          <p class="list-meta">
            The security and integrity of your data is important to us. You can request a file containing all your user data for Checklist Inspector Pro Cloud by clicking on the button below. This request includes both personal data and Inspection
            data. Your request may take a few minutes to prepare.
          </p>
          <div>
            <!-- EXPORT -->
            @if (!hasBeenRequestedInLastDay && !requestUserDataProcessing) {
              <button type="button" aria-label="Export Data" class="btn btn-primary" (click)="setExportDoc()">Export Data</button>
            }

            <!-- DOWNLOAD -->
            @if (hasBeenRequestedInLastDay && !requestUserDataProcessing) {
              <a [href]="requestUserDataUrl" target="_blank">
                <button type="button" aria-label="Download User Data" class="btn btn-primary">Download Data</button>
              </a>
            }

            <!-- PROCESSING -->
            @if (requestUserDataProcessing) {
              <div class="flex flex-row items-center">
                <loading-spinner />
                <div class="list-meta pl-2 capitalize">{{ requestUserDataStatus }}</div>
              </div>
            }

            <!-- ERROR -->
            @if (requestUserDataError) {
              <info-view [type]="'error'" [message]="requestUserDataErrorText" />
            }
          </div>
          @if (hasBeenRequestedInLastDay) {
            <div>
              <p class="text-sm text-gray-500 mb-2">Last export occured on: {{ requestCreatedAt | date: "dd MMM yyyy" }}</p>
              <p class="text-sm text-gray-500">Next export available in: {{ timeLeftUntilNextExport }}</p>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}
