import { inject, Injectable } from '@angular/core';
import { where } from 'firebase/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionsService } from 'src/app/core/services/collections/collections.service';
import { FirestoreUtilsService } from 'src/app/core/services/firestore/firestore-utils.service';
import { FolderEnhanced } from 'src/app/models/folder/folder.model';

@Injectable({
  providedIn: 'root',
})
export class FoldersListService {
  // Services
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private collectionsService = inject(CollectionsService);

  // Properties
  public _reorderedFoldersSubject = new BehaviorSubject<FolderEnhanced[]>([]);
  public reorderedFolders$ = this._reorderedFoldersSubject.asObservable();
  public areFoldersSorted = false;
  public currentlySelectedFolderSortChoice!: string;
  public isSortOrderAscending: boolean = true;
  public sortDropdown: boolean = false;

  // Search

  /**
   * Get Folders List
   */
  public getFoldersList$(workspaceId: string): Observable<FolderEnhanced[]> {
    const path = this.collectionsService.foldersCol(workspaceId);
    const queryConstraints = [where('is_deleted', '==', false)];
    return this.firestoreUtilsService.getCollectionData<FolderEnhanced>(path, queryConstraints);
  }

  /**
   * Update Reordered Folders
   * @param folders
   */
  updateReorderedFolders(folders: FolderEnhanced[]) {
    this._reorderedFoldersSubject.next([...folders]);
  }

  /**
   * Reorder Folders With New Data
   */
  reorderFoldersWithNewData(): void {
    const currentFolders = this._reorderedFoldersSubject.getValue();
    let sortedFolders: FolderEnhanced[] = [...currentFolders];

    switch (this.currentlySelectedFolderSortChoice) {
      case 'Title':
        sortedFolders.sort((a, b) => (this.isSortOrderAscending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)));
        break;
      case 'Date':
        sortedFolders.sort((a, b) => {
          const dateA: Date = a.date_created.toDate();
          const dateB: Date = b.date_created.toDate();
          const comparison = dateA.getTime() - dateB.getTime();
          return this.isSortOrderAscending ? comparison : -comparison;
        });
        break;
      case 'Inspection Count':
        sortedFolders.sort((a, b) => (this.isSortOrderAscending ? a.inspections_count - b.inspections_count : b.inspections_count - a.inspections_count));
        break;
    }

    // Update the reordered folders subject
    this.updateReorderedFolders(sortedFolders);
  }
}
