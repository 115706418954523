import { AsyncPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { SupplementaryEnhanced } from "src/app/models/inspection/supplementary.model";
import { BreadcrumbsComponent } from "src/app/shared/breadcrumbs/breadcrumbs.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { InspectionSupplementaryDetailsComponent } from "../inspection-supplementary-details/inspection-supplementary-details.component";
import { SupplementaryService } from "../services/supplementary.service";

@Component({
  selector: "inspection-introduction",
  standalone: true,
  imports: [LoadingSpinnerComponent, BreadcrumbsComponent, AsyncPipe, InspectionSupplementaryDetailsComponent, FormStateComponent, NgClass],
  templateUrl: "./inspection-introduction.component.html",
  styleUrl: "./inspection-introduction.component.scss",
})
export class InspectionIntroductionComponent implements OnInit {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  // Services
  private formFormattingService = inject(FormFormattingService);
  private fb = inject(FormBuilder);
  private supplementaryService = inject(SupplementaryService);
  private authService = inject(AuthService);
  private router = inject(Router);

  // Properties
  public user!: User;
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public introductionForm: FormGroup;
  public introduction$!: Observable<SupplementaryEnhanced[]>;

  constructor() {
    this.user = this.authService.currentUser;
    this.introductionForm = this.fb.group({
      id: null,
      title: ["", this.formFormattingService.noWhitespaceValidator()],
      type: null,
      content: ["", this.formFormattingService.noWhitespaceValidator()],
      order: 0,
    });
  }

  ngOnInit(): void {
    this.getIntroduction();
  }

  /**
   * Get Introduction
   */
  private async getIntroduction(): Promise<void> {
    this.introduction$ = this.supplementaryService.getSupplementaryList$(this.workspaceId, this.inspectionId, "introduction").pipe(
      filter((data) => !!data),
      tap((data) => {
        if (this.introductionForm.pristine && this.introductionForm.untouched) {
          this.introductionForm.patchValue(data[0]);
        }
      })
    );
  }

  /**
   * Save Supplementary Form
   */
  public async saveSupplementaryForm(): Promise<void> {
    try {
      await this.supplementaryService.saveSupplementaryDoc(this.workspaceId, this.inspectionId, this.introductionForm.value, this.user);
      this.resetForm();
      this.router.navigate(["workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "overview"]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Reset Form
   */
  private resetForm(): void {
    this.introductionForm.reset();
    this.introductionForm.markAsPristine();
    this.introductionForm.markAsUntouched;
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.introductionForm.dirty ? false : true;
  }
}
