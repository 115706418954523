import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-show-actions",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./report-settings-show-actions.component.html",
  styleUrl: "./report-settings-show-actions.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsShowActionsComponent {
  // Services
  public reportsService = inject(ReportsService);

  // Properties
  public showActions = ["All", "Complete", "Incomplete", "None"];
  public toggleContent = false;

  /**
   * Toggle Content Options
   */
  public toggleContentOptions() {
    this.toggleContent = !this.toggleContent;
  }

  /**
   * Show Actions Radio Change
   */
  public showActionsRadioChange(): void {
    this.reportsService.updateReportSettings(this.reportsService.reportSettings);
  }
}
