import { NgClass } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { RolePermissions } from "cip";
import { AuthService } from "src/app/core/services/auth.service";
import { ConnectedDevicesService } from "src/app/core/services/connected-devices.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { NavigationService } from "src/app/core/services/navigation.service";
import { environment } from "src/environments/environment";
import { MainMenuSignOutOverlayComponent } from "./main-menu-sign-out-overlay/main-menu-sign-out-overlay.component";

@Component({
  selector: "main-menu",
  standalone: true,
  imports: [RouterModule, NgClass, MainMenuSignOutOverlayComponent],
  templateUrl: "./main-menu.component.html",
  styleUrl: "./main-menu.component.scss",
})
export class MainMenuComponent {
  // Services
  private router = inject(Router);
  private navigationService = inject(NavigationService);
  private limitationManagerService = inject(LimitationManagerService);
  private connectedDevicesService = inject(ConnectedDevicesService);
  public authService = inject(AuthService);

  // Route Param received by parent
  @Input({ required: true }) workspaceId!: string;

  // Response menu property for the UI
  public mobileMenu = false;

  // App Version to display in the menu
  public appVersion: string;

  // A hack to maintain the activeRouteColour for the manage routes
  // Due to the structure of the modules, the highlight isn't maintained
  // The 'checkUrl' will ensure the highlight for the 'manage' route is maintained
  public manageAccountIconColour!: string;
  public manageWorkspaceIconColour!: string;

  // A solution to handle routerLinkActive for a div element that doesn't have a routerLink attribute
  isManageWorkspaceActive = false;

  constructor() {
    this.appVersion = environment.version;
  }

  ngOnInit(): void {
    this.updateActiveState(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveState(event.urlAfterRedirects);
      }
    });
  }

  updateActiveState(url: string): void {
    this.isManageWorkspaceActive = url.includes(`/workspace/${this.workspaceId}/manage`);
  }

  goBackToFoldersOrReturnToInspectionChain() {
    const currentUrl = window.location.href;
    const breadcrumbsUrl = this.navigationService.breadcrumbsPath;
    console.log("currentUrl", currentUrl);
    console.log("breadcrumbsUrl", breadcrumbsUrl);
    if (currentUrl.includes("folders")) {
      this.router.navigate(["workspace", this.workspaceId, "folders"]);
    } else {
      this.router.navigateByUrl(breadcrumbsUrl);
    }
  }

  // Limitation Manager Check
  async limitationManagerCheck(): Promise<void> {
    const featureAction: keyof RolePermissions = "workspace_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.router.navigate(["workspace", this.workspaceId, "manage", "workspace"]);
    } else {
      this.router.navigate(["workspace", this.workspaceId, "manage", "members"]);
    }
    this.isManageWorkspaceActive = true;
  }

  // TODO - Toggle the mobile menu
  toggleMenu() {
    this.mobileMenu = !this.mobileMenu;
  }
}
