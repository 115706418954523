<div class="content-card-wrapper">
  <div class="content-card-header">
    <h3>Inspection Actions</h3>
  </div>
  <div class="content-card px-6 py-5">
    <div class="border-b border-gray-200 pb-2">
      <a [routerLink]="['/', 'workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'report']" class="btn btn-primary"> Generate Report </a>
    </div>
    <div class="grid gap-y-2 pt-2">
      <button type="button" class="btn btn-secondary" (click)="setSaveInspectionAsTemplateOverlay()">Save as Template</button>
      <button type="button" (click)="setDuplicateInspectionOverlay()" class="btn btn-secondary">Duplicate</button>
      <button type="button" (click)="setReinspectionOverlay()" class="btn btn-secondary">Reinspect</button>
      <button type="button" (click)="routeToSupplementaryEditor()" class="btn btn-secondary">Add Internal Notes</button>
      <button type="button" class="btn btn-destructive" (click)="deleteInspectionLimitationManagerCheck()" class="btn btn-secondary">Delete Inspection</button>
    </div>
  </div>
</div>

@if (overlay_deleteInspection) {
  <delete-confirmation [deleteTitle]="deleteOverlayTitle" [deleteMessage]="inspection.title + ' ' + deleteOverlayDescription" (cancelCommandEmitter)="toggleDeleteInspectionOverlay()" (deleteCommandEmitter)="deleteInspection()"></delete-confirmation>
}
@if (duplicationService.overlay_duplicate) {
  <duplicate-overlay
    [duplicationObject]="duplicationObject"
    [originalDocumentTitle]="inspectionDetailService.duplicationType === 'create-template-from-inspection' ? inspection.title : inspection.title + ' ' + '(Copy)'"
    [duplicationTitle]="inspectionDetailService.duplicationTitle"
    [duplicationDescription]="inspectionDetailService.duplicationDescription"
    [inProgressText]="inspectionDetailService.inProgressText"
    [successText]="inspectionDetailService.successText"
    [failedText]="inspectionDetailService.failedText"
    [navigateToText]="inspectionDetailService.navigateToText"
    (duplicateEmitter)="whichDuplicationProcessToRun($event)"
    (navigateToNewDocEmitter)="whichDuplicationRouteToNavigateTo()" />
}
