import { AsyncPipe } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { AuditLog } from "cip";
import type * as firestore from "firebase/firestore";
import { collection, getDocs, getFirestore, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { GetCountFromServerService } from "src/app/core/services/counts/get-count-from-server.service";
import { AuditLogType } from "src/app/core/util/models";
import { AUDIT_FILTERS } from "../../utils/filters";
import { processAuditLog, ProcessedAuditLog } from "../../utils/process-audit-log";
import { AuditLogsListComponent } from "../audit-logs-list/audit-logs-list.component";

@Component({
  selector: "audit-logs-list-wrapper",
  standalone: true,
  imports: [AuditLogsListComponent, AsyncPipe],
  templateUrl: "./audit-logs-list-wrapper.component.html",
  styleUrl: "./audit-logs-list-wrapper.component.scss",
})
export class AuditLogsListWrapperComponent {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
    this.getAllAuditLogs();
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private collectionsService = inject(CollectionsService);
  private getCountFromServerService = inject(GetCountFromServerService);

  // Properties
  public _workspaceId!: string;
  public totalActivities = 0;
  public queriedActivities = 0;
  public sortDropdown = false;
  public auditFilters = AUDIT_FILTERS;
  public selectedAuditFilterType = "All";
  public auditLogsArray: ProcessedAuditLog[] = [];
  private selectedAuditFilterTypeValue: AuditLogType | null = null;
  private lastVisible: ProcessedAuditLog | null = null;

  private get auditLogsCol() {
    const path = this.collectionsService.auditLogsCol(this.workspaceId);
    const firestoreInstance = getFirestore();
    return { ref: collection(firestoreInstance, path) as firestore.CollectionReference<AuditLog>, path };
  }

  /**
   * Get All Audit Logs
   */
  public async getAllAuditLogs() {
    this.resetAuditLogValues();
    this.selectedAuditFilterType = "All Audit Logs";
    const { path: colPath, ref: colRef } = this.auditLogsCol;

    const retrieveCount = async () => {
      return await this.getCountFromServerService.getExportCount(colPath);
    };

    const retrieveAndProcessAuditLogs = async () => {
      const q = query(colRef, orderBy("date", "desc"), limit(20));
      const { docs } = await getDocs(q);
      return docs.map((doc) => processAuditLog({ id: doc.id, ...doc.data() }));
    };

    const [totalCount, processedAuditLogs] = await Promise.all([retrieveCount(), retrieveAndProcessAuditLogs()]);

    this.totalActivities = totalCount;
    this.queriedActivities += processedAuditLogs.length;
    this.auditLogsArray = processedAuditLogs;
    this.lastVisible = processedAuditLogs[processedAuditLogs.length - 1];
  }

  /**
   * Query Next Batch Of Activities
   */
  public async queryNextBatchOfActivities() {
    const { ref: colRef } = this.auditLogsCol;
    if (!this.lastVisible) return;

    const retrieveAndProcessAuditLogs = async () => {
      let q;

      if (this.selectedAuditFilterTypeValue) {
        q = query(colRef, where("info.type", "==", this.selectedAuditFilterTypeValue), orderBy("date", "desc"), startAfter(this.lastVisible?.date), limit(20));
      } else {
        q = query(colRef, orderBy("date", "desc"), startAfter(this.lastVisible?.date), limit(20));
      }

      const { docs } = await getDocs(q);
      return docs.map((doc) => processAuditLog({ id: doc.id, ...doc.data() }));
    };

    const processedAuditLogs = await retrieveAndProcessAuditLogs();

    // Only update if we got new documents
    if (processedAuditLogs.length > 0) {
      this.auditLogsArray = [...this.auditLogsArray, ...processedAuditLogs];
      this.queriedActivities += processedAuditLogs.length;
      this.lastVisible = processedAuditLogs[processedAuditLogs.length - 1];
    }
  }
  /**
   * Query Audit Logs With Type
   * @param event
   */
  public async queryAuditLogsWithType(event: { filterType: AuditLogType; filterTitle: string }) {
    const { path: colPath, ref: colRef } = this.auditLogsCol;

    this.selectedAuditFilterType = event.filterTitle;
    this.selectedAuditFilterTypeValue = event.filterType;
    this.resetAuditLogValues();
    this.toggleSortDropdown();

    const retrieveCount = async () => {
      return await this.getCountFromServerService.getAuditCountBasedOnType(colPath, event.filterType);
    };

    const retrieveAndProcessAuditLogs = async () => {
      const q = query(
        colRef,
        where("info.type", "==", event.filterType),
        orderBy("date", "desc"), // Make sure this matches the pagination query
        limit(20)
      );
      const { docs } = await getDocs(q);
      return docs.map((doc) => processAuditLog({ id: doc.id, ...doc.data() }));
    };

    const [totalCount, processedAuditLogs] = await Promise.all([retrieveCount(), retrieveAndProcessAuditLogs()]);

    this.totalActivities = totalCount;
    this.queriedActivities += processedAuditLogs.length;
    this.auditLogsArray = processedAuditLogs;
    this.lastVisible = processedAuditLogs[processedAuditLogs.length - 1];
  }

  private resetAuditLogValues() {
    this.auditLogsArray = [];
    this.queriedActivities = 0;
  }

  public toggleSortDropdown() {
    this.sortDropdown = !this.sortDropdown;
  }

  public closeSortDropdown() {
    this.sortDropdown = false;
  }
}
