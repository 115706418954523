<div class="flex h-full flex-1 flex-col overflow-hidden">
  <div class="w-full flex-1 space-y-4 overflow-y-scroll overscroll-contain px-6 pb-6">
    @if (members) {
      <div class="mb-14 mt-4 flex w-full flex-col px-6 sm:flex-row">
        <search-bar [placeholder]="'Search Members'" (searchTermChange)="onSearch($event)" />
      </div>

      @if (currentlySelectedMember) {
        <div>
          <div class="mb-10 block">
            <div class="mb-3 flex items-center justify-between">
              <div class="container-header" style="padding-bottom: 0">Transfer to Selected Member</div>
            </div>
            <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
              <div class="mb-4">
                <div class="list-row">
                  <div class="list-a border-none">
                    <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                      <div class="min-w-0 flex-auto">
                        <p class="list-title">{{ currentlySelectedMember.name_first }} {{ currentlySelectedMember.name_last }}</p>
                        <p class="list-meta">{{ currentlySelectedMember.email }}</p>
                        <p class="list-meta">{{ currentlySelectedMember.job_title }}</p>
                        <p class="list-meta">{{ currentlySelectedMember.role_title }}</p>
                      </div>
                    </div>
                    <div class="flex items-center text-brand_Primary">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                          clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <ul id="membersList">
        @if (members.length > 0) {
          <div class="mb-3 flex items-center justify-between">
            <div class="container-header" style="padding-bottom: 0">Choose new Owner</div>
          </div>
        }

        @if (members.length > 0) {
          <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
            @for (member of filteredMembers; track $index; let last = $last) {
              @if (workspaceForm.value.current_owner_id !== member.id && currentlySelectedMember?.id !== member.id) {
                <li [class.hidden]="member.hidden" [ngClass]="last ? 'border-none' : 'border-b'">
                  <div class="list-row">
                    <div class="list-a group cursor-pointer" (click)="memberSelection(member)">
                      <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                        <div class="min-w-0 flex-auto">
                          <p class="list-title">{{ member.name_first }} {{ member.name_last }}</p>
                          <p class="list-meta">{{ member.email }}</p>
                          <p class="list-meta">{{ member.job_title }}</p>
                          <p class="list-meta">{{ member.role_title }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              }
            }
          </div>
        }
      </ul>

      @if (allMembersHidden) {
        <search-empty [searchTerm]="searchTerm" />
      }

      <!-- <ng-container *ngIf="emptySearchResultsService.noResults">
        <empty-search-results></empty-search-results>
      </ng-container> -->
    }
  </div>

  <div class="m-0 flex justify-end bg-gray-100 px-6 py-6">
    <button type="button" class="btn btn-cancel" (click)="toggleNewOwnerPanel()">Cancel</button>
  </div>
</div>
