import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ReportsCsvService {
  public csvHeaders = [
    { value: "assignee_name", width: null },
    { value: "category_title", width: null },
    { value: "client_name", width: null },
    { value: "created_by", width: null },
    { value: "current_owner", width: null },
    { value: "folder_title", width: null },
    { value: "inspection_title", width: null },
    { value: "item_title", width: null },
    { value: "name", width: null },
    { value: "name_first", width: null },
    { value: "name_last", width: null },
    { value: "site_title", width: null },
    { value: "template_title", width: null },
    { value: "title", width: null },
    { value: "date", width: null },
    { value: "date_created", width: null },
    { value: "date_signed", width: null },
    { value: "date_uploaded", width: null },
    { value: "due_date", width: null },
    { value: "question_date", width: null },
    { value: "question_time", width: null },
    { value: "timestamp", width: null },
    { value: "annotation_thumbnail_url", width: "620px" },
    { value: "annotation_url", width: "620px" },
    { value: "image_thumbnail_url", width: "620px" },
    { value: "image_url", width: "620px" },
    { value: "map_url", width: "620px" },
    { value: "site_photo_thumbnail_url", width: "620px" },
    { value: "assignee_id", width: null },
    { value: "category_id", width: null },
    { value: "client_id", width: null },
    { value: "created_by_id", width: null },
    { value: "current_owner_id", width: null },
    { value: "folder_id", width: null },
    { value: "id", width: null },
    { value: "inspection_id", width: null },
    { value: "item_id", width: null },
    { value: "site_id", width: null },
    { value: "template_id", width: null },
    { value: "user_id", width: null },
    { value: "workspace_users", width: null },
    { value: "actions_count", width: null },
    { value: "items_completed_count", width: null },
    { value: "items_count", width: null },
    { value: "photos_count", width: null },
    { value: "complete", width: null },
    { value: "enabled", width: null },
    { value: "question_bool", width: null },
    { value: "latitude", width: null },
    { value: "longitude", width: null },
    { value: "order", width: null },
    { value: "overall_score", width: null },
    { value: "priority", width: null },
    { value: "question_number", width: null },
    { value: "address", width: "620px" },
    { value: "answer_type", width: null },
    { value: "answered", width: null },
    { value: "assignee_email", width: null },
    { value: "assignee_type", width: null },
    { value: "branch", width: null },
    { value: "company", width: null },
    { value: "content", width: null },
    { value: "email", width: null },
    { value: "job_title", width: null },
    { value: "mode", width: null },
    { value: "notes", width: "620px" },
    { value: "pending_uploads", width: null },
    { value: "phone", width: null },
    { value: "question_string", width: null },
    { value: "ref", width: null },
    { value: "test_answer", width: null },
    { value: "type", width: null },
    { value: "website", width: null },
    { value: "what_3_words", width: null },
  ];
}
