import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { BehaviorSubject, filter, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { ClientEnhanced } from "src/app/models/client/client.model";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ManageClientNewOverlayComponent } from "../../edit/manage-client-new-overlay/manage-client-new-overlay.component";
import { ClientDetailService } from "../../services/client-detail.service";
import { ClientsListService } from "../../services/clients-list.service";
import { ManageClientsListHeaderComponent } from "../manage-clients-list-header/manage-clients-list-header.component";
import { ManageClientsListComponent } from "../manage-clients-list/manage-clients-list.component";

@Component({
  selector: "manage-clients-list-wrapper",
  standalone: true,
  imports: [ManageClientsListHeaderComponent, ManageClientsListComponent, EmptyListComponent, AsyncPipe, LoadingSpinnerComponent, DeleteOverlayComponent, ManageClientNewOverlayComponent],
  templateUrl: "./manage-clients-list-wrapper.component.html",
  styleUrl: "./manage-clients-list-wrapper.component.scss",
})
export class ManageClientsListWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private clientsListService = inject(ClientsListService);
  private clientDetailService = inject(ClientDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  // Properties
  private _workspaceId!: string;
  private user: User;
  public clients$!: Observable<ClientEnhanced[]>;
  public editMode: boolean = false;
  public currentSearchTerm: string = "";
  public clientsBulkEditArray$: BehaviorSubject<ClientEnhanced[]> = new BehaviorSubject<ClientEnhanced[]>([]);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  public clientToBeDeleted: string = "";
  public overlay_deleteClient = signal<boolean>(false);
  public overlay_newClient: boolean = false;
  public clientForm: FormGroup;
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos = false;
  private tempBlobPhotoURL!: string;
  public newclientId!: string;

  constructor() {
    this.user = this.authService.currentUser;
    this.clientForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      branch: ["", this.formFormattingService.noWhitespaceValidator()],
      image_url: null,
      image_thumbnail_url: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getClientsList();
  }

  /**
   * Get Clients List
   */
  private async getClientsList(): Promise<void> {
    this.clients$ = this.clientsListService.getClientsList$(this.workspaceId).pipe(filter((data) => !!data));
  }

  /**
   * Save Client
   */
  public async saveClient(): Promise<void> {
    try {
      await this.clientDetailService.saveClientDoc(this.workspaceId, "new", this.clientForm.value, "added", this.user);
      this.toggleNewClientOverlay();
      this.resetForm();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Update Search Term
   * @param term
   */
  public searchTermChange(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   * Set Delete Batch Clients Properties
   */
  public async setDeleteBatchClientsProperties(): Promise<void> {
    const featureAction: keyof RolePermissions = "client_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      if (this.clientsBulkEditArray$.getValue().length > 0) {
        const deleteClients = this.stringsService.alertFilter(AlertType.DeleteClients);
        if (deleteClients) {
          this.deleteOverlayTitle = deleteClients.title;
          this.deleteOverlayDescription = deleteClients.description;
          this.toggleDeleteClientOverlay(true);
        }
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Limitation Manager Check
   */
  public async limitationManagerCheck(featureAction: keyof RolePermissions): Promise<boolean> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    return limitationResult;
  }

  /**
   * Set Delete Single Client Properties
   * @param clientId
   */
  public async setDeleteSingleClientProperties(clientId: string): Promise<void> {
    const featureAction: keyof RolePermissions = "client_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      const deleteClient = this.stringsService.alertFilter(AlertType.DeleteClient);
      if (deleteClient) {
        this.deleteOverlayTitle = deleteClient.title;
        this.deleteOverlayDescription = deleteClient.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteClient);
      }
      this.clientToBeDeleted = clientId;
      this.toggleDeleteClientOverlay(true);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Single Or Multiple Clients
   */
  public deleteSingleOrMultipleClients(): void {
    if (this.clientsBulkEditArray$.getValue().length > 0) {
      this.deleteBatchedClients();
    } else {
      this.deleteSingleClient();
    }
  }

  /**
   * Delete Single Client
   * @param clientId
   */
  public async deleteSingleClient(): Promise<void> {
    try {
      await this.clientDetailService.deleteClientDoc(this.workspaceId, this.clientToBeDeleted, this.user);
      this.toggleDeleteClientOverlay(false);
      this.toggleEditMode();
      this.resetForm();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Batched Clients
   */
  private async deleteBatchedClients(): Promise<void> {
    try {
      await this.clientDetailService.deleteBatchedClients(this.workspaceId, this.clientsBulkEditArray$.value, this.user);
      this.toggleDeleteClientOverlay(false);
      this.toggleEditMode();
      this.resetForm();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Reset Form
   */
  private resetForm(): void {
    this.clientForm.markAsPristine();
    this.clientForm.reset();
  }

  /**
   * Create Batch Edit
   * @param client
   */
  public createBatchEdit(client: ClientEnhanced): void {
    const currentArray = this.clientsBulkEditArray$.getValue();
    if (currentArray.some((e: ClientEnhanced) => e.id === client.id)) {
      this.removeFromBatchArray(client);
    } else {
      this.addToBatchArray(client);
    }
  }

  /**
   * Add To Batch Array
   * @param client
   */
  private addToBatchArray(client: ClientEnhanced): void {
    const updatedArray = [...this.clientsBulkEditArray$.getValue(), client];
    this.clientsBulkEditArray$.next(updatedArray);
  }

  /**
   * Remove From Batch Array
   * @param client
   */
  private removeFromBatchArray(client: ClientEnhanced): void {
    const updatedArray = this.clientsBulkEditArray$.getValue().filter((e) => e.id !== client.id);
    this.clientsBulkEditArray$.next(updatedArray);
  }

  /**
   * Route To Client Editor
   */
  public async routeToClientEditor(clientId: string): Promise<void> {
    const featureAction: keyof RolePermissions = "client_create_update";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "clients", clientId]);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split("/").pop();
    if (!(fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png")) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.togglePhotoPreview();
      this.clientForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * Remove Image From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.clientForm.patchValue({
      image_url: photos.image_url,
      image_thumbnail_url: photos.image_thumbnail_url,
    });
    try {
      await this.saveClient();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Does A Photo Need Uploading
   */
  public doesAPhotoNeedUploading(): void {
    if (this.uploadFileArray.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.saveClient();
    }
  }

  /**
   * Toggle Photos Upload Overlay
   */
  public togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Toggle Photos Upload Overlay
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.clientsBulkEditArray$.next([]);
  }

  /**
   * Toggle Delete Client Overlay
   * @param value
   */
  public toggleDeleteClientOverlay(value: boolean): void {
    this.overlay_deleteClient.set(value);
  }

  /**
   * Can User Create Client
   */
  public async canUserCreateClient(): Promise<void> {
    const featureAction: keyof RolePermissions = "client_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleNewClientOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle New Client Overlay
   */
  public async toggleNewClientOverlay(): Promise<void> {
    if (!this.overlay_newClient) {
      this.newclientId = this.firestoreUtilsService.createFirestoreId();
    }
    this.overlay_newClient = !this.overlay_newClient;
  }
}
