import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
  selector: "current-workspace-menu",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./current-workspace-menu.component.html",
  styleUrl: "./current-workspace-menu.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentWorkspaceMenuComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) limitationResult!: boolean;
  @Input({ required: true }) memberRole!: string;
}
