import { inject, Injectable } from "@angular/core";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { RoleDefault, RoleEnhanced } from "src/app/models/role/role.model";

@Injectable({
  providedIn: "root",
})
export class RolesListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  // Properties
  public defaultRoles: RoleDefault[] = [
    {
      id: "Owner",
      title: "Owner",
      description: "There can only be one Owner of a Workspace",
      type: "default",
      permissions: {
        action_create_update: true,
        action_delete: true,
        assignee_create_update: true,
        assignee_delete: true,
        category_create_update: true,
        category_delete: true,
        client_create_update: true,
        client_delete: true,
        folder_create_update: true,
        folder_delete: true,
        inspection_create_update: true,
        inspection_delete: true,
        invite_create_update: true,
        invite_delete: true,
        item_create_update: true,
        item_delete: true,
        member_update: true,
        member_delete: true,
        role_create_update: true,
        role_delete: true,
        photo_create_update: true,
        photo_delete: true,
        signature_create_update: true,
        signature_delete: true,
        site_create_update: true,
        site_delete: true,
        template_create_update: true,
        template_delete: true,
        theme_create_update: true,
        theme_delete: true,
        workspace_update: true,
      },
    },
    {
      id: "Admin",
      title: "Admin",
      description: "Admins can do everything other than delete a Workspace",
      type: "default",
      permissions: {
        action_create_update: true,
        action_delete: true,
        assignee_create_update: true,
        assignee_delete: true,
        category_create_update: true,
        category_delete: true,
        client_create_update: true,
        client_delete: true,
        folder_create_update: true,
        folder_delete: true,
        inspection_create_update: true,
        inspection_delete: true,
        invite_create_update: true,
        invite_delete: true,
        item_create_update: true,
        item_delete: true,
        member_update: true,
        member_delete: true,
        photo_create_update: true,
        photo_delete: true,
        role_create_update: true,
        role_delete: true,
        signature_create_update: true,
        signature_delete: true,
        site_create_update: true,
        site_delete: true,
        template_create_update: true,
        template_delete: true,
        theme_create_update: true,
        theme_delete: true,
        workspace_update: true,
      },
    },
    {
      id: "Inspector",
      title: "Inspector",
      description: "Create and Edit Inspections",
      type: "default",
      permissions: {
        action_create_update: true,
        action_delete: true,
        assignee_create_update: true,
        assignee_delete: true,
        category_create_update: true,
        category_delete: true,
        client_create_update: true,
        client_delete: false,
        folder_create_update: true,
        folder_delete: false,
        inspection_create_update: true,
        inspection_delete: false,
        invite_create_update: false,
        invite_delete: false,
        item_create_update: true,
        item_delete: true,
        member_update: false,
        member_delete: false,
        photo_create_update: true,
        photo_delete: true,
        role_create_update: false,
        role_delete: false,
        signature_create_update: true,
        signature_delete: true,
        site_create_update: true,
        site_delete: false,
        template_create_update: false,
        template_delete: false,
        theme_create_update: true,
        theme_delete: true,
        workspace_update: true,
      },
    },
  ];

  /**
   * Get Roles List
   * @param workspaceId
   */
  public getRolesList$(workspaceId: string): Observable<RoleEnhanced[]> {
    const path = this.collectionsService.rolesCol(workspaceId);
    const queryConstraints = [where("is_deleted", "==", false), orderBy("title", "asc")];
    return this.firestoreUtilsService.getCollectionData<RoleEnhanced>(path, queryConstraints);
  }
}
