import { AsyncPipe } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { combineLatest, filter, Observable } from "rxjs";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { RoleCurrent, RoleEnhanced } from "src/app/models/role/role.model";
import { PermissionsGridComponent } from "src/app/shared/permissions/permissions-grid/permissions-grid.component";
import { InvitesListService } from "../../../manage-members/services/invites-list.service";
import { MembersListService } from "../../../manage-members/services/members-list.service";
import { RolesListService } from "../../services/roles-list.service";
import { ManageRoleAssignedInvitesComponent } from "../manage-role-assigned-invites/manage-role-assigned-invites.component";
import { ManageRoleAssignedMembersComponent } from "../manage-role-assigned-members/manage-role-assigned-members.component";

@Component({
  selector: "manage-role-default-viewer",
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, PermissionsGridComponent, AsyncPipe, ManageRoleAssignedInvitesComponent, ManageRoleAssignedMembersComponent],
  templateUrl: "./manage-role-default-viewer.component.html",
  styleUrl: "./manage-role-default-viewer.component.scss",
})
export class ManageRoleDefaultViewerComponent {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set roleId(value: string) {
    this._roleId = value;
    this.setDefaultRole(value);
  }
  get roleId(): string {
    return this._roleId;
  }

  // Services
  private fb = inject(FormBuilder);
  private membersListService = inject(MembersListService);
  private invitesListService = inject(InvitesListService);
  private rolesListService = inject(RolesListService);
  private infoViewStringsService = inject(InfoViewStringsService);

  // Properties
  public roleForm: FormGroup;
  private _workspaceId!: string;
  private _roleId!: string;
  private members$!: Observable<MemberEnhanced[]>;
  private invites$!: Observable<InviteEnhanced[]>;
  public currentRole: RoleCurrent | null = null;
  public customRoles: RoleEnhanced[] = [];
  public combinedData$!: Observable<{ members: MemberEnhanced[]; invites: InviteEnhanced[] }>;

  constructor() {
    this.roleForm = this.fb.group({
      id: [{ value: null, disabled: null }],
      title: null,
      description: "",
      type: null,
      permissions: this.fb.group({
        action_create_update: false,
        action_delete: false,
        assignee_create_update: false,
        assignee_delete: false,
        category_create_update: false,
        category_delete: false,
        client_create_update: false,
        client_delete: false,
        folder_create_update: false,
        folder_delete: false,
        inspection_create_update: false,
        inspection_delete: false,
        invite_create_update: false,
        invite_delete: false,
        item_create_update: false,
        item_delete: false,
        member_update: false,
        member_delete: false,
        photo_create_update: false,
        photo_delete: false,
        role_create_update: false,
        role_delete: false,
        signature_create_update: false,
        signature_delete: false,
        site_create_update: false,
        site_delete: false,
        template_create_update: false,
        template_delete: false,
        theme_create_update: false,
        theme_delete: false,
        workspace_update: false,
      }),
    });
  }

  ngOnInit(): void {
    this.initializeCombinedData();
  }

  /**
   * Get Members and Invites
   */
  private async initializeCombinedData(): Promise<void> {
    this.members$ = this.membersListService.getAssignedMembers$(this.workspaceId, this.roleId).pipe(filter((data) => !!data));
    this.invites$ = this.invitesListService.getWorkspaceInvitesByRole$(this.workspaceId, this.roleId).pipe(filter((data) => !!data));
    this.combinedData$ = combineLatest({
      members: this.members$,
      invites: this.invites$,
    });
  }

  /**
   * get Default Role
   * @param role
   */
  setDefaultRole(role: string): void {
    const selectedRole = this.getCurrentRole(role);
    if (selectedRole) {
      this.roleForm.patchValue(selectedRole);
    } else {
      console.error(`Role with ID ${role} not found.`);
    }
  }

  /**
   * Get Current Role
   * @param roleId
   */
  getCurrentRole(roleId: string): RoleCurrent | null {
    const allRoles = this.rolesListService.defaultRoles;
    const role = allRoles.find((obj) => obj.id === roleId);
    return role ?? null;
  }
}
