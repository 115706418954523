import { DatePipe, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InspectionEnhanced } from 'src/app/models/inspection/inspection.model';
import { SearchEmptyComponent } from 'src/app/shared/search-empty/search-empty.component';

@Component({
  selector: 'inspections-list',
  standalone: true,
  imports: [SearchEmptyComponent, RouterModule, NgStyle, DatePipe],
  templateUrl: './inspections-list.component.html',
  styleUrl: './inspections-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionsListComponent {
  @Input()
  set inspections(value: InspectionEnhanced[]) {
    this._inspections = value;
    this.updateFilteredinspectionsForSearchResults();
  }

  get inspections(): InspectionEnhanced[] {
    return this._inspections;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredinspectionsForSearchResults();
  }

  @Input() set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input() set folderId(value: string) {
    this._folderId = value;
  }
  get folderId(): string {
    return this._folderId;
  }

  private _inspections: InspectionEnhanced[] = [];
  public _workspaceId!: string;
  public _folderId!: string;
  public _searchTerm: string = '';
  public filteredInspections: InspectionEnhanced[] = [];
  public allInspectionsHidden: boolean = false;

  /**
   * Update Filtered inspections
   */
  updateFilteredinspectionsForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredInspections = this.inspections.map((inspection) => ({
      ...inspection,
      hidden: !inspection.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allInspectionsHidden = this.filteredInspections.every((inspection) => inspection.hidden);
  }
}
