import { NgStyle, NgTemplateOutlet } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { DateFormatPipe } from "src/app/core/pipes/date-format.pipe";
import { ActionsListService } from "src/app/sections/actions/services/actions-list.service";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-content-actions",
  standalone: true,
  imports: [NgTemplateOutlet, NgStyle, DateFormatPipe],
  templateUrl: "./report-content-actions.component.html",
  styleUrl: "./report-content-actions.component.scss",
})
export class ReportContentActionsComponent {
  @Input({ required: true }) itemId!: string;

  // Services
  public actionsListService = inject(ActionsListService);
  public reportsService = inject(ReportsService);
}
