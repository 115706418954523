@if (limitationManagerService.billingDoc; as subscription) {
  <header>
    <!-- Dummy breadcrumb element-->
    <div class="h-[28px] px-6 pb-2"></div>
    <!-- Header -->
    <div class="page-header-wrapper">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="page-header">Manage Subscription</h2>
        </div>
      </div>
    </div>
  </header>
  <!-- Content -->
  <div class="grid grid-cols-1 gap-y-8">
    <!-- Details -->
    <div class="content-card-wrapper">
      <div class="content-card">
        <div class="content-grid grid-cols-2 gap-x-4 gap-y-6">
          <div class="col-span-2">
            <info-view [type]="infoViewSubscription.type" [message]="infoViewSubscription.message" [clickable]="true" [link]="'https://support.apple.com/en-gb/118428'" />
          </div>
          <div>
            <div class="text-sm text-text_Subtitle">Billing</div>
            <div class="text-base text-text_Title">{{ subscription.status }}</div>
          </div>
          <div>
            <div class="text-sm text-text_Subtitle">Store</div>
            <div class="text-base text-text_Title">{{ subscription.store }}</div>
          </div>
          <div>
            <div class="text-sm text-text_Subtitle">Sign up date</div>
            <div class="text-base text-text_Title">{{ subscription.date_subscribed?.toDate() | date: "dd MMM yyyy" }}</div>
          </div>
          <div>
            <div class="text-sm text-text_Subtitle">Renews</div>
            <div class="text-base text-text_Title">{{ subscription.date_renews?.toDate() | date: "dd MMM yyyy" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
