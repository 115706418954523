<div id="cover-wrapper">
  @if (themeSettingsForm.value.styles_cover === "Cover1") {
    <div class="cover-left" [ngStyle]="{ 'background-color': themeSettingsForm.value.colours_brand }">
      <img src="../../../../../../../../assets/images/theme-preview/cover-style-01.png" />
    </div>
  }
  <div class="cover-right" [ngStyle]="{ 'background-image': themeSettingsForm.value.styles_cover === 'Cover3' ? 'url(\'../../../../../../../../assets/images/theme-preview/cover-style-03.png\')' : '' }">
    <div class="cover-top">
      @if (themeSettingsForm.value.details_workspace) {
        <div class="cover-workspace-wrapper">
          <div class="cover-workspace-name" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Workspace Name</div>
          <div class="cover-workspace-logo">
            <img src="../../../../../../../../assets/images/theme-preview/veamlogo.svg" />
          </div>
        </div>
      }
      <div class="cover-inspection-wrapper">
        <p class="cover-inspection-title" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">Inspection Title</p>
        <div [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">
          <p class="cover-inspection-reference">Inspection Reference</p>
          <p class="cover-inspection-date">{{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}</p>
          <p class="cover-inspection-site">Inspection Site Title</p>
          @if (themeSettingsForm.value.details_overall_score) {
            <p class="cover-inspection-score">Inspection Score</p>
          }
        </div>
      </div>
      @if (themeSettingsForm.value.details_action) {
        <div class="cover-action-wrapper">
          <p class="cover-action-count" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">45 Actions</p>

          <div class="cover-action-complete text-group-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path
                [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }"
                d="M15.9409 8.22857C15.9409 12.4891 12.4871 15.9429 8.22662 15.9429C3.96614 15.9429 0.512332 12.4891 0.512332 8.22857C0.512332 3.96809 3.96614 0.514286 8.22662 0.514286C12.4871 0.514286 15.9409 3.96809 15.9409 8.22857Z"
                stroke-width="1.02857" />
              <path [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }" d="M4.79814 8.34429L7.10023 10.6466L11.5758 6.17139" stroke-width="1.32888" />
            </svg>
            <p [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">34 Complete</p>
          </div>
          <div class="cover-action-incomplete text-group-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path
                d="M15.9418 8.22857C15.9418 12.4891 12.488 15.9429 8.22747 15.9429C3.96699 15.9429 0.513187 12.4891 0.513187 8.22857C0.513187 3.96809 3.96699 0.514286 8.22747 0.514286C12.488 0.514286 15.9418 3.96809 15.9418 8.22857Z"
                [ngStyle]="{ stroke: themeSettingsForm.value.colours_subtitle }"
                stroke-width="1.02857" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.04168 8.1596L11.5266 5.61472L10.6928 4.80054L8.22726 7.32553L5.76204 4.80083L4.92821 5.61501L7.41285 8.1596L4.79639 10.8392L5.63021 11.6534L8.22726 8.99366L10.8246 11.6537L11.6584 10.8395L9.04168 8.1596Z"
                [ngStyle]="{ fill: themeSettingsForm.value.colours_subtitle }" />
            </svg>
            <p [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">11 Incomplete</p>
          </div>
        </div>
      }
    </div>
    <div class="cover-bottom">
      @if (themeSettingsForm.value.details_client) {
        <div class="cover-client-wrapper">
          <div class="cover-client-logo">
            <img src="../../../../../../../../assets/images/theme-preview/veamlogo.svg" />
          </div>
          <div class="cover-client-inner">
            <p class="cover-client-name" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Client Name</p>
            <p class="cover-client-branch" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Client Branch</p>
          </div>
        </div>
      }
      @if (themeSettingsForm.value.details_user) {
        <div class="cover-user-wrapper">
          <p class="cover-user-name" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Inspected By User Name</p>
        </div>
      }
    </div>
  </div>
</div>
