<div class="content-card-wrapper">
  <div class="content-card-header">
    <h3>Inspection Stats</h3>
  </div>
  <div class="px-6 py-5">
    <div class="flex w-full flex-row space-x-6 border-b border-gray-200">
      @for (detail of detailChoices; track $index) {
        <div
          class="cursor-pointer pb-2 text-center text-sm capitalize hover:text-brand_Primary dark:text-slate-300"
          [ngClass]="selectedDetail === detail ? 'border-b-2 border-brand_Primary text-brand_Primary dark:border-sky-500 dark:text-sky-500' : 'text-gray-500'"
          (click)="setSelectedDetail(detail)"
        >
          {{ detail }}
        </div>
      }
    </div>

    <div class="pt-5">
      @switch (selectedDetail) {
        @case ('score distribution') {
          <inspection-overview-stats-scores [chartsResults]="chartsResults" [totalTests]="totalTests" />
        }
        @case ('assignees') {
          <inspection-overview-stats-assignees [chartsAssignees]="chartsAssignees" [totalActions]="totalActions" [assigneeHighestActionCount]="assigneeHighestActionCount" />
        }
        @case ('actions') {
          <inspection-overview-stats-actions [actions]="actionResults" [totalActions]="totalActions" />
        }
      }
    </div>
  </div>
</div>
