<div>
  <div class="mb-5 px-8">
    <search-bar [placeholder]="'Search Members'" (searchTermChange)="onSearch($event)" />
  </div>
  <div class="grid grid-cols-1 gap-y-4">
    <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
      <!-- List Component -->
      <ul id="membersList" role="list" class="list-ul">
        @for (member of filteredMembers; track $index) {
          <li [class.hidden]="member.hidden">
            <div class="list-row">
              <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'members', member.id]" class="list-a group">
                <!-- Left and Right Content -->

                @if (user.user_id === member.id) {
                  <div class="flex flex-row text-text_Title">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-2.5 h-6 w-6 sm:ml-0">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </div>
                }

                <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                  <!-- Left Content -->
                  <div class="min-w-0 flex-auto">
                    <p class="list-title">{{ member.name_first }} {{ member.name_last }}</p>
                    <p class="list-meta">{{ member.email }}</p>
                    @if (member.status !== "activated") {
                      <p class="text-sm capitalize italic leading-5 text-brand_Destructive">{{ member.status }}</p>
                    }
                  </div>

                  <!-- Right Content -->
                  <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                    <div class="sm:flex sm:flex-col sm:items-end">
                      <p class="list-meta">{{ member.job_title }}</p>
                      @if (member.status === "activated") {
                        <p class="list-meta">{{ member.role_title }}</p>
                      }
                    </div>
                  </div>
                </div>

                <!-- Right Icon -->
                <div class="flex">
                  <button type="button" class="list-icon" aria-expanded="false" aria-haspopup="true">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                  </button>
                </div>
              </a>
            </div>
          </li>
        }
      </ul>

      @if (allMembersHidden) {
        <search-empty [searchTerm]="searchTerm" />
      }
    </div>
  </div>
</div>
