<div class="px-4">
  <div class="border-b border-gray-200 py-6">
    <button type="button" class="btn btn-primary w-full" (click)="setCSVGenerationChoice()">Generate CSV Report</button>
  </div>
</div>

@if (reportsService.overlay_csvConfirmation) {
  <div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex min-h-screen items-center justify-center text-center">
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
      <div class="inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:max-w-[625px] sm:align-middle">
        <div class="sm:flex sm:items-start">
          <div class="flex min-h-[166px] w-full flex-col space-y-4 overscroll-contain">
            <div class="flex flex-row items-center px-6 pt-6">
              <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">Generate CSV</h3>
            </div>

            @if (!reportsService.csvDownloadURL) {
              <div class="my-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <div class="col-span-6 flex justify-center">
                  <svg class="-ml-1 h-10 w-10 animate-spin text-brand_Primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
                <div class="col-span-6 flex justify-center">
                  <p class="text-sm text-text_Subtitle">Generating...</p>
                </div>
              </div>
            }

            @if (reportsService.csvDownloadURL) {
              <div class="my-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <div class="col-span-6 flex justify-center">
                  <div class="flex h-10 w-10 items-center justify-center rounded-full bg-brand_Primary text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                </div>
                <div class="col-span-6 flex justify-center">
                  <p class="text-sm text-text_Subtitle">Complete</p>
                </div>
              </div>
            }
          </div>
        </div>

        <div class="bg-gray-50 p-6 sm:flex sm:flex-row-reverse">
          @if (!reportsService.csvDownloadURL) {
            <button type="button" class="btn btn-disabled ml-3 cursor-not-allowed">Download CSV</button>
          }
          @if (reportsService.csvDownloadURL) {
            <a [href]="reportsService.csvDownloadURL" type="button" class="btn btn-primary ml-3" (click)="resetCSVDownload()">Download CSV</a>
          }
          <button type="button" class="btn btn-cancel" (click)="toggleCsvConfirmation()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
}
