@if (combinedData$ | async; as combinedData) {
  <manage-members-edit-invite-header [workspaceId]="workspaceId" [inviteId]="inviteId" />

  <div class="grid grid-cols-1 gap-y-8">
    <!-- Form State Top -->
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="inviteForm.pristine"></form-state>
      <button type="button" (click)="saveInvite()" type="button" aria-label="Save or Send Invite" class="btn" [disabled]="inviteForm.pristine || !inviteForm.valid" [ngClass]="inviteForm.pristine || !inviteForm.valid ? 'btn-disabled' : 'btn-primary'">
        {{ inviteId === "new" ? "Send Email Invite" : "Save Invite" }}
      </button>
    </div>

    <info-view [type]="infoViewInviteMember.type" [message]="infoViewInviteMember.message" />

    <!-- Details -->
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Details</h3>
      </div>
      <div class="content-card">
        <div class="content-grid gap-y-6">
          <manage-members-edit-invite-details [inviteForm]="inviteForm" [inviteId]="inviteId" />
        </div>
      </div>
    </div>

    <!-- Role -->
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Role</h3>
      </div>
      <div class="content-card">
        <div class="content-grid gap-y-6">
          <role-field [currentRole]="currentRole" [memberDoc]="memberDoc" [infoViewMemberEditor]="infoViewMemberEditor" [form]="inviteForm" (toggleRoleSelectorOutput)="toggleRoleSelectorOverlay()" />
        </div>
      </div>
    </div>

    <!-- Permissions -->
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Permissions</h3>
      </div>
      <div class="content-card">
        <div class="content-grid gap-y-6">
          <permissions-grid [roleForm]="inviteForm" [isDisabled]="true" [infoViewPermissionsEditor]="infoViewPermissionsEditor" />
        </div>
      </div>
    </div>

    <!-- Form State Bottom -->
    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      @if (inviteId !== "new") {
        <button type="button" class="btn btn-destructive mr-3" type="button" aria-label="Revoke Invite" (click)="canUserRevokeInvite()">Revoke Invite</button>
      }
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="inviteForm.pristine"></form-state>
        <button
          type="button"
          (click)="saveInvite()"
          class="btn"
          type="button"
          aria-label="Save or Send Invite"
          [disabled]="inviteForm.pristine || inviteForm.invalid"
          [ngClass]="inviteForm.pristine || inviteForm.invalid ? 'btn-disabled' : 'btn-primary'">
          {{ inviteId === "new" ? "Send Email Invite" : "Save Invite" }}
        </button>
      </div>
    </div>
  </div>
}

@if (overlay_roleSelector) {
  <role-selector-wrapper
    [defaultRoles]="defaultRoles"
    [customRoles]="customRoles"
    [currentRole]="currentRole"
    [infoViewPermissionsEditor]="infoViewPermissionsEditor"
    [overlay_roleForm]="overlay_roleForm"
    [roleForm]="roleForm"
    (toggleRoleSelectorOverlayOutput)="toggleRoleSelectorOverlay()"
    (selectRoleOutput)="selectRole($event)"
    (toggleRoleFormOverlayOutput)="toggleRoleFormOverlay()"
    (saveRoleOutput)="saveRole()" />
}

@if (overlay_revokeInvite()) {
  <delete-overlay [deleteOverlayButton]="'Revoke'" [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleRevokeInviteOverlay(false)" (deleteButtonClicked)="revokeInvite()" />
}
