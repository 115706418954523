<div class="px-6 pb-2">
  <nav aria-label="Breadcrumb">
    <ol role="list" class="flex items-center">
      @if (folderId) {
        <li>
          <div class="flex items-center">
            <a [routerLink]="['/workspace', workspaceId, 'folders']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Folders</a>
          </div>
        </li>
      }
      @if (inspectionId) {
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'folders', folderId, 'inspections']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Inspections</a>
          </div>
        </li>
      }
      @if (inspectionId && !overview) {
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'overview']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Inspection Overview</a>
          </div>
        </li>
      }
      @if (signatureId) {
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'signoff']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Signatures</a>
          </div>
        </li>
      }
      @if (categoryId) {
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'categories']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Categories</a>
          </div>
        </li>
      }
      @if (itemId) {
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'categories', categoryId, 'items']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Items</a>
          </div>
        </li>
      }
      @if (photoId) {
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'categories', categoryId, 'items', itemId]" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Test</a>
          </div>
        </li>
      }
    </ol>
  </nav>
</div>
