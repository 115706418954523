<div class="action-chart-wrapper">
  @for (action of actions; track $index; let last = $last) {
    <div class="action-chart-row" [ngStyle]="last ? { 'margin-bottom': '0' } : {}" style="page-break-inside: avoid">
      <div class="action-chart-date">{{ action.dateTitle }}</div>
      <div class="action-chart-data-wrapper">
        <div
          class="action-chart-percent"
          [ngStyle]="{
            width: action.overallPercentage + '%',
            'background-color': action.percentColour,
          }"
        ></div>
        <div class="action-chart-percent-text" [ngStyle]="action.overallPercentage < 25 && action.dataLength >= 6 ? { left: action.overallPercentage + '%', color: '#27323C' } : { right: 100 - action.overallPercentage + '%', color: '#fff' }">
          {{ action.totalOfThisDate }} ({{ action.overallPercentage | number: '1.0-0' }}%)
        </div>
      </div>
    </div>
  }
  <div class="action-chart-measurement">
    <div class="action-chart-percentage-x">
      <div>0%</div>
      <div>25%</div>
      <div>50%</div>
      <div>75%</div>
      <div>100%</div>
    </div>
  </div>
</div>
