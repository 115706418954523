<div class="grid grid-cols-1 gap-y-8">
  <!-- Form State Top -->
  <div class="flex flex-row items-center justify-end px-6 sm:px-8">
    <form-state [pristine]="roleForm.pristine"></form-state>
    <button type="button" (click)="saveRole()" class="btn" [disabled]="roleForm.pristine || roleForm.invalid" [ngClass]="roleForm.pristine || roleForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
  </div>

  <!-- Details -->
  <div class="content-card-wrapper">
    <div class="content-card-header">
      <h3>Details</h3>
    </div>
    <div class="content-card">
      <div class="content-grid gap-y-6">
        @if (roleId === "new") {
          <info-view [type]="infoViewRoleEditor.type" [message]="infoViewRoleEditor.message" />
        }
        <role-edit-details [roleForm]="roleForm" />
      </div>
    </div>
  </div>

  <!-- Permissions -->
  <div class="content-card-wrapper">
    <div class="content-card-header">
      <h3>Permissions</h3>
    </div>
    <div class="content-card">
      <div class="content-grid gap-y-6">
        <permissions-grid [roleForm]="roleForm" [isDisabled]="false" [infoViewPermissionsEditor]="infoViewPermissionsEditor" />
      </div>
    </div>
  </div>

  @if (assignedMembers.length > 0) {
    <manage-role-assigned-members [assignedMembers]="assignedMembers" />
  }
  @if (assignedInvites.length > 0) {
    <manage-role-assigned-invites [assignedInvites]="assignedInvites" />
  }

  <!-- Form State Bottom -->
  <div class="flex flex-row items-center justify-between px-6 sm:px-8">
    @if (roleId !== "new") {
      <button
        type="button"
        class="btn mr-3"
        (click)="deleteRole()"
        [disabled]="assignedMembers.length > 0 || assignedInvites.length > 0"
        [ngClass]="assignedMembers.length > 0 || assignedInvites.length > 0 ? 'btn-disabled cursor-not-allowed' : 'btn-destructive'">
        Delete Role
      </button>
    }
    <div class="flex flex-1 flex-row items-center justify-end">
      <form-state [pristine]="roleForm.pristine"></form-state>
      <button type="button" (click)="saveRole()" class="btn" [disabled]="roleForm.pristine || roleForm.invalid" [ngClass]="roleForm.pristine || roleForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
    </div>
  </div>
</div>
