import { inject, Injectable } from "@angular/core";
import { User } from "cip";
import { increment, orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { ActionEnhanced } from "src/app/models/action/action.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class ActionsListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  // Properties
  public priorityDict = {
    "0": { priority: "trivial", colour: "bg-score_100" },
    "25": { priority: "low", colour: "bg-score_75" },
    "50": { priority: "medium", colour: "bg-score_50" },
    "75": { priority: "high", colour: "bg-score_25" },
    "100": { priority: "critical", colour: "bg-score_0" },
  };

  /**
   * Get Inspection Actions
   * @param workspaceId
   * @param inspectionId
   */
  public getInspectionActions$(workspaceId: string, inspectionId: string): Observable<ActionEnhanced[]> {
    // console.log("getInspectionActions$");
    const path = this.collectionsService.actionsCol(workspaceId);
    const queryConstraints = [where("inspection_id", "==", inspectionId), where("complete", "==", false), where("is_deleted", "==", false), orderBy("order", "asc")];
    return this.firestoreUtilsService.getCollectionData<ActionEnhanced>(path, queryConstraints);
  }

  /**
   * Get Item Actions
   * @param workspaceId
   * @param itemId
   * @returns
   */
  public getItemActions$(workspaceId: string, itemId: string): Observable<ActionEnhanced[]> {
    const path = this.collectionsService.actionsCol(workspaceId);
    const queryConstraints = [where("item_id", "==", itemId), where("is_deleted", "==", false), orderBy("order", "asc")];
    return this.firestoreUtilsService.getCollectionData<ActionEnhanced>(path, queryConstraints);
  }

  /**
   * Reorder Actions
   * @param actions
   * @param workspaceId
   * @param user
   */
  async reorderActions(actions: ActionEnhanced[], workspaceId: string, user: User) {
    const path = this.collectionsService.actionsCol(workspaceId);
    let index = 0;

    const operations: BatchOperation[] = [];

    actions.forEach((action: ActionEnhanced) => {
      const doc = `${path}/${action.id}`;
      const lastEvent = this.lastEventService.lastEvent("changed", user);

      operations.push({
        type: "update",
        documentPath: doc,
        data: {
          order: index,
          last_event: lastEvent,
        },
      });

      index = index + 1;
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Delete Batched Actions
   * @param allActions
   * @param actionsBulkEditArray
   * @param user
   * @param workspaceId
   * @param inspectionId
   * @param itemId
   */
  async deleteBatchedActions(allActions: ActionEnhanced[], actionsBulkEditArray: ActionEnhanced[], user: User, workspaceId: string, inspectionId: string, itemId: string): Promise<void> {
    const actionsPath = this.collectionsService.actionsCol(workspaceId);
    const itemsPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const itemDoc = `${itemsPath}/${itemId}`;

    const operations: BatchOperation[] = [];

    const remainingActions = allActions.filter((existingAction) => !actionsBulkEditArray.some((actionToEdit) => actionToEdit.id === existingAction.id));

    remainingActions.forEach((remainingAction, index) => {
      operations.push({
        type: "update",
        documentPath: `${actionsPath}/${remainingAction.id}`,
        data: { order: index },
      });
    });

    for (const action of actionsBulkEditArray) {
      const lastEvent = this.lastEventService.lastEvent("deleted", user);

      operations.push({
        type: "update",
        documentPath: `${actionsPath}/${action.id}`,
        data: {
          is_deleted: true,
          last_event: lastEvent,
        },
      });
    }

    operations.push({
      type: "update",
      documentPath: itemDoc,
      data: { actions_count: increment(-actionsBulkEditArray.length) },
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Complete Incomplete Actions
   * @param workspaceId
   * @param actions
   * @param complete
   * @param user
   */
  async completeIncompleteActions(workspaceId: string, actions: ActionEnhanced[], complete: boolean, user: User): Promise<void> {
    const path = this.collectionsService.actionsCol(workspaceId);
    const operations: BatchOperation[] = [];

    actions.forEach((action: ActionEnhanced) => {
      const doc = `${path}/${action.id}`;
      const lastEvent = this.lastEventService.lastEvent("changed", user);
      const obj = { complete: complete, last_event: lastEvent };

      operations.push({
        type: "update",
        documentPath: doc,
        data: obj,
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }
}
