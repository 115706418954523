// TODO(kafkas): The @ts-ignore here won't be needed once TypeScript is upgraded to a more recent version.
// That requires upgrading Angular as well. The current version which is 4 majors behind latest, is not compatible with TS 5.4

export function extractErrorMessage(e: unknown): string {
  if (typeof e === 'string') return e;
  if (e instanceof Error) return e.message;
  // eslint-disable-next-line
  // @ts-ignore
  if (typeof e === 'object' && e !== null && 'message' in e && typeof e['message'] === 'string') {
    // eslint-disable-next-line
    // @ts-ignore
    return e['message'];
  }
  return 'Unknown error';
}
