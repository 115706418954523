import { bootstrapApplication } from '@angular/platform-browser';
import { applyPolyfills, defineCustomElements } from '@what3words/javascript-components/loader';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

// bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

// NOTE: Polyfill web components into build
defineCustomElements(window).then(applyPolyfills);
