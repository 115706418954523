@if (reportsService.reportData.site; as site) {
  <div class="site-wrapper">
    <div class="header">
      <p class="title text-group-1" [ngStyle]="{ color: reportsService.themeDetails.value.colours_brand }">{{ site.title }}</p>
      <div class="divider" [ngStyle]="{ 'background-color': reportsService.themeDetails.value.colours_brand }"></div>
    </div>
    <div class="site-content">
      <div class="site-address-wrapper">
        <div class="site-address-left">
          @if (site.address) {
            <p class="site-address-header text-group-2">Address</p>
            <p class="site-address text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_content }">{{ site.address }}</p>
          }
          @if (site.ref) {
            <div class="site-ref-wrapper">
              <p class="site-ref-header text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">Reference</p>
              <p class="site-ref text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_content }">{{ site.ref }}</p>
            </div>
          }
        </div>
        @if (reportsService.themeDetails.value.details_site_photo) {
          @if (site.image_url) {
            <div class="site-address-right">
              <div class="site-image h-[320px] w-[320px] bg-red-100 bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + site.image_url + ')' }"></div>
            </div>
          }
        }
      </div>

      <div class="site-separator"></div>

      @if (site.what_3_words) {
        <div class="site-location-wrapper">
          <div class="site-location-left">
            <p class="site-location-header text-group-2">Location</p>
            <div class="site-coordinates-wrapper">
              <div class="site-coordinates-long">
                <p class="site-coordinates-header text-group-5 text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">Longitude</p>
                <p class="site-coordinates-data text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_content }">{{ site.longitude }}</p>
              </div>
              <div class="site-coordinates-lat">
                <p class="site-coordinates-header text-group-5 text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">Latitude</p>
                <p class="site-coordinates-data text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_content }">{{ site.latitude }}</p>
              </div>
            </div>
            <div class="site-w3w-wrapper">
              <div class="site-w3w-logo">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 522.4174 87.8988">
                  <!-- SVG content remains unchanged -->
                </svg>
              </div>
              <p class="site-w3w-data text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_content }">{{ site.what_3_words }}</p>
              <p></p>
            </div>
          </div>
          @if (reportsService.themeDetails.value.details_site_map) {
            <div class="site-location-right">
              <div class="site-image h-[320px] w-[320px] bg-red-100 bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + site.map_url + ')' }"></div>
            </div>
          }
        </div>
      }
    </div>
  </div>
}
