import { inject, Injectable } from "@angular/core";
import { Member, User, WriteEvent } from "cip";
import { deleteField, PartialWithFieldValue, WithFieldValue } from "firebase/firestore";
import { catchError, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { MemberEnhanced } from "src/app/models/member/member.model";
@Injectable({
  providedIn: "root",
})
export class MemberDetailService {
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private collectionsService = inject(CollectionsService);
  private lastEventService = inject(LastEventService);

  // Toggles the role form inside the role selector on the member editor
  public overlay_roleForm = false;

  // Toggles the remove member overlay
  public overlay_removeMember = false;

  // Limitation Manager
  public limitationManagerWorkspaceId!: string;

  /**
   * Get Member
   * @param workspaceId
   * @param memberId
   */
  public getMemberDoc$(workspaceId: string, memberId: string): Observable<MemberEnhanced | null> {
    const path = this.collectionsService.membersCol(workspaceId);
    const documentPath = `${path}/${memberId}`;
    return this.firestoreUtilsService.getDocumentData<MemberEnhanced>(documentPath).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Save Member
   * @param workspaceId
   * @param memberId
   * @param memberForm
   * @param user
   */
  public async saveMemberDoc(workspaceId: string, memberId: string, memberForm: MemberEnhanced, user: User): Promise<void> {
    const path = this.collectionsService.membersCol(workspaceId);
    const documentPath = `${path}/${memberId}`;
    const writeEventType = memberId === "new" ? "added" : "changed";
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const member: WithFieldValue<Member> = {
      name_first: memberForm.name_first,
      name_last: memberForm.name_last,
      email: memberForm.email,
      job_title: memberForm.job_title === "" || memberForm.job_title === null ? deleteField() : memberForm.job_title,
      company: memberForm.company === "" || memberForm.company === null ? deleteField() : memberForm.company,
      phone: memberForm.phone === "" || memberForm.phone === null ? deleteField() : memberForm.phone,
      role_title: memberForm.role_title,
      role_id: memberForm.role_id,
      permissions: memberForm.permissions,
      last_event: lastEvent,
      status: memberForm.status,
    };
    return await this.firestoreUtilsService.setPartialDocumentData<MemberEnhanced>(documentPath, member);
  }

  /**
   * Remove Member Or Member Left
   * @param workspaceId
   * @param memberId
   * @param memberForm
   * @param user
   */
  public async removeMemberOrMemberLeft(workspaceId: string, memberId: string, user: User): Promise<void> {
    const path = this.collectionsService.membersCol(workspaceId);
    const documentPath = `${path}/${memberId}`;
    const memberLastEvent = this.lastEventService.lastEvent("changed", user);
    const member: PartialWithFieldValue<Member> = {
      last_event: memberLastEvent,
      status: "removed",
    };
    return await this.firestoreUtilsService.setPartialDocumentData<MemberEnhanced>(documentPath, member);
  }

  /**
   * Create Member Object
   * @param lastEvent
   * @param user
   */
  createMemberObject(lastEvent: WithFieldValue<WriteEvent>, user: User): WithFieldValue<Member> {
    return {
      name_first: user.name_first,
      name_last: user.name_last,
      email: user.email,
      job_title: user.job_title ?? deleteField(),
      company: user.company ?? deleteField(),
      phone: user.phone ?? deleteField(),
      role_title: "Owner",
      role_id: "Owner",
      status: "activated",
      last_event: lastEvent,
      permissions: {
        action_create_update: true,
        action_delete: true,
        assignee_create_update: true,
        assignee_delete: true,
        category_create_update: true,
        category_delete: true,
        client_create_update: true,
        client_delete: true,
        folder_create_update: true,
        folder_delete: true,
        inspection_create_update: true,
        inspection_delete: true,
        invite_create_update: true,
        invite_delete: true,
        item_create_update: true,
        item_delete: true,
        member_update: true,
        member_delete: true,
        photo_create_update: true,
        photo_delete: true,
        role_create_update: true,
        role_delete: true,
        signature_create_update: true,
        signature_delete: true,
        site_create_update: true,
        site_delete: true,
        template_create_update: true,
        template_delete: true,
        theme_create_update: true,
        theme_delete: true,
        workspace_update: true,
      },
    };
  }
}
