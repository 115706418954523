@if (reportsService.themeDetails.value.charts_included) {
  <div class="charts-cover-wrapper">
    <div class="header">
      <div class="title text-group-1" [ngStyle]="{ color: reportsService.themeDetails.value.colours_brand }">Charts</div>
      <div class="divider" [ngStyle]="{ 'background-color': reportsService.themeDetails.value.colours_brand }"></div>
    </div>
    <div class="charts-content-wrapper">
      <!-- 1. Scores -->
      @if (reportsService.themeDetails.value.charts_score) {
        <div class="charts-scores-wrapper">
          <div class="charts-title text-group-2">Scores</div>
          <div class="chart-total text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ chartsService.totalTests }} {{ chartsService.totalTests === 1 ? "Test" : "Tests" }}</div>
          @if (chartsService.totalTests > 0) {
            <charts-overall [items]="reportsService.reportData.chartsTests" [widthHeight]="40" />
          }
        </div>
      }

      <!-- 2. Assignees-->
      @if (reportsService.themeDetails.value.charts_assignee) {
        <div class="charts-assignees-wrapper">
          <div class="charts-title text-group-2">{{ chartsService.totalAssignees === 1 ? "Assignee" : "Assignees" }}</div>
          <div class="chart-total" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ chartsService.totalAssignees }} {{ chartsService.totalAssignees === 1 ? "Assignee" : "Assignees" }}</div>
          @if (chartsService.totalAssignees > 0) {
            <charts-assignees [chartsAssignees]="reportsService.reportData.chartsAssignees" [assigneeHighestActionCount]="chartsService.assigneeHighestActionCount" />
          }
        </div>
      }

      <!-- 3. Actions -->
      @if (reportsService.themeDetails.value.charts_action) {
        <div class="charts-actions-wrapper">
          <div class="charts-title text-group-2">{{ chartsService.totalActions === 1 ? "Action" : "Actions" }}</div>
          <div class="chart-total text-group-5" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ chartsService.totalActions }} Actions</div>
          @if (chartsService.totalActions > 0) {
            <charts-actions [actions]="reportsService.reportData.chartsActions" />
          }
        </div>
      }
    </div>
  </div>
}
