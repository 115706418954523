import { AsyncPipe, NgClass, NgStyle } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { ClientEnhanced } from "src/app/models/client/client.model";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { DropzoneDirective } from "src/app/shared/directives/dropzone.directive";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { PhotoDropzoneComponent } from "src/app/shared/photo-dropzone/photo-dropzone.component";
import { PhotoPreviewComponent } from "src/app/shared/photo-preview/photo-preview.component";
import { PhotoUploadSingleComponent } from "src/app/shared/photo-upload-single/photo-upload-single.component";
import { ClientDetailService } from "../../services/client-detail.service";
import { ManageClientEditDetailsComponent } from "../manage-client-edit-details/manage-client-edit-details.component";
import { ManageClientEditHeaderComponent } from "../manage-client-edit-header/manage-client-edit-header.component";

@Component({
  selector: "manage-client-edit-wrapper",
  standalone: true,
  imports: [
    ManageClientEditDetailsComponent,
    DeleteOverlayComponent,
    LoadingSpinnerComponent,
    FormStateComponent,
    AsyncPipe,
    NgClass,
    InfoViewComponent,
    DeleteOverlayComponent,
    ManageClientEditHeaderComponent,
    PhotoDropzoneComponent,
    DropzoneDirective,
    PhotoUploadSingleComponent,
    PhotoPreviewComponent,
    NgStyle,
  ],
  templateUrl: "./manage-client-edit-wrapper.component.html",
  styleUrl: "./manage-client-edit-wrapper.component.scss",
})
export class ManageClientEditWrapperComponent implements OnInit {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set clientId(value: string) {
    this._clientId = value;
  }
  get clientId(): string {
    return this._clientId;
  }

  // Services
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private clientDetailService = inject(ClientDetailService);
  private router = inject(Router);
  private limitationManagerService = inject(LimitationManagerService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private stringsService = inject(StringsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  // Properties
  private _workspaceId!: string;
  private _clientId!: string;
  public client$!: Observable<ClientEnhanced>;
  private user: User;
  public clientForm: FormGroup;
  public overlay_deleteClient: boolean = false;
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos = false;
  private tempBlobPhotoURL!: string;
  public overlay_deleteClientImage: boolean = false;

  constructor() {
    this.user = this.authService.currentUser;
    this.clientForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      branch: ["", this.formFormattingService.noWhitespaceValidator()],
      image_url: null,
      image_thumbnail_url: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getClientDoc();
  }

  /**
   * Get Client Doc
   */
  private async getClientDoc(): Promise<void> {
    try {
      this.client$ = this.clientDetailService.getClientDoc$(this.workspaceId, this.clientId).pipe(
        filter((data) => !!data),
        tap((data) => {
          if (this.clientForm.pristine && this.clientForm.untouched) {
            this.clientForm.patchValue(data);
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Client
   */
  public async saveClient(): Promise<void> {
    try {
      await this.clientDetailService.saveClientDoc(this.workspaceId, this.clientId, this.clientForm.value, "changed", this.user);
      this.clientForm.markAsPristine();
      this.router.navigate(["/workspace", this.workspaceId, "manage", "clients"]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Client
   */
  public async deleteClient(): Promise<void> {
    try {
      await this.clientDetailService.deleteClientDoc(this.workspaceId, this.clientId, this.user);
      this.router.navigate(["/workspace", this.workspaceId, "manage", "clients"]);
      this.toggleDeleteClientOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Delete Client
   */
  public async canUserDeleteClient(): Promise<void> {
    const featureAction: keyof RolePermissions = "client_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const deleteClient = this.stringsService.alertFilter(AlertType.DeleteClient);
      if (deleteClient) {
        this.deleteOverlayTitle = deleteClient.title;
        this.deleteOverlayDescription = deleteClient.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteClient);
      }
      this.toggleDeleteClientOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Does A Photo Need Uploading
   */
  public doesAPhotoNeedUploading(): void {
    if (this.uploadFileArray.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.saveClient();
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split("/").pop();
    if (!(fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png")) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.togglePhotoPreview();
      this.clientForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * Remove Image From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.clientForm.patchValue({
      image_url: photos.image_url,
      image_thumbnail_url: photos.image_thumbnail_url,
    });
    try {
      await this.saveClient();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Delete Client Image Overlay
   */
  public toggleDeleteClientImageOverlay(): void {
    this.overlay_deleteClientImage = !this.overlay_deleteClientImage;
    const deleteClientImage = this.stringsService.alertFilter(AlertType.DeleteClientImage);
    if (deleteClientImage) {
      this.deleteOverlayTitle = deleteClientImage.title;
      this.deleteOverlayDescription = deleteClientImage.description;
    } else {
      console.error("Unknown string type:", AlertType.DeleteClientImage);
    }
  }

  /**
   * Remove Client Image Properties From Form
   */
  public removeClientImagePropertiesFromForm(): void {
    this.clientForm.patchValue({
      image_url: null,
      image_thumbnail_url: null,
    });
    this.clientForm.markAsDirty();
    this.toggleDeleteClientImageOverlay();
  }

  /**
   * Toggle Delete Client Overlay
   */
  public toggleDeleteClientOverlay(): void {
    this.overlay_deleteClient = !this.overlay_deleteClient;
  }

  /**
   * Toggle Photos Upload Overlay
   */
  public togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.clientForm.dirty ? false : true;
  }
}
