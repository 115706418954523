@if (signatures$ | async; as signatures) {
  <inspection-signoff-list-header
    [signatures]="signatures"
    [workspaceId]="workspaceId"
    [folderId]="folderId"
    [inspectionId]="inspectionId"
    [editMode]="editMode"
    [signatureBulkEditArray]="signatureBulkEditArray$ | async"
    (toggleEditModeOutput)="toggleEditMode()"
    (routeToSignatureEditorOutput)="limitationManagerCheck('new')"
    (bulkDeleteSignaturesOutput)="setDeleteBatchSignaturesProperties()" />
  @if (signatures.length > 0) {
    @if (!editMode) {
      <div class="grid grid-cols-1 gap-y-8">
        <inspection-signoff-list-main [mainSignature]="mainSignature" (routeToSignatureEditorOutput)="routeToSignatureEditor($event)" />
        <inspection-signoff-list-other [otherSignatures]="otherSignatures" (routeToSignatureEditorOutput)="routeToSignatureEditor($event)" />
      </div>
    } @else {
      <inspection-signoff-list-all
        [workspaceId]="workspaceId"
        [folderId]="folderId"
        [inspectionId]="inspectionId"
        [signatures]="allSignatures"
        [signatureBulkEditArray]="signatureBulkEditArray$ | async"
        (reorderSignaturesOutput)="reorderSignatures($event)"
        (deleteSingleSignatureOutput)="setDeleteSignatureProperty($event)"
        (createBatchEditOutput)="createBatchEdit($event)" />
    }
  } @else {
    <empty-list [list]="'signature'" />
  }
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}
@if (overlay_deleteSignature) {
  <delete-overlay [deleteOverlayTitle]="deleteTitle" [deleteOverlayDescription]="deleteDescription" (cancelButtonClicked)="toggleDeleteSignatureOverlay()" (deleteButtonClicked)="deleteSingleOrBatchedSignatures()" />
}
@if (overlay_newSignature) {
  <inspection-signoff-new-overlay
    [signatureForm]="signatureForm"
    [workspaceId]="workspaceId"
    [inspectionId]="inspectionId"
    [uploadFileArray]="uploadFileArray"
    [photoPreview]="photoPreview"
    [newSignatureId]="newSignatureId"
    [overlay_uploadPhotos]="overlay_uploadPhotos"
    (detectPhotosOutput)="detectPhotos($event)"
    (toggleNewSignatureOverlayOutput)="toggleNewSignatureOverlay()"
    (removePhotoFromArrayOutput)="removePhotoFromArray($event)"
    (returnedPhotoOutput)="patchFormWithPhotosAndSaveDoc($event)"
    (saveSignatureOutput)="checkIfPhotosAreUploadingOrCreateSignature()" />
}
