@if (themes$ | async; as themes) {
  <div class="flex min-w-0 flex-1">
    <div class="flex h-full min-w-0 flex-1 flex-col overflow-hidden">
      @if (themesBulkEditArray$ | async; as themesBulkEditArray) {
        <manage-themes-list-header
          [workspaceId]="workspaceId"
          [themesCount]="themes.length"
          [editMode]="editMode"
          [themesBulkEditArray]="themesBulkEditArray"
          (searchTermChange)="searchTermChange($event)"
          (routeToThemeEditorOutput)="routeToNewEditor()"
          (toggleEditModeOutput)="toggleEditMode()"
          (bulkDeleteThemesOutput)="setDeleteBatchThemesProperties()" />

        @if (themes.length > 0) {
          <manage-themes-list
            [themes]="themes"
            [editMode]="editMode"
            [searchTerm]="currentSearchTerm"
            [themeDetails]="themeDetails | async"
            [themesBulkEditArray]="themesBulkEditArray"
            (deleteSingleThemeOutput)="setDeleteSingleThemeProperties($event)"
            (createBatchEditOutput)="createBatchEdit($event)"
            (routeToThemeEditorOutput)="routeToThemeEditor($event)" />
        } @else {
          <empty-list [list]="'theme'" />
        }
      }
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteTheme()) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteThemeOverlay(false)" (deleteButtonClicked)="deleteSingleOrMultipleThemes()" />
}
