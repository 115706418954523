import { inject, Injectable } from '@angular/core';

import { Operation } from 'cip';
import { WithFieldValue } from 'firebase/firestore';
import { catchError, Observable, of } from 'rxjs';
import { OperationEnhanced } from 'src/app/models/operation/operation.model';
import { CollectionsService } from '../collections/collections.service';
import { FirestoreUtilsService } from '../firestore/firestore-utils.service';

@Injectable({
  providedIn: 'root',
})
export class DuplicationService {
  public overlay_duplicate = false;
  public overlay_duplicateConfirmation = false;

  public overlay_createCategoriesFromTemplate = false;

  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Duplicate
   * @param workspaceId
   * @param templateToDuplicate
   */
  async duplicate(workspaceId: string, templateToDuplicate: WithFieldValue<Operation>) {
    const path = this.collectionsService.operationsCol(workspaceId);
    const operationId = this.firestoreUtilsService.createFirestoreId();
    const documentPath = `${path}/${operationId}`;
    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Operation>(documentPath, templateToDuplicate);
    return { id: operationId, ...templateToDuplicate };
  }

  /**
   * Get Duplication Operation
   * @param workspaceId
   * @param operationId
   */
  public getDuplicationOperation$(workspaceId: string, operationId: string): Observable<OperationEnhanced | null> {
    const path = this.collectionsService.operationsCol(workspaceId);
    const documentPath = `${path}/${operationId}`;
    return this.firestoreUtilsService.getDocumentData<OperationEnhanced>(documentPath).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Toggle Duplication Process
   * This will toggle the boolean state of 'overlay_duplicateConfirmation'
   * Displays the confirmation message when true
   * Displays the duplication process when false
   */
  toggleDuplicationProcess(): void {
    this.overlay_duplicateConfirmation = !this.overlay_duplicateConfirmation;
  }
}
