<div class="px-4">
  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false" (click)="toggleContentOptions()">
        <span class="font-medium text-gray-900">Assignee</span>
        <span class="ml-6 flex items-center">
          @if (!toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
          }
          @if (toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
            </svg>
          }
        </span>
      </button>
    </h3>

    @if (toggleContent) {
      <div class="pt-6">
        @if (reportsService.reportData.actionAssignees.length > 0) {
          <ul class="space-y-4">
            <li class="flex items-center">
              <input
                #allAssigneesCheckbox
                id="assignee-all"
                name="assignee-all"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
                [(ngModel)]="reportsService.reportSettings.assignee_all"
                (change)="toggleAllAssigneeCheckboxes()" />
              <label for="assignee-all" class="ml-3 cursor-pointer text-sm text-text_Title">Select All</label>
            </li>

            <li class="flex items-center">
              <input
                #noAssigneeCheckbox
                id="assignee-none"
                name="assignee-none"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
                [checked]="reportsService.reportSettings.assignee_ids.includes('no-assignee')"
                (change)="onAssigneeCheckboxChange('no-assignee', noAssigneeCheckbox.checked)" />
              <label for="assignee-none" class="ml-3 cursor-pointer text-sm text-text_Title">Actions without Assignee</label>
            </li>
            @for (assignee of reportsService.reportData.actionAssignees; track assignee.id; let i = $index; let last = $last) {
              <li class="flex items-center">
                <input
                  #assigneeCheckbox
                  id="assignee_{{ assignee.id }}"
                  name="assignee-{{ assignee.id }}"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
                  [checked]="reportsService.reportSettings.assignee_ids.includes(assignee.assignee_id!)"
                  (change)="onAssigneeCheckboxChange(assignee.assignee_id!, assigneeCheckbox.checked)" />
                <label for="assignee_{{ assignee.id }}" class="ml-3 cursor-pointer text-sm text-text_Title">
                  {{ assignee.assignee_name }}
                  @if (assignee.assignee_company) {
                    <span class="list-meta">{{ assignee.assignee_company }}</span>
                  }
                </label>
              </li>
            }
          </ul>
        } @else {
          <p>No assignees</p>
        }
      </div>
    }
  </div>
</div>
