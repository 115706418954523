import { AsyncPipe, NgClass } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input } from '@angular/core';
import { SiteDetailService } from '../../../services/site-detail.service';

@Component({
  selector: 'w3w-map',
  standalone: true,
  imports: [NgClass, AsyncPipe],
  templateUrl: './w3w-map.component.html',
  styleUrl: './w3w-map.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class W3wMapComponent {
  @Input({ required: true }) w3w_api_key!: string;
  @Input({ required: true }) map_api_key!: string;
  public siteDetailService = inject(SiteDetailService);

  public closeOverlay(): void {
    this.siteDetailService.viewSiteMapSubject.next(!this.siteDetailService.viewSiteMapSubject.getValue());
  }
}
