import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SinglePhotoUpload } from 'src/app/models/photo/photo-upload.model';

@Component({
  selector: 'photo-preview',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './photo-preview.component.html',
  styleUrl: './photo-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoPreviewComponent {
  // @Input({ required: true }) photosToPreview!: SinglePhotoUpload[] | MultiPhotoUpload[];
  @Input({ required: true }) photosToPreview!: SinglePhotoUpload[];
  @Input({ required: true }) uploadLimit!: number;
  @Input({ required: true }) uploadSourceComponent!: 'workspace' | 'client' | 'site' | 'signature' | 'item';
  @Output() removePhotoFromArrayOutput = new EventEmitter<string>();

  /**
   * Remove image from array
   * @param id
   */
  removePhotoFromArray(id: string): void {
    this.removePhotoFromArrayOutput.emit(id);
  }
}
