<manage-roles-list-header [selectedTab]="selectedTab" (routeToRoleEditorOutput)="routeToRoleEditor()" />

<!-- Tab Selection between members and invites -->
<div class="mb-5 flex w-full flex-col px-8">
  <nav class="flex space-x-4" aria-label="Tabs">
    <div class="flex cursor-pointer items-center justify-between gap-x-2 rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-700" [ngClass]="selectedTab === 'Default' ? 'bg-gray-200' : 'bg-gray-100'" (click)="selectTab('Default')">
      <span>Default</span>
      <tooltip [toolTipText]="toolTipRoleDefault.title"></tooltip>
    </div>
    <div class="flex cursor-pointer items-center justify-between gap-x-2 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-700" [ngClass]="selectedTab === 'Custom' ? 'bg-gray-200' : 'bg-gray-100'" (click)="selectTab('Custom')">
      <span>Custom</span>
      <tooltip [toolTipText]="toolTipRoleCustom.title"></tooltip>
    </div>
  </nav>
</div>

<div class="grid grid-cols-1 gap-y-8">
  @if (selectedTab === "Default") {
    <manage-roles-list-default [roles]="defaultRoles" [workspaceId]="workspaceId" />
  } @else {
    @if (roles$ | async; as roles) {
      @if (roles.length > 0) {
        <manage-roles-list-custom [roles]="roles" [workspaceId]="workspaceId" />
      } @else {
        <empty-list [list]="'role'" />
      }
    } @else {
      <div class="flex h-full items-center justify-center">
        <loading-spinner />
      </div>
    }
  }
</div>

<ng-template #noCustomRoles>
  <empty-list [list]="'role'"></empty-list>
</ng-template>
