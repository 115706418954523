<div class="grid grid-cols-1 gap-y-4">
  <!-- List -->
  <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
    <!-- List Component -->
    <ul class="list-ul" id="itemsList" cdkDropList (cdkDropListDropped)="reorderItems($event)">
      @for (item of filteredItems; track $index) {
        <li cdkDrag [cdkDragDisabled]="!editMode" [ngClass]="[item.enabled && currentCategory.enabled ? 'opacity-100' : 'opacity-50']" class="list-none" [class.hidden]="item.hidden">
          <div class="list-row">
            <!-- List Editing Tools -->
            @if (editMode) {
              <div class="flex flex-row items-center">
                <div class="mr-3 flex items-center justify-end hover:text-brand_Primary-hover dark:text-slate-300 dark:hover:text-sky-500">
                  <div class="flex items-center">
                    <input type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" (change)="createBatchEdit(item)" [checked]="itemsBulkEditArray?.includes(item)" />
                  </div>
                </div>
                <div class="flex items-center">
                  @if (itemsBulkEditArray && itemsBulkEditArray.length <= 0) {
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" (click)="deleteSingleItem(item)" class="cip-transition mr-3 h-5 w-5 cursor-pointer text-alert_Error hover:opacity-75">
                      <path
                        fill-rule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clip-rule="evenodd" />
                    </svg>
                  }
                </div>

                @if (itemsBulkEditArray && itemsBulkEditArray.length <= 0) {
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5 cursor-pointer text-text_Subtitle" (click)="toggleDuplicateOverlay(item)">
                    <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
                    <path
                      d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
                  </svg>
                }
              </div>
            }

            <!-- Left Index-->
            <div class="ml-2.5 flex flex-row sm:ml-0">
              <div class="flex h-6 w-6 font-medium text-brand_Primary-hover dark:text-white">{{ item.order + 1 }}</div>
            </div>

            <div (click)="routeToItemOrTestDetails(item.id)" class="list-a group" [ngClass]="{ 'cursor-pointer': item.enabled && currentCategory.enabled }">
              <!-- Content -->
              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                <!-- Left -->
                <div class="min-w-0 flex-auto">
                  <p class="list-title" [ngClass]="{ 'mb-1': item.answered }">{{ item.title }}</p>

                  @if (item.mode === "test") {
                    <div class="s mt-1 flex items-center gap-x-6">
                      <div class="flex flex-row items-center gap-x-4">
                        <div class="list-meta flex items-center space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                          </svg>
                          <p>{{ item.photos_count }} Photos</p>
                        </div>
                        <div class="list-meta flex items-center space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <p>{{ item.actions_count }} Actions</p>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>

            <!-- Test Types -->
            @if (!editMode && item.mode === "test") {
              <div class="flex flex-shrink-0 items-center">
                <inspection-items-list-test-types [item]="item" [currentCategory]="currentCategory" (setItemAnswerOutput)="setItemAnswer($event)" />
              </div>
            }

            <!-- Question Types -->
            @if (!editMode && item.mode === "question") {
              <div class="flex flex-shrink-0 items-center">
                <inspection-items-list-question-types [item]="item" />
              </div>
            }

            <!-- Right Icon - Default -->
            @if (!editMode) {
              <div class="flex">
                <button type="button" class="mr-2.5 block h-full py-2.5 pl-2.5 text-text_Subtitle dark:text-gray-400 sm:mr-0" aria-expanded="false" aria-haspopup="true">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </button>
              </div>
            } @else {
              <!-- Right Icon - Edit -->
              <div class="flex">
                <button type="button" class="mr-2.5 block h-full py-2.5 pl-2.5 text-text_Subtitle dark:text-gray-400 sm:mr-0" aria-expanded="false" aria-haspopup="true">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                  </svg>
                </button>
              </div>
            }
          </div>
        </li>
      }
    </ul>
  </div>
</div>

@if (allItemsHidden) {
  <search-empty [searchTerm]="_searchTerm" />
}
