<form [formGroup]="inviteForm">
  <div class="col-span-1 grid gap-x-4 gap-y-6 sm:grid-cols-2">
    <div class="col-span-1">
      <div class="form-input-wrapper">
        <label for="invite-name" class="form-input-label"
          >Name*
          @if (!inviteForm.pristine && inviteForm.controls["name"].errors) {
            <span class="required-field"> - Name is required</span>
          }
        </label>
        <input type="text" name="invite-name" id="invite-name" class="form-input" placeholder="Name" formControlName="name" />
      </div>
    </div>

    <div class="col-span-1" [ngClass]="{ 'cursor-not-allowed opacity-50': inviteId !== 'new' }">
      <div class="form-input-wrapper">
        <label for="invite-email" class="form-input-label"
          >Email*
          @if (!inviteForm.pristine && inviteForm.controls["email"].hasError("required")) {
            <span class="required-field"> - Email is required</span>
          }
          @if (!inviteForm.pristine && inviteForm.get("email")!.hasError("pattern")) {
            <span class="required-field"> - Please provide a valid email address</span>
          }
        </label>
        <input type="text" name="invite-email" id="invite-email" class="form-input" placeholder="Email" formControlName="email" [readonly]="inviteId !== 'new'" [ngClass]="{ 'cursor-not-allowed': inviteId !== 'new' }" />
      </div>
    </div>
  </div>
</form>
