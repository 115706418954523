import { inject, Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { AuditLogEnhanced } from "src/app/models/audit-log/audit-log.model";

@Injectable({
  providedIn: "root",
})
export class AuditLogDetailService {
  // Servuces
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Audit Log Doc
   * @param workspaceId
   * @param auditLogId
   */
  public getAuditLogDoc$(workspaceId: string, auditLogId: string): Observable<AuditLogEnhanced | null> {
    const path = `${this.collectionsService.auditLogsCol(workspaceId)}/${auditLogId}`;
    return this.firestoreUtilsService.getDocumentData<AuditLogEnhanced>(path).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}
