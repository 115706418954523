import { Component, EventEmitter, Input, Output, signal } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TestFormTitleComponent } from "../test-form-title/test-form-title.component";

import { NgClass } from "@angular/common";

import { User } from "cip";
import { WithFieldValue } from "firebase/firestore";
import { MultiPhotoUpload } from "src/app/models/photo/photo-upload.model";
import { PhotoEnhanced } from "src/app/models/photo/photo.model";
import { ActionsListWrapperComponent } from "src/app/sections/actions/list/actions-list-wrapper/actions-list-wrapper.component";
import { PhotoDropzoneComponent } from "src/app/shared/photo-dropzone/photo-dropzone.component";
import { PhotoPreviewComponent } from "src/app/shared/photo-preview/photo-preview.component";
import { PhotoUploadMultipleComponent } from "src/app/shared/photo-upload-multiple/photo-upload-multiple.component";
import { TestFailPassComponent } from "../test-form-answer-types/test-fail-pass/test-fail-pass.component";
import { TestLowHighComponent } from "../test-form-answer-types/test-low-high/test-low-high.component";
import { TestNoYesComponent } from "../test-form-answer-types/test-no-yes/test-no-yes.component";
import { TestPercentageComponent } from "../test-form-answer-types/test-percentage/test-percentage.component";
import { TestPoorFairGoodComponent } from "../test-form-answer-types/test-poor-fair-good/test-poor-fair-good.component";
import { TestProgressComponent } from "../test-form-answer-types/test-progress/test-progress.component";
import { TestRedAmberGreenComponent } from "../test-form-answer-types/test-red-amber-green/test-red-amber-green.component";
import { TestFormPhotosComponent } from "../test-form-photos/test-form-photos.component";

@Component({
  selector: "test-form-wrapper",
  standalone: true,
  imports: [
    TestFormTitleComponent,
    NgClass,
    TestFailPassComponent,
    TestLowHighComponent,
    TestNoYesComponent,
    TestPoorFairGoodComponent,
    TestProgressComponent,
    TestRedAmberGreenComponent,
    TestPercentageComponent,
    PhotoDropzoneComponent,
    PhotoPreviewComponent,
    PhotoUploadMultipleComponent,
    ReactiveFormsModule,
    TestFormPhotosComponent,
    ActionsListWrapperComponent,
  ],
  templateUrl: "./test-form-wrapper.component.html",
  styleUrl: "./test-form-wrapper.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestFormWrapperComponent {
  @Input({ required: true }) testForm!: FormGroup;
  @Input({ required: true }) currentCategoryOrder!: number;
  @Input({ required: true }) itemLimitationManagerResult!: boolean;
  // @Input({ required: true }) photoLimitationManagerResult = signal<boolean>(false);
  @Input() photoLimitationManagerResult = signal<boolean>(false);
  @Input({ required: true }) photoPreview!: boolean;
  @Input({ required: true }) overlay_uploadPhotos!: boolean;
  @Input({ required: true }) webUploadLimit!: number;
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) categoryId!: string;
  @Input({ required: true }) itemId!: string;
  @Input({ required: true }) user!: User;
  @Input({ required: true }) photos!: PhotoEnhanced[];
  @Input() isTestNotesEditable!: boolean;
  @Input() uploadFileArray: MultiPhotoUpload[] | null = [];
  @Output() enableOrDisableTestOutput = new EventEmitter<void>();
  @Output() removeAnswerOutput = new EventEmitter<void>();
  @Output() itemAnswerLimitationManagerCheckOutput = new EventEmitter<string>();
  @Output() canUserEditNotesOutput = new EventEmitter<void>();
  @Output() onNotesTextAreaInputOutput = new EventEmitter<Event>();
  @Output() detectPhotosOutput = new EventEmitter<FileList>();
  @Output() removePhotoFromArrayOutput = new EventEmitter<string>();
  @Output() photoUploadsCompleteSaveTestOutput = new EventEmitter<WithFieldValue<PhotoEnhanced>[]>();
  @Output() reorderPhotosOutput = new EventEmitter<PhotoEnhanced[]>();
  @Output() deletePhotoOutput = new EventEmitter<{ photoId: string; photos: PhotoEnhanced[] }>();

  photoUploadsCompleteSaveTest(photoObjects: WithFieldValue<PhotoEnhanced>[]): void {
    this.photoUploadsCompleteSaveTestOutput.emit(photoObjects);
  }

  /**
   * Reorder Photos
   * @param photos
   */
  public reorderPhotos(photos: PhotoEnhanced[]): void {
    this.reorderPhotosOutput.emit(photos);
  }
  /**
   * Detect Photos
   * @param files
   */
  public detectPhotos(files: FileList): void {
    this.detectPhotosOutput.emit(files);
  }

  /**
   * Remove Phot From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    this.removePhotoFromArrayOutput.emit(id);
  }

  /**
   * Enable or disable the test
   */
  public enableOrDisableTest(): void {
    this.enableOrDisableTestOutput.emit();
  }

  /**
   * Remove Answer
   */
  public removeAnswer(): void {
    this.removeAnswerOutput.emit();
  }

  /**
   * Item Answer Limitation Manager Check
   * @param newValue
   */
  public itemAnswerLimitationManagerCheck(newValue: string): void {
    this.itemAnswerLimitationManagerCheckOutput.emit(newValue);
  }

  /**
   * Can User Edit Notes
   */
  public canUserEditNotes(): void {
    this.canUserEditNotesOutput.emit();
  }

  /**
   * On Notes Text Area Input
   * @param $event
   */
  onNotesTextAreaInput($event: Event): void {
    this.onNotesTextAreaInputOutput.emit($event);
  }

  /**
   * Delete Photo
   * @param photoId
   */

  public deletePhoto(event: { photoId: string; photos: PhotoEnhanced[] }) {
    const { photoId, photos } = event;
    this.deletePhotoOutput.emit({ photoId, photos: photos });
  }
}
