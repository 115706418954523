import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ThemeEnhanced } from "src/app/models/theme/theme.model";
import { ThemesListService } from "src/app/sections/workspaces/current-workspace/manage-themes/services/themes-list.service";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";
import { ThemesListComponent } from "src/app/shared/themes/themes-list/themes-list.component";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-theme-selector",
  standalone: true,
  imports: [ThemesListComponent, SearchBarComponent, AsyncPipe, SearchEmptyComponent],
  templateUrl: "./report-settings-theme-selector.component.html",
  styleUrl: "./report-settings-theme-selector.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsThemeSelectorComponent implements OnChanges {
  @Input({ required: true }) themes!: ThemeEnhanced[];

  // Services
  public themesListService = inject(ThemesListService);
  public reportsService = inject(ReportsService);

  // Properties
  public themeSelectionOverlay = false;
  public filteredThemes: Array<ThemeEnhanced> = [];
  public allThemesHidden: boolean = false;
  public searchTerm: string = "";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["themes"]) {
      this.applySearch();
    }
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredThemes = this.themes.map((theme) => ({
      ...theme,
      hidden: !theme.title.toLowerCase().includes(lowerSearchTerm),
    }));

    this.allThemesHidden = this.filteredThemes.every((theme) => theme.hidden);
  }

  /**
   * Update Chosen Theme
   * @param theme
   */
  public updateChosenTheme(theme: ThemeEnhanced): void {
    this.reportsService.themeDetails.next(theme);
    this.toggleThemeSelectionOverlay();
  }

  /**
   * Toggle Theme Selection Overlay
   */
  public toggleThemeSelectionOverlay(): void {
    this.themeSelectionOverlay = !this.themeSelectionOverlay;
  }
}
