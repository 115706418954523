import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
  selector: "manage-assignee-edit-header",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./manage-assignee-edit-header.component.html",
  styleUrl: "./manage-assignee-edit-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageAssigneeEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Output() inviteAsMemberOutput = new EventEmitter<void>();

  /**
   * Invite As Member
   */
  public inviteAsMember(): void {
    this.inviteAsMemberOutput.emit();
  }
}
