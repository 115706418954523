<div class="grid grid-cols-1 gap-y-8">
  <div class="grid grid-cols-1 gap-4">
    <div class="content-card-wrapper">
      <div class="content-card-header flex justify-between">
        <h3>{{ currentCategoryOrder }}.{{ testForm.value.order + 1 }} {{ testForm.value.mode }}</h3>
        <div class="flex flex-row items-center">
          <button type="button" class="btn-secondary-small-default" (click)="enableOrDisableTest()">
            {{ testForm.value.enabled ? "Disable Question" : "Enable Question" }}
          </button>
        </div>
      </div>
      <div class="content-card px-6 py-5">
        <p class="text-lg font-semibold text-text_Title">{{ testForm.value.title }}</p>
      </div>
    </div>
  </div>
</div>
