import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'inspection-signoff-edit-details',
  standalone: true,
  imports: [JsonPipe, ReactiveFormsModule],
  templateUrl: './inspection-signoff-edit-details.component.html',
  styleUrl: './inspection-signoff-edit-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionSignoffEditDetailsComponent {
  @Input({ required: true }) signatureForm!: FormGroup;
}
