<div class="content-card-wrapper">
  <div class="content-card-header">
    <h3>Sign Off</h3>
  </div>
  <div class="px-6 py-5">
    <div class="content-grid gap-y-4">
      <div class="flow-root px-6">
        <ul role="list" class="-mb-8">
          @for (signature of signatures; track $index; let last = $last) {
            <li>
              <div class="relative pb-8">
                @if (!last) {
                  <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                }
                <div class="relative flex items-center space-x-3">
                  <div>
                    <span class="flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white" [ngClass]="signature.date_signed ? 'bg-green-500' : 'bg-gray-300'">
                      <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </div>
                  <div class="flex min-w-0 flex-1 justify-between space-x-4">
                    <div>
                      <p class="text-base text-text_Title">
                        {{ signature.name }}
                        @if (signature.date_signed) {
                          signed off -
                          <span class="font-normal text-gray-400">
                            {{ signature.date_signed.toDate() | date: 'dd MMM yyyy' }}
                          </span>
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          }
        </ul>
      </div>

      <div class="inline-flex">
        <a [routerLink]="['/', 'workspace', this.workspaceId, 'folders', this.folderId, 'inspections', this.inspectionId, 'signoff']" class="btn btn-secondary">Add or edit Signatures</a>
      </div>
    </div>
  </div>
</div>
