@if (combinedData$ | async; as data) {
  <header>
    <!-- Breadcrumbs-->
    <div class="px-6 pb-2">
      <nav aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-2">
          <li>
            <div class="flex items-center">
              <a [routerLink]="['/workspace', workspaceId, 'manage', 'roles']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Roles</a>
            </div>
          </li>
        </ol>
      </nav>
    </div>

    <!-- Header -->
    <div class="page-header-wrapper">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="page-header">Role Overview</h2>
        </div>
      </div>
    </div>
  </header>

  <div class="grid grid-cols-1 gap-y-8">
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Details</h3>
      </div>
      <form [formGroup]="roleForm" class="content-card">
        <div class="content-grid gap-y-6">
          <div>
            <div class="text-sm text-text_Subtitle">Title</div>
            <div class="text-base text-text_Title">{{ roleForm.value.title }}</div>
          </div>
          <div>
            <div class="text-sm text-text_Subtitle">Description</div>
            <div class="text-base text-text_Title">{{ roleForm.value.description }}</div>
          </div>
        </div>
      </form>
    </div>
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Permissions</h3>
      </div>
      <div class="content-card">
        <permissions-grid [roleForm]="roleForm" [isDisabled]="true" />
      </div>
    </div>
    @if (data.members.length > 0) {
      <manage-role-assigned-members [assignedMembers]="data.members" />
    }
    @if (data.invites.length > 0) {
      <manage-role-assigned-invites [assignedInvites]="data.invites" />
    }
  </div>
}
