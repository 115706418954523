<div [nxtSortablejs]="photos" [config]="eventOptions" class="flex flex-row flex-wrap space-x-2 space-y-3">
  @for (photo of photos; track photo.id; let first = $first; let i = $index) {
    <div>
      <div class="relative h-40 w-40 cursor-move" [ngClass]="photos.indexOf(photo) === 0 ? 'ml-2 mt-3' : 'ml-0 mt-0'">
        <!-- TODO: Requires a link for annotatorWeb to route to correct annotation component / page / route -->

        <div class="relative mb-3 mr-3 block h-full w-full rounded-lg bg-gray-100 bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + photo.image_thumbnail_url + ')' }"></div>

        <button
          aria-label="Delete Photo"
          type="button"
          class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500"
          (click)="deletePhoto(photo.id)">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
          </svg>
        </button>

        <a
          [routerLink]="['/workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'categories', categoryId, 'items', itemId, 'photos', photo.id]"
          class="absolute right-2 top-10 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Primary text-white hover:bg-brand_Primary-hover">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
          </svg>
        </a>

        <div class="absolute bottom-2 left-2 space-y-2">
          @if (photo.annotation_thumbnail_url) {
            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-slate-700 bg-opacity-35 text-sm text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
              </svg>
            </div>
          }

          <div class="flex h-8 w-8 items-center justify-center rounded-full bg-slate-700 bg-opacity-75 text-sm text-white">{{ i + 1 }}</div>
        </div>
      </div>
    </div>
  }
</div>
