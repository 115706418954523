import { AsyncPipe, NgClass } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RolePermissions } from "cip";
import { filter, Observable } from "rxjs";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { ToolTipsStringService } from "src/app/core/services/strings/tooltips.service";
import { RoleEnhanced } from "src/app/models/role/role.model";
import { ToolTipFilter, ToolTipType } from "src/app/models/strings/strings.model";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { TooltipComponent } from "src/app/shared/tooltip/tooltip.component";
import { RolesListService } from "../../services/roles-list.service";
import { ManageRolesListCustomComponent } from "../manage-roles-list-custom/manage-roles-list-custom.component";
import { ManageRolesListDefaultComponent } from "../manage-roles-list-default/manage-roles-list-default.component";
import { ManageRolesListHeaderComponent } from "../manage-roles-list-header/manage-roles-list-header.component";

@Component({
  selector: "manage-roles-list-wrapper",
  standalone: true,
  imports: [LoadingSpinnerComponent, NgClass, AsyncPipe, ManageRolesListHeaderComponent, TooltipComponent, EmptyListComponent, ManageRolesListDefaultComponent, ManageRolesListCustomComponent],
  templateUrl: "./manage-roles-list-wrapper.component.html",
  styleUrl: "./manage-roles-list-wrapper.component.scss",
})
export class ManageRolesListWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private rolesListService = inject(RolesListService);
  private toolTipsStringService = inject(ToolTipsStringService);
  private limitationManagerService = inject(LimitationManagerService);
  private router = inject(Router);

  // Properties
  private _workspaceId!: string;
  public roles$!: Observable<RoleEnhanced[]>;
  public defaultRoles = this.rolesListService.defaultRoles;
  public selectedTab: string = "Default";
  public toolTipRoleDefault: ToolTipFilter = this.toolTipsStringService.getToolTip(ToolTipType.RoleDefault);
  public toolTipRoleCustom: ToolTipFilter = this.toolTipsStringService.getToolTip(ToolTipType.RoleCustom);

  ngOnInit(): void {
    this.getRolesList();
  }

  /**
   * Get Roles List
   */
  private async getRolesList(): Promise<void> {
    try {
      this.roles$ = this.rolesListService.getRolesList$(this.workspaceId).pipe(filter((data) => !!data));
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Route To Role Editor
   */
  public async routeToRoleEditor(): Promise<void> {
    const featureAction: keyof RolePermissions = "role_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "roles", "new"]);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Select Tab
   * @param tab
   */
  public selectTab(tab: string): void {
    this.selectedTab = tab;
  }
}
