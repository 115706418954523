import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "test-low-high",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: "./test-low-high.component.html",
  styleUrl: "./test-low-high.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestLowHighComponent {
  @Input({ required: true }) testForm!: FormGroup;
  public answerType = [
    { title: "Low", value: "low" },
    { title: "High", value: "high" },
  ];
}
