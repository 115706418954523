@if (roleForm) {
  <form [formGroup]="roleForm" class="grid gap-x-8 gap-y-6 sm:col-span-2">
    <div formGroupName="permissions" class="border-b pb-6">
      @if (infoViewPermissionsEditor) {
        <div class="mb-6">
          <info-view [type]="infoViewPermissionsEditor.type" [message]="infoViewPermissionsEditor.message" />
        </div>
      }

      <div class="pb-2 text-base font-semibold leading-6 text-gray-900">Inspection Permissions</div>
      <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <!-- Folders -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Folders</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.folder_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="folder_create_update" formControlName="folder_create_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.folder_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="folder_delete" aria-describedby="folder_delete" formControlName="folder_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Inspections -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Inspections</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.inspection_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input
                  id="inspection_create_update"
                  aria-describedby="inspection_create_update"
                  formControlName="inspection_create_update"
                  type="checkbox"
                  class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.inspection_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="inspection_delete" aria-describedby="inspection_delete" formControlName="inspection_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Categories -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Categories</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.category_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input
                  id="category_create_update"
                  aria-describedby="category_create_update"
                  formControlName="category_create_update"
                  type="checkbox"
                  class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.category_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="category_delete" aria-describedby="category_delete" formControlName="category_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Items -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Items</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.item_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="item_create_update" aria-describedby="item_create_update" formControlName="item_create_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.item_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="item_delete" aria-describedby="item_delete" formControlName="item_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Photos -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Photos</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.photo_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="photo_create_update" aria-describedby="photo_create_update" formControlName="photo_create_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.photo_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="photo_delete" aria-describedby="photo_delete" formControlName="photo_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Actions -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Actions</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.action_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="action_create_update" aria-describedby="action_create_update" formControlName="action_create_update" type="checkbox" class="form-checkbox h-5 w-5 text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.action_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="action_delete" aria-describedby="action_delete" formControlName="action_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Signatures -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Signatures</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.signature_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input
                  id="signature_create_update"
                  aria-describedby="signature_create_update"
                  formControlName="signature_create_update"
                  type="checkbox"
                  class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.signature_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="signature_delete" aria-describedby="signature_delete" formControlName="signature_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="permissions">
      <div class="pb-2 text-base font-semibold leading-6 text-gray-900">Workspace Permissions</div>
      <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <!-- Workspaces -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Current Workspace</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.workspace_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Update</div>
              @if (!isDisabled) {
                <input id="workspace_update" aria-describedby="workspace_update" formControlName="workspace_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Members -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Members</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.member_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="member_update" aria-describedby="member_update" formControlName="member_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.member_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="member_delete" aria-describedby="member_delete" formControlName="member_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Invites -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Invites</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.invite_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="invite_create_update" aria-describedby="invite_create_update" formControlName="invite_create_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.invite_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="invite_delete" aria-describedby="invite_delete" formControlName="invite_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Roles -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Roles</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.role_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="role_create_update" aria-describedby="role_create_update" formControlName="role_create_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.role_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="role_delete" aria-describedby="role_delete" formControlName="role_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Themes -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Themes</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.theme_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="theme_create_update" aria-describedby="theme_create_update" formControlName="theme_create_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.theme_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="theme_delete" aria-describedby="theme_delete" formControlName="theme_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Assignees -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Assignees</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.assignee_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input
                  id="assignee_create_update"
                  aria-describedby="assignee_create_update"
                  formControlName="assignee_create_update"
                  type="checkbox"
                  class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.assignee_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="assignee_delete" aria-describedby="assignee_delete" formControlName="assignee_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Clients -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Clients</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.client_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="client_create_update" aria-describedby="client_create_update" formControlName="client_create_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.client_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="client_delete" aria-describedby="client_delete" formControlName="client_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Sites -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Sites</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.site_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input id="site_create_update" aria-describedby="site_create_update" formControlName="site_create_update" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.site_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="site_delete" aria-describedby="site_delete" formControlName="site_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>

        <!-- Templates -->
        <div class="col-span-1">
          <div class="pb-2 text-sm font-medium uppercase text-text_Title">Templates</div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.template_create_update) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Create / Update</div>
              @if (!isDisabled) {
                <input
                  id="template_create_update"
                  aria-describedby="template_create_update"
                  formControlName="template_create_update"
                  type="checkbox"
                  class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
          <div class="flex flex-row items-center">
            @if (roleForm.value.permissions.template_delete) {
              <ng-container [ngTemplateOutlet]="tickIcon"></ng-container>
            } @else {
              <ng-container [ngTemplateOutlet]="crossIcon"></ng-container>
            }
            <div class="flex w-full flex-row items-center justify-between">
              <div class="permission-list-title">Delete</div>
              @if (!isDisabled) {
                <input id="template_delete" aria-describedby="template_delete" formControlName="template_delete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
}

<ng-template #tickIcon>
  <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-6 w-6 text-score_100" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
</ng-template>

<ng-template #crossIcon>
  <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-6 w-6 text-score_0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
</ng-template>

<ng-template #disabled> </ng-template>

<!-- Accordion Icons -->
<ng-template #accordionDown>
  <div class="px-8">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  </div>
</ng-template>

<ng-template #accordionUp>
  <div class="px-8">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
    </svg>
  </div>
</ng-template>
