@if (reportsService.themeDetails | async; as themeSettings) {
  <div class="px-4">
    <div class="py-6">
      <h3 class="-my-3 flow-root">
        <div type="button" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400">
          <span class="font-medium text-gray-900">Theme</span>
        </div>
      </h3>
      <div class="pt-6">
        <div class="space-y-4">
          <div class="flex items-center">
            <div class="flex w-full cursor-pointer items-center justify-between rounded-md px-3 py-2.5 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 dark:bg-gray-700 dark:ring-gray-600" (click)="toggleThemeSelectionOverlay()">
              <div class="form-input">{{ themeSettings?.title }}</div>

              <svg xmlns="http://www.w3.org/2000/svg" class="-mr-1 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#C8C8C8" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  @if (themeSelectionOverlay) {
    <div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex min-h-screen items-center justify-center text-center">
        <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
        <div class="inline-block h-[600px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
          <div class="flex h-full flex-1 flex-col justify-between">
            <!-- Header Title & New -->
            <div class="flex flex-row items-center bg-white px-6 py-6">
              <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">Select Theme</h3>
            </div>

            <div class="flex h-full flex-1 overflow-hidden">
              <!-- [formGroup]="inspectionForm"-->
              <div class="w-full space-y-4 overflow-y-scroll overscroll-contain">
                <!-- Search -->
                <div class="mt-4 flex w-full flex-col px-12 sm:flex-row">
                  <search-bar [placeholder]="'Search Themes'" (searchTermChange)="onSearch($event)" />
                </div>

                <div class="px-6 py-8">
                  <!-- Selected Theme -->
                  <div class="mb-10 block">
                    <div class="mb-3 flex items-center justify-between">
                      <div class="container-header" style="padding-bottom: 0">Current Theme</div>
                    </div>

                    <ul class="space-y-4 rounded-md bg-white px-8 py-5 shadow-sm">
                      @for (theme of themes; track theme.id) {
                        @if (theme.id === themeSettings?.id) {
                          <li>
                            <themes-list [theme]="theme" [listLocation]="'selectReportTheme'"></themes-list>
                          </li>
                        }
                      }
                    </ul>
                  </div>

                  @if (!allThemesHidden) {
                    <ul id="themesList" class="space-y-4 rounded-md bg-white px-8 py-5 shadow-sm">
                      @for (theme of filteredThemes; track theme.id) {
                        @if (!theme.hidden) {
                          <li>
                            @if (theme.id !== themeSettings?.id) {
                              <themes-list [theme]="theme" [listLocation]="'selectReportTheme'" (themeSelectionOutput)="updateChosenTheme($event)" />
                            }
                          </li>
                        }
                      }
                    </ul>
                  }
                </div>
                @if (allThemesHidden) {
                  <search-empty [searchTerm]="searchTerm" />
                }
              </div>
            </div>

            <!-- Actions -->
            <div class="flex justify-end bg-gray-100 px-6 py-6">
              <button type="button" class="btn btn-cancel" (click)="toggleThemeSelectionOverlay()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
