import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, signal } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActionAssigneeType, User } from "cip";
import { AssigneeEnhanced } from "src/app/models/assignee/assignee.model";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { ManageAssigneeEditDetailsComponent } from "src/app/sections/workspaces/current-workspace/manage-assignees/edit/manage-assignee-edit-details/manage-assignee-edit-details.component";
import { AssigneeDetailService } from "src/app/sections/workspaces/current-workspace/manage-assignees/services/assignee-detail.service";
import { ActionEditAssigneeMemberSelectorComponent } from "../action-edit-assignee-member-selector/action-edit-assignee-member-selector.component";
import { ActionEditAssigneeSelectorComponent } from "../action-edit-assignee-selector/action-edit-assignee-selector.component";

@Component({
  selector: "action-edit-assignee-selector-wrapper",
  standalone: true,
  imports: [NgClass, ActionEditAssigneeSelectorComponent, ManageAssigneeEditDetailsComponent, ActionEditAssigneeMemberSelectorComponent, AsyncPipe],
  templateUrl: "./action-edit-assignee-selector-wrapper.component.html",
  styleUrl: "./action-edit-assignee-selector-wrapper.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEditAssigneeSelectorWrapperComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) actionForm!: FormGroup;
  @Input({ required: true }) user!: User;
  @Output() toggleAssigneeSelectorWrapperOverlayOutput = new EventEmitter<void>();

  // Services
  private fb = inject(FormBuilder);
  private assigneeDetailService = inject(AssigneeDetailService);

  // Properties
  public overlay_assigneeForm = signal<boolean>(false);

  public overlay_assigneeSelector: boolean = false;
  public selectedTab = "Members";
  public assigneeForm: FormGroup;

  constructor() {
    this.assigneeForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", Validators.required],
      email: null,
      company: null,
      created_by: null,
      created_by_id: null,
    });
  }

  async saveAssignee(): Promise<void> {
    const assigneeType: ActionAssigneeType = "external";
    try {
      const assignee = await this.assigneeDetailService.saveAssigneeDoc(this.workspaceId, "new", this.assigneeForm.value, this.user);
      this.actionForm.patchValue({
        assignee_id: assignee.id,
        assignee_name: assignee.name,
        assignee_type: assigneeType,
        assignee_email: assignee.email,
        assignee_company: assignee.company,
      });
      this.actionForm.markAsDirty();
      this.assigneeForm.markAsPristine();
      this.toggleAssigneeFormOverlay(false);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Select Assignee
   * @param assignee
   */
  public selectAssignee(assignee: AssigneeEnhanced): void {
    const assigneeType: ActionAssigneeType = "external";
    this.actionForm.patchValue({
      assignee_id: assignee.id,
      assignee_name: assignee.name,
      assignee_type: assigneeType,
      assignee_email: assignee.email ? assignee.email : null,
      assignee_company: assignee.company ? assignee.company : null,
    });
    this.actionForm.markAsDirty();
    this.toggleAssigneeSelectorOverlay();
    this.toggleAssigneeSelectorWrapperOverlay();
  }

  /**
   * Select Member
   * @param member
   */
  public selectMember(member: MemberEnhanced): void {
    const assigneeType: ActionAssigneeType = "internal";
    this.actionForm.patchValue({
      assignee_id: member.id,
      assignee_name: `${member.name_first} ${member.name_last}`,
      assignee_type: assigneeType,
      assignee_email: member.email,
    });
    this.actionForm.markAsDirty();
    this.toggleAssigneeSelectorWrapperOverlay();
  }

  /**
   * Select the tab
   * @param value
   */
  public selectTab(value: string): void {
    this.selectedTab = value;
  }

  /**
   * Toggle the assignee selector overlay
   */
  public toggleAssigneeSelectorOverlay(): void {
    this.overlay_assigneeSelector = !this.overlay_assigneeSelector;
  }

  /**
   * Toggle the assignee form overlay
   * @param value
   */
  public toggleAssigneeFormOverlay(value: boolean): void {
    this.overlay_assigneeForm.set(value);
    this.assigneeForm.reset();
  }

  /**
   * Toggles the assignee selector wrapper overlay
   */
  toggleAssigneeSelectorWrapperOverlay(): void {
    this.toggleAssigneeSelectorWrapperOverlayOutput.emit();
  }
}
