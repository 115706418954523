<!-- Dummy breadcrumb element-->
<div class="px-6 pb-2"></div>
<!-- Header -->
<div class="page-header-wrapper">
  <div class="md:flex md:items-center md:justify-between">
    <div class="min-w-0 flex-1">
      <h2 class="page-header">Delete Account</h2>
    </div>
  </div>
</div>

<!-- Content -->
<div class="grid grid-cols-1 gap-y-8">
  <div class="content-card-wrapper">
    <div class="px-6 py-5">
      <div class="content-grid gap-x-4 gap-y-6">
        <p class="list-title">Are you sure you want to delete your account?</p>
        <p class="list-meta">Deleting your {{ user.email }} Checklist Inspector Pro account is permanent and cannot be undone. Make sure you read and understand the following:</p>
        <ul class="list-disc space-y-4">
          <li class="text-red-500">
            <div class="list-meta">You will be logged out of Checklist Inspector Pro on all your devices after you submit your delete request.</div>
          </li>
          <li class="text-red-500">
            <div class="list-meta">Any Workspaces that you are an Owner of will be deleted, along with all Workspace data.</div>
          </li>
          <li class="text-red-500">
            <div class="list-meta">If Members of Workspaces you are an Owner of still need data, you should instead transfer ownership of the Workspace to someone else.</div>
          </li>
          <li class="text-red-500">
            <div class="list-meta">Deleting your account does not cancel your Checklist Inspector Pro subscription. You must cancel the subscription through your Apple Account. Follow the link below to do that:</div>
          </li>
        </ul>

        <a href="https://support.apple.com/en-gb/HT202039" target="_blank" class="text-sm text-brand_Primary hover:text-brand_Primary-hover">Manage Subscription</a>

        <p class="list-meta">To permanently delete your account, confirm your account email address.</p>

        <div class="form-input-wrapper">
          <label for="account-email-address" class="form-input-label">Account Email Address</label>
          <input type="text" name="account-email-address" id="title" class="form-input" [(ngModel)]="accountEmailAddress" />
        </div>
      </div>
    </div>
  </div>
  <!-- Delete Button -->
  <div class="flex flex-row items-center justify-end px-6 sm:px-8">
    <div class="sm:flex sm:flex-row-reverse">
      <button type="button" class="btn ml-3" [ngClass]="accountEmailAddress === user.email ? 'btn-destructive' : 'btn-disabled cursor-not-allowed'" [disabled]="accountEmailAddress !== user.email" (click)="authService.toggleDeleteAccountOverlay()">
        Delete Account
      </button>
    </div>
  </div>
</div>

@if (authService.overlay_deleteAccount) {
  <delete-confirmation [deleteTitle]="authService.deleteOverlayTitle" [deleteMessage]="authService.deleteOverlayDescription" (cancelCommandEmitter)="authService.toggleDeleteAccountOverlay()" (deleteCommandEmitter)="authService.deleteAccount()" />
}
