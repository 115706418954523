<form [formGroup]="themeSettingsForm">
  <div class="border-b border-gray-200 py-6">
    <fieldset>
      <span class="mb-4 block text-sm font-medium text-gray-900">Chart</span>
      <div class="space-y-4">
        @for (chart of themeStylesByType["chart"]; track $index; let i = $index) {
          <div class="charts-center flex">
            <label class="charts-center flex cursor-pointer" for="chart{{ i }}">
              <input type="radio" class="h-4 w-4 border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="styles_chart" [value]="chart.id" id="chart{{ i }}" (change)="updateCustomThemeStyles()" />
              <span class="ml-3 cursor-pointer text-sm text-text_Title">{{ chart.title }}</span>
            </label>
          </div>
        }
      </div>
    </fieldset>
  </div>
</form>
