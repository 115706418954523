@if (folders$ | async; as folders) {
  <div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex min-h-screen items-center justify-center text-center">
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
      <div
        class="inline-block w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle"
        [ngClass]="templateDetailService.overlay_foldersList ? 'h-[600px]' : 'h-auto'">
        <div class="flex h-full flex-1 flex-col justify-between">
          <!-- Header Title -->
          <div class="flex flex-row items-center bg-white px-6 py-6">
            <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">Create Inspection From Template</h3>
          </div>

          <div class="flex h-full flex-1 overflow-hidden">
            <div class="w-full space-y-4 overflow-y-scroll overscroll-contain">
              <!-- Confirmation -->
              @if (templateDetailService.overlay_duplicateConfirmation) {
                <ng-container [ngTemplateOutlet]="confirmation" />
              }

              <!-- Duplication -->
              @if (!templateDetailService.overlay_duplicateConfirmation && duplicationObject) {
                <ng-container [ngTemplateOutlet]="duplicating" />
              }

              <!-- Folders -->
              @if (templateDetailService.overlay_foldersList) {
                <!-- Search & New Button -->
                <div class="mt-4 flex w-full flex-col px-12 sm:flex-row" [ngClass]="folders.length > 0 ? 'justify-between' : 'justify-end'">
                  @if (folders.length > 0) {
                    <search-bar [placeholder]="'Search Folders'" (searchTermChange)="onSearch($event)" />
                  }
                  <button type="button" class="btn btn-primary" (click)="showNewFolderOverlay()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Folder
                  </button>
                </div>

                <div class="px-6 py-8">
                  <!-- Folder List -->
                  @if (templateDetailService.currentlySelectedFolder; as currentlySelectedFolder) {
                    <div class="mb-10 block">
                      <div class="mb-3 flex items-center justify-between">
                        <div class="container-header" style="padding-bottom: 0">Current Folder</div>
                        <div class="cip-transition flex cursor-pointer flex-row items-center rounded-full bg-red-50 px-2 py-1 text-xs text-red-800 hover:bg-red-200" (click)="unselectFolder()">
                          <div class="mr-2 rounded-full bg-red-400 p-1 text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-2.5 w-2.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                          Unselect
                        </div>
                      </div>

                      <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
                        <div class="mb-4">
                          <div class="list-row">
                            <div class="list-a group cursor-pointer border-none" (click)="folderSelection(currentlySelectedFolder)">
                              <div [ngStyle]="{ color: currentlySelectedFolder.colour }">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="ml-2.5 h-6 w-6 sm:ml-0">
                                  <path
                                    d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
                                </svg>
                              </div>
                              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                                <div class="min-w-0 flex-auto">
                                  <p class="list-title">{{ currentlySelectedFolder.title }}</p>
                                  <p class="list-meta">{{ currentlySelectedFolder.inspections_count }} {{ currentlySelectedFolder.inspections_count === 1 ? "Inspection" : "Inspections" }}</p>
                                  <p class="list-meta-second">{{ currentlySelectedFolder.created_by }}</p>
                                </div>
                              </div>
                              <div class="flex items-center text-brand_Primary">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
                                  <path
                                    fill-rule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                    clip-rule="evenodd" />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <!-- Other Folders -->
                  @if (allOtherFolders.length > 0) {
                    <div class="mb-3 flex items-center justify-between">
                      <div class="container-header" style="padding-bottom: 0">Select New Folder</div>
                    </div>
                    <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
                      @for (folder of filteredFolders; track folder.id; let last = $last) {
                        @if (!folder.hidden) {
                          <div class="list-row" [ngClass]="last ? 'border-none' : 'border-b'">
                            @if (templateDetailService.folderId !== folder.id) {
                              <div class="list-a group cursor-pointer" (click)="folderSelection(folder)">
                                <div [ngStyle]="{ color: folder.colour }">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="ml-2.5 h-6 w-6 sm:ml-0">
                                    <path
                                      d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
                                  </svg>
                                </div>
                                <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                                  <div class="min-w-0 flex-auto">
                                    <p class="list-title">{{ folder.title }}</p>
                                    <p class="list-meta">{{ folder.inspections_count }} {{ folder.inspections_count === 1 ? "Inspection" : "Inspections" }}</p>
                                    <p class="list-meta-second">{{ folder.created_by }}</p>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        }
                      }
                    </div>
                  }
                </div>
              }
              @if (allFoldersHidden) {
                <search-empty [searchTerm]="searchTerm" />
              }
            </div>
          </div>

          <!-- Updated Footer with conditional rendering -->
          @if (!templateDetailService.overlay_duplicateConfirmation && !duplicationObject) {
            <div class="flex justify-end bg-gray-100 px-6 py-6">
              <button type="button" class="btn btn-cancel" (click)="hideFolderSelectionOverlay()">Close</button>
            </div>
          }

          @if (duplicationObject) {
            <div class="flex justify-end bg-gray-100 px-6 py-6">
              @if (duplicationObject.status === "success" || duplicationObject.status === "failed") {
                <button type="button" class="btn btn-cancel" (click)="toggleDuplicationOverlay()">Close</button>
              }
              @if (duplicationObject.status === "success") {
                <button type="button" class="btn btn-primary ml-3 w-auto" (click)="navigateToNewInspection()">Go to new {{ navigateToText }}</button>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}

<!-- Confirmation Template -->
<ng-template #confirmation>
  <div class="p-6">
    <div class="bg-white">
      <div class="grid grid-cols-1 gap-6 px-6 pt-6 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <info-view [message]="infoViewCreateInspectionFromTemplate.message" [type]="infoViewCreateInspectionFromTemplate.type" />
        </div>
        <div class="sm:col-span-6">
          <div class="form-input-wrapper">
            <label for="inspection-title" class="form-input-label">
              Inspection Title*
              @if (documentTitle.value === "") {
                <span class="required-field"> - Title is required</span>
              }
            </label>
            <input #documentTitle type="text" name="inspection-title" id="inspection-title" class="form-input" placeholder="Inspection Title" [(ngModel)]="templateTitle" />
          </div>
        </div>

        <div class="sm:col-span-6">
          <div class="form-input-wrapper relative flex h-[60px] cursor-pointer items-center hover:ring-gray-400" (click)="showFoldersList()">
            <div class="text-brand_Primary-hover">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-4 w-4">
                <path
                  d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
              </svg>
            </div>

            <div class="w-full border-0 pl-3 text-base text-text_Title focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-700 sm:leading-6">
              {{ templateDetailService.folderTitle ? templateDetailService.folderTitle : "Select Folder" }}
            </div>
            <div class="absolute left-0 top-0 flex h-full w-full items-center justify-end pr-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="-mr-1 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#C8C8C8" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
        <div class="sm:col-span-6"></div>
      </div>
    </div>
  </div>

  <!-- Actions: Duplicate or Cancel-->
  <div class="flex-row-reverse bg-gray-100 p-6 sm:flex">
    <button
      type="button"
      class="btn btn-primary ml-3"
      (click)="duplicateTemplateAsInspection()"
      [disabled]="!documentTitle.value || !templateDetailService.folderId"
      [ngClass]="!documentTitle.value || !templateDetailService.folderId ? 'btn-disabled hover cursor-not-allowed' : 'btn-primary'">
      Create Inspection
    </button>
    <button type="button" class="btn btn-cancel ml-3 w-[100px]" (click)="toggleDuplicationOverlay()">Close</button>
  </div>
</ng-template>

<!-- Duplicating Template -->
<ng-template #duplicating>
  <div class="my-10 flex flex-col items-center">
    @switch (duplicationObject.status) {
      @case ("pending") {
        <ng-container [ngTemplateOutlet]="inProgress" />
      }
      @case ("running") {
        <ng-container [ngTemplateOutlet]="inProgress" />
      }
      @case ("success") {
        <ng-container [ngTemplateOutlet]="success" />
      }
      @case ("failed") {
        <ng-container [ngTemplateOutlet]="failed" />
      }
    }
  </div>
</ng-template>

<!-- In Progress Template -->
<ng-template #inProgress>
  <div class="my-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
    <div class="col-span-6 flex justify-center">
      <svg class="-ml-1 h-10 w-10 animate-spin text-brand_Primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <div class="col-span-6 flex justify-center">
      <p class="text-sm text-text_Subtitle">Creating Inspection from Template</p>
    </div>
  </div>
</ng-template>

<!-- Success Template -->
<ng-template #success>
  <div class="my-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
    <div class="col-span-6 flex justify-center">
      <div class="flex h-10 w-10 items-center justify-center rounded-full bg-brand_Primary text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
        </svg>
      </div>
    </div>
    <div class="col-span-6 flex justify-center">
      <p class="mt-3 text-sm text-text_Subtitle">Successfully created Inspection</p>
    </div>
  </div>
</ng-template>

<!-- Error Template -->
<ng-template #failed>
  <div class="mt-8">
    <div class="flex flex-col items-center justify-center">
      <div class="flex h-10 w-10 items-center justify-center rounded-full bg-red-500 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" class="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
      </div>
      <p class="mt-3 text-sm text-text_Subtitle">Failed to create Inspection</p>
      <p class="mt-3 text-sm capitalize text-text_Subtitle">{{ duplicationObject.error?.code }}</p>
      <p class="mt-3 text-center text-sm capitalize text-text_Subtitle">{{ duplicationObject.error?.message }}</p>
    </div>
  </div>
</ng-template>
