<header>
  <!-- Breadcrumbs-->
  <div class="px-6 pb-2">
    <nav aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-2">
        <li>
          <div class="flex items-center">
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'assignees']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Assignees</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>

  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="flex min-w-0 flex-1 justify-between">
        <h2 class="page-header">Edit Assignee</h2>
        <button aria-label="Invite As Member" type="button" class="btn btn-secondary" (click)="inviteAsMember()">Invite as Member</button>
      </div>
    </div>
  </div>
</header>
