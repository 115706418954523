<div class="category-wrapper">
  <div class="category-content">
    <div class="category-header">
      <p class="category-title text-group-1" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">1. Personnel inspection</p>
      <div class="category-items text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">6 Items</div>
      <div class="category-header-border" [ngStyle]="{ 'background-color': themeSettingsForm.value.colours_brand }"></div>
    </div>

    <p class="category-notes text-group-4" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">
      This category focuses on the evaluation of the bank's workforce. It encompasses aspects such as staffing, staff training and development, compliance with HR policies, and adherence to code of conduct. Ensuring a well-trained, motivated,
      workforce is crucial for maintaining top-notch customer service and operational efficiency.
    </p>
  </div>

  <div class="category-items-wrapper">
    <manage-theme-edit-preview-item [themeSettingsForm]="themeSettingsForm" />
  </div>
</div>
