<form autocomplete="off" [formGroup]="testForm">
  <div class="relative flex h-[47px] flex-row justify-evenly overflow-hidden rounded-md bg-[#F0F4F7]">
    @for (result of answerType; track result.value; let last = $last) {
      <label class="z-10 flex w-1/3 cursor-pointer items-center justify-center border-[#C8C8C8] transition-all duration-300 hover:opacity-40" [ngClass]="last ? 'border-none' : 'border-r'">
        <input id="{{ result.title }}" type="radio" class="sr-only" [value]="result.value" [formControlName]="'test_answer'" (click)="onRadioClick($event, result.value)" />
        <span
          class="text-base font-medium text-text_Title"
          [ngClass]="{
            'text-white': result.value === testForm.get('test_answer')?.value && result.value !== 'amber',
          }">
          {{ result.title }}
        </span>
      </label>
    }
    <div class="absolute left-0 top-0 z-[5] flex h-full w-full flex-row">
      <div
        class="w-1/3 transition-all duration-300"
        [ngClass]="{
          'answerOne bg-score_0': testForm.get('test_answer')?.value === 'red',
          'answerTwo bg-score_50': testForm.get('test_answer')?.value === 'amber',
          'answerThree bg-score_100': testForm.get('test_answer')?.value === 'green',
        }"></div>
    </div>
  </div>
</form>
