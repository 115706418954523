import { Component } from '@angular/core';
import { ConnectedDevicesComponent } from '../connected-devices.component';

@Component({
  selector: 'connected-devices-overlay',
  standalone: true,
  imports: [ConnectedDevicesComponent],
  templateUrl: './connected-devices-overlay.component.html',
  styleUrl: './connected-devices-overlay.component.scss',
})
export class ConnectedDevicesOverlayComponent {}
