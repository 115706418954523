@if (introduction$ | async; as supplementary) {
  <header>
    <!-- Breadcrumbs-->
    <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" />

    <!-- Header -->
    <div class="page-header-wrapper">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="page-header">Edit Introduction</h2>
        </div>
      </div>
    </div>
  </header>

  <!-- Form -->
  <inspection-supplementary-details [supplementaryForm]="introductionForm" (saveSupplementaryOutput)="saveSupplementaryForm()" />
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}
