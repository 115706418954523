import { AsyncPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "cip";
import { filter, map, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { NavigationService } from "src/app/core/services/navigation.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { SignatureEnhanced } from "src/app/models/inspection/signature.model";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { PhotoDropzoneComponent } from "src/app/shared/photo-dropzone/photo-dropzone.component";
import { PhotoPreviewComponent } from "src/app/shared/photo-preview/photo-preview.component";
import { PhotoUploadSingleComponent } from "src/app/shared/photo-upload-single/photo-upload-single.component";
import { SignOffDetailService } from "../../../services/sign-off-detail.service";
import { InspectionSignoffEditDetailsComponent } from "../inspection-signoff-edit-details/inspection-signoff-edit-details.component";
import { InspectionSignoffEditHeaderComponent } from "../inspection-signoff-edit-header/inspection-signoff-edit-header.component";

@Component({
  selector: "inspection-signoff-edit-wrapper",
  standalone: true,
  imports: [
    InspectionSignoffEditHeaderComponent,
    InspectionSignoffEditDetailsComponent,
    AsyncPipe,
    LoadingSpinnerComponent,
    PhotoUploadSingleComponent,
    PhotoPreviewComponent,
    PhotoDropzoneComponent,
    InfoViewComponent,
    FormStateComponent,
    NgClass,
    DeleteOverlayComponent,
  ],
  templateUrl: "./inspection-signoff-edit-wrapper.component.html",
  styleUrl: "./inspection-signoff-edit-wrapper.component.scss",
})
export class InspectionSignoffEditWrapperComponent implements OnInit, OnDestroy {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input() set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input() set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input() set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  @Input() set signatureId(value: string) {
    this._signatureId = value;
  }

  get signatureId(): string {
    return this._signatureId;
  }

  // Services
  private authService = inject(AuthService);
  private navigationService = inject(NavigationService);
  private router = inject(Router);
  private signOffDetailService = inject(SignOffDetailService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private stringsService = inject(StringsService);

  // Properties
  public user: User;
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public _signatureId!: string;
  public signature$!: Observable<SignatureEnhanced>;
  public signatureForm: FormGroup;
  public currentSignatureImage!: string;
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos = false;
  public overlay_deleteSignaturePhoto = false;
  private tempBlobPhotoURL!: string;
  public infoViewSignatureEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.SignatureEditor);
  public deleteOverlayTitle!: string;
  public deleteOverlayDescription!: string;

  constructor() {
    this.navigationService.breadcrumbsPath = this.router.url;

    // User
    this.user = this.authService.currentUser;

    this.signatureForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      image_url: null,
      image_thumbnail_url: null,
      date_signed: null,
      order: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getSignatureDoc();
  }

  ngOnDestroy(): void {
    this.revokeObjectURL();
  }

  /**
   * Get Signature Doc
   */
  private async getSignatureDoc(): Promise<void> {
    try {
      this.signature$ = this.signOffDetailService.getSignatureDoc$(this.workspaceId, this.inspectionId, this.signatureId).pipe(
        filter((data) => !!data),
        map((data) => {
          if (data.image_thumbnail_url) {
            this.currentSignatureImage = data.image_thumbnail_url;
          }
          if (this.signatureForm.pristine && this.signatureForm.untouched) {
            this.signatureForm.patchValue(data);
          }
          return data;
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Check If Photos Are Uploading Or Create Signature
   */
  public async checkIfPhotosAreUploadingOrCreateSignature(): Promise<void> {
    if (this.uploadFileArray.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.createSignatureDoc();
    }
  }

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    if (photos.type === "signature") {
      this.signatureForm.patchValue({
        image_url: photos.image_url,
        image_thumbnail_url: photos.image_thumbnail_url,
      });
    }
    try {
      await this.createSignatureDoc();
      this.emptyUploadFileArray();
      this.revokeObjectURL();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Create Signature Doc
   */
  private async createSignatureDoc(): Promise<void> {
    try {
      await this.signOffDetailService.setCurrentSignatureDoc(this.workspaceId, this.inspectionId, this.signatureId, this.currentSignatureImage, this.signatureForm.value, this.user);
      this.signatureForm.reset();
      this.togglePhotosUploadOverlay();
      this.togglePhotoPreview();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split("/").pop();
    if (!(fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png")) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.togglePhotoPreview();
      this.signatureForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * Remove Photo From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle Photos Upload Overlay
   */
  private togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Empty Upload File Array
   */
  private emptyUploadFileArray(): void {
    this.uploadFileArray = [];
  }

  /**
   * Revoke Object URL
   */
  private revokeObjectURL(): void {
    URL.revokeObjectURL(this.tempBlobPhotoURL);
  }

  /**
   * Toggle Delete Signature Photo Overlay
   */
  toggleDeleteSignaturePhotoOverlay(): void {
    this.overlay_deleteSignaturePhoto = !this.overlay_deleteSignaturePhoto;
    const deleteSignaturePhoto = this.stringsService.alertFilter(AlertType.DeleteSignaturePhoto);
    if (deleteSignaturePhoto) {
      this.deleteOverlayTitle = deleteSignaturePhoto.title;
      this.deleteOverlayDescription = deleteSignaturePhoto.description;
    } else {
      console.error("Unknown string type:", AlertType.DeleteSignaturePhoto);
    }
  }

  /**
   * Remove Signature Image Properties From Form
   */
  async removeSignatureImagePropertiesFromForm(): Promise<void> {
    this.toggleDeleteSignaturePhotoOverlay();
    this.signatureForm.patchValue({
      image_url: null,
      image_thumbnail_url: null,
    });
    this.signatureForm.markAsDirty();
  }

  /**
   * Is Form Dirty
   * @returns
   */
  isFormDirty() {
    return this.signatureForm.dirty ? false : true;
  }
}
