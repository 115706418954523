import { inject, Injectable } from "@angular/core";
import { Export } from "cip";
import { where } from "firebase/firestore";
import { catchError, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";

@Injectable({
  providedIn: "root",
})
export class UserExportService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Export Doc
   * @param userId
   */
  public getExportDoc$(userId: string): Observable<Export[]> {
    const userPath = this.collectionsService.exportsCol(userId);
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    const queryConstraints = [where("created_at", ">", yesterday)];
    return this.firestoreUtilsService.getCollectionData<Export>(userPath, queryConstraints).pipe(
      map((data) => data || []),
      catchError((error) => {
        return of([]);
      })
    );
  }
}
