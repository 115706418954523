import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { RoleCurrent, RoleDefault } from "src/app/models/role/role.model";
import { SearchBarComponent } from "../../search-bar/search-bar.component";
import { SearchEmptyComponent } from "../../search-empty/search-empty.component";

@Component({
  selector: "role-selector-default",
  standalone: true,
  imports: [NgClass, SearchBarComponent, SearchEmptyComponent],
  templateUrl: "./role-selector-default.component.html",
  styleUrl: "./role-selector-default.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleSelectorDefaultComponent implements OnChanges {
  @Input({ required: true }) defaultRoles!: RoleDefault[];
  @Input({ required: true }) currentRole: RoleCurrent | null = null;
  @Output() selectRoleOutput = new EventEmitter<RoleDefault>();

  public filteredRoles: Array<RoleDefault> = [];
  public allRolesHidden: boolean = false;
  public searchTerm: string = "";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["defaultRoles"]) {
      this.applySearch();
    }
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredRoles = this.defaultRoles.map((role) => ({
      ...role,
      hidden: !role.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allRolesHidden = this.filteredRoles.every((role) => role.hidden);
  }

  /**
   * Select Default Role
   * @param role
   */
  public selectRole(role: RoleDefault): void {
    this.selectRoleOutput.emit(role);
  }
}
