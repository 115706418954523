import { JsonPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ItemEnhanced } from 'src/app/models/item/item.model';
import { InfoViewFilter } from 'src/app/models/strings/strings.model';
import { FormStateComponent } from 'src/app/shared/form-state/form-state.component';
import { InfoViewComponent } from 'src/app/shared/info-view/info-view.component';
import { QuestionTypesComponent } from 'src/app/shared/items/question-types/question-types.component';
import { TestTypesComponent } from 'src/app/shared/items/test-types/test-types.component';

@Component({
  selector: 'inspection-item-edit-form',
  standalone: true,
  imports: [ReactiveFormsModule, TestTypesComponent, QuestionTypesComponent, InfoViewComponent, NgClass, FormStateComponent, JsonPipe],
  templateUrl: './inspection-item-edit-form.component.html',
  styleUrl: './inspection-item-edit-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionItemEditFormComponent {
  @Input({ required: true }) itemForm!: FormGroup;
  @Input({ required: true }) item!: ItemEnhanced;
  @Input({ required: true }) infoViewItemEditorTest!: InfoViewFilter;
  @Input({ required: true }) infoViewItemEditorQuestion!: InfoViewFilter;
  @Input({ required: true }) itemId!: string;
  @Output() saveItemAndRerouteOutput = new EventEmitter<void>();
  @Output() saveItemAndAddDifferentModeOutput = new EventEmitter<void>();
  @Output() saveItemAndAddAnotherOutput = new EventEmitter<void>();
  @Output() deleteItemOutput = new EventEmitter<void>();
  /**
   * Save Item and Reroute
   */
  public saveItemAndReroute(): void {
    this.saveItemAndRerouteOutput.emit();
  }

  /**
   * Save Item and Add Different Mode
   */
  public saveItemAndAddDifferentMode(): void {
    this.saveItemAndAddDifferentModeOutput.emit();
  }

  /**
   *
   */
  public saveItemAndAddAnother(): void {
    this.saveItemAndAddAnotherOutput.emit();
  }

  /**
   * Delete Item
   */
  public deleteItem(): void {
    this.deleteItemOutput.emit();
  }
}
