import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Operation, RolePermissions, User } from "cip";
import { serverTimestamp, WithFieldValue } from "firebase/firestore";
import { filter, Observable, Subscription } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { GetCountFromServerService } from "src/app/core/services/counts/get-count-from-server.service";
import { DuplicationService } from "src/app/core/services/duplication/duplication.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { ActionType, LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { OperationStringsService } from "src/app/core/services/strings/operation-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { OperationEnhanced } from "src/app/models/operation/operation.model";
import { AlertType, OperationType } from "src/app/models/strings/strings.model";
import { TemplateEnhanced } from "src/app/models/template/template.model";
import { FolderEditWrapperComponent } from "src/app/sections/folders/edit/folder-edit-wrapper/folder-edit-wrapper.component";
import { FolderDetailService } from "src/app/sections/folders/services/folder-detail.service";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { DuplicateOverlayComponent } from "src/app/shared/duplicate-overlay/duplicate-overlay.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { TemplateDetailService } from "../../services/template-detail.service";
import { ManageTemplateOverviewActionsComponent } from "../manage-template-overview-actions/manage-template-overview-actions.component";
import { ManageTemplateOverviewContentsComponent } from "../manage-template-overview-contents/manage-template-overview-contents.component";
import { ManageTemplateOverviewDetailsComponent } from "../manage-template-overview-details/manage-template-overview-details.component";
import { ManageTemplateOverviewHeaderComponent } from "../manage-template-overview-header/manage-template-overview-header.component";
import { ManageTemplateStartInspectionComponent } from "../manage-template-start-inspection/manage-template-start-inspection.component";

@Component({
  selector: "manage-template-overview-wrapper",
  standalone: true,
  imports: [
    ManageTemplateOverviewHeaderComponent,
    ManageTemplateOverviewDetailsComponent,
    ManageTemplateOverviewActionsComponent,
    ManageTemplateOverviewContentsComponent,
    LoadingSpinnerComponent,
    AsyncPipe,
    DeleteOverlayComponent,
    DuplicateOverlayComponent,
    ManageTemplateStartInspectionComponent,
    FolderEditWrapperComponent,
  ],
  templateUrl: "./manage-template-overview-wrapper.component.html",
  styleUrl: "./manage-template-overview-wrapper.component.scss",
})
export class ManageTemplateOverviewWrapperComponent implements OnInit, OnDestroy {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }
  @Input({ required: true }) set templateId(value: string) {
    this._templateId = value;
  }
  get templateId(): string {
    return this._templateId;
  }

  // Services
  public templateDetailService = inject(TemplateDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private collectionsService = inject(CollectionsService);
  private getCountFromServerService = inject(GetCountFromServerService);
  private router = inject(Router);
  private authService = inject(AuthService);
  public duplicationService = inject(DuplicationService);
  private stringsService = inject(StringsService);
  private operationStringsService = inject(OperationStringsService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private folderDetailService = inject(FolderDetailService);

  // Properties
  private _workspaceId!: string;
  private _templateId!: string;
  public template$!: Observable<TemplateEnhanced>;
  public categoriesCount!: number;
  public user: User;
  public overlay_deleteTemplate = false;
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  public duplicationObject: OperationEnhanced | null;
  private _duplicationSubscription!: Subscription;
  public folderForm: FormGroup;
  public canUserCreateFoldersLimitationResult: boolean = false;

  constructor() {
    this.user = this.authService.currentUser;
    this.duplicationObject = null;
    this.folderForm = this.fb.group({
      id: null,
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      colour: "#2F8559",
      date_created: null,
      created_by: null,
      created_by_id: null,
      inspections_count: null,
      is_deleted: null,
    });
  }

  ngOnInit(): void {
    this.getTemplateDoc();
    this.getCategoryCount();
    this.canUserCreateFolders();
  }

  ngOnDestroy(): void {
    this._duplicationSubscription?.unsubscribe();
  }

  private async canUserCreateFolders(): Promise<void> {
    const featureAction: keyof RolePermissions = "folder_create_update";
    this.canUserCreateFoldersLimitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
  }

  /**
   * Get Template Doc
   */
  private async getTemplateDoc(): Promise<void> {
    try {
      this.template$ = this.templateDetailService.getTemplateDoc$(this.workspaceId, this.templateId).pipe(filter((data) => !!data));
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Route To Template Editor
   */
  public async routeToTemplateEditor(): Promise<void> {
    const featureAction: keyof RolePermissions = "template_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", this.templateId, "details"]);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Get Category Count
   */
  public async getCategoryCount() {
    const path = this.collectionsService.templateCategoriesCol(this.workspaceId, this.templateId);
    this.categoriesCount = await this.getCountFromServerService.getCountFromServer(path);
  }

  /**
   * Delete Template
   */
  public async deleteTemplate(): Promise<void> {
    try {
      await this.templateDetailService.deleteTemplateDoc(this.workspaceId, this.templateId, this.user);
      this.router.navigate(["/workspace", this.workspaceId, "manage", "templates"]);
      this.toggleDeleteTemplateOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Delete Template
   */
  public async canUserDeleteTemplate(): Promise<void> {
    const featureAction: keyof RolePermissions = "action_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      const deleteString = this.stringsService.alertFilter(AlertType.DeleteTemplate);
      if (deleteString) {
        this.deleteOverlayTitle = deleteString.title;
        this.deleteOverlayDescription = deleteString.description;
      } else {
        console.error("Unknown string type:", AlertType);
      }
      this.toggleDeleteTemplateOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Duplicate Template Overlay
   */
  public async duplicateTemplateOverlay(): Promise<void> {
    const featureAction: keyof RolePermissions = "template_create_update";
    const actionType: ActionType = { type: "duplicate" };
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction, actionType);
    if (limitationResult) {
      this.toggleDuplicateOverlay();
      this.toggleDuplicateConfirmation();
      const duplicateTemplate = this.operationStringsService.operationFilter(OperationType.DuplicateTemplate);
      if (duplicateTemplate) {
        this.templateDetailService.duplicationTitle = duplicateTemplate.title;
        this.templateDetailService.duplicationDescription = duplicateTemplate.description;
        this.templateDetailService.inProgressText = duplicateTemplate.running;
        this.templateDetailService.successText = duplicateTemplate.success;
        this.templateDetailService.failedText = duplicateTemplate.failure;
      } else {
        console.error("Unknown string type:", OperationType.DuplicateTemplate);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Duplicate Template
   * @param templateTitle
   */
  public async duplicateTemplate(templateTitle: string): Promise<void> {
    this.duplicationService.toggleDuplicationProcess();
    const duplicateTemplate: WithFieldValue<Operation> = {
      type: "duplicate-template",
      created_at: serverTimestamp(),
      status: "pending",
      request: {
        title: templateTitle,
        template_id: this.templateId,
        created_by: `${this.user.name_first} ${this.user.name_last}`,
        created_by_id: `${this.user.user_id}`,
      },
    };
    const operation = (await this.duplicationService.duplicate(this.workspaceId, duplicateTemplate)) as OperationEnhanced;
    this.subscribeToDuplicationEvent(operation);
  }

  async templateToInspectionDuplication(inspectionTitle: string) {
    this.templateDetailService.overlay_duplicateConfirmation = false;
    this.templateDetailService.overlay_folderSelector = false;
    this.templateDetailService.overlay_foldersList = false;
    this.templateDetailService.overlay_newFolder = false;

    const templateToInspection: WithFieldValue<Operation> = {
      type: "create-inspection-from-template",
      created_at: serverTimestamp(),
      status: "pending",
      request: {
        template_id: this.templateId,
        title: inspectionTitle,
        folder_id: this.templateDetailService.folderId,
        folder_title: this.templateDetailService.folderTitle,
        created_by: `${this.user.name_first} ${this.user.name_last}`,
        created_by_id: `${this.user.user_id}`,
      },
    };

    const operation = (await this.duplicationService.duplicate(this.workspaceId, templateToInspection)) as OperationEnhanced;
    this.subscribeToDuplicationEvent(operation);
  }

  /**
   * Subscribe To Duplication Event
   * @param operation
   */
  subscribeToDuplicationEvent(operation: OperationEnhanced) {
    this._duplicationSubscription = this.duplicationService.getDuplicationOperation$(this.workspaceId, operation.id).subscribe((result) => {
      this.duplicationObject = result;
    });
  }

  /**
   * Navigate To New Template
   */
  public navigateToNewTemplate(): void {
    if (this.duplicationObject && this.duplicationObject.response) {
      this.duplicationService.toggleDuplicationProcess();
      this.duplicationService.overlay_duplicate = false;
      this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", this.duplicationObject.response?.new_template_id, "overview"]);
    } else {
      alert("New Inspection ID not found");
    }
  }

  public navigateToNewInspection(): void {
    if (this.duplicationObject && this.duplicationObject.response) {
      const route = ["/workspace", this.workspaceId, "folders", this.templateDetailService.folderId, "inspections", this.duplicationObject.response.new_inspection_id, "overview"];
      this.router.navigate(route);
    } else {
      alert("New Inspection ID not found");
    }
  }

  /**
   * Toggle Delete Template Overlay
   */
  public toggleDeleteTemplateOverlay(): void {
    this.overlay_deleteTemplate = !this.overlay_deleteTemplate;
  }

  /**
   * Toggle Duplicate Overlay
   */
  public toggleDuplicateOverlay(): void {
    this.duplicationService.overlay_duplicate = !this.duplicationService.overlay_duplicate;
  }

  /**
   * Toggle Duplicate Overlay
   */
  public toggleDuplicateConfirmation(): void {
    this.duplicationService.overlay_duplicateConfirmation = !this.duplicationService.overlay_duplicateConfirmation;
  }

  public async canUserCreateInspections(): Promise<void> {
    const featureAction: keyof RolePermissions = "inspection_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleFolderListOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  public toggleFolderListOverlay(): void {
    this.templateDetailService.overlay_duplicate = true;
    this.templateDetailService.overlay_duplicateConfirmation = true;
    this.duplicationObject = null;
  }

  public cancelNewFolder(): void {
    this.templateDetailService.overlay_newFolder = false;
    this.templateDetailService.overlay_foldersList = true;
    this.templateDetailService.overlay_duplicate = true;
  }

  public async saveNewFolder(): Promise<void> {
    try {
      const writeEventType = "added";
      const folder = await this.folderDetailService.setFolderDoc(this.user, this.workspaceId, this.folderForm.value, writeEventType);
      this.templateDetailService.overlay_duplicateConfirmation = true;
      this.templateDetailService.overlay_duplicate = true;
      this.templateDetailService.folderTitle = folder.title;
      this.templateDetailService.folderId = folder.id;
    } catch (error) {
      alert(error);
    } finally {
      this.templateDetailService.overlay_newFolder = false;
      this.templateDetailService.overlay_foldersList = false;
    }
  }
}
