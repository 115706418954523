import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { ManageTemplateEditDetailsComponent } from "../manage-template-edit-details/manage-template-edit-details.component";

@Component({
  selector: "manage-template-new-overlay",
  standalone: true,
  imports: [NgClass, FormStateComponent, ManageTemplateEditDetailsComponent],
  templateUrl: "./manage-template-new-overlay.component.html",
  styleUrl: "./manage-template-new-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateNewOverlayComponent {
  @Input({ required: true }) templateForm!: FormGroup;
  @Input({ required: true }) newTemplateId!: string;
  @Input({ required: true }) workspaceId!: string;
  @Output() toggleNewTemplateOverlayOutput = new EventEmitter<void>();
  @Output() saveTemplateOutput = new EventEmitter<void>();

  /**
   * Save Template And Reroute
   */
  public saveTemplate(): void {
    this.saveTemplateOutput.emit();
  }

  /**
   * Toggle New Template Overlay
   */
  public toggleNewTemplateOverlay(): void {
    this.toggleNewTemplateOverlayOutput.emit();
  }
}
