import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-action-assignee",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./report-settings-action-assignee.component.html",
  styleUrl: "./report-settings-action-assignee.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsActionAssigneeComponent {
  // Services
  public reportsService = inject(ReportsService);

  // Properties
  public toggleContent: boolean = false;

  /**
   * Toggle Content Options
   */
  toggleContentOptions() {
    this.toggleContent = !this.toggleContent;
  }

  /**
   * On Assignee Checkbox Change
   * @param assigneeId
   * @param isChecked
   */
  onAssigneeCheckboxChange(assigneeId: string, isChecked: boolean) {
    if (isChecked) {
      this.reportsService.reportSettings.assignee_ids.push(assigneeId);
    } else {
      const index = this.reportsService.reportSettings.assignee_ids.indexOf(assigneeId);
      if (index !== -1) {
        this.reportsService.reportSettings.assignee_ids.splice(index, 1);
      }
    }
    this.reportsService.updateReportSettings(this.reportsService.reportSettings);
  }

  /**
   * Toggle All Assignee Checkboxes
   */
  toggleAllAssigneeCheckboxes() {
    const allAssigneeCheckboxState = this.reportsService.reportSettings.assignee_all;
    if (!allAssigneeCheckboxState) {
      this.reportsService.reportSettings.assignee_ids = [];
    } else {
      const assigneeIds = this.reportsService.reportData.actionAssignees.map((assignee) => assignee.assignee_id).filter((assigneeId): assigneeId is string => assigneeId !== undefined);
      this.reportsService.reportSettings.assignee_ids = ["no-assignee", ...assigneeIds];
    }
    this.reportsService.updateReportSettings(this.reportsService.reportSettings);
  }
}
