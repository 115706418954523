import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartsAssigneeResults } from 'src/app/models/charts/charts-assignees.model';

@Component({
  selector: 'charts-assignees',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './charts-assignees.component.html',
  styleUrl: './charts-assignees.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartsAssigneesComponent {
  @Input({ required: true }) chartsAssignees: ChartsAssigneeResults[] = [];
  @Input({ required: true }) assigneeHighestActionCount!: number;
}
