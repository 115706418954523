import { inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';
import { combineLatest, firstValueFrom } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { MemberDetailService } from 'src/app/sections/workspaces/current-workspace/manage-members/services/member-detail.service';
import { WorkspacesService } from 'src/app/sections/workspaces/services/workspaces.service';
import { LimitationManagerService } from '../services/limitation-manager.service';
import { SubscriptionService } from '../services/subscription.service';

/**
 * Limitation Guard
 * To view a workspace, a user must meet certain criteria
 *  1) uid must exist in the workspace_users array
 *  2) To view their own workspace, the users uid must match the current_owner_id field on the workspace doc
 *  3) To view another users workspace, the user viewing user must be subscribed (not free)
 */
export const LimitationDataGuard: CanActivateFn = async (route) => {
  const auth = inject(Auth);
  const subscriptionService = inject(SubscriptionService);
  const memberDetailService = inject(MemberDetailService);
  const limitationManagerService = inject(LimitationManagerService);
  const workspacesService = inject(WorkspacesService);
  const router = inject(Router);

  // Wait for auth state to be ready
  const user = await firstValueFrom(authState(auth).pipe(take(1)));

  const workspaceId = route.paramMap.get('workspaceId');

  if (!user) {
    router.navigate(['/signin']);
    return false;
  }

  if (!workspaceId) {
    router.navigate(['/workspaces']);
    return false;
  }

  try {
    // First get workspace data to check access
    const workspace = await firstValueFrom(workspacesService.getWorkspaceDoc$(workspaceId).pipe(take(1)));

    if (!workspace) {
      router.navigate(['/workspaces']);
      return false;
    }

    // Check if user has access to workspace
    const hasAccess = workspace.workspace_users.includes(user.uid);
    if (!hasAccess) {
      router.navigate(['/workspaces']);
      return false;
    }

    // Now get billing and member data
    const billing$ = subscriptionService.getUserSubscription$(user.uid);
    const member$ = memberDetailService.getMemberDoc$(workspaceId, user.uid).pipe(filter((data) => !!data));

    const [billingData] = await firstValueFrom(
      combineLatest([billing$, member$]).pipe(
        take(1),
        tap(([billing, member]) => {
          limitationManagerService.billingDoc = billing;
          limitationManagerService.memberDoc.next(member);
        })
      )
    );

    // Check subscription status if not workspace owner
    if (billingData.status === 'free' && workspace.current_owner_id !== user.uid) {
      limitationManagerService.setSubscriptionErrorMessage();
      limitationManagerService.overlay_limitationManager = true;
      router.navigate(['/workspaces']);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error loading LDG Data:', error);
    router.navigate(['/workspaces']);
    return false;
  }
};
