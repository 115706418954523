import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import type * as firestore from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as plist from "plist";
import { filter, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { ActionType, LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { TemplateEnhanced } from "src/app/models/template/template.model";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ManageTemplateNewOverlayComponent } from "../../edit/manage-template-new-overlay/manage-template-new-overlay.component";
import { TemplateDetailService } from "../../services/template-detail.service";
import { TemplatesListService } from "../../services/templates-list.service";
import { ManageTemplatesListHeaderComponent } from "../manage-templates-list-header/manage-templates-list-header.component";
import { ManageTemplatesListImportComponent } from "../manage-templates-list-import/manage-templates-list-import.component";
import { ManageTemplatesListComponent } from "../manage-templates-list/manage-templates-list.component";

interface Checklist {
  Categories: Category[];
  dateCreated: string;
  reference: string;
  title: string;
}

interface Category {
  Tests: Test[];
  completed: number;
  number: number;
  title: string;
}

interface Test {
  displayOrder: number;
  score: number;
  title: string;
}

interface NewTemplate {
  code: string;
  date: firestore.Timestamp;
  template_id: string;
}

@Component({
  selector: "manage-templates-list-wrapper",
  standalone: true,
  imports: [ManageTemplatesListHeaderComponent, ManageTemplatesListComponent, EmptyListComponent, AsyncPipe, LoadingSpinnerComponent, ManageTemplateNewOverlayComponent, ManageTemplatesListImportComponent],
  templateUrl: "./manage-templates-list-wrapper.component.html",
  styleUrl: "./manage-templates-list-wrapper.component.scss",
})
export class ManageTemplatesListWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private templatesListService = inject(TemplatesListService);
  private templateDetailService = inject(TemplateDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private infoViewStringsService = inject(InfoViewStringsService);

  // Properties
  private _workspaceId!: string;
  private user: User;
  public templates$!: Observable<TemplateEnhanced[]>;
  public currentSearchTerm: string = "";
  public overlay_newTemplate: boolean = false;
  public templateForm: FormGroup;
  public importTemplateForm: FormGroup;
  public newTemplateId!: string;
  public overlay_importTemplate: boolean = false;
  public infoViewImportTemplate: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.ImportTemplate);
  private importConvertedFile!: Checklist;
  public fileName: string = "";
  public newImportTemplateId: string = "";
  public fileUploading: boolean = false;
  public fileUploadComplete: boolean = false;
  public fileAdded: boolean = false;

  constructor() {
    this.user = this.authService.currentUser;
    this.templateForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      ref: null,
      version: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      date_created: null,
      date_updated: null,
      created_by: null,
      created_by_id: null,
    });
    this.importTemplateForm = this.fb.group({
      title: ["", Validators.required],
      reference: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getTemplatesList();
  }

  /**
   * Get Templates List
   */
  private async getTemplatesList(): Promise<void> {
    this.templates$ = this.templatesListService.getTemplatesList$(this.workspaceId).pipe(filter((data) => !!data));
  }

  /**
   * Save Template
   */
  public async saveTemplate(): Promise<void> {
    try {
      await this.templateDetailService.setTemplateDoc(this.workspaceId, this.newTemplateId, this.templateForm.value, this.user);
      this.toggleNewTemplateOverlay();
      this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", this.newTemplateId, "overview"]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Update Search Term
   * @param term
   */
  public searchTermChange(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   * Reset Form
   */
  private resetForm(): void {
    this.templateForm.markAsPristine();
    this.templateForm.reset();
  }

  /**
   * Route To Template Overview
   */
  public async routeToTemplateOverview(templateId: string): Promise<void> {
    this.router.navigate(["/workspace", this.workspaceId, "manage", "templates", templateId, "overview"]);
  }

  /**
   * Limitation Manager Check
   */
  public async limitationManagerCheck(featureAction: keyof RolePermissions): Promise<boolean> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    return limitationResult;
  }

  /**
   * Can User Create Template
   */
  public async canUserCreateTemplate(): Promise<void> {
    const featureAction: keyof RolePermissions = "template_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleNewTemplateOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Can User Create Templates
   */
  async canUserCreateTemplates(): Promise<boolean> {
    const featureAction: keyof RolePermissions = "template_create_update";
    const actionType: ActionType = { type: "duplicate" };
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction, actionType);
    return limitationResult;
  }

  /**
   * Convert itmp to json
   * Takes the file information and converts it to json to be used in the form
   * The file name populates the file upload input field
   * We assign a variable to the converted file to be used in the importTemplate function
   * TODO
   */
  public convertItmpToJson(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files) {
      const file: File = fileInput.files[0];
      // Check if the file type is allowed
      if (!this.isFileTypeAllowed(file)) {
        alert("Invalid file type. Please select a file with .itmp or .plist extension.");
        return;
      }

      this.fileAdded = true;
      this.fileName = file.name;
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const plistData: string = e.target?.result as string;
          const parsedData = plist.parse(plistData);

          const processedData = {
            Categories: Array.isArray((parsedData as any).Categories) ? (parsedData as any).Categories : [],
            dateCreated: (parsedData as any).dateCreated || "",
            reference: (parsedData as any).reference || "",
            title: (parsedData as any).title || "",
          } as Checklist;

          this.importConvertedFile = processedData;

          const formUpdate: any = {};
          if (processedData.title) formUpdate.title = processedData.title;
          if (processedData.reference) formUpdate.reference = processedData.reference;

          this.importTemplateForm.patchValue(formUpdate);
          this.importTemplateForm.markAsDirty();
        } catch (error) {
          console.error("Error processing file:", error);
          alert("Error processing the file. Please try again.");
          this.fileAdded = false;
          this.fileName = "";
        }
      };
      reader.readAsText(file);
    }
  }

  public isFileTypeAllowed(file: File): boolean {
    // Define allowed file extensions
    const allowedExtensions = [".itmp", ".plist"];

    // Get the file extension
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    // Check if the file extension is in the allowed list
    return allowedExtensions.includes(`.${fileExtension}`);
  }

  /**
   * Import Template
   * Calls the cloud function to import the template
   * Creates references to the functions and calls the function
   * Creates a new template object and calls the function
   * Upon completion of the function, the fileUploading variable is set to false and the fileUploadComplete variable is set to true to show the success message
   * Updates the templateId
   */
  public importTemplate() {
    this.fileUploading = true;
    const functions = getFunctions();
    const createTemplateFromOldTemplate = httpsCallable(functions, "createTemplateFromOldTemplate");
    const { title, reference } = this.importTemplateForm.value;
    const data = {
      workspace_id: this.workspaceId,
      old_template: this.importConvertedFile,
      title: title,
      ref: reference,
      user: this.user,
      created_by: `${this.user.name_first} ${this.user.name_last}`,
      created_by_id: this.user.user_id,
    };

    createTemplateFromOldTemplate(data)
      .then((result) => {
        const template = result.data as NewTemplate;
        this.newTemplateId = template.template_id;
        this.fileUploading = false;
        this.fileUploadComplete = true;
      })
      .catch((error) => {
        console.error("Error importing Template", error);
      });
  }

  /**
   * Close Import Screen
   * Resets the form and closes the overlay
   * @returns void
   */
  public closeImportScreen() {
    this.fileAdded = false;
    this.importTemplateForm.reset();
    this.fileUploading = false;
    this.fileUploadComplete = false;
    this.toggleImportTemplateOverlay();
  }

  /**
   * Toggle Import Template Overlay
   */
  public async toggleImportTemplateOverlay(): Promise<void> {
    const limitationResult = await this.canUserCreateTemplates();
    if (limitationResult) {
      this.overlay_importTemplate = !this.overlay_importTemplate;
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle New Template Overlay
   */
  public async toggleNewTemplateOverlay(): Promise<void> {
    if (!this.overlay_newTemplate) {
      this.newTemplateId = this.firestoreUtilsService.createFirestoreId();
    }
    this.overlay_newTemplate = !this.overlay_newTemplate;
    this.resetForm();
  }
}
