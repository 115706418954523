@if (category$ | async; as category) {
  <inspection-category-edit-header [workspaceId]="_workspaceId" [folderId]="_folderId" [inspectionId]="_inspectionId" [categoryId]="_categoryId" (duplicateCategoryOutput)="toggleAndSetDuplicateCategoryOverlay(category)" />
  <div class="grid grid-cols-1 gap-y-8">
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="categoryForm.pristine"></form-state>
      <button
        type="button"
        (click)="saveCategoryAndReroute()"
        class="btn"
        [disabled]="categoryForm.pristine || categoryForm.controls['title'].errors"
        [ngClass]="categoryForm.pristine || categoryForm.controls['title'].errors ? 'btn-disabled' : 'btn-primary'">
        Save
      </button>
    </div>

    <div class="content-card-wrapper">
      <div class="content-card">
        <inspection-category-edit-details [categoryForm]="categoryForm" [toolTipCategoryNotes]="toolTipCategoryNotes.title" />
      </div>
    </div>

    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      <button type="button" class="btn btn-destructive mr-3" (click)="setDeleteCategoryProperties()">Delete Category</button>
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="categoryForm.pristine"></form-state>
        <button
          type="button"
          (click)="saveCategoryAndReroute()"
          class="btn"
          [disabled]="categoryForm.pristine || categoryForm.controls['title'].errors"
          [ngClass]="categoryForm.pristine || categoryForm.controls['title'].errors ? 'btn-disabled' : 'btn-primary'">
          Save
        </button>
      </div>
    </div>
  </div>
}
@if (duplicationService.overlay_duplicate) {
  <duplicate-overlay
    [duplicationObject]="duplicationObject"
    [originalDocumentTitle]="categoryToBeDuplicated.title + ' ' + '(Copy)'"
    [duplicationTitle]="duplicationTitle"
    [duplicationDescription]="duplicationDescription"
    [inProgressText]="inProgressText"
    [successText]="successText"
    [failedText]="failedText"
    [navigateToText]="navigateToText"
    (duplicateEmitter)="duplicateCategory($event)"
    (navigateToNewDocEmitter)="navigateToNewCategory()" />
}

@if (overlay_deleteCategory) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteCategoryOverlay()" (deleteButtonClicked)="deleteCategory()" />
}
