<nav class="bg-white shadow-sm dark:bg-gray-900">
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="flex h-16 justify-between">
      <div class="hidden sm:flex">
        <div class="flex flex-shrink-0 items-center">
          <img class="hidden h-8 w-auto lg:block" src="../../../../assets/images/cip-logo.png" />
        </div>
        <div class="sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
          <a [routerLink]="['/workspaces']" [routerLinkActive]="['active']" class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-text_Subtitle dark:text-white">All Workspaces</a>
          <div
            (click)="goBackToFoldersOrReturnToInspectionChain()"
            [routerLink]="['/workspace', workspaceId, 'folders']"
            [routerLinkActive]="['active']"
            class="inline-flex cursor-pointer items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-text_Subtitle hover:border-gray-300 hover:text-gray-700 dark:text-white">
            Folders
          </div>
          <div
            (click)="limitationManagerCheck()"
            [ngClass]="{ active: isManageWorkspaceActive }"
            class="inline-flex cursor-pointer items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-text_Subtitle hover:border-gray-300 hover:text-gray-700 dark:text-white">
            Manage Workspace
          </div>

          <!-- [routerLink]="['/workspace', workspaceId, 'manage']" -->
          <a
            [routerLink]="['/workspace', workspaceId, 'account', 'profile']"
            [routerLinkActive]="['active']"
            class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-text_Subtitle hover:border-gray-300 hover:text-gray-700 dark:text-white"
            >Account</a
          >
        </div>
      </div>
      <div class="sm:ml-6 sm:flex">
        <div class="flex h-full w-full flex-shrink-0">
          <div class="flex flex-row items-center">
            <div class="flex items-center">
              <a
                [routerLink]="['/workspace', workspaceId, 'auditlogs']"
                [routerLinkActive]="['active-icon']"
                class="group flex cursor-pointer items-center justify-center rounded-lg border border-gray-200 p-2 transition hover:bg-brand_Primary/50 dark:border-gray-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 group-hover:text-white">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                </svg>
              </a>
            </div>
            <!-- <div
              (click)="setTheme('dark')"
              title="Dark Mode"
              class="cursor-pointer border rounded-lg border-gray-200 flex items-center justify-center p-2 mr-2 dark:border-gray-600 transition hover:bg-brand_Primary/50 group"
              [ngClass]="darkModeService.themeMode === 'dark' ? 'bg-slate-700 text-gray-400' : 'bg-transparent'"
            >
              <svg class="w-4 h-4 group-hover:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
              </svg>
            </div>
            <div
              (click)="setTheme('light')"
              title="Light Mode"
              class="cursor-pointer border rounded-lg border-gray-200 flex items-center justify-center p-2 dark:border-gray-600 transition hover:bg-brand_Primary/50 group"
              [ngClass]="darkModeService.themeMode === 'light' ? 'bg-gray-200' : 'bg-transparent text-text_Subtitle'"
            >
              <svg class="w-4 h-4 group-hover:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div> -->
            <div class="ml-4 flex items-center border-l">
              <div (click)="authService.toggleSignOutOverlay()" title="Sign Out" class="group ml-3 mr-2 flex cursor-pointer items-center justify-center rounded-lg border border-gray-200 p-2 transition hover:bg-brand_Destructive dark:border-gray-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 group-hover:text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="-mr-2 flex items-center sm:hidden">
        <!-- Mobile menu button -->
        <button
          type="button"
          class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-text_Subtitle focus:outline-none focus:ring-2 focus:ring-brand_Primary focus:ring-offset-2"
          aria-controls="mobile-menu"
          aria-expanded="false"
          (click)="toggleMenu()">
          <span class="sr-only">Open main menu</span>
          <!-- Menu open: "hidden", Menu closed: "block" -->
          <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          <!-- Menu open: "block", Menu closed: "hidden" -->
          <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  @if (mobileMenu) {
    <div class="sm:hidden" id="mobile-menu">
      <div class="space-y-1 pb-3 pt-2">
        <a [routerLink]="['/workspaces']" [routerLinkActive]="['active']" class="block border-b-2 border-transparent py-2 pl-3 pr-4 text-base font-medium text-text_Subtitle dark:text-white" aria-current="page" (click)="toggleMenu()"
          >All Workspaces</a
        >
        <a [routerLink]="['/workspace', workspaceId, 'folders']" [routerLinkActive]="['active']" class="block border-b-2 border-transparent py-2 pl-3 pr-4 text-base font-medium text-text_Subtitle dark:text-white" (click)="toggleMenu()">Folders</a>
        <a [routerLink]="['/workspace', workspaceId, 'manage']" [routerLinkActive]="['active']" class="block border-b-2 border-transparent py-2 pl-3 pr-4 text-base font-medium text-text_Subtitle dark:text-white" (click)="toggleMenu()"
          >Manage Workspace</a
        >
        <a [routerLink]="['/workspace', workspaceId, 'account']" [routerLinkActive]="['active']" class="block border-b-2 border-transparent py-2 pl-3 pr-4 text-base font-medium text-text_Subtitle dark:text-white" (click)="toggleMenu()">Account</a>
      </div>
    </div>
  }
</nav>

@if (authService.overlay_signOut) {
  <main-menu-sign-out-overlay />
}
