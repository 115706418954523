import { NgStyle } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ManageThemeEditPreviewActionComponent } from "../manage-theme-edit-preview-action/manage-theme-edit-preview-action.component";
import { ManageThemeEditPreviewItemPhotosComponent } from "../manage-theme-edit-preview-item-photos/manage-theme-edit-preview-item-photos.component";

@Component({
  selector: "manage-theme-edit-preview-item",
  standalone: true,
  imports: [NgStyle, ManageThemeEditPreviewItemPhotosComponent, ManageThemeEditPreviewActionComponent],
  templateUrl: "./manage-theme-edit-preview-item.component.html",
  styleUrl: "./manage-theme-edit-preview-item.component.scss",
})
export class ManageThemeEditPreviewItemComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
}
