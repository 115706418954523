import { NgStyle } from "@angular/common";
import { Component, inject } from "@angular/core";
import { DateFormatPipe } from "src/app/core/pipes/date-format.pipe";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-content-signature",
  standalone: true,
  imports: [NgStyle, DateFormatPipe],
  templateUrl: "./report-content-signature.component.html",
  styleUrl: "./report-content-signature.component.scss",
})
export class ReportContentSignatureComponent {
  public reportsService = inject(ReportsService);
}
