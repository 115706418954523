<div class="px-4">
  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false" (click)="toggleContentOptions()">
        <span class="font-medium text-gray-900">Due Date</span>
        <span class="ml-6 flex items-center">
          @if (!toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
          }
          @if (toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
            </svg>
          }
        </span>
      </button>
    </h3>
    @if (toggleContent) {
      <div class="pt-6">
        <ul class="space-y-4">
          <li class="flex items-center">
            <input id="data_all" name="data_all" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" [(ngModel)]="reportsService.reportSettings.date_all" (change)="toggleAllDateCheckboxes()" />
            <label for="data_all" class="ml-3 cursor-pointer text-sm text-text_Title">Select All</label>
          </li>
          <li class="flex items-center">
            <input id="date_none" name="date_none" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" [(ngModel)]="reportsService.reportSettings.date_none" (change)="dueDateInputChange()" />
            <label for="date_none" class="ml-3 cursor-pointer text-sm text-text_Title">No Date</label>
          </li>
          <li class="flex items-center">
            <input id="date_overdue" name="date_overdue" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" [(ngModel)]="reportsService.reportSettings.date_overdue" (change)="dueDateInputChange()" />
            <div class="ml-3 h-4 w-4 rounded-full bg-action_0"></div>
            <label for="date_overdue" class="ml-2 cursor-pointer text-sm text-text_Title">Overdue</label>
          </li>
          <li class="flex items-center">
            <input id="date_1_week" name="date_1_week" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" [(ngModel)]="reportsService.reportSettings.date_1_week" (change)="dueDateInputChange()" />
            <div class="ml-3 h-4 w-4 rounded-full bg-action_1"></div>
            <label for="date_1_week" class="ml-2 cursor-pointer text-sm text-text_Title">< 1 Week</label>
          </li>
          <li class="flex items-center">
            <input
              id="date_1_to_2_weeks"
              name="date_1_to_2_weeks"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.date_1_to_2_weeks"
              (change)="dueDateInputChange()" />
            <div class="ml-3 h-4 w-4 rounded-full bg-action_2"></div>
            <label for="date_1_to_2_weeks" class="ml-2 cursor-pointer text-sm text-text_Title">1 Week - 2 Weeks</label>
          </li>
          <li class="flex items-center">
            <input
              id="date_2_weeks_to_1_month"
              name="date_2_weeks_to_1_month"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.date_2_weeks_to_1_month"
              (change)="dueDateInputChange()" />
            <div class="ml-3 h-4 w-4 rounded-full bg-action_3"></div>
            <label for="date_2_weeks_to_1_month" class="ml-2 cursor-pointer text-sm text-text_Title">2 Weeks - 1 Month</label>
          </li>
          <li class="flex items-center">
            <input
              id="date_1_month_to_6_months"
              name="date_1_month_to_6_months"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.date_1_month_to_6_months"
              (change)="dueDateInputChange()" />
            <div class="ml-3 h-4 w-4 rounded-full bg-action_4"></div>
            <label for="date_1_month_to_6_months" class="ml-2 cursor-pointer text-sm text-text_Title">1 Month - 6 Months</label>
          </li>
          <li class="flex items-center">
            <input
              id="date_6_months_to_1_year"
              name="date_6_months_to_1_year"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.date_6_months_to_1_year"
              (change)="dueDateInputChange()" />
            <div class="ml-3 h-4 w-4 rounded-full bg-action_5"></div>
            <label for="date_6_months_to_1_year" class="ml-2 cursor-pointer text-sm text-text_Title">6 Months - 1 Year</label>
          </li>
          <li class="flex items-center">
            <input
              id="date_1_year_plus"
              name="date_1_year_plus"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary"
              [(ngModel)]="reportsService.reportSettings.date_1_year_plus"
              (change)="dueDateInputChange()" />
            <div class="ml-3 h-4 w-4 rounded-full bg-action_6"></div>
            <label for="date_1_year_plus" class="ml-2 cursor-pointer text-sm text-text_Title">> 1 Year</label>
          </li>
        </ul>
      </div>
    }
  </div>
</div>
