import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "manage-members-list-header",
  standalone: true,
  imports: [],
  templateUrl: "./manage-members-list-header.component.html",
  styleUrl: "./manage-members-list-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageMembersListHeaderComponent {
  @Output() routeToInviteEditorOutput = new EventEmitter<void>();

  /**
   * Route To Invite Editor
   */
  public routeToInviteEditor(): void {
    this.routeToInviteEditorOutput.emit();
  }
}
