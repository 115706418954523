import { AsyncPipe, JsonPipe } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { NavigationService } from "src/app/core/services/navigation.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { FolderSorting } from "src/app/models/folder/folder-sorting.model";
import { FolderEnhanced } from "src/app/models/folder/folder.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { DeleteConfirmationComponent } from "src/app/shared/delete-confirmation/delete-confirmation.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { FolderEditWrapperComponent } from "../../edit/folder-edit-wrapper/folder-edit-wrapper.component";
import { FolderDetailService } from "../../services/folder-detail.service";
import { FoldersListService } from "../../services/folders-list.service";
import { FoldersListHeaderComponent } from "../folders-list-header/folders-list-header.component";
import { FoldersListComponent } from "../folders-list/folders-list.component";

@Component({
  selector: "folders-list-wrapper",
  standalone: true,
  imports: [LoadingSpinnerComponent, AsyncPipe, FoldersListComponent, EmptyListComponent, FoldersListHeaderComponent, FolderEditWrapperComponent, DeleteConfirmationComponent, JsonPipe],
  templateUrl: "./folders-list-wrapper.component.html",
  styleUrl: "./folders-list-wrapper.component.scss",
})
export class FoldersListWrapperComponent implements OnInit {
  @Input() set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private navigationService = inject(NavigationService);
  private router = inject(Router);
  private authService = inject(AuthService);
  public foldersListService = inject(FoldersListService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);
  private folderDetailService = inject(FolderDetailService);

  // Properties
  public folders$!: Observable<FolderEnhanced[]>;

  public user!: User;
  public _workspaceId!: string;
  public overlay_editFolder: boolean = false;
  public overlay_deleteFolder: boolean = false;
  public isNewFolder: boolean = false;
  public folderForm: FormGroup;
  public limitationResult: boolean = false;
  public deleteTitle!: string;
  public deleteDescription!: string;
  public currentSearchTerm: string = "";

  constructor() {
    this.navigationService.breadcrumbsPath = this.router.url;
    this.user = this.authService.currentUser;
    this.folderForm = this.fb.group({
      id: null,
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      colour: "#2B6DB0",
      date_created: null,
      created_by: null,
      created_by_id: null,
      inspections_count: null,
      is_deleted: null,
    });
  }

  ngOnInit(): void {
    this.getFoldersList();
  }

  /**
   * Get Folders List
   */
  private getFoldersList(): void {
    try {
      this.folders$ = this.foldersListService.getFoldersList$(this._workspaceId).pipe(
        filter((data) => !!data),
        tap((data) => {
          this.foldersListService.updateReorderedFolders(data);
          if (this.foldersListService.areFoldersSorted) {
            this.foldersListService.reorderFoldersWithNewData();
          } else {
            this.foldersListService.currentlySelectedFolderSortChoice = FolderSorting.dateCreated;
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Add Folder
   */
  public async canUserAddFolder(): Promise<void> {
    this.limitationResult = await this.canUserEdit();
    if (this.limitationResult) {
      this.isNewFolder = true;
      this.toggleEditFolderOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Save Folder
   */
  public async saveFolder(): Promise<void> {
    try {
      const folder = await this.folderDetailService.setFolderDoc(this.user, this._workspaceId, this.folderForm.value, "added");
      this.router.navigate(["/", "workspace", this.workspaceId, "folders", folder.id, "inspections"]);
      this.toggleEditFolderOverlay();
      this.resetFolderForm();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Update Edit Folder Folder
   * @param folder
   */
  public async updateEditFolderForm(folder: FolderEnhanced): Promise<void> {
    this.limitationResult = await this.canUserEdit();
    if (this.limitationResult) {
      this.folderForm.patchValue(folder);
      this.toggleEditFolderOverlay();
      this.isNewFolder = false;
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Folder
   * @param folder
   */
  public async deleteFolder(): Promise<void> {
    this.toggleDeleteFolderOverlay();
    try {
      await this.folderDetailService.setFolderDoc(this.user, this._workspaceId, this.folderForm.value, "deleted");
      this.toggleEditFolderOverlay();
      this.overlay_deleteFolder = false;
      this.resetFolderForm();
      this.folderForm.markAsPristine();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Delete Folder Overlay
   */
  public async checkIfUserCanDelete(): Promise<void> {
    const canDelete = await this.canUserDelete();
    if (canDelete) {
      this.toggleDeleteFolderOverlay();
      const deleteFolder = this.stringsService.alertFilter(AlertType.DeleteFolder);

      if (deleteFolder) {
        this.deleteTitle = deleteFolder.title;
        this.deleteDescription = deleteFolder.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteFolder);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Change Folder Sort Choice
   * @param filterChoice
   */
  public changeFolderSortChoice(filterChoice: string): void {
    this.foldersListService.areFoldersSorted = true;
    this.foldersListService.sortDropdown = false;

    if (this.foldersListService.currentlySelectedFolderSortChoice === filterChoice) {
      this.foldersListService.isSortOrderAscending = !this.foldersListService.isSortOrderAscending;
    } else {
      this.foldersListService.isSortOrderAscending = false;
    }

    this.foldersListService.currentlySelectedFolderSortChoice = filterChoice;
    this.foldersListService.reorderFoldersWithNewData();
  }

  /**
   * Toggle Sort Dropdown
   */
  public toggleSortDropdown(): void {
    this.foldersListService.sortDropdown = !this.foldersListService.sortDropdown;
  }

  /**
   * Limitation Manager Check
   */
  private async canUserPerformAction(action: keyof RolePermissions): Promise<boolean> {
    return await this.limitationManagerService.canUserPerformAction(action);
  }

  /**
   * Can User Edit
   */
  async canUserEdit(): Promise<boolean> {
    return await this.canUserPerformAction("folder_create_update");
  }

  /**
   * Can User Delete
   */
  async canUserDelete(): Promise<boolean> {
    return await this.canUserPerformAction("folder_delete");
  }

  /**
   * Update Search Term
   * @param term
   */
  updateSearchTerm(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   * Toggle Edit Folder Overlay
   */
  public toggleEditFolderOverlay(): void {
    if (this.overlay_editFolder) {
      this.resetFolderForm();
    }
    this.overlay_editFolder = !this.overlay_editFolder;
  }

  /**
   * Toggle Delete Folder Overlay
   */
  public toggleDeleteFolderOverlay(): void {
    this.overlay_deleteFolder = !this.overlay_deleteFolder;
  }

  /**
   * Reset Folder Form
   */
  private resetFolderForm(): void {
    this.folderForm.patchValue({
      id: null,
      title: "",
      colour: "#2B6DB0",
      date_created: null,
      created_by: null,
      created_by_id: null,
      inspections_count: null,
      is_deleted: null,
    });
    this.folderForm.markAsUntouched();
    this.folderForm.markAsPristine();
  }
}
