import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "manage-theme-edit-settings-toggle-date",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./manage-theme-edit-settings-toggle-date.component.html",
  styleUrl: "./manage-theme-edit-settings-toggle-date.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsToggleDateComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  public toggleContent = false;

  dateOrder: { value: number; name: string }[] = [
    { value: 0, name: "Day Month Year" },
    { value: 1, name: "Month Day Year" },
    { value: 2, name: "Year Month Day" },
  ];
  dateStyle: { value: number; name: string }[] = [
    { value: 0, name: "Numeric" },
    { value: 1, name: "Abbreviated" },
    { value: 2, name: "Long" },
    // { value: 3, name: 'Complete' },
  ];

  public toggleContentOptions(): void {
    this.toggleContent = !this.toggleContent;
  }
}
