import { inject, Injectable } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SigninGuard implements CanActivate {
  private router = inject(Router);
  private auth = inject(Auth);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean | UrlTree>((observer) => {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.router.navigate(["/workspaces"]);
          observer.next(false); // Block access to the signin page
        } else {
          observer.next(true); // Allow access to signin if not authenticated
        }
        observer.complete();
      });
    }).pipe(take(1));
  }
}
