<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block h-[600px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">
            {{ overlay_assigneeForm() ? "New Assignee" : "Assignee Selector" }}
          </h3>
        </div>

        <div class="flex h-full flex-1 overflow-hidden">
          <div class="w-full space-y-4 overflow-y-scroll overscroll-contain">
            <!-- Tabs -->
            @if (!overlay_assigneeForm()) {
              <div class="mt-4 flex w-full flex-col px-12 sm:flex-row">
                <nav class="flex space-x-4" aria-label="Tabs">
                  <div class="cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-700" [ngClass]="selectedTab === 'Members' ? 'bg-gray-200' : 'bg-gray-100'" (click)="selectTab('Members')">Workspace Members</div>
                  <div class="cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-700" [ngClass]="selectedTab === 'Assignees' ? 'bg-gray-200' : 'bg-gray-100'" (click)="selectTab('Assignees')">External Assignees</div>
                </nav>
              </div>
            }

            <div>
              @if (!overlay_assigneeForm()) {
                @if (selectedTab === "Members") {
                  <action-edit-assignee-member-selector [workspaceId]="workspaceId" [actionForm]="actionForm" (memberSelectionOutput)="selectMember($event)" />
                } @else {
                  <action-edit-assignee-selector
                    [workspaceId]="workspaceId"
                    [actionForm]="actionForm"
                    [assigneeForm]="assigneeForm"
                    [overlay_assigneeForm]="overlay_assigneeForm()"
                    [overlay_assigneeSelector]="overlay_assigneeSelector"
                    (toggleAssigneeFormOverlayOutput)="toggleAssigneeFormOverlay($event)"
                    (assigneeSelectionOutput)="selectAssignee($event)" />
                }
              } @else {
                <div class="grid grid-cols-1 gap-y-8 px-6 py-8">
                  <manage-assignee-edit-details [assigneeForm]="assigneeForm" (saveAssignee)="saveAssignee()" />
                </div>
              }
            </div>
          </div>
        </div>

        @if (overlay_assigneeForm()) {
          <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
            <button
              type="button"
              class="btn ml-3"
              [disabled]="assigneeForm.pristine || assigneeForm.controls['name'].errors"
              [ngClass]="assigneeForm.pristine || assigneeForm.controls['name'].errors ? 'btn-disabled cursor-not-allowed' : 'btn-primary'"
              (click)="saveAssignee()">
              Save
            </button>
            <button type="button" class="btn btn-cancel" (click)="toggleAssigneeFormOverlay(false)">Cancel</button>
          </div>
        } @else {
          <div class="flex justify-end bg-gray-100 px-6 py-6">
            <!-- *ngIf="!actionDetailService.overlay_assigneeForm" -->
            <button type="button" class="btn btn-cancel" (click)="toggleAssigneeSelectorWrapperOverlay()">Close</button>
          </div>
        }
      </div>
    </div>
  </div>
</div>
