import { inject, Injectable } from '@angular/core';
import { Auth, user, User } from '@angular/fire/auth';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private lastUid: string | null = null;
  private auth = inject(Auth);
  private router = inject(Router);
  private usersService = inject(UsersService);

  canActivate(): Observable<boolean> {
    return user(this.auth).pipe(
      take(1),
      switchMap((authState: User | null) => {
        if (!authState) {
          return of(false);
        } else if (authState.uid === this.lastUid) {
          return of(true);
        } else {
          return this.usersService.getUserById(authState.uid).pipe(
            take(1),
            map((user) => {
              if (user) {
                this.lastUid = authState.uid;
                return true;
              } else {
                return false;
              }
            })
          );
        }
      }),
      tap((authenticated) => {
        if (!authenticated) {
          this.router.navigate(['/signin']);
        }
      })
    );
  }
}
