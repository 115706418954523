import { inject, Injectable } from "@angular/core";
import { SupplementaryType, User, WriteEvent } from "cip";
import { deleteField, where, WithFieldValue } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { SupplementaryEnhanced } from "src/app/models/inspection/supplementary.model";

@Injectable({
  providedIn: "root",
})
export class SupplementaryService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Supplementary List
   * @param workspaceId
   * @param inspectionId
   * @param type
   */
  public getSupplementaryList$(workspaceId: string, inspectionId: string, type: SupplementaryType): Observable<SupplementaryEnhanced[]> {
    const path = this.collectionsService.supplementaryCol(workspaceId, inspectionId);
    const queryConstraints = [where("type", "==", type)];
    return this.firestoreUtilsService.getCollectionData<SupplementaryEnhanced>(path, queryConstraints);
  }

  /**
   * Save Supplementary Doc
   * @param workspaceId
   * @param inspectionId
   * @param supplementaryForm
   * @param user
   */
  public async saveSupplementaryDoc(workspaceId: string, inspectionId: string, supplementaryForm: SupplementaryEnhanced, user: User): Promise<void> {
    const path = this.collectionsService.supplementaryCol(workspaceId, inspectionId);
    const doc = `${path}/${supplementaryForm.id}`;
    const lastEvent = this.lastEventService.lastEvent("changed", user);
    const supplementaryObj = this.createSupplementaryObject(supplementaryForm, user, lastEvent);
    return await this.firestoreUtilsService.setDocumentDataWithFieldValue<SupplementaryEnhanced>(doc, supplementaryObj);
  }

  /**
   * Create Supplementary Object
   * @param supplementaryForm
   * @param user
   * @param lastEvent
   */
  private createSupplementaryObject(supplementaryForm: SupplementaryEnhanced, user: User, lastEvent: WithFieldValue<WriteEvent>): WithFieldValue<SupplementaryEnhanced> {
    return {
      title: supplementaryForm.title === "" || supplementaryForm.title === null || supplementaryForm.title === undefined ? deleteField() : supplementaryForm.title.trim(),
      content: supplementaryForm.content === "" || supplementaryForm.content === null || supplementaryForm.content === undefined ? deleteField() : supplementaryForm.content.trim(),
      type: supplementaryForm.type,
      order: supplementaryForm.order,
      is_deleted: false,
      last_event: lastEvent,
    };
  }
}
