<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:max-w-[625px] sm:align-middle">
      <div class="sm:flex sm:items-start">
        <div class="flex min-h-[166px] w-full flex-col space-y-4 overscroll-contain">
          <div class="flex flex-row items-center px-6 pt-6">
            <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">{{ duplicationTitle }}</h3>
          </div>

          <!-- Confirmation -->
          @if (duplicationService.overlay_duplicateConfirmation) {
            <ng-container *ngTemplateOutlet="confirmation"></ng-container>
          }

          <!-- Duplication -->
          @if (!duplicationService.overlay_duplicateConfirmation && duplicationObject) {
            <ng-container *ngTemplateOutlet="duplicating"></ng-container>
          }
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation -->
<ng-template #confirmation>
  @if (documentType !== 'categories-from-template') {
    <ng-container>
      <div class="mt-8 grid grid-cols-1 gap-6 px-6">
        <div class="sm:col-span-6">
          <info-view [message]="duplicationDescription" type="info" />
        </div>
        <div class="sm:col-span-6">
          <div class="form-input-wrapper">
            <label for="{{ duplicationTitle }}" class="form-input-label"
              >Title*
              @if (documentTitle.value === '') {
                <span> - Title is required</span>
              }
            </label>
            <input #documentTitle type="text" name="{{ duplicationTitle }}" id="{{ duplicationTitle }}" class="form-input" placeholder="{{ duplicationTitle }}" ngModel="{{ originalDocumentTitle }}" />
          </div>
        </div>
      </div>

      <div class="flex-row-reverse bg-gray-50 p-6 sm:flex">
        <button type="button" class="btn btn-primary ml-3" (click)="duplicate(documentTitle.value)" [disabled]="!documentTitle.value" [ngClass]="!documentTitle.value ? 'btn-disabled hover cursor-not-allowed' : 'btn-primary'">
          {{ duplicationTitle }}
        </button>
        <button type="button" class="btn btn-cancel ml-3" (click)="toggleDuplicationOverlay()">Close</button>
      </div>
    </ng-container>
  }
  @if (documentType === 'categories-from-template') {
    <div class="mt-8 grid grid-cols-1 gap-6 px-6">
      <p class="text-sm text-text_Subtitle">Are you sure you want to use this Template?</p>
    </div>

    <div class="flex-row-reverse bg-gray-50 p-6 sm:flex">
      <button type="button" aria-label="Confirm" class="btn btn-primary ml-3" (click)="duplicateCategoriesFromTemplate()">Confirm</button>
      <button type="button" aria-label="Cancel" class="btn btn-cancel" (click)="toggleDuplicationOverlay()">Close</button>
    </div>
  }
</ng-template>

<!-- Duplicating -->
<ng-template #duplicating>
  @if (duplicationObject) {
    <div class="flex flex-col items-center">
      @switch (duplicationObject!.status) {
        @case ('pending') {
          <ng-container *ngTemplateOutlet="inProgress"></ng-container>
        }
        @case ('running') {
          <ng-container *ngTemplateOutlet="inProgress"></ng-container>
        }
        @case ('success') {
          <ng-container *ngTemplateOutlet="success"></ng-container>
        }
        @case ('failed') {
          <ng-container *ngTemplateOutlet="failed"></ng-container>
        }
      }
    </div>

    @if (duplicationObject!.status === 'success' || duplicationObject!.status === 'failed') {
      <div class="bg-gray-50 p-6 sm:flex sm:flex-row-reverse">
        @if (duplicationObject!.status === 'success' && documentType !== 'categories-from-template') {
          <button type="button" class="btn btn-primary ml-3" (click)="navigateToNewDocument()">Navigate to new {{ navigateToText }}</button>
        }
        @if (duplicationObject!.status === 'success' || duplicationObject!.status === 'failed') {
          <button type="button" class="btn btn-cancel" (click)="toggleDuplicationOverlay()">Close</button>
        }
      </div>
    }
  }
</ng-template>

<!-- In Progress -->
<ng-template #inProgress>
  <div class="my-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
    <div class="col-span-6 flex justify-center">
      <svg class="-ml-1 h-10 w-10 animate-spin text-brand_Primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <div class="col-span-6 flex justify-center">
      <p class="text-sm text-text_Subtitle">{{ inProgressText }}</p>
    </div>
  </div>
</ng-template>

<!-- Success -->
<ng-template #success>
  <div class="my-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
    <div class="col-span-6 flex justify-center">
      <div class="flex h-10 w-10 items-center justify-center rounded-full bg-brand_Primary text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
        </svg>
      </div>
    </div>
    <div class="col-span-6 flex justify-center">
      <p class="text-sm text-text_Subtitle">{{ successText }}</p>
    </div>
  </div>
</ng-template>

<!-- Error -->
<ng-template #failed>
  <div class="flex flex-col items-center space-y-4">
    <!-- Error Icon-->
    <div class="flex h-10 w-10 items-center justify-center rounded-full bg-red-500 text-white">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
    <p class="text-sm text-text_Subtitle">{{ failedText }}</p>

    <!-- Error Code -->
    <p class="text-sm text-text_Subtitle">{{ duplicationObject!.error!.code }}</p>

    <!-- Error Message -->
    <p class="text-sm text-text_Subtitle">{{ duplicationObject!.error!.message }}</p>
  </div>
</ng-template>
