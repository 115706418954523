<div class="space-y-4">
  <test-form-title [testForm]="testForm" [currentCategoryOrder]="currentCategoryOrder" (enableOrDisableTestOutput)="enableOrDisableTest()" />
  @if (testForm.value.enabled === true) {
    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-6 md:col-span-4">
        <div class="content-card-wrapper">
          <!-- Header -->
          <div class="content-card-header flex items-center justify-between">
            <h3>Answer and Details</h3>
            @if (testForm.value.answered) {
              <button type="button" class="btn-secondary-small-default cursor-pointer" (click)="removeAnswer()">Remove Answer</button>
            }
          </div>

          <!-- Test Answer -->
          <form [formGroup]="testForm" class="content-card grid space-y-6 px-6 py-5">
            @switch (testForm.value.answer_type) {
              @case ("test-fail-pass") {
                <test-fail-pass [testForm]="testForm" />
              }
              @case ("test-low-high") {
                <test-low-high [testForm]="testForm" />
              }
              @case ("test-no-yes") {
                <test-no-yes [testForm]="testForm" />
              }
              @case ("test-percentage") {
                <test-percentage [testForm]="testForm" />
              }
              @case ("test-poor-fair-good") {
                <test-poor-fair-good [testForm]="testForm" />
              }
              @case ("test-progress") {
                <test-progress [testForm]="testForm" />
              }
              @case ("test-red-amber-green") {
                <test-red-amber-green [testForm]="testForm" (itemAnswerLimitationManagerCheckOutput)="itemAnswerLimitationManagerCheck($event)" />
              }
            }

            <div class="form-input-wrapper">
              <label for="test-notes" class="form-input-label">Notes</label>
              <textarea
                type="text"
                name="test-notes"
                id="test-notes"
                class="form-input"
                placeholder="Notes"
                formControlName="notes"
                (click)="canUserEditNotes()"
                (input)="onNotesTextAreaInput($event)"
                [ngClass]="{ 'cursor-not-allowed': !itemLimitationManagerResult }"></textarea>
            </div>

            <photo-dropzone [uploadSourceComponent]="'test'" (detectPhotosOutput)="detectPhotos($event)" [photoLimitationManagerResult]="photoLimitationManagerResult()" />

            @if (uploadFileArray) {
              @if (photoPreview && uploadFileArray.length > 0) {
                <photo-preview [uploadLimit]="webUploadLimit" [photosToPreview]="uploadFileArray" [uploadSourceComponent]="'item'" (removePhotoFromArrayOutput)="removePhotoFromArray($event)" />
              }
              @if (overlay_uploadPhotos) {
                <photo-upload-multiple
                  [files]="uploadFileArray"
                  [imageCount]="testForm.value.photos_count"
                  [path]="'workspaces/' + workspaceId + '/inspections/' + inspectionId + '/items/' + itemId + '/photos/'"
                  [fileType]="'jpeg'"
                  [largeMaxWidth]="1200"
                  [largeMaxHeight]="1200"
                  [thumbnailMaxWidth]="300"
                  [thumbnailMaxHeight]="300"
                  [itemId]="itemId"
                  [user]="user"
                  (photoDataOutput)="photoUploadsCompleteSaveTest($event)">
                </photo-upload-multiple>
              }
            }

            <test-form-photos
              [photos]="photos"
              [workspaceId]="workspaceId"
              [folderId]="folderId"
              [inspectionId]="inspectionId"
              [categoryId]="categoryId"
              [itemId]="itemId"
              (reorderPhotosOutput)="reorderPhotos($event)"
              (deletePhotoOutput)="deletePhoto($event)" />
          </form>
        </div>
      </div>
      <div class="col-span-6 md:col-span-2">
        <actions-list-wrapper [workspaceId]="workspaceId" [inspectionId]="inspectionId" [itemId]="itemId" />
      </div>
    </div>
  }
</div>
