import { Injectable } from "@angular/core";
import { AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { assertNever } from "../../util/assert";

@Injectable({
  providedIn: "root",
})
export class InfoViewStringsService {
  alertFilter(RevokeInvite: AlertType) {
    throw new Error("Method not implemented.");
  }
  private AddYourName: InfoViewFilter = {
    type: "info",
    message: "Thank you for creating an account with Checklist Inspector Pro. \n\nPlease add your name so other Members can identify you.",
  };

  private SubscriptionViewer: InfoViewFilter = {
    type: "warning",
    message: "Cancelling your subscription cannot be done in app, tap the button to get a step-by-step guide of how to cancel your Checklist Inspector Pro subscription.",
  };

  private SignIn: InfoViewFilter = {
    type: "error",
    message: "No account with this email has been found. Sign up or try another email.",
  };

  private EmailLinkSuccess: InfoViewFilter = {
    type: "success",
    message: "Email sent to {email}",
  };

  private EmailLink: InfoViewFilter = {
    type: "info",
    message: "Tap the link in the email to login securely",
  };

  private ItemEditorTest: InfoViewFilter = {
    type: "info",
    message: "Tests affect the score on the final Report. Answers all correspond to a score between 0% and 100%",
  };

  private ItemEditorQuestion: InfoViewFilter = {
    type: "info",
    message: "Does not affect the score on the final Report. Use to capture extra information that can optionally be included on the Report.",
  };

  private SignatureEditor: InfoViewFilter = {
    type: "warning",
    message: "The date and time you add a Signature will be captured and displayed on the Report as your Sign Off date for this Inspection.",
  };

  private MemberEditor: InfoViewFilter = {
    type: "info",
    message: "As the Owner of this Workspace, you are unable to change Role unless you Transfer Ownership to someone else.",
  };

  private TemplateItemEditor: InfoViewFilter = {
    type: "info",
    message: "Tests affect the score on the final Report. Answers all correspond to a score between 0% and 100%. : Does not affect the score on the final Report. Use to capture extra information that can optionally be included on the Report.",
  };

  private ThemeEditor: InfoViewFilter = {
    type: "info",
    message: "This is a Default Theme, it cannot be deleted but everything except its Title can be edited.",
  };

  private WorkspaceListInvited: InfoViewFilter = {
    type: "info",
    message: "You have been invited to join the following Workspaces. Tap Join to view the Inspections created in those Workspaces or create your own Workspace by tapping the + in the top right. saff",
  };

  private LeaveWorkspace1: InfoViewFilter = {
    type: "error",
    message: "Leaving this Workspace means you will no longer be able to access any Folders or Data contained with it.",
  };

  private LeaveWorkspace2: InfoViewFilter = {
    type: "info",
    message: "To regain access to the Workspace, an Admin or Owner will need to invite you again.",
  };

  private DeleteWorkspace1: InfoViewFilter = {
    type: "info",
    message: "If you just want to leave this Workspace, use the button below.",
  };

  private DeleteWorkspace2: InfoViewFilter = {
    type: "error",
    message:
      "If you delete this Workspace then your Folders, Inspections, Tests, Images and other data will be deleted immediately. \n\nAll Members of this Workspace will be removed and they will lose access to any Inspections they are working on.\n\nDeletion of this Workspace is permanent and cannot be undone or recovered in any way.",
  };

  private OwnershipEditor1: InfoViewFilter = {
    type: "warning",
    message: 'Transferring means you will no longer be the Owner of the Workspace \'(document?.name ?? """")\' and will automatically be assigned the new Role of Admin.',
  };

  private OwnershipEditor2: InfoViewFilter = {
    type: "error",
    message: "Transferring the Ownership of your Workspace is immediate and cannot be undone.",
  };

  private ProfileEditor: InfoViewFilter = {
    type: "warning",
    message: "Changing your details here will change them in all Workspaces that you are a Member of.",
  };

  private DeleteAccount1: InfoViewFilter = {
    type: "error",
    message: "Deleting your Account will immediately and permanently remove all your Folders, Inspections, Tests and other data. This cannot be undone and your data cannot be recovered.",
  };

  private DeleteAccount2: InfoViewFilter = {
    type: "warning",
    message: "Deleting your account does not cancel your subscription. If you do not cancel you subscription you will still be charged when it is due for renewal. To manage your subscription please follow the link below: ",
  };

  private EmailEditor1: InfoViewFilter = {
    type: "info",
    message: "To change your email you need to have recently logged in to your Checklist Inspector Pro account.",
  };

  private EmailEditor2: InfoViewFilter = {
    type: "success",
    message: "Current Email: ",
  };

  private SubscriptionViewerCancelLinkWarning: InfoViewFilter = {
    type: "warning",
    message: "Cancelling your subscription cannot be done in app, tap the button to get a step-by-step guide of how to cancel your Checklist Inspector Pro subscription.",
  };

  private CacheEditor1: InfoViewFilter = {
    type: "info",
    message: "Some images from your Inspections are also stored locally on this device. This is to enable access to your Inspection images quickly to continue working on them, even whilst offline.",
  };

  private CacheEditor2: InfoViewFilter = {
    type: "warning",
    message: "If you find this cache is taking up too much space, you can clear it. Please note that your device will download these images again as you access your Inspections.",
  };

  private SupplementaryEditor: InfoViewFilter = {
    type: "info",
    message: "Leave this blank if you do not want the Title to appear on the Report.",
  };

  private InternalNotes: InfoViewFilter = {
    type: "info",
    message: "Internal Notes do not appear on Reports.",
  };

  private DuplicateItem: InfoViewFilter = {
    type: "info",
    message: "Duplicating this Item will copy its Title, Mode and Type but not its Score, Notes, Actions or Photos",
  };

  private ImportTemplate: InfoViewFilter = {
    type: "info",
    message: "Closing this screen will not cancel the import process.",
  };

  private CreateInspectionFromTemplate: InfoViewFilter = {
    type: "info",
    message: "Creates a new Inspection with the Categories and Items contained in this Template.",
  };

  private InviteEditor: InfoViewFilter = {
    type: "info",
    message: "A Role is a predefined set of permissions which must be assigned to new Members of the Workspace.",
  };

  private InviteEditorMember: InfoViewFilter = {
    type: "info",
    message: `Invites add new Members to your Workspace. Use them to:
  - Set member roles
  - Track pending invites in the Members section
  - Manage team growth efficiently`,
  };

  private CustomRoleEditor: InfoViewFilter = {
    type: "warning",
    message: "A Custom Role cannot be deleted if a Member is currently assigned that Role. Please unassign all Members from this Role if you wish to delete it.",
  };

  private PermissionsEditor: InfoViewFilter = {
    type: "info",
    message: "Set or change the Permissions for this Role and for All Members to whom it is assigned.",
  };
  private ConnectedDevices: InfoViewFilter = {
    type: "error",
    message: "There are too many devices currently connected. Please choose a device from the list below to disconnect and sign out on. You can have a maximum of 3 devices connected at any one time.",
  };

  private AssigneeEditorReport: InfoViewFilter = {
    type: "info",
    message: "Actions on the Report will specify the Assignee as the responsible person or company.",
  };

  private Subscription: InfoViewFilter = {
    type: "info",
    message: "Your membership is managed by Apple. To see details or make changes, use an iOS device or iTunes. Learn more.",
  };

  infoViewFilter(type: InfoViewType): InfoViewFilter {
    switch (type) {
      case InfoViewType.AddYourName:
        return this.AddYourName;
      case InfoViewType.SubscriptionViewer:
        return this.SubscriptionViewer;
      case InfoViewType.SignIn:
        return this.SignIn;
      case InfoViewType.EmailLinkSuccess:
        return this.EmailLinkSuccess;
      case InfoViewType.EmailLink:
        return this.EmailLink;
      case InfoViewType.ItemEditorTest:
        return this.ItemEditorTest;
      case InfoViewType.ItemEditorQuestion:
        return this.ItemEditorQuestion;
      case InfoViewType.SignatureEditor:
        return this.SignatureEditor;
      case InfoViewType.MemberEditor:
        return this.MemberEditor;
      case InfoViewType.TemplateItemEditor:
        return this.TemplateItemEditor;
      case InfoViewType.ThemeEditor:
        return this.ThemeEditor;
      case InfoViewType.WorkspaceListInvited:
        return this.WorkspaceListInvited;
      case InfoViewType.LeaveWorkspace1:
        return this.LeaveWorkspace1;
      case InfoViewType.LeaveWorkspace2:
        return this.LeaveWorkspace2;
      case InfoViewType.DeleteWorkspace1:
        return this.DeleteWorkspace1;
      case InfoViewType.DeleteWorkspace2:
        return this.DeleteWorkspace2;
      case InfoViewType.OwnershipEditor1:
        return this.OwnershipEditor1;
      case InfoViewType.OwnershipEditor2:
        return this.OwnershipEditor2;
      case InfoViewType.ProfileEditor:
        return this.ProfileEditor;
      case InfoViewType.DeleteAccount1:
        return this.DeleteAccount1;
      case InfoViewType.DeleteAccount2:
        return this.DeleteAccount2;
      case InfoViewType.EmailEditor1:
        return this.EmailEditor1;
      case InfoViewType.EmailEditor2:
        return this.EmailEditor2;
      case InfoViewType.SubscriptionViewerCancelLinkWarning:
        return this.SubscriptionViewerCancelLinkWarning;
      case InfoViewType.CacheEditor1:
        return this.CacheEditor1;
      case InfoViewType.CacheEditor2:
        return this.CacheEditor2;
      case InfoViewType.SupplementaryEditor:
        return this.SupplementaryEditor;
      case InfoViewType.InternalNotes:
        return this.InternalNotes;
      case InfoViewType.DuplicateItem:
        return this.DuplicateItem;
      case InfoViewType.ImportTemplate:
        return this.ImportTemplate;
      case InfoViewType.CreateInspectionFromTemplate:
        return this.CreateInspectionFromTemplate;
      case InfoViewType.InviteEditor:
        return this.InviteEditor;
      case InfoViewType.InviteEditorMember:
        return this.InviteEditorMember;
      case InfoViewType.CustomRoleEditor:
        return this.CustomRoleEditor;
      case InfoViewType.PermissionsEditor:
        return this.PermissionsEditor;
      case InfoViewType.ConnectedDevices:
        return this.ConnectedDevices;
      case InfoViewType.AssigneeEditorReport:
        return this.AssigneeEditorReport;
      case InfoViewType.Subscription:
        return this.Subscription;
      default:
        assertNever(type);
    }
  }

  /**
   * Get Info View
   * @param type
   * @returns
   */
  public getInfoView(type: InfoViewType): InfoViewFilter {
    const infoView = this.infoViewFilter(type);
    return infoView;
  }
}
