import { NgStyle } from "@angular/common";
import { Component, inject } from "@angular/core";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-content-site",
  standalone: true,
  imports: [NgStyle],
  templateUrl: "./report-content-site.component.html",
  styleUrl: "./report-content-site.component.scss",
})
export class ReportContentSiteComponent {
  public reportsService = inject(ReportsService);
}
