import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemEnhanced } from 'src/app/models/item/item.model';

@Component({
  selector: 'test-navigation',
  standalone: true,
  imports: [NgClass],
  templateUrl: './test-navigation.component.html',
  styleUrl: './test-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestNavigationComponent {
  @Input() currentItem!: ItemEnhanced;
  @Input() currentCategoryOrder = 0;
  @Input() totalItems = 0;
  @Output() navigateToPreviousItemEvent = new EventEmitter<void>();
  @Output() navigateToNextItemEvent = new EventEmitter<void>();

  /**
   * Navigates to the previous item.
   */
  public navigateToPreviousItem(): void {
    this.navigateToPreviousItemEvent.emit();
  }

  /**
   * Navigates to the next item.
   */
  public navigateToNextItem(): void {
    this.navigateToNextItemEvent.emit();
  }
}
