<form [formGroup]="assigneeForm">
  <div class="content-card-wrapper">
    <div class="content-card">
      <div class="content-grid grid-cols-6 gap-x-4 gap-y-6">
        <div class="sm:col-span-3">
          <div class="form-input-wrapper">
            <label for="assingee-name" class="form-input-label">
              Name*
              @if (!assigneeForm.pristine && assigneeForm.controls["name"].errors) {
                <span class="required-field"> - Name is required</span>
              }
            </label>
            <input type="text" name="assingee-name" id="assingee-name" class="form-input" placeholder="Name" formControlName="name" />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="form-input-wrapper">
            <label for="assignee-email" class="form-input-label"
              >Email
              @if (!assigneeForm.pristine && assigneeForm.get("email")!.hasError("pattern")) {
                <span class="required-field"> - Please provide a valid email address</span>
              }
            </label>
            <input type="text" name="assignee-email" id="assignee-email" class="form-input" placeholder="Email" formControlName="email" />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="form-input-wrapper">
            <label for="assignee-company" class="form-input-label">Company</label>
            <input type="text" name="assignee-company" id="assignee-company" class="form-input" placeholder="Company" formControlName="company" />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
