import { Routes } from '@angular/router';
import { RolePermissions } from 'cip';
import { AuthGuard } from './core/guards/auth.guard';
import { LimitationDataGuard } from './core/guards/limitation-data.guard';
import { LimitationGuard } from './core/guards/limitation.guard';
import { PendingChangesGuard } from './core/guards/pending-changes.guard';
import { ProfileCompleteGuard } from './core/guards/profile-complete.guard';
import { SigninGuard } from './core/guards/signin.guard';

export interface RouteData {
  featureAction: keyof RolePermissions;
  checkSubscription?: boolean;
  returnPathSegments: string[];
  blockNewOnFreeSubscription?: boolean;
}
interface WidthAndRouteData extends RouteData {
  widthStyle: string;
}

export const routes: Routes = [
  {
    canActivate: [SigninGuard],
    path: 'signin',
    loadComponent: () => import('./sections/login/login-panel/login-panel.component').then((m) => m.LoginPanelComponent),
  },

  {
    path: 'loginconfirmation',
    loadComponent: () => import('./sections/login/login-confirmation/login-confirmation.component').then((m) => m.LoginConfirmationComponent),
  },
  {
    path: 'signup',
    loadComponent: () => import('./sections/login/login-signup/login-signup.component').then((m) => m.LoginSignupComponent),
  },
  {
    path: 'profilecomplete',
    loadComponent: () => import('./sections/login/profile-complete/profile-complete.component').then((m) => m.ProfileCompleteComponent),
  },

  {
    path: 'workspaces',
    canActivate: [AuthGuard, ProfileCompleteGuard],
    loadComponent: () => import('./sections/workspaces/all-workspaces/all-workspaces-wrapper/all-workspaces-wrapper.component').then((m) => m.AllWorkspacesWrapperComponent),
  },

  {
    path: 'workspace/:workspaceId',
    canActivate: [AuthGuard, ProfileCompleteGuard, LimitationDataGuard],
    loadComponent: () => import('./sections/sections-wrapper/sections-wrapper.component').then((m) => m.SectionsWrapperComponent),
    children: [
      {
        path: 'folders',
        loadComponent: () => import('./sections/folders/list/folders-list-wrapper/folders-list-wrapper.component').then((m) => m.FoldersListWrapperComponent),
      },
      {
        path: 'folders/:folderId/inspections',
        loadComponent: () => import('./sections/inspections/list/inspections-list-wrapper/inspections-list-wrapper.component').then((m) => m.InspectionsListWrapperComponent),
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/overview',
        loadComponent: () => import('./sections/inspections/overview/inspection-overview-wrapper/inspection-overview-wrapper.component').then((m) => m.InspectionOverviewWrapperComponent),
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/details',
        loadComponent: () => import('./sections/inspections/edit/inspection-details/inspection-details-wrapper/inspection-details-wrapper.component').then((m) => m.InspectionDetailsWrapperComponent),
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
          featureAction: 'inspection_create_update',
          returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'overview'],
        } as RouteData,
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/categories',
        loadComponent: () => import('./sections/categories/list/inspection-categories-list-wrapper/inspection-categories-list-wrapper.component').then((m) => m.InspectionCategoriesListWrapperComponent),
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/categories/:categoryId/editor',
        loadComponent: () => import('./sections/categories/edit/inspection-category-edit-wrapper/inspection-category-edit-wrapper.component').then((m) => m.InspectionCategoryEditWrapperComponent),
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
          featureAction: 'category_create_update',
          returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'categories'],
        } as RouteData,
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/categories/:categoryId/items',
        loadComponent: () => import('./sections/items/list/inspection-items-list-wrapper/inspection-items-list-wrapper.component').then((m) => m.InspectionItemsListWrapperComponent),
      },

      {
        path: 'folders/:folderId/inspections/:inspectionId/categories/:categoryId/items/:itemId/editor',
        loadComponent: () => import('./sections/items/edit/inspection-item-edit-wrapper/inspection-item-edit-wrapper.component').then((m) => m.InspectionItemEditWrapperComponent),
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
          featureAction: 'item_create_update',
          returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'categories', ':categoryId', 'items'],
        } as RouteData,
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/categories/:categoryId/items/:itemId',
        loadComponent: () => import('./sections/tests/test-wrapper/test-wrapper.component').then((m) => m.TestWrapperComponent),
        // canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
          featureAction: 'item_create_update',
          returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'categories', ':categoryId', 'items'],
        } as RouteData,
        children: [
          {
            path: 'action/:actionId',
            loadComponent: () => import('./sections/actions/edit/action-edit-wrapper/action-edit-wrapper.component').then((m) => m.ActionEditWrapperComponent),
            outlet: 'actionEditor',
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: {
              featureAction: 'action_create_update',
              returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'categories', ':categoryId', 'items', ':itemId'],
            } as RouteData,
          },
        ],
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/categories/:categoryId/items/:itemId/photo/:photoId',
        loadComponent: () => import('./sections/annotations/annotations-wrapper/annotations-wrapper.component').then((m) => m.AnnotationsWrapperComponent),
        data: { route: 'fullWidth' },
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/signoff',
        loadComponent: () => import('./sections/inspections/sign-off/list/inspection-signoff-list-wrapper/inspection-signoff-list-wrapper.component').then((m) => m.InspectionSignoffListWrapperComponent),
        data: { featureAction: 'inspection_create_update' } as RouteData,
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/signoff/:signatureId/editor',
        loadComponent: () => import('./sections/inspections/sign-off/edit/inspection-signoff-edit-wrapper/inspection-signoff-edit-wrapper.component').then((m) => m.InspectionSignoffEditWrapperComponent),
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
          featureAction: 'signature_create_update',
          returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'signoff'],
        } as RouteData,
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/summary',
        loadComponent: () => import('./sections/inspections/supplementary/inspection-summary/inspection-summary.component').then((m) => m.InspectionSummaryComponent),
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
          featureAction: 'inspection_create_update',
          returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'overview'],
        } as RouteData,
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/introduction',
        loadComponent: () => import('./sections/inspections/supplementary/inspection-introduction/inspection-introduction.component').then((m) => m.InspectionIntroductionComponent),
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
          featureAction: 'inspection_create_update',
          returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'overview'],
        } as RouteData,
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/internalnotes',
        loadComponent: () => import('./sections/inspections/supplementary/inspection-internal-notes/inspection-internal-notes.component').then((m) => m.InspectionInternalNotesComponent),
        canActivate: [LimitationGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
          featureAction: 'inspection_create_update',
          returnPathSegments: ['folders', ':folderId', 'inspections', ':inspectionId', 'overview'],
        } as RouteData,
      },
      {
        path: 'folders/:folderId/inspections/:inspectionId/report',
        loadComponent: () => import('./sections/reports/report-wrapper/report-wrapper.component').then((m) => m.ReportWrapperComponent),
        data: { route: 'fullWidth' },
      },
      {
        path: 'manage',
        data: { route: 'fullWidth' },
        loadComponent: () => import('./sections/workspaces/current-workspace/current-workspace-wrapper/current-workspace-wrapper.component').then((m) => m.CurrentWorkspaceWrapperComponent),
        children: [
          {
            path: 'workspace',
            canActivate: [LimitationGuard],
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-workspace/manage-workspace-wrapper/manage-workspace-wrapper.component').then((m) => m.ManageWorkspaceWrapperComponent),
            data: {
              featureAction: 'workspace_update',
            } as RouteData,
          },
          {
            path: 'members',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-members/list/manage-members-list-wrapper/manage-members-list-wrapper.component').then((m) => m.ManageMembersListWrapperComponent),
          },
          {
            path: 'members/:memberId',
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: {
              featureAction: 'member_update',
              returnPathSegments: ['manage', 'members'],
            } as RouteData,
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-members/edit/members/manage-member-edit-wrapper/manage-member-edit-wrapper.component').then((m) => m.ManageMemberEditWrapperComponent),
          },
          {
            path: 'members/invites/:inviteId',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-members/edit/invites/manage-members-edit-invite-wrapper/manage-members-edit-invite-wrapper.component').then((m) => m.ManageMembersEditInviteWrapperComponent),
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: {
              featureAction: 'invite_create_update',
              checkSubscription: true,
              returnPathSegments: ['manage', 'members'],
            } as RouteData,
          },
          {
            path: 'roles',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-roles/list/manage-roles-list-wrapper/manage-roles-list-wrapper.component').then((m) => m.ManageRolesListWrapperComponent),
          },
          {
            path: 'roles/:roleId',
            canActivate: [LimitationGuard],
            data: {
              featureAction: 'role_create_update',
              returnPathSegments: ['manage', 'roles'],
            } as RouteData,
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-roles/edit/manage-role-edit-wrapper/manage-role-edit-wrapper.component').then((m) => m.ManageRoleEditWrapperComponent),
          },
          {
            path: 'roles/default/:roleId',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-roles/edit/manage-role-default-viewer/manage-role-default-viewer.component').then((m) => m.ManageRoleDefaultViewerComponent),
          },
          {
            path: 'themes',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-themes/list/manage-themes-list-wrapper/manage-themes-list-wrapper.component').then((m) => m.ManageThemesListWrapperComponent),
          },

          {
            path: 'themes/:themeId/editor',
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: {
              widthStyle: 'fullWidth',
              featureAction: 'theme_create_update',
              checkSubscription: true,
              returnPathSegments: ['manage', 'themes'],
              blockNewOnFreeSubscription: true,
            } as WidthAndRouteData,
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-themes/edit/manage-theme-edit-wrapper/manage-theme-edit-wrapper.component').then((m) => m.ManageThemeEditWrapperComponent),
          },
          {
            path: 'delete-workspace',
            loadComponent: () => import('./sections/workspaces/current-workspace/current-workspace-delete/current-workspace-delete.component').then((m) => m.CurrentWorkspaceDeleteComponent),
          },
          {
            path: 'assignees',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-assignees/list/manage-assignees-list-wrapper/manage-assignees-list-wrapper.component').then((m) => m.ManageAssigneesListWrapperComponent),
          },
          {
            path: 'assignees/:assigneeId',
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: {
              featureAction: 'assignee_create_update',
              returnPathSegments: ['manage', 'assignees'],
            } as RouteData,
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-assignees/edit/manage-assignee-edit-wrapper/manage-assignee-edit-wrapper.component').then((m) => m.ManageAssigneeEditWrapperComponent),
          },
          {
            path: 'clients',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-clients/list/manage-clients-list-wrapper/manage-clients-list-wrapper.component').then((m) => m.ManageClientsListWrapperComponent),
          },
          {
            path: 'clients/:clientId',
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: {
              featureAction: 'client_create_update',
              returnPathSegments: ['manage', 'clients'],
            } as RouteData,
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-clients/edit/manage-client-edit-wrapper/manage-client-edit-wrapper.component').then((m) => m.ManageClientEditWrapperComponent),
          },
          {
            path: 'sites',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-sites/list/manage-sites-list-wrapper/manage-sites-list-wrapper.component').then((m) => m.ManageSitesListWrapperComponent),
          },
          {
            path: 'sites/:siteId',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-sites/edit/manage-site-edit-wrapper/manage-site-edit-wrapper.component').then((m) => m.ManageSiteEditWrapperComponent),
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: {
              featureAction: 'site_create_update',
              returnPathSegments: ['manage', 'sites'],
            } as RouteData,
          },
          {
            path: 'templates',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-templates/list/manage-templates-list-wrapper/manage-templates-list-wrapper.component').then((m) => m.ManageTemplatesListWrapperComponent),
          },
          {
            path: 'templates/:templateId/details',
            canActivate: [LimitationGuard],
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-templates/edit/manage-template-edit-wrapper/manage-template-edit-wrapper.component').then((m) => m.ManageTemplateEditWrapperComponent),
            data: {
              featureAction: 'template_create_update',
              returnPathSegments: ['manage', 'templates'],
            } as RouteData,
          },
          {
            path: 'templates/:templateId/overview',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-templates/overview/manage-template-overview-wrapper/manage-template-overview-wrapper.component').then((m) => m.ManageTemplateOverviewWrapperComponent),
          },
          {
            path: 'templates/:templateId/categories',
            loadComponent: () =>
              import('./sections/workspaces/current-workspace/manage-templates/categories/list/manage-template-categories-list-wrapper/manage-template-categories-list-wrapper.component').then((m) => m.ManageTemplateCategoriesListWrapperComponent),
          },
          {
            path: 'templates/:templateId/categories/:categoryId/editor',
            loadComponent: () =>
              import('./sections/workspaces/current-workspace/manage-templates/categories/edit/manage-template-category-edit-wrapper/manage-template-category-edit-wrapper.component').then((m) => m.ManageTemplateCategoryEditWrapperComponent),
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { featureAction: 'template_create_update', returnPathSegments: ['manage', 'templates', ':templateId', 'categories'] } as RouteData,
          },
          {
            path: 'templates/:templateId/categories/:categoryId/items',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-templates/items/list/manage-template-items-list-wrapper/manage-template-items-list-wrapper.component').then((m) => m.ManageTemplateItemsListWrapperComponent),
          },
          {
            path: 'templates/:templateId/categories/:categoryId/items/:itemId/editor',
            loadComponent: () => import('./sections/workspaces/current-workspace/manage-templates/items/edit/manage-template-item-edit-wrapper/manage-template-item-edit-wrapper.component').then((m) => m.ManageTemplateItemEditWrapperComponent),
            canActivate: [LimitationGuard],
            canDeactivate: [PendingChangesGuard],
            data: { featureAction: 'template_create_update', returnPathSegments: ['manage', 'templates', ':templateId', 'categories', ':categoryId', 'items'] } as RouteData,
          },
        ],
      },
      {
        path: 'account',
        loadComponent: () => import('./sections/account/account-wrapper/account-wrapper.component').then((m) => m.AccountWrapperComponent),
        children: [
          {
            path: 'profile',
            loadComponent: () => import('./sections/account/account-profile/account-profile.component').then((m) => m.AccountProfileComponent),
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: 'email',
            loadComponent: () => import('./sections/account/account-email/account-email.component').then((m) => m.AccountEmailComponent),
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: 'subscription',
            loadComponent: () => import('./sections/account/account-subscription/account-subscription.component').then((m) => m.AccountSubscriptionComponent),
          },
          {
            path: 'devices',
            loadComponent: () => import('./sections/account/account-connected-devices/account-connected-devices.component').then((m) => m.AccountConnectedDevicesComponent),
          },
          {
            path: 'mydata',
            loadComponent: () => import('./sections/account/account-my-data/account-my-data.component').then((m) => m.AccountMyDataComponent),
          },
          {
            path: 'deleteaccount',
            loadComponent: () => import('./sections/account/account-delete/account-delete.component').then((m) => m.AccountDeleteComponent),
          },
          {
            path: 'support',
            loadComponent: () => import('./sections/account/account-help-and-support/account-help-and-support.component').then((m) => m.AccountHelpAndSupportComponent),
          },
        ],
      },
      {
        path: 'auditlogs',
        loadComponent: () => import('./sections/audit-logs/list/audit-logs-list-wrapper/audit-logs-list-wrapper.component').then((m) => m.AuditLogsListWrapperComponent),
      },
      {
        path: 'auditlogs/:auditLogId',
        loadComponent: () => import('./sections/audit-logs/overview/audit-log-overview-wrapper/audit-log-overview-wrapper.component').then((m) => m.AuditLogOverviewWrapperComponent),
      },
    ],
  },

  { path: '', redirectTo: 'signin', pathMatch: 'full' },
];
