<div class="flex w-full flex-row px-12">
  <search-bar [placeholder]="'Search Roles'" (searchTermChange)="onSearch($event)" />
</div>

<div class="px-6 py-8">
  <!-- Current Role -->
  @if (currentRole && currentRole.type === "default") {
    <div class="mb-10 block">
      <div class="mb-3 flex items-center justify-between">
        <div class="container-header" style="padding-bottom: 0">Current Role</div>
      </div>
      <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 lg:px-8">
        <div class="mb-4">
          <div class="list-row">
            <div class="list-a group border-none">
              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                <div class="min-w-0 flex-auto">
                  <p class="list-title">{{ currentRole.title }}</p>
                  <p class="list-meta">{{ currentRole.description }}</p>
                </div>
              </div>
              <div class="flex items-center text-brand_Primary">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  <!-- Roles List -->
  <ul id="defaultRolesList">
    <div class="mb-3 flex items-center justify-between">
      <div class="container-header" style="padding-bottom: 0">Select Default Role</div>
    </div>

    <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 lg:px-8">
      @for (role of filteredRoles; track $index; let last = $last) {
        @if (role.id !== currentRole?.id && role.id !== "Owner") {
          <li [class.hidden]="role.hidden">
            <div class="list-row" [ngClass]="last ? 'border-none' : 'border-b'">
              <div class="list-a group cursor-pointer" (click)="selectRole(role)">
                <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                  <div class="min-w-0 flex-auto">
                    <p class="list-title">{{ role.title }}</p>
                    <p class="list-meta">{{ role.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        }
      }
    </div>
  </ul>
  @if (allRolesHidden) {
    <search-empty [searchTerm]="searchTerm" />
  }
</div>
