@if (themeSettingsForm.value.details_site) {
  <div class="site-wrapper">
    <div class="header">
      <p class="title text-group-1" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">London Central Building</p>
      <div class="divider" [ngStyle]="{ 'background-color': themeSettingsForm.value.colours_brand }"></div>
    </div>
    <div class="site-content">
      <div class="site-address-wrapper">
        <div class="site-address-left">
          <p class="site-address-header text-group-2">Address</p>
          <p class="site-address text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">16-18 St James's Pl, St. James's, London SW1A 1NJ, United Kingdom</p>
          <div class="site-ref-wrapper">
            <p class="site-ref-header text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Reference</p>
            <p class="site-ref text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">Reference</p>
          </div>
        </div>
        @if (themeSettingsForm.value.details_site_photo) {
          <div class="site-address-right">
            <div
              class="site-image h-[320px] w-[320px] bg-red-100 bg-cover bg-center"
              style="background-image: url(https://images.unsplash.com/photo-1665395806066-d47f41e6aa6d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2487&q=80)"></div>
          </div>
        }
      </div>
      <div class="site-separator"></div>
      <div class="site-location-wrapper">
        <div class="site-location-left">
          <p class="site-location-header text-group-2">Location</p>
          <div class="site-coordinates-wrapper">
            <div class="site-coordinates-long">
              <p class="site-coordinates-header text-group-5 text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Longitude</p>
              <p class="site-coordinates-data text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">-0.140610</p>
            </div>
            <div class="site-coordinates-lat">
              <p class="site-coordinates-header text-group-5 text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Latitude</p>
              <p class="site-coordinates-data text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">51.505870</p>
            </div>
          </div>
          <div class="site-w3w-wrapper">
            <div class="site-w3w-logo">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 522.4174 87.8988">
                <defs>
                  <style>
                    .cls-1 {
                      fill: none;
                    }
                    .cls-2 {
                      clip-path: url(#clip-path);
                    }
                    .cls-3 {
                      fill: #e11f26;
                    }
                    .cls-4 {
                      fill: #fff;
                    }
                    .cls-5 {
                      fill: #0a3049;
                    }
                  </style>
                  <clipPath id="clip-path" transform="translate(-49.1271 -49.1271)"><rect class="cls-1" width="620.6617" height="186.151" /></clipPath>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="artwork">
                    <g class="cls-2">
                      <path class="cls-3" d="M137.0259,49.1271H49.1271v87.8988h87.8988V49.1271Z" transform="translate(-49.1271 -49.1271)" />
                      <path class="cls-4" d="M87.583,112.3052a2.75,2.75,0,0,1-2.606-3.6159L95.9643,75.7272a2.7471,2.7471,0,0,1,5.212,1.7382L90.189,110.4275A2.747,2.747,0,0,1,87.583,112.3052Z" transform="translate(-49.1271 -49.1271)" />
                      <path class="cls-4" d="M71.1019,112.3052a2.75,2.75,0,0,1-2.606-3.6159L79.4833,75.7272a2.7471,2.7471,0,0,1,5.212,1.7382L73.708,110.4275A2.7471,2.7471,0,0,1,71.1019,112.3052Z" transform="translate(-49.1271 -49.1271)" />
                      <path class="cls-4" d="M104.064,112.3052a2.75,2.75,0,0,1-2.606-3.6159l10.9873-32.9621a2.7472,2.7472,0,0,1,5.2121,1.7382L106.67,110.4275A2.747,2.747,0,0,1,104.064,112.3052Z" transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M491.4269,73.8377c-4.4868.0043-8.3871,1.0985-10.9224,3.487v-.743a2.7468,2.7468,0,0,0-5.4935,0v32.974a2.7468,2.7468,0,0,0,5.4935,0V91.3705c0-8.1551,2.7044-12.0287,10.85-12.0394a2.9111,2.9111,0,0,0,2.783-2.7118A2.7332,2.7332,0,0,0,491.4269,73.8377Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M189.9716,112.3024a2.7478,2.7478,0,0,1-2.58-1.8052L177.9842,84.72,168.8,110.4784a2.4093,2.4093,0,0,1-2.5871,1.824,2.7478,2.7478,0,0,1-2.58-1.8052l-12.029-32.9606a2.7468,2.7468,0,1,1,5.1608-1.883l9.4191,25.8068L175.2617,76a2.7465,2.7465,0,0,1,4.3561-1.5825,2.7864,2.7864,0,0,1,.97,1.4135l9.3547,25.63,9.19-25.7746a2.7467,2.7467,0,1,1,5.1742,1.8454l-11.7473,32.9472A2.5074,2.5074,0,0,1,189.9716,112.3024Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M229.9363,73.835a16.094,16.094,0,0,0-10.6033,3.49V60.1014a2.7468,2.7468,0,0,0-5.4935,0v49.4543a2.7468,2.7468,0,0,0,5.4935,0V89.1082c.008-1.0005.38-9.78,10.6033-9.78,9.0891,0,9.4378,8.554,9.4472,9.517v20.71a2.7467,2.7467,0,0,0,5.4934,0v-20.71C244.8769,83.6524,241.7547,73.835,229.9363,73.835Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M318.6046,106.809c-4.6391,0-6.447-2.0359-6.447-7.2638V79.3284h6.447a2.7467,2.7467,0,0,0,0-5.4934h-6.447V67.8507a2.7467,2.7467,0,1,0-5.4934,0V73.835h-4.2958a2.7467,2.7467,0,0,0,0,5.4934h4.2958V99.5452c0,11.1,7.481,12.7572,11.94,12.7572a2.7467,2.7467,0,0,0,0-5.4934Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M345.0892,113.598A14.4939,14.4939,0,0,1,330.681,100.21a2.7472,2.7472,0,0,1,5.48-.3969A8.9525,8.9525,0,1,0,345.0892,90.2a2.7467,2.7467,0,1,1,0-5.4934,7.3711,7.3711,0,1,0-7.35-7.9156,2.7472,2.7472,0,0,1-5.48-.397,12.8654,12.8654,0,1,1,21.0148,10.8608,14.4421,14.4421,0,0,1-8.1851,26.3433Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M406.3234,112.3024a2.7479,2.7479,0,0,1-2.58-1.8052L394.3347,84.72l-9.183,25.7585a2.7492,2.7492,0,0,1-2.5764,1.824h-.0107a2.7478,2.7478,0,0,1-2.58-1.8052l-12.03-32.9606a2.7468,2.7468,0,0,1,5.1608-1.883l9.419,25.8068L391.6121,76a2.7466,2.7466,0,0,1,4.3561-1.5825,2.786,2.786,0,0,1,.97,1.4135l9.3547,25.63,9.19-25.7746a2.7467,2.7467,0,1,1,5.1742,1.8454l-11.746,32.9472a2.749,2.749,0,0,1-2.5763,1.824Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M446.2612,112.3024a19.227,19.227,0,1,1,19.2271-19.227A19.2495,19.2495,0,0,1,446.2612,112.3024Zm0-32.9606a13.7336,13.7336,0,1,0,13.7336,13.7336A13.75,13.75,0,0,0,446.2612,79.3418Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M533.3323,57.3547a2.746,2.746,0,0,0-2.7468,2.7467V79.3831a17.8274,17.8274,0,0,0-12.8323-5.5347c-10.1044,0-18.3257,8.6264-18.3257,19.227s8.2213,19.227,18.3257,19.227a17.8277,17.8277,0,0,0,12.8323-5.5346v2.7879a2.7468,2.7468,0,0,0,5.4935,0V60.1014A2.746,2.746,0,0,0,533.3323,57.3547ZM517.7532,106.809c-7.076,0-12.8323-6.1613-12.8323-13.7336s5.7563-13.7336,12.8323-13.7336,12.8323,6.1614,12.8323,13.7336S524.8292,106.809,517.7532,106.809Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M287.3925,73.8484a2.746,2.746,0,0,0-2.7468,2.7467V79.42a17.8333,17.8333,0,0,0-12.8739-5.5712c-10.1043,0-18.3257,8.6264-18.3257,19.227s8.2214,19.227,18.3257,19.227a17.8333,17.8333,0,0,0,12.8739-5.5712s.2044-.5088,0,2.8245a2.7468,2.7468,0,0,0,5.4935,0V76.5951A2.746,2.746,0,0,0,287.3925,73.8484ZM271.7718,106.809c-7.076,0-12.8323-6.1613-12.8323-13.7336s5.7563-13.7336,12.8323-13.7336,12.831,6.1614,12.831,13.7336S278.8465,106.809,271.7718,106.809Z"
                        transform="translate(-49.1271 -49.1271)" />
                      <path
                        class="cls-5"
                        d="M558.9077,112.2768c-5.7208,0-10.44-2.4624-12.9454-6.7568a2.9321,2.9321,0,0,1,.7331-3.7982,2.3364,2.3364,0,0,1,3.426.8154,10.66,10.66,0,0,0,8.8224,4.7137c4.5877,0,7.5121-1.9359,7.5121-5.0262,0-3.1-3.3749-4.397-8.4918-6.75-5.7614-2.65-11.3581-4.516-11.4634-11.4917-.07-4.6592,4.7071-9.688,11.9921-10.1252,4.4724-.2763,8.8672,1.4818,11.201,3.9779a2.9945,2.9945,0,0,1,.1609,3.8814,2.3165,2.3165,0,0,1-3.5.18c-5.6762-5.3149-14.8408-2.8057-14.8415,2.0862,0,2.9726,4.1879,4.62,8.34,6.4839,4.0509,1.8189,11.6912,4.6836,11.6912,11.758C571.5444,106.7914,567.561,112.2768,558.9077,112.2768Z"
                        transform="translate(-49.1271 -49.1271)" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <p class="site-w3w-data text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_content }">filled.count.soap</p>
            <p></p>
          </div>
        </div>
        @if (themeSettingsForm.value.details_site_map) {
          <div class="site-location-right">
            <div
              class="site-image h-[320px] w-[320px]"
              style="background-image: url(https://images.unsplash.com/photo-1665395806066-d47f41e6aa6d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2487&q=80)"></div>
          </div>
        }
      </div>
    </div>
  </div>
}
