<div class="grid grid-cols-1 gap-y-4">
  <!-- List -->
  <ul class="list-ul" id="actionsList" cdkDropList (cdkDropListDropped)="reorderActions($event)">
    @for (action of actions; track $index) {
      <li class="list-none first:pt-0 last:pb-0" cdkDrag [cdkDragDisabled]="!editMode">
        <div class="list-row">
          <!-- List Editing Tools -->
          @if (editMode) {
            <div class="flex flex-row items-center">
              <div class="mr-3 flex items-center justify-end hover:text-brand_Primary-hover dark:text-slate-300 dark:hover:text-sky-500">
                <div class="flex items-center">
                  @if (actionsBulkEditArray) {
                    <input type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" (change)="createBatchEdit(action)" [checked]="actionsBulkEditArray.includes(action)" />
                  }
                </div>
              </div>
              <div class="flex items-center">
                @if (actionsBulkEditArray && actionsBulkEditArray.length <= 0) {
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" (click)="deleteSingleAction(action.id)" class="cip-transition h-5 w-5 cursor-pointer text-alert_Error hover:opacity-75">
                    <path
                      fill-rule="evenodd"
                      d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                      clip-rule="evenodd" />
                  </svg>
                }
              </div>
            </div>
          }

          <div class="flex w-full cursor-pointer flex-row">
            <div (click)="routeToAction(action.id)" class="list-a group cursor-pointer">
              <!-- Content -->
              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                <!-- Left -->
                <div class="flex min-w-0 flex-auto flex-col space-y-1">
                  <p class="list-title">{{ action.title }}</p>
                  <div class="flex flex-row items-center">
                    @if (action.priority || action.priority === 0) {
                      <div class="mr-3 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 rounded-full text-white" [ngClass]="priorityDict[action.priority].colour">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                        <div class="ml-2 text-sm capitalize">{{ priorityDict[action.priority].priority }}</div>
                      </div>
                    }
                    <p class="list-meta">{{ action.assignee_name }}</p>
                  </div>
                  @if (action.due_date) {
                    <p class="list-meta">Due by {{ action.due_date.toDate() | date: "MMM dd yyyy" }}</p>
                  }
                </div>

                <!-- Right -->
                @if (action.complete) {
                  <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                    <div class="flex flex-col items-center text-brand_Primary">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                          clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                }
              </div>

              <!-- Right Icon - Edit -->
              @if (editMode) {
                <div class="flex">
                  <button type="button" class="mr-2.5 block h-full py-2.5 pl-2.5 text-text_Subtitle dark:text-gray-400 sm:mr-0" aria-expanded="false" aria-haspopup="true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                    </svg>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </li>
    }
  </ul>
</div>
