<div class="relative w-full rounded-md shadow-sm sm:w-[300px]">
  <input
    type="text"
    name="search"
    id="listSearchInput"
    class="focus:ring-9 block w-full rounded-md border-0 py-1.5 pl-4 pr-10 text-text_Title ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-brand_Primary sm:text-sm sm:leading-6"
    placeholder="{{ placeholder }}"
    [(ngModel)]="searchTerm"
    (input)="onSearch()"
  />
  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 text-gray-400">
      <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
    </svg>
  </div>
</div>
