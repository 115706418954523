<form [formGroup]="workspaceForm" class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
  <div class="sm:col-span-3">
    <div class="form-input-wrapper">
      <label for="workspace-name" class="form-input-label"
        >Name*
        @if (!workspaceForm.pristine && workspaceForm.controls['name'].errors) {
          <span class="required-field"> - Name is required </span>
        }
      </label>
      <input type="text" name="workspace-name" id="workspace-name" class="form-input" placeholder="Name" formControlName="name" />
    </div>
  </div>
  <div class="sm:col-span-3">
    <div class="form-input-wrapper">
      <label for="workspace-website" class="form-input-label">Website</label>
      <input type="text" name="workspace-website" id="workspace-website" class="form-input" placeholder="Website" formControlName="website" />
    </div>
  </div>
</form>
