import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker";
@Component({
  selector: "action-edit-details",
  standalone: true,
  imports: [ReactiveFormsModule, OwlDateTimeModule, OwlNativeDateTimeModule],
  templateUrl: "./action-edit-details.component.html",
  styleUrl: "./action-edit-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEditDetailsComponent {
  @Input({ required: true }) actionForm!: FormGroup;

  /**
   * Clear Due Date
   */
  public clearActionDate(): void {
    this.actionForm.patchValue({ due_date: null });
    this.actionForm.markAsDirty();
  }
}
