<form autocomplete="off" [formGroup]="testForm">
  <div class="relative flex h-[47px] flex-row justify-evenly overflow-hidden rounded-md bg-[#F0F4F7]">
    @for (result of answerType; track $index; let last = $last) {
      <label class="z-10 flex w-1/2 cursor-pointer items-center justify-center border-[#C8C8C8] transition-all duration-300 hover:opacity-40" [ngClass]="last ? 'border-none' : 'border-r'">
        <input type="radio" class="sr-only" [value]="result.value" formControlName="test_answer" id="{{ result.title }}" />
        <span class="text-base font-medium" [ngClass]="result.value === testForm.value.test_answer ? 'text-white' : 'text-text_Title'"> {{ result.title }} </span>
      </label>
    }
    <div class="absolute left-0 top-0 z-[5] flex h-full w-full flex-row">
      <div
        class="w-1/2 transition-all duration-300"
        [ngClass]="{
          'answerOne bg-score_0': testForm.value.test_answer === 'low',
          'answerTwo bg-score_100': testForm.value.test_answer === 'high',
        }"></div>
    </div>
  </div>
</form>
