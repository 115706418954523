<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 text-blue-500 sm:mx-0 sm:h-10 sm:w-10">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">{{ overlayTitle }}</h3>
              <div class="mt-2">
                <p class="text-sm text-text_Subtitle">{{ overlayDescription }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between bg-gray-50 p-6">
          <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
          <div class="sm:flex sm:flex-row-reverse">
            <button type="button" (click)="enableState(true)" class="btn btn-primary ml-3">Enable</button>
            <button type="button" (click)="enableState(false)" class="btn btn-primary">Disable</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
