import { AuditLogType } from "src/app/core/util/models";
import { objectEntries } from "src/app/core/util/object";

export interface AuditFilter {
  title: string;
  type: AuditLogType;
}

const FILTER_TITLES_BY_TYPE: Record<AuditLogType, string> = {
  "assignee-added": "Assignee Added",
  "assignee-deleted": "Assignee Deleted",
  "client-added": "Client Added",
  "client-deleted": "Client Deleted",
  "folder-added": "Folder Added",
  "folder-deleted": "Folder Deleted",
  "inspection-added": "Inspection Added",
  "inspection-deleted": "Inspection Deleted",
  "member-activated": "Member Joined",
  "member-deactivated": "Member Deactivated",
  "member-name-changed": "Member Name Changed",
  "member-removed": "Member Removed",
  "member-role-changed": "Member Role Changed",
  "role-added": "Role Added",
  "role-deleted": "Role Deleted",
  "role-permissions-changed": "Role Permissions Changed",
  "role-title-changed": "Role Title Changed",
  "site-added": "Site Added",
  "site-deleted": "Site Deleted",
  "template-added": "Template Added",
  "template-deleted": "Template Deleted",
  "workspace-current-owner-id-changed": "Workspace Owner Changed",
  "workspace-image-url-changed": "Workspace Image Changed",
  "workspace-name-changed": "Workspace Name Changed",
  "workspace-website-changed": "Workspace Website Changed",
};

export const AUDIT_FILTERS: AuditFilter[] = objectEntries(FILTER_TITLES_BY_TYPE).map(([type, title]) => ({ type, title }));
