import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InfoViewStringsService } from 'src/app/core/services/strings/info-view-strings.service';
import { InfoViewFilter, InfoViewType } from 'src/app/models/strings/strings.model';
import { FormStateComponent } from 'src/app/shared/form-state/form-state.component';
import { InfoViewComponent } from 'src/app/shared/info-view/info-view.component';

@Component({
  selector: 'inspection-supplementary-details',
  standalone: true,
  imports: [ReactiveFormsModule, InfoViewComponent, FormStateComponent, NgClass],
  templateUrl: './inspection-supplementary-details.component.html',
  styleUrl: './inspection-supplementary-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionSupplementaryDetailsComponent {
  @Input({ required: true }) supplementaryForm!: FormGroup;
  @Output() saveSupplementaryOutput = new EventEmitter<void>();

  // Services
  private infoViewStringsService = inject(InfoViewStringsService);

  // Properties
  public infoViewSupplementaryEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.SupplementaryEditor);
  public infoViewInternalNotes: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.InternalNotes);

  /**
   * Save Supplementary Form
   */
  saveSupplementaryForm() {
    this.saveSupplementaryOutput.emit();
  }
}
