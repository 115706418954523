@if (template$ | async; as template) {
  <!-- Header -->
  <manage-template-edit-header [workspaceId]="workspaceId" [templateId]="template.id" />

  <div class="grid grid-cols-1 gap-y-8">
    <!-- Form State Top -->
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="templateForm.pristine"></form-state>
      <button type="button" (click)="saveTemplate()" class="btn" [disabled]="templateForm.pristine || templateForm.invalid" [ngClass]="templateForm.pristine || templateForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
    </div>

    <!-- Content-->
    <div class="content-card-wrapper">
      <div class="content-card">
        <div class="content-grid gap-y-6">
          <manage-template-edit-details [templateForm]="templateForm" />
        </div>
      </div>
    </div>

    <!-- Form State Bottom-->
    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      <button type="button" aria-label="Delete Template" class="btn btn-destructive mr-3" (click)="canUserDeleteTemplate()">Delete Template</button>
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="templateForm.pristine"></form-state>
        <button type="button" (click)="saveTemplate()" class="btn" [disabled]="templateForm.pristine || templateForm.invalid" [ngClass]="templateForm.pristine || templateForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteTemplate) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteTemplateOverlay()" (deleteButtonClicked)="deleteTemplate()" />
}
