<header>
  <!--  Breadcrumbs-->
  <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" />

  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Inspections</h2>
      </div>
      <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
        @if (inspections.length > 0) {
          <!-- Search -->
          <search-bar [placeholder]="'Search Inspections'" (searchTermChange)="onSearch($event)" />

          <!-- Sort -->

          <div class="ml-3 flex flex-shrink-0">
            <div class="relative">
              <button
                type="button"
                class="relative h-[36px] w-[200px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand_Primary sm:text-sm sm:leading-6"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                (click)="toggleSortDropdown()"
                appClickedOutsideDropdown
                (clickedOutside)="closeSortDropdown()"
              >
                <span class="block truncate">{{ currentlySelectedInspectionSortChoice }} </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                      fill-rule="evenodd"
                      d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>

              @if (sortDropdown) {
                <ul
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  tabindex="-1"
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-option-3"
                >
                  @for (filter of filterChoices; track $index) {
                    <li class="relative flex cursor-default select-none flex-row items-center justify-between px-2 py-2 text-gray-900" id="listbox-option-0" role="option" (click)="updateInspectionsArrayWithSortSelection(filter)">
                      <div class="flex flex-row items-center">
                        <span class="flex items-center pr-1 text-brand_Primary">
                          <div class="flex h-5 w-5 items-center">
                            @if (currentlySelectedInspectionSortChoice === filter) {
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                              </svg>
                            }
                          </div>
                        </span>
                        <span class="block truncate font-normal">{{ filter }}</span>
                      </div>
                      @if (currentlySelectedInspectionSortChoice === filter) {
                        @if (isSortOrderAscending === true) {
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5h-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                          </svg>
                        } @else {
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5h-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                          </svg>
                        }
                      }
                    </li>
                  }
                </ul>
              }
            </div>
          </div>
        }

        <!-- New -->
        <button type="button" class="btn btn-primary ml-0 lg:ml-3" (click)="canUserAddInspection()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Inspection
        </button>
      </div>
    </div>
  </div>
</header>
