import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, ThemeStyleType, User } from "cip";
import { combineLatest, filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { ThemeStyleEnhanced } from "src/app/models/theme/theme-style.model";
import { ThemeEnhanced } from "src/app/models/theme/theme.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ThemeDetailService } from "../../services/theme-detail.service";
import { ThemeStylesAndSettingsService } from "../../services/theme-styles-and-settings.service";
import { ManageThemeEditHeaderComponent } from "../manage-theme-edit-header/manage-theme-edit-header.component";
import { ManageThemeEditPreviewWrapperComponent } from "../manage-theme-edit-preview/manage-theme-edit-preview-wrapper/manage-theme-edit-preview-wrapper.component";
import { ManageThemeEditSettingsWrapperComponent } from "../manage-theme-edit-settings/manage-theme-edit-settings-wrapper/manage-theme-edit-settings-wrapper.component";

@Component({
  selector: "manage-theme-edit-wrapper",
  standalone: true,
  imports: [AsyncPipe, JsonPipe, LoadingSpinnerComponent, FormStateComponent, NgClass, ManageThemeEditHeaderComponent, ManageThemeEditPreviewWrapperComponent, ManageThemeEditSettingsWrapperComponent, DeleteOverlayComponent],
  templateUrl: "./manage-theme-edit-wrapper.component.html",
  styleUrl: "./manage-theme-edit-wrapper.component.scss",
})
export class ManageThemeEditWrapperComponent implements OnInit, OnDestroy {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set themeId(value: string) {
    this._themeId = value;
  }
  get themeId(): string {
    return this._themeId;
  }

  // Services
  private authService = inject(AuthService);
  private formFormattingService = inject(FormFormattingService);
  private fb = inject(FormBuilder);
  private themeDetailService = inject(ThemeDetailService);
  private themeStylesAndSettingsService = inject(ThemeStylesAndSettingsService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private router = inject(Router);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);

  // Properties
  private user: User;
  private _workspaceId!: string;
  private _themeId!: string;
  public theme$!: Observable<ThemeEnhanced>;
  public styles$!: Observable<ThemeStyleEnhanced[]>;
  public themeSettingsForm: FormGroup;
  public themeStylesByType: { [key in ThemeStyleType]?: ThemeStyleEnhanced[] } = {};
  public combinedData$!: Observable<{ styles: ThemeStyleEnhanced[]; theme: ThemeEnhanced }>;
  public infoViewThemeEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.ThemeEditor);
  public overlay_deleteTheme: boolean = false;
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";

  constructor() {
    // User
    this.user = this.authService.currentUser;

    this.themeSettingsForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      csv_images: true,
      footers_page_numbers: true,
      dates_order: 2,
      dates_style: 0,
      details_workspace: true,
      details_user: true,
      details_overall_score: true,
      details_action: true,
      details_client: true,
      details_site: true,
      details_site_photo: true,
      details_site_map: true,
      details_introduction: true,
      details_summary: true,
      details_signatures: true,
      charts_included: true,
      charts_score: true,
      charts_assignee: true,
      charts_action: true,
      photos_included: true,
      photos_timestamps: true,
      photos_size: 1,
      photos_squared: true,
      photos_quality: 100,
      styles_cover: "Cover1",
      styles_site: "Site1",
      styles_category: "Category1",
      styles_chart: "Chart1",
      styles_item: "Item1",
      styles_photo: "Photo1",
      styles_action: "Action1",
      styles_supplementary: "Supplementary1",
      styles_signature: "Signature1",
      fonts_size: 1,
      colours_brand: "#1E4999",
      colours_subtitle: "#27323C",
      colours_content: "#61788D",

      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.initializeCombinedData();
  }

  ngOnDestroy(): void {
    this.clearAllThemeStyles();
  }

  /**
   * Initialize Combined Data
   */
  private initializeCombinedData(): void {
    this.theme$ = this.themeDetailService.getThemeDoc$(this.workspaceId, this.themeId).pipe(
      filter((data) => !!data),
      tap((theme) => {
        if (this.themeSettingsForm.pristine && this.themeSettingsForm.untouched) {
          this.themeSettingsForm.patchValue(theme);
        }
      })
    );
    this.styles$ = this.themeStylesAndSettingsService.getThemeStyles$().pipe(
      filter((data) => !!data),
      tap((styles) => {
        if (this.themeSettingsForm.pristine && this.themeSettingsForm.untouched) {
          styles.forEach((style) => {
            this.applyStylesToPage(style);
          });
        }
      })
    );
    this.combinedData$ = combineLatest({
      styles: this.styles$,
      theme: this.theme$,
    });
  }

  /**
   * Apple Styles To Page
   * @param style
   */
  private applyStylesToPage(style: ThemeStyleEnhanced): void {
    const { styles_cover, styles_supplementary, styles_site, styles_category, styles_item, styles_photo, styles_action, styles_signature, styles_chart } = this.themeSettingsForm.value;
    const id = style.id;
    const type = style.type;

    if (!this.themeStylesByType[type]) {
      this.themeStylesByType[type] = [];
    }

    this.themeStylesByType[type]?.push(style);

    // Check if any of the properties' IDs match the form values
    if (
      styles_cover === id ||
      styles_supplementary === id ||
      styles_site === id ||
      styles_category === id ||
      styles_chart === id ||
      styles_item === id ||
      styles_photo === id ||
      styles_action === id ||
      styles_signature === id
      // Add more conditions here for other properties as needed
    ) {
      switch (type) {
        case "cover":
          this.themeStylesAndSettingsService.applyCoverStyles(style.css);
          break;
        case "supplementary":
          this.themeStylesAndSettingsService.applySupplementaryStyles(style.css);
          break;
        case "site":
          this.themeStylesAndSettingsService.applySiteStyles(style.css);
          break;
        case "category":
          this.themeStylesAndSettingsService.applyCategoryStyles(style.css);
          break;
        case "chart":
          this.themeStylesAndSettingsService.applyChartStyles(style.css);
          break;
        case "item":
          this.themeStylesAndSettingsService.applyItemStyles(style.css);
          break;
        case "photo":
          this.themeStylesAndSettingsService.applyPhotoStyles(style.css);
          break;
        case "action":
          this.themeStylesAndSettingsService.applyActionStyles(style.css);
          break;
        case "signature":
          this.themeStylesAndSettingsService.applySignatureStyles(style.css);
          break;
        default:
          break;
      }
    }
  }
  /**
   * Clear All Theme Styles
   */
  private clearAllThemeStyles(): void {
    const styleTags = ["styles_coverCSS", "styles_siteCSS", "styles_introductionCSS", "styles_categoryCSS", "styles_itemCSS", "styles_photoCSS", "styles_actionCSS", "styles_summaryCSS", "styles_signatureCSS", "styles_chartCSS"];
    styleTags.forEach((styleTag) => {
      this.themeStylesAndSettingsService.clearStyles(styleTag);
    });
  }

  /**
   * Save Theme Doc
   */
  async saveThemeDoc(): Promise<void> {
    try {
      await this.themeDetailService.saveThemeDoc(this.workspaceId, this.themeId, this.themeSettingsForm.value, this.user);
      this.themeSettingsForm.markAsPristine();
      this.router.navigate(["/workspace", this.workspaceId, "manage", "themes"]);
    } catch (error) {
      alert(error);
    }
  }

  public async canUserDeleteTheme(): Promise<void> {
    const featureAction: keyof RolePermissions = "theme_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      const deleteTheme = this.stringsService.alertFilter(AlertType.DeleteTheme);
      if (deleteTheme) {
        this.toggleDeleteOverlay();
        this.deleteOverlayTitle = deleteTheme.title;
        this.deleteOverlayDescription = deleteTheme.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteTheme);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Theme Doc
   */
  async deleteThemeDoc(): Promise<void> {
    try {
      await this.themeDetailService.deleteThemeDoc(this.workspaceId, this.themeId, this.user);
      this.themeSettingsForm.markAsPristine();
      this.router.navigate(["/workspace", this.workspaceId, "manage", "themes"]);
      this.toggleDeleteOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Limitation Manager Check
   */
  public async limitationManagerCheck(featureAction: keyof RolePermissions): Promise<boolean> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    return limitationResult;
  }

  /**
   * Toggle Delete Overlay
   */
  public toggleDeleteOverlay(): void {
    this.overlay_deleteTheme = !this.overlay_deleteTheme;
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  isFormDirty(): boolean {
    return this.themeSettingsForm.dirty ? false : true;
  }
}
