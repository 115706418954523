import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartsTestResults } from 'src/app/models/charts/charts-tests.model';
import { ChartsOverallComponent } from 'src/app/shared/charts/charts-overall/charts-overall.component';

@Component({
  selector: 'inspection-overview-stats-scores',
  standalone: true,
  imports: [ChartsOverallComponent],
  templateUrl: './inspection-overview-stats-scores.component.html',
  styleUrls: ['./inspection-overview-stats-scores.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionOverviewStatsScoresComponent {
  @Input({ required: true }) chartsResults!: ChartsTestResults[];
  @Input({ required: true }) totalTests!: number;
}
