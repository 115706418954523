import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { combineLatest, filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { RoleCurrent, RoleEnhanced } from "src/app/models/role/role.model";
import { AlertType, InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { InvitesListService } from "../../../manage-members/services/invites-list.service";
import { MembersListService } from "../../../manage-members/services/members-list.service";
import { RoleDetailService } from "../../services/role-detail.service";
import { ManageRoleEditFormComponent } from "../manage-role-edit-form/manage-role-edit-form.component";
import { ManageRoleEditHeaderComponent } from "../manage-role-edit-header/manage-role-edit-header.component";

@Component({
  selector: "manage-role-edit-wrapper",
  standalone: true,
  imports: [LoadingSpinnerComponent, ManageRoleEditHeaderComponent, ManageRoleEditFormComponent, AsyncPipe, DeleteOverlayComponent],
  templateUrl: "./manage-role-edit-wrapper.component.html",
  styleUrl: "./manage-role-edit-wrapper.component.scss",
})
export class ManageRoleEditWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set roleId(value: string) {
    this._roleId = value;
  }
  get roleId(): string {
    return this._roleId;
  }

  // Serivces
  private fb = inject(FormBuilder);
  private membersListService = inject(MembersListService);
  private invitesListService = inject(InvitesListService);
  private roleDetailService = inject(RoleDetailService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);

  // Properties
  public roleForm: FormGroup;
  private _workspaceId!: string;
  private _roleId!: string;
  private members$!: Observable<MemberEnhanced[]>;
  private invites$!: Observable<InviteEnhanced[]>;
  private role$!: Observable<RoleEnhanced>;
  private user: User;
  public currentRole: RoleCurrent | null = null;
  public customRoles: RoleEnhanced[] = [];
  public combinedData$!: Observable<{ members: MemberEnhanced[]; invites: InviteEnhanced[]; role: RoleEnhanced }>;
  public infoViewPermissionsEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.PermissionsEditor);
  public infoViewRoleEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.CustomRoleEditor);
  public overlay_deleteRole = signal<boolean>(false);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";

  constructor() {
    this.user = this.authService.currentUser;
    this.roleForm = this.fb.group({
      id: [{ value: null, disabled: null }],
      title: ["", Validators.required],
      description: ["", Validators.required],
      type: null,
      permissions: this.fb.group({
        action_create_update: false,
        action_delete: false,
        assignee_create_update: false,
        assignee_delete: false,
        category_create_update: false,
        category_delete: false,
        client_create_update: false,
        client_delete: false,
        folder_create_update: false,
        folder_delete: false,
        inspection_create_update: false,
        inspection_delete: false,
        invite_create_update: false,
        invite_delete: false,
        item_create_update: false,
        item_delete: false,
        member_update: false,
        member_delete: false,
        photo_create_update: false,
        photo_delete: false,
        role_create_update: false,
        role_delete: false,
        signature_create_update: false,
        signature_delete: false,
        site_create_update: false,
        site_delete: false,
        template_create_update: false,
        template_delete: false,
        theme_create_update: false,
        theme_delete: false,
        workspace_update: false,
      }),
    });
  }

  ngOnInit(): void {
    this.initializeCombinedData();
  }

  /**
   * Initializes the combined data observable
   */
  private async initializeCombinedData(): Promise<void> {
    this.members$ = this.membersListService.getAssignedMembers$(this.workspaceId, this.roleId).pipe(filter((data) => !!data));
    this.invites$ = this.invitesListService.getWorkspaceInvitesByRole$(this.workspaceId, this.roleId).pipe(filter((data) => !!data));
    this.role$ = this.roleDetailService.getRoleDoc$(this.workspaceId, this.roleId).pipe(
      filter((data) => !!data),
      tap((role) => {
        this.roleForm.patchValue(role);
      })
    );
    this.combinedData$ = combineLatest({
      members: this.members$,
      invites: this.invites$,
      role: this.role$,
    });
  }

  /**
   * Save Role
   */
  async saveRole(): Promise<void> {
    const route = ["/workspace", this.workspaceId, "manage", "roles"];
    try {
      await this.roleDetailService.saveRoleDoc(this.workspaceId, this.roleForm.getRawValue(), this.user);
      this.roleForm.markAsPristine();
      this.router.navigate(route);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Can User Delete Role
   */
  public async canUserDeleteRole(): Promise<void> {
    const featureAction: keyof RolePermissions = "role_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleDeleteRoleOverlay(true);
      const deleteRole = this.stringsService.alertFilter(AlertType.DeleteRole);
      if (deleteRole) {
        this.deleteOverlayTitle = deleteRole.title;
        this.deleteOverlayDescription = deleteRole.description;
      } else {
        console.error("Unknown string type:", AlertType.RevokeInvite);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Role
   */
  public async deleteRole(): Promise<void> {
    const route = ["/workspace", this.workspaceId, "manage", "roles"];
    try {
      await this.roleDetailService.deleteRoleDoc(this.workspaceId, this.roleId, this.user);
      this.roleForm.markAsPristine();
      this.router.navigate(route);
    } catch (error) {
      alert(error);
    } finally {
      this.toggleDeleteRoleOverlay(false);
    }
  }

  /**
   * Toggle Delete Role Overlay
   * @param value
   */
  public toggleDeleteRoleOverlay(value: boolean): void {
    this.overlay_deleteRole.set(value);
  }
}
