@if (signature$ | async; as signature) {
  <inspection-signoff-edit-header [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [signatureId]="signatureId" />

  <div class="grid grid-cols-1 gap-y-8">
    <!-- Form State Top-->
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="signatureForm.pristine"></form-state>
      <button
        type="button"
        (click)="checkIfPhotosAreUploadingOrCreateSignature()"
        class="btn"
        [disabled]="signatureForm.pristine || signatureForm.controls['name'].errors"
        [ngClass]="signatureForm.pristine || signatureForm.controls['name'].errors ? 'btn-disabled' : 'btn-primary'">
        Save
      </button>
    </div>

    <!-- Details -->
    <div class="content-card-wrapper">
      <div class="px-6 py-5">
        <div class="content-grid gap-x-4 gap-y-6">
          <info-view [type]="infoViewSignatureEditor.type" [message]="infoViewSignatureEditor.message" />
          <inspection-signoff-edit-details [signatureForm]="signatureForm" />
          <div>
            <div class="form-input-label mb-2 px-3">Photo</div>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-6">
                @if (signatureForm.value.image_thumbnail_url) {
                  <div class="relative h-auto max-w-[300px] rounded-md border border-gray-100">
                    <img [src]="signatureForm.value.image_thumbnail_url" class="w-full" />
                    <div class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500" (click)="toggleDeleteSignaturePhotoOverlay()">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                      </svg>
                    </div>
                  </div>
                }
                <!-- Image Dropzone -->
                @if (!signatureForm.value.image_thumbnail_url && uploadFileArray.length < 1) {
                  <photo-dropzone [uploadSourceComponent]="'signature'" (detectPhotosOutput)="detectPhotos($event)" [uploadSourceComponent]="'signature'" />
                }
                @if (photoPreview && uploadFileArray.length > 0) {
                  <photo-preview [photosToPreview]="uploadFileArray" [uploadLimit]="1" [uploadSourceComponent]="'signature'" (removePhotoFromArrayOutput)="removePhotoFromArray($event)" />
                }
                @if (overlay_uploadPhotos) {
                  @for (photo of uploadFileArray; track photo.id) {
                    <photo-upload-single
                      [file]="photo.photo"
                      [path]="'workspaces/' + workspaceId + '/inspections/' + inspectionId + '/signatures/' + signatureId"
                      [fileType]="'jpeg'"
                      [largeMaxWidth]="900"
                      [largeMaxHeight]="300"
                      [thumbnailMaxWidth]="300"
                      [thumbnailMaxHeight]="100"
                      [uploadSourceComponent]="'signature'"
                      (photoDataOutput)="patchFormWithPhotosAndSaveDoc($event)" />
                  }
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="signatureForm.pristine"></form-state>
      <button
        type="button"
        (click)="checkIfPhotosAreUploadingOrCreateSignature()"
        class="btn"
        [disabled]="signatureForm.pristine || signatureForm.controls['name'].errors"
        [ngClass]="signatureForm.pristine || signatureForm.controls['name'].errors ? 'btn-disabled' : 'btn-primary'">
        Save
      </button>
    </div>
  </div>
} @else {
  <loading-spinner />
}
@if (overlay_deleteSignaturePhoto) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteSignaturePhotoOverlay()" (deleteButtonClicked)="removeSignatureImagePropertiesFromForm()" />
}
