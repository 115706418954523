import { Component, inject } from '@angular/core';
import { ActionCodeSettings, Auth, sendSignInLinkToEmail } from '@angular/fire/auth'; // Import from @angular/fire/auth
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'login-signup',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule],
  templateUrl: './login-signup.component.html',
  styleUrl: './login-signup.component.scss',
})
export class LoginSignupComponent {
  // Services
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private auth = inject(Auth); // Inject the Auth service from @angular/fire/auth

  // Properties
  public signUpForm: FormGroup;
  public isFormInvalid = false;
  public hasAccount = false;
  public hasEmailBeenSent = false;

  constructor() {
    this.signUpForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
    });
  }

  /**
   * Check form validations
   */
  async checkFormValidations() {
    if (this.signUpForm.invalid) {
      this.isFormInvalid = true;
    } else {
      await this.sendEmailLink();
    }
  }

  /**
   * Send Email Link
   */
  async sendEmailLink() {
    const actionCodeSettings: ActionCodeSettings = {
      url: environment.loginUrl,
      handleCodeInApp: true,
    };
    try {
      await sendSignInLinkToEmail(this.auth, this.signUpForm.value.email, actionCodeSettings);
      this.hasEmailBeenSent = true;
      this.authService.setEmailForSignInToLocalStorage(this.signUpForm.value.email);
      this.authService.setNewAccountToLocalStorage();
    } catch (error) {
      alert(error);
    }
  }
}
