import { Injectable } from "@angular/core";
import { ActionEnhanced } from "src/app/models/action/action.model";
import { ChartsActionResults } from "src/app/models/charts/charts-action.model";
import { ChartsAssigneeAction, ChartsAssigneeResults } from "src/app/models/charts/charts-assignees.model";
import { ChartsTestCalculations, ChartsTestResults } from "src/app/models/charts/charts-tests.model";
import { ItemEnhanced } from "src/app/models/item/item.model";

export interface Calculations {
  totalActions: number;
  noDate: number;
  overdue: number;
  withinOneWeek: number;
  oneWeekToTwoWeeks: number;
  twoWeeksToOneMonth: number;
  oneMonthToSixMonths: number;
  sixMonthsToOneYear: number;
  overOneYear: number;
  [key: string]: number;
}

export interface ChartsFilterResults {
  testResults: ChartsTestResults[];
  overallScore: number;
}

@Injectable({
  providedIn: "root",
})
export class ChartsService {
  public totalActions = 0;
  public totalTests = 0;
  public totalAssignees = 0;
  private chartsResults: ChartsTestResults[] = [];

  // Grouping each of the possible results into arrays
  IS_ZERO = ["fail", "no", "low", "red", "poor", "progress-0"];
  IS_FIFTY = ["amber", "fair", "progress-50"];
  IS_HUNDRED = ["pass", "yes", "high", "green", "good", "progress-100"];

  // Contains all items in the results arrays (IS_ZERO / IS_FIFTY etc)
  // It is used to assign default values to each array items
  public testResultTranslator: { [key: string]: number } = {};

  // The user with the most actions

  public assigneeHighestActionCount = 0;

  /**
   * Filter Results
   * @param tests
   * We create a const called `calcs` that is strong typed to the Calculations interface and...
   * ...set all values to zero (this ensures that when updates come through, calculations are updated)
   * We loop through each item (test) result...
   * ...For each value in the cals object, we check against the `translator` object to see if the test_answer matches...
   * ...If it does match, we +1, if not +0
   * We then update the `totalTests` value to that of all the others combined
   * At the end we return an array where we call `formatTestResult` with each value, passing in:...
   * ...the cals object, the key for each value, the title used in the HTML and the HTML styling bar class
   */

  filterChartsTestResults(tests: ItemEnhanced[]): ChartsFilterResults {
    this.IS_ZERO.forEach((k) => (this.testResultTranslator[k] = 0.0));
    this.IS_FIFTY.forEach((k) => (this.testResultTranslator[k] = 50.0));
    this.IS_HUNDRED.forEach((k) => (this.testResultTranslator[k] = 100.0));

    const calcs: ChartsTestCalculations = {
      totalTests: 0,
      zeroTotal: 0,
      fiftyTotal: 0,
      onehundredTotal: 0,
      zeroPercent: 0,
    };

    tests.forEach((test) => {
      const testAnswer = test.test_answer;
      if (testAnswer !== undefined && this.testResultTranslator[testAnswer] !== undefined) {
        calcs.zeroTotal += this.testResultTranslator[testAnswer] === 0.0 ? 1 : 0;
        calcs.fiftyTotal += this.testResultTranslator[testAnswer] === 50.0 ? 1 : 0;
        calcs.onehundredTotal += this.testResultTranslator[testAnswer] === 100.0 ? 1 : 0;
      }
    });

    calcs.totalTests = calcs.zeroTotal + calcs.fiftyTotal + calcs.onehundredTotal;
    this.totalTests = calcs.totalTests;

    const results = [this.formatTestResult(calcs, "onehundredTotal", "Scored 100%"), this.formatTestResult(calcs, "fiftyTotal", "Scored 50%"), this.formatTestResult(calcs, "zeroTotal", "Scored 0%")];

    this.chartsResults = results;

    return {
      testResults: results,
      overallScore: this.calculateOverallScore(),
    };
  }

  private calculateOverallScore(): number {
    const possibleScore = this.totalTests * 100;

    if (possibleScore === 0) return 0;

    const fiftyScore = this.chartsResults[1].totalOfThisScore * 50;
    const hundredScore = this.chartsResults[0].totalOfThisScore * 100;

    let score = ((fiftyScore + hundredScore) / possibleScore) * 100;

    if (score <= 0) return 0;
    if (score >= 100) return 100;

    return score;
  }

  /**
   * Filter Action By Assignee
   * @param actions: AssigneeAction[]
   * @returns Results[]
   *
   * Filter the actions by assignee
   * Count the number of actions for each assignee
   * Sort the results in descending order based on the 'total' property
   */
  filterActionByAssignee(actions: ChartsAssigneeAction[]): ChartsAssigneeResults[] {
    const results: ChartsAssigneeResults[] = [];

    actions.forEach((action: ChartsAssigneeAction) => {
      const assigneeId = action.assignee_id ?? "Unassigned";

      let assignee = results.find((a) => a.assignee_id === assigneeId);

      if (!assignee) {
        assignee = {
          assignee_id: assigneeId,
          assignee_name: action.assignee_name ?? "Unassigned",
          no_priority: 0,
          trivial: 0,
          low: 0,
          medium: 0,
          high: 0,
          critical: 0,
          total: 0,
        };
        results.push(assignee);
        this.totalAssignees++;
      }
      assignee.total++;

      switch (action.priority) {
        case 0:
          assignee.trivial++;
          break;
        case 25:
          assignee.low++;
          break;
        case 50:
          assignee.medium++;
          break;
        case 75:
          assignee.high++;
          break;
        case 100:
          assignee.critical++;
          break;
        case null:
        case undefined:
          assignee.no_priority++;
          break;
        default:
          break;
      }

      if (assignee.total > this.assigneeHighestActionCount) {
        this.assigneeHighestActionCount = assignee.total;
      }
    });

    // Sort the results with 'Unassigned' at the top, and the rest by 'total' in descending order
    results.sort((a, b) => {
      if (a.assignee_id === "Unassigned") return -1; // 'Unassigned' should be at the top
      if (b.assignee_id === "Unassigned") return 1; // 'Unassigned' should be at the top
      return b.total - a.total; // For others, sort by total in descending order
    });

    return results;
  }

  /**
   * Filter Test
   * @param actions
   * We define the dates
   * We create an object for the date rangers we wish to sure, strong typed to the Calculations interface
   * We loop through each action checking the due_date
   * If the date falls within a specified range, we increase that range value in the object
   * We then call the `formatActionResult` function for each date range, passing in:
   * - the calcs object
   * - a "key", which is the name of the date range as specific in the calcs object
   * - a title for the date range as it would appear in the html
   * - a colour for the percentage bar
   */
  filterActionResults(actions: ActionEnhanced[]): ChartsActionResults[] {
    const today = new Date().valueOf();

    // days * hours * minutes * seconds * 1000 (milliseconds)
    const oneWeek = 604800000;
    const twoWeeks = 1209600000;
    const oneMonth = 2592000000;
    const sixMonths = 15552000000;
    const oneYear = 31556952000;

    const calcs: Calculations = {
      totalActions: 0,
      noDate: 0,
      overdue: 0,
      withinOneWeek: 0,
      oneWeekToTwoWeeks: 0,
      twoWeeksToOneMonth: 0,
      oneMonthToSixMonths: 0,
      sixMonthsToOneYear: 0,
      overOneYear: 0,
    };

    actions.forEach((action) => {
      let due_date: number;
      let dueDateMinusToday: number;
      if (action.due_date) {
        due_date = action.due_date.toDate().valueOf();
        dueDateMinusToday = due_date - today;

        if (dueDateMinusToday < 0) {
          calcs.overdue++;
        } else if (dueDateMinusToday <= oneWeek) {
          calcs.withinOneWeek++;
        } else if (dueDateMinusToday <= twoWeeks && dueDateMinusToday > oneWeek) {
          calcs.oneWeekToTwoWeeks++;
        } else if (dueDateMinusToday >= twoWeeks && dueDateMinusToday < oneMonth) {
          calcs.twoWeeksToOneMonth++;
        } else if (dueDateMinusToday >= oneMonth && dueDateMinusToday < sixMonths) {
          calcs.oneMonthToSixMonths++;
        } else if (dueDateMinusToday >= sixMonths && dueDateMinusToday < oneYear) {
          calcs.sixMonthsToOneYear++;
        } else if (dueDateMinusToday > oneYear) {
          calcs.overOneYear++;
        }
      } else {
        calcs.noDate++;
      }
    });

    calcs.totalActions = actions.length;
    this.totalActions = actions.length;

    return [
      this.formatActionResult(calcs, "noDate", "No Due Date", "#B8CAEC"),
      this.formatActionResult(calcs, "overdue", "Past Due Date", "#1450C1"), // previously overOneYear
      this.formatActionResult(calcs, "withinOneWeek", "< 1 week", "#2B61C7"), // previously sixMonthsToOneYear
      this.formatActionResult(calcs, "oneWeekToTwoWeeks", "1 week - 2 weeks", "#4272CD"), // previously oneMonthToSixMonths
      this.formatActionResult(calcs, "twoWeeksToOneMonth", "2 weeks - 1 month", "#5A84D3"), // previously twoWeeksToOneMonth
      this.formatActionResult(calcs, "oneMonthToSixMonths", "1 month - 6 months", "#7296D9"), // previously oneWeekToTwoWeeks
      this.formatActionResult(calcs, "sixMonthsToOneYear", "6 months - 1 year", "#89A7DF"), // previously withinOneWeek
      this.formatActionResult(calcs, "overOneYear", "> 1 Year", "#A1B9E6"), // previously overdue
    ];
  }

  /**
   * Format Result
   * @param calcs
   * @param key
   * @param dateTitle
   * @param percentColour
   * We want the actions observable to be formatted in the follow way
   * This function receives data for each date range and returns a format that would make sense to display
   * dateTitle = the title of the date range in the html (e.g "6 months - 1 year" / "2 weeks - 1 month")
   * totalOfThisDate = the total amount of actions that fall within this date range. We use the date range object name to reference its value in the calcs object
   * overallPercentage = overall percentage for this date range against the rest. We take the count (key) for this date range divide total amount of actions and times by 100
   * percentColour = actions due sooner will have a stronger red. The colour for each date ranger is passed through as a tailwind css class that is applied to the html
   */
  private formatActionResult(calcs: Calculations, key: string, dateTitle: string, percentColour: string): ChartsActionResults {
    const percentage = (calcs[key] / calcs.totalActions) * 100;
    const percentageFixed = percentage.toFixed(0) + "%";
    const totalForDate = calcs[key];
    const dataForActionRow = `${totalForDate} ${"(" + percentageFixed + ")"}`;
    const dataLength = dataForActionRow.length;

    return {
      dateTitle: dateTitle,
      totalOfThisDate: totalForDate,
      overallPercentage: percentage,
      percentColour: percentColour,
      dataLength: dataLength,
    };
  }

  /**
   * Format Result
   * @param calcs
   * @param key
   * @param title
   * @param colour
   * This will return an object that is strong typed to the `Results` interface
   * scoreTitle = The title of the score bar in the UI (0%, 50% etc)
   * totalOfThisScore = How many results there are per score type (zeroTotal = 2 / onehundredTotal = 4)
   * overallPercentage = For each score type, we divide it by the total amount of scores and times by 100 to obtain the percentage
   * percentColour =
   */
  private formatTestResult(calcs: ChartsTestCalculations, key: string, title: string): ChartsTestResults {
    return {
      scoreTitle: title,
      totalOfThisScore: calcs[key],
      overallPercentage: (calcs[key] / calcs.totalTests) * 100,
    };
  }
}
