import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-pdf-csv-toggle",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./report-settings-pdf-csv-toggle.component.html",
  styleUrl: "./report-settings-pdf-csv-toggle.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsPdfCsvToggleComponent {
  // Services
  public reportsService = inject(ReportsService);

  // Properties
  public currentview = ["PDF", "CSV"];
}
