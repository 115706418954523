<div class="flex items-center justify-end px-8">
  <button
    type="button"
    class="btn ml-3"
    (click)="saveItemAndAddAnother()"
    aria-label="Save and Add Another Test"
    [disabled]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors"
    [ngClass]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors ? 'btn-disabled' : 'btn-secondary'">
    {{ itemForm.value.mode === "test" ? "Save and Add Another Test" : "Save and Add Another Question" }}
  </button>

  <button
    type="button"
    class="btn ml-3"
    (click)="saveItemAndAddDifferentMode()"
    aria-label="Save and"
    [disabled]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors"
    [ngClass]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors ? 'btn-disabled' : 'btn-secondary'">
    {{ itemForm.value.mode === "test" ? "Save and Add a Question" : "Save and Add a Test" }}
  </button>

  <button
    type="button"
    (click)="saveItemAndReroute()"
    aria-label="Save Text"
    class="btn ml-3"
    [disabled]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors"
    [ngClass]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors ? 'btn-disabled' : 'btn-primary'">
    Save
  </button>
</div>

<form [formGroup]="itemForm" class="grid grid-cols-1 gap-y-8 py-8">
  <div class="content-card-wrapper">
    <div class="px-6 py-5">
      <div class="content-grid gap-y-6">
        <!-- Info Views -->

        <div class="col-span-6">
          @if (itemForm.value.mode === "test") {
            <info-view [type]="infoViewItemEditorTest.type" [message]="infoViewItemEditorTest.message" />
          }
          @if (itemForm.value.mode === "question") {
            <info-view [type]="infoViewItemEditorQuestion.type" [message]="infoViewItemEditorQuestion.message" />
          }
        </div>

        <!-- Title -->
        <div class="col-span-6">
          <div class="form-input-wrapper">
            <label for="item-title" class="form-input-label"
              >Title*
              @if (!itemForm.pristine && itemForm.controls["title"].errors) {
                <span class="required-field"> - Item title is required</span>
              }
            </label>
            <input type="text" name="item-title" id="item-title" class="form-input" placeholder="Title" formControlName="title" />
          </div>
        </div>
        <div class="col-span-6">
          <!-- Test Choices -->
          @if (itemForm.value.mode === "test") {
            <test-types [itemForm]="itemForm" />
          }

          <!-- Question Choices -->
          @if (itemForm.value.mode === "question") {
            <question-types [itemForm]="itemForm" />
          }
        </div>
      </div>
    </div>
  </div>
</form>

<div class="flex flex-row items-center justify-between px-6 sm:px-8">
  @if (itemId !== "new") {
    <button type="button" class="btn btn-destructive mr-3" (click)="deleteItem()">Delete Item</button>
  }
  <div class="flex flex-1 flex-row items-center justify-end">
    <form-state [pristine]="itemForm.pristine"></form-state>
    <button
      type="button"
      (click)="saveItemAndReroute()"
      class="btn"
      [disabled]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors"
      [ngClass]="itemForm.pristine || itemForm.controls['title'].errors || itemForm.controls['answer_type'].errors ? 'btn-disabled' : 'btn-primary'">
      Save
    </button>
  </div>
</div>
