@if (userProfile$ | async; as profile) {
  <header>
    <!-- Dummy breadcrumb element-->
    <div class="h-[28px] px-6 pb-2"></div>
    <!-- Header -->
    <div class="page-header-wrapper">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="page-header">Edit Profile</h2>
        </div>
      </div>
    </div>
  </header>

  <!-- Content -->
  <div class="grid grid-cols-1 gap-y-8">
    <!-- Form State Top -->
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="userProfileForm.pristine"></form-state>
      <button type="button" (click)="saveUserProfile()" class="btn" [disabled]="userProfileForm.pristine || !userProfileForm.valid" [ngClass]="userProfileForm.pristine || !userProfileForm.valid ? 'btn-disabled' : 'btn-primary'">Save</button>
    </div>

    <!-- Details -->
    <div class="content-card-wrapper">
      <div class="content-card">
        <form [formGroup]="userProfileForm" class="content-grid grid-cols-2 gap-x-4 gap-y-6">
          <div class="col-span-2">
            <info-view [type]="infoViewProfileEditor.type" [message]="infoViewProfileEditor.message" />
          </div>

          <div class="sm:col-span-1">
            <div class="form-input-wrapper">
              <label for="name_first" class="form-input-label"
                >Name*
                @if (!userProfileForm.pristine && userProfileForm.controls["name_first"].errors) {
                  <span class="required-field"> - First Name is required</span>
                }
              </label>
              <input type="text" name="name_first" id="name_first" class="form-input" placeholder="First Name" formControlName="name_first" />
            </div>
          </div>
          <div class="sm:col-span-1">
            <div class="form-input-wrapper">
              <label for="name_last" class="form-input-label"
                >Last Name*
                @if (!userProfileForm.pristine && userProfileForm.controls["name_last"].errors) {
                  <span class="required-field"> - Last Name is required</span>
                }
              </label>
              <input type="text" name="name_last" id="name_last" class="form-input" placeholder="Last Name" formControlName="name_last" />
            </div>
          </div>
          <div class="sm:col-span-1">
            <div class="form-input-wrapper">
              <label for="job_title" class="form-input-label">Job Title</label>
              <input type="text" name="job_title" id="job_title" class="form-input" placeholder="Job Title" formControlName="job_title" />
            </div>
          </div>
          <div class="sm:col-span-1">
            <div class="form-input-wrapper">
              <label for="company" class="form-input-label">Company</label>
              <input type="text" name="company" id="company" class="form-input" placeholder="Company" formControlName="company" />
            </div>
          </div>
          <div class="sm:col-span-1">
            <div class="form-input-wrapper">
              <label for="phone" class="form-input-label">Phone</label>
              <input type="text" name="phone" id="phone" class="form-input" placeholder="Phone" formControlName="phone" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Form State Bottom -->
    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="userProfileForm.pristine"></form-state>
        <button type="button" (click)="saveUserProfile()" class="btn" [disabled]="userProfileForm.pristine || !userProfileForm.valid" [ngClass]="userProfileForm.pristine || !userProfileForm.valid ? 'btn-disabled' : 'btn-primary'">Save</button>
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}
