import { Injectable } from "@angular/core";
import { collection, getCountFromServer, getFirestore, query, where } from "firebase/firestore";

@Injectable({
  providedIn: "root",
})
export class GetCountFromServerService {
  /**
   * Get Count From Server
   * A generic function for counts that don't have an additional query
   */
  async getCountFromServer(path: string): Promise<number> {
    const db = getFirestore();
    const coll = collection(db, path);
    const query_ = query(coll, where("is_deleted", "==", false));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
  }

  async getExportCount(path: string): Promise<number> {
    const db = getFirestore();
    const coll = collection(db, path);
    const snapshot = await getCountFromServer(coll);
    return snapshot.data().count;
  }

  /**
   * Get Template Items Count
   */
  async getTemplateItemsCount(path: string, categoryId: string): Promise<number> {
    const db = getFirestore();
    const coll = collection(db, path);
    const q = query(coll, where("category_id", "==", categoryId), where("is_deleted", "==", false));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  /**
   * Check Categories Count In Database
   */
  async getItemsCount(path: string, categoryId: string): Promise<number> {
    const db = getFirestore();
    const coll = collection(db, path);
    const query_ = query(coll, where("category_id", "==", categoryId), where("is_deleted", "==", false));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
  }

  /*
   * Get Test Images Count
   */
  async getTestImagesCount(path: string, itemId: string): Promise<number> {
    const db = getFirestore();
    const coll = collection(db, path);
    const query_ = query(coll, where("item_id", "==", itemId), where("is_deleted", "==", false));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
  }

  /**
   * Get Item Actions Count
   */
  async getItemActionsCount(path: string, itemId: string): Promise<number> {
    const db = getFirestore();
    const coll = collection(db, path);
    const query_ = query(coll, where("item_id", "==", itemId), where("is_deleted", "==", false));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
  }

  async getAuditCountBasedOnType(path: string, filterType: string): Promise<number> {
    const db = getFirestore();
    const coll = collection(db, path);
    const query_ = query(coll, where("info.type", "==", filterType));
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
  }
}
