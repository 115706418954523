import { inject, Injectable } from "@angular/core";
import { Theme, User } from "cip";
import { WithFieldValue } from "firebase/firestore";
import { catchError, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { ThemeEnhanced } from "src/app/models/theme/theme.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class ThemeDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Theme Doc
   * @param workspaceId
   * @param themeId
   */
  public getThemeDoc$(workspaceId: string, themeId: string): Observable<ThemeEnhanced> {
    if (themeId === "new") {
      return of({} as ThemeEnhanced);
    }
    const path = this.collectionsService.themesCol(workspaceId);
    const doc = `${path}/${themeId}`;
    return this.firestoreUtilsService.getDocumentData<ThemeEnhanced>(doc).pipe(
      map((data) => data || ({} as ThemeEnhanced)),
      catchError((error) => {
        return of({} as ThemeEnhanced);
      })
    );
  }

  /**
   * Save Theme Doc
   * @param workspaceId
   * @param themeId
   * @param themeSettingsForm
   * @param user
   */
  async saveThemeDoc(workspaceId: string, themeId: string, themeSettingsForm: ThemeEnhanced, user: User) {
    const path = this.collectionsService.themesCol(workspaceId);
    const themeIdToUse = themeId === "new" ? this.firestoreUtilsService.createFirestoreId() : themeId;
    const doc = `${path}/${themeIdToUse}`;
    const writeEventType = themeId === "new" ? "added" : "changed";
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);

    const {
      csv_images,
      footers_page_numbers,
      dates_order,
      dates_style,
      details_workspace,
      details_user,
      details_overall_score,
      details_action,
      details_client,
      details_site,
      details_site_photo,
      details_site_map,
      details_introduction,
      details_summary,
      details_signatures,
      charts_included,
      charts_score,
      charts_assignee,
      charts_action,
      photos_included,
      photos_timestamps,
      photos_size,
      photos_squared,
      photos_quality,
      styles_cover,
      styles_site,
      styles_category,
      styles_chart,
      styles_item,
      styles_photo,
      styles_action,
      styles_supplementary,
      styles_signature,
      fonts_size,
      colours_brand,
      colours_subtitle,
      colours_content,
    } = themeSettingsForm;

    const themeObj: WithFieldValue<Theme> = {
      title: themeSettingsForm.title.trim(),
      csv_images,
      footers_page_numbers,
      dates_order,
      dates_style,
      details_workspace,
      details_user,
      details_overall_score,
      details_action,
      details_client,
      details_site,
      details_site_photo,
      details_site_map,
      details_introduction,
      details_summary,
      details_signatures,
      charts_included,
      charts_score,
      charts_assignee,
      charts_action,
      photos_included,
      photos_timestamps,
      photos_size,
      photos_squared,
      photos_quality,
      styles_cover,
      styles_site,
      styles_category,
      styles_chart,
      styles_item,
      styles_photo,
      styles_action,
      styles_supplementary,
      styles_signature,
      fonts_size,
      colours_brand,
      colours_subtitle,
      colours_content,
      created_by: themeSettingsForm.created_by ?? `${user.name_first} ${user.name_last}`,
      created_by_id: themeSettingsForm.created_by_id ?? user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };

    await this.firestoreUtilsService.setDocumentDataWithFieldValue<Theme>(doc, themeObj);
  }

  /**
   * Delete Theme Doc
   * @param workspaceId
   * @param themeId
   */
  public async deleteThemeDoc(workspaceId: string, themeId: string, user: User): Promise<void> {
    const path = this.collectionsService.themesCol(workspaceId);
    const doc = `${path}/${themeId}`;
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    const obj = { is_deleted: true, last_event: lastEvent };
    return this.firestoreUtilsService.setDocumentData(doc, obj);
  }

  /**
   * Delete Batched
   * @param workspaceId
   * @param batchedThemes
   */
  async deleteBatchedThemes(workspaceId: string, batchedThemes: ThemeEnhanced[], user: User) {
    const path = this.collectionsService.themesCol(workspaceId);
    const operations: BatchOperation[] = [];
    const lastEvent = this.lastEventService.lastEvent("deleted", user);
    batchedThemes.forEach((theme) => {
      const doc = `${path}/${theme.id}`;
      operations.push({
        type: "update",
        documentPath: doc,
        data: { last_event: lastEvent, is_deleted: true },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Create Theme Object
   * @param user
   */
  createThemeObject(user: User): WithFieldValue<Theme> {
    const writeEventType = "added";
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);
    const { name_first, name_last, user_id } = user;
    return {
      title: "Default",
      csv_images: true,
      footers_page_numbers: true,
      dates_order: 2,
      dates_style: 0,
      details_workspace: true,
      details_user: true,
      details_overall_score: true,
      details_action: true,
      details_client: true,
      details_site: true,
      details_site_photo: true,
      details_site_map: true,
      details_introduction: true,
      details_summary: true,
      details_signatures: true,
      charts_included: true,
      charts_score: true,
      charts_assignee: true,
      charts_action: true,
      photos_included: true,
      photos_timestamps: true,
      photos_size: 2,
      photos_squared: true,
      photos_quality: 100,
      styles_cover: "Cover1",
      styles_site: "Site1",
      styles_category: "Category1",
      styles_item: "Item1",
      styles_photo: "Photo1",
      styles_action: "Action1",
      styles_supplementary: "Supplementary1",
      styles_signature: "Signature1",
      styles_chart: "Chart1",
      fonts_size: 2,
      colours_brand: "#1E4999",
      colours_subtitle: "#27323C",
      colours_content: "#61788D",
      created_by: `${name_first} ${name_last}`,
      created_by_id: user_id,
      is_deleted: false,
      last_event: lastEvent,
    };
  }
}
