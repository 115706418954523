<div class="assignee-chart-wrapper">
  <!-- Key -->
  <div class="chart-key-wrapper">
    <div class="chart-key-item">
      <div class="chart-key-no-priority chart-key"></div>
      <div class="chart-text">No Priority</div>
    </div>
    <div class="chart-key-item">
      <div class="chart-key-trivial chart-key"></div>
      <div class="chart-text">Trivial</div>
    </div>
    <div class="chart-key-item">
      <div class="chart-key-low chart-key"></div>
      <div class="chart-text">Low</div>
    </div>
    <div class="chart-key-item">
      <div class="chart-key-medium chart-key"></div>
      <div class="chart-text">Medium</div>
    </div>
    <div class="chart-key-item">
      <div class="chart-key-high chart-key"></div>
      <div class="chart-text">High</div>
    </div>
    <div class="chart-key-item">
      <div class="chart-key-critical chart-key"></div>
      <div class="chart-text">Critical</div>
    </div>
  </div>

  <div class="assignee-chart-data-wrapper">
    <!-- Assignees Data -->
    <div class="assignee-chart-bar-wrapper">
      @for (assignee of chartsAssignees; track $index) {
        <div class="assignee-chart-row" style="page-break-inside: avoid">
          <div class="assignee-chart-user">
            <div class="assignee-chart-name">{{ assignee.assignee_name }}</div>
            <div class="assignee-chart-count">({{ assignee.total }})</div>
          </div>
          <div class="assignee-chart-action-data">
            <div class="assignee-chart-action-priority assignee-action-priority-none" [ngStyle]="{ width: (assignee.no_priority / assigneeHighestActionCount) * 100 + '%', 'padding.px': assignee.no_priority > 0 ? 6 : 0 }">
              {{ assignee.no_priority < 1 ? '' : assignee.no_priority }}
            </div>
            <div class="assignee-chart-action-priority assignee-action-priority-trivial" [ngStyle]="{ width: (assignee.trivial / assigneeHighestActionCount) * 100 + '%', 'padding.px': assignee.trivial > 0 ? 6 : 0 }">
              {{ assignee.trivial < 1 ? '' : assignee.trivial }}
            </div>
            <div class="assignee-chart-action-priority assignee-action-priority-low" [ngStyle]="{ width: (assignee.low / assigneeHighestActionCount) * 100 + '%', 'padding.px': assignee.low > 0 ? 6 : 0 }">
              {{ assignee.low < 1 ? '' : assignee.low }}
            </div>
            <div class="assignee-chart-action-priority assignee-action-priority-medium" [ngStyle]="{ width: (assignee.medium / assigneeHighestActionCount) * 100 + '%', 'padding.px': assignee.medium > 0 ? 6 : 0 }">
              {{ assignee.medium < 1 ? '' : assignee.medium }}
            </div>
            <div class="assignee-chart-action-priority assignee-action-priority-high" [ngStyle]="{ width: (assignee.high / assigneeHighestActionCount) * 100 + '%', 'padding.px': assignee.high > 0 ? 6 : 0 }">
              {{ assignee.high < 1 ? '' : assignee.high }}
            </div>
            <div class="assignee-chart-action-priority assignee-action-priority-critical" [ngStyle]="{ width: (assignee.critical / assigneeHighestActionCount) * 100 + '%', 'padding.px': assignee.critical > 0 ? 6 : 0 }">
              {{ assignee.critical < 1 ? '' : assignee.critical }}
            </div>
          </div>
        </div>
      }

      <div class="percentage-axis-wrapper">
        <div>0</div>
        <div>{{ assigneeHighestActionCount }}</div>
      </div>
    </div>
  </div>
</div>
