<div class="grid grid-cols-1 gap-y-4">
  <!-- List -->
  <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
    <!-- List Component -->
    <ul class="list-ul" id="sitesList">
      @for (site of filteredSites; track $index) {
        <li [class.hidden]="site.hidden">
          <div class="list-row cursor-pointer">
            <!-- List Editing Tools -->
            @if (editMode) {
              <div class="flex flex-row items-center">
                <div class="mr-3 flex items-center justify-end hover:text-brand_Primary-hover dark:text-slate-300 dark:hover:text-sky-500">
                  @if (sitesBulkEditArray) {
                    <div class="flex items-center">
                      <input type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" (change)="createBatchEdit(site)" [checked]="sitesBulkEditArray.includes(site)" />
                    </div>
                  }
                </div>
                @if (sitesBulkEditArray && sitesBulkEditArray.length <= 0) {
                  <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" (click)="deleteSingleSite(site.id)" class="cip-transition h-5 w-5 cursor-pointer text-alert_Error hover:opacity-75">
                      <path
                        fill-rule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                }
              </div>
            }

            <div (click)="routeToSiteEditor(site.id)" class="list-a group">
              <div class="ml-2.5 flex flex-row sm:ml-0">
                @if (site.image_thumbnail_url) {
                  <div class="flex h-12 w-12 rounded-md bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + site.image_thumbnail_url + ')' }"></div>
                } @else {
                  <div class="flex h-12 w-12 items-center justify-center rounded-md bg-gray-100 text-text_Subtitle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                  </div>
                }
              </div>

              <!-- Left and Right Content -->
              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                <!-- Left Content -->
                <div class="min-w-0 flex-auto">
                  <p class="list-title">{{ site.title }}</p>
                  @if (site.ref) {
                    <p class="list-meta search-ref">{{ site.ref }}</p>
                  }
                  @if (site.what_3_words) {
                    <div class="list-meta flex flex-row items-center">
                      <div class="mr-1 text-[#e11f26]">///</div>
                      {{ site.what_3_words }}
                    </div>
                  }
                </div>

                @if (site.address) {
                  <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                    <div class="sm:flex sm:flex-col sm:items-end">
                      <pre class="list-meta search-address font-sans">{{ site.address }}</pre>
                    </div>
                  </div>
                }
              </div>
              <!-- Right Icon -->
              <div class="flex">
                <button type="button" class="list-icon" aria-expanded="false" aria-haspopup="true">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </li>
      }
    </ul>
    @if (allSitesHidden) {
      <search-empty [searchTerm]="_searchTerm" />
    }
  </div>
</div>
