import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ConnectedDevicesService } from "src/app/core/services/connected-devices.service";

@Component({
  selector: "connected-devices",
  standalone: true,
  imports: [DatePipe],
  templateUrl: "./connected-devices.component.html",
  styleUrl: "./connected-devices.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectedDevicesComponent {
  public connectedDevicesService = inject(ConnectedDevicesService);
}
