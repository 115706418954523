import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'cip';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { FirestoreUtilsService } from 'src/app/core/services/firestore/firestore-utils.service';
import { FormFormattingService } from 'src/app/core/services/forms/form-formatting.service';
import { LimitationManagerService } from 'src/app/core/services/limitation-manager.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { StringsService } from 'src/app/core/services/strings/strings.service';
import { SignatureEnhanced } from 'src/app/models/inspection/signature.model';
import { IncorrectFileType } from 'src/app/models/photo/photo-incorrect-file';
import { PhotoType } from 'src/app/models/photo/photo-type';
import { SinglePhotoUpload } from 'src/app/models/photo/photo-upload.model';
import { AlertType } from 'src/app/models/strings/strings.model';
import { SignOffDetailService } from 'src/app/sections/inspections/services/sign-off-detail.service';
import { DeleteOverlayComponent } from 'src/app/shared/delete-overlay/delete-overlay.component';
import { EmptyListComponent } from 'src/app/shared/empty-list/empty-list.component';
import { LoadingSpinnerComponent } from 'src/app/shared/loading-spinner/loading-spinner.component';
import { SignOffListService } from '../../../services/sign-off-list.service';
import { InspectionSignoffNewOverlayComponent } from '../../edit/inspection-signoff-new-overlay/inspection-signoff-new-overlay.component';
import { InspectionSignoffListAllComponent } from '../inspection-signoff-list-all/inspection-signoff-list-all.component';
import { InspectionSignoffListHeaderComponent } from '../inspection-signoff-list-header/inspection-signoff-list-header.component';
import { InspectionSignoffListMainComponent } from '../inspection-signoff-list-main/inspection-signoff-list-main.component';
import { InspectionSignoffListOtherComponent } from '../inspection-signoff-list-other/inspection-signoff-list-other.component';

@Component({
  selector: 'inspection-signoff-list-wrapper',
  standalone: true,
  imports: [
    AsyncPipe,
    InspectionSignoffListHeaderComponent,
    InspectionSignoffListMainComponent,
    InspectionSignoffListOtherComponent,
    InspectionSignoffListAllComponent,
    EmptyListComponent,
    LoadingSpinnerComponent,
    DeleteOverlayComponent,
    JsonPipe,
    InspectionSignoffNewOverlayComponent,
  ],
  templateUrl: './inspection-signoff-list-wrapper.component.html',
  styleUrl: './inspection-signoff-list-wrapper.component.scss',
})
export class InspectionSignoffListWrapperComponent implements OnInit, OnDestroy {
  @Input() set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input() set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input() set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  // Services
  private authService = inject(AuthService);
  private signOffListService = inject(SignOffListService);
  private signOffDetailService = inject(SignOffDetailService);
  private navigationService = inject(NavigationService);
  private router = inject(Router);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  // Properties
  public signatures$!: Observable<SignatureEnhanced[]>;
  public user: User;
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public editMode: boolean = false;
  public signatureBulkEditArray$: BehaviorSubject<SignatureEnhanced[]> = new BehaviorSubject<SignatureEnhanced[]>([]);
  public mainSignature: SignatureEnhanced | null = null;
  public otherSignatures: SignatureEnhanced[] = [];
  public allSignatures: SignatureEnhanced[] = [];
  private signatureToDelete: string = '';
  public overlay_deleteSignature: boolean = false;
  public deleteTitle: string = '';
  public deleteDescription: string = '';
  public overlay_newSignature: boolean = false;
  public signatureForm: FormGroup;
  public newSignatureId!: string;

  // Photo Properties
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos = false;
  private tempBlobPhotoURL!: string;

  constructor() {
    this.navigationService.breadcrumbsPath = this.router.url;

    // User
    this.user = this.authService.currentUser;

    this.signatureForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ['', [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      image_url: null,
      image_thumbnail_url: null,
      date_signed: null,
      order: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getSignaturesList();
  }

  ngOnDestroy(): void {
    this.revokeObjectURL();
  }

  /**
   * Get Signatures List
   */
  private async getSignaturesList(): Promise<void> {
    try {
      this.signatures$ = this.signOffListService.getSignaturesList$(this.workspaceId, this.inspectionId).pipe(
        filter((data) => !!data),
        tap((data) => {
          this.allSignatures = [];
          this.otherSignatures = [];
          this.mainSignature = null;
          data.forEach((element) => {
            this.allSignatures.push(element);
            if (element.order === 0) {
              this.mainSignature = element;
            } else {
              this.otherSignatures.push(element);
            }
          });
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Reorder Signatures
   * @param event
   */
  async reorderSignatures(event: CdkDragDrop<string[]>): Promise<void> {
    moveItemInArray(this.allSignatures, event.previousIndex, event.currentIndex);
    try {
      await this.signOffListService.reorderSignatures(this.allSignatures, this.workspaceId, this.inspectionId, this.user);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Route To Signature Editor
   */
  public async limitationManagerCheck(signatureId: string): Promise<void> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction('signature_create_update');
    if (limitationResult) {
      this.toggleNewSignatureOverlay();
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Route To Signature Editor
   */
  public async routeToSignatureEditor(signatureId: string): Promise<void> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction('signature_create_update');
    if (limitationResult) {
      this.router.navigate(['/workspace', this.workspaceId, 'folders', this.folderId, 'inspections', this.inspectionId, 'signoff', signatureId, 'editor']);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Set Delete Signature Property
   * @param signatureId
   */
  public setDeleteSignatureProperty(signatureId: string): void {
    const deleteSignature = this.stringsService.alertFilter(AlertType.DeleteSignature);
    if (deleteSignature) {
      this.signatureToDelete = signatureId;
      this.deleteTitle = deleteSignature.title;
      this.deleteDescription = deleteSignature.description;
      this.toggleDeleteSignatureOverlay();
    }
  }

  /**
   * Set Delete Batch Signatures Properties
   */
  public setDeleteBatchSignaturesProperties(): void {
    if (this.signatureBulkEditArray$.getValue().length > 0) {
      const deleteSignatures = this.stringsService.alertFilter(AlertType.DeleteSignatures);
      if (deleteSignatures) {
        this.deleteTitle = deleteSignatures.title;
        this.deleteDescription = deleteSignatures.description;
        this.toggleDeleteSignatureOverlay();
      }
    }
  }
  /**
   * Delete Single Or Batched Signatures
   */
  public deleteSingleOrBatchedSignatures(): void {
    if (this.signatureBulkEditArray$.getValue().length > 0) {
      this.deleteBatchedSignatures();
    } else {
      this.deleteSingleSignature();
    }
  }

  /**
   * Delete Batched Signatures
   */
  private async deleteBatchedSignatures(): Promise<void> {
    try {
      await this.signOffListService.deleteBatchedSignatures(this.workspaceId, this.inspectionId, this.signatureBulkEditArray$.getValue(), this.allSignatures, this.user);
      this.toggleDeleteSignatureOverlay();
      this.checkSignaturesCountForEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Single Signature
   */
  private async deleteSingleSignature(): Promise<void> {
    try {
      if (this.signatureToDelete) {
        await this.signOffDetailService.deleteSignatureDoc(this.workspaceId, this.inspectionId, this.allSignatures, this.signatureToDelete, this.user);
        this.toggleDeleteSignatureOverlay();
        this.checkSignaturesCountForEditMode();
      }
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Check Signatures Count For Edit Mode
   */
  private checkSignaturesCountForEditMode(): void {
    if (this.allSignatures.length === 0) {
      this.editMode = false;
      this.signatureBulkEditArray$.next([]);
    }
  }

  /**
   * Create Batch Edit
   * @param signature
   */
  public createBatchEdit(signature: SignatureEnhanced): void {
    const currentArray = this.signatureBulkEditArray$.getValue();
    if (currentArray.some((e: SignatureEnhanced) => e.id === signature.id)) {
      this.removeFromBatchArray(signature);
    } else {
      this.addToBatchArray(signature);
    }
  }

  /**
   * Add To Batch Array
   * @param signature
   */
  addToBatchArray(signature: SignatureEnhanced): void {
    const updatedArray = [...this.signatureBulkEditArray$.getValue(), signature];
    this.signatureBulkEditArray$.next(updatedArray);
  }
  /**
   * Remove From Batch Array
   * @param signature
   */
  removeFromBatchArray(signature: SignatureEnhanced): void {
    const updatedArray = this.signatureBulkEditArray$.getValue().filter((e) => e.id !== signature.id);
    this.signatureBulkEditArray$.next(updatedArray);
  }

  /**
   * Check If Photos Are Uploading Or Create Signature
   */
  public async checkIfPhotosAreUploadingOrCreateSignature(): Promise<void> {
    if (this.uploadFileArray.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.createSignatureDoc();
    }
  }

  /**
   * Bulk Delete Signatures
   */
  public async bulkDeleteSignatures(): Promise<void> {}

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    if (photos.type === 'signature') {
      this.signatureForm.patchValue({
        image_url: photos.image_url,
        image_thumbnail_url: photos.image_thumbnail_url,
      });
    }
    try {
      await this.createSignatureDoc();
      this.togglePhotosUploadOverlay();
      this.emptyUploadFileArray();
      this.revokeObjectURL();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Create Signature Doc
   */
  private async createSignatureDoc(): Promise<void> {
    try {
      await this.signOffDetailService.setNewSignatureDoc(this.workspaceId, this.inspectionId, this.newSignatureId, this.signatureForm.value, this.user);
      this.signatureForm.reset();
      this.toggleNewSignatureOverlay();
      this.togglePhotosUploadOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split('/').pop();
    if (!(fileExtension == 'jpg' || fileExtension == 'jpeg' || fileExtension == 'png')) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.togglePhotoPreview();
      this.signatureForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * Remove Photo From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle Photos Upload Overlay
   */
  private togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Empty Upload File Array
   */
  private emptyUploadFileArray(): void {
    this.uploadFileArray = [];
  }

  /**
   * Revoke Object URL
   */
  private revokeObjectURL(): void {
    URL.revokeObjectURL(this.tempBlobPhotoURL);
  }

  /**
   * Toggle Edit Mode
   */
  public async toggleEditMode(): Promise<void> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction('signature_create_update');
    if (limitationResult) {
      this.editMode = !this.editMode;
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Delete Signature Overlay
   */
  public toggleDeleteSignatureOverlay(): void {
    this.overlay_deleteSignature = !this.overlay_deleteSignature;
  }

  /**
   * Toggle New Signature Overlay
   */
  public toggleNewSignatureOverlay(): void {
    if (!this.overlay_newSignature) {
      this.newSignatureId = this.firestoreUtilsService.createFirestoreId();
    } else {
      this.signatureForm.reset();
      this.emptyUploadFileArray();
      this.revokeObjectURL();
    }
    this.overlay_newSignature = !this.overlay_newSignature;
  }
}
