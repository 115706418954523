import { JsonPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartsActionResults } from 'src/app/models/charts/charts-action.model';
import { ChartsAssigneeResults } from 'src/app/models/charts/charts-assignees.model';
import { ChartsTestResults } from 'src/app/models/charts/charts-tests.model';
import { InspectionOverviewStatsActionsComponent } from '../inspection-overview-stats-actions/inspection-overview-stats-actions.component';
import { InspectionOverviewStatsAssigneesComponent } from '../inspection-overview-stats-assignees/inspection-overview-stats-assignees.component';
import { InspectionOverviewStatsScoresComponent } from '../inspection-overview-stats-scores/inspection-overview-stats-scores.component';

@Component({
  selector: 'inspection-overview-stats-wrapper',
  standalone: true,
  imports: [InspectionOverviewStatsActionsComponent, NgClass, JsonPipe, InspectionOverviewStatsAssigneesComponent, InspectionOverviewStatsScoresComponent],
  templateUrl: './inspection-overview-stats-wrapper.component.html',
  styleUrl: './inspection-overview-stats-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionOverviewStatsWrapperComponent {
  @Input({ required: true }) chartsAssignees: ChartsAssigneeResults[] = [];
  @Input({ required: true }) actionResults: ChartsActionResults[] = [];
  @Input({ required: true }) chartsResults: ChartsTestResults[] = [];
  @Input({ required: true }) totalActions!: number;
  @Input({ required: true }) assigneeHighestActionCount!: number;
  @Input({ required: true }) totalTests!: number;

  // Toggle which details (scores, assignees, actions) to view
  public selectedDetail = 'score distribution';
  public detailChoices = ['score distribution', 'assignees', 'actions'];

  /**
   * Set Selected Detail
   * @param detail
   */
  setSelectedDetail(detail: string): void {
    this.selectedDetail = detail;
  }
}
