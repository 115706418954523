import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartsAssigneeResults } from 'src/app/models/charts/charts-assignees.model';
import { ChartsAssigneesComponent } from 'src/app/shared/charts/charts-assignees/charts-assignees.component';

@Component({
  selector: 'inspection-overview-stats-assignees',
  standalone: true,
  imports: [ChartsAssigneesComponent],
  templateUrl: './inspection-overview-stats-assignees.component.html',
  styleUrls: ['./inspection-overview-stats-assignees.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionOverviewStatsAssigneesComponent {
  @Input({ required: true })
  set chartsAssignees(value: ChartsAssigneeResults[]) {
    this._chartsAssignees = value;
    this.updateFilteredAssignees(); // Call the filtering method when the input is updated
  }

  get chartsAssignees(): ChartsAssigneeResults[] {
    return this._chartsAssignees;
  }

  @Input({ required: true }) totalActions!: number;
  @Input({ required: true }) assigneeHighestActionCount!: number;

  private _chartsAssignees: ChartsAssigneeResults[] = [];
  filteredAssignees: ChartsAssigneeResults[] = [];

  updateFilteredAssignees() {
    this.filteredAssignees = this._chartsAssignees.filter((assignee) => assignee.assignee_id !== 'Unassigned');
  }
}
