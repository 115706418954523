import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { User } from "cip";
import { firstValueFrom } from "rxjs";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { WorkspaceEnhanced } from "src/app/models/workspace/workspace.model";
import { MemberDetailService } from "../../current-workspace/manage-members/services/member-detail.service";
import { LeaveWorkspaceComponent } from "../leave-workspace/leave-workspace.component";
@Component({
  selector: "leave-workspace-wrapper",
  standalone: true,
  imports: [NgClass, LeaveWorkspaceComponent],
  templateUrl: "./leave-workspace-wrapper.component.html",
  styleUrl: "./leave-workspace-wrapper.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveWorkspaceWrapperComponent {
  @Input({ required: true }) workspaceToLeave!: WorkspaceEnhanced;
  @Input({ required: true }) user!: User;
  @Output() leaveWorkspaceOutput = new EventEmitter<void>();
  @Output() toggleLeaveWorkspaceOverlayOutput = new EventEmitter<void>();

  // Services
  private memberDetailService = inject(MemberDetailService);

  // Properties
  public isConfirmLeaveChecked = false;

  /**
   * Check If The User Owns The Workspace
   */
  async checkIfTheUserOwnsTheWorkspace(): Promise<void> {
    try {
      const member: MemberEnhanced | null = await firstValueFrom(this.memberDetailService.getMemberDoc$(this.workspaceToLeave.id, this.user.user_id));
      if (member === null) {
        alert("Member not found.");
        return;
      }

      if (member.role_id === "Owner") {
        alert("You cannot leave a workspace that you own. Please transfer ownership to another Member first.");
        return;
      } else {
        this.leaveWorkspace();
      }
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Leave Workspace
   */
  leaveWorkspace(): void {
    this.leaveWorkspaceOutput.emit();
  }
  /**
   * Set Is Confirm Leave Checked
   */
  setIsConfirmLeaveChecked() {
    this.isConfirmLeaveChecked = !this.isConfirmLeaveChecked;
  }

  /**
   * Toggle Leave Workspace Overlay
   */
  toggleLeaveWorkspaceOverlay() {
    this.toggleLeaveWorkspaceOverlayOutput.emit();
  }
}
