import { inject, Injectable } from '@angular/core';
import { where } from 'firebase/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionsService } from 'src/app/core/services/collections/collections.service';
import { FirestoreUtilsService } from 'src/app/core/services/firestore/firestore-utils.service';
import { InspectionEnhanced } from 'src/app/models/inspection/inspection.model';

@Injectable({
  providedIn: 'root',
})
export class InspectionsListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  // Properties
  public _reorderedInspectionsSubject = new BehaviorSubject<InspectionEnhanced[]>([]);
  public reorderedInspections$ = this._reorderedInspectionsSubject.asObservable();
  public areInspectionsSorted = false;
  public currentlySelectedInspectionSortChoice!: string;
  public isSortOrderAscending: boolean = true;
  public sortDropdown: boolean = false;

  /**
   * Get Inspections List
   * @param workspaceId
   * @param folderId
   */
  public getInspectionsList$(workspaceId: string, folderId: string): Observable<InspectionEnhanced[]> {
    const path = this.collectionsService.inspectionsCol(workspaceId);
    const queryConstraints = [where('folder_id', '==', folderId), where('is_deleted', '==', false)];
    return this.firestoreUtilsService.getCollectionData<InspectionEnhanced>(path, queryConstraints);
  }

  /**
   * Update Reordered Inspections
   * @param Inspections
   */
  updateReorderedInspections(Inspections: InspectionEnhanced[]) {
    this._reorderedInspectionsSubject.next([...Inspections]);
  }

  /**
   * Reorder Inspections With New Data
   */
  reorderInspectionsWithNewData(): void {
    const currentInspections = this._reorderedInspectionsSubject.getValue();
    let sortedInspections: InspectionEnhanced[] = [...currentInspections];

    // Sort the inspections based on the currently selected sort choice
    switch (this.currentlySelectedInspectionSortChoice) {
      case 'Title':
        sortedInspections.sort((a, b) => (this.isSortOrderAscending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)));
        break;
      case 'Date':
        sortedInspections.sort((a, b) => {
          const dateA: Date = a.date_created.toDate();
          const dateB: Date = b.date_created.toDate();
          const comparison = dateA.getTime() - dateB.getTime();
          return this.isSortOrderAscending ? comparison : -comparison;
        });
        break;
        break;
      case 'Reference':
        sortedInspections.sort((a, b) => (this.isSortOrderAscending ? (a.ref || '').localeCompare(b.ref || '') : (b.ref || '').localeCompare(a.ref || '')));
        break;
    }

    // Update the reordered Inspections subject
    this.updateReorderedInspections(sortedInspections);
  }
}
