import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { JsonPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryEnhanced } from 'src/app/models/category/category.model';
import { ItemAnswerOutput } from 'src/app/models/item/item-from-list.model';
import { ItemEnhanced } from 'src/app/models/item/item.model';
import { SearchEmptyComponent } from 'src/app/shared/search-empty/search-empty.component';
import { InspectionItemsListQuestionTypesComponent } from '../inspection-items-list-question-types/inspection-items-list-question-types.component';
import { InspectionItemsListTestTypesComponent } from '../inspection-items-list-test-types/inspection-items-list-test-types.component';

@Component({
  selector: 'inspection-items-list',
  standalone: true,
  imports: [JsonPipe, CdkDrag, CdkDropList, NgClass, SearchEmptyComponent, InspectionItemsListTestTypesComponent, InspectionItemsListQuestionTypesComponent],
  templateUrl: './inspection-items-list.component.html',
  styleUrl: './inspection-items-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionItemsListComponent {
  @Input({ required: true })
  set items(value: ItemEnhanced[]) {
    this._items = value;
    this.updateFilteredItemsForSearchResults();
  }

  get items(): ItemEnhanced[] {
    return this._items;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredItemsForSearchResults();
  }

  @Input({ required: true }) editMode!: boolean;
  @Input({ required: true }) currentCategory!: CategoryEnhanced;
  @Input() itemsBulkEditArray: ItemEnhanced[] | null = [];
  @Output() reorderItemsOutput = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() deleteSingleItemOutput = new EventEmitter<ItemEnhanced>();
  @Output() createBatchEditOutput = new EventEmitter<ItemEnhanced>();
  @Output() routeToItemOrTestDetailsOutput = new EventEmitter<string>();
  @Output() toggleDuplicateOverlayOutput = new EventEmitter<ItemEnhanced>();
  @Output() setItemAnswerOutput = new EventEmitter<ItemAnswerOutput>();

  // Properites
  private _items: ItemEnhanced[] = [];
  public _searchTerm: string = '';
  public filteredItems: ItemEnhanced[] = [];
  public allItemsHidden: boolean = false;

  /**
   * Update Filtered inspections
   */
  updateFilteredItemsForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredItems = this.items.map((item) => ({
      ...item,
      hidden: !item.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allItemsHidden = this.filteredItems.every((item) => item.hidden);
  }

  /**
   * Reorder Items
   * @param event
   */
  public reorderItems(event: CdkDragDrop<string[]>): void {
    this.reorderItemsOutput.emit(event);
  }

  /**
   * Create Batch Edit
   * @param Item
   */
  public createBatchEdit(item: ItemEnhanced): void {
    this.createBatchEditOutput.emit(item);
  }

  /**
   * Delete Single Item
   * @param itemId
   */
  public deleteSingleItem(item: ItemEnhanced): void {
    this.deleteSingleItemOutput.emit(item);
  }

  /**
   * Route to Item or Items
   * @param Item
   */
  public routeToItemOrTestDetails(itemId: string): void {
    this.routeToItemOrTestDetailsOutput.emit(itemId);
  }

  /**
   * Toggle Duplicate Overlay
   */
  public toggleDuplicateOverlay(item: ItemEnhanced): void {
    this.toggleDuplicateOverlayOutput.emit(item);
  }

  /**
   * Set Item Answer
   * @param answer
   */
  public setItemAnswer(itemAnswer: ItemAnswerOutput): void {
    this.setItemAnswerOutput.emit(itemAnswer);
  }
}
