import { JsonPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "manage-workspace-transfer-ownership-members-list",
  standalone: true,
  imports: [NgClass, SearchBarComponent, SearchEmptyComponent, JsonPipe],
  templateUrl: "./manage-workspace-transfer-ownership-members-list.component.html",
  styleUrl: "./manage-workspace-transfer-ownership-members-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageWorkspaceTransferOwnershipMembersListComponent implements OnChanges {
  @Input({ required: true }) workspaceForm!: FormGroup;
  @Input({ required: true }) members: MemberEnhanced[] | undefined | null;
  @Input({ required: true }) allOtherMembers: MemberEnhanced[] = [];
  @Input({ required: true }) currentlySelectedMember!: MemberEnhanced | null;
  @Output() memberSelectionOutput = new EventEmitter<MemberEnhanced>();
  @Output() toggleNewOwnerPanelOutput = new EventEmitter<void>();
  // Properties
  public filteredMembers: Array<MemberEnhanced> = [];
  public allMembersHidden: boolean = false;
  public searchTerm: string = "";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["allOtherMembers"]) {
      this.applySearch();
    }
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredMembers = this.allOtherMembers.map((member) => ({
      ...member,
      hidden: !(member.name_first.toLowerCase().includes(lowerSearchTerm) || member.name_last.toLowerCase().includes(lowerSearchTerm)),
    }));

    this.allMembersHidden = this.filteredMembers.every((member) => member.hidden);
  }

  /**
   * Member Selection
   * @param member
   */
  public memberSelection(member: MemberEnhanced): void {
    this.memberSelectionOutput.emit(member);
  }

  /**
   * Toggle New Owner Panel
   */
  public toggleNewOwnerPanel(): void {
    this.toggleNewOwnerPanelOutput.emit();
  }
}
