import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "role-edit-details",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./role-edit-details.component.html",
  styleUrl: "./role-edit-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleEditDetailsComponent {
  @Input({ required: true }) roleForm!: FormGroup;
  public charactersLeft = 120;

  characterLimit(event: Event): void {
    const descriptionValue = (event.target as HTMLInputElement).value;
    this.charactersLeft = 120 - descriptionValue.length;
  }

  onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData?.getData("text") || "";

    // Check if pasted text exceeds the character limit
    if (pastedText.length > 120) {
      event.preventDefault(); // Prevent the default paste behavior
      alert("You have exceeded the character limit of 120 characters.");
    }
  }
}
