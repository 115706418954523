@if (combinedData$ | async; as combinedData) {
  <manage-role-edit-header [workspaceId]="workspaceId" [roleId]="roleId" />
  <manage-role-edit-form
    [assignedInvites]="combinedData.invites"
    [assignedMembers]="combinedData.members"
    [roleForm]="roleForm"
    [infoViewPermissionsEditor]="infoViewPermissionsEditor"
    [infoViewRoleEditor]="infoViewRoleEditor"
    [roleId]="roleId"
    (saveRoleOutput)="saveRole()"
    (deleteRoleOutput)="canUserDeleteRole()" />
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteRole()) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteRoleOverlay(false)" (deleteButtonClicked)="deleteRole()" />
}
