import { CdkDrag, CdkDragDrop, CdkDropList } from "@angular/cdk/drag-drop";
import { DecimalPipe, NgClass, NgStyle, PercentPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TemplateCategoryEnhanced } from "src/app/models/category/template-category.model";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "manage-template-categories-list",
  standalone: true,
  imports: [SearchEmptyComponent, NgClass, NgStyle, PercentPipe, CdkDrag, CdkDropList, DecimalPipe],
  templateUrl: "./manage-template-categories-list.component.html",
  styleUrl: "./manage-template-categories-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateCategoriesListComponent {
  @Input({ required: true })
  set categories(value: TemplateCategoryEnhanced[]) {
    this._categories = value;
    this.updateFilteredCategoriesForSearchResults();
  }

  get categories(): TemplateCategoryEnhanced[] {
    return this._categories;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredCategoriesForSearchResults();
  }

  @Input({ required: true }) editMode!: boolean;
  @Input() categoriesBulkEditArray: TemplateCategoryEnhanced[] | null = [];
  @Output() reorderCategoriesOutput = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() deleteSingleCategoryOutput = new EventEmitter<string>();
  @Output() createBatchEditOutput = new EventEmitter<TemplateCategoryEnhanced>();
  @Output() routeToCategoryOrItemsOutput = new EventEmitter<string>();
  @Output() toggleDuplicateOverlayOutput = new EventEmitter<TemplateCategoryEnhanced>();

  // Properties
  private _categories: TemplateCategoryEnhanced[] = [];
  public _searchTerm: string = "";
  public filteredCategories: TemplateCategoryEnhanced[] = [];
  public allCategoriesHidden: boolean = false;

  /**
   * Update Filtered Categories
   */
  updateFilteredCategoriesForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredCategories = this.categories.map((category) => ({
      ...category,
      hidden: !category.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allCategoriesHidden = this.filteredCategories.every((category) => category.hidden);
  }

  /**
   * Reorder Categories
   * @param event
   */
  public reorderCategories(event: CdkDragDrop<string[]>): void {
    this.reorderCategoriesOutput.emit(event);
  }

  /**
   * Create Batch Edit
   * @param category
   */
  public createBatchEdit(category: TemplateCategoryEnhanced): void {
    this.createBatchEditOutput.emit(category);
  }

  /**
   * Delete Single Category
   * @param categoryId
   */
  public deleteSingleCategory(categoryId: string): void {
    this.deleteSingleCategoryOutput.emit(categoryId);
  }

  /**
   * Route to Category or Items
   * @param category
   */
  public routeToCategoryOrItems(category: TemplateCategoryEnhanced): void {
    this.routeToCategoryOrItemsOutput.emit(category.id);
  }

  /**
   * Toggle Duplicate Overlay
   */
  public toggleDuplicateOverlay(category: TemplateCategoryEnhanced): void {
    this.toggleDuplicateOverlayOutput.emit(category);
  }
}
