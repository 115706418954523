import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'action-edit-priority',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: './action-edit-priority.component.html',
  styleUrl: './action-edit-priority.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEditPriorityComponent {
  @Input({required: true}) actionForm!: FormGroup;

  // Severity Options
  severityOptions: { value: number; name: string; colour: string }[] = [
    { value: 100, name: 'Critical', colour: 'bg-score_0' },
    { value: 75, name: 'High', colour: 'bg-score_25' },
    { value: 50, name: 'Medium', colour: 'bg-score_50' },
    { value: 25, name: 'Low', colour: 'bg-score_75' },
    { value: 0, name: 'Trivial', colour: 'bg-score_100' },
  ];

  public currentSeverity!: number;

  /**
   * Set Severity
   * @param value
   */
  setSeverity(value: number) {
    this.currentSeverity = value;
  }
}
