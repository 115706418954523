import { CdkDrag, CdkDragDrop, CdkDropList } from "@angular/cdk/drag-drop";
import { JsonPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TemplateCategoryEnhanced } from "src/app/models/category/template-category.model";
import { TemplateItemEnhanced } from "src/app/models/item/template-item.model";

import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";
import { ManageTemplateItemsListItemsModeComponent } from "../manage-template-items-list-items-mode/manage-template-items-list-items-mode.component";

@Component({
  selector: "manage-template-items-list",
  standalone: true,
  imports: [JsonPipe, CdkDrag, CdkDropList, NgClass, SearchEmptyComponent, ManageTemplateItemsListItemsModeComponent],
  templateUrl: "./manage-template-items-list.component.html",
  styleUrl: "./manage-template-items-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateItemsListComponent {
  @Input({ required: true })
  set items(value: TemplateItemEnhanced[]) {
    this._items = value;
    this.updateFilteredItemsForSearchResults();
  }

  get items(): TemplateItemEnhanced[] {
    return this._items;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredItemsForSearchResults();
  }

  @Input({ required: true }) editMode!: boolean;
  @Input({ required: true }) currentCategory!: TemplateCategoryEnhanced;
  @Input() itemsBulkEditArray: TemplateItemEnhanced[] | null = [];
  @Output() reorderItemsOutput = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() deleteSingleItemOutput = new EventEmitter<TemplateItemEnhanced>();
  @Output() createBatchEditOutput = new EventEmitter<TemplateItemEnhanced>();
  @Output() routeToItemEditorOutput = new EventEmitter<string>();
  @Output() toggleDuplicateOverlayOutput = new EventEmitter<TemplateItemEnhanced>();

  // Properties
  private _items: TemplateItemEnhanced[] = [];
  public _searchTerm: string = "";
  public filteredItems: TemplateItemEnhanced[] = [];
  public allItemsHidden: boolean = false;

  /**
   * Update Filtered items
   */
  updateFilteredItemsForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredItems = this.items.map((item) => ({
      ...item,
      hidden: !item.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allItemsHidden = this.filteredItems.every((item) => item.hidden);
  }

  /**
   * Reorder Items
   * @param event
   */
  public reorderItems(event: CdkDragDrop<string[]>): void {
    this.reorderItemsOutput.emit(event);
  }

  /**
   * Create Batch Edit
   * @param Item
   */
  public createBatchEdit(item: TemplateItemEnhanced): void {
    this.createBatchEditOutput.emit(item);
  }

  /**
   * Delete Single Item
   * @param itemId
   */
  public deleteSingleItem(item: TemplateItemEnhanced): void {
    this.deleteSingleItemOutput.emit(item);
  }

  /**
   * Route to Item Editor
   * @param Item
   */
  public routeToItemEditor(itemId: string): void {
    this.routeToItemEditorOutput.emit(itemId);
  }

  /**
   * Toggle Duplicate Overlay
   */
  public toggleDuplicateOverlay(item: TemplateItemEnhanced): void {
    this.toggleDuplicateOverlayOutput.emit(item);
  }
}
