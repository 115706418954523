import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { RoleCurrent, RoleDefault, RoleEnhanced } from "src/app/models/role/role.model";
import { SearchBarComponent } from "../../search-bar/search-bar.component";
import { SearchEmptyComponent } from "../../search-empty/search-empty.component";

@Component({
  selector: "role-selector-custom",
  standalone: true,
  imports: [SearchBarComponent, SearchEmptyComponent, NgClass],
  templateUrl: "./role-selector-custom.component.html",
  styleUrl: "./role-selector-custom.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleSelectorCustomComponent implements OnChanges {
  @Input({ required: true }) customRoles!: RoleEnhanced[];
  @Input({ required: true }) currentRole: RoleCurrent | null = null;
  @Output() selectRoleOutput = new EventEmitter<RoleDefault>();
  @Output() toggleRoleFormOverlayOutput = new EventEmitter<void>();
  public filteredRoles: Array<RoleEnhanced> = [];
  public allRolesHidden: boolean = false;
  public searchTerm: string = "";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["customRoles"]) {
      this.applySearch();
    }
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredRoles = this.customRoles.map((role) => ({
      ...role,
      hidden: !role.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allRolesHidden = this.filteredRoles.every((role) => role.hidden);
  }

  /**
   * Select Custom Role
   * @param role
   */
  public selectRole(role: RoleDefault): void {
    this.selectRoleOutput.emit(role);
  }

  /**
   * Toggle New Role Form
   */
  public toggleRoleFormOverlay(): void {
    this.toggleRoleFormOverlayOutput.emit();
  }
}
