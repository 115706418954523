import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "test-progress",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: "./test-progress.component.html",
  styleUrl: "./test-progress.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestProgressComponent {
  @Input({ required: true }) testForm!: FormGroup;
  public answerType = [
    { title: "Not Started", value: "progress-0" },
    { title: "In Progress ", value: "progress-50" },
    { title: "Completed ", value: "progress-100" },
  ];
}
