import { NgStyle } from "@angular/common";
import { Component, inject } from "@angular/core";
import { ReportsService } from "../../services/reports.service";
import { ReportContentItemsComponent } from "../report-content-items/report-content-items.component";

@Component({
  selector: "report-content-categories",
  standalone: true,
  imports: [NgStyle, ReportContentItemsComponent],
  templateUrl: "./report-content-categories.component.html",
  styleUrl: "./report-content-categories.component.scss",
})
export class ReportContentCategoriesComponent {
  public reportsService = inject(ReportsService);
}
