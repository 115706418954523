import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from "@angular/router";
import { Billing } from "cip";
import { combineLatest, filter, map, Observable, Subscription } from "rxjs";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { SubscriptionService } from "src/app/core/services/subscription.service";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { LimitationManagerComponent } from "src/app/shared/limitation-manager/limitation-manager.component";
import { MainMenuComponent } from "src/app/shared/main-menu/main-menu.component";
import { MemberDetailService } from "../workspaces/current-workspace/manage-members/services/member-detail.service";

@Component({
  selector: "sections-wrapper",
  standalone: true,
  imports: [RouterModule, NgClass, MainMenuComponent, AsyncPipe, JsonPipe, LimitationManagerComponent],
  templateUrl: "./sections-wrapper.component.html",
  styleUrl: "./sections-wrapper.component.scss",
})
export class SectionsWrapperComponent implements OnInit, OnDestroy {
  @Input() workspaceId!: string;

  // Services
  private activatedRoute = inject(ActivatedRoute);
  public limitationManagerService = inject(LimitationManagerService);
  private auth = inject(Auth);
  private memberDetailService = inject(MemberDetailService);
  private subscriptionService = inject(SubscriptionService);
  private router = inject(Router);

  // Properties / Observables
  public routeLabel!: string;
  public billing$!: Observable<Billing>;
  public member$!: Observable<MemberEnhanced>;
  private billingSubscription!: Subscription;
  private memberSubscription!: Subscription;
  public combinedData$!: Observable<{ member: MemberEnhanced; billing: Billing }>;
  private routeSubscription: Subscription;
  constructor() {
    this.routeSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.routeLabel = this.activatedRoute.firstChild?.snapshot?.data?.["route"];
    });
    this.routeLabel = this.activatedRoute.firstChild?.snapshot?.data?.["route"];
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(({ workspaceId }) => {
      this.workspaceId = workspaceId;
      this.unsubscribeFromSubscriptionsAndReset();
      this.setUpBillingSubscription();
      this.setUpMemberSubscription(workspaceId);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptionsAndReset();
    this.routeSubscription?.unsubscribe();
  }

  unsubscribeFromSubscriptionsAndReset() {
    this.billingSubscription?.unsubscribe();
    this.memberSubscription?.unsubscribe();
  }

  // Update setUpBillingSubscription to use new Auth API
  async setUpBillingSubscription() {
    const user = this.auth.currentUser; // Use this.auth.currentUser instead of afAuth
    if (user) {
      if (!this.billing$) {
        this.billing$ = this.subscriptionService.getUserSubscription$(user.uid);
      }
      this.billingSubscription = this.billing$.subscribe((data) => {
        this.limitationManagerService.billingDoc = data;
        this.createCombinedDataStream(); // Create or update combinedData$ when billing$ is ready
      });
    }
  }

  // Update setUpMemberSubscription to use new Auth API
  async setUpMemberSubscription(workspaceId: string) {
    const user = this.auth.currentUser; // Use this.auth.currentUser instead of afAuth
    if (user) {
      if (!this.member$) {
        this.member$ = this.memberDetailService.getMemberDoc$(workspaceId, user.uid).pipe(filter((data) => !!data));
      }
      this.memberSubscription = this.member$.subscribe((data) => {
        if (this.limitationManagerService.memberDoc) {
          this.limitationManagerService.memberDoc.next(data);
          this.createCombinedDataStream(); // Create or update combinedData$ when member$ is ready
        }
      });
    }
  }

  createCombinedDataStream() {
    if (this.billing$ && this.member$) {
      this.combinedData$ = combineLatest([this.billing$, this.member$]).pipe(map(([billing, member]) => ({ billing, member })));
    }
  }

  closeLimitationOverlay() {
    this.limitationManagerService.overlay_limitationManager = false;
  }
}
