import { Injectable } from '@angular/core';
import { OperationFilter, OperationType } from 'src/app/models/strings/strings.model';

@Injectable({
  providedIn: 'root',
})
export class OperationStringsService {
  private operationDuplicateInspection: OperationFilter = {
    title: 'Duplicate Inspection',
    description: 'An exact copy of the original Inspection including Test and Question Answers, Notes, Photos, and Actions.',
    failure: 'Failed to duplicate Inspection',
    running: 'Creating duplicate Inspection',
    success: 'Successfully duplicated Inspection',
  };

  private operationDuplicateCategory: OperationFilter = {
    title: 'Duplicate Category',
    description: 'Duplicating this Category will copy all Items (Tests & Questions) but not their Answers, Photos or Actions.',
    failure: 'Failed to duplicate Category',
    running: 'Creating duplicate Category',
    success: 'Successfully duplicated Category',
  };

  private operationDuplicateItem: OperationFilter = {
    title: 'Duplicate Item',
    description: 'Duplicating this Item will copy its Title, Mode and Type but not its Score, Notes, Actions or Photos.',
    failure: 'Failed to duplicate Item',
    running: 'Creating duplicate Item',
    success: 'Successfully duplicated Item',
  };

  private operationDuplicateTemplate: OperationFilter = {
    title: 'Duplicate Template',
    description: 'Duplicating this Template will copy all its Categories and Items.',
    failure: 'Failed to duplicate Template',
    running: 'Creating duplicate Template',
    success: 'Successfully duplicated Template',
  };

  private operationReinspection: OperationFilter = {
    title: 'Reinspection',
    description: 'Items (Tests and Questions) will be unanswered and there will be no Notes, Photos or Actions.',
    failure: 'Failed to create Reinspection',
    running: 'Creating Reinspection',
    success: 'Successfully created Reinspection',
  };

  private operationCreateInspectionFromTemplate: OperationFilter = {
    title: 'Confirm Template Selection',
    description: 'Adds the Categories and Items contained in this Template to the current Inspection',
    failure: 'Failed to create Categories & Items',
    running: 'Creating Categories & Items from Template',
    success: 'Successfully created Categories & Items',
  };

  private operationCreateCategoriesFromTemplate: OperationFilter = {
    title: 'Create Categories From Template',
    description: 'Are you sure you wanto to use this Template? This will add the Categories and Items contained in this Template to the current Inspection.',
    failure: 'Failed to create Categories & Items',
    running: 'Creating Categories & Items from Template',
    success: 'Successfully created Categories & Items',
  };

  private operationSaveInspectionAsTemplate: OperationFilter = {
    title: 'Save Inspection As Template',
    description: 'Creates a new Template with the Categories and Items contained in this Inspection.',
    failure: 'Failed to create Template',
    running: 'Creating Template from Inspection',
    success: 'Successfully created Template',
  };

  private operationDuplicateTemplateCategory: OperationFilter = {
    title: 'Duplicate Template Category',
    description: 'Duplicating this Template Category will copy all Template Items (Tests & Questions) contained in this Template Category.',
    failure: 'Failed to duplicate Template Category',
    running: 'Creating duplicate Template Category',
    success: 'Successfully duplicated Template Category',
  };

  private operationDuplicateTemplateItem: OperationFilter = {
    title: 'Duplicate Template Item',
    description: 'Duplicating this Template Item will copy its Title, Mode and Type.',
    failure: 'Failed to duplicate Template Item',
    running: 'Creating duplicate Template Item',
    success: 'Successfully duplicated Template Item',
  };

  /**
   * Strings for operations (duplicate inspection, duplicate category, duplicate item, etc.)
   * @param type
   * @returns
   */
  operationFilter(type: OperationType): OperationFilter | undefined {
    switch (type) {
      case OperationType.DuplicateInspection:
        return this.operationDuplicateInspection;
      case OperationType.DuplicateCategory:
        return this.operationDuplicateCategory;
      case OperationType.DuplicateItem:
        return this.operationDuplicateItem;
      case OperationType.DuplicateTemplate:
        return this.operationDuplicateTemplate;
      case OperationType.Reinspection:
        return this.operationReinspection;
      case OperationType.CreateInspectionFromTemplate:
        return this.operationCreateInspectionFromTemplate;
      case OperationType.CreateCategoriesFromTemplate:
        return this.operationCreateCategoriesFromTemplate;
      case OperationType.SaveInspectionAsTemplate:
        return this.operationSaveInspectionAsTemplate;
      case OperationType.DuplicateTemplateCategory:
        return this.operationDuplicateTemplateCategory;
      case OperationType.DuplicateTemplateItem:
        return this.operationDuplicateTemplateItem;
      default:
        return undefined;
    }
  }
}
