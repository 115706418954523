import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { User } from "cip";
import { MemberEnhanced } from "src/app/models/member/member.model";

@Component({
  selector: "manage-member-edit-header",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./manage-member-edit-header.component.html",
  styleUrl: "./manage-member-edit-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageMemberEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) member!: MemberEnhanced;
  @Input({ required: true }) user!: User;
}
