import { NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SortablejsModule } from "nxt-sortablejs";
import { Options } from "sortablejs";
import { PhotoEnhanced } from "src/app/models/photo/photo.model";
@Component({
  selector: "test-form-photos",
  standalone: true,
  imports: [SortablejsModule, NgClass, NgStyle, RouterModule],
  templateUrl: "./test-form-photos.component.html",
  styleUrl: "./test-form-photos.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestFormPhotosComponent {
  @Input({ required: true }) photos!: PhotoEnhanced[];
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) categoryId!: string;
  @Input({ required: true }) itemId!: string;
  @Output() reorderPhotosOutput = new EventEmitter<PhotoEnhanced[]>();
  @Output() deletePhotoOutput = new EventEmitter<{ photoId: string; photos: PhotoEnhanced[] }>();
  eventOptions: Options = {
    onUpdate: () => this.reorderPhotos(),
  };

  /**
   * Reorder Photos
   */
  reorderPhotos() {
    this.reorderPhotosOutput.emit(this.photos);
  }

  /**
   * Delete Photo
   * @param photoId
   */
  public deletePhoto(photoId: string) {
    this.deletePhotoOutput.emit({ photoId, photos: this.photos });
  }

  public annotatePhoto(photoId: string) {
    this;
  }
}
