import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadcrumbsComponent } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'inspection-category-edit-header',
  standalone: true,
  imports: [BreadcrumbsComponent],
  templateUrl: './inspection-category-edit-header.component.html',
  styleUrl: './inspection-category-edit-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionCategoryEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) categoryId!: string;
  @Output() duplicateCategoryOutput = new EventEmitter<void>();

  /**
   * Duplicate Category
   */
  public duplicateCategory(): void {
    this.duplicateCategoryOutput.emit();
  }
}
