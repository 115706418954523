import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { RoleCurrent } from "src/app/models/role/role.model";
import { InfoViewFilter } from "src/app/models/strings/strings.model";
import { InfoViewComponent } from "../../info-view/info-view.component";

@Component({
  selector: "role-field",
  standalone: true,
  imports: [ReactiveFormsModule, InfoViewComponent, NgClass],
  templateUrl: "./role-field.component.html",
  styleUrl: "./role-field.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleFieldComponent {
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) currentRole: RoleCurrent | null = null;
  @Input({ required: true }) memberDoc!: MemberEnhanced | null;
  @Input({ required: true }) infoViewMemberEditor!: InfoViewFilter;
  @Output() toggleRoleSelectorOutput = new EventEmitter<void>();

  /**
   * Toggle Role Selector
   */
  public toggleRoleSelector(): void {
    this.toggleRoleSelectorOutput.emit();
  }
}
