<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <form [formGroup]="folderForm" class="flex flex-col justify-between" aria-label="Folder creation form">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">{{ isNewFolder ? "New Folder" : "Edit Folder " }}</h3>
        </div>

        <div class="px-6 py-8">
          <div class="block w-full space-y-4 rounded-md bg-white p-6 shadow-sm dark:bg-gray-800">
            <div>
              <div class="form-input-wrapper flex flex-row items-center gap-x-4">
                <div [ngStyle]="{ color: folderForm.value.colour }">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="ml-2.5 h-6 w-6 sm:ml-0" aria-label="Folder icon">
                    <path
                      d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
                  </svg>
                </div>
                <div class="flex w-full flex-col">
                  <label for="folder-title" class="form-input-label"
                    >Title*
                    @if (!folderForm.pristine && folderForm.controls["title"].errors) {
                      <span class="required-field"> - Title is required</span>
                    }
                  </label>
                  <input #folderTitle type="text" name="folder-title" id="folder-title" class="form-input" placeholder="Title" formControlName="title" required />
                </div>
              </div>
              @if (folderForm.value.created_by !== null) {
                <div class="footer-text">Created by {{ folderForm.value.created_by }} on {{ folderForm.value.date_created.toDate() | date: "dd/MM/yyyy HH:mm" }}</div>
              }
            </div>

            <fieldset aria-label="Folder color selection">
              <div class="-ml-2 -mr-2 grid grid-cols-10 gap-4">
                @for (colour of folderColours; track $index) {
                  <div class="flex w-full items-center justify-center">
                    <label
                      id="{{ colour }}"
                      class="cip-transition col-span-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-2 hover:opacity-75"
                      [ngStyle]="{ 'border-color': colour === folderForm.value.colour ? folderForm.value.colour : 'transparent' }">
                      <input id="{{ colour }}" type="radio" #folderColour [value]="colour" class="sr-only" attr.aria-labelledby="{{ colour }}" attr.aria-label="{{ colour }} color option" formControlName="colour" />
                      <span aria-hidden="true" class="h-6 w-6 rounded-full" [ngStyle]="{ 'background-color': colour }"></span>
                    </label>
                  </div>
                }
              </div>
              <p class="footer-text">Assign a colour to help categorise your Folders</p>
            </fieldset>
          </div>
        </div>
        <div class="flex bg-gray-100 px-6 py-6" [ngClass]="isNewFolder ? 'justify-end' : 'justify-between'">
          @if (!isNewFolder) {
            <button class="btn btn-destructive" (click)="deleteFolder()">Delete Folder</button>
          }
          <div class="flex flex-row-reverse">
            <button type="button" (click)="saveFolder()" class="btn" [ngClass]="folderForm.pristine || folderForm.controls['title'].errors || !limitationResult ? 'btn-disabled' : 'btn-primary'" aria-label="Save folder changes">Save</button>
            <button type="button" (click)="toggleEditFolderOverlay()" class="btn btn-cancel mr-3" aria-label="Cancel folder editing">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
