import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostListener, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { User } from "cip";
import { getAuth, updateEmail } from "firebase/auth";
import { Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { extractErrorCode } from "src/app/core/util/extract-error-code";
import { extractErrorMessage } from "src/app/core/util/extract-error-message";
import { InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";

type EmailErrorInfo = {
  code: string;
  message: string;
};

@Component({
  selector: "account-email",
  standalone: true,
  imports: [ReactiveFormsModule, FormStateComponent, NgClass, InfoViewComponent],
  templateUrl: "./account-email.component.html",
  styleUrl: "./account-email.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountEmailComponent {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  // Services
  private infoViewStringsService = inject(InfoViewStringsService);
  private profileService = inject(ProfileService);
  private authService = inject(AuthService);
  private formFormattingService = inject(FormFormattingService);

  // Properties
  public emailForm: FormGroup;
  public infoViewEmailEditor2: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.EmailEditor2);
  public emailError: boolean = false;
  public emailErrorInfo!: EmailErrorInfo;
  public emailUpdated: boolean = false;
  public user: User;

  constructor() {
    this.user = this.authService.currentUser;
    this.emailForm = new FormGroup({
      newEmail: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), this.formFormattingService.noWhitespaceValidator()]),
      confirmNewEmail: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), this.formFormattingService.noWhitespaceValidator()]),
    });
  }

  async saveNewEmail() {
    const auth = getAuth();
    try {
      if (auth.currentUser) {
        await updateEmail(auth.currentUser, this.emailForm.value.newEmail);
        await this.updateUserDocument();
      }
    } catch (err) {
      this.emailError = true;
      this.emailErrorInfo = {
        code: extractErrorCode(err),
        message: extractErrorMessage(err),
      };
    }
  }

  async updateUserDocument() {
    try {
      await this.profileService.saveUserEmail(this.emailForm.value.newEmail, this.user.user_id);
      this.user = this.authService.currentUser;
      this.emailUpdated = true;
      this.emailError = false;
      this.hideSuccessfulUpdateBox();
      this.emailForm.markAsPristine();
      this.emailForm.reset();
    } catch (error) {
      console.log("failed to update document - ", error);
      alert(error);
    }
  }

  public hideSuccessfulUpdateBox() {
    this.emailUpdated = false;
  }

  get newEmail() {
    return this.emailForm.get("newEmail");
  }

  get confirmNewEmail() {
    return this.emailForm.get("confirmNewEmail");
  }

  disablePaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  /**
   * Is Form Dirty
   * @returns
   */
  isFormDirty() {
    return this.emailForm.dirty ? false : true;
  }
}
