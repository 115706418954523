import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "manage-theme-edit-settings-toggle-photo",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./manage-theme-edit-settings-toggle-photo.component.html",
  styleUrl: "./manage-theme-edit-settings-toggle-photo.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsTogglePhotoComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  public toggleContent: boolean = false;

  public photoSizes: { value: number; name: string }[] = [
    { value: 0, name: "Extra Small" },
    { value: 1, name: "Small" },
    { value: 2, name: "Regular" },
    { value: 3, name: "Large" },
    { value: 4, name: "Extra Large" },
  ];

  public photoQuality: { value: number; name: string }[] = [
    { value: 0, name: "Low" },
    { value: 100, name: "Maximum" },
  ];
  public toggleContentOptions(): void {
    this.toggleContent = !this.toggleContent;
  }
}
