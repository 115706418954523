@if (reportsService.reportData.inspection; as inspection) {
  <div id="report-cover">
    <div id="cover-wrapper">
      <div class="cover-right">
        <div class="cover-top">
          @if (reportsService.themeDetails.value.details_workspace) {
            @if (reportsService.reportData.workspace; as workspace) {
              <div class="cover-workspace-wrapper">
                <div class="cover-workspace-name" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ workspace.name }}</div>
                @if (workspace.image_thumbnail_url) {
                  <div class="cover-workspace-logo">
                    <img [src]="workspace.image_thumbnail_url" />
                  </div>
                }
              </div>
            }
          }
          <div class="cover-inspection-wrapper">
            <p
              class="cover-inspection-title"
              [ngStyle]="{
                color: reportsService.themeDetails.value.colours_brand,
                'font-size':
                  inspection.title.length > 0 && inspection.title.length <= 16
                    ? '45pt'
                    : inspection.title.length > 16 && inspection.title.length <= 33
                      ? '39pt'
                      : inspection.title.length > 33 && inspection.title.length <= 45
                        ? '30pt'
                        : inspection.title.length > 45 && inspection.title.length <= 90
                          ? '22.5pt'
                          : '',
              }">
              {{ inspection.title }}
            </p>

            <div [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">
              @if (inspection.ref) {
                <p class="cover-inspection-reference">{{ inspection.ref }}</p>
              }
              @if (inspection.date) {
                <p class="cover-inspection-date">{{ inspection.date.toDate() | dateFormat: reportsService.themeDetails.value.dates_order : reportsService.themeDetails.value.dates_style }}</p>
              }
              @if (inspection.site_title) {
                <p class="cover-inspection-site">Inspection Site TItle</p>
              }
              @if (reportsService.themeDetails.value.details_overall_score) {
                <p class="cover-inspection-score">TODO Inspection Score</p>
              }
            </div>
          </div>
          @if (reportsService.reportData.actions; as actions) {
            @if (actions.length > 0) {
              @if (reportsService.themeDetails.value.details_action) {
                <div class="cover-action-wrapper">
                  <p class="cover-action-count" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ actions.length }} {{ reportsService.reportData.actions.length > 0 ? "Actions" : "Action" }}</p>
                  <div class="cover-action-complete text-group-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path
                        [ngStyle]="{ stroke: reportsService.themeDetails.value.colours_subtitle }"
                        d="M15.9409 8.22857C15.9409 12.4891 12.4871 15.9429 8.22662 15.9429C3.96614 15.9429 0.512332 12.4891 0.512332 8.22857C0.512332 3.96809 3.96614 0.514286 8.22662 0.514286C12.4871 0.514286 15.9409 3.96809 15.9409 8.22857Z"
                        stroke-width="1.02857" />
                      <path [ngStyle]="{ stroke: reportsService.themeDetails.value.colours_subtitle }" d="M4.79814 8.34429L7.10023 10.6466L11.5758 6.17139" stroke-width="1.32888" />
                    </svg>
                    <p [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ actions | actionCount }} Complete</p>
                  </div>
                  <div class="cover-action-incomplete text-group-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path
                        d="M15.9418 8.22857C15.9418 12.4891 12.488 15.9429 8.22747 15.9429C3.96699 15.9429 0.513187 12.4891 0.513187 8.22857C0.513187 3.96809 3.96699 0.514286 8.22747 0.514286C12.488 0.514286 15.9418 3.96809 15.9418 8.22857Z"
                        [ngStyle]="{ stroke: reportsService.themeDetails.value.colours_subtitle }"
                        stroke-width="1.02857" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.04168 8.1596L11.5266 5.61472L10.6928 4.80054L8.22726 7.32553L5.76204 4.80083L4.92821 5.61501L7.41285 8.1596L4.79639 10.8392L5.63021 11.6534L8.22726 8.99366L10.8246 11.6537L11.6584 10.8395L9.04168 8.1596Z"
                        [ngStyle]="{ fill: reportsService.themeDetails.value.colours_subtitle }" />
                    </svg>
                    <p [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ actions.length - (actions | actionCount) }} Incomplete</p>
                  </div>
                </div>
              }
            }
          }
        </div>
        <div class="cover-bottom">
          @if (reportsService.themeDetails.value.details_client) {
            @if (reportsService.reportData.client; as client) {
              <div class="cover-client-wrapper">
                @if (client.image_thumbnail_url) {
                  <div class="cover-client-logo">
                    <img [src]="client.image_thumbnail_url" />
                  </div>
                }
                <div class="cover-client-inner">
                  @if (client.name) {
                    <p class="cover-client-name" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ client.name }}</p>
                  }
                  @if (client.branch) {
                    <p class="cover-client-branch" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">{{ client.branch }}</p>
                  }
                </div>
              </div>
            }
          }
          @if (reportsService.themeDetails.value.details_user) {
            @if (user) {
              <div class="cover-user-wrapper">
                <p class="cover-user-name" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">Inspected By {{ user.name_first }} {{ user.name_last }}</p>
              </div>
            }
          }
        </div>
      </div>
    </div>
  </div>
}
