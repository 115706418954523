<header>
  <!-- Dummy breadcrumb element-->
  <div class="h-[28px] px-6 pb-2"></div>

  <!-- Header -->
  <div class="mx-auto max-w-7xl px-6 pb-5 sm:px-8">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Members</h2>
      </div>
      <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
        <button type="button" class="btn btn-primary" (click)="routeToInviteEditor()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Member
        </button>
      </div>
    </div>
  </div>
</header>
