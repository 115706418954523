@if (assignees$ | async; as assignees) {
  <div class="flex min-w-0 flex-1">
    <div class="flex h-full min-w-0 flex-1 flex-col overflow-hidden">
      @if (assigneesBulkEditArray$ | async; as assigneesBulkEditArray) {
        <manage-assignees-list-header
          [workspaceId]="workspaceId"
          [assigneesCount]="assignees.length"
          [editMode]="editMode"
          [assigneesBulkEditArray]="assigneesBulkEditArray"
          (searchTermChange)="searchTermChange($event)"
          (toggleNewAssigneeOverlayOutput)="canUserCreateAssignee()"
          (toggleEditModeOutput)="toggleEditMode()"
          (bulkDeleteAssigneesOutput)="setDeleteBatchAssigneesProperties()" />

        @if (assignees.length > 0) {
          <manage-assignees-list
            [assignees]="assignees"
            [workspaceId]="workspaceId"
            [searchTerm]="currentSearchTerm"
            [editMode]="editMode"
            [assigneesBulkEditArray]="assigneesBulkEditArray"
            (deleteSingleAssigneeOutput)="setDeleteSingleAssigneeProperties($event)"
            (createBatchEditOutput)="createBatchEdit($event)"
            (routeToAssigneeEditorOutput)="routeToAssigneeEditor($event)" />
        } @else {
          <empty-list [list]="'assignee'" />
        }
      }
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteAssignee()) {
  <delete-overlay
    [deleteOverlayButton]="'Remove'"
    [deleteOverlayTitle]="deleteOverlayTitle"
    [deleteOverlayDescription]="deleteOverlayDescription"
    (deleteButtonClicked)="deleteSingleOrMultipleAssignees()"
    (cancelButtonClicked)="toggleDeleteAssigneeOverlay(false)" />
}

@if (overlay_newAssignee) {
  <manage-assignee-new-overlay [assigneeForm]="assigneeForm" (toggleNewAssigneeOverlayOutput)="toggleNewAssigneeOverlay()" (saveAssigneeOutput)="saveAssignee()" />
}
