<form [formGroup]="categoryForm" class="grid gap-x-4 gap-y-6">
  <div class="sm:col-span-6">
    <div class="form-input-wrapper">
      <label for="category-title" class="form-input-label"
        >Title*
        @if (!categoryForm.pristine && categoryForm.controls['title'].errors) {
          <span class="required-field"> - Title is required</span>
        }
      </label>
      <input type="text" name="category-title" id="category-title" class="form-input" placeholder="Title" formControlName="title" />
    </div>
  </div>
  <div class="sm:col-span-6">
    <div class="form-input-wrapper">
      <label for="category-notes" class="form-input-label flex flex-row items-center">
        <span class="flex flex-row gap-x-2">
          <span>Notes</span>
          <tooltip [toolTipText]="toolTipCategoryNotes" />
        </span>
      </label>
      <textarea type="text" name="category-notes" id="category-notes" class="form-input" placeholder="Notes" formControlName="notes"></textarea>
    </div>
  </div>
  <div class="relative flex items-start sm:col-span-6">
    <label for="enabled" class="flex cursor-pointer flex-row items-center">
      <div class="flex h-5">
        <input id="enabled" aria-describedby="enabled" formControlName="enabled" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
      </div>
      <div class="ml-3 text-sm">Enabled</div>
    </label>
  </div>
</form>
