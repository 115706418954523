import { DecimalPipe, NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartsActionResults } from 'src/app/models/charts/charts-action.model';

@Component({
  selector: 'charts-actions',
  standalone: true,
  imports: [NgClass, NgStyle, DecimalPipe],
  templateUrl: './charts-actions.component.html',
  styleUrl: './charts-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartsActionsComponent {
  @Input() actions!: ChartsActionResults[];
}
