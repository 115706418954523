@if (dataReady) {
  <div class="flex">
    <!-- Left Column -->
    <div class="-mt-[39px] h-[calc(100vh-64px)] w-[360px] flex-shrink-0 overflow-y-scroll overscroll-contain bg-white px-4">
      <report-settings-wrapper [workspaceId]="workspaceId" [inspectionId]="inspectionId" [themes]="themes" />
    </div>

    <!-- Right Column -->
    <div class="-mt-[39px] h-[calc(100vh-64px)] flex-grow overflow-y-scroll overscroll-contain px-4">
      <div class="mx-auto max-w-7xl px-6 pb-10 pt-10 xl:px-0">
        <header>
          <!-- Breadcrumbs-->
          <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId"></breadcrumbs>

          <!-- Header -->
          <div class="page-header-wrapper">
            <div class="md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="page-header">Generate Report</h2>
              </div>
            </div>
          </div>
        </header>
      </div>

      <!-- bg-[#F1F4F7]-->
      <div class="px-4"></div>
      <report-content-wrapper />
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}
