import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { WorkspaceEnhanced } from 'src/app/models/workspace/workspace.model';
import { LoadingSpinnerComponent } from 'src/app/shared/loading-spinner/loading-spinner.component';

@Component({
  selector: 'your-workspaces',
  standalone: true,
  imports: [LoadingSpinnerComponent, RouterLink, NgStyle],
  templateUrl: './your-workspaces.component.html',
  styleUrl: './your-workspaces.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YourWorkspacesComponent {
  @Input({ required: true }) workspaces: WorkspaceEnhanced[] | null = null;
  @Output() leaveWorkspaceOutput = new EventEmitter<WorkspaceEnhanced>();

  /**
   * Toggle Leave Workspace Overlay
   */
  toggleLeaveWorkspaceOverlay(workspace: WorkspaceEnhanced) {
    this.leaveWorkspaceOutput.emit(workspace);
  }
}
