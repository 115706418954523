import { inject, Injectable } from "@angular/core";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { TemplateEnhanced } from "src/app/models/template/template.model";

@Injectable({
  providedIn: "root",
})
export class TemplatesListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Templates List
   * @param workspaceId
   */
  public getTemplatesList$(workspaceId: string): Observable<TemplateEnhanced[]> {
    const path = this.collectionsService.templatesCol(workspaceId);
    const queryConstraints = [where("is_deleted", "==", false), orderBy("title", "asc")];
    return this.firestoreUtilsService.getCollectionData<TemplateEnhanced>(path, queryConstraints);
  }
}
