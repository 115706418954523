import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { InspectionEnhanced } from "src/app/models/inspection/inspection.model";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { InspectionDetailService } from "../../../services/inspection-detail.service";
import { InspectionEditDetailsComponent } from "../../inspection-edit-details/inspection-edit-details.component";
import { InspectionDetailsClientWrapperComponent } from "../inspection-details-client/inspection-details-client-wrapper/inspection-details-client-wrapper.component";
import { InspectionDetailsFolderWrapperComponent } from "../inspection-details-folder/inspection-details-folder-wrapper/inspection-details-folder-wrapper.component";
import { InspectionDetailsHeaderComponent } from "../inspection-details-header/inspection-details-header.component";
import { InspectionDetailsSiteWrapperComponent } from "../inspection-details-site/inspection-details-site-wrapper/inspection-details-site-wrapper.component";

@Component({
  selector: "inspection-details-wrapper",
  standalone: true,
  imports: [
    InspectionDetailsHeaderComponent,
    FormStateComponent,
    LoadingSpinnerComponent,
    NgClass,
    AsyncPipe,
    InspectionEditDetailsComponent,
    InspectionDetailsSiteWrapperComponent,
    JsonPipe,
    InspectionDetailsFolderWrapperComponent,
    InspectionDetailsClientWrapperComponent,
  ],
  templateUrl: "./inspection-details-wrapper.component.html",
  styleUrl: "./inspection-details-wrapper.component.scss",
})
export class InspectionDetailsWrapperComponent implements OnInit {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  // Services
  private inspectionDetailService = inject(InspectionDetailService);
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private formFormattingService = inject(FormFormattingService);
  private router = inject(Router);

  // Properties
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public inspection$!: Observable<InspectionEnhanced>;
  public inspectionForm!: FormGroup;
  public user: User;

  constructor() {
    // User
    this.user = this.authService.currentUser;

    // Inspection Document Form
    this.inspectionForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      ref: null,
      date: null,
      template_title: null,
      template_id: null,
      site_title: null,
      site_id: null,
      site_photo_thumbnail_url: null,
      folder_id: null,
      folder_title: null,
      client_id: null,
      client_name: null,
      date_created: null,
      overall_score: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getInspectionDoc();
  }

  /**
   * Get Inspection Doc
   */
  private async getInspectionDoc(): Promise<void> {
    try {
      this.inspection$ = this.inspectionDetailService.getInspectionDoc$(this.workspaceId, this.inspectionId).pipe(
        filter((data) => !!data),
        tap((data) => {
          if (this.inspectionForm.pristine && this.inspectionForm.untouched) {
            const date = data.date?.toDate();
            const inspection = { ...data, date };
            this.inspectionForm.patchValue(inspection);
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Inspection
   */
  public async saveInspection(): Promise<void> {
    try {
      const inspection = await this.inspectionDetailService.updateInspectionDoc(this.workspaceId, this.folderId, this.inspectionId, this.inspectionForm.value, this.user);
      this.resetInspectionForm();
      this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", inspection.id, "overview"]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Reset Inspection Form
   */
  private resetInspectionForm(): void {
    this.inspectionForm.reset();
    this.inspectionForm.markAsPristine();
    this.inspectionForm.markAsUntouched();
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.inspectionForm.dirty ? false : true;
  }
}
