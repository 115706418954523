import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'search-bar',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent {
  @Input({ required: true }) placeholder!: string;
  @Output() searchTermChange = new EventEmitter<string>();
  public searchTerm: string = '';

  /**
   * On Search
   */
  onSearch() {
    this.searchTermChange.emit(this.searchTerm);
  }
}
