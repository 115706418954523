<form [formGroup]="actionForm">
  <div class="content-card-wrapper">
    <div class="content-card-header">
      <h3>Complete</h3>
    </div>
    <div class="px-6 py-5">
      <div class="content-grid gap-x-4 gap-y-6">
        <div class="flex">
          <div class="flex h-5 items-center">
            <input id="action-completed" aria-describedby="complete" formControlName="complete" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" />
          </div>
          <div class="ml-3 text-sm">
            <label for="action-completed" class="font-medium text-gray-700 dark:text-slate-300">Complete</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
