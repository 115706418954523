import { Pipe, PipeTransform } from "@angular/core";
import { ActionEnhanced } from "src/app/models/action/action.model";

@Pipe({
  name: "actionCount",
  standalone: true,
})
export class ActionCountPipe implements PipeTransform {
  transform(actions: ActionEnhanced[]): number {
    if (!actions) {
      return 0;
    }

    return actions.filter((action) => action.complete).length;
  }
}
