import { NgStyle } from "@angular/common";
import { Component, inject } from "@angular/core";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-content-introduction",
  standalone: true,
  imports: [NgStyle],
  templateUrl: "./report-content-introduction.component.html",
  styleUrl: "./report-content-introduction.component.scss",
})
export class ReportContentIntroductionComponent {
  public reportsService = inject(ReportsService);
}
