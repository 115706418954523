<header>
  <!-- Breadcrumbs-->
  <div class="px-6 pb-2">
    <nav aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-2">
        <li>
          <div class="flex items-center">
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'members']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Members</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>

  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Edit Member</h2>
      </div>
      <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
        @if (member.id === user.user_id) {
          <div class="ml-0 flex flex-shrink-0 flex-col sm:ml-3 sm:flex-row">
            <a [routerLink]="['/', 'workspace', workspaceId, 'account', 'profile']" class="btn btn-secondary"> Edit Profile </a>
          </div>
        }
      </div>
    </div>
  </div>
</header>
