<div>
  <div class="flex h-8 items-center justify-between">
    <span class="pb-2 text-xs uppercase tracking-wider text-text_Subtitle" style="padding-bottom: 0">Answer</span>
    @if (testForm.value.question_Date !== null) {
      <div class="group mb-2 flex cursor-pointer flex-row items-center hover:opacity-90" (click)="removeAnswer()">
        <div class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
          </svg>
        </div>
        <div class="ml-1 text-xs font-bold text-brand_Destructive">Remove</div>
      </div>
    }
  </div>

  <form [formGroup]="testForm" class="sm:col-span-6">
    <div class="form-input-wrapper" id="cip-date-picker">
      <label for="question_date" class="form-input-label">Date </label>
      <div class="sm:col-span-1">
        <div class="flex justify-between">
          <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date" class="form-input" formControlName="question_date" id="question_date" />
        </div>
        <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
      </div>
    </div>
  </form>
</div>
