import { AsyncPipe, JsonPipe } from "@angular/common";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "cip";
import { filter, Observable, Subscription } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { WorkspaceEnhanced } from "src/app/models/workspace/workspace.model";
import { UserNameFormComponent } from "src/app/shared/user-name-form/user-name-form.component";
import { environment } from "src/environments/environment";
import { WorkspacesService } from "../../services/workspaces.service";

import { Router } from "@angular/router";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { InviteAcceptDecline } from "src/app/models/invite/invite-accept-decline.model";
import { InviteEnhanced } from "src/app/models/invite/invite.model";
import { IncorrectFileType } from "src/app/models/photo/photo-incorrect-file";
import { PhotoType } from "src/app/models/photo/photo-type";
import { SinglePhotoUpload } from "src/app/models/photo/photo-upload.model";
import { MainMenuSignOutOverlayComponent } from "src/app/shared/main-menu/main-menu-sign-out-overlay/main-menu-sign-out-overlay.component";
import { InviteDetailService } from "../../current-workspace/manage-members/services/invite-detail.service";
import { InvitesListService } from "../../current-workspace/manage-members/services/invites-list.service";
import { CreateWorkspaceComponent } from "../create-workspace/create-workspace.component";
import { InvitedWorkspacesComponent } from "../invited-workspaces/invited-workspaces.component";
import { LeaveWorkspaceWrapperComponent } from "../leave-workspace-wrapper/leave-workspace-wrapper.component";
import { YourWorkspacesComponent } from "../your-workspaces/your-workspaces.component";
@Component({
  selector: "all-workspaces-wrapper",
  standalone: true,
  imports: [UserNameFormComponent, YourWorkspacesComponent, AsyncPipe, JsonPipe, LeaveWorkspaceWrapperComponent, InvitedWorkspacesComponent, CreateWorkspaceComponent, MainMenuSignOutOverlayComponent],
  templateUrl: "./all-workspaces-wrapper.component.html",
  styleUrl: "./all-workspaces-wrapper.component.scss",
})
export class AllWorkspacesWrapperComponent implements OnInit, OnDestroy {
  // Services
  public authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private workspacesService = inject(WorkspacesService);
  private invitesListService = inject(InvitesListService);
  private inviteDetailService = inject(InviteDetailService);
  private router = inject(Router);
  private formFormattingService = inject(FormFormattingService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  // Properties
  public user!: User;
  public currentAppVersion = environment.version;
  public newAccountForm = false;
  public workspaceToLeave: WorkspaceEnhanced | null = null;
  public isInviteActionProcessing = false;
  private inviteSubscription!: Subscription;
  public newWorkspaceId!: string;

  // Observables
  public workspaces$!: Observable<WorkspaceEnhanced[]>;
  public invites$!: Observable<InviteEnhanced[]>;

  // Overlays
  public overlay_newWorkspace = false;
  public overlay_leaveWorkspace = false;

  // Forms
  public signUpForm: FormGroup;
  public workspaceForm: FormGroup;

  // Photos
  public uploadFileArray: SinglePhotoUpload[] = [];
  public incorrectFileTypeArray: IncorrectFileType[] = [];
  public photoPreview: boolean = false;
  public overlay_uploadPhotos = false;
  private tempBlobPhotoURL!: string;

  constructor() {
    this.user = this.authService.currentUser;
    this.signUpForm = this.fb.group({
      name_first: ["", Validators.required],
      name_last: ["", Validators.required],
      terms: [false, Validators.requiredTrue],
      privacy: [false, Validators.requiredTrue],
    });
    this.workspaceForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      name: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      image_url: null,
      image_thumbnail_url: null,
      website: ["", this.formFormattingService.noWhitespaceValidator()],
      created_by: null,
      created_by_id: null,
      current_owner: null,
      current_owner_id: null,
    });
    if (this.user.name_first === undefined || this.user.name_first === "") {
      this.newAccountForm = true;
    }
  }

  ngOnInit(): void {
    this.getWorkspacesList();
    this.getInvites();
  }

  ngOnDestroy(): void {
    this.inviteSubscription?.unsubscribe();
    this.revokeObjectURL();
  }

  /**
   * Get Workspaces
   */
  private async getWorkspacesList(): Promise<void> {
    this.workspaces$ = this.workspacesService.getWorkspacesList$(this.user.user_id).pipe(filter((data) => !!data));
  }

  /**
   * Get Invites
   */
  private async getInvites(): Promise<void> {
    this.invites$ = this.invitesListService.getInvitesList$(this.user.email).pipe(filter((data) => !!data));
  }

  /**
   * Update New User Doc
   */
  public async updateNewUserDoc() {
    try {
      this.authService.createUserDoc(this.signUpForm, this.user.email);
      this.newAccountForm = false;
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Leave Workspace Overlay
   * @param workspace
   */
  public setWorkspaceToLeave(workspace: WorkspaceEnhanced) {
    this.workspaceToLeave = workspace;
    this.toggleLeaveWorkspaceOverlay();
  }

  /**
   * Leave Workspace
   */
  public async leaveWorkspace(): Promise<void> {
    if (this.workspaceToLeave) {
      await this.workspacesService.leaveWorkspace(this.workspaceToLeave.id, this.user);
      this.toggleLeaveWorkspaceOverlay();
      this.router.navigate(["/workspaces"]);
    }
  }

  /**
   * Join Or Decline Invite And Subscribe
   * @param inviteProperties
   */
  public async joinOrDeclineInviteAndSubscribe(inviteProperties: InviteAcceptDecline): Promise<void> {
    try {
      const invite = await this.invitesListService.joinOrDeclineInvite(inviteProperties);
      this.subscribeToInvite(invite);
    } catch (error) {
      alert(error);
    } finally {
      this.isInviteActionProcessing = true;
    }
  }

  /**
   * Subscribe To Invite
   * @param invite
   */
  subscribeToInvite(invite: InviteEnhanced): void {
    this.inviteSubscription = this.inviteDetailService.getInviteDoc$(invite.id).subscribe((result: InviteEnhanced | null) => {
      if (!result) {
        this.isInviteActionProcessing = true;
      } else {
        this.isInviteActionProcessing = false;
      }
    });
  }

  /**
   * Check If Photos Are Uploading Or Create Workspace
   */
  public async checkIfPhotosAreUploadingOrCreateWorkspace(): Promise<void> {
    if (this.uploadFileArray.length > 0) {
      this.togglePhotosUploadOverlay();
    } else {
      this.createWorkspaceDoc();
    }
  }

  /**
   * Patch Form With Photos And Save Doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    if (photos.type === "workspace") {
      this.workspaceForm.patchValue({
        image_url: photos.image_url,
        image_thumbnail_url: photos.image_thumbnail_url,
      });
    }
    await this.createWorkspaceDoc();
    this.togglePhotosUploadOverlay();
    this.emptyUploadFileArray();
    this.revokeObjectURL();
  }

  /**
   * Create Workspace Doc
   */
  private async createWorkspaceDoc(): Promise<void> {
    try {
      await this.workspacesService.createWorkspaceDoc(this.workspaceForm.getRawValue(), this.newWorkspaceId, this.user);
      const route = ["/", "workspace", this.newWorkspaceId, "folders"];
      this.router.navigate(route);
      this.resetForm();
      this.toggleCreateWorkspaceOverlay();
      this.togglePhotosUploadOverlay();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  public async detectPhotos(files: FileList): Promise<void> {
    const file = files[0];
    const fileType = file.type;
    const fileName = file.name;
    const fileExtension = fileType.split("/").pop();
    if (!(fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png")) {
      const incorrectFile: IncorrectFileType = { title: fileName };
      this.incorrectFileTypeArray.push(incorrectFile);
    } else {
      this.uploadFileArray = [];
      this.togglePhotoPreview();
      this.workspaceForm.markAsDirty();
      this.tempBlobPhotoURL = URL.createObjectURL(file);
      const newId = this.firestoreUtilsService.createFirestoreId();
      this.uploadFileArray.push({
        id: newId,
        photo: this.tempBlobPhotoURL,
      });
    }
  }

  /**
   * Remove Photo From Array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    const indexOfObject = this.uploadFileArray.findIndex((object) => {
      return object.id === id;
    });
    const file = this.uploadFileArray.splice(indexOfObject, 1);
    this.togglePhotoPreview();
    URL.revokeObjectURL(file[0].photo);
    if (this.uploadFileArray.length < 1) {
      this.photoPreview = false;
    }
  }

  /**
   * Toggle Photo Preview
   */
  private togglePhotoPreview(): void {
    this.photoPreview = !this.photoPreview;
  }

  /**
   * Toggle Photos Upload Overlay
   */
  private togglePhotosUploadOverlay(): void {
    this.overlay_uploadPhotos = !this.overlay_uploadPhotos;
  }

  /**
   * Toggle Leave Workspace Overlay
   */
  public toggleLeaveWorkspaceOverlay(): void {
    this.overlay_leaveWorkspace = !this.overlay_leaveWorkspace;
  }

  /**
   * Toggle Create Workspace Overlay
   */
  public toggleCreateWorkspaceOverlay(): void {
    if (!this.overlay_newWorkspace) {
      this.newWorkspaceId = this.firestoreUtilsService.createFirestoreId();
    }
    this.overlay_newWorkspace = !this.overlay_newWorkspace;
  }

  /**
   * Reset Form
   */
  private resetForm(): void {
    this.workspaceForm.markAsPristine();
    this.workspaceForm.markAsUntouched();
  }

  /**
   * Empty Upload File Array
   */
  private emptyUploadFileArray(): void {
    this.uploadFileArray = [];
  }

  /**
   * Revoke Object URL
   */
  private revokeObjectURL(): void {
    URL.revokeObjectURL(this.tempBlobPhotoURL);
  }
}
