import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemEnhanced } from 'src/app/models/item/item.model';

@Component({
  selector: 'inspection-items-list-question-types',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './inspection-items-list-question-types.component.html',
  styleUrl: './inspection-items-list-question-types.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionItemsListQuestionTypesComponent {
  @Input({ required: true }) item!: ItemEnhanced;
}
