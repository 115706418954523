import { JsonPipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { QuestionBoolComponent } from "../question-bool/question-bool.component";
import { QuestionDateComponent } from "../question-date/question-date.component";
import { QuestionFormTitleComponent } from "../question-form-title/question-form-title.component";
import { QuestionNumberComponent } from "../question-number/question-number.component";
import { QuestionStringComponent } from "../question-string/question-string.component";
import { QuestionTimeComponent } from "../question-time/question-time.component";

@Component({
  selector: "question-form-wrapper",
  standalone: true,
  imports: [ReactiveFormsModule, QuestionBoolComponent, QuestionDateComponent, QuestionNumberComponent, QuestionStringComponent, QuestionTimeComponent, QuestionFormTitleComponent, JsonPipe],
  templateUrl: "./question-form-wrapper.component.html",
  styleUrl: "./question-form-wrapper.component.scss",
})
export class QuestionFormWrapperComponent {
  @Input({ required: true }) testForm!: FormGroup;
  @Input({ required: true }) currentCategoryOrder!: number;
  @Output() enableOrDisableTestOutput = new EventEmitter<void>();

  public enableOrDisableTest(): void {
    this.enableOrDisableTestOutput.emit();
  }
}
