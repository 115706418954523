import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateFormat",
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  /**
    - ORDER
    case daymonthyear = 0 /// 21/09/2023
    case monthdayyear = 1 /// 09/21/2023
    case yearmonthday = 2 /// 2023/09/21

    - STYLE
    case numeric = 0 /// 21/09/2023
    case abbreviated = 1 /// 21 Sep, 2023
    case long = 2 /// 21 September, 2023
    case complete = 3 /// Thursday, 21 September, 2023
    **/

  transform(date: Date, dateOrder: number, dateStyle: number): string {
    const userLocale: string = navigator.language;

    const order = dateOrder;
    const style = dateStyle;

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    let formattedDate = "";

    if (order === 0 && style === 0) {
      // DAY/MONTH/YEAR - numeric (21/09/2023)
      formattedDate = `${day}/${month}/${year}`;
    }
    if (order === 0 && style === 1) {
      // DAY/MONTH/YEAR - abbreviated (21 Sep, 2023)
      formattedDate = `${day} ${date.toLocaleString(userLocale, { month: "short" })}, ${year}`;
    }
    if (order === 0 && style === 2) {
      // DAY/MONTH/YEAR - long (21 September, 2023)
      formattedDate = `${day} ${date.toLocaleString(userLocale, { month: "long" })}, ${year}`;
    }
    // if (order === 0 && style === 3) {
    //   // DAY/MONTH/YEAR - complete (Thursday, 21 September, 2023)
    //   formattedDate = `${date.toLocaleString(userLocale, { weekday: 'long' })}, ${day} ${date.toLocaleString(userLocale, { month: 'long' })}, ${year}`;
    // }
    if (order === 1 && style === 0) {
      // MONTH/DAY/YEAR - numeric (09/21/2023)
      formattedDate = `${month}/${day}/${year}`;
    }
    if (order === 1 && style === 1) {
      // MONTH/DAY/YEAR - abbreviated (Sep 21, 2023)
      formattedDate = `${date.toLocaleString(userLocale, { month: "short" })} ${day}, ${year}`;
    }
    if (order === 1 && style === 2) {
      // MONTH/DAY/YEAR - long (September 21, 2023)
      formattedDate = `${date.toLocaleString(userLocale, { month: "long" })} ${day}, ${year}`;
    }
    // if (order === 1 && style === 3) {
    //   // MONTH/DAY/YEAR - complete (September, Thursday 21, 2023)
    //   formattedDate = `${date.toLocaleString(userLocale, { month: 'long' })}, ${date.toLocaleString(userLocale, { weekday: 'long' })} ${day}, ${year}`;
    // }
    if (order === 2 && style === 0) {
      // YEAR/MONTH/DAY - numeric (2023/09/21)
      formattedDate = `${year}/${month}/${day}`;
    }
    if (order === 2 && style === 1) {
      // YEAR/MONTH/DAY - abbreviated (2023, Sep 21)
      formattedDate = `${year}, ${date.toLocaleString(userLocale, { month: "short" })} ${day}`;
    }
    if (order === 2 && style === 2) {
      // YEAR/MONTH/DAY - long (2023, September 21)
      formattedDate = `${year}, ${date.toLocaleString(userLocale, { month: "long" })} ${day}`;
    }
    // if (order === 2 && style === 3) {
    //   // YEAR/MONTH/DAY - complete (2023 September, Thursday 21)
    //   formattedDate = `${year} ${date.toLocaleString(userLocale, { month: 'long' })}, ${date.toLocaleString(userLocale, { weekday: 'long' })} ${day}`;
    // }

    return formattedDate;
  }
}
