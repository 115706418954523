import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { User } from "cip";
import { ActionEditAssigneeSelectorWrapperComponent } from "../action-edit-assignee-selector-wrapper/action-edit-assignee-selector-wrapper.component";

@Component({
  selector: "action-edit-assignee-wrapper",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, AsyncPipe, ActionEditAssigneeSelectorWrapperComponent],
  templateUrl: "./action-edit-assignee-wrapper.component.html",
  styleUrl: "./action-edit-assignee-wrapper.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEditAssigneeWrapperComponent {
  @Input({ required: true }) actionForm!: FormGroup;
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) user!: User;

  // Properties
  public overlay_assignee: boolean = false;

  /**
   * Toggle Assignee selector Overlay
   */
  public toggleAssigneeSelectorOverlay(): void {
    this.overlay_assignee = !this.overlay_assignee;
  }
}
