@if (item$ | async; as item) {
  <inspection-item-edit-header [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [categoryId]="categoryId" [itemId]="itemId" [item]="item" (duplicateItemOutput)="toggleAndSetDuplicateItemOverlay(item)" />

  <inspection-item-edit-form
    [itemForm]="itemForm"
    [item]="item"
    [itemId]="itemId"
    [infoViewItemEditorTest]="infoViewItemEditorTest"
    [infoViewItemEditorQuestion]="infoViewItemEditorQuestion"
    (saveItemAndRerouteOutput)="saveItemAndReRoute(item)"
    (saveItemAndAddAnotherOutput)="saveItemAndAddAnother(item)"
    (saveItemAndAddDifferentModeOutput)="saveItemAndAddDifferentMode(item)"
    (deleteItemOutput)="setDeleteSingleItemProperties(item)" />
} @else {
  <loading-spinner />
}

<!-- Duplicate -->
@if (duplicationService.overlay_duplicate) {
  <duplicate-overlay
    [duplicationObject]="duplicationObject"
    [originalDocumentTitle]="itemToBeDuplicated.title + ' ' + '(Copy)'"
    [duplicationTitle]="duplicationTitle"
    [duplicationDescription]="duplicationDescription"
    [inProgressText]="inProgressText"
    [successText]="successText"
    [failedText]="failedText"
    [navigateToText]="navigateToText"
    (duplicateEmitter)="duplicateItem($event)"
    (navigateToNewDocEmitter)="navigateToNewitem()" />
}

<!-- Delete -->

@if (overlay_deleteItem) {
  <delete-overlay [deleteOverlayTitle]="deleteTitle" [deleteOverlayDescription]="deleteDescription" (cancelButtonClicked)="toggleDeleteItemOverlay()" (deleteButtonClicked)="deleteSingleItem()" />
}
