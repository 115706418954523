<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block h-[650px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between overscroll-contain">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">
            {{ overlay_siteForm ? "Add Site" : "Select Site" }}
          </h3>
        </div>

        <!-- Site Selection-->
        @if (!overlay_siteForm) {
          <!-- List -->
          <div class="flex h-full flex-1 overflow-hidden" [formGroup]="inspectionForm">
            <div class="w-full space-y-4 overflow-y-scroll overscroll-contain">
              @if (sites) {
                <div class="mt-4 flex w-full flex-col px-12 sm:flex-row" [ngClass]="sites.length > 0 ? 'justify-between' : 'justify-end'">
                  @if (sites.length > 0) {
                    <search-bar [placeholder]="'Search Sites'" (searchTermChange)="onSearch($event)" />
                  }
                  <button type="button" class="btn btn-primary" (click)="toggleNewSiteForm()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Site
                  </button>
                </div>

                <!-- Sites List -->
                <div class="px-6 py-8">
                  <!-- Selected Site -->
                  @if (currentlySelectedSite) {
                    <div class="mb-10 block">
                      <div class="mb-3 flex items-center justify-between">
                        <div class="container-header" style="padding-bottom: 0">Current Site</div>
                        <div class="cip-transition mr-8 flex cursor-pointer flex-row items-center rounded-full bg-red-50 px-2 py-1 text-xs text-red-800 hover:bg-red-200" (click)="unselectSite()">
                          <div class="mr-2 rounded-full bg-red-400 p-1 text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-2.5 w-2.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                          Unselect
                        </div>
                      </div>
                      <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
                        <div class="mb-4">
                          <div class="list-row">
                            <div class="list-a group cursor-pointer border-none" (click)="siteSelection(currentlySelectedSite)">
                              @if (currentlySelectedSite.image_thumbnail_url) {
                                <div class="flex h-12 w-12 overflow-hidden rounded-md bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + currentlySelectedSite.image_thumbnail_url + ')' }"></div>
                              } @else {
                                <div class="flex h-12 w-12 items-center justify-center rounded-md bg-gray-100 text-text_Subtitle">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                  </svg>
                                </div>
                              }

                              <!-- Left and Right Content -->
                              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                                <!-- Left Content -->
                                <div class="min-w-0 flex-auto">
                                  <p class="list-title">{{ currentlySelectedSite.title }}</p>
                                  <p class="list-meta search-ref">{{ currentlySelectedSite.ref }}</p>
                                  @if (currentlySelectedSite.what_3_words) {
                                    <div class="list-meta flex flex-row items-center">
                                      <div class="mr-1 text-[#e11f26]">///</div>
                                      {{ currentlySelectedSite.what_3_words }}
                                    </div>
                                  }
                                </div>

                                <!-- Right Content -->
                                <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                                  <div class="sm:flex sm:flex-col sm:items-end">
                                    <pre class="list-meta search-address font-sans">{{ currentlySelectedSite.address }}</pre>
                                  </div>
                                </div>
                              </div>
                              <div class="flex items-center text-brand_Primary">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
                                  <path
                                    fill-rule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                    clip-rule="evenodd" />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <!-- New Selection -->
                  @if (sites.length > 0 && !allSitesHidden) {
                    <div class="mb-3 flex items-center justify-between">
                      <div class="container-header" style="padding-bottom: 0">Select New Site</div>
                    </div>
                  }
                  <ul id="sitesList">
                    @if (allOtherSites.length > 0) {
                      <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
                        @for (site of filteredSites; track $index; let last = $last) {
                          @if (!site.hidden) {
                            <li [ngClass]="last ? 'border-none' : 'border-b'">
                              <div class="list-row">
                                <!-- List Editing Tools -->
                                @if (inspectionForm.value.site_id !== site.id) {
                                  <div class="list-a group cursor-pointer" (click)="siteSelection(site)">
                                    @if (site.image_thumbnail_url) {
                                      <div class="flex h-12 w-12 overflow-hidden rounded-md bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + site.image_thumbnail_url + ')' }"></div>
                                    } @else {
                                      <div class="flex h-12 w-12 items-center justify-center rounded-md bg-gray-100 text-text_Subtitle">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                        </svg>
                                      </div>
                                    }

                                    <!-- Left and Right Content -->
                                    <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                                      <!-- Left Content -->
                                      <div class="min-w-0 flex-auto">
                                        <p class="list-title">{{ site.title }}</p>
                                        <p class="list-meta search-ref">{{ site.ref }}</p>
                                        @if (site.what_3_words) {
                                          <div class="list-meta flex flex-row items-center">
                                            <div class="mr-1 text-[#e11f26]">///</div>
                                            {{ site.what_3_words }}
                                          </div>
                                        }
                                      </div>

                                      <!-- Right Content -->
                                      <div class="mt-1 flex items-center gap-x-6 sm:mt-0">
                                        <div class="sm:flex sm:flex-col sm:items-end">
                                          <pre class="list-meta search-address font-sans">{{ site.address }}</pre>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            </li>
                          }
                        }
                      </div>
                    } @else {
                      @if (!currentlySelectedSite) {
                        <empty-list [list]="'site'" />
                      }
                    }
                  </ul>
                </div>

                @if (allSitesHidden && searchTerm !== "") {
                  <search-empty [searchTerm]="searchTerm" />
                }
              } @else {
                <div class="flex h-full items-center justify-center">
                  <loading-spinner />
                </div>
              }
            </div>
          </div>

          <!-- Actions -->
          <div class="flex justify-end bg-gray-100 px-6 py-6">
            <button type="button" class="btn btn-cancel" (click)="cancelButton()">Close</button>
          </div>
        }

        <!-- New Site -->
        @if (overlay_siteForm) {
          <div class="flex h-full w-full flex-col overflow-hidden">
            <div class="w-full flex-1 overflow-y-scroll">
              <div class="grid gap-y-8 px-6 py-5">
                <div class="content-card-wrapper">
                  <div class="content-card-header">
                    <h3>Details</h3>
                  </div>
                  <div class="px-6 py-5">
                    <div class="content-grid gap-x-4 gap-y-6">
                      <!-- Details -->

                      <manage-site-edit-details [siteForm]="siteForm" />

                      <div>
                        <div class="form-input-label mb-2 px-3">Site Photo</div>
                        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div class="sm:col-span-6">
                            <!-- Image Dropzone -->
                            @if (!siteForm.value.image_thumbnail_url && uploadFileArray.length < 1) {
                              <photo-dropzone [uploadSourceComponent]="'site'" (detectPhotosOutput)="detectPhotos($event)" />
                            }

                            <!-- Image Preview -->
                            @if (photoPreview && uploadFileArray.length > 0) {
                              <photo-preview [photosToPreview]="uploadFileArray" [uploadLimit]="1" [uploadSourceComponent]="'site'" (removePhotoFromArrayOutput)="removePhotoFromArray($event)" />
                            }

                            <!-- Image Uploading-->
                            @if (overlay_uploadPhotos) {
                              @for (photo of uploadFileArray; track photo.id) {
                                <photo-upload-single
                                  [file]="photo.photo"
                                  [path]="'workspaces/' + workspaceId + '/sites' + newSiteId"
                                  [fileType]="'jpeg'"
                                  [largeMaxWidth]="2000"
                                  [largeMaxHeight]="2000"
                                  [thumbnailMaxWidth]="300"
                                  [thumbnailMaxHeight]="300"
                                  [uploadSourceComponent]="'site'"
                                  (photoDataOutput)="patchFormWithPhotosAndSaveDoc($event)" />
                              }
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-card-wrapper">
                  <div class="content-card-header">
                    <h3>Location</h3>
                  </div>
                  <div class="px-6 py-5">
                    <div class="content-grid gap-x-4 gap-y-6">
                      <manage-site-edit-location [siteForm]="siteForm" [inspectionOrSite]="'inspection'" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Actions -->

            <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
              <button type="button" class="btn ml-3" [disabled]="siteForm.pristine || siteForm.invalid" [ngClass]="siteForm.pristine || siteForm.invalid ? 'btn-disabled' : 'btn-primary'" (click)="saveSite()">Save</button>
              <button type="button" class="btn btn-cancel" (click)="toggleNewSiteForm()">Cancel</button>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
