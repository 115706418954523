import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FolderEnhanced } from 'src/app/models/folder/folder.model';
import { EmptyListComponent } from 'src/app/shared/empty-list/empty-list.component';
import { LoadingSpinnerComponent } from 'src/app/shared/loading-spinner/loading-spinner.component';
import { SearchBarComponent } from 'src/app/shared/search-bar/search-bar.component';
import { SearchEmptyComponent } from 'src/app/shared/search-empty/search-empty.component';

@Component({
  selector: 'inspection-details-folder-selector',
  standalone: true,
  imports: [ReactiveFormsModule, NgStyle, NgClass, LoadingSpinnerComponent, EmptyListComponent, SearchBarComponent, SearchEmptyComponent],
  templateUrl: './inspection-details-folder-selector.component.html',
  styleUrl: './inspection-details-folder-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionDetailsFolderSelectorComponent implements OnChanges {
  @Input() folders: FolderEnhanced[] | undefined | null;
  @Input({ required: true }) currentlySelectedFolder!: FolderEnhanced | null;
  @Input({ required: true }) allOtherFolders: Array<FolderEnhanced> = [];
  @Input({ required: true }) inspectionForm!: FormGroup;
  @Output() cancelSiteSelectionOutput = new EventEmitter<void>();
  @Output() folderSelectionOuput = new EventEmitter<FolderEnhanced>();
  @Output() toggleNewFolderFormOutput = new EventEmitter<void>();

  // Properties
  public filteredFolders: Array<FolderEnhanced> = [];
  public allFoldersHidden: boolean = false;
  public searchTerm: string = '';

  /**
   * ngOnChanges
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allOtherFolders']) {
      this.applySearch();
    }
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredFolders = this.allOtherFolders.map((folder) => ({
      ...folder,
      hidden: !folder.title.toLowerCase().includes(lowerSearchTerm),
    }));

    this.allFoldersHidden = this.filteredFolders.every((folder) => folder.hidden);
  }

  /**
   * Flder Selection
   * @param folder
   */
  public folderSelection(folder: FolderEnhanced): void {
    this.folderSelectionOuput.emit(folder);
  }

  /**
   * Toggle New Folder Form
   */
  public toggleNewFolderForm(): void {
    this.toggleNewFolderFormOutput.emit();
  }

  /**
   * Cancel Button
   */
  public cancelButton(): void {
    this.cancelSiteSelectionOutput.emit();
  }
}
