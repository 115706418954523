<div class="content-card-wrapper">
  <div class="content-card-header flex items-center justify-between">
    <h3>Details</h3>
    <button (click)="routeToTemplateEditor()" class="btn-secondary-small-default">Edit Details</button>
  </div>
  <div class="px-6 py-5">
    <div class="content-grid gap-y-3">
      <div>
        <div class="text-sm text-text_Subtitle">Reference</div>
        <div class="text-base text-text_Title">{{ template.ref }}</div>
      </div>
      <div>
        <div class="text-sm text-text_Subtitle">Version</div>
        <div class="text-base text-text_Title">{{ template.version }}</div>
      </div>
      @if (template.date_created) {
        <div>
          <div class="text-sm text-text_Subtitle">Created</div>
          <div class="text-base text-text_Title">{{ template.date_created.toDate() | date: "dd MMM yyyy" }}</div>
        </div>
      }
      @if (template.date_updated) {
        <div>
          <div class="text-sm text-text_Subtitle">Updated</div>
          <div class="text-base text-text_Title">{{ template.date_updated.toDate() | date: "dd MMM yyyy" }}</div>
        </div>
      }
      @if (template.created_by) {
        <div>
          <div class="text-sm text-text_Subtitle">Created by</div>
          <div class="text-base text-text_Title">{{ template.created_by }}</div>
        </div>
      }
    </div>
  </div>
</div>
