// Generated by CoffeeScript 2.4.1
(function () {
  var NodeType, XMLDTDEntity, XMLNode, isObject;
  ({
    isObject
  } = require('./Utility'));
  XMLNode = require('./XMLNode');
  NodeType = require('./NodeType');

  // Represents an entity declaration in the DTD
  module.exports = XMLDTDEntity = function () {
    class XMLDTDEntity extends XMLNode {
      // Initializes a new instance of `XMLDTDEntity`

      // `parent` the parent `XMLDocType` element
      // `pe` whether this is a parameter entity or a general entity
      //      defaults to `false` (general entity)
      // `name` the name of the entity
      // `value` internal entity value or an object with external entity details
      // `value.pubID` public identifier
      // `value.sysID` system identifier
      // `value.nData` notation declaration
      constructor(parent, pe, name, value) {
        super(parent);
        if (name == null) {
          throw new Error("Missing DTD entity name. " + this.debugInfo(name));
        }
        if (value == null) {
          throw new Error("Missing DTD entity value. " + this.debugInfo(name));
        }
        this.pe = !!pe;
        this.name = this.stringify.name(name);
        this.type = NodeType.EntityDeclaration;
        if (!isObject(value)) {
          this.value = this.stringify.dtdEntityValue(value);
          this.internal = true;
        } else {
          if (!value.pubID && !value.sysID) {
            throw new Error("Public and/or system identifiers are required for an external entity. " + this.debugInfo(name));
          }
          if (value.pubID && !value.sysID) {
            throw new Error("System identifier is required for a public external entity. " + this.debugInfo(name));
          }
          this.internal = false;
          if (value.pubID != null) {
            this.pubID = this.stringify.dtdPubID(value.pubID);
          }
          if (value.sysID != null) {
            this.sysID = this.stringify.dtdSysID(value.sysID);
          }
          if (value.nData != null) {
            this.nData = this.stringify.dtdNData(value.nData);
          }
          if (this.pe && this.nData) {
            throw new Error("Notation declaration is not allowed in a parameter entity. " + this.debugInfo(name));
          }
        }
      }

      // Converts the XML fragment to string

      // `options.pretty` pretty prints the result
      // `options.indent` indentation for pretty print
      // `options.offset` how many indentations to add to every line for pretty print
      // `options.newline` newline sequence for pretty print
      toString(options) {
        return this.options.writer.dtdEntity(this, this.options.writer.filterOptions(options));
      }
    }
    ;

    // DOM level 1
    Object.defineProperty(XMLDTDEntity.prototype, 'publicId', {
      get: function () {
        return this.pubID;
      }
    });
    Object.defineProperty(XMLDTDEntity.prototype, 'systemId', {
      get: function () {
        return this.sysID;
      }
    });
    Object.defineProperty(XMLDTDEntity.prototype, 'notationName', {
      get: function () {
        return this.nData || null;
      }
    });

    // DOM level 3
    Object.defineProperty(XMLDTDEntity.prototype, 'inputEncoding', {
      get: function () {
        return null;
      }
    });
    Object.defineProperty(XMLDTDEntity.prototype, 'xmlEncoding', {
      get: function () {
        return null;
      }
    });
    Object.defineProperty(XMLDTDEntity.prototype, 'xmlVersion', {
      get: function () {
        return null;
      }
    });
    return XMLDTDEntity;
  }.call(this);
}).call(this);