import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, HostListener, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { ActionEnhanced } from "src/app/models/action/action.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ActionDetailService } from "../../services/action-detail.service";
import { ActionEditAssigneeWrapperComponent } from "../action-edit-assignee/action-edit-assignee-wrapper/action-edit-assignee-wrapper.component";
import { ActionEditCompleteComponent } from "../action-edit-complete/action-edit-complete.component";
import { ActionEditDetailsComponent } from "../action-edit-details/action-edit-details.component";
import { ActionEditPriorityComponent } from "../action-edit-priority/action-edit-priority.component";

@Component({
  selector: "action-edit-wrapper",
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    LoadingSpinnerComponent,
    ActionEditDetailsComponent,
    ActionEditPriorityComponent,
    ActionEditAssigneeWrapperComponent,
    ActionEditCompleteComponent,
    FormStateComponent,
    NgClass,
    ReactiveFormsModule,
    DeleteOverlayComponent,
  ],
  templateUrl: "./action-edit-wrapper.component.html",
  styleUrl: "./action-edit-wrapper.component.scss",
})
export class ActionEditWrapperComponent implements OnInit {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.isFormDirty();
  }

  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  @Input({ required: true }) set categoryId(value: string) {
    this._categoryId = value;
  }

  get categoryId(): string {
    return this._categoryId;
  }

  @Input({ required: true }) set itemId(value: string) {
    this._itemId = value;
  }

  get itemId(): string {
    return this._itemId;
  }

  @Input({ required: true }) set actionId(value: string) {
    this._actionId = value;
  }

  get actionId(): string {
    return this._actionId;
  }

  // Services
  private actionDetailService = inject(ActionDetailService);
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private formFormattingService = inject(FormFormattingService);
  private router = inject(Router);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);

  // Properties
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public _categoryId!: string;
  public _itemId!: string;
  public _actionId!: string;
  public action$!: Observable<ActionEnhanced>;
  public actionForm: FormGroup;
  public user: User;
  public overlay_deleteAction = signal<boolean>(false);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";

  constructor() {
    // User
    this.user = this.authService.currentUser;

    // Action Form
    this.actionForm = this.fb.group({
      id: [{ value: null, disabled: true }],
      title: ["", [Validators.required, this.formFormattingService.noWhitespaceValidator()]],
      notes: null,
      order: null,
      priority: null,
      due_date: [null, []],
      complete: null,
      assignee_name: null,
      assignee_email: null,
      assignee_id: null,
      assignee_type: null,
      assignee_company: null,
      item_id: null,
      item_title: null,
      category_id: null,
      category_title: null,
      inspection_id: null,
      inspection_title: null,
      created_by: null,
      created_by_id: null,
    });
  }

  ngOnInit(): void {
    this.getActionDoc();
  }

  /**
   * Get Action Doc
   */
  private async getActionDoc(): Promise<void> {
    try {
      this.action$ = this.actionDetailService.getActionDoc$(this.workspaceId, this.actionId).pipe(
        filter((data) => !!data),
        tap((data) => {
          if (this.actionForm.pristine && this.actionForm.untouched) {
            const due_date = data.due_date?.toDate();
            const action = { ...data, due_date };
            this.actionForm.patchValue(action);
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Save Action Doc
   */
  public async saveActionDoc() {
    try {
      await this.actionDetailService.saveActionDoc(this.user, this.workspaceId, this.inspectionId, this.categoryId, this.itemId, this.actionId, this.actionForm.value);
      this.actionForm.markAsPristine();
      this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", this.itemId]);
      this.actionForm.reset();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Set Delete Overlay Properties
   */
  public async setDeleteOverlayProperties(): Promise<void> {
    const featureAction: keyof RolePermissions = "action_delete";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.toggleDeleteOverlay(true);
      const deleteAction = this.stringsService.alertFilter(AlertType.DeleteAction);
      if (deleteAction) {
        this.deleteOverlayTitle = deleteAction.title;
        this.deleteOverlayDescription = deleteAction.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteAction);
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Action
   */
  public async deleteAction(): Promise<void> {
    try {
      this.actionDetailService.deleteActionDoc(this.user, this.workspaceId, this.inspectionId, this.itemId, this.actionId);
      this.toggleDeleteOverlay(false);
      this.router.navigate(["/workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", this.itemId]);
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Toggle Delete Overlay
   * @param value
   */
  public toggleDeleteOverlay(value: boolean): void {
    this.overlay_deleteAction.set(value);
  }

  /**
   * Close Panel
   */
  closePanel() {
    const route = ["workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, "categories", this.categoryId, "items", this.itemId];
    this.router.navigate(route);
  }

  /**
   * Is Form Dirty
   * @returns true or false
   * If the form is dirty return false
   * If the form isn't dirty return true
   */
  private isFormDirty(): boolean {
    return this.actionForm.dirty ? false : true;
  }
}
