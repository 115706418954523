import { Component, inject, OnDestroy } from "@angular/core";
import { AppCheck } from "@angular/fire/app-check";
import { RouterOutlet } from "@angular/router";
import { AppUpdateService } from "./core/services/app-update.service";
import { AuthService } from "./core/services/auth.service";
import { ConnectedDevicesService } from "./core/services/connected-devices.service";
import { AppUpdateComponent } from "./shared/app-update/app-update.component";
@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, AppUpdateComponent],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnDestroy {
  private authService = inject(AuthService);
  public appUpdateService = inject(AppUpdateService);
  private connectedDevicesService = inject(ConnectedDevicesService);
  private appCheck = inject(AppCheck);

  ngOnDestroy(): void {
    this.connectedDevicesService.unsubscribeFromDeviceList();
    this.connectedDevicesService.resetConnectedDevicesArray();
  }
}
