import { AsyncPipe, DatePipe, JsonPipe } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import axios from "axios";
import { Export, User } from "cip";
import { getAuth } from "firebase/auth";
import { filter, Observable, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { environment } from "src/environments/environment";
import { UserExportService } from "../services/user-export.service";
@Component({
  selector: "account-my-data",
  standalone: true,
  imports: [AsyncPipe, JsonPipe, LoadingSpinnerComponent, InfoViewComponent, DatePipe],
  templateUrl: "./account-my-data.component.html",
  styleUrl: "./account-my-data.component.scss",
})
export class AccountMyDataComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private authService = inject(AuthService);
  private userExportService = inject(UserExportService);

  // Properties
  public exportData$!: Observable<Export[]>;
  public hasBeenRequestedInLastDay: boolean = false;
  public requestUserDataProcessing: boolean = false;
  public requestUserDataError: boolean = false;
  public requestUserDataStatus: string = "";
  public requestUserDataErrorText: string = "";
  public requestUserDataUrl: string = "";
  public timeLeftUntilNextExport: string = "";
  public requestCreatedAt: Date = new Date();
  private user: User;
  private _workspaceId!: string;

  constructor() {
    this.user = this.authService.currentUser;
  }

  ngOnInit() {
    this.getExportDoc();
  }

  /**
   * Get Export Doc
   */
  private async getExportDoc(): Promise<void> {
    try {
      this.exportData$ = this.userExportService.getExportDoc$(this.user.user_id).pipe(
        filter((data) => !!data),
        tap((data) => {
          if (data) {
            if (data.length > 0) {
              const doc = data[0];
              const status = doc.info.status;
              this.requestCreatedAt = doc.created_at.toDate();
              this.requestUserDataStatus = status;
              this.calculateTimeUntilNextExport(doc.created_at.toDate());

              if (status === "done") {
                if (doc.info.url) {
                  this.requestUserDataUrl = doc.info.url;
                }
                this.hasBeenRequestedInLastDay = true;
                this.requestUserDataProcessing = false;
              }

              if (status === "error") {
                this.requestUserDataError = true;
                if (doc.info.error) {
                  this.requestUserDataErrorText = doc.info.error;
                }
              }

              if (status === "pending" || status === "running") {
                this.requestUserDataProcessing = true;
              }
            } else {
              this.hasBeenRequestedInLastDay = false;
            }
          }
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Set Export Doc
   */
  public async setExportDoc(): Promise<void> {
    try {
      const auth = getAuth();
      const idToken = await auth.currentUser?.getIdToken(true);
      if (!idToken) {
        throw new Error("User not authenticated");
      }

      const cloudServicesURL = environment.production ? "https://main-pwz6sst7ka-ew.a.run.app" : "https://main-wf57supnuq-ew.a.run.app";

      await axios.request({
        url: `${cloudServicesURL}/v1/export_user_data/${environment.firebase.projectId}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        data: {},
      });
    } catch (error) {
      console.error("Failed to initiate export:", error);
      throw error;
    }
  }

  /**
   * Calculate Time Until Next Export
   * @param createdDate
   */
  private calculateTimeUntilNextExport(createdDate: Date) {
    const now = new Date();
    const nextAvailable = new Date(createdDate.getTime() + 24 * 60 * 60 * 1000);
    const diffMs = nextAvailable.getTime() - now.getTime();
    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    this.timeLeftUntilNextExport = `${hours}h ${minutes}m`;
  }
}
