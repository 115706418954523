import { NgTemplateOutlet } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { ErrorString } from "src/app/models/reports/report-error.model";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-generation-overlay",
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: "./report-settings-generation-overlay.component.html",
  styleUrl: "./report-settings-generation-overlay.component.scss",
})
export class ReportSettingsGenerationOverlayComponent {
  @Input() reportError!: ErrorString;

  public reportsService = inject(ReportsService);
}
