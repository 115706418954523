import { Injectable } from "@angular/core";
import { SwUpdate, VersionEvent } from "@angular/service-worker";

@Injectable({
  providedIn: "root",
})
export class AppUpdateService {
  updateAvailable = false;

  constructor(private swUpdate: SwUpdate) {
    this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type === "VERSION_READY") {
        this.updateAvailable = true;
      }
    });
  }
}
