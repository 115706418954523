<div (click)="navigateToThemeEditorOrSelectTheme(theme)" class="group w-full cursor-pointer">
  <div class="mb-4 flex w-full flex-row items-center border-b-2 pb-1">
    <div class="list-title text-lg text-text_Title group-hover:text-brand_Primary" [ngStyle]="{ 'border-bottom-color': theme.colours_content }">
      {{ theme.title }}
    </div>
    @if (theme.id === "default") {
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-3 h-5 w-5 text-text_Title">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
      </svg>
    }
  </div>

  <div class="flex w-full flex-row items-center gap-x-4">
    <!-- Content -->
    <div class="flex w-full flex-col gap-x-4 sm:flex-row">
      <!-- Left -->
      <div class="min-w-0 flex-auto">
        <div class="flex flex-row space-x-4">
          <div>
            <div class="h-[242px] w-[195px] border border-gray-300 bg-[#F9F9FB] p-2">
              <div class="border-b pb-2" [ngStyle]="{ 'border-bottom-color': theme.colours_content }">
                <p class="theme_list-text pb-1 text-xs" [ngStyle]="{ color: theme.colours_brand }">Title</p>
                <p class="theme_list-text text-xs" [ngStyle]="{ color: theme.colours_subtitle }">Example Text</p>
              </div>
              <div class="space-x-2 space-y-4" [ngClass]="'theme_list_photo_' + theme.photos_size">
                @for (index of getNumberOfImages(theme.photos_size); track $index; let first = $first) {
                  <div
                    class="theme_list_photo_wrapper"
                    [ngStyle]="{
                      'margin-top': first ? '16px' : '',
                    }">
                    <div class="photo"></div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="space-y-4">
            <div class="flex flex-row space-x-4">
              <div class="h-7 w-9 rounded-md border border-gray-300" [ngStyle]="{ 'background-color': theme.colours_brand }"></div>
              <div class="h-7 w-9 rounded-md border border-gray-300" [ngStyle]="{ 'background-color': theme.colours_subtitle }"></div>
              <div class="h-7 w-9 rounded-md border border-gray-300" [ngStyle]="{ 'background-color': theme.colours_content }"></div>
            </div>
            <div>
              @switch (theme.photos_size) {
                @case (0) {
                  <div class="list-meta">Extra Small Photos</div>
                }
                @case (1) {
                  <div class="list-meta">Small Photos</div>
                }
                @case (2) {
                  <div class="list-meta">Medium Photos</div>
                }
                @case (3) {
                  <div class="list-meta">Large Photos</div>
                }
                @case (4) {
                  <div class="list-meta">Extra Large Photos</div>
                }
              }

              @switch (theme.fonts_size) {
                @case (0) {
                  <div class="list-meta">Extra Small Text</div>
                }
                @case (1) {
                  <div class="list-meta">Small Text</div>
                }
                @case (2) {
                  <div class="list-meta">Medium Text</div>
                }
                @case (3) {
                  <div class="list-meta">Large Text</div>
                }
                @case (4) {
                  <div class="list-meta">Extra Large Text</div>
                }
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Icon - Default -->
    <div class="flex">
      <div class="mr-2.5 block h-full py-2.5 pl-2.5 text-text_Subtitle dark:text-gray-400 sm:mr-0" aria-expanded="false" aria-haspopup="true">
        @if (listLocation === "manageThemesList") {
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        }

        @if (themeDetails) {
          @if (themeDetails.id === theme.id && listLocation !== "manageThemesList") {
            <div class="flex items-center text-brand_Primary">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
                <path
                  fill-rule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clip-rule="evenodd" />
              </svg>
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>
