import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'search-empty',
  standalone: true,
  imports: [],
  templateUrl: './search-empty.component.html',
  styleUrl: './search-empty.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchEmptyComponent {
  @Input() searchTerm: string = '';
}
