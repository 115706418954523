import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemeEnhanced, ThemeStyleModel } from "src/app/models/theme/theme.model";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";
import { ThemesListComponent } from "src/app/shared/themes/themes-list/themes-list.component";

@Component({
  selector: "manage-themes-list",
  standalone: true,
  imports: [ThemesListComponent, SearchEmptyComponent],
  templateUrl: "./manage-themes-list.component.html",
  styleUrl: "./manage-themes-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemesListComponent {
  @Input({ required: true })
  set themes(value: ThemeEnhanced[]) {
    this._themes = value;
    this.updateFilteredThemesForSearchResults();
  }

  get themes(): ThemeEnhanced[] {
    return this._themes;
  }

  @Input({ required: true })
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredThemesForSearchResults();
  }

  @Input({ required: true }) editMode!: boolean;
  @Input() themesBulkEditArray: ThemeEnhanced[] | null = [];
  @Input() themeDetails!: ThemeStyleModel | null;
  @Output() deleteSingleThemeOutput = new EventEmitter<string>();
  @Output() createBatchEditOutput = new EventEmitter<ThemeEnhanced>();
  @Output() routeToThemeEditorOutput = new EventEmitter<ThemeEnhanced>();

  // Properties
  private _themes: ThemeEnhanced[] = [];
  public _searchTerm: string = "";
  public filteredThemes: ThemeEnhanced[] = [];
  public allThemesHidden: boolean = false;

  /**
   * Update Filtered Themes
   */
  updateFilteredThemesForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredThemes = this.themes.map((theme) => ({
      ...theme,
      hidden: !theme.title.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allThemesHidden = this.filteredThemes.every((theme) => theme.hidden);
  }

  /**
   * Create Batch Edit
   * @param Theme
   */
  public createBatchEdit(theme: ThemeEnhanced): void {
    this.createBatchEditOutput.emit(theme);
  }

  /**
   * Delete Single Theme
   * @param ThemeId
   */
  public deleteSingleTheme(themeId: string): void {
    this.deleteSingleThemeOutput.emit(themeId);
  }

  /**
   * Theme Selection
   * @param theme
   */
  public themeSelection(theme: ThemeEnhanced): void {
    this.routeToThemeEditorOutput.emit(theme);
  }
}
