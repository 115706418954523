<form [formGroup]="themeSettingsForm">
  <div class="border-b border-gray-200 py-6">
    <fieldset>
      <span class="mb-4 block text-sm font-medium text-gray-900">Photo</span>
      <div class="space-y-4">
        @for (photo of themeStylesByType["photo"]; track $index; let i = $index) {
          <div class="photos-center flex">
            <label class="photos-center flex cursor-pointer" for="photo{{ i }}">
              <input type="radio" class="h-4 w-4 border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="styles_photo" [value]="photo.id" id="photo{{ i }}" (change)="updateCustomThemeStyles()" />
              <span class="ml-3 cursor-pointer text-sm text-text_Title">{{ photo.title }}</span>
            </label>
          </div>
        }
      </div>
    </fieldset>
  </div>
</form>
