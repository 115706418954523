// TODO(kafkas): The @ts-ignore here won't be needed once TypeScript is upgraded to a more recent version.
// That requires upgrading Angular as well. The current version which is 4 majors behind latest, is not compatible with TS 5.4

export function extractErrorCode(e: unknown): string {
  // eslint-disable-next-line
  // @ts-ignore
  if (typeof e === 'object' && e !== null && 'code' in e && typeof e['code'] === 'string') {
    // eslint-disable-next-line
    // @ts-ignore
    return e['code'];
  }
  return 'unknown';
}
