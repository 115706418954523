<div class="mx-auto mb-28 max-w-[612pt] rounded-md bg-white shadow-sm">
  <div id="report-content">
    <div id="content-wrapper" [ngClass]="'font-size-' + themeSettingsForm.value.fonts_size">
      <!-- Front over -->
      <manage-theme-edit-preview-front-cover [themeSettingsForm]="themeSettingsForm" />

      <!-- Site Cover -->
      <manage-theme-edit-preview-site-cover [themeSettingsForm]="themeSettingsForm" />

      <!-- Charts Cover -->
      <manage-theme-edit-preview-charts-cover [themeSettingsForm]="themeSettingsForm" />

      <!-- Introduction / Supplementary-->
      @if (themeSettingsForm.value.details_introduction) {
        <manage-theme-edit-preview-supplementary [themeSettingsForm]="themeSettingsForm" [title]="'Introduction'" />
      }

      <!-- Categories / Items / Photos / Actions -->
      <manage-theme-edit-preview-category [themeSettingsForm]="themeSettingsForm" />

      <!-- Introduction / Supplementary-->
      @if (themeSettingsForm.value.details_summary) {
        <manage-theme-edit-preview-supplementary [themeSettingsForm]="themeSettingsForm" [title]="'Summary'" />
      }

      <!-- Signature -->
      @if (themeSettingsForm.value.details_signatures) {
        <manage-theme-edit-preview-signature [themeSettingsForm]="themeSettingsForm" />
      }
    </div>
  </div>
</div>
