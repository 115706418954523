@if (assignee$ | async; as assignee) {
  <manage-assignee-edit-header [workspaceId]="workspaceId" (inviteAsMemberOutput)="inviteAsMember()" />
  <div class="grid grid-cols-1 gap-y-8">
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="assigneeForm.pristine === true || !!assigneeForm.controls['name'].errors" />
      <button type="button" (click)="saveAssignee()" class="btn" [disabled]="assigneeForm.pristine || assigneeForm.invalid" [ngClass]="assigneeForm.pristine || assigneeForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
    </div>

    <info-view [type]="infoViewAssigneeEditorReport.type" [message]="infoViewAssigneeEditorReport.message" />
    <manage-assignee-edit-details [assigneeForm]="assigneeForm" />
    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      <button type="button" class="btn btn-destructive mr-3" (click)="canUserDeleteAssignee()">Remove Assignee</button>
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="assigneeForm.pristine === true || !!assigneeForm.controls['name'].errors"></form-state>
        <button type="button" (click)="saveAssignee()" class="btn" [disabled]="assigneeForm.pristine || assigneeForm.invalid" [ngClass]="assigneeForm.pristine || assigneeForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteAssignee) {
  <delete-overlay [deleteOverlayButton]="'Remove'" [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (deleteButtonClicked)="deleteAssignee()" (cancelButtonClicked)="toggleDeleteAssigneeOverlay()" />
}
