import { NgClass, NgSwitch } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
type InfoBarType = 'error' | 'info' | 'success' | 'warning';
@Component({
  selector: 'info-view',
  standalone: true,
  imports: [NgClass, NgSwitch],
  templateUrl: './info-view.component.html',
  styleUrl: './info-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoViewComponent {
  @Input({ required: true }) type!: InfoBarType;
  @Input({ required: true }) message!: string;
  @Input() clickable = false;
  @Input() link = '';

  public navigateToLink(): void {
    window.open(this.link, '_blank');
  }
}
