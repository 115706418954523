<header>
  <div class="px-6 pb-2">
    <nav aria-label="Breadcrumb">
      <ol role="list" class="flex items-center">
        <li>
          <div class="flex items-center">
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'templates']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Templates</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'templates', templateId, 'overview']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Template Overview</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Categories</h2>
      </div>
      <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
        @if (categories.length > 0 && !editMode) {
          <search-bar [placeholder]="'Search Categories'" (searchTermChange)="onSearch($event)" />
        }

        <div class="ml-0 flex flex-shrink-0 flex-col sm:ml-3 sm:flex-row">
          @if (categories.length > 0) {
            @if (categoriesBulkEditArray && categoriesBulkEditArray.length > 0) {
              <div class="flex">
                <button type="button" class="btn btn-secondary mr-3" aria-label="Bulk Delete" (click)="bulkDeleteCategories()">Bulk Delete</button>
              </div>
            }
            @if (categories.length > 0) {
              <button class="btn mr-3" aria-label="Edit Mode" (click)="toggleEditMode()" [ngClass]="editMode ? 'btn-primary' : 'btn-secondary'">
                {{ editMode ? "Done" : "Edit" }}
              </button>
            }
          }
          @if (!editMode) {
            <button type="button" class="btn btn-primary" aria-label="New Category" (click)="toggleNewCategory()">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Category
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</header>
