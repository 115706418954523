@if (actions$ | async; as actions) {
  @if (actionsBulkEditArray$ | async; as actionsBulkEditArray) {
    <div class="content-card-wrapper">
      <div class="content-card-header flex flex-col justify-between space-y-1">
        <div class="flex w-full flex-row justify-between">
          <h3>Actions</h3>
          <div class="flex flex-row">
            @if (actions.length > 0) {
              <button (click)="canUserEdit()" type="button" class="btn-secondary-small" [ngClass]="editMode ? 'btn-secondary-small-active' : 'btn-secondary-small-default'">
                {{ editMode ? "Done" : "Edit" }}
              </button>
            }
            @if (!editMode) {
              <button type="button" aria-label="Add Action " (click)="routeToAction('new')" class="btn-secondary-small-default ml-3">Add Action</button>
            }
          </div>
        </div>
        @if (actionsBulkEditArray.length > 0) {
          <div class="flex w-full flex-row justify-end">
            <button (click)="setBulkDeleteActionProperties()" type="button" aria-label="Bulk Delete" class="btn-secondary-small btn-secondary-small-default">Bulk Delete</button>
            <button (click)="toggleBulkActionCompleteOverlay()" type="button" aria-label="Bulk Complete / Incomplete" class="btn-secondary-small btn-secondary-small-default ml-3">Complete / Incomplete</button>
          </div>
        }
      </div>
      @if (actions.length > 0) {
        <div class="content-card px-6">
          <actions-list
            [actions]="actions"
            [editMode]="editMode"
            [priorityDict]="priorityDict"
            [actionsBulkEditArray]="actionsBulkEditArray"
            (createBatchEditOutput)="createBatchEdit($event)"
            (reorderActionsOutput)="reorderActions($event)"
            (deleteSingleActionOutput)="setDeleteSingleActionProperties($event)"
            (routeToActionOutput)="routeToAction($event)" />
        </div>
      } @else {
        <empty-list [list]="'action'" />
      }
    </div>
  }
} @else {
  <loading-spinner />
}

<!-- Delete Action-->
@if (overlay_deleteAction$ | async; as overlay_deleteAction) {
  @if (overlay_deleteAction) {
    <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (deleteButtonClicked)="deleteSingleOrMultipleActions()" (cancelButtonClicked)="toggleDeleteOverlay(false)"></delete-overlay>
  }
}

<!-- Actions Complete Incomplet -->
@if (overlay_completeIncompleteAction$ | async; as overlay_completeIncompleteAction) {
  @if (overlay_completeIncompleteAction) {
    <actions-list-complete-incomplete
      [completeOverlayTitle]="completeOverlayTitle"
      [completeOverlayDescription]="completeOverlayDescription"
      (cancelButtonClicked)="toggleCompleteActionOverlay(false)"
      (completeIncompleteOutput)="completeIncompleteBatchedActions($event)" />
  }
}

<!-- Action Editor Outlet -->
<router-outlet name="actionEditor"></router-outlet>
