import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ColorPickerModule, ColorPickerService, Rgba } from "ngx-color-picker";
export class Cmyk {
  constructor(
    public c: number,
    public m: number,
    public y: number,
    public k: number
  ) {}
}

@Component({
  selector: "manage-theme-edit-settings-colours",
  standalone: true,
  imports: [NgStyle, ReactiveFormsModule, ColorPickerModule],
  templateUrl: "./manage-theme-edit-settings-colours.component.html",
  styleUrl: "./manage-theme-edit-settings-colours.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsColoursComponent {
  // The form that holds the users theme settings
  @Input({ required: true }) themeSettingsForm!: FormGroup;

  // Colour Plugin Properties
  public cmyk: Cmyk = new Cmyk(0, 0, 0, 0);
  brandColourToggle = false;
  subTitleColourToggle = false;
  contentColourToggle = false;
  public toggleContent = false;

  private cpService = inject(ColorPickerService);

  onChangeColor(color: string): Cmyk {
    this.themeSettingsForm.markAsDirty();
    const colorAsHsva = this.cpService.stringToHsva(color);
    if (colorAsHsva === null) {
      return new Cmyk(0, 0, 0, 0);
    }
    return this.rgbaToCmyk(this.cpService.hsvaToRgba(colorAsHsva));
  }
  rgbaToCmyk(rgba: Rgba): Cmyk {
    const cmyk: Cmyk = new Cmyk(0, 0, 0, 0);
    const k = 1 - Math.max(rgba.r, rgba.g, rgba.b);
    if (k == 1) return new Cmyk(0, 0, 0, 1);
    cmyk.c = (1 - rgba.r - k) / (1 - k);
    cmyk.m = (1 - rgba.g - k) / (1 - k);
    cmyk.y = (1 - rgba.b - k) / (1 - k);
    cmyk.k = k;

    return cmyk;
  }

  /**
   * Colour Selections
   * @param color
   * When a colour is changed within the form, call any of these functions to set the appropriate value
   * Note: For some reason, without these, the values in the colour inputs are reset when any other form value is changed
   */
  setColourBrandOnForm(color: string): void {
    this.themeSettingsForm.controls["colours_brand"].setValue(color);
  }
  setColourSubTitleOnForm(color: string): void {
    this.themeSettingsForm.controls["colours_subtitle"].setValue(color);
  }
  setColourContentOnForm(color: string): void {
    this.themeSettingsForm.controls["colours_content"].setValue(color);
  }

  public toggleContentOptions(): void {
    this.toggleContent = !this.toggleContent;
  }
}
