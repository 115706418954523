import { NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemeEnhanced, ThemeStyleModel } from "src/app/models/theme/theme.model";

@Component({
  selector: "themes-list",
  standalone: true,
  imports: [NgStyle, NgClass],
  templateUrl: "./themes-list.component.html",
  styleUrl: "./themes-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemesListComponent {
  @Input({ required: true }) theme!: ThemeEnhanced;
  @Input({ required: true }) listLocation!: string;
  @Input() themeDetails!: ThemeStyleModel | null;
  @Output() themeSelectionOutput = new EventEmitter<ThemeEnhanced>();

  /**
   * Route To Theme Editor Or Select Theme
   * @param themeId
   */
  public navigateToThemeEditorOrSelectTheme(theme: ThemeEnhanced): void {
    this.themeSelectionOutput.emit(theme);
  }

  /**
   *
   * @param photosSize
   * @returns
   */
  public getNumberOfImages(photosSize: number): number[] {
    return Array(5 - photosSize).fill(0);
  }
}
