import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AppCheck } from '@angular/fire/app-check';
import { Database, ref, remove } from '@angular/fire/database';
import { RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppUpdateService } from './core/services/app-update.service';
import { AuthService } from './core/services/auth.service';
import { ConnectedDevicesService } from './core/services/connected-devices.service';
import { W3wMapComponent } from './sections/workspaces/current-workspace/manage-sites/edit/manage-site-edit-location/w3w-map/w3w-map.component';
import { AppUpdateComponent } from './shared/app-update/app-update.component';
import { ConnectedDevicesOverlayComponent } from './shared/connected-devices/connected-devices-overlay/connected-devices-overlay.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AppUpdateComponent, W3wMapComponent, ConnectedDevicesOverlayComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  private authService = inject(AuthService);
  public appUpdateService = inject(AppUpdateService);
  public connectedDevicesService = inject(ConnectedDevicesService);
  private appCheck = inject(AppCheck);
  public w3w_api_key = environment.w3w_api_key;
  public map_api_key = environment.map_key;
  private database = inject(Database);

  ngOnInit(): void {
    this.connectedDevicesService.initializeDeviceTracking();
  }

  ngOnDestroy(): void {
    if (this.connectedDevicesService.userStatusRef) {
      // Remove this device from the database when the app is destroyed
      const deviceRef = ref(this.database, `${this.connectedDevicesService.userStatusRef.toString()}/${this.connectedDevicesService.uniqueDeviceId}`);
      remove(deviceRef);
    }
  }
}
