@if (workspace$ | async; as workspace) {
  <!-- Dummy breadcrumb element-->
  <div class="h-[28px] px-6 pb-2"></div>
  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Edit Workspace</h2>
      </div>
    </div>
  </div>

  <!-- New UI -->
  <div class="grid grid-cols-1 gap-y-8">
    <!-- Form State Top -->
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="workspaceForm.pristine"></form-state>
      <button
        type="button"
        (click)="checkIfPhotosAreUploadingOrCreateWorkspace()"
        class="btn"
        [disabled]="workspaceForm.pristine || workspaceForm.controls['name'].errors"
        [ngClass]="workspaceForm.pristine || workspaceForm.controls['name'].errors ? 'btn-disabled' : 'btn-primary'">
        Save
      </button>
    </div>

    <!-- Details -->
    <div class="content-card-wrapper">
      <div class="content-card-header flex items-center justify-between">
        <h3>Details</h3>
        <tooltip [toolTipText]="'These are the details that will appear on the Report.'"></tooltip>
      </div>
      <div class="px-6 py-5">
        <div class="content-grid gap-x-4 gap-y-6">
          <!-- Workspace Form -->
          <workspace-editor-details [workspaceForm]="workspaceForm" />

          <!-- Workspace Image -->
          <div>
            <div class="form-input-label mb-2 px-3">Workspace Photo</div>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <!-- Image Dropzone -->

                @if (!workspaceForm.value.image_thumbnail_url && uploadFileArray.length < 1) {
                  <photo-dropzone [uploadSourceComponent]="'workspace'" (detectPhotosOutput)="detectPhotos($event)" />
                }
                @if (photoPreview && uploadFileArray.length > 0) {
                  <photo-preview [photosToPreview]="uploadFileArray" [uploadLimit]="1" [uploadSourceComponent]="'workspace'" (removePhotoFromArrayOutput)="removePhotoFromArray($event)" />
                }
                @if (overlay_uploadPhotos) {
                  @for (photo of uploadFileArray; track photo.id) {
                    <photo-upload-single
                      [file]="photo.photo"
                      [path]="'workspaces/' + workspaceId"
                      [fileType]="'jpeg'"
                      [largeMaxWidth]="1200"
                      [largeMaxHeight]="1200"
                      [thumbnailMaxWidth]="300"
                      [thumbnailMaxHeight]="300"
                      [uploadSourceComponent]="'workspace'"
                      (photoDataOutput)="patchFormWithPhotosAndSaveDoc($event)" />
                  }
                }
                <!-- Image -->
                @if (workspaceForm.value.image_thumbnail_url) {
                  <div class="relative flex h-32 w-32 rounded-md bg-gray-600 bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + workspaceForm.value.image_thumbnail_url + ')' }">
                    <div class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500" (click)="toggleDeletePhotoOverlay()">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                      </svg>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Transfer Ownership -->
    @if (workspaceForm.value.current_owner_id === user.user_id) {
      <div class="content-card-wrapper">
        <div class="content-card-header flex items-center justify-between">
          <h3>Workspace Ownership</h3>
          <tooltip [toolTipText]="toolTipWorkspaceOwnership.title"></tooltip>
        </div>
        <div class="px-6 py-5">
          <div class="content-grid gap-x-4 gap-y-6">
            <p class="list-meta">You are the owner of this Workspace. Click the button below to transfer ownership of this Workspace to someone else.</p>
            <div>
              <button class="btn btn-primary" (click)="toggleEditOwnershipOverlay()">Transfer Ownership</button>
            </div>
            @if (overlay_editOwnership) {
              <manage-workspace-transfer-ownership-wrapper
                [workspaceForm]="workspaceForm"
                [overlay_selectOwner]="overlay_selectOwner"
                [transferOwnershipResponseLoadingIndicator]="transferOwnershipResponseLoadingIndicator"
                [transferConfirmation]="transferConfirmation"
                [allOtherMembers]="allOtherMembers"
                [currentlySelectedMember]="currentlySelectedMember"
                [members]="members$ | async"
                (toggleEditOwnershipOverlayOutput)="toggleEditOwnershipOverlay()"
                (toggleNewOwnerPanelOutput)="toggleNewOwnerPanel()"
                (transferOwnershipAndCloseOverlaysOutput)="transferOwnershipAndCloseOverlays()"
                (resetTransferOwnershipPropertiesOutput)="resetTransferOwnershipProperties()"
                (memberSelectionOutput)="memberSelection($event)" />
            }
          </div>
        </div>
      </div>
    }

    <!-- Form State Bottom-->
    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="workspaceForm.pristine"></form-state>
        <button
          type="button"
          (click)="checkIfPhotosAreUploadingOrCreateWorkspace()"
          class="btn"
          [disabled]="workspaceForm.pristine || workspaceForm.controls['name'].errors"
          [ngClass]="workspaceForm.pristine || workspaceForm.controls['name'].errors ? 'btn-disabled' : 'btn-primary'">
          Save
        </button>
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deletePhoto) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (deleteButtonClicked)="removeWorkspacePhotoFromForm()" (cancelButtonClicked)="toggleDeletePhotoOverlay()" />
}
