<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <form [formGroup]="inspectionForm" class="flex flex-col justify-between">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">New Inspection</h3>
        </div>

        <div class="px-6 py-8">
          <div class="block w-full space-y-4 rounded-md bg-white p-6 shadow-sm dark:bg-gray-800">
            <inspection-edit-details [inspectionForm]="inspectionForm" />
          </div>
        </div>
        <div class="flex justify-end bg-gray-100 px-6 py-6">
          <div class="flex flex-row-reverse">
            <button
              type="button"
              aria-label="Save New Inspection"
              (click)="saveInspection()"
              class="btn"
              [ngClass]="inspectionForm.pristine || inspectionForm.controls['title'].errors || inspectionForm.controls['date'].errors ? 'btn-disabled' : 'btn-primary'"
              [disabled]="inspectionForm.pristine || inspectionForm.controls['title'].errors || inspectionForm.controls['date'].errors"
            >
              Save
            </button>
            <button type="button" aria-label="Cancel New Inspection" (click)="toggleNewInspectionOverlay()" class="btn btn-cancel mr-3">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
