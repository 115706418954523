// Generated by CoffeeScript 2.4.1
(function () {
  var XMLCharacterData, XMLNode;
  XMLNode = require('./XMLNode');

  // Represents a character data node
  module.exports = XMLCharacterData = function () {
    class XMLCharacterData extends XMLNode {
      // Initializes a new instance of `XMLCharacterData`

      constructor(parent) {
        super(parent);
        this.value = '';
      }

      // Creates and returns a deep clone of `this`
      clone() {
        return Object.create(this);
      }

      // DOM level 1 functions to be implemented later
      substringData(offset, count) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      appendData(arg) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      insertData(offset, arg) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      deleteData(offset, count) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      replaceData(offset, count, arg) {
        throw new Error("This DOM method is not implemented." + this.debugInfo());
      }
      isEqualNode(node) {
        if (!super.isEqualNode(node)) {
          return false;
        }
        if (node.data !== this.data) {
          return false;
        }
        return true;
      }
    }
    ;

    // DOM level 1
    Object.defineProperty(XMLCharacterData.prototype, 'data', {
      get: function () {
        return this.value;
      },
      set: function (value) {
        return this.value = value || '';
      }
    });
    Object.defineProperty(XMLCharacterData.prototype, 'length', {
      get: function () {
        return this.value.length;
      }
    });

    // DOM level 3
    Object.defineProperty(XMLCharacterData.prototype, 'textContent', {
      get: function () {
        return this.value;
      },
      set: function (value) {
        return this.value = value || '';
      }
    });
    return XMLCharacterData;
  }.call(this);
}).call(this);