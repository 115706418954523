import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "test-red-amber-green",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: "./test-red-amber-green.component.html",
  styleUrl: "./test-red-amber-green.component.scss",
})
export class TestRedAmberGreenComponent {
  @Input({ required: true }) testForm!: FormGroup;
  @Output() itemAnswerLimitationManagerCheckOutput = new EventEmitter<string>();

  // Properties
  public answerType = [
    { title: "Red", value: "red" },
    { title: "Amber ", value: "amber" },
    { title: "Green ", value: "green" },
  ];

  public onRadioClick(event: Event, newValue: string): void {
    event.preventDefault();
    this.itemAnswerLimitationManagerCheckOutput.emit(newValue);
  }
}
