import { JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Operation } from 'cip';
import { DuplicationService } from 'src/app/core/services/duplication/duplication.service';
import { InfoViewComponent } from '../info-view/info-view.component';

@Component({
  selector: 'duplicate-overlay',
  standalone: true,
  imports: [FormsModule, InfoViewComponent, NgClass, NgTemplateOutlet, JsonPipe],
  templateUrl: './duplicate-overlay.component.html',
  styleUrl: './duplicate-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateOverlayComponent {
  @Input({ required: true }) duplicationObject: Operation | undefined;

  // Original document (such as Inspection, Category, Item etc)
  @Input({ required: true }) originalDocumentTitle!: string;

  // The title of the overlay
  @Input({ required: true }) duplicationTitle!: string;

  // The description on the overlay
  @Input({ required: true }) duplicationDescription!: string;

  // Status Text
  @Input({ required: true }) inProgressText!: string;
  @Input({ required: true }) successText!: string;
  @Input({ required: true }) failedText!: string;

  @Input({ required: true }) navigateToText!: string;

  @Input() documentType!: 'category' | 'item' | 'categories-from-template';

  // When the duplicate button text is clicked
  @Output() duplicateEmitter = new EventEmitter<string>();
  @Output() duplicateCategoriesFromTemplateEmitter = new EventEmitter();

  // Navigate to new document after completion
  @Output() navigateToNewDocEmitter = new EventEmitter();

  // Services
  public duplicationService = inject(DuplicationService);

  /**
   * Duplicate
   * @param documentTitle
   * Emit the document title for the parent to receive and react
   */
  duplicate(documentTitle: string): void {
    this.duplicateEmitter.emit(documentTitle);
  }

  duplicateCategoriesFromTemplate(): void {
    this.duplicateCategoriesFromTemplateEmitter.emit();
  }

  /**
   * Emit to the parent so that the user can navigate to the new document
   */
  navigateToNewDocument(): void {
    this.navigateToNewDocEmitter.emit();
  }

  /**
   * Toggle Duplication Overlays
   */
  toggleDuplicationOverlay(): void {
    this.duplicationService.overlay_createCategoriesFromTemplate = false;
    this.duplicationService.overlay_duplicate = false;
    this.duplicationService.overlay_duplicateConfirmation = false;
  }
}
