<div class="grid grid-cols-1 gap-y-4">
  <!-- List -->
  <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
    <!-- List Component -->
    <ul class="list-ul" id="themesList">
      @for (theme of filteredThemes; track $index) {
        <li [class.hidden]="theme.hidden">
          <div class="list-row">
            <!-- List Editing Tools -->
            @if (editMode) {
              <div class="flex flex-row items-center">
                <div class="mr-3 flex items-center justify-end hover:text-brand_Primary-hover dark:text-slate-300 dark:hover:text-sky-500">
                  <div class="flex items-center">
                    @if (theme.id !== "default" && themesBulkEditArray) {
                      <input type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" (change)="createBatchEdit(theme)" [checked]="themesBulkEditArray.includes(theme)" />
                    }
                  </div>
                </div>
                @if (themesBulkEditArray && themesBulkEditArray.length <= 0 && theme.id !== "default") {
                  <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" (click)="deleteSingleTheme(theme.id)" class="cip-transition h-5 w-5 cursor-pointer text-alert_Error hover:opacity-75">
                      <path
                        fill-rule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                }
              </div>
            }

            <!-- Left Index-->
            <themes-list [theme]="theme" [themeDetails]="themeDetails" [listLocation]="'manageThemesList'" (themeSelectionOutput)="themeSelection($event)" />
          </div>
        </li>
      }
    </ul>
  </div>
</div>

@if (allThemesHidden) {
  <search-empty [searchTerm]="_searchTerm" />
}
