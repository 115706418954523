<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="m-h-[600px] inline-block w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold text-text_Title" id="modal-title">Import Template</h3>
        </div>

        <!-- Import Information-->
        @if (!fileUploading && !fileUploadComplete) {
          <div class="flex h-full w-full flex-col overflow-hidden">
            <div class="w-full flex-1 overflow-y-scroll">
              <div class="grid grid-cols-1 gap-y-8 px-6 py-8">
                <div>
                  <div class="container-header">File</div>
                  <div class="rounded-md bg-white px-6 py-8 shadow-sm dark:bg-gray-800 sm:px-8">
                    <label for="file_input" class="flex w-full cursor-pointer flex-row items-center overflow-hidden rounded-md border border-gray-200">
                      <span class="w-auto bg-brand_Primary px-4 py-2 text-white hover:bg-brand_Primary-hover">Upload File</span>
                      <span class="pl-4">{{ fileName ? fileName : "No file selected" }}</span>
                    </label>
                    <input (change)="onFileSelected($event)" class="hidden" id="file_input" type="file" />
                  </div>
                </div>

                @if (fileAdded) {
                  <div>
                    <div class="container-header">Details</div>
                    <div class="rounded-md bg-white px-6 py-8 shadow-sm dark:bg-gray-800 sm:px-8">
                      <form [formGroup]="importTemplateForm">
                        <div class="grid grid-cols-1 gap-x-6 gap-y-8">
                          <div class="col-span-1">
                            <div class="form-input-wrapper">
                              <label for="import-title" class="form-input-label">
                                Title*
                                @if (!importTemplateForm.pristine && importTemplateForm.controls["title"].errors) {
                                  <span class="required-field"> - Title is required</span>
                                }
                              </label>
                              <input type="text" name="import-title" id="import-title" class="form-input" formControlName="title" />
                            </div>
                          </div>

                          <div class="col-span-1">
                            <div class="form-input-wrapper">
                              <label for="import-reference" class="form-input-label">
                                Reference*
                                @if (!importTemplateForm.pristine && importTemplateForm.controls["reference"].errors) {
                                  <span class="required-field"> - Reference is required</span>
                                }
                              </label>
                              <input #importReference type="text" name="import-reference" id="import-reference" class="form-input" formControlName="reference" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
              <button
                type="button"
                class="btn ml-3"
                [disabled]="importTemplateForm.pristine || importTemplateForm.invalid"
                [ngClass]="importTemplateForm.pristine || importTemplateForm.invalid ? 'btn-disabled cursor-not-allowed' : 'btn-primary'"
                (click)="onImportTemplate()">
                Import Template
              </button>
              <button type="button" class="btn btn-cancel" (click)="toggleImportTemplateOverlay()">Cancel</button>
            </div>
          </div>
        }

        <!-- Data Uploading -->
        @if (fileUploading) {
          <div class="flex h-full w-full flex-col overflow-hidden">
            <div class="w-full flex-1 overflow-y-scroll">
              <div class="flex h-full flex-col px-6 py-8">
                <div class="mb-4 flex h-full flex-col items-center justify-center rounded-md bg-white px-6 py-8 shadow-sm dark:bg-gray-800 sm:px-8">
                  <div class="flex h-32 items-center justify-center rounded-lg">
                    <span class="loader"></span>
                  </div>
                  <p class="list-meta">Importing Template...</p>
                </div>
                <!-- <info-bar [type]="infoViewImportTemplate.type" [message]="infoViewImportTemplate.message"></info-bar> -->
              </div>
            </div>
            <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
              <button type="button" class="btn btn-cancel" (click)="toggleImportTemplateOverlay()">Close</button>
            </div>
          </div>
        }

        <!-- Data Complete -->
        @if (fileUploadComplete) {
          <div class="flex h-full w-full flex-col overflow-hidden">
            <div class="w-full flex-1 overflow-y-scroll">
              <div class="flex h-full flex-col px-6 py-8">
                <div class="mb-4 flex h-full flex-col items-center justify-center rounded-md bg-white px-6 py-8 shadow-sm dark:bg-gray-800 sm:px-8">
                  <div class="flex items-center justify-center rounded-lg">
                    <div class="flex h-10 w-10 items-center justify-center rounded-full bg-brand_Primary text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                  </div>
                  <p class="list-meta mt-4">Importing Complete</p>
                </div>
              </div>
            </div>
            <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
              <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'templates', newImportTemplateId, 'overview']" type="button" class="btn btn-primary ml-3" (click)="toggleImportTemplateOverlay()">Navigate to New Template</a>
              <button type="button" class="btn btn-cancel" (click)="toggleImportTemplateOverlay()">Close</button>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
