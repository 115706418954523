import { NgStyle } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { User } from "cip";
import { ActionCountPipe } from "src/app/core/pipes/action-count.pipe";
import { DateFormatPipe } from "src/app/core/pipes/date-format.pipe";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-content-cover",
  standalone: true,
  imports: [NgStyle, DateFormatPipe, ActionCountPipe],
  templateUrl: "./report-content-cover.component.html",
  styleUrl: "./report-content-cover.component.scss",
})
export class ReportContentCoverComponent {
  @Input({ required: true }) user!: User;

  // Services
  public reportsService = inject(ReportsService);
}
