import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InspectionEnhanced } from 'src/app/models/inspection/inspection.model';
import { BreadcrumbsComponent } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { SearchBarComponent } from 'src/app/shared/search-bar/search-bar.component';

@Component({
  selector: 'inspections-list-header',
  standalone: true,
  imports: [SearchBarComponent, BreadcrumbsComponent],
  templateUrl: './inspections-list-header.component.html',
  styleUrl: './inspections-list-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionsListHeaderComponent {
  @Input({ required: true }) inspections!: InspectionEnhanced[];
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) currentlySelectedInspectionSortChoice!: string;
  @Input({ required: true }) isSortOrderAscending!: boolean;
  @Input({ required: true }) sortDropdown!: boolean;
  @Output() filterChoiceOutput = new EventEmitter<string>();
  @Output() newInspectionOutput = new EventEmitter<void>();
  @Output() sortDropdownOutput = new EventEmitter<boolean>();
  @Output() sortDropdownCloseOutput = new EventEmitter<void>();
  @Output() searchTermChange = new EventEmitter<string>();

  // Properties
  public filterChoices = ['Title', 'Reference', 'Date'];

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Can User Add Inspection
   */
  public canUserAddInspection(): void {
    this.newInspectionOutput.emit();
  }

  /**
   * Update Inspections Array With Sort Selection
   * @param filterChoice
   */
  public updateInspectionsArrayWithSortSelection(filterChoice: string): void {
    this.filterChoiceOutput.emit(filterChoice);
  }

  /**
   * Toggle Sort Dropdown
   * Triggered by clicking the sort button
   */
  public toggleSortDropdown(): void {
    this.sortDropdownOutput.emit();
  }

  /**
   * Close Sort Dropdown
   * Trigger by clicking outside the element
   * TODO
   */
  public closeSortDropdown() {}
}
