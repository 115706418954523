import { DatePipe, NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuditLogType } from "src/app/core/util/models";
import { AUDIT_FILTERS } from "../../utils/filters";
import { ProcessedAuditLog } from "../../utils/process-audit-log";

@Component({
  selector: "audit-logs-list",
  standalone: true,
  imports: [NgTemplateOutlet, RouterModule, DatePipe],
  templateUrl: "./audit-logs-list.component.html",
  styleUrl: "./audit-logs-list.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditLogsListComponent {
  @Input({ required: true }) auditLogsArray: ProcessedAuditLog[] = [];
  @Input({ required: true }) auditFilters = AUDIT_FILTERS;
  @Input({ required: true }) selectedAuditFilterType!: string;
  @Input({ required: true }) sortDropdown!: boolean;
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) totalActivities!: number;
  @Input({ required: true }) queriedActivities!: number;
  @Output() toggleSortDropdownOutput = new EventEmitter<void>();
  @Output() closeSortDropdownOutput = new EventEmitter<void>();
  @Output() getAllAuditLogsOutput = new EventEmitter<void>();
  @Output() queryAuditLogsWithTypeOutput = new EventEmitter<{ filterType: AuditLogType; filterTitle: string }>();
  @Output() queryNextBatchOfActivitiesOutput = new EventEmitter<void>();

  public toggleSortDropdown(): void {
    this.toggleSortDropdownOutput.emit();
  }
  public closeSortDropdown(): void {
    this.closeSortDropdownOutput.emit();
  }
  public getAllAuditLogs(): void {
    this.getAllAuditLogsOutput.emit();
  }
  public queryAuditLogsWithType(filterType: AuditLogType, filterTitle: string): void {
    this.queryAuditLogsWithTypeOutput.emit({ filterType, filterTitle });
  }
  public queryNextBatchOfActivities(): void {
    this.queryNextBatchOfActivitiesOutput.emit();
  }
}
