import { inject, Injectable } from "@angular/core";
import { Firestore } from "@angular/fire/firestore";
import { User } from "cip";
import { deleteField, WithFieldValue } from "firebase/firestore";
import { catchError, Observable, of } from "rxjs";
import { UserEnhanced } from "src/app/models/user/user.model";
import { CollectionsService } from "./collections/collections.service";
import { FirestoreUtilsService } from "./firestore/firestore-utils.service";

export interface NewEmail {
  email: string;
}

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private afs = inject(Firestore);
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get User Profile
   * @param userId
   */
  public getUserProfile$(userId: string): Observable<UserEnhanced | null> {
    const userPath = `${this.collectionsService.usersCol()}/${userId}`;
    return this.firestoreUtilsService.getDocumentData<UserEnhanced>(userPath).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  /**
   * Save User Profile
   * @param userProfileForm
   */
  public saveUserProfile(userProfileForm: User, user: User): Promise<void> {
    const userPath = `${this.collectionsService.usersCol()}/${user.user_id}`;
    const form = this.preprocessUser(userProfileForm);
    return this.firestoreUtilsService.setDocumentDataWithFieldValue<User>(userPath, form);
  }

  /**
   * Save User Email
   * @param newEmail
   * @param userId
   */
  async saveUserEmail(newEmail: string, userId: string): Promise<void> {
    const userPath = `${this.collectionsService.usersCol()}/${userId}`;
    const form = {
      email: newEmail,
    };
    return this.firestoreUtilsService.setPartialDocumentData<User>(userPath, form);
  }

  /**
   * Preprocess User
   * @param form
   */
  private preprocessUser(form: User): WithFieldValue<User> {
    return {
      user_id: form.user_id.trim(),
      name_first: form.name_first.trim(),
      name_last: form.name_last.trim(),
      email: form.email,
      job_title: !form.job_title ? deleteField() : form.job_title.trim(),
      company: !form.company ? deleteField() : form.company.trim(),
      phone: !form.phone ? deleteField() : form.phone.trim(),
    };
  }
}
