@if (otherSignatures) {
  <div class="grid grid-cols-1 gap-y-4">
    <!-- Heading -->
    <div class="mx-auto w-full max-w-7xl sm:px-6 lg:px-8">
      <div class="form-header-wrapper">
        <h3 class="form-header-text">Other Signatures</h3>
      </div>
    </div>

    <!-- Main Signature -->
    <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
      <!-- List Component -->
      <div id="signaturesList" role="list">
        <ul class="list-ul">
          @for (signature of otherSignatures; track $index) {
            <li class="list-row">
              <div class="list-a group" (click)="routeToSignatureEditor(signature.id)">
                <!-- Image -->
                <div class="ml-2.5 flex flex-row sm:ml-0">
                  @if (signature.image_thumbnail_url) {
                    <img class="flex h-auto w-[250px] rounded-md border border-gray-50" src="{{ signature.image_thumbnail_url }}" />
                  }
                </div>

                <!-- Content -->
                <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                  <div class="min-w-0 flex-auto">
                    <p class="list-title">{{ signature.name }}</p>
                    @if (signature.date_signed) {
                      <p class="list-meta">Signed {{ signature.date_signed.toDate() | date: 'dd/MM/yyyy' }} at {{ signature.date_signed.toDate() | date: 'HH:mm' }}</p>
                    }
                  </div>
                </div>

                <!-- Right Icon -->
                <div class="flex">
                  <button type="button" class="mr-2.5 block h-full py-2.5 pl-2.5 text-text_Subtitle dark:text-gray-400 sm:mr-0" aria-expanded="false" aria-haspopup="true">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
}
