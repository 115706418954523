<form [formGroup]="actionForm">
  <div class="content-card-wrapper">
    <div class="content-card-header">
      <h3>Priority</h3>
    </div>
    <div class="px-6 py-5">
      <div class="content-grid gap-x-4 gap-y-6">
        <fieldset class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="relative -space-y-px rounded-md border bg-white sm:col-span-6">
            @for (severity of severityOptions; track $index; let last = $last; let i = $index) {
            <label
              [ngClass]="[currentSeverity === severity.value ? 'z-10 bg-brand_Primary/5' : 'border-gray-200', last ? 'border-none' : 'border-b']"
              (click)="setSeverity(severity.value)"
              class="relative flex cursor-pointer p-4 focus:outline-none"
            >
              <input
                type="radio"
                [value]="severity.value"
                class="mt-0.5 h-4 w-4 shrink-0 cursor-pointer border-gray-300 text-brand_Primary focus:ring-brand_Primary"
                aria-labelledby="item-mode-0-label"
                aria-describedby="item-mode-0-description"
                formControlName="priority"
              />
              <span class="ml-3 flex flex-row items-center">
                <div class="h-4 w-4 rounded-full" [ngClass]="severity.colour"></div>
                <span [ngClass]="currentSeverity === severity.value ? 'text-brand_Primary' : 'text-text_Title'" id="answer-type-{{ i }}-label" class="ml-2 block text-sm font-medium">{{ severity.name }}</span>
              </span>
            </label>
          }
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</form>
