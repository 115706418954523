import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SignatureEnhanced } from 'src/app/models/inspection/signature.model';

@Component({
  selector: 'inspection-signoff-list-main',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './inspection-signoff-list-main.component.html',
  styleUrl: './inspection-signoff-list-main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionSignoffListMainComponent {
  @Input({ required: true }) mainSignature: SignatureEnhanced | null = null;
  @Output() routeToSignatureEditorOutput = new EventEmitter<string>();

  /**
   * Route to the signature editor
   */
  public routeToSignatureEditor(signatureId: string): void {
    this.routeToSignatureEditorOutput.emit(signatureId);
  }
}
