import { inject, Injectable } from "@angular/core";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { MemberEnhanced } from "src/app/models/member/member.model";

@Injectable({
  providedIn: "root",
})
export class MembersListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Members List
   * @param workspaceId
   */
  public getMembersList$(workspaceId: string): Observable<MemberEnhanced[]> {
    const path = this.collectionsService.membersCol(workspaceId);
    const queryConstraints = [orderBy("name_first", "asc")];
    return this.firestoreUtilsService.getCollectionData<MemberEnhanced>(path, queryConstraints);
  }

  /**
   * get Assigned Members
   * @param workspaceId
   * @param roleId
   */
  public getAssignedMembers$(workspaceId: string, roleId: string): Observable<MemberEnhanced[]> {
    const path = this.collectionsService.membersCol(workspaceId);
    const queryConstraints = [where("role_id", "==", roleId)];
    return this.firestoreUtilsService.getCollectionData<MemberEnhanced>(path, queryConstraints);
  }
}
