<header>
  <!-- Breadcrumbs-->
  <div class="h-[28px] px-6 pb-2"></div>

  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Delete Workspace</h2>
      </div>
    </div>
  </div>
</header>

<div class="grid grid-cols-1 gap-y-8">
  <div class="content-card-wrapper">
    <div class="content-card">
      <div class="content-grid gap-y-6">
        <info-view [type]="infoViewDeleteWorkspace2.type" [message]="infoViewDeleteWorkspace2.message" />
        <label for="deleteWorkspace" class="flex cursor-pointer flex-row">
          <div class="flex h-5">
            <input id="deleteWorkspace" aria-describedby="deleteWorkspace" type="checkbox" class="form-checkbox h-5 w-5 cursor-pointer text-brand_Primary transition duration-150 ease-in-out" [(ngModel)]="deleteWorkspaceConfirmation" />
          </div>
          <div class="ml-3 text-sm">
            <span class="form-input-label">Delete Workspace?</span>
          </div>
        </label>
      </div>
    </div>
  </div>

  <div class="flex flex-row items-center justify-end px-6 sm:px-8">
    <button type="button" class="btn ml-3" [ngClass]="deleteWorkspaceConfirmation ? 'btn-destructive' : 'btn-disabled cursor-not-allowed'" [disabled]="!deleteWorkspaceConfirmation" (click)="setDeleteConfirmationTextAndToggleDeleteOverlay()">
      Delete Workspace
    </button>
  </div>
</div>

@if (overlay_deleteWorkspace) {
  <delete-confirmation [deleteTitle]="deleteOverlayTitle" [deleteMessage]="deleteOverlayDescription" (cancelCommandEmitter)="toggleDeleteWorkspaceOverlay()" (deleteCommandEmitter)="deleteWorkspaceAndRouteToWorkspaces()" />
}
