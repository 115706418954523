import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InviteAcceptDecline } from 'src/app/models/invite/invite-accept-decline.model';
import { InviteEnhanced } from 'src/app/models/invite/invite.model';

@Component({
  selector: 'invited-workspaces',
  standalone: true,
  imports: [],
  templateUrl: './invited-workspaces.component.html',
  styleUrl: './invited-workspaces.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitedWorkspacesComponent {
  @Input({ required: true }) invites: InviteEnhanced[] = [];
  @Input({ required: true }) isInviteActionProcessing!: boolean;
  @Output() joinWorkspaceOutput: EventEmitter<InviteAcceptDecline> = new EventEmitter();

  /**
   * Accept or Decline Invite
   * @param choice
   */
  public acceptOrDeclineInvite(inviteProperties: InviteAcceptDecline): void {
    this.joinWorkspaceOutput.emit(inviteProperties);
  }
}
