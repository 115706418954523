import { inject, Injectable } from '@angular/core';
import { orderBy, where } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { CollectionsService } from 'src/app/core/services/collections/collections.service';
import { FirestoreUtilsService } from 'src/app/core/services/firestore/firestore-utils.service';
import { SiteEnhanced } from 'src/app/models/site/site.model';

@Injectable({
  providedIn: 'root',
})
export class SitesListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Sites List
   * @param workspaceId
   * @returns
   */
  public getSitesList$(workspaceId: string): Observable<SiteEnhanced[]> {
    const path = this.collectionsService.sitesCol(workspaceId);
    const queryConstraints = [where('is_deleted', '==', false), orderBy('title', 'asc')];
    return this.firestoreUtilsService.getCollectionData<SiteEnhanced>(path, queryConstraints);
  }
}
