import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RolePermissions } from 'cip';
import { LimitationManagerService } from '../services/limitation-manager.service';

@Injectable({
  providedIn: 'root',
})
export class LimitationGuard implements CanActivate {
  constructor(
    private limitationManagerService: LimitationManagerService,
    private router: Router
  ) {}
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const workspaceId = route.paramMap.get('workspaceId');
    const featureAction = route.data['featureAction'] as keyof RolePermissions;
    const hasPermission = await this.limitationManagerService.doesUserHaveCorrectPermissions(featureAction);
    if (!hasPermission) {
      if (workspaceId) {
        this.router.navigate(['/', 'workspace', workspaceId, 'folders']);
      } else {
        this.router.navigate(['/', 'workspaces']);
      }
    }
    return hasPermission;
  }
}
