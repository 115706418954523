import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { BehaviorSubject, filter, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { FormFormattingService } from "src/app/core/services/forms/form-formatting.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { AlertType } from "src/app/models/strings/strings.model";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { SiteDetailService } from "../../services/site-detail.service";
import { SitesListService } from "../../services/sites-list.service";
import { ManageSitesListHeaderComponent } from "../manage-sites-list-header/manage-sites-list-header.component";
import { ManageSitesListComponent } from "../manage-sites-list/manage-sites-list.component";

@Component({
  selector: "manage-sites-list-wrapper",
  standalone: true,
  imports: [ManageSitesListHeaderComponent, ManageSitesListComponent, EmptyListComponent, AsyncPipe, LoadingSpinnerComponent, DeleteOverlayComponent],
  templateUrl: "./manage-sites-list-wrapper.component.html",
  styleUrl: "./manage-sites-list-wrapper.component.scss",
})
export class ManageSitesListWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private sitesListService = inject(SitesListService);
  private siteDetailService = inject(SiteDetailService);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private formFormattingService = inject(FormFormattingService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  // Properties
  private _workspaceId!: string;
  private user: User;
  public sites$!: Observable<SiteEnhanced[]>;
  public editMode: boolean = false;
  public currentSearchTerm: string = "";
  public sitesBulkEditArray$: BehaviorSubject<SiteEnhanced[]> = new BehaviorSubject<SiteEnhanced[]>([]);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  public siteToBeDeleted: string = "";
  public overlay_deleteSite = signal<boolean>(false);

  constructor() {
    this.user = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.getSitesList();
  }

  /**
   * Get Sites List
   */
  private async getSitesList(): Promise<void> {
    this.sites$ = this.sitesListService.getSitesList$(this.workspaceId).pipe(filter((data) => !!data));
  }

  /**
   * Update Search Term
   * @param term
   */
  public searchTermChange(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   * Set Delete Batch Sites Properties
   */
  public async setDeleteBatchSitesProperties(): Promise<void> {
    const featureAction: keyof RolePermissions = "site_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      if (this.sitesBulkEditArray$.getValue().length > 0) {
        const deleteSites = this.stringsService.alertFilter(AlertType.DeleteSites);
        if (deleteSites) {
          this.deleteOverlayTitle = deleteSites.title;
          this.deleteOverlayDescription = deleteSites.description;
          this.toggleDeleteSiteOverlay(true);
        }
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Limitation Manager Check
   */
  public async limitationManagerCheck(featureAction: keyof RolePermissions): Promise<boolean> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    return limitationResult;
  }

  /**
   * Set Delete Single Site Properties
   * @param siteId
   */
  public async setDeleteSingleSiteProperties(siteId: string): Promise<void> {
    const featureAction: keyof RolePermissions = "site_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      const deleteSite = this.stringsService.alertFilter(AlertType.DeleteSite);
      if (deleteSite) {
        this.deleteOverlayTitle = deleteSite.title;
        this.deleteOverlayDescription = deleteSite.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteSite);
      }
      this.siteToBeDeleted = siteId;
      this.toggleDeleteSiteOverlay(true);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Single Or Multiple Sites
   */
  public deleteSingleOrMultipleSites(): void {
    if (this.sitesBulkEditArray$.getValue().length > 0) {
      this.deleteBatchedSites();
    } else {
      this.deleteSingleSite();
    }
  }

  /**
   * Delete Single Site
   */
  public async deleteSingleSite(): Promise<void> {
    try {
      await this.siteDetailService.deleteSiteDoc(this.workspaceId, this.siteToBeDeleted, this.user);
      this.toggleDeleteSiteOverlay(false);
      this.toggleEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Batched Sites
   */
  private async deleteBatchedSites(): Promise<void> {
    try {
      await this.siteDetailService.deleteBatchedSites(this.workspaceId, this.sitesBulkEditArray$.value, this.user);
      this.toggleDeleteSiteOverlay(false);
      this.toggleEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Create Batch Edit
   * @param site
   */
  public createBatchEdit(site: SiteEnhanced): void {
    const currentArray = this.sitesBulkEditArray$.getValue();
    if (currentArray.some((e: SiteEnhanced) => e.id === site.id)) {
      this.removeFromBatchArray(site);
    } else {
      this.addToBatchArray(site);
    }
  }

  /**
   * Add To Batch Array
   * @param site
   */
  private addToBatchArray(site: SiteEnhanced): void {
    const updatedArray = [...this.sitesBulkEditArray$.getValue(), site];
    this.sitesBulkEditArray$.next(updatedArray);
  }

  /**
   * Remove From Batch Array
   * @param site
   */
  private removeFromBatchArray(site: SiteEnhanced): void {
    const updatedArray = this.sitesBulkEditArray$.getValue().filter((e) => e.id !== site.id);
    this.sitesBulkEditArray$.next(updatedArray);
  }

  /**
   * Route To Site Editor
   */
  public async routeToSiteEditor(siteId: string): Promise<void> {
    const featureAction: keyof RolePermissions = "site_create_update";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "sites", siteId]);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.sitesBulkEditArray$.next([]);
  }

  /**
   * Toggle Delete Site Overlay
   * @param value
   */
  public toggleDeleteSiteOverlay(value: boolean): void {
    this.overlay_deleteSite.set(value);
  }

  /**
   * Can User Create Site
   */
  public async canUserCreateSite(): Promise<void> {
    const featureAction: keyof RolePermissions = "site_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    if (limitationResult) {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "sites", "new"]);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }
}
