@if (combinedData$ | async; as combinedData) {
  <manage-members-list-header (routeToInviteEditorOutput)="routeToInviteEditor()" />
  <!-- Tab Selection between members and invites -->
  <div class="mb-5 flex w-full flex-col px-8">
    <nav class="flex space-x-4" aria-label="Tabs">
      <div class="cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-700" [ngClass]="selectedTab === 'Members' ? 'bg-gray-200' : 'bg-gray-100'" (click)="selectTab('Members')">Members</div>
      <div class="cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-700" [ngClass]="selectedTab === 'Pending Invites' ? 'bg-gray-200' : 'bg-gray-100'" (click)="selectTab('Pending Invites')">Pending Invites</div>
    </nav>
  </div>

  <div class="grid grid-cols-1 gap-y-8">
    @if (selectedTab === "Pending Invites") {
      <manage-members-invites-list [invites]="combinedData.invites" [workspaceId]="workspaceId" />
    }

    @if (selectedTab === "Members") {
      <manage-members-list [members]="combinedData.members" [user]="user" [workspaceId]="workspaceId" />
    }
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}
