import { NgStyle } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "manage-theme-edit-preview-supplementary",
  standalone: true,
  imports: [NgStyle],
  templateUrl: "./manage-theme-edit-preview-supplementary.component.html",
  styleUrl: "./manage-theme-edit-preview-supplementary.component.scss",
})
export class ManageThemeEditPreviewSupplementaryComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  @Input({ required: true }) title!: string;
}
