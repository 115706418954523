import { inject, Injectable } from "@angular/core";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { ThemeEnhanced } from "src/app/models/theme/theme.model";

@Injectable({
  providedIn: "root",
})
export class ThemesListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);

  /**
   * Get Themes List
   * @param workspaceId
   * @returns
   */
  public getThemesList$(workspaceId: string): Observable<ThemeEnhanced[]> {
    const path = this.collectionsService.themesCol(workspaceId);
    const queryConstraints = [where("is_deleted", "==", false), orderBy("title", "asc")];
    return this.firestoreUtilsService.getCollectionData<ThemeEnhanced>(path, queryConstraints);
  }
}
