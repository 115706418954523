<div class="mx-auto w-full max-w-7xl px-6 pb-10 xl:px-0">
  <header>
    <!-- Dummy breadcrumb element-->
    <div class="h-[28px] px-6 pb-2">&nbsp;</div>

    <!-- Header -->
    <div class="page-header-wrapper">
      <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="page-header">Audit Logs</h2>
        </div>
        <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
          <div class="ml-3 flex flex-shrink-0">
            <div class="relative">
              <button
                type="button"
                class="relative h-[36px] w-[200px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-brand_Primary sm:text-sm sm:leading-6"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                (click)="toggleSortDropdown()"
                appClickedOutsideDropdown
                (clickedOutside)="closeSortDropdown()">
                <span class="block truncate">{{ selectedAuditFilterType }}</span>

                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                      fill-rule="evenodd"
                      d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
              </button>

              @if (sortDropdown) {
                <ul
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  tabindex="-1"
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-option-3">
                  <li class="relative flex cursor-default select-none flex-row items-center justify-between px-2 py-2 text-gray-900" id="listbox-option-0" role="option" (click)="getAllAuditLogs()">
                    <div class="flex flex-row items-center">
                      <span class="block truncate font-normal">All Audit Logs</span>
                    </div>
                  </li>
                  @for (filter of auditFilters; track filter.type) {
                    <li class="relative flex cursor-default select-none flex-row items-center justify-between px-2 py-2 text-gray-900" id="listbox-option-0" role="option" (click)="queryAuditLogsWithType(filter.type, filter.title)">
                      <div class="flex flex-row items-center">
                        <span class="block truncate font-normal">{{ filter.title }}</span>
                      </div>
                    </li>
                  }
                </ul>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- List -->
  <div class="rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
    <ul id="auditLogList" role="list" class="list-ul">
      @for (auditLog of auditLogsArray; track auditLog.id) {
        <li>
          <a [routerLink]="['/', 'workspace', workspaceId, 'auditlogs', auditLog.id]" class="list-row">
            <div class="list-a group items-center">
              <!-- Left Icon -->
              <div class="ml-2.5 flex flex-row sm:ml-0">
                @switch (auditLog.type) {
                  @case ("assignee-added") {
                    <ng-container [ngTemplateOutlet]="profileIcon"></ng-container>
                  }
                  @case ("assignee-deleted") {
                    <ng-container [ngTemplateOutlet]="profileIcon"></ng-container>
                  }
                  @case ("client-added") {
                    <ng-container [ngTemplateOutlet]="officeIcon"></ng-container>
                  }
                  @case ("client-deleted") {
                    <ng-container [ngTemplateOutlet]="officeIcon"></ng-container>
                  }
                  @case ("folder-added") {
                    <ng-container [ngTemplateOutlet]="folderAdded"></ng-container>
                  }
                  @case ("folder-deleted") {
                    <ng-container [ngTemplateOutlet]="folderDeleted"></ng-container>
                  }
                  @case ("inspection-added") {
                    <ng-container [ngTemplateOutlet]="documentAdded"></ng-container>
                  }
                  @case ("inspection-deleted") {
                    <ng-container [ngTemplateOutlet]="documentDeleted"></ng-container>
                  }
                  @case ("member-activated") {
                    <ng-container [ngTemplateOutlet]="profileCircularIcon"></ng-container>
                  }
                  @case ("member-deactivated") {
                    <ng-container [ngTemplateOutlet]="profileCircularIcon"></ng-container>
                  }
                  @case ("member-name-changed") {
                    <ng-container [ngTemplateOutlet]="profileCircularIcon"></ng-container>
                  }
                  @case ("member-removed") {
                    <ng-container [ngTemplateOutlet]="profileCircularIcon"></ng-container>
                  }
                  @case ("role-added") {
                    <ng-container [ngTemplateOutlet]="roles"></ng-container>
                  }
                  @case ("role-deleted") {
                    <ng-container [ngTemplateOutlet]="roles"></ng-container>
                  }
                  @case ("role-permissions-changed") {
                    <ng-container [ngTemplateOutlet]="roles"></ng-container>
                  }
                  @case ("role-title-changed") {
                    <ng-container [ngTemplateOutlet]="roles"></ng-container>
                  }
                  @case ("site-added") {
                    <ng-container [ngTemplateOutlet]="sites"></ng-container>
                  }
                  @case ("site-deleted") {
                    <ng-container [ngTemplateOutlet]="sites"></ng-container>
                  }
                  @case ("template-added") {
                    <ng-container [ngTemplateOutlet]="documentAdded"></ng-container>
                  }
                  @case ("template-deleted") {
                    <ng-container [ngTemplateOutlet]="documentDeleted"></ng-container>
                  }
                  @case ("workspace-current-owner-id-changed") {
                    <ng-container [ngTemplateOutlet]="workspaces"></ng-container>
                  }
                  @case ("workspace-image-url-changed") {
                    <ng-container [ngTemplateOutlet]="workspaces"></ng-container>
                  }
                  @case ("workspace-name-changed") {
                    <ng-container [ngTemplateOutlet]="workspaces"></ng-container>
                  }
                  @case ("workspace-website-changed") {
                    <ng-container [ngTemplateOutlet]="workspaces"></ng-container>
                  }
                }
              </div>

              <!-- Content -->
              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                <div class="min-w-0 flex-auto">
                  <p class="list-title">{{ auditLog.type_title }}</p>
                  <p class="list-meta">{{ auditLog.description }}</p>
                  @if (auditLog.image) {
                    <img [src]="auditLog.image" alt="Image" class="mt-2 h-20 w-20 rounded-lg" />
                  }
                  <p class="list-meta">{{ auditLog.date.toDate() | date: "dd MMM yyyy" }} at {{ auditLog.date.toDate() | date: "HH:mm" }}</p>
                </div>
              </div>

              <!-- Right Icon -->
              <div class="flex">
                <div type="button" class="list-icon" aria-expanded="false" aria-haspopup="true">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </li>
      }
    </ul>
  </div>
</div>

<!-- More Button-->
@if (auditLogsArray.length > 0) {
  <div class="max-w-7xlsm:px-6 mx-auto mt-6 flex items-center justify-center pb-10 lg:px-8">
    @if (totalActivities !== queriedActivities) {
      <button class="btn btn-secondary" (click)="queryNextBatchOfActivities()">More</button>
    } @else {
      <div class="list-meta">No more results</div>
    }
  </div>
} @else {
  <div class="mx-auto flex w-full max-w-7xl items-center justify-center rounded-md bg-white py-5 shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
    <div class="list-meta">No results for the selected filter</div>
  </div>
}

<!-- Assignee Added, Deleted -->
<ng-template #profileIcon>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
  </svg>
</ng-template>

<ng-template #profileCircularIcon>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  </svg>
</ng-template>

<!-- Client Added, Deleted -->
<ng-template #officeIcon>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
  </svg>
</ng-template>

<!-- Folder Added -->
<ng-template #folderAdded>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
  </svg>
</ng-template>

<!-- Folder Deleted-->
<ng-template #folderDeleted>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15 13.5H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
  </svg>
</ng-template>

<!-- Inspection Added -->
<ng-template #documentAdded>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
  </svg>
</ng-template>

<!-- Inspection Deleted -->
<ng-template #documentDeleted>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
  </svg>
</ng-template>

<!-- Roles -->
<ng-template #roles>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
  </svg>
</ng-template>

<!-- Sites -->
<ng-template #sites>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
  </svg>
</ng-template>

<!-- Workspace -->
<ng-template #workspaces>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-sm text-text_Subtitle">
    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
  </svg>
</ng-template>
