import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
  selector: "account-menu",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./account-menu.component.html",
  styleUrl: "./account-menu.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMenuComponent {
  @Input({ required: true }) workspaceId!: string;

  // Properties
  public isOffCanvasMenu = false;

  /**
   * Toggles responsive menu canvas
   */
  toggleOffCanvasMenu() {
    this.isOffCanvasMenu = !this.isOffCanvasMenu;
  }
}
