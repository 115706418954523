import type * as firestore from "firebase/firestore";
import { assertNever } from "src/app/core/util/assert";
import {
  AuditLogAssigneeAdded,
  AuditLogAssigneeDeleted,
  AuditLogClientAdded,
  AuditLogClientDeleted,
  AuditLogFolderAdded,
  AuditLogFolderDeleted,
  AuditLogInspectionAdded,
  AuditLogInspectionDeleted,
  AuditLogMemberActivated,
  AuditLogMemberDeactivated,
  AuditLogMemberNameChanged,
  AuditLogMemberRemoved,
  AuditLogRoleAdded,
  AuditLogRoleDeleted,
  AuditLogRolePermissionsChanged,
  AuditLogRoleTitleChanged,
  AuditLogSiteAdded,
  AuditLogSiteDeleted,
  AuditLogTemplateAdded,
  AuditLogTemplateDeleted,
  AuditLogType,
  AuditLogWorkspaceImageUrlChanged,
  AuditLogWorkspaceNameChanged,
  AuditLogWorkspaceOwnerChanged,
  AuditLogWorkspaceWebsiteChanged,
} from "src/app/core/util/models";
import { AuditLogEnhanced } from "src/app/models/audit-log/audit-log.model";

export interface ProcessedAuditLog {
  id: string;
  type: AuditLogType;
  type_title: string;
  date: firestore.Timestamp;
  description: string;
  image?: string;
}

export function processAuditLog(auditLog: AuditLogEnhanced): ProcessedAuditLog {
  switch (auditLog.info.type) {
    case "assignee-added":
      return processAuditLogAssigneeAdded(auditLog as AuditLogAssigneeAdded);
    case "assignee-deleted":
      return processAuditLogAssigneeDeleted(auditLog as AuditLogAssigneeDeleted);
    case "client-added":
      return processAuditLogClientAdded(auditLog as AuditLogClientAdded);
    case "client-deleted":
      return processAuditLogClientDeleted(auditLog as AuditLogClientDeleted);
    case "folder-added":
      return processAuditLogFolderAdded(auditLog as AuditLogFolderAdded);
    case "folder-deleted":
      return processAuditLogFolderDeleted(auditLog as AuditLogFolderDeleted);
    case "inspection-added":
      return processAuditLogInspectionAdded(auditLog as AuditLogInspectionAdded);
    case "inspection-deleted":
      return processAuditLogInspectionDeleted(auditLog as AuditLogInspectionDeleted);
    case "member-activated":
      return processAuditLogMemberActivated(auditLog as AuditLogMemberActivated);
    case "member-deactivated":
      return processAuditLogMemberDeactivated(auditLog as AuditLogMemberDeactivated);
    case "member-name-changed":
      return processAuditLogMemberNameChanged(auditLog as AuditLogMemberNameChanged);
    case "member-role-changed":
      // TODO(kafkas): Implement
      throw new Error(`processAuditLogMemberRoleChanged is not implemented yet.`);
    case "member-removed":
      return processAuditLogMemberRemoved(auditLog as AuditLogMemberRemoved);
    case "role-added":
      return processAuditLogRoleAdded(auditLog as AuditLogRoleAdded);
    case "role-deleted":
      return processAuditLogRoleDeleted(auditLog as AuditLogRoleDeleted);
    case "role-permissions-changed":
      return processAuditLogRolePermissionsChanged(auditLog as AuditLogRolePermissionsChanged);
    case "role-title-changed":
      return processAuditLogRoleTitleChanged(auditLog as AuditLogRoleTitleChanged);
    case "site-added":
      return processAuditLogSiteAdded(auditLog as AuditLogSiteAdded);
    case "site-deleted":
      return processAuditLogSiteDeleted(auditLog as AuditLogSiteDeleted);
    case "template-added":
      return processAuditLogTemplateAdded(auditLog as AuditLogTemplateAdded);
    case "template-deleted":
      return processAuditLogTemplateDeleted(auditLog as AuditLogTemplateDeleted);
    case "workspace-image-url-changed":
      return processAuditLogWorkspaceImageUrlChanged(auditLog as AuditLogWorkspaceImageUrlChanged);
    case "workspace-name-changed":
      return processAuditLogWorkspaceNameChanged(auditLog as AuditLogWorkspaceNameChanged);
    case "workspace-website-changed":
      return processAuditLogWorkspaceWebsiteChanged(auditLog as AuditLogWorkspaceWebsiteChanged);
    case "workspace-current-owner-id-changed":
      return processAuditLogWorkspaceOwnerChanged(auditLog as AuditLogWorkspaceOwnerChanged);
    default:
      assertNever(auditLog.info);
  }
}

function processAuditLogAssigneeAdded(auditLog: AuditLogAssigneeAdded): ProcessedAuditLog {
  const date = auditLog.date;
  const assigneeName = auditLog.info.assignee_name;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Assignee Added",
    date: date,
    description: `"${assigneeName}" added`,
  };
}

function processAuditLogAssigneeDeleted(auditLog: AuditLogAssigneeDeleted): ProcessedAuditLog {
  const date = auditLog.date;
  const assigneeName = auditLog.info.assignee_name;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Assignee Deleted",
    date: date,
    description: `"${assigneeName}" deleted`,
  };
}

function processAuditLogClientAdded(auditLog: AuditLogClientAdded): ProcessedAuditLog {
  const date = auditLog.date;
  const clientName = auditLog.info.client_name;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Client Added",
    date: date,
    description: `"${clientName}" added`,
  };
}

function processAuditLogClientDeleted(auditLog: AuditLogClientDeleted): ProcessedAuditLog {
  const date = auditLog.date;
  const clientName = auditLog.info.client_name;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Client Deleted",
    date: date,
    description: `"${clientName}" deleted`,
  };
}

function processAuditLogFolderAdded(auditLog: AuditLogFolderAdded): ProcessedAuditLog {
  const date = auditLog.date;
  const folderTitle = auditLog.info.folder_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Folder Added",
    date: date,
    description: `"${folderTitle}" added`,
  };
}

function processAuditLogFolderDeleted(auditLog: AuditLogFolderDeleted): ProcessedAuditLog {
  const date = auditLog.date;
  const folderTitle = auditLog.info.folder_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Folder Deleted",
    date: date,
    description: `"${folderTitle}" deleted`,
  };
}

function processAuditLogInspectionAdded(auditLog: AuditLogInspectionAdded): ProcessedAuditLog {
  const date = auditLog.date;
  const inspectionTitle = auditLog.info.inspection_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Inspection Added",
    date: date,
    description: `"${inspectionTitle}" added`,
  };
}

function processAuditLogInspectionDeleted(auditLog: AuditLogInspectionDeleted): ProcessedAuditLog {
  const date = auditLog.date;
  const inspectionTitle = auditLog.info.inspection_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Inspection Deleted",
    date: date,
    description: `"${inspectionTitle}" deleted`,
  };
}

function processAuditLogMemberActivated(auditLog: AuditLogMemberActivated): ProcessedAuditLog {
  const date = auditLog.date;
  const memberNameFirst = auditLog.info.member_name_first;
  const memberNameLast = auditLog.info.member_name_last;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Member Activated",
    date: date,
    description: `"${memberNameFirst} ${memberNameLast}" joined the Workspace`,
  };
}

function processAuditLogMemberDeactivated(auditLog: AuditLogMemberDeactivated): ProcessedAuditLog {
  const date = auditLog.date;
  const memberNameFirst = auditLog.info.member_name_first;
  const memberNameLast = auditLog.info.member_name_last;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Member Deactivated",
    date: date,
    description: `"${memberNameFirst} ${memberNameLast}" deactivated their account`,
  };
}

function processAuditLogMemberNameChanged(auditLog: AuditLogMemberNameChanged): ProcessedAuditLog {
  const date = auditLog.date;
  const changedFrom = auditLog.info.changed_from;
  const changedTo = auditLog.info.changed_to;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Member Name Edited",
    date: date,
    description: `Changed from "${changedFrom} to ${changedTo}"`,
  };
}

function processAuditLogMemberRemoved(auditLog: AuditLogMemberRemoved): ProcessedAuditLog {
  const date = auditLog.date;
  const memberNameFirst = auditLog.info.member_name_first;
  const memberNameLast = auditLog.info.member_name_last;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Member Removed",
    date: date,
    description: `"${memberNameFirst} ${memberNameLast}" was removed from the Workspace`,
  };
}

function processAuditLogRoleAdded(auditLog: AuditLogRoleAdded): ProcessedAuditLog {
  const date = auditLog.date;
  const roleTitle = auditLog.info.role_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Role Added",
    date: date,
    description: `"${roleTitle}" added`,
  };
}

function processAuditLogRoleDeleted(auditLog: AuditLogRoleDeleted): ProcessedAuditLog {
  const date = auditLog.date;
  const roleTitle = auditLog.info.role_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Role Deleted",
    date: date,
    description: `"${roleTitle}" deleted`,
  };
}

function processAuditLogRolePermissionsChanged(auditLog: AuditLogRolePermissionsChanged): ProcessedAuditLog {
  const date = auditLog.date;
  const roleTitle = auditLog.info.role_title;
  const changedFields = auditLog.info.changed_fields;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Role Permissions Edited",
    date: date,
    description: `"${roleTitle}" had ${changedFields.length} ${changedFields.length > 1 ? "permissions" : "permission"} changed`,
  };
}

function processAuditLogRoleTitleChanged(auditLog: AuditLogRoleTitleChanged): ProcessedAuditLog {
  const date = auditLog.date;
  const roleTitleBefore = auditLog.info.role_title_before;
  const roleTitleAfter = auditLog.info.role_title_after;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Role Title Edited",
    date: date,
    description: `"${roleTitleBefore}" changed to "${roleTitleAfter}"`,
  };
}

function processAuditLogSiteAdded(auditLog: AuditLogSiteAdded): ProcessedAuditLog {
  const date = auditLog.date;
  const siteTitle = auditLog.info.site_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Site Added",
    date: date,
    description: `"${siteTitle}" added`,
  };
}

function processAuditLogSiteDeleted(auditLog: AuditLogSiteDeleted): ProcessedAuditLog {
  const date = auditLog.date;
  const siteTitle = auditLog.info.site_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Site Deleted",
    date: date,
    description: `"${siteTitle}" deleted`,
  };
}

function processAuditLogTemplateAdded(auditLog: AuditLogTemplateAdded): ProcessedAuditLog {
  const date = auditLog.date;
  const templateTitle = auditLog.info.template_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Template Added",
    date: date,
    description: `"${templateTitle}" added`,
  };
}

function processAuditLogTemplateDeleted(auditLog: AuditLogTemplateDeleted): ProcessedAuditLog {
  const date = auditLog.date;
  const templateTitle = auditLog.info.template_title;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Template Deleted",
    date: date,
    description: `"${templateTitle}" deleted`,
  };
}

function processAuditLogWorkspaceImageUrlChanged(auditLog: AuditLogWorkspaceImageUrlChanged): ProcessedAuditLog {
  const date = auditLog.date;
  const workspace_image_url_after = auditLog.info.workspace_image_url_after;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Workspace Logo Edited",
    date: date,
    description: `Icon changed to:`,
    image: workspace_image_url_after,
  };
}

function processAuditLogWorkspaceNameChanged(auditLog: AuditLogWorkspaceNameChanged): ProcessedAuditLog {
  const date = auditLog.date;
  const workspaceNameBefore = auditLog.info.workspace_name_before;
  const workspaceNameAfter = auditLog.info.workspace_name_after;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Workspace Name Edited",
    date: date,
    description: `“${workspaceNameBefore}” changed to “${workspaceNameAfter}”`,
  };
}

function processAuditLogWorkspaceWebsiteChanged(auditLog: AuditLogWorkspaceWebsiteChanged): ProcessedAuditLog {
  const date = auditLog.date;
  const workspaceWebsiteBefore = auditLog.info.workspace_website_before;
  const workspaceWebsiteAfter = auditLog.info.workspace_website_after;
  const type = auditLog.info.type;
  const id = auditLog.id;

  let description = "";

  if (workspaceWebsiteBefore && workspaceWebsiteAfter) {
    description = `"${workspaceWebsiteBefore}" changed to "${workspaceWebsiteAfter}"`;
  } else if (!workspaceWebsiteBefore && workspaceWebsiteAfter) {
    description = `"${workspaceWebsiteAfter}" added`;
  } else if (workspaceWebsiteBefore && !workspaceWebsiteAfter) {
    description = `"${workspaceWebsiteBefore}" removed`;
  }

  return {
    id: id,
    type: type,
    type_title: "Workspace Website Edited",
    date: date,
    description: description,
  };
}

function processAuditLogWorkspaceOwnerChanged(auditLog: AuditLogWorkspaceOwnerChanged): ProcessedAuditLog {
  const date = auditLog.date;
  const workspaceCurrentOwnerAfter = auditLog.info.workspace_current_owner_after;
  const workspaceName = auditLog.info.workspace_name;
  const type = auditLog.info.type;
  const id = auditLog.id;
  return {
    id: id,
    type: type,
    type_title: "Workspace Owner Edited",
    date: date,
    description: `“${workspaceCurrentOwnerAfter}” is now the owner of “${workspaceName}”`,
  };
}
