import { inject, Injectable } from "@angular/core";
import { User } from "cip";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { SignatureEnhanced } from "src/app/models/inspection/signature.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class SignOffListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Signatures List
   * @param workspaceId
   * @param inspectionId
   */
  public getSignaturesList$(workspaceId: string, inspectionId: string): Observable<SignatureEnhanced[]> {
    const path = this.collectionsService.signaturesCol(workspaceId, inspectionId);
    const queryConstraints = [where("is_deleted", "==", false), orderBy("order", "asc")];
    return this.firestoreUtilsService.getCollectionData<SignatureEnhanced>(path, queryConstraints);
  }

  /**
   * Delete Batched Signatures
   * @param workspaceId
   * @param inspectionId
   * @param signatures
   * @param user
   */
  async deleteBatchedSignatures(workspaceId: string, inspectionId: string, batchedSignatures: SignatureEnhanced[], allSignatures: SignatureEnhanced[], user: User) {
    const path = this.collectionsService.signaturesCol(workspaceId, inspectionId);
    const operations: BatchOperation[] = [];

    // Add delete operations for each signature in batchedSignatures
    batchedSignatures.forEach((signature) => {
      const doc = `${path}/${signature.id}`;
      const lastEvent = this.lastEventService.lastEvent("deleted", user);
      const obj = { is_deleted: true, last_event: lastEvent };
      operations.push({
        type: "update",
        documentPath: doc,
        data: obj,
      });
    });

    // Filter out the signatures that are in the batchedSignatures to get the remaining ones
    const remainingSignatures = allSignatures.filter((existingSignature) => !batchedSignatures.some((batchedSignature) => batchedSignature.id === existingSignature.id));

    // Add update operations to reorder the remaining signatures
    remainingSignatures.forEach((remainingSignature, index) => {
      const updatePath = `${path}/${remainingSignature.id}`;
      operations.push({
        type: "update",
        documentPath: updatePath,
        data: { order: index },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Reorder Signatures
   * @param signatures
   * @param workspaceId
   * @param inspectionId
   * @param user
   */
  async reorderSignatures(signatures: SignatureEnhanced[], workspaceId: string, inspectionId: string, user: User) {
    const path = this.collectionsService.signaturesCol(workspaceId, inspectionId);
    let index = 0;

    const operations: BatchOperation[] = [];

    signatures.forEach((signature: SignatureEnhanced) => {
      const doc = `${path}/${signature.id}`;
      const lastEvent = this.lastEventService.lastEvent("changed", user);

      operations.push({
        type: "update",
        documentPath: doc,
        data: {
          order: index,
          last_event: lastEvent,
        },
      });

      index = index + 1;
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }
}
