<div class="grid grid-cols-1 gap-y-8">
  <div class="flex flex-row items-center justify-end px-6 sm:px-8">
    <form-state [pristine]="supplementaryForm.pristine" />
    <button
      type="button"
      (click)="saveSupplementaryForm()"
      class="btn"
      [disabled]="supplementaryForm.pristine || supplementaryForm.controls['title'].errors"
      [ngClass]="supplementaryForm.pristine || supplementaryForm.controls['title'].errors ? 'btn-disabled' : 'btn-primary'"
    >
      Save
    </button>
  </div>

  <form [formGroup]="supplementaryForm" class="content-card-wrapper">
    <div class="content-card">
      <div class="content-grid gap-y-6">
        @if (supplementaryForm.value.type !== 'internal') {
          <info-view [type]="infoViewSupplementaryEditor.type" [message]="infoViewSupplementaryEditor.message" />
        } @else {
          <info-view [type]="infoViewInternalNotes.type" [message]="infoViewInternalNotes.message" />
        }
        @if (supplementaryForm.value.type !== 'internal') {
          <div>
            <div class="form-input-wrapper">
              <label for="supplementary-title" class="form-input-label">Title</label>
              <input type="text" name="supplementary-title" id="supplementary-title" class="form-input" placeholder="Title" formControlName="title" />
            </div>
          </div>
        }

        <div class="form-input-wrapper">
          <label for="supplementary-notes" class="form-input-label capitalize">
            {{ supplementaryForm.value.type === 'internal' ? supplementaryForm.value.type + ' Notes' : supplementaryForm.value.type }}
          </label>
          <textarea
            type="text"
            name="supplementary-notes"
            id="supplementary-notes"
            class="form-input placeholder:capitalize"
            placeholder="{{ supplementaryForm.value.type === 'internal' ? supplementaryForm.value.type + ' Notes' : supplementaryForm.value.type }}"
            formControlName="content"
          ></textarea>
        </div>
      </div>
    </div>
  </form>

  <div class="flex flex-row items-center justify-end px-6 sm:px-8">
    <form-state [pristine]="supplementaryForm.pristine" />
    <button
      type="button"
      (click)="saveSupplementaryForm()"
      class="btn"
      [disabled]="supplementaryForm.pristine || supplementaryForm.controls['title'].errors"
      [ngClass]="supplementaryForm.pristine || supplementaryForm.controls['title'].errors ? 'btn-disabled' : 'btn-primary'"
    >
      Save
    </button>
  </div>
</div>
