import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'breadcrumbs',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input() workspaceId!: string;
  @Input() folderId!: string;
  @Input() inspectionId!: string;
  @Input() signatureId!: string;
  @Input() categoryId!: string;
  @Input() itemId!: string;
  @Input() photoId!: string;
  @Input() actionId!: string;
  @Input() overview!: boolean;
}
