import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { filter, Observable, tap } from "rxjs";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { FolderEnhanced } from "src/app/models/folder/folder.model";
import { OperationEnhanced } from "src/app/models/operation/operation.model";
import { InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { TemplateEnhanced } from "src/app/models/template/template.model";
import { FoldersListService } from "src/app/sections/folders/services/folders-list.service";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";
import { TemplateDetailService } from "../../services/template-detail.service";

@Component({
  selector: "manage-template-start-inspection",
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, InfoViewComponent, SearchBarComponent, AsyncPipe, NgStyle, InfoViewComponent, FormsModule, SearchEmptyComponent],
  templateUrl: "./manage-template-start-inspection.component.html",
  styleUrls: ["./manage-template-start-inspection.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateStartInspectionComponent {
  @Input() template!: TemplateEnhanced;
  @Input() duplicationObject!: OperationEnhanced;
  @Input() workspaceId!: string;
  @Output() templateToInspectionEmitter = new EventEmitter<string>();
  @Output() navigateToNewInspectionEmitter = new EventEmitter();
  @Input() navigateToText!: string;
  @Input() canUserCreateFoldersLimitationResult!: boolean;

  // Services
  public templateDetailService = inject(TemplateDetailService);
  private foldersService = inject(FoldersListService);
  private infoViewStringsService = inject(InfoViewStringsService);
  private limitationManagerService = inject(LimitationManagerService);

  // Properties
  public templateTitle!: string;
  public allOtherFolders: Array<FolderEnhanced> = [];
  public folders$!: Observable<FolderEnhanced[]>;
  public infoViewCreateInspectionFromTemplate: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.CreateInspectionFromTemplate);

  public filteredFolders: Array<FolderEnhanced> = [];
  public allFoldersHidden: boolean = false;
  public searchTerm: string = "";

  ngOnInit(): void {
    // Once the template is received, update the templateTitle string so that...
    // the ui in the component can view & modify
    this.templateTitle = this.template.title;
    this.queryFoldersForTemplateToInspection();
  }

  /**
   * Query Folders For Template To Inspection
   * This screen allows the user to `start inspection` using the existing template
   * Only when this button is clicked do we want to query the folders collection, otherwise...
   * ...we might end up with an unnecessary query if the user doesn't use this functionlaity
   * We toggle some overlay booleans to show the overlay, and query the folders
   * The folders are then passed down to the manage-templates-start-inspection child component
   */
  queryFoldersForTemplateToInspection(): void {
    this.folders$ = this.foldersService.getFoldersList$(this.workspaceId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.allOtherFolders = [];
        this.templateDetailService.currentlySelectedFolder = null;
        data.forEach((element) => {
          if (element.id === this.templateDetailService.folderId) {
            this.templateDetailService.currentlySelectedFolder = element;
          } else {
            this.allOtherFolders.push(element);
          }
        });
        this.applySearch();
      })
    );
  }

  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredFolders = this.allOtherFolders.map((folder) => ({
      ...folder,
      hidden: !folder.title.toLowerCase().includes(lowerSearchTerm),
    }));

    this.allFoldersHidden = this.filteredFolders.every((folder) => folder.hidden);
  }

  unselectClient(): void {
    this.templateDetailService.folderId === "";
    this.queryFoldersForTemplateToInspection();
  }

  /**
   * Duplicate Template As Inspection
   * This will emit a value to the parent so that the duplication of the template...
   * ...to be used as an inspection can take place. The only param will emit with it...
   * ...is the templateTite which will then be used as the inspection title
   */
  duplicateTemplateAsInspection(): void {
    this.templateToInspectionEmitter.emit(this.templateTitle);
  }

  /**
   * Show New Folder Overlay
   * This function toggles the booleans to show the new folder editor
   */
  public async showNewFolderOverlay(): Promise<void> {
    if (this.canUserCreateFoldersLimitationResult) {
      this.templateDetailService.overlay_newFolder = true;
      this.templateDetailService.overlay_folderSelector = false;
      this.templateDetailService.overlay_foldersList = false;
      this.templateDetailService.overlay_duplicate = false;
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Show Folders List
   * This functions hides the default overlay confirmation panel and...
   * ...displays the folders list. This is initiated by clicking the...
   * ...folder selector input
   */
  showFoldersList(): void {
    this.templateDetailService.overlay_duplicateConfirmation = false;
    this.templateDetailService.overlay_foldersList = true;
  }

  /**
   * Folder Selection
   * @param folder
   * When a folder is selected from the list, this function is called
   * We update the properties on the templateDetailService so that the...
   * ...folder title and ID can be used in the duplication process
   * We toggle the boolean states of the folders list (to hide)...
   * ...and the duplicate confirmation panel (to show)
   */
  folderSelection(folder: FolderEnhanced): void {
    this.templateDetailService.folderTitle = folder.title;
    this.templateDetailService.folderId = folder.id;
    this.templateDetailService.currentlySelectedFolder = folder;
    this.templateDetailService.overlay_foldersList = false;
    this.templateDetailService.overlay_duplicateConfirmation = true;
  }

  unselectFolder(): void {
    this.templateDetailService.folderTitle = "";
    this.templateDetailService.folderId = "";
    this.templateDetailService.currentlySelectedFolder = null;
  }

  /**
   * Hide Folder Selection Overlay
   * This function is called via the foldersList panel by the 'cancel' button
   * This will hide the folders list panel and show the default confirmation panel
   */
  hideFolderSelectionOverlay(): void {
    this.templateDetailService.overlay_foldersList = false;
    this.templateDetailService.overlay_duplicateConfirmation = true;
    this.searchTerm = "";
    this.allFoldersHidden = false;
    this.onSearch("");
  }

  /**
   * Toggle Duplication Overlay
   * When clicking the "Cancel" button the default overlay, this function is called
   * We set the boolean state of the overlay to false to hide it
   * We also need to reset the folder title and ID properties in the event that...
   * ...the user selected a folder and then cancels the process
   */
  toggleDuplicationOverlay(): void {
    this.templateDetailService.overlay_duplicate = false;
    this.templateDetailService.folderTitle = "";
    this.templateDetailService.folderId = "";
    this.templateDetailService.overlay_duplicateConfirmation = false;
  }

  /**
   * Navigate To New Inspection
   * When the Navigate button is clicked, emit a value to the parent to route...
   * ...to the appropriate location
   */
  navigateToNewInspection(): void {
    this.navigateToNewInspectionEmitter.emit();
  }
}
