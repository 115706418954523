import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { User } from "cip";
import { AuthService } from "src/app/core/services/auth.service";
import { InfoViewStringsService } from "src/app/core/services/strings/info-view-strings.service";
import { InfoViewFilter, InfoViewType } from "src/app/models/strings/strings.model";
import { DeleteConfirmationComponent } from "src/app/shared/delete-confirmation/delete-confirmation.component";

@Component({
  selector: "account-delete",
  standalone: true,
  imports: [DeleteConfirmationComponent, NgClass, FormsModule],
  templateUrl: "./account-delete.component.html",
  styleUrl: "./account-delete.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDeleteComponent {
  // Services
  public authService = inject(AuthService);
  private infoViewStringsService = inject(InfoViewStringsService);

  // Properties
  public isChecked = false;
  public accountEmailAddress!: string;
  public infoViewDeleteAccount1: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.DeleteAccount1);
  public infoViewDeleteAccount2: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.DeleteAccount2);
  public user: User;

  constructor() {
    this.user = this.authService.currentUser;
  }

  /**
   * Toggles the delete account overlay.
   */
  public toggleDeleteAccountOverlay() {
    this.authService.toggleDeleteAccountOverlay();
  }
}
