import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "test-no-yes",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: "./test-no-yes.component.html",
  styleUrl: "./test-no-yes.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestNoYesComponent {
  @Input({ required: true }) testForm!: FormGroup;
  public answerType = [
    { title: "No", value: "no" },
    { title: "Yes", value: "yes" },
  ];
}
