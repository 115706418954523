import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ClientEnhanced } from "src/app/models/client/client.model";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "manage-clients-list",
  standalone: true,
  imports: [SearchEmptyComponent, NgStyle],
  templateUrl: "./manage-clients-list.component.html",
  styleUrl: "./manage-clients-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageClientsListComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true })
  set clients(value: ClientEnhanced[]) {
    this._clients = value;
    this.updateFilteredClientsForSearchResults();
  }

  get clients(): ClientEnhanced[] {
    return this._clients;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredClientsForSearchResults();
  }

  @Input({ required: true }) editMode!: boolean;
  @Input() clientsBulkEditArray: ClientEnhanced[] | null = [];
  @Output() deleteSingleClientOutput = new EventEmitter<string>();
  @Output() createBatchEditOutput = new EventEmitter<ClientEnhanced>();
  @Output() routeToClientEditorOutput = new EventEmitter<string>();

  // Properties
  private _clients: ClientEnhanced[] = [];
  public _searchTerm: string = "";
  public filteredClients: ClientEnhanced[] = [];
  public allClientsHidden: boolean = false;

  updateFilteredClientsForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredClients = this.clients.map((client) => ({
      ...client,
      hidden: !client.name.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allClientsHidden = this.filteredClients.every((client) => client.hidden);
  }

  public createBatchEdit(client: ClientEnhanced): void {
    this.createBatchEditOutput.emit(client);
  }

  public deleteSingleClient(client: string): void {
    this.deleteSingleClientOutput.emit(client);
  }

  public routeToClientEditor(clientId: string): void {
    this.routeToClientEditorOutput.emit(clientId);
  }
}
