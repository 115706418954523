import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, NgZone, OnInit, Output } from '@angular/core';
import { getDownloadURL, ref, Storage, uploadBytes } from '@angular/fire/storage';
import Compressor from 'compressorjs';
import { serverTimestamp, WithFieldValue } from 'firebase/firestore';
import { forkJoin, from } from 'rxjs';
import { environment } from 'src/environments/environment';

import { User } from 'cip';
import { FirestoreUtilsService } from 'src/app/core/services/firestore/firestore-utils.service';
import { LastEventService } from 'src/app/core/services/last-event/last-event.service';
import { MultiPhotoUpload } from 'src/app/models/photo/photo-upload.model';
import { PhotoEnhanced } from 'src/app/models/photo/photo.model';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
  selector: 'photo-upload-multiple',
  standalone: true,
  imports: [LoadingSpinnerComponent],
  templateUrl: './photo-upload-multiple.component.html',
  styleUrl: './photo-upload-multiple.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoUploadMultipleComponent implements OnInit {
  @Input({ required: true }) largeMaxWidth!: number;
  @Input({ required: true }) largeMaxHeight!: number;
  @Input({ required: true }) thumbnailMaxWidth!: number;
  @Input({ required: true }) thumbnailMaxHeight!: number;
  @Input({ required: true }) path!: string;
  @Input({ required: true }) itemId!: string;
  @Input({ required: true }) imageCount!: number;
  @Input({ required: true }) user!: User;
  @Input() files!: MultiPhotoUpload[];
  @Input({ required: true }) fileType!: string;
  @Input() timestamp?: Date;
  @Input() longitude?: number | null;
  @Input() latitude?: number | null;
  // @Output() photoDataOutput = new EventEmitter();
  @Output() photoDataOutput = new EventEmitter<WithFieldValue<PhotoEnhanced>[]>();

  private ngZone = inject(NgZone);
  private fbStorage = inject(Storage);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  public successfulUploadCount = 0;
  private photoObjects: WithFieldValue<PhotoEnhanced>[] = []; // Moved to class property

  private metadataJPEG = {
    contentType: 'image/jpeg',
    cacheControl: 'public, max-age=31536000',
    customMetadata: {
      platform: 'Web: v' + environment.version,
    },
  };

  ngOnInit() {
    this.photoObjects = [];
    this.successfulUploadCount = 0;
    for (const file of this.files) {
      this.resizeImage(file);
    }
  }

  async resizeImage(file: MultiPhotoUpload) {
    const blob = await fetch(file.photo).then((r) => r.blob());
    const photoId = this.firestoreUtilsService.createFirestoreId();
    this.ngZone
      .runOutsideAngular(async () => {
        const original = await this.compressPhoto(blob, {
          quality: 0.75,
          maxWidth: this.largeMaxWidth,
          maxHeight: this.largeMaxHeight,
          checkOrientation: true,
        });
        const thumb = await this.compressPhoto(blob, {
          quality: 0.75,
          maxWidth: this.thumbnailMaxWidth,
          maxHeight: this.thumbnailMaxHeight,
          checkOrientation: true,
        });
        return {
          original,
          thumb,
        } as { original: Blob; thumb: Blob };
      })
      .then((results) => {
        this.startUpload(photoId, results, file);
      });
  }

  async startUpload(photoId: string, results: { original: Blob; thumb: Blob }, file: MultiPhotoUpload) {
    const randomIdForiOSRefresh = this.firestoreUtilsService.createFirestoreId();
    const originalStorageRef = ref(this.fbStorage, `${this.path}/${file.id}/${photoId}_image_photo_${randomIdForiOSRefresh}.${this.fileType}`);
    const thumbStorageRef = ref(this.fbStorage, `${this.path}//${file.id}/${photoId}_image_photo_thumbnail_${randomIdForiOSRefresh}.${this.fileType}`);
    const uploadOriginal = uploadBytes(originalStorageRef, results.original, this.metadataJPEG);
    const uploadThumb = uploadBytes(thumbStorageRef, results.thumb, this.metadataJPEG);

    forkJoin({
      originalUpload: from(uploadOriginal),
      thumbUpload: from(uploadThumb),
    }).subscribe(async () => {
      const originalDownloadURL = await getDownloadURL(originalStorageRef);
      const thumbDownloadURL = await getDownloadURL(thumbStorageRef);
      const lastEvent = this.lastEventService.lastEvent('added', this.user);

      const photoObject: WithFieldValue<PhotoEnhanced> = {
        id: photoId,
        order: this.imageCount + this.successfulUploadCount,
        image_url: originalDownloadURL,
        image_thumbnail_url: thumbDownloadURL,
        item_id: this.itemId,
        created_by_id: this.user.user_id,
        timestamp: serverTimestamp(),
        date_uploaded: serverTimestamp(),
        is_deleted: false,
        last_event: lastEvent,
      };
      URL.revokeObjectURL(file.photo);

      this.photoObjects.push(photoObject);
      this.successfulUploadCount++;

      if (this.successfulUploadCount === this.files.length) {
        this.photoDataOutput.emit(this.photoObjects);
      }
    });
  }

  compressPhoto(file: Blob, options: Compressor.Options | undefined) {
    return new Promise((resolve, reject) => {
      return new Compressor(file, {
        ...options,
        success: resolve,
        error: reject,
      });
    });
  }
}
