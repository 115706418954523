import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import axios, { isAxiosError } from "axios";
import { getAuth } from "firebase/auth";
import { ErrorStringsService } from "src/app/core/services/strings/error-strings.service";
import { assertNonNull } from "src/app/core/util/assert";
import { extractErrorMessage } from "src/app/core/util/extract-error-message";
import { Error, ErrorString } from "src/app/models/reports/report-error.model";
import { ThemeEnhanced } from "src/app/models/theme/theme.model";
import { InspectionDetailService } from "src/app/sections/inspections/services/inspection-detail.service";
import { ThemeStylesAndSettingsService } from "src/app/sections/workspaces/current-workspace/manage-themes/services/theme-styles-and-settings.service";
import { environment } from "src/environments/environment";
import { ReportExportStylesService } from "../../services/report-export-styles.service";
import { ReportsService } from "../../services/reports.service";
import { ReportSettingsActionAssigneeComponent } from "../report-settings-action-assignee/report-settings-action-assignee.component";
import { ReportSettingsCsvImagesComponent } from "../report-settings-csv-images/report-settings-csv-images.component";
import { ReportSettingsCsvComponent } from "../report-settings-csv/report-settings-csv.component";
import { ReportSettingsDueDateComponent } from "../report-settings-due-date/report-settings-due-date.component";
import { ReportSettingsGenerationOverlayComponent } from "../report-settings-generation-overlay/report-settings-generation-overlay.component";
import { ReportSettingsPdfCsvToggleComponent } from "../report-settings-pdf-csv-toggle/report-settings-pdf-csv-toggle.component";
import { ReportSettingsPriorityComponent } from "../report-settings-priority/report-settings-priority.component";
import { ReportSettingsShowActionsComponent } from "../report-settings-show-actions/report-settings-show-actions.component";
import { ReportSettingsThemeSelectorComponent } from "../report-settings-theme-selector/report-settings-theme-selector.component";
@Component({
  selector: "report-settings-wrapper",
  standalone: true,
  imports: [
    FormsModule,
    ReportSettingsShowActionsComponent,
    ReportSettingsActionAssigneeComponent,
    ReportSettingsPriorityComponent,
    ReportSettingsDueDateComponent,
    ReportSettingsPdfCsvToggleComponent,
    ReportSettingsCsvComponent,
    ReportSettingsCsvImagesComponent,
    ReportSettingsThemeSelectorComponent,
    ReportSettingsGenerationOverlayComponent,
  ],
  templateUrl: "./report-settings-wrapper.component.html",
  styleUrl: "./report-settings-wrapper.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsWrapperComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) themes!: ThemeEnhanced[];

  // Services
  public reportsService = inject(ReportsService);
  private themeStylesAndSettingsService = inject(ThemeStylesAndSettingsService);
  private reportExportStyles = inject(ReportExportStylesService);
  private inspectionDetailService = inject(InspectionDetailService);
  private errorStringsService = inject(ErrorStringsService);

  // Properties
  public reportError!: ErrorString;

  // TODO: Remove
  public temporaryReportFunction(): void {
    // Cover
    const coverHTML = document.getElementById("report-cover");
    const coverInnerHTML = coverHTML?.innerHTML;

    // Content
    const contentHTML = document.getElementById("report-content");
    const contentInnerHTML = contentHTML?.innerHTML;

    // Combined HTML structure
    const combinedReportHTML =
      "<!DOCTYPE html>" +
      "<html>" +
      "<head>" +
      "<meta http-equiv=Content-type content=text/html;charset=UTF-8>" +
      `<link href="https://fonts.cdnfonts.com/css/satoshi" rel="stylesheet" />` +
      '<style media=print,screen type="text/css">' +
      this.reportExportStyles.globalWrapper +
      this.themeStylesAndSettingsService.combined_themeCSS +
      this.reportExportStyles.bodyStyles +
      this.reportExportStyles.baseStyles +
      this.reportExportStyles.fontSizeStyles +
      this.reportExportStyles.chartStyles +
      this.reportExportStyles.photoSizeStyles +
      "</style>" +
      "</head>" +
      "<body>" +
      coverInnerHTML +
      contentInnerHTML +
      "</body>" +
      "</html>";

    // Create a Blob with the combined HTML content
    const blob = new Blob([combinedReportHTML], { type: "text/html" });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "report.html";

    // Append the link to the body, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the URL object
    window.URL.revokeObjectURL(url);

    // Call the original generateReport function if needed
    // this.generateReport(combinedReportHTML, '');
  }

  public generateReportStrings(): void {
    this.reportsService.reportDownloadURL.set("");
    this.reportsService.toggleGenerationOverlay();
    // Cover
    const coverHTML = document.getElementById("report-cover");
    const coverInnerHTML = coverHTML?.innerHTML;

    // Content
    const contentHTML = document.getElementById("report-content");
    const contentInnerHTML = contentHTML?.innerHTML;

    // Cover HTML Structure
    const reportCoverHTML =
      "<!DOCTYPE html>" +
      "<html>" +
      "<head>" +
      "<meta http-equiv=Content-type content=text/html;charset=UTF-8>" +
      `<link href="https://fonts.cdnfonts.com/css/satoshi" rel="stylesheet" />` +
      '<style media=print,screen type="text/css">' +
      this.reportExportStyles.globalWrapper +
      this.themeStylesAndSettingsService.combined_themeCSS +
      this.reportExportStyles.bodyStyles +
      this.reportExportStyles.baseStyles +
      this.reportExportStyles.fontSizeStyles +
      "</style>" +
      "</head>" +
      "<body>" +
      coverInnerHTML +
      "</body>" +
      "</html>";

    // Content HTML structure
    const reportContentHTML =
      "<!DOCTYPE html>" +
      "<html>" +
      "<head>" +
      "<meta http-equiv=Content-type content=text/html;charset=UTF-8>" +
      `<link href="https://fonts.cdnfonts.com/css/satoshi" rel="stylesheet" />` +
      '<style media=print,screen type="text/css">' +
      this.reportExportStyles.globalWrapper +
      this.themeStylesAndSettingsService.combined_themeCSS +
      this.reportExportStyles.bodyStyles +
      this.reportExportStyles.baseStyles +
      this.reportExportStyles.fontSizeStyles +
      this.reportExportStyles.chartStyles +
      this.reportExportStyles.photoSizeStyles +
      "</style>" +
      "</head>" +
      "<body>" +
      contentInnerHTML +
      "</body>" +
      "</html>";

    this.generateReport(reportCoverHTML, reportContentHTML);
  }

  async generateReport(reportCoverHTML: string, reportContentHTML: string): Promise<void> {
    // Retrieve Firebase authentication token
    const auth = getAuth();
    const currentUser = auth.currentUser;
    assertNonNull(currentUser, `Reports can be generated for authenticated users only.`);
    const idToken = await currentUser.getIdToken(true);

    try {
      const { data: response } = await axios.request({
        url: environment.cloudServicesBaseUrl + "/v1/pdf/" + environment.firebase.projectId,
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        data: {
          title: this.reportsService.reportData.inspection?.title,
          fileName: this.reportsService.reportData.inspection?.title,
          contentHtml: reportContentHTML,
          coverHtml: reportCoverHTML,
          userSettings: {
            showPageNumbers: true,
            customWords: {
              page: "Page",
              of: "of",
            },
          },
          workspaceId: this.workspaceId,
        },
      });

      console.log(reportContentHTML);

      // If the response returns a URL, we set the reportDownloadURL to the URL and set the inspectino preferred theme to the current theme id
      if (response.url) {
        this.reportsService.reportDownloadURL.set(response.url);

        // Once a report has been generated, we take the current theme id and save it to the "preferred_theme" field in the inspection document
        this.inspectionDetailService.savePreferredThemeToInspection(this.workspaceId, this.inspectionId, this.reportsService.themeDetails.value.id);
      }
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err.response?.data.error as Error;
        this.reportError = this.errorStringsService.errorFilter(error);
      } else {
        // TODO(kafkas): Some other unexpected error. Handle appropriately.
        alert(`Unexpected error: ${extractErrorMessage(err)}`);
      }
    }
  }
}
