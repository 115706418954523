import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "report-settings-due-date",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./report-settings-due-date.component.html",
  styleUrl: "./report-settings-due-date.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSettingsDueDateComponent {
  // Services
  public reportsService = inject(ReportsService);

  // Properties
  public toggleContent = false;

  /**
   * Toggle Content Options
   */
  public toggleContentOptions() {
    this.toggleContent = !this.toggleContent;
  }

  /**
   * On Due Date Checkbox Change
   */
  public dueDateInputChange() {
    this.reportsService.updateReportSettings(this.reportsService.reportSettings);
  }

  /**
   * On Due Date "All" Checkbox Change
   * Handles the change in state of the "All" due date checkbox in the UI.
   */
  public toggleAllDateCheckboxes() {
    const dates = ["none", "overdue", "1_week", "1_to_2_weeks", "2_weeks_to_1_month", "1_month_to_6_months", "6_months_to_1_year", "1_year_plus"];

    dates.forEach((date) => {
      this.reportsService.reportSettings[`date_${date}`] = this.reportsService.reportSettings.date_all;
    });

    this.reportsService.updateReportSettings(this.reportsService.reportSettings);
  }
}
