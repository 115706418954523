import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ConnectedDevicesComponent } from "src/app/shared/connected-devices/connected-devices.component";

@Component({
  selector: "account-connected-devices",
  standalone: true,
  imports: [ConnectedDevicesComponent],
  templateUrl: "./account-connected-devices.component.html",
  styleUrl: "./account-connected-devices.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountConnectedDevicesComponent {}
