<div>
  <div class="grid grid-cols-1 gap-y-4">
    <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
      <!-- List Component -->
      <ul id="membersList" role="list" class="list-ul">
        @for (role of roles; track $index) {
          <li class="list-row">
            <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'roles', 'default', role.id]" class="list-a group">
              <!-- Left and Right Content -->
              <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                <!-- Left Content -->
                <div class="min-w-0 flex-auto">
                  <p class="list-title">{{ role.title }}</p>
                  <p class="list-meta">{{ role.description }}</p>
                </div>
              </div>
              <!-- Right Icon -->
              <div class="flex">
                <button type="button" class="list-icon" aria-expanded="false" aria-haspopup="true">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </button>
              </div>
            </a>
          </li>
        }
      </ul>
    </div>
  </div>
</div>
