import { NgStyle } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ChartsActionsComponent } from "src/app/shared/charts/charts-actions/charts-actions.component";
import { ChartsAssigneesComponent } from "src/app/shared/charts/charts-assignees/charts-assignees.component";
import { ChartsOverallComponent } from "src/app/shared/charts/charts-overall/charts-overall.component";

@Component({
  selector: "manage-theme-edit-preview-charts-cover",
  standalone: true,
  imports: [NgStyle, ChartsOverallComponent, ChartsAssigneesComponent, ChartsActionsComponent],
  templateUrl: "./manage-theme-edit-preview-charts-cover.component.html",
  styleUrl: "./manage-theme-edit-preview-charts-cover.component.scss",
})
export class ManageThemeEditPreviewChartsCoverComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  public chartsTests = [
    {
      scoreTitle: "Scored 100%",
      totalOfThisScore: 1,
      overallPercentage: 33.33333333333333,
    },
    {
      scoreTitle: "Scored 50%",
      totalOfThisScore: 1,
      overallPercentage: 33.33333333333333,
    },
    {
      scoreTitle: "Scored 0%",
      totalOfThisScore: 1,
      overallPercentage: 33.33333333333333,
    },
  ];
  public chartsActions = [
    {
      dateTitle: "No Due Date",
      totalOfThisDate: 1,
      overallPercentage: 9.090909090909092,
      percentColour: "#D0DCF2",
      dataLength: 6,
    },
    {
      dateTitle: "< 1 week",
      totalOfThisDate: 7,
      overallPercentage: 63.63636363636363,
      percentColour: "#1450C1", // previously > 1 Year
      dataLength: 7,
    },
    {
      dateTitle: "1 week - 2 weeks",
      totalOfThisDate: 0,
      overallPercentage: 0,
      percentColour: "#2B61C7", // previously 6 months - 1 year
      dataLength: 6,
    },
    {
      dateTitle: "2 weeks - 1 month",
      totalOfThisDate: 0,
      overallPercentage: 0,
      percentColour: "#4272CD", // previously 1 month - 6 months
      dataLength: 6,
    },
    {
      dateTitle: "1 month - 6 months",
      totalOfThisDate: 0,
      overallPercentage: 0,
      percentColour: "#5A84D3", // previously 2 weeks - 1 month
      dataLength: 6,
    },
    {
      dateTitle: "6 months - 1 year",
      totalOfThisDate: 2,
      overallPercentage: 18.181818181818183,
      percentColour: "#7296D9", // previously 1 week - 2 weeks
      dataLength: 7,
    },
    {
      dateTitle: "> 1 Year",
      totalOfThisDate: 1,
      overallPercentage: 9.090909090909092,
      percentColour: "#A1B9E6", // previously < 1 week
      dataLength: 6,
    },
  ];

  public chartsAssignees = [
    {
      assignee_id: "Unassigned",
      assignee_name: "Unassigned",
      no_priority: 0,
      trivial: 1,
      low: 0,
      medium: 0,
      high: 0,
      critical: 0,
      total: 1,
    },
    {
      assignee_id: "mMv5GYWBdIT6GfJyj0bVooP6Xgd2",
      assignee_name: "Matthew Holden",
      no_priority: 0,
      trivial: 0,
      low: 0,
      medium: 0,
      high: 0,
      critical: 1,
      total: 1,
    },
  ];
}
