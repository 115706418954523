<form [formGroup]="itemForm">
  <fieldset>
    <div class="relative -space-y-px rounded-md border bg-white sm:col-span-6">
      @for (answerQuestionType of answerQuestionTypes; track $index; let last = $last; let i = $index) {
        <label
          [ngClass]="[currentAnswerQuestionType === answerQuestionType.id ? 'z-10 bg-brand_Primary/5' : 'border-gray-200', last ? 'border-none' : 'border-b']"
          (click)="setAnswerQuestionType(answerQuestionType.id)"
          class="relative flex cursor-pointer p-4 focus:outline-none"
        >
          <input
            type="radio"
            value="{{ answerQuestionType.id }}"
            class="mt-0.5 h-4 w-4 shrink-0 cursor-pointer border-gray-300 text-brand_Primary focus:ring-brand_Primary"
            aria-labelledby="item-mode-0-label"
            aria-describedby="item-mode-0-description"
            formControlName="answer_type"
          />
          <span class="ml-3 flex flex-col">
            <span [ngClass]="currentAnswerQuestionType === answerQuestionType.id ? 'text-brand_Primary' : 'text-text_Title'" id="answer-type-{{ i }}-label" class="block text-sm font-medium">{{ answerQuestionType.title }}</span>
          </span>
        </label>
      }
    </div>
  </fieldset>
</form>
