import { Injectable } from "@angular/core";
import { Error, ErrorString } from "src/app/models/reports/report-error.model";
@Injectable({
  providedIn: "root",
})
export class ErrorStringsService {
  private userSubscriptionNotActive: ErrorString = {
    code: "user-subscription-not-active",
    title: "Generation Failed",
    message: "Your subscription is not active. You are currently on a free tier. This functionality requires a subscription.",
  };

  private invalidRequest: ErrorString = {
    code: "invalid-request",
    title: "Invalid Request",
    message: "The request was invalid. Please try again.",
  };

  private missingAuth: ErrorString = {
    code: "missing-auth",
    title: "Authentication Missing",
    message: "Authentication is required for this action.",
  };

  private missingIdToken: ErrorString = {
    code: "missing-id-token",
    title: "ID Token Missing",
    message: "ID token is required for this action.",
  };

  private invalidIdToken: ErrorString = {
    code: "invalid-id-token",
    title: "Invalid ID Token",
    message: "The provided ID token is invalid.",
  };

  private userNotInWorkspace: ErrorString = {
    code: "user-not-in-workspace",
    title: "User Not in Workspace",
    message: "You do not have access to this workspace.",
  };

  private createPdfFail: ErrorString = {
    code: "create-pdf-fail",
    title: "PDF Creation Failed",
    message: "Failed to create the PDF. Please try again.",
  };

  private savePdfFail: ErrorString = {
    code: "save-pdf-fail",
    title: "PDF Save Failed",
    message: "Failed to save the PDF. Please try again.",
  };

  errorFilter(error: Error): ErrorString {
    switch (error.code) {
      case "user-subscription-not-active":
        return this.userSubscriptionNotActive;
      case "invalid-request":
        return this.invalidRequest;
      case "missing-auth":
        return this.missingAuth;
      case "missing-id-token":
        return this.missingIdToken;
      case "invalid-id-token":
        return this.invalidIdToken;
      case "user-not-in-workspace":
        return this.userNotInWorkspace;
      case "create-pdf-fail":
        return this.createPdfFail;
      case "save-pdf-fail":
        return this.savePdfFail;
      default:
        return {
          code: error.code,
          title: "Error",
          message: error.message,
        };
    }
  }
}
