@if (combinedData$ | async; as combinedData) {
  <inspection-overview-header [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [inspection]="combinedData.inspection" />
  <!-- Content -->
  <div class="space-y-4">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-6">
      <div class="md:col-span-4">
        <inspection-overview-contents
          [workspaceId]="workspaceId"
          [folderId]="folderId"
          [inspectionId]="inspectionId"
          [percentageOfAnsweredTests]="inspectionItemsListService.percentageOfAnsweredTests"
          [totalTests]="inspectionItemsListService.totalTests"
          [totalTestsWithAnswers]="inspectionItemsListService.totalTestsWithAnswers"
          (navigateToSupplementary)="navigateToSupplementary($event)" />
      </div>
      <div class="md:col-span-2">
        <!-- <inspection-overview-actions [inspection]="data.inspection" [workspaceId]="data.routeParams.workspaceId" [folderId]="data.routeParams.folderId" [inspectionId]="data.routeParams.inspectionId"></inspection-overview-actions> -->
        <inspection-overview-actions [inspection]="combinedData.inspection" [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [user]="user" />
      </div>
    </div>

    <div class="grid gap-4 md:grid-cols-6">
      <div class="col-span-4">
        <div class="grid gap-y-4">
          <inspection-overview-sign-off [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [signatures]="combinedData.signatures" />
          <inspection-overview-stats-wrapper
            [chartsAssignees]="chartsAssignees"
            [actionResults]="actionResults"
            [chartsResults]="chartsResults"
            [totalActions]="chartsService.totalActions"
            [assigneeHighestActionCount]="chartsService.assigneeHighestActionCount"
            [totalTests]="chartsService.totalTests" />
        </div>
      </div>
      <div class="col-span-4 md:col-span-2">
        <inspection-overview-details
          [inspection]="combinedData.inspection"
          [workspaceId]="workspaceId"
          [folderId]="folderId"
          [inspectionId]="inspectionId"
          [site]="site$ | async"
          [showSiteInfo]="showSiteInfo"
          (editDetailsOutput)="editDetails()"
          (toggleSiteOutput)="toggleSite($event)" />
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}
