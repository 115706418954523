@if (reportsService.reportData.actions.length > 0) {
  <div class="action-wrapper">
    <p class="action-header text-group-2">Actions</p>
    <ul>
      @for (action of reportsService.actionsFiltered; track action.id) {
        @if (action.item_id === itemId) {
          <li>
            <div class="action-li-wrapper">
              <div class="action-content">
                <div class="action-content-left">
                  <p class="action-title text-group-3">{{ action.order + 1 }}. {{ action.title }}</p>
                  @if (action.assignee_name || action.assignee_email) {
                    <div class="action-assignee-wrapper" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">
                      @if (action.assignee_name) {
                        <p class="action-assignee-name text-group-6">{{ action.assignee_name }}</p>
                      }
                      @if (action.assignee_email) {
                        <span>&#8226;</span>
                        <p class="action-assignee-email text-group-6">{{ action.assignee_email }}</p>
                      }
                    </div>
                  }
                  @if (action.due_date) {
                    <p class="action-date text-group-6" [ngStyle]="{ color: reportsService.themeDetails.value.colours_subtitle }">
                      {{ action.due_date.toDate() | dateFormat: reportsService.themeDetails.value.dates_order : reportsService.themeDetails.value.dates_style }}
                    </p>
                  }
                </div>
                <div class="action-content-right">
                  @if (action.priority || action.priority === 0) {
                    <div class="action-priority text-group-5 action-priority-{{ actionsListService.priorityDict[action.priority].priority }}">
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M7 9.5C8.10457 9.5 9 8.60457 9 7.5C9 6.39543 8.10457 5.5 7 5.5C5.89543 5.5 5 6.39543 5 7.5C5 8.60457 5.89543 9.5 7 9.5Z" />
                          <rect x="0.5" y="1" width="13" height="13" rx="6.5" />
                        </svg>
                        <p>{{ actionsListService.priorityDict[action.priority].priority }}</p>
                      </div>
                    </div>
                  }
                  @if (action.complete) {
                    <ng-container [ngTemplateOutlet]="complete"></ng-container>
                  } @else {
                    <ng-container [ngTemplateOutlet]="incomplete"></ng-container>
                  }
                </div>
              </div>
            </div>
          </li>
        }
      }
    </ul>
  </div>
}

<!-- Complete -->
<ng-template #complete>
  <div class="action-complete text-group-5">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fill="none">
        <rect y="0.5" width="14" height="14" rx="7" fill="white" />
        <path d="M10 5.5L5.91489 9.5L4 7.62792" stroke="#439457" stroke-linecap="square" stroke-linejoin="round" />
      </svg>
      <p>Complete</p>
    </div>
  </div>
</ng-template>

<!-- Incomplete -->
<ng-template #incomplete>
  <div class="action-incomplete text-group-5">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fill="none">
        <rect y="0.5" width="14" height="14" rx="7" fill="#B9C5CE" />
        <path d="M7 3.5C7 3.5 7 6.433 7 9.16667M7 11.5V10.5V10.1667" stroke="#F4F7FA" />
      </svg>
      <p>Incomplete</p>
    </div>
  </div>
</ng-template>
