import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "question-number",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./question-number.component.html",
  styleUrl: "./question-number.component.scss",
})
export class QuestionNumberComponent {
  @Input({ required: true }) testForm!: FormGroup;

  /**
   * Remove Answer
   */
  removeAnswer(): void {
    this.testForm.patchValue(
      {
        question_number: null,
        answered: false,
      },
      {
        emitEvent: true,
        onlySelf: false,
      }
    );
    this.testForm.markAsDirty();
  }
}
