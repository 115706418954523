@if (reportsService.reportSettings.pdf_or_csv === "PDF") {
  <div class="grid-col-1 mb-28 grid">
    <div class="mt-6 w-full border-b border-gray-200 py-6">
      <button class="btn btn-primary w-full" style="width: 100%" type="button" aria-label="Generate Report" (click)="generateReportStrings()">Generate Report</button>
      <button class="btn btn-secondary mt-10 w-full" style="width: 100%" type="button" aria-label="Generate HTML" (click)="temporaryReportFunction()">TEMP: Generate HTML</button>
    </div>
    <report-settings-pdf-csv-toggle />
    <report-settings-show-actions />
    @if (reportsService.reportSettings.show_actions !== "None") {
      <report-settings-action-assignee />
      <report-settings-priority />
      <report-settings-due-date />
    }
    <report-settings-theme-selector [themes]="themes" />
  </div>
}
@if (reportsService.reportSettings.pdf_or_csv === "CSV") {
  <div class="grid-col-1 mb-28 grid">
    <report-settings-pdf-csv-toggle />
    <report-settings-csv-images />
    <report-settings-csv [workspaceId]="workspaceId" />
  </div>
}

@if (reportsService.overlay_reportGeneration) {
  <report-settings-generation-overlay [reportError]="reportError" />
}
