import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { filter, Observable, tap } from "rxjs";
import { AssigneeEnhanced } from "src/app/models/assignee/assignee.model";
import { AssigneesListService } from "src/app/sections/workspaces/current-workspace/manage-assignees/services/assignees-list.service";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "action-edit-assignee-selector",
  standalone: true,
  imports: [LoadingSpinnerComponent, AsyncPipe, NgClass, SearchBarComponent, SearchEmptyComponent, JsonPipe, EmptyListComponent],
  templateUrl: "./action-edit-assignee-selector.component.html",
  styleUrl: "./action-edit-assignee-selector.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEditAssigneeSelectorComponent implements OnInit {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) actionForm!: FormGroup;
  @Input({ required: true }) assigneeForm!: FormGroup;
  @Input({ required: true }) overlay_assigneeForm!: boolean;
  @Input({ required: true }) overlay_assigneeSelector!: boolean;
  @Output() toggleAssigneeFormOverlayOutput = new EventEmitter<boolean>();
  @Output() assigneeSelectionOutput = new EventEmitter<AssigneeEnhanced>();

  // Services
  private assigneesListService = inject(AssigneesListService);

  // Properties
  public currentlySelectedAssignee!: AssigneeEnhanced | null;
  public allOtherAssignees: Array<AssigneeEnhanced> = [];
  public assignees$!: Observable<AssigneeEnhanced[]>;
  public filteredAssignees: Array<AssigneeEnhanced> = [];
  public allAssigneesHidden: boolean = false;
  public searchTerm: string = "";

  ngOnInit(): void {
    this.getAssigneesList();
  }

  /**
   * Get Assignees List
   */
  private async getAssigneesList(): Promise<void> {
    this.assignees$ = this.assigneesListService.getAssigneesList$(this.workspaceId).pipe(
      filter((data) => !!data),
      tap((data) => {
        this.filteredAssignees = [];
        this.currentlySelectedAssignee = null;
        data.forEach((element) => {
          if (element.id === this.actionForm.value["assignee_id"]) {
            this.currentlySelectedAssignee = element;
          } else {
            this.filteredAssignees.push(element);
            this.allOtherAssignees.push(element);
          }
        });
      })
    );
  }

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.applySearch();
  }

  /**
   * Apply Search
   */
  private applySearch(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredAssignees = this.allOtherAssignees.map((assignee) => ({
      ...assignee,
      hidden: !assignee.name.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allAssigneesHidden = this.filteredAssignees.every((assignee) => assignee.hidden);
  }

  /**
   * Unselect Assignee
   */
  public unselectAssignee(): void {
    this.actionForm.patchValue({
      assignee_id: null,
      assignee_name: null,
      assignee_type: null,
      assignee_email: null,
      assignee_company: null,
    });
    this.currentlySelectedAssignee = null;
    this.actionForm.markAsDirty();
    this.getAssigneesList();
  }

  /**
   * Select Assignee
   * @param assignee
   */
  public selectAssignee(assignee: AssigneeEnhanced): void {
    this.assigneeSelectionOutput.emit(assignee);
  }

  /**
   * Toggle client Form Overlay
   */
  public toggleAssigneeFormOverlay(): void {
    this.toggleAssigneeFormOverlayOutput.emit(true);
  }
}
