import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'enable-disable-overlay',
  standalone: true,
  imports: [],
  templateUrl: './enable-disable-overlay.component.html',
  styleUrl: './enable-disable-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnableDisableOverlayComponent {
  @Input({ required: true }) overlayTitle!: string;
  @Input({ required: true }) overlayDescription!: string;
  @Output() enabled = new EventEmitter<boolean>();
  @Output() cancelButtonClicked = new EventEmitter();

  /**
   * Cancel
   */
  cancel(): void {
    this.cancelButtonClicked.emit();
  }

  /**
   * Enabled State
   */
  enableState(enabled: boolean): void {
    this.enabled.emit(enabled);
  }
}
