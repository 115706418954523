import { inject, Injectable } from "@angular/core";
import { Item, ItemTestAnswer, User } from "cip";
import { deleteField, increment, limit, orderBy, where, WithFieldValue } from "firebase/firestore";
import { catchError, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { GetCountFromServerService } from "src/app/core/services/counts/get-count-from-server.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { categoryItemCountsChecker } from "src/app/models/item/item-counts.model";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { PhotoEnhanced } from "src/app/models/photo/photo.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class InspectionItemDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private getCountFromServerService = inject(GetCountFromServerService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Item Doc
   * @param workspaceId
   * @param inspectionId
   * @param itemId
   */
  public getItemDoc$(workspaceId: string, inspectionId: string, itemId: string): Observable<ItemEnhanced | null> {
    const path = `${this.collectionsService.inspectionItemsCol(workspaceId, inspectionId)}/${itemId}`;
    return this.firestoreUtilsService.getDocumentData<ItemEnhanced>(path).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  public getPreviousItem$(workspaceId: string, inspectionId: string, currentOrder: number, categoryId: string): Observable<ItemEnhanced | null> {
    const path = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const queryConstraints = [where("category_id", "==", categoryId), where("is_deleted", "==", false), where("order", "<", currentOrder), orderBy("order", "desc"), limit(1)];
    return this.firestoreUtilsService.getCollectionData<ItemEnhanced>(path, queryConstraints).pipe(
      map((items: ItemEnhanced[]) => (items.length > 0 ? items[0] : null)) // Map the array to the first item or null
    );
  }

  public getNextItem$(workspaceId: string, inspectionId: string, currentOrder: number, categoryId: string): Observable<ItemEnhanced | null> {
    const path = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const queryConstraints = [where("category_id", "==", categoryId), where("is_deleted", "==", false), where("order", ">", currentOrder), orderBy("order", "asc"), limit(1)];
    return this.firestoreUtilsService.getCollectionData<ItemEnhanced>(path, queryConstraints).pipe(
      map((items: ItemEnhanced[]) => (items.length > 0 ? items[0] : null)) // Map the array to the first item or null
    );
  }

  /**
   * Set New Item Doc
   * @param workspaceId
   * @param inspectionId
   * @param categoryId
   * @param itemForm
   * @param user
   */
  public async setNewItemDoc(workspaceId: string, inspectionId: string, categoryId: string, itemForm: ItemEnhanced, user: User) {
    // writeEventType
    const lastEvent = this.lastEventService.lastEvent("added", user);

    // Item
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const itemId = this.firestoreUtilsService.createFirestoreId();
    const itemDoc = `${itemPath}/${itemId}`;
    const itemCount = await this.getCountFromServerService.getItemsCount(itemPath, categoryId);
    const itemObj = {
      title: itemForm.title.trim(),
      enabled: true,
      answer_type: itemForm.answer_type,
      answered: false,
      order: itemCount,
      mode: itemForm.mode,
      category_id: categoryId,
      actions_count: 0,
      photos_count: 0,
      created_by_id: user.user_id,
      last_event: lastEvent,
      is_deleted: false,
      notes: itemForm.notes == null || itemForm.notes == "" ? deleteField() : itemForm.notes.trim(),
    };

    // Category
    const categoryPath = this.collectionsService.inspectionCategoriesCol(workspaceId, inspectionId);
    const categoryDoc = `${categoryPath}/${categoryId}`;

    // Batch
    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: itemDoc,
        data: itemObj,
      },
      {
        type: "set",
        documentPath: categoryDoc,
        data: { items_count: increment(+1) },
      },
    ];

    try {
      await this.firestoreUtilsService.batchWrite(operations);
      return { ...itemObj, id: itemId };
    } catch (error) {
      throw error;
    }
  }

  /**
   * Save Item
   * @param workspaceId
   * @param inspectionId
   * @param categoryId
   * @param itemId
   * @param itemForm
   * @param hasTestAnswer
   * @param user
   * @param currentItem
   */
  public async saveItem(workspaceId: string, inspectionId: string, categoryId: string, itemId: string, itemForm: ItemEnhanced, hasTestAnswer: boolean, user: User, currentItem: ItemEnhanced) {
    // ID
    const itemIdToUse = itemId === "new" ? this.firestoreUtilsService.createFirestoreId() : itemId;

    // Paths
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const categoryPath = this.collectionsService.inspectionCategoriesCol(workspaceId, inspectionId);
    const actionsPath = this.collectionsService.actionsCol(workspaceId);
    const photosPath = this.collectionsService.photosCol(workspaceId, inspectionId);

    // Actions
    const actionsCount = await this.getCountFromServerService.getItemActionsCount(actionsPath, itemId);

    // Answer Type Changed?
    const answerTypeChanged = currentItem?.answer_type !== itemForm.answer_type && currentItem?.answered;

    // Item Count for ordering
    const itemCount = await this.getCountFromServerService.getItemsCount(itemPath, categoryId);

    // writeEventType
    const writeEventType = itemId === "new" ? "added" : "changed";
    const lastEvent = this.lastEventService.lastEvent(writeEventType, user);

    const categoryItemCountsChecker = await this.doesCategoryItemCountsNeedUpdating(currentItem, itemForm);
    const photoDocCountsChecker = await this.getCountFromServerService.getTestImagesCount(photosPath, itemId);

    // Item
    const itemObj = {
      title: itemForm.title.trim(),
      enabled: itemForm.enabled ?? true,
      answer_type: itemForm.answer_type,
      answered: itemForm.answered === null || answerTypeChanged ? false : itemForm.answered,
      order: itemForm.order ?? itemCount,
      mode: itemForm.mode,
      category_id: categoryId,
      actions_count: actionsCount,
      photos_count: photoDocCountsChecker ?? 0,
      created_by_id: itemForm.created_by_id ?? user.user_id,
      last_event: lastEvent,
      is_deleted: false,
      test_answer: !hasTestAnswer || itemForm.test_answer === null || answerTypeChanged ? deleteField() : itemForm.test_answer,
      notes: itemForm.notes == null || itemForm.notes == "" ? deleteField() : itemForm.notes.trim(),
      question_number: itemForm.question_number === null ? deleteField() : itemForm.question_number,
      question_string: itemForm.question_string === "" || itemForm.question_string === null ? deleteField() : itemForm.question_string,
      question_bool: itemForm.question_bool === null ? deleteField() : itemForm.question_bool,
      question_date: itemForm.question_date === null || itemForm.question_date === undefined ? deleteField() : itemForm.question_date,
      question_time: !itemForm.question_time || itemForm.question_time === null ? deleteField() : itemForm.question_time,
    };

    // Batch Operations
    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: `${itemPath}/${itemIdToUse}`,
        data: itemObj,
      },
    ];

    // Add operations for new items
    if (itemId === "new") {
      operations.push({
        type: "update",
        documentPath: `${categoryPath}/${categoryId}`,
        data: { items_count: increment(1) },
      });
    }

    // If answer type changed, update the completed items count
    if (answerTypeChanged) {
      operations.push({
        type: "update",
        documentPath: `${categoryPath}/${categoryId}`,
        data: { items_completed_count: increment(-1) },
      });
    }

    if (categoryItemCountsChecker.decrementItemsCount) {
      operations.push({
        type: "update",
        documentPath: `${categoryPath}/${categoryId}`,
        data: {
          items_count: increment(-1),
          items_completed_count: increment(categoryItemCountsChecker.decrementItemsCompletedCount ? -1 : 0),
        },
      });
    }

    if (categoryItemCountsChecker.incrementItemsCount) {
      operations.push({
        type: "update",
        documentPath: `${categoryPath}/${categoryId}`,
        data: {
          items_count: increment(1),
          items_completed_count: increment(categoryItemCountsChecker.incrementItemsCompletedCount ? 1 : 0),
        },
      });
    }

    if (categoryItemCountsChecker.decrementItemsCompletedCount && !categoryItemCountsChecker.decrementItemsCount) {
      operations.push({
        type: "update",
        documentPath: `${categoryPath}/${categoryId}`,
        data: {
          items_completed_count: increment(-1),
        },
      });
    }

    if (categoryItemCountsChecker.incrementItemsCompletedCount && !categoryItemCountsChecker.incrementItemsCount) {
      operations.push({
        type: "update",
        documentPath: `${categoryPath}/${categoryId}`,
        data: {
          items_completed_count: increment(1),
        },
      });
    }

    try {
      await this.firestoreUtilsService.batchWrite(operations);
      return { itemId: itemIdToUse, itemObj };
    } catch (error) {
      alert(error);
      throw error;
    }
  }

  /**
   * Save Photo Docs And Item
   * @param workspaceId
   * @param inspectionId
   * @param categoryId
   * @param itemId
   * @param itemForm
   * @param user
   * @param photoDocs
   */
  public async savePhotoDocsAndItem(workspaceId: string, inspectionId: string, categoryId: string, itemId: string, itemForm: ItemEnhanced, user: User, photoDocs: WithFieldValue<PhotoEnhanced>[]): Promise<void> {
    // Items
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const itemCount = await this.getCountFromServerService.getItemsCount(itemPath, categoryId);
    const itemLastEvent = this.lastEventService.lastEvent("changed", user);

    const itemObj = {
      title: itemForm.title.trim(),
      enabled: itemForm.enabled,
      answer_type: itemForm.answer_type,
      answered: itemForm.answered === null ? false : itemForm.answered,
      order: itemForm.order ?? itemCount,
      mode: itemForm.mode,
      category_id: categoryId,
      actions_count: itemForm.actions_count,
      photos_count: itemForm.photos_count,
      created_by_id: itemForm.created_by_id,
      last_event: itemLastEvent,
      is_deleted: false,
      test_answer: itemForm.test_answer === null ? deleteField() : itemForm.test_answer,
      notes: itemForm.notes == null || itemForm.notes == "" ? deleteField() : itemForm.notes.trim(),
    };

    const operations: BatchOperation[] = [];

    // Item Batch
    operations.push({
      type: "set",
      documentPath: `${itemPath}/${itemId}`,
      data: itemObj,
    });

    // Photo Batch
    const photosPath = this.collectionsService.photosCol(workspaceId, inspectionId);
    photoDocs.forEach((photo) => {
      const photoId = photo.id;
      const photoDocPath = `${photosPath}/${photoId}`;
      const imageObj = {
        order: photo.order,
        image_url: photo.image_url,
        image_thumbnail_url: photo.image_thumbnail_url,
        item_id: photo.item_id,
        created_by_id: photo.created_by_id,
        timestamp: photo.timestamp,
        date_uploaded: photo.date_uploaded,
        is_deleted: photo.is_deleted,
        last_event: photo.last_event,
      };
      operations.push({
        type: "set",
        documentPath: photoDocPath,
        data: imageObj,
      });
    });

    try {
      return await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      alert(error);
      throw error;
    }
  }

  /**
   * Delete Item Doc
   * @param workspaceId
   * @param inspectionId
   * @param categoryId
   * @param item
   * @param allItems
   * @param user
   */
  public async deleteItemDoc(workspaceId: string, inspectionId: string, categoryId: string, item: ItemEnhanced, allItems: ItemEnhanced[], user: User) {
    // Last Event
    const lastEvent = this.lastEventService.lastEvent("deleted", user);

    // Item
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const itemDoc = `${itemPath}/${item.id}`;
    const itemAnswered = !!item.answered;

    // Category
    const categoryPath = this.collectionsService.inspectionCategoriesCol(workspaceId, inspectionId);
    const categoryDoc = `${categoryPath}/${categoryId}`;

    // Batch
    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: itemDoc,
        data: { is_deleted: true, last_event: lastEvent },
      },
    ];

    operations.push({
      type: "update",
      documentPath: categoryDoc,
      data: { items_count: increment(-1) },
    });

    // Remaining Items
    const remainingItems = allItems.filter((existingItem) => existingItem.id !== item.id);
    remainingItems.forEach((remainingItem, index) => {
      const updatePath = `${itemPath}/${remainingItem.id}`;
      operations.push({
        type: "update",
        documentPath: updatePath,
        data: { order: index },
      });
    });

    if (itemAnswered) {
      operations.push({
        type: "update",
        documentPath: categoryDoc,
        data: { items_completed_count: increment(-1) },
      });
    }
    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Delete Batched Items
   * @param workspaceId
   * @param inspectionId
   * @param categoryId
   * @param batchedItems
   * @param allItems
   * @param user
   */
  public async deleteBatchedItems(workspaceId: string, inspectionId: string, categoryId: string, batchedItems: ItemEnhanced[], allItems: ItemEnhanced[], user: User) {
    // Last Event
    const lastEvent = this.lastEventService.lastEvent("deleted", user);

    // Paths
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const categoryPath = this.collectionsService.inspectionCategoriesCol(workspaceId, inspectionId);

    // Category Doc
    const categoryDoc = `${categoryPath}/${categoryId}`;

    // Batch
    const operations: BatchOperation[] = [];

    batchedItems.forEach((item) => {
      const itemDoc = `${itemPath}/${item.id}`;
      const lastEvent = this.lastEventService.lastEvent("deleted", user);
      operations.push({
        type: "update",
        documentPath: itemDoc,
        data: { is_deleted: true, last_event: lastEvent },
      });
      operations.push({
        type: "update",
        documentPath: categoryDoc,
        data: { items_count: increment(-1) },
      });
      if (item.answered) {
        operations.push({
          type: "update",
          documentPath: categoryDoc,
          data: { items_completed_count: increment(-1) },
        });
      }
    });

    // Filter out the Categories that are in the batchedCategories to get the remaining ones
    const remainingItems = allItems.filter((existingItem) => !batchedItems.some((batchedItem) => batchedItem.id === existingItem.id));

    // Add update operations to reorder the remaining Categories
    remainingItems.forEach((remainingItem, index) => {
      const updatePath = `${itemPath}/${remainingItem.id}`;
      operations.push({
        type: "update",
        documentPath: updatePath,
        data: { order: index },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Update Test Answer From List
   * @param workspaceId
   * @param inspectionId
   * @param categoryId
   * @param item
   * @param testAnswer
   * @param isItemAnswered
   * @param user
   */
  public async updateTestAnswerFromList(workspaceId: string, inspectionId: string, categoryId: string, item: ItemEnhanced, testAnswer: ItemTestAnswer, isItemAnswered: boolean, user: User): Promise<void> {
    const itemPath = this.collectionsService.inspectionItemsCol(workspaceId, inspectionId);
    const itemDoc = `${itemPath}/${item.id}`;
    const itemsCompletedModifier = this.doesItemsCompletedCountNeedUpdating(item.answered, isItemAnswered);
    const categoryPath = this.collectionsService.inspectionCategoriesCol(workspaceId, inspectionId);
    const categoryDoc = `${categoryPath}/${categoryId}`;
    const lastEvent = this.lastEventService.lastEvent("changed", user);

    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: itemDoc,
        data: {
          answered: isItemAnswered,
          test_answer: isItemAnswered ? testAnswer : deleteField(),
          last_event: lastEvent,
        },
      },
    ];

    if (itemsCompletedModifier === -1 || itemsCompletedModifier === +1) {
      operations.push({
        type: "update",
        documentPath: categoryDoc,
        data: { items_completed_count: increment(itemsCompletedModifier) },
      });
    }

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Does Category Item Counts Need Updating
   * @param item
   * @param itemForm
   * @returns
   */
  private async doesCategoryItemCountsNeedUpdating(item: Item, itemForm: Item): Promise<categoryItemCountsChecker> {
    const updateInfo: categoryItemCountsChecker = {
      decrementItemsCount: false,
      incrementItemsCount: false,
      decrementItemsCompletedCount: false,
      incrementItemsCompletedCount: false,
    };

    if (item.enabled && !itemForm.enabled) {
      updateInfo.decrementItemsCount = true;
      updateInfo.decrementItemsCompletedCount = item.answered;
    }

    if (!item.enabled && itemForm.enabled) {
      updateInfo.incrementItemsCount = true;
      updateInfo.incrementItemsCompletedCount = itemForm.answered;
    }

    if (item.answered && !itemForm.answered && item.enabled === itemForm.enabled) {
      updateInfo.decrementItemsCompletedCount = true;
    }

    if (!item.answered && itemForm.answered && item.enabled === itemForm.enabled) {
      updateInfo.incrementItemsCompletedCount = true;
    }
    return updateInfo;
  }

  /**
   * Does Items Completed Count Need Updating
   * @param oldItemAnswered
   * @param newItemAnswered
   * @returns
   */
  private doesItemsCompletedCountNeedUpdating(oldItemAnswered: boolean, newItemAnswered: boolean) {
    const isOldAnswered = oldItemAnswered;
    const isNewAnswered = newItemAnswered;

    if (isOldAnswered !== isNewAnswered) {
      return isNewAnswered ? +1 : -1;
    }

    return 0;
  }
}
