@if (client$ | async; as client) {
  <manage-client-edit-header [workspaceId]="workspaceId" />
  <div class="grid grid-cols-1 gap-y-8">
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="clientForm.pristine === true || !!clientForm.controls['name'].errors" />
      <button type="button" (click)="doesAPhotoNeedUploading()" class="btn" [disabled]="clientForm.pristine || clientForm.invalid" [ngClass]="clientForm.pristine || clientForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
    </div>

    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Details</h3>
      </div>
      <div class="px-6 py-5">
        <div class="content-grid gap-x-4 gap-y-6">
          <!-- Details -->

          <manage-client-edit-details [clientForm]="clientForm" />

          <div>
            <div class="form-input-label mb-2 px-3">Client Photo</div>

            @if (clientForm.value.image_thumbnail_url) {
              <div class="relative flex h-32 w-32 rounded-md bg-gray-600 bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + clientForm.value.image_thumbnail_url + ')' }">
                <div class="absolute right-2 top-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-white bg-brand_Destructive text-white hover:bg-red-500" (click)="toggleDeleteClientImageOverlay()">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                  </svg>
                </div>
              </div>
            }

            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <!-- Image Dropzone -->
                @if (!clientForm.value.image_thumbnail_url && uploadFileArray.length < 1) {
                  <photo-dropzone [uploadSourceComponent]="'client'" (detectPhotosOutput)="detectPhotos($event)" />
                }

                <!-- Image Preview -->
                @if (photoPreview && uploadFileArray.length > 0) {
                  <photo-preview [photosToPreview]="uploadFileArray" [uploadLimit]="1" [uploadSourceComponent]="'client'" (removePhotoFromArrayOutput)="removePhotoFromArray($event)" />
                }

                <!-- Image Uploading-->
                @if (overlay_uploadPhotos) {
                  @for (photo of uploadFileArray; track photo.id) {
                    <photo-upload-single
                      [file]="photo.photo"
                      [path]="'workspaces/' + workspaceId + '/clients/' + clientId"
                      [fileType]="'jpeg'"
                      [largeMaxWidth]="1200"
                      [largeMaxHeight]="1200"
                      [thumbnailMaxWidth]="300"
                      [thumbnailMaxHeight]="300"
                      [uploadSourceComponent]="'client'"
                      (photoDataOutput)="patchFormWithPhotosAndSaveDoc($event)" />
                  }
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      <button type="button" class="btn btn-destructive mr-3" (click)="canUserDeleteClient()">Delete Client</button>
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="clientForm.pristine === true || !!clientForm.controls['name'].errors"></form-state>
        <button type="button" (click)="doesAPhotoNeedUploading()" class="btn" [disabled]="clientForm.pristine || clientForm.invalid" [ngClass]="clientForm.pristine || clientForm.invalid ? 'btn-disabled' : 'btn-primary'">Save</button>
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteClient) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (deleteButtonClicked)="deleteClient()" (cancelButtonClicked)="toggleDeleteClientOverlay()" />
}

@if (overlay_deleteClientImage) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteClientImageOverlay()" (deleteButtonClicked)="removeClientImagePropertiesFromForm()" />
}
