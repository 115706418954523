@if (combinedData$ | async; as combinedData) {
  <div class="flex">
    <div class="h-[calc(100vh-64px)] w-[360px] flex-shrink-0 overflow-y-scroll overscroll-contain bg-white px-4">
      <manage-theme-edit-settings-wrapper [themeSettingsForm]="themeSettingsForm" [themeId]="themeId" [themeStylesByType]="themeStylesByType" (toggleDeleteOverlayOutput)="canUserDeleteTheme()" />
    </div>

    <!-- Right Column -->
    <div class="h-[calc(100vh-64px)] flex-grow overflow-y-scroll overscroll-contain bg-[#F1F4F7] px-4">
      <manage-theme-edit-header [infoViewThemeEditor]="infoViewThemeEditor" [workspaceId]="workspaceId" [themeId]="themeId" />
      <div class="mx-auto max-w-7xl px-6 pb-10 xl:px-0">
        <div class="grid grid-cols-1 gap-y-8">
          <div class="flex flex-row items-center justify-between px-6 sm:px-8">
            <form-state [pristine]="!themeSettingsForm.dirty"></form-state>
            <button
              type="button"
              class="btn"
              [disabled]="themeSettingsForm.pristine || themeSettingsForm.controls['title'].invalid"
              [ngClass]="themeSettingsForm.pristine || themeSettingsForm.controls['title'].invalid ? 'btn-disabled' : 'btn-primary'"
              (click)="saveThemeDoc()">
              Save
            </button>
          </div>
          <manage-theme-edit-preview-wrapper [themeSettingsForm]="themeSettingsForm" />
          <div class="flex flex-row items-center justify-between px-6 sm:px-8">
            <form-state [pristine]="!themeSettingsForm.dirty"></form-state>
            <button
              type="button"
              class="btn"
              [disabled]="themeSettingsForm.pristine || themeSettingsForm.controls['title'].invalid"
              [ngClass]="themeSettingsForm.pristine || themeSettingsForm.controls['title'].invalid ? 'btn-disabled' : 'btn-primary'"
              (click)="saveThemeDoc()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}

@if (overlay_deleteTheme) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteOverlay()" (deleteButtonClicked)="deleteThemeDoc()"></delete-overlay>
}
