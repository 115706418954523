import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "manage-site-edit-details",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./manage-site-edit-details.component.html",
  styleUrl: "./manage-site-edit-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageSiteEditDetailsComponent {
  @Input({ required: true }) siteForm!: FormGroup;
}
