import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "question-bool",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./question-bool.component.html",
  styleUrl: "./question-bool.component.scss",
})
export class QuestionBoolComponent {
  @Input({ required: true }) testForm!: FormGroup;

  /**
   * Remove Answer
   */
  removeAnswer(): void {
    this.testForm.patchValue(
      {
        question_bool: null,
        answered: false,
      },
      {
        emitEvent: true,
        onlySelf: false,
      }
    );
    this.testForm.markAsDirty();
  }
}
