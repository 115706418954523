import { inject, Injectable } from "@angular/core";
import { Item, User } from "cip";
import { increment, limit, orderBy, where } from "firebase/firestore";
import { catchError, map, Observable, of } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { GetCountFromServerService } from "src/app/core/services/counts/get-count-from-server.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { categoryItemCountsChecker } from "src/app/models/item/item-counts.model";
import { TemplateItemEnhanced } from "src/app/models/item/template-item.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class TemplateItemDetailService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private getCountFromServerService = inject(GetCountFromServerService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Item Doc
   * @param workspaceId
   * @param templateId
   * @param itemId
   */
  public getItemDoc$(workspaceId: string, templateId: string, itemId: string): Observable<TemplateItemEnhanced | null> {
    const path = `${this.collectionsService.templateItemsCol(workspaceId, templateId)}/${itemId}`;
    return this.firestoreUtilsService.getDocumentData<TemplateItemEnhanced>(path).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }

  public getPreviousItem$(workspaceId: string, templateId: string, currentOrder: number, categoryId: string): Observable<TemplateItemEnhanced | null> {
    const path = this.collectionsService.templateItemsCol(workspaceId, templateId);
    const queryConstraints = [where("category_id", "==", categoryId), where("is_deleted", "==", false), where("order", "<", currentOrder), orderBy("order", "desc"), limit(1)];
    return this.firestoreUtilsService.getCollectionData<TemplateItemEnhanced>(path, queryConstraints).pipe(map((items: TemplateItemEnhanced[]) => (items.length > 0 ? items[0] : null)));
  }

  public getNextItem$(workspaceId: string, templateId: string, currentOrder: number, categoryId: string): Observable<TemplateItemEnhanced | null> {
    const path = this.collectionsService.templateItemsCol(workspaceId, templateId);
    const queryConstraints = [where("category_id", "==", categoryId), where("is_deleted", "==", false), where("order", ">", currentOrder), orderBy("order", "asc"), limit(1)];
    return this.firestoreUtilsService.getCollectionData<TemplateItemEnhanced>(path, queryConstraints).pipe(map((items: TemplateItemEnhanced[]) => (items.length > 0 ? items[0] : null)));
  }

  public async setNewItemDoc(workspaceId: string, templateId: string, categoryId: string, itemId: string, itemForm: TemplateItemEnhanced, user: User) {
    // writeEventType
    const lastEvent = this.lastEventService.lastEvent("added", user);

    // Item
    const itemPath = this.collectionsService.templateItemsCol(workspaceId, templateId);
    const itemIdToUse = itemId === "new" ? this.firestoreUtilsService.createFirestoreId() : itemId;
    const itemDoc = `${itemPath}/${itemIdToUse}`;
    const itemCount = await this.getCountFromServerService.getItemsCount(itemPath, categoryId);
    const itemObj = {
      answer_type: itemForm.answer_type,
      title: itemForm.title.trim(),
      order: itemForm.order ?? itemCount,
      category_id: categoryId,
      mode: itemForm.mode,
      created_by_id: user.user_id,
      is_deleted: false,
      last_event: lastEvent,
    };

    // Category
    const categoryPath = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const categoryDoc = `${categoryPath}/${categoryId}`;

    // Batch
    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: itemDoc,
        data: itemObj,
      },
      {
        type: "set",
        documentPath: categoryDoc,
        data: { items_count: increment(+1) },
      },
    ];

    try {
      await this.firestoreUtilsService.batchWrite(operations);
      return { ...itemObj, id: itemIdToUse };
    } catch (error) {
      throw error;
    }
  }

  /**
   * Delete Item Doc
   * @param workspaceId
   * @param templateId
   * @param categoryId
   * @param item
   * @param allItems
   * @param user
   */
  public async deleteItemDoc(workspaceId: string, templateId: string, categoryId: string, item: TemplateItemEnhanced, allItems: TemplateItemEnhanced[], user: User) {
    // Last Event
    const lastEvent = this.lastEventService.lastEvent("deleted", user);

    // Item
    const itemPath = this.collectionsService.templateItemsCol(workspaceId, templateId);
    const itemDoc = `${itemPath}/${item.id}`;

    // Category
    const categoryPath = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const categoryDoc = `${categoryPath}/${categoryId}`;

    // Batch
    const operations: BatchOperation[] = [
      {
        type: "set",
        documentPath: itemDoc,
        data: { is_deleted: true, last_event: lastEvent },
      },
      {
        type: "update",
        documentPath: categoryDoc,
        data: { items_count: increment(-1) },
      },
    ];

    // Remaining Items
    const remainingItems = allItems.filter((existingItem) => existingItem.id !== item.id);
    remainingItems.forEach((remainingItem, index) => {
      const updatePath = `${itemPath}/${remainingItem.id}`;
      operations.push({
        type: "update",
        documentPath: updatePath,
        data: { order: index },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Delete Batched Items
   * @param workspaceId
   * @param templateId
   * @param categoryId
   * @param batchedItems
   * @param allItems
   * @param user
   */
  public async deleteBatchedItems(workspaceId: string, templateId: string, categoryId: string, batchedItems: TemplateItemEnhanced[], allItems: TemplateItemEnhanced[], user: User) {
    // Last Event
    const lastEvent = this.lastEventService.lastEvent("deleted", user);

    // Paths
    const itemPath = this.collectionsService.templateItemsCol(workspaceId, templateId);
    const categoryPath = this.collectionsService.templateCategoriesCol(workspaceId, templateId);

    // Category Doc
    const categoryDoc = `${categoryPath}/${categoryId}`;

    // Batch
    const operations: BatchOperation[] = [];

    batchedItems.forEach((item) => {
      const itemDoc = `${itemPath}/${item.id}`;
      const lastEvent = this.lastEventService.lastEvent("deleted", user);
      operations.push({
        type: "update",
        documentPath: itemDoc,
        data: { is_deleted: true, last_event: lastEvent },
      });
      operations.push({
        type: "update",
        documentPath: categoryDoc,
        data: { items_count: increment(-1) },
      });
    });

    // Filter out the items that are in the batchedItems to get the remaining ones
    const remainingItems = allItems.filter((existingItem) => !batchedItems.some((batchedItem) => batchedItem.id === existingItem.id));

    // Add update operations to reorder the remaining items
    remainingItems.forEach((remainingItem, index) => {
      const updatePath = `${itemPath}/${remainingItem.id}`;
      operations.push({
        type: "update",
        documentPath: updatePath,
        data: { order: index },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Does Category Item Counts Need Updating
   * @param item
   * @param itemForm
   * @returns
   */
  private async doesCategoryItemCountsNeedUpdating(item: Item, itemForm: Item): Promise<categoryItemCountsChecker> {
    const updateInfo: categoryItemCountsChecker = {
      decrementItemsCount: false,
      incrementItemsCount: false,
      decrementItemsCompletedCount: false,
      incrementItemsCompletedCount: false,
    };

    if (item.enabled && !itemForm.enabled) {
      updateInfo.decrementItemsCount = true;
    }

    if (!item.enabled && itemForm.enabled) {
      updateInfo.incrementItemsCount = true;
    }

    return updateInfo;
  }
}
