@if (invites.length > 0) {
  <div>
    <div class="mb-5 px-8">
      <search-bar [placeholder]="'Search Invites'" (searchTermChange)="onSearch($event)" />
    </div>

    <div class="w-full rounded-md bg-white shadow-sm dark:bg-gray-800 sm:px-6 lg:px-8">
      <!-- List Component -->
      <ul id="invitedMembersList" role="list" class="list-ul">
        @for (invite of filteredInvites; track $index) {
          <li [class.hidden]="invite.hidden">
            <div class="list-row">
              <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'members', 'invites', invite.id]" class="list-a group">
                <!-- Left and Right Content -->
                <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 group-hover:text-brand_Primary">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                  </svg>

                  <!-- Left Content -->
                  <div class="min-w-0 flex-auto">
                    <p class="list-title">{{ invite.name }}</p>
                    <p class="list-meta">{{ invite.email }}</p>
                    <p class="list-meta">Invited as {{ invite.role_title }} on {{ invite.date_invited.toDate() | date: "dd MMM yyyy HH:mm" }}</p>
                  </div>
                </div>
                <!-- Right Icon -->
                <div class="flex">
                  <button type="button" class="list-icon" aria-expanded="false" aria-haspopup="true">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                  </button>
                </div>
              </a>
            </div>
          </li>
        }
      </ul>
      @if (allInvitesHidden) {
        <search-empty [searchTerm]="searchTerm" />
      }
    </div>
  </div>
} @else {
  <empty-list [list]="'invite'" />
}
