import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "delete-overlay",
  standalone: true,
  imports: [],
  templateUrl: "./delete-overlay.component.html",
  styleUrl: "./delete-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteOverlayComponent {
  @Input({ required: true }) deleteOverlayTitle!: string;
  @Input({ required: true }) deleteOverlayDescription!: string;
  @Input() deleteOverlayButton!: string;
  @Output() cancelButtonClicked = new EventEmitter<boolean>();
  @Output() deleteButtonClicked = new EventEmitter<boolean>();

  /**
   * Delete Button
   */
  deleteButton(): void {
    this.deleteButtonClicked.emit();
  }

  /**
   * Cancel Button
   */
  cancelButton(): void {
    this.cancelButtonClicked.emit();
  }
}
