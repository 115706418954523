<div class="content-card-wrapper flex h-full flex-col">
  <div class="content-card-header">
    <h3>Inspection Contents</h3>
  </div>
  <div class="flex w-full flex-1">
    <div class="flex w-full flex-col">
      <!-- Tests and Questions -->
      <div class="flex h-[101px] items-center justify-between border-b border-gray-200 p-6">
        <div class="flex flex-row">
          <div class="mr-4 flex h-12 w-12 items-center justify-center rounded-md border border-brand_Primary border-opacity-25 bg-brand_Primary bg-opacity-10 text-brand_Primary">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
              />
            </svg>
          </div>
          <div class="flex flex-col justify-between">
            <div class="font-semibold tracking-wide">Tests and Questions</div>
            <div class="flex flex-row">
              <div class="progress-wrapper mr-2">
                <div class="progress-inner">
                  <div class="middle-circle"></div>
                  <div class="progress-spinner" [style.background]="'conic-gradient(rgb(30, 73, 153) ' + percentageOfAnsweredTests + '%, rgb(242, 242, 242) ' + percentageOfAnsweredTests + '%)'"></div>
                </div>
              </div>

              <div class="text-sm text-gray-400">{{ totalTestsWithAnswers }} of {{ totalTests }} answered</div>
            </div>
          </div>
        </div>
        <a [routerLink]="['/', 'workspace', workspaceId, 'folders', folderId, 'inspections', inspectionId, 'categories']" class="btn btn-secondary">Continue Inspection</a>
      </div>

      <!-- Introduction -->
      <div class="flex h-[101px] items-center justify-between border-b border-gray-200 p-6">
        <div class="flex flex-row">
          <div class="mr-4 flex h-12 w-12 items-center justify-center rounded-md border border-gray-200 bg-gray-100 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
            </svg>
          </div>
          <div class="flex flex-col justify-between">
            <div class="font-semibold tracking-wide">Introduction</div>
            <p class="text-sm text-gray-400">Write an Introduction page</p>
          </div>
        </div>
        <button type="button" (click)="limitationManagerCheck('introduction')" class="btn btn-secondary">Add Introduction</button>
      </div>

      <!-- Summary -->
      <div class="flex h-[101px] items-center justify-between p-6">
        <div class="flex flex-row">
          <div class="mr-4 flex h-12 w-12 items-center justify-center rounded-md border border-gray-200 bg-gray-100 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25" />
            </svg>
          </div>
          <div class="flex flex-col justify-between">
            <div class="font-semibold tracking-wide">Summary</div>
            <p class="text-sm text-gray-400">Write a Summary page</p>
          </div>
        </div>
        <button type="button" (click)="limitationManagerCheck('summary')" class="btn btn-secondary">Add Summary</button>
      </div>
    </div>
  </div>
</div>
