import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { combineLatest, filter, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { InvitesListService } from "../../services/invites-list.service";
import { MembersListService } from "../../services/members-list.service";
import { ManageMembersInvitesListComponent } from "../manage-members-invites-list/manage-members-invites-list.component";
import { ManageMembersListHeaderComponent } from "../manage-members-list-header/manage-members-list-header.component";
import { ManageMembersListComponent } from "../manage-members-list/manage-members-list.component";

@Component({
  selector: "manage-members-list-wrapper",
  standalone: true,
  imports: [AsyncPipe, ManageMembersListComponent, ManageMembersListHeaderComponent, LoadingSpinnerComponent, NgClass, JsonPipe, ManageMembersInvitesListComponent],
  templateUrl: "./manage-members-list-wrapper.component.html",
  styleUrl: "./manage-members-list-wrapper.component.scss",
})
export class ManageMembersListWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private membersListService = inject(MembersListService);
  private invitesListService = inject(InvitesListService);
  private authService = inject(AuthService);
  private limitationManagerService = inject(LimitationManagerService);
  private router = inject(Router);

  // Properites
  public members$!: Observable<MemberEnhanced[]>;
  public invites$!: Observable<InviteEnhanced[]>;
  public combinedData$!: Observable<{ members: MemberEnhanced[]; invites: InviteEnhanced[] }>;
  private _workspaceId!: string;
  public user!: User;
  public selectedTab = "Members";

  constructor() {
    this.user = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.initializeCombinedData();
  }

  /**
   * Initialize Combined Data
   */
  private initializeCombinedData(): void {
    this.members$ = this.membersListService.getMembersList$(this.workspaceId).pipe(filter((data) => !!data));
    this.invites$ = this.invitesListService.getInvitesForSpecificWorkspace$(this.workspaceId).pipe(filter((data) => !!data));
    this.combinedData$ = combineLatest({
      members: this.members$,
      invites: this.invites$,
    });
  }

  /**
   * Route To Invite Editor
   */
  async routeToInviteEditor(): Promise<void> {
    const featureAction: keyof RolePermissions = "invite_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    const route = ["/workspace", this.workspaceId, "manage", "members", "invites", "new"];
    limitationResult ? this.router.navigate(route) : (this.limitationManagerService.overlay_limitationManager = true);
  }

  /**
   * Select Tab
   * @param value
   */
  selectTab(value: string): void {
    this.selectedTab = value;
  }
}
