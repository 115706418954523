import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TooltipComponent } from "src/app/shared/tooltip/tooltip.component";

@Component({
  selector: "manage-template-category-edit-details",
  standalone: true,
  imports: [ReactiveFormsModule, TooltipComponent],
  templateUrl: "./manage-template-category-edit-details.component.html",
  styleUrl: "./manage-template-category-edit-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateCategoryEditDetailsComponent {
  @Input({ required: true }) categoryForm!: FormGroup;
  @Input({ required: true }) toolTipCategoryNotes!: string;
}
