@if (reportsService.reportSettings.pdf_or_csv === "PDF") {
  @if (reportsService.reportData.inspection) {
    <div class="mx-auto mb-28 max-w-[612pt] rounded-md bg-white shadow-sm">
      <report-content-cover [user]="user" />
      <div id="report-content">
        <div id="content-wrapper" [ngClass]="'font-size-' + reportsService.themeDetails.value.fonts_size">
          <!-- Site -->
          @if (reportsService.themeDetails.value.details_site && reportsService.reportData.site) {
            <report-content-site />
          }

          <!-- Charts -->
          @if (reportsService.themeDetails.value.charts_included) {
            <div style="break-after: page">
              <report-content-charts />
            </div>
          }

          <!-- Introduction -->
          @if (reportsService.themeDetails.value.details_introduction) {
            <div style="break-after: page">
              <report-content-introduction />
            </div>
          }

          <!-- Categories -->
          <report-content-categories />

          <!-- Summary -->
          @if (reportsService.themeDetails.value.details_summary) {
            <div style="break-after: page">
              <report-content-summary />
            </div>
          }

          <!-- Signature -->
          @if (reportsService.themeDetails.value.details_signatures) {
            <report-content-signature />
          }
        </div>
      </div>
    </div>
  }
}

@if (reportsService.reportSettings.pdf_or_csv === "CSV") {
  <report-content-csv />
}
