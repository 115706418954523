<div class="space-y-4">
  <question-form-title [testForm]="testForm" [currentCategoryOrder]="currentCategoryOrder" (enableOrDisableTestOutput)="enableOrDisableTest()" />
  @if (testForm.value.enabled === true) {
    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-6">
        <div class="content-card-wrapper">
          <!-- Test Answer -->
          <form [formGroup]="testForm" class="content-card grid space-y-6 px-6 py-5">
            @switch (testForm.value.answer_type) {
              @case ("question-bool") {
                <question-bool [testForm]="testForm" />
              }
              @case ("question-date") {
                <question-date [testForm]="testForm" />
              }
              @case ("question-number") {
                <question-number [testForm]="testForm" />
              }
              @case ("question-string") {
                <question-string [testForm]="testForm" />
              }
              @case ("question-time") {
                <question-time [testForm]="testForm" />
              }
            }
          </form>
        </div>
      </div>
    </div>
  }
</div>
