import { AsyncPipe } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { RolePermissions, User } from "cip";
import { BehaviorSubject, filter, Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { StringsService } from "src/app/core/services/strings/strings.service";
import { AlertType } from "src/app/models/strings/strings.model";
import { ThemeEnhanced } from "src/app/models/theme/theme.model";
import { ReportsService } from "src/app/sections/reports/services/reports.service";
import { DeleteOverlayComponent } from "src/app/shared/delete-overlay/delete-overlay.component";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ThemeDetailService } from "../../services/theme-detail.service";
import { ThemesListService } from "../../services/themes-list.service";
import { ManageThemesListHeaderComponent } from "../manage-themes-list-header/manage-themes-list-header.component";
import { ManageThemesListComponent } from "../manage-themes-list/manage-themes-list.component";

@Component({
  selector: "manage-themes-list-wrapper",
  standalone: true,
  imports: [AsyncPipe, DeleteOverlayComponent, ManageThemesListHeaderComponent, ManageThemesListComponent, LoadingSpinnerComponent, EmptyListComponent],
  templateUrl: "./manage-themes-list-wrapper.component.html",
  styleUrl: "./manage-themes-list-wrapper.component.scss",
})
export class ManageThemesListWrapperComponent implements OnInit {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Services
  private themesListService = inject(ThemesListService);
  private themeDetailService = inject(ThemeDetailService);
  private router = inject(Router);
  private limitationManagerService = inject(LimitationManagerService);
  private stringsService = inject(StringsService);
  private reportsService = inject(ReportsService);
  private authService = inject(AuthService);

  // Properties
  private _workspaceId!: string;
  public themes$!: Observable<ThemeEnhanced[]>;
  public editMode: boolean = false;
  public currentSearchTerm: string = "";
  public overlay_deleteTheme = signal<boolean>(false);
  public themesBulkEditArray$: BehaviorSubject<ThemeEnhanced[]> = new BehaviorSubject<ThemeEnhanced[]>([]);
  public deleteOverlayTitle: string = "";
  public deleteOverlayDescription: string = "";
  public themeToBeDeleted: string = "";
  public themeDetails = this.reportsService.themeDetails;
  private user: User;

  constructor() {
    this.user = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.getThemesList();
  }

  /**
   * Get Themes List
   */
  private async getThemesList(): Promise<void> {
    try {
      this.themes$ = this.themesListService.getThemesList$(this.workspaceId).pipe(filter((data) => !!data));
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Update Search Term
   * @param term
   */
  public searchTermChange(term: string) {
    this.currentSearchTerm = term;
  }

  /**
   * Route To Theme Editor
   */
  public async routeToThemeEditor(theme: ThemeEnhanced): Promise<void> {
    const featureAction: keyof RolePermissions = "theme_create_update";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "themes", theme.id, "editor"]);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Route To New Editor
   */
  public async routeToNewEditor(): Promise<void> {
    const featureAction: keyof RolePermissions = "theme_create_update";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      this.router.navigate(["/workspace", this.workspaceId, "manage", "themes", "new", "editor"]);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Set Delete Batch Themes Properties
   */
  public async setDeleteBatchThemesProperties(): Promise<void> {
    const featureAction: keyof RolePermissions = "theme_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      if (this.themesBulkEditArray$.getValue().length > 0) {
        const deleteThemes = this.stringsService.alertFilter(AlertType.DeleteThemes);
        if (deleteThemes) {
          this.deleteOverlayTitle = deleteThemes.title;
          this.deleteOverlayDescription = deleteThemes.description;
          this.toggleDeleteThemeOverlay(true);
        }
      }
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Delete Single Or Multiple Themes
   */
  public deleteSingleOrMultipleThemes(): void {
    if (this.themesBulkEditArray$.getValue().length > 0) {
      this.deleteBatchedThemes();
    } else {
      this.deleteSingleTheme();
    }
  }

  /**
   * Delete Single Theme
   */
  public async deleteSingleTheme() {
    try {
      await this.themeDetailService.deleteThemeDoc(this.workspaceId, this.themeToBeDeleted, this.user);
      this.toggleDeleteThemeOverlay(false);
      this.toggleEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Delete Batched Themes
   */
  private async deleteBatchedThemes(): Promise<void> {
    try {
      await this.themeDetailService.deleteBatchedThemes(this.workspaceId, this.themesBulkEditArray$.value, this.user);
      this.toggleDeleteThemeOverlay(false);
      this.toggleEditMode();
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Set Delete Single Theme Properties
   * @param themeId
   */
  public async setDeleteSingleThemeProperties(themeId: string): Promise<void> {
    const featureAction: keyof RolePermissions = "theme_delete";
    const limitationResult = await this.limitationManagerCheck(featureAction);
    if (limitationResult) {
      const deleteTheme = this.stringsService.alertFilter(AlertType.DeleteTheme);
      if (deleteTheme) {
        this.deleteOverlayTitle = deleteTheme.title;
        this.deleteOverlayDescription = deleteTheme.description;
      } else {
        console.error("Unknown string type:", AlertType.DeleteTemplateCategory);
      }
      this.themeToBeDeleted = themeId;
      this.toggleDeleteThemeOverlay(true);
    } else {
      this.limitationManagerService.overlay_limitationManager = true;
    }
  }

  /**
   * Create Batch Edit
   * @param category
   */
  public createBatchEdit(theme: ThemeEnhanced): void {
    const currentArray = this.themesBulkEditArray$.getValue();
    if (currentArray.some((e: ThemeEnhanced) => e.id === theme.id)) {
      this.removeFromBatchArray(theme);
    } else {
      this.addToBatchArray(theme);
    }
  }

  /**
   * Add To Batch Array
   * @param category
   */
  private addToBatchArray(theme: ThemeEnhanced): void {
    const updatedArray = [...this.themesBulkEditArray$.getValue(), theme];
    this.themesBulkEditArray$.next(updatedArray);
  }
  /**
   * Remove From Batch Array
   * @param category
   */
  private removeFromBatchArray(theme: ThemeEnhanced): void {
    const updatedArray = this.themesBulkEditArray$.getValue().filter((e) => e.id !== theme.id);
    this.themesBulkEditArray$.next(updatedArray);
  }

  /**
   * Limitation Manager Check
   */
  public async limitationManagerCheck(featureAction: keyof RolePermissions): Promise<boolean> {
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    return limitationResult;
  }

  /**
   * Toggle Delete Theme Overlay
   * @param value
   */
  public toggleDeleteThemeOverlay(value: boolean): void {
    this.overlay_deleteTheme.set(value);
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.themesBulkEditArray$.next([]);
  }
}
