@if (reportsService.reportData) {
  <div class="mx-10 w-[16500px] px-10 pb-10">
    <table id="csvTable">
      <tbody>
        <tr>
          <td></td>
          <td>Type</td>
          @for (header of reportsCsvService.csvHeaders; track $index) {
            <td [ngStyle]="{ width: header.width }">
              {{ header.value }}
            </td>
          }
        </tr>
        @for (info of csvData.rows; track $index) {
          @switch (info.row.type) {
            @case ("workspace") {
              <ng-container [ngTemplateOutlet]="workspaceTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("inspection") {
              <ng-container [ngTemplateOutlet]="inspectionTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("site") {
              <ng-container [ngTemplateOutlet]="siteTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("client") {
              <ng-container [ngTemplateOutlet]="clientTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("introduction") {
              <ng-container [ngTemplateOutlet]="introductionTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("category") {
              <ng-container [ngTemplateOutlet]="categoryTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("item") {
              <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("action") {
              <ng-container [ngTemplateOutlet]="actionTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("photo") {
              <ng-container [ngTemplateOutlet]="photoTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("summary") {
              <ng-container [ngTemplateOutlet]="summaryTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("signature") {
              <ng-container [ngTemplateOutlet]="signatureTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
            @case ("user") {
              <ng-container [ngTemplateOutlet]="userTemplate" [ngTemplateOutletContext]="{ $implicit: info }"></ng-container>
            }
          }
        }
      </tbody>
    </table>
  </div>
}

<!-- Workspace -->
<ng-template #workspaceTemplate let-info>
  @if (info.row.workspace; as workspace) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (workspace.hasOwnProperty(header.value)) {
          <td>
            @switch (header.value) {
              @case ("image_url") {
                {{ reportsService.reportSettings.csv_images ? workspace.image_url : "-" }}
              }
              @case ("image_thumbnail_url") {
                {{ reportsService.reportSettings.csv_images ? workspace.image_thumbnail_url : "-" }}
              }
              @default {
                {{ workspace[header.value] }}
              }
            }
          </td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Inspection -->
<ng-template #inspectionTemplate let-info>
  @if (info.row.inspection; as inspection) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (inspection.hasOwnProperty(header.value)) {
          <td>
            @switch (header.value) {
              @case ("date") {
                {{ inspection.date.toDate() | date: "dd MMM yyyy" }}
              }
              @case ("date_created") {
                {{ inspection.date_created.toDate() | date: "dd MMM yyyy" }}
              }
              @default {
                {{ inspection[header.value] }}
              }
            }
          </td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Site -->
<ng-template #siteTemplate let-info>
  @if (info.row.site; as site) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (site.hasOwnProperty(header.value)) {
          <td>
            @switch (header.value) {
              @case ("image_thumbnail_url") {
                {{ reportsService.reportSettings.csv_images ? site.image_thumbnail_url : "-" }}
              }
              @case ("image_url") {
                {{ reportsService.reportSettings.csv_images ? site.image_url : "-" }}
              }
              @case ("map_url") {
                {{ reportsService.reportSettings.csv_images ? site.map_url : "-" }}
              }
              @default {
                {{ site[header.value] }}
              }
            }
          </td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Client -->
<ng-template #clientTemplate let-info>
  @if (info.row.client; as client) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (client.hasOwnProperty(header.value)) {
          <td>
            @switch (header.value) {
              @case ("image_thumbnail_url") {
                {{ reportsService.reportSettings.csv_images ? client.image_thumbnail_url : "-" }}
              }
              @case ("image_url") {
                {{ reportsService.reportSettings.csv_images ? client.image_url : "-" }}
              }
              @default {
                {{ client[header.value] }}
              }
            }
          </td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Introduction -->
<ng-template #introductionTemplate let-info>
  @if (info.row.introduction; as introduction) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (introduction.hasOwnProperty(header.value)) {
          <td>{{ introduction[header.value] }}</td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Category -->
<ng-template #categoryTemplate let-info>
  @if (info.row.category; as category) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (category.hasOwnProperty(header.value)) {
          <td>{{ category[header.value] }}</td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Item -->
<ng-template #itemTemplate let-info>
  @if (info.row.item; as item) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (item.hasOwnProperty(header.value)) {
          <td>
            @if (header.value === "question_date") {
              {{ item.question_date.toDate() | date: "dd MMM yyyy" }}
            } @else {
              {{ item[header.value] }}
            }
          </td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Action -->
<ng-template #actionTemplate let-info>
  @if (info.row.action; as action) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (action.hasOwnProperty(header.value)) {
          <td>
            @if (header.value === "due_date") {
              {{ action.due_date.toDate() | date: "dd MMM yyyy" }}
            } @else {
              {{ action[header.value] }}
            }
          </td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Photo -->
<ng-template #photoTemplate let-info>
  @if (info.row.photo; as photo) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (photo.hasOwnProperty(header.value)) {
          <td>
            @switch (header.value) {
              @case ("annotation_thumbnail_url") {
                {{ reportsService.reportSettings.csv_images ? photo.annotation_thumbnail_url : "-" }}
              }
              @case ("annotation_url") {
                {{ reportsService.reportSettings.csv_images ? photo.annotation_url : "-" }}
              }
              @case ("image_thumbnail_url") {
                {{ reportsService.reportSettings.csv_images ? photo.image_thumbnail_url : "-" }}
              }
              @case ("image_url") {
                {{ reportsService.reportSettings.csv_images ? photo.image_url : "-" }}
              }
              @case ("date_uploaded") {
                {{ photo.date_uploaded.toDate() | date: "dd MMM yyyy" }}
              }
              @case ("timestamp") {
                {{ photo.timestamp.toDate() | date: "dd MMM yyyy" }}
              }
              @default {
                {{ photo[header.value] }}
              }
            }
          </td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Summary -->
<ng-template #summaryTemplate let-info>
  @if (info.row.summary; as summary) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (summary.hasOwnProperty(header.value)) {
          <td>{{ summary[header.value] }}</td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- Signature -->
<ng-template #signatureTemplate let-info>
  @if (info.row.signature; as signature) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (signature.hasOwnProperty(header.value)) {
          <td>
            @switch (header.value) {
              @case ("image_url") {
                {{ reportsService.reportSettings.csv_images ? signature.image_url : "-" }}
              }
              @case ("image_thumbnail_url") {
                {{ reportsService.reportSettings.csv_images ? signature.image_thumbnail_url : "-" }}
              }
              @case ("date_signed") {
                {{ signature.date_signed.toDate() | date: "dd MMM yyyy" }}
              }
              @default {
                {{ signature[header.value] }}
              }
            }
          </td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>

<!-- User -->
<ng-template #userTemplate let-info>
  @if (info.row.user; as user) {
    <tr>
      <td style="width: 40px">{{ info.index }}</td>
      <td>{{ info.row.type }}</td>
      @for (header of reportsCsvService.csvHeaders; track header.value) {
        @if (user.hasOwnProperty(header.value)) {
          <td>{{ user[header.value] }}</td>
        } @else {
          <td></td>
        }
      }
    </tr>
  }
</ng-template>
