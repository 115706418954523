<header>
  <!-- Breadcrumbs-->
  <breadcrumbs [overview]="true" [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId"></breadcrumbs>

  <!-- Header -->
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">{{ inspection.title }}</h2>
      </div>
    </div>
  </div>
</header>
