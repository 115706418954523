import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { InfoViewFilter } from "src/app/models/strings/strings.model";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { QuestionTypesComponent } from "src/app/shared/items/question-types/question-types.component";
import { TestTypesComponent } from "src/app/shared/items/test-types/test-types.component";

@Component({
  selector: "manage-template-item-new-overlay",
  standalone: true,
  imports: [InfoViewComponent, NgClass, ReactiveFormsModule, TestTypesComponent, QuestionTypesComponent],
  templateUrl: "./manage-template-item-new-overlay.component.html",
  styleUrl: "./manage-template-item-new-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateItemNewOverlayComponent {
  @Input({ required: true }) type!: "test" | "question";
  @Input({ required: true }) itemForm!: FormGroup;
  @Input({ required: true }) infoViewItemEditorTest!: InfoViewFilter;
  @Input({ required: true }) infoViewItemEditorQuestion!: InfoViewFilter;
  @Output() toggleNewItemOutput = new EventEmitter<void>();
  @Output() saveItemAndRerouteOutput = new EventEmitter<void>();
  @Output() saveItemAndAddDifferentModeOutput = new EventEmitter<void>();
  @Output() saveItemAndAddAnotherOutput = new EventEmitter<void>();

  public saveItemAndReroute(): void {
    this.saveItemAndRerouteOutput.emit();
  }

  public saveItemAndAddDifferentMode(): void {
    this.saveItemAndAddDifferentModeOutput.emit();
  }

  public saveItemAndAddAnother(): void {
    this.saveItemAndAddAnotherOutput.emit();
  }

  /**
   * Toggle the new item overlay
   */
  public toggleNewItem(): void {
    this.toggleNewItemOutput.emit();
  }
}
