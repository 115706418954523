import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";
import { MemberEnhanced } from "src/app/models/member/member.model";
import { InfoViewFilter } from "src/app/models/strings/strings.model";
import { FormStateComponent } from "src/app/shared/form-state/form-state.component";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";
import { PermissionsGridComponent } from "src/app/shared/permissions/permissions-grid/permissions-grid.component";
import { RoleEditDetailsComponent } from "src/app/shared/roles/role-edit-details/role-edit-details.component";
import { ManageRoleAssignedInvitesComponent } from "../manage-role-assigned-invites/manage-role-assigned-invites.component";
import { ManageRoleAssignedMembersComponent } from "../manage-role-assigned-members/manage-role-assigned-members.component";

@Component({
  selector: "manage-role-edit-form",
  standalone: true,
  imports: [FormStateComponent, NgClass, ReactiveFormsModule, InfoViewComponent, RoleEditDetailsComponent, PermissionsGridComponent, ManageRoleAssignedInvitesComponent, ManageRoleAssignedMembersComponent],
  templateUrl: "./manage-role-edit-form.component.html",
  styleUrl: "./manage-role-edit-form.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRoleEditFormComponent {
  @Input({ required: true }) assignedMembers!: MemberEnhanced[];
  @Input({ required: true }) assignedInvites!: InviteEnhanced[];
  @Input({ required: true }) roleForm!: FormGroup;
  @Input({ required: true }) infoViewPermissionsEditor!: InfoViewFilter;
  @Input({ required: true }) infoViewRoleEditor!: InfoViewFilter;
  @Input({ required: true }) roleId!: string;
  @Output() saveRoleOutput = new EventEmitter<void>();
  @Output() deleteRoleOutput = new EventEmitter<void>();

  /**
   * Save Role
   */
  public saveRole(): void {
    this.saveRoleOutput.emit();
  }

  /**
   * Delete Role
   */
  public deleteRole(): void {
    this.deleteRoleOutput.emit();
  }
}
