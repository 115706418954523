import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AssigneeEnhanced } from "src/app/models/assignee/assignee.model";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "manage-assignees-list",
  standalone: true,
  imports: [SearchEmptyComponent],
  templateUrl: "./manage-assignees-list.component.html",
  styleUrl: "./manage-assignees-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageAssigneesListComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true })
  set assignees(value: AssigneeEnhanced[]) {
    this._assignees = value;
    this.updateFilteredassigneesForSearchResults();
  }

  get assignees(): AssigneeEnhanced[] {
    return this._assignees;
  }

  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.updateFilteredassigneesForSearchResults();
  }

  @Input({ required: true }) editMode!: boolean;
  @Input() assigneesBulkEditArray: AssigneeEnhanced[] | null = [];
  @Output() deleteSingleAssigneeOutput = new EventEmitter<string>();
  @Output() createBatchEditOutput = new EventEmitter<AssigneeEnhanced>();
  @Output() routeToAssigneeEditorOutput = new EventEmitter<string>();

  // Properties
  private _assignees: AssigneeEnhanced[] = [];
  public _searchTerm: string = "";
  public filteredAssignees: AssigneeEnhanced[] = [];
  public allAssigneesHidden: boolean = false;

  updateFilteredassigneesForSearchResults(): void {
    const lowerSearchTerm = this._searchTerm.toLowerCase();
    this.filteredAssignees = this.assignees.map((assignee) => ({
      ...assignee,
      hidden: !assignee.name.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allAssigneesHidden = this.filteredAssignees.every((assignee) => assignee.hidden);
  }

  public createBatchEdit(assignee: AssigneeEnhanced): void {
    this.createBatchEditOutput.emit(assignee);
  }

  public deleteSingleAssignee(assignee: string): void {
    this.deleteSingleAssigneeOutput.emit(assignee);
  }

  public routeToAssigneeEditor(assigneeId: string): void {
    this.routeToAssigneeEditorOutput.emit(assigneeId);
  }
}
