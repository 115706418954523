import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";

@Component({
  selector: "manage-role-edit-header",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./manage-role-edit-header.component.html",
  styleUrl: "./manage-role-edit-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRoleEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) roleId!: string;
}
