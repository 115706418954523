<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block h-[600px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">New Signature</h3>
        </div>

        <div class="flex h-full w-full flex-col overflow-hidden">
          <div class="w-full flex-1 overflow-y-scroll">
            <div class="grid grid-cols-1 gap-y-8 px-6 py-8">
              <!-- Details -->
              <div class="content-card-wrapper">
                <div class="px-6 py-5">
                  <div class="content-grid gap-x-4 gap-y-6">
                    <info-view [type]="infoViewSignatureEditor.type" [message]="infoViewSignatureEditor.message" />
                    <inspection-signoff-edit-details [signatureForm]="signatureForm" />
                    <div>
                      <div class="form-input-label mb-2 px-3">Photo</div>
                      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="sm:col-span-6">
                          <!-- Image Dropzone -->
                          @if (!signatureForm.value.image_thumbnail_url && uploadFileArray.length < 1) {
                            <photo-dropzone [uploadSourceComponent]="'signature'" (detectPhotosOutput)="detectPhotos($event)" />
                          }
                          @if (photoPreview && uploadFileArray.length > 0) {
                            <photo-preview [photosToPreview]="uploadFileArray" [uploadLimit]="1" [uploadSourceComponent]="'signature'" (removePhotoFromArrayOutput)="removePhotoFromArray($event)" />
                          }
                          @if (overlay_uploadPhotos) {
                            @for (photo of uploadFileArray; track photo.id) {
                              <photo-upload-single
                                [file]="photo.photo"
                                [path]="'workspaces/' + workspaceId + '/inspections/' + inspectionId + '/signatures/' + newSignatureId"
                                [fileType]="'jpeg'"
                                [largeMaxWidth]="900"
                                [largeMaxHeight]="300"
                                [thumbnailMaxWidth]="300"
                                [thumbnailMaxHeight]="100"
                                [uploadSourceComponent]="'signature'"
                                (photoDataOutput)="patchFormWithPhotosAndSaveDoc($event)"
                              />
                            }
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Form State Bottom -->
          <div class="flex flex-row-reverse bg-gray-100 px-6 py-6 sm:px-8">
            <button
              type="button"
              class="btn ml-3"
              [disabled]="signatureForm.pristine || signatureForm.controls['name'].errors"
              [ngClass]="signatureForm.pristine || signatureForm.controls['name'].errors ? 'btn-disabled' : 'btn-primary'"
              (click)="saveSignature()"
            >
              Save
            </button>
            <button type="button" class="btn btn-cancel" (click)="toggleNewSignatureOverlay()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
