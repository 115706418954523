<header>
  <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [categoryId]="categoryId" [itemId]="itemId" />
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Edit {{ item.mode === 'test' ? 'Test' : 'Question' }}</h2>
      </div>

      @if (itemId !== 'new') {
        <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
          <button type="button" class="btn btn-secondary" (click)="duplicateItem()">Duplicate Item</button>
        </div>
      }
    </div>
  </div>
</header>
