<form [formGroup]="siteForm" class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
  <div class="sm:col-span-1">
    <div class="form-input-wrapper">
      <label for="site-title" class="form-input-label"
        >Title*
        @if (!siteForm.pristine && siteForm.controls['title'].errors) {
          <span class="required-field"> - Title is required</span>
        }
      </label>
      <input type="text" name="site-title" id="site-title" class="form-input" placeholder="Title" formControlName="title" />
    </div>
  </div>
  <div class="sm:col-span-1">
    <div class="form-input-wrapper">
      <label for="site-ref" class="form-input-label">Reference</label>
      <input type="text" name="site-ref" id="site-ref" class="form-input" placeholder="Reference" formControlName="ref" />
    </div>
  </div>
</form>
