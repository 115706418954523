@if (members$ | async; as members) {
  <div class="mt-4 flex w-full flex-col px-12 sm:flex-row" [ngClass]="members.length > 0 ? 'justify-between' : 'justify-end'">
    <search-bar [placeholder]="'Search Assignees'" (searchTermChange)="onSearch($event)" />
  </div>

  <div class="px-6 py-8">
    <!-- Currently Selected Assignee-->
    @if (currentlySelectedMember) {
      <div class="mb-10 block">
        <div class="mb-3 flex items-center justify-between">
          <div class="container-header" style="padding-bottom: 0">Current Assignee</div>
          <div class="cip-transition mr-8 flex cursor-pointer flex-row items-center rounded-full bg-red-50 px-2 py-1 text-xs text-red-800 hover:bg-red-200" (click)="unselectMember()">
            <div class="mr-2 rounded-full bg-red-400 p-1 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-2.5 w-2.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            Unselect
          </div>
        </div>
        <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 lg:px-8">
          <div class="mb-4">
            <div class="list-row">
              <div class="list-a group cursor-pointer border-none">
                <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                  <div class="min-w-0 flex-auto">
                    <p class="list-title">{{ currentlySelectedMember.name_first }} {{ currentlySelectedMember.name_last }}</p>
                    <p class="list-meta">{{ currentlySelectedMember.email }}</p>
                    <p class="list-meta">{{ currentlySelectedMember.job_title }}</p>
                    <p class="list-meta">{{ currentlySelectedMember.role_title }}</p>
                  </div>
                </div>
                <div class="flex items-center text-brand_Primary">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
                    <path
                      fill-rule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    <!-- Assignees List -->
    @if (members.length > 0 && !allMembersHidden) {
      <div class="mb-3 flex items-center justify-between">
        <div class="container-header" style="padding-bottom: 0">Select New Assignee</div>
      </div>
    }
    <ul id="assigneesList">
      @if (allOtherMembers.length > 0) {
        <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
          @for (member of filteredMembers; track $index; let last = $last) {
            @if (!member.hidden) {
              <li [class.hidden]="member.hidden" [ngClass]="last ? 'border-none' : 'border-b'">
                <div class="list-row">
                  <!-- List Editing Tools -->
                  @if (actionForm.value.assignee_id !== member.id) {
                    <div class="list-a group cursor-pointer" (click)="selectMember(member)">
                      <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                        <div class="min-w-0 flex-auto">
                          <p class="list-title">{{ member.name_first }} {{ member.name_last }}</p>
                          <p class="list-meta">{{ member.email }}</p>
                          <p class="list-meta">{{ member.job_title }}</p>
                          <p class="list-meta">{{ member.role_title }}</p>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </li>
            }
          }
        </div>
      }
    </ul>
  </div>
  @if (allMembersHidden) {
    <search-empty [searchTerm]="searchTerm" />
  }
} @else {
  <div class="flex h-full items-center justify-center">
    <loading-spinner />
  </div>
}
