@if (testCategoryPhoto$ | async; as combinedData) {
  <!-- Breadcrumbs -->
  <breadcrumbs [workspaceId]="workspaceId" [folderId]="folderId" [inspectionId]="inspectionId" [categoryId]="categoryId" [itemId]="itemId" />

  <!-- Navigation -->
  <test-navigation [currentItem]="currentItem" [currentCategoryOrder]="currentCategoryOrder" [totalItems]="totalItems" (navigateToPreviousItemEvent)="navigateToPreviousItem()" (navigateToNextItemEvent)="navigateToNextItem()" />

  <!-- Header -->
  <test-header [item]="combinedData.item" />

  <div class="grid grid-cols-1 gap-y-8">
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="testForm.pristine"></form-state>
      <button type="button" (click)="updateTestOrQuestion()" class="btn" [disabled]="testForm.pristine || testForm.controls['title'].errors" [ngClass]="testForm.pristine || testForm.controls['title'].errors ? 'btn-disabled' : 'btn-primary'">
        Save
      </button>
    </div>

    <!-- Test  Form  -->
    @if (testForm.value.mode === "test") {
      <test-form-wrapper
        [testForm]="testForm"
        [itemId]="itemId"
        [workspaceId]="workspaceId"
        [folderId]="folderId"
        [inspectionId]="inspectionId"
        [categoryId]="categoryId"
        [user]="user"
        [currentCategoryOrder]="currentCategoryOrder"
        [itemLimitationManagerResult]="itemLimitationManagerResult"
        [photoLimitationManagerResult]="photoLimitationManagerResult"
        [isTestNotesEditable]="isTestNotesEditable"
        [uploadFileArray]="uploadFileArray$ | async"
        [photoPreview]="photoPreview"
        [overlay_uploadPhotos]="overlay_uploadPhotos"
        [webUploadLimit]="webUploadLimit"
        (enableOrDisableTestOutput)="enableOrDisableTest()"
        (removeAnswerOutput)="removeAnswer()"
        (itemAnswerLimitationManagerCheckOutput)="itemAnswerLimitationManagerCheck($event)"
        (canUserEditNotesOutput)="canUserEditNotes()"
        (onNotesTextAreaInputOutput)="onNotesTextAreaInput($event)"
        (detectPhotosOutput)="detectPhotos($event)"
        (removePhotoFromArrayOutput)="removePhotoFromArray($event)"
        (photoUploadsCompleteSaveTestOutput)="photoUploadsCompleteSaveTest($event)"
        [photos]="combinedData.photos"
        (reorderPhotosOutput)="reorderPhotos($event)"
        (deletePhotoOutput)="setDeletePhotoProperties($event)" />
    }

    <!-- Question Form-->
    @if (testForm.value.mode === "question") {
      <question-form-wrapper [testForm]="testForm" [currentCategoryOrder]="currentCategoryOrder" (enableOrDisableTestOutput)="enableOrDisableTest()" />
    }

    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="testForm.pristine"></form-state>
      <button type="button" (click)="updateTestOrQuestion()" class="btn" [disabled]="testForm.pristine || testForm.controls['title'].errors" [ngClass]="testForm.pristine || testForm.controls['title'].errors ? 'btn-disabled' : 'btn-primary'">
        Save
      </button>
    </div>
  </div>
} @else {
  <loading-spinner />
}
@if (overlay_deletePhoto) {
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeletePhotoOverlay()" (deleteButtonClicked)="deletePhoto()" />
}
