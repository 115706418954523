<div class="signature-wrapper">
  <div class="header text-group-1">
    <p class="title" [ngStyle]="{ color: themeSettingsForm.value.colours_brand }">Signatures</p>
    <div class="divider" [ngStyle]="{ 'background-color': themeSettingsForm.value.colours_brand }"></div>
  </div>
  <ul class="signature-list-wrapper">
    <!-- Loop here -->
    <li class="signature-item">
      <div class="signature-row-1">
        <div class="signature-data-wrapper">
          <p class="signature-data-title text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Name:</p>
          <p class="signature-data text-group-5">Charles Moran</p>
        </div>
        <div class="signature-data-wrapper">
          <p class="signature-data-title text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Date:</p>
          <p class="signature-data text-group-5">
            {{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}
          </p>
        </div>
      </div>
      <div class="signature-row-2">
        <p class="signature-data-title text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Signature:</p>
        <img src="../../../../../../../../assets/images/theme-preview/signature-01.png" class="signature-image" alt="Signature Image" />
      </div>
    </li>
    <li class="signature-item">
      <div class="signature-row-1">
        <div class="signature-data-wrapper">
          <p class="signature-data-title text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Name:</p>
          <p class="signature-data text-group-5">Charles Moran</p>
        </div>
        <div class="signature-data-wrapper">
          <p class="signature-data-title text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Date:</p>
          <p class="signature-data text-group-5">
            {{ themeStylesAndSettingsSerivce.demoDate | dateFormat: themeSettingsForm.value.dates_order : themeSettingsForm.value.dates_style }}
          </p>
        </div>
      </div>
      <div class="signature-row-2">
        <p class="signature-data-title text-group-5" [ngStyle]="{ color: themeSettingsForm.value.colours_subtitle }">Signature:</p>
        <img src="../../../../../../../../assets/images/theme-preview/signature-01.png" class="signature-image" alt="Signature Image" />
      </div>
    </li>
  </ul>
</div>
