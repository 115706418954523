<div class="overall-chart-wrapper">
  <!-- Key -->
  <div class="chart-key-wrapper">
    <div class="chart-key-item">
      <div class="chart-key chart-key-0"></div>
      <div class="chart-text">Scored 0% ({{ items[2].totalOfThisScore }})</div>
    </div>
    <div class="chart-key-item">
      <div class="chart-key chart-key-50"></div>
      <div class="chart-text">Scored 50% ({{ items[1].totalOfThisScore }})</div>
    </div>
    <div class="chart-key-item">
      <div class="chart-key chart-key-100"></div>
      <div class="chart-text">Scored 100% ({{ items[0].totalOfThisScore }})</div>
    </div>
  </div>
</div>

<div class="chart-svg-wrapper">
  <svg attr.width="{{ widthHeight + '%' }}" attr.height="{{ widthHeight + '%' }}" viewBox="0 0 42 42" class="donut">
    <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" stroke-width="20%"></circle>
    <circle
      class="donut-segment"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="#4caf50"
      stroke-width="20%"
      attr.stroke-dasharray="{{ items[0].overallPercentage }}, {{ 100 - items[0].overallPercentage }}"
      stroke-dashoffset="25"
    ></circle>
    <circle
      class="donut-segment"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="#ffeb3b"
      stroke-width="20%"
      attr.stroke-dasharray="{{ items[1].overallPercentage }}, {{ 100 - items[1].overallPercentage }}"
      attr.stroke-dashoffset="{{ 100 - items[0].overallPercentage + 25 }}"
    ></circle>
    <circle
      class="donut-segment"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="#F44336"
      stroke-width="20%"
      attr.stroke-dasharray="{{ items[2].overallPercentage }}, {{ 100 - items[2].overallPercentage }}"
      attr.stroke-dashoffset="{{ 100 - (items[0].overallPercentage + items[1].overallPercentage) + 25 }}"
    ></circle>
  </svg>
</div>
