<div class="content-card-wrapper">
  <div class="content-card-header flex items-center justify-between">
    <h3>Folder</h3>
    <tooltip [toolTipText]="toolTipInspectionDetailsFolder.title"></tooltip>
  </div>
  <div class="px-6 py-5">
    <form [formGroup]="inspectionForm" class="form-input-wrapper relative flex h-[60px] cursor-pointer items-center hover:ring-gray-400" (click)="toggleFolderSelectionOverlay()">
      <div class="text-brand_Primary-hover">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-4 w-4">
          <path
            d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z"
          />
        </svg>
      </div>

      <div class="w-full border-0 pl-3 text-base text-text_Title focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-700 sm:leading-6">
        {{ inspectionForm.value.folder_title }}
      </div>
      <div class="absolute left-0 top-0 flex h-full w-full items-center justify-end pr-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="-mr-1 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#C8C8C8" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </form>
  </div>
</div>

@if (overlay_folderSelector) {
  <inspection-details-folder-selector
    [folders]="folders$ | async"
    [inspectionForm]="inspectionForm"
    [currentlySelectedFolder]="currentlySelectedFolder"
    [allOtherFolders]="allOtherFolders"
    (folderSelectionOuput)="folderSelection($event)"
    (toggleNewFolderFormOutput)="canUserAddFolder()"
    (cancelSiteSelectionOutput)="toggleFolderSelectionOverlay()"
  />
}
@if (overlay_newFolder) {
  <folder-edit-wrapper [folderForm]="folderForm" [limitationResult]="limitationResult" [isNewFolder]="true" (toggleEditFolderOverlayOutput)="toggleNewFolderOverlay()" (saveFolderOutput)="saveNewFolder()" />
}
