import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TemplateCategoryEnhanced } from "src/app/models/category/template-category.model";
import { BreadcrumbsComponent } from "src/app/shared/breadcrumbs/breadcrumbs.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";

@Component({
  selector: "manage-template-categories-list-header",
  standalone: true,
  imports: [SearchBarComponent, NgClass, BreadcrumbsComponent, RouterModule],
  templateUrl: "./manage-template-categories-list-header.component.html",
  styleUrl: "./manage-template-categories-list-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateCategoriesListHeaderComponent {
  @Input({ required: true }) categories!: TemplateCategoryEnhanced[];
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) templateId!: string;
  @Input({ required: true }) editMode!: boolean;
  @Input() categoriesBulkEditArray: TemplateCategoryEnhanced[] | null = [];
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() toggleNewCategoryOutput = new EventEmitter<void>();
  @Output() toggleEditModeOutput = new EventEmitter<void>();
  @Output() bulkDeleteCategoriesOutput = new EventEmitter<void>();

  /**
   * On Search
   * @param searchTerm
   */
  public onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Bulk Delete Categories
   */
  public bulkDeleteCategories(): void {
    this.bulkDeleteCategoriesOutput.emit();
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.toggleEditModeOutput.emit();
  }

  /**
   * Toggle New Category
   */
  public toggleNewCategory(): void {
    this.toggleNewCategoryOutput.emit();
  }
}
