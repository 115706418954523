import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SignatureEnhanced } from 'src/app/models/inspection/signature.model';

@Component({
  selector: 'inspection-signoff-list-all',
  standalone: true,
  imports: [DatePipe, RouterModule, CdkDrag, CdkDropList],
  templateUrl: './inspection-signoff-list-all.component.html',
  styleUrl: './inspection-signoff-list-all.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionSignoffListAllComponent {
  @Input({ required: true }) signatures!: SignatureEnhanced[];
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input() signatureBulkEditArray: SignatureEnhanced[] | null = [];
  @Output() reorderSignaturesOutput = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() deleteSingleSignatureOutput = new EventEmitter<string>();
  @Output() createBatchEditOutput = new EventEmitter<SignatureEnhanced>();

  /**
   * Reorder signatures
   * @param event
   */
  public reorderSignatures(event: CdkDragDrop<string[]>): void {
    this.reorderSignaturesOutput.emit(event);
  }

  /**
   * Create Batch Edit
   * @param signature
   */
  public createBatchEdit(signature: SignatureEnhanced): void {
    this.createBatchEditOutput.emit(signature);
  }

  /**
   * Delete Single Signature
   * @param signatureId
   */
  public deleteSingleSignature(signatureId: string): void {
    this.deleteSingleSignatureOutput.emit(signatureId);
  }
}
