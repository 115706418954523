<div class="content-card-wrapper">
  <div class="content-card-header flex items-center justify-between">
    <h3>Details</h3>
    <button class="btn-secondary-small-default" (click)="limitationManagerCheck()">Edit Details</button>
  </div>
  <div class="px-6 py-5">
    <div class="content-grid gap-y-3">
      <div>
        <div class="text-sm text-text_Subtitle">Date</div>
        <div class="text-base text-text_Title">{{ inspection.date.toDate() | date: 'dd MMM yyyy' }}</div>
      </div>
      <div>
        <div class="text-sm text-text_Subtitle">Created By</div>
        <div class="text-base text-text_Title">{{ inspection.created_by }}</div>
      </div>
      @if (inspection.client_name) {
        <div>
          <div class="text-sm text-text_Subtitle">Client</div>
          <div class="text-base text-text_Title">{{ inspection.client_name }}</div>
        </div>
      }
      @if (inspection.ref) {
        <div>
          <div class="text-sm text-text_Subtitle">Reference</div>
          <div class="text-base text-text_Title">{{ inspection.ref }}</div>
        </div>
      }
      @if (inspection.template_id) {
        <div>
          <div class="text-sm text-text_Subtitle">Template</div>
          <div class="text-base text-text_Title">{{ inspection.template_title }}</div>
        </div>
      }
      @if (inspection.folder_id) {
        <div>
          <div class="text-sm text-text_Subtitle">Folder</div>
          <div class="text-base text-text_Title">{{ inspection.folder_title }}</div>
        </div>
      }
      @if (inspection.site_id) {
        <div>
          <div class="text-sm text-text_Subtitle">Site</div>
          <div class="flex flex-row">
            @if (inspection.site_photo_thumbnail_url) {
              <div class="relative mr-4 flex h-24 w-24 rounded-md bg-gray-600 bg-cover bg-center" [ngStyle]="{ 'background-image': 'url(' + inspection.site_photo_thumbnail_url + ')' }"></div>
            }
            <div class="flex flex-col">
              <div class="text-base text-text_Title">{{ inspection.site_title }}</div>
              <div class="cursor-pointer text-sm text-gray-400 hover:text-gray-500" (click)="toggleSiteInfo(inspection.site_id)">
                {{ showSiteInfo ? 'Less Site details' : 'More Site details' }}
              </div>
            </div>
          </div>
          @if (showSiteInfo) {
            @if (site) {
              <div class="content-grid gap-y-3 pt-3">
                @if (site.ref) {
                  <div>
                    <div class="text-sm text-text_Subtitle">Reference</div>
                    <div class="text-base text-text_Title">{{ site.ref }}</div>
                  </div>
                }
                @if (site.address) {
                  <div>
                    <div class="text-sm text-text_Subtitle">Address</div>
                    <div class="text-base text-text_Title">{{ site.address }}</div>
                  </div>
                }
                @if (site.longitude) {
                  <div>
                    <div class="text-sm text-text_Subtitle">Longitude</div>
                    <div class="text-base text-text_Title">{{ site.longitude }}</div>
                  </div>
                }
                @if (site.latitude) {
                  <div>
                    <div class="text-sm text-text_Subtitle">Latitude</div>
                    <div class="text-base text-text_Title">{{ site.latitude }}</div>
                  </div>
                }
                @if (site.map_url) {
                  <div>
                    <div class="text-sm text-text_Subtitle">Map</div>
                    <img src="{{ site.map_url }}" class="w-full" />
                  </div>
                }
              </div>
            }
          }
        </div>
      }
    </div>
  </div>
</div>
