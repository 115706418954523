<div class="grid-col-1 mb-28 grid">
  <form class="border-b border-gray-200 px-4" [formGroup]="themeSettingsForm">
    <h3 class="py-6">
      <div class="w-full">
        <div class="py-3 text-sm font-medium text-gray-900">Title</div>
        <div class="form-input-wrapper">
          <label for="theme-title" class="form-input-label"
            >Title*
            @if (!themeSettingsForm.pristine && themeSettingsForm.controls["title"].errors) {
              <span class="required-field"> - Title is required</span>
            }
          </label>
          <input type="text" name="theme-title" id="theme-title" class="form-input" placeholder="Title" formControlName="title" />
        </div>
        @if (themeSettingsForm.value.created_by) {
          <p class="list-meta mt-2">Created by {{ themeSettingsForm.value.created_by }}</p>
        }
      </div>
    </h3>
  </form>

  <manage-theme-edit-settings-toggle-front-cover [themeSettingsForm]="themeSettingsForm" />
  <manage-theme-edit-settings-toggle-site-cover [themeSettingsForm]="themeSettingsForm" />
  <manage-theme-edit-settings-toggle-charts-cover [themeSettingsForm]="themeSettingsForm" />
  <manage-theme-edit-settings-toggle-other-options [themeSettingsForm]="themeSettingsForm" />
  <manage-theme-edit-settings-toggle-photo [themeSettingsForm]="themeSettingsForm" />
  <manage-theme-edit-settings-toggle-date [themeSettingsForm]="themeSettingsForm" />
  <manage-theme-edit-settings-fonts [themeSettingsForm]="themeSettingsForm" />
  <manage-theme-edit-settings-colours [themeSettingsForm]="themeSettingsForm" />
  <manage-theme-edit-settings-customisations [themeSettingsForm]="themeSettingsForm" [themeStylesByType]="themeStylesByType" />

  @if (themeId !== "default") {
    <button type="button" aria-label="Delete Theme" type="button" class="btn btn-destructive mt-5 w-full" (click)="toggleDeleteOverlay()">Delete</button>
  }
</div>

<!-- <ng-container *ngIf="overlay_deleteTheme">
  <delete-overlay [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleDeleteOverlay()" (deleteButtonClicked)="deleteTheme()"></delete-overlay>
</ng-container> -->
