import { CdkDrag, CdkDragDrop, CdkDropList } from "@angular/cdk/drag-drop";
import { DatePipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionEnhanced, PriorityDict } from "src/app/models/action/action.model";
@Component({
  selector: "actions-list",
  standalone: true,
  imports: [NgClass, DatePipe, CdkDrag, CdkDropList],
  templateUrl: "./actions-list.component.html",
  styleUrl: "./actions-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsListComponent {
  @Input({ required: true }) actions!: ActionEnhanced[];
  @Input({ required: true }) editMode!: boolean;
  @Input({ required: true }) priorityDict!: PriorityDict;
  @Input() actionsBulkEditArray: ActionEnhanced[] | null = [];
  @Output() reorderActionsOutput = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() routeToActionOutput = new EventEmitter<string>();
  @Output() createBatchEditOutput = new EventEmitter<ActionEnhanced>();
  @Output() deleteSingleActionOutput = new EventEmitter<string>();

  /**
   * Reorder Actions
   * @param event
   */
  public reorderActions(event: CdkDragDrop<string[]>): void {
    this.reorderActionsOutput.emit(event);
  }

  /**
   * Route To Action
   * @param actionId
   */
  public routeToAction(actionId: string): void {
    this.routeToActionOutput.emit(actionId);
  }

  /**
   * Create Batch Edit
   * @param action
   */
  public createBatchEdit(action: ActionEnhanced): void {
    this.createBatchEditOutput.emit(action);
  }

  /**
   * Delete Single Action
   * @param categoryId
   */
  public deleteSingleAction(actionId: string): void {
    this.deleteSingleActionOutput.emit(actionId);
  }
}
