import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { TemplateCategoryEnhanced } from "src/app/models/category/template-category.model";
import { ItemType } from "src/app/models/item/item-type.model";
import { TemplateItemEnhanced } from "src/app/models/item/template-item.model";
import { BreadcrumbsComponent } from "src/app/shared/breadcrumbs/breadcrumbs.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";

@Component({
  selector: "manage-template-items-list-header",
  standalone: true,
  imports: [BreadcrumbsComponent, NgClass, MatMenuModule, MatButtonModule, SearchBarComponent, RouterModule],
  templateUrl: "./manage-template-items-list-header.component.html",
  styleUrl: "./manage-template-items-list-header.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateItemsListHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) templateId!: string;
  @Input({ required: true }) categoryId!: string;
  @Input({ required: true }) items: TemplateItemEnhanced[] = [];
  @Input({ required: true }) editMode!: boolean;
  @Input({ required: true }) currentCategory!: TemplateCategoryEnhanced;
  @Input() itemsBulkEditArray: TemplateItemEnhanced[] | null = [];
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() toggleNewItemOutput = new EventEmitter<ItemType>();
  @Output() toggleEditModeOutput = new EventEmitter<void>();
  @Output() bulkDeleteItemsOutput = new EventEmitter<void>();

  /**
   * On Search
   * @param searchTerm
   */
  public onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Bulk Delete Items
   */
  public bulkDeleteItems(): void {
    this.bulkDeleteItemsOutput.emit();
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.toggleEditModeOutput.emit();
  }

  /**
   * Toggle New Item
   */
  public toggleNewItem(type: ItemType): void {
    this.toggleNewItemOutput.emit(type);
  }
}
