import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { InfoViewFilter } from "src/app/models/strings/strings.model";
import { InfoViewComponent } from "src/app/shared/info-view/info-view.component";

@Component({
  selector: "manage-theme-edit-header",
  standalone: true,
  imports: [InfoViewComponent, RouterModule],
  templateUrl: "./manage-theme-edit-header.component.html",
  styleUrl: "./manage-theme-edit-header.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditHeaderComponent {
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) themeId!: string;
  @Input({ required: true }) infoViewThemeEditor!: InfoViewFilter;
}
