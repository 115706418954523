<form [formGroup]="actionForm">
  <div class="content-card-wrapper">
    <div class="content-card-header">
      <h3>Details</h3>
    </div>
    <div class="px-6 py-5">
      <div class="content-grid gap-x-4 gap-y-6">
        <div class="sm:col-span-6">
          <div class="form-input-wrapper">
            <label for="action-title" class="form-input-label"
              >Title*
              @if (!actionForm.pristine && actionForm.controls["title"].errors) {
                <span class="required-field"> - Title is required</span>
              }
            </label>
            <input type="text" name="action-title" id="action-title" class="form-input" placeholder="Title" formControlName="title" />
          </div>
        </div>

        <div class="col-span-6">
          <div class="form-input-wrapper" id="cip-date-picker">
            <label for="inspection-date" class="form-input-label">Date </label>
            <div class="sm:col-span-1">
              <div class="flex justify-between">
                <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date" class="form-input" formControlName="due_date" id="due_date" />
                @if (actionForm.value.due_date) {
                  <button (click)="clearActionDate()" class="rounded-md hover:bg-brand_Primary-hover hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                  </button>
                }
              </div>
              <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
            </div>
          </div>
        </div>

        <div class="sm:col-span-6">
          <div class="form-input-wrapper">
            <label for="action-notes" class="form-input-label">Notes</label>
            <textarea type="text" name="action-notes" id="action-notes" class="form-input" placeholder="Notes" formControlName="notes"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
