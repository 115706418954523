@for (category of reportsService.reportData.categories; track category.order) {
  <div class="category-wrapper" style="break-before: page">
    <div class="category-content">
      <div class="category-header" [ngStyle]="{ color: reportsService.themeDetails.value.colours_brand }">
        <p class="category-title text-group-1">{{ category.order + 1 }}. {{ category.title }}</p>
        <div class="category-items text-group-5">{{ category.items_count }} Items</div>
        <div class="category-header-border" [ngStyle]="{ 'background-color': reportsService.themeDetails.value.colours_brand }"></div>
      </div>

      @if (category.notes) {
        <p
          class="category-notes text-group-4"
          [ngStyle]="{
            color: reportsService.themeDetails.value.colours_content,
            'margin-bottom.pt': category.items_count > 0 ? '24' : '0',
          }">
          {{ category.notes }}
        </p>
      }
    </div>

    @if (category.items_count > 0) {
      <div class="category-items-wrapper">
        <report-content-items [category]="category" />
      </div>
    }
  </div>
}
