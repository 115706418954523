import { AsyncPipe, JsonPipe } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RolePermissions, SupplementaryType, User } from "cip";
import { combineLatest, filter, map, Observable, shareReplay, Subject, switchMap, takeUntil, tap } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { ActionEnhanced } from "src/app/models/action/action.model";
import { CategoryEnhanced } from "src/app/models/category/category.model";
import { ChartsActionResults } from "src/app/models/charts/charts-action.model";
import { ChartsAssigneeAction, ChartsAssigneeResults } from "src/app/models/charts/charts-assignees.model";
import { ChartsTestResults } from "src/app/models/charts/charts-tests.model";
import { InspectionEnhanced } from "src/app/models/inspection/inspection.model";
import { SignatureEnhanced } from "src/app/models/inspection/signature.model";
import { ItemEnhanced } from "src/app/models/item/item.model";
import { SiteEnhanced } from "src/app/models/site/site.model";
import { ActionsListService } from "src/app/sections/actions/services/actions-list.service";
import { InspectionCategoriesListService } from "src/app/sections/categories/services/inspection-categories-list.service";
import { inspectionItemsListService } from "src/app/sections/items/services/inspection-items-list.service";
import { SiteDetailService } from "src/app/sections/workspaces/current-workspace/manage-sites/services/site-detail.service";
import { LoadingSpinnerComponent } from "src/app/shared/loading-spinner/loading-spinner.component";
import { ChartsService } from "../../services/charts.service";
import { InspectionDetailService } from "../../services/inspection-detail.service";
import { SignOffListService } from "../../services/sign-off-list.service";
import { InspectionOverviewActionsComponent } from "../inspection-overview-actions/inspection-overview-actions.component";
import { InspectionOverviewContentsComponent } from "../inspection-overview-contents/inspection-overview-contents.component";
import { InspectionOverviewDetailsComponent } from "../inspection-overview-details/inspection-overview-details.component";
import { InspectionOverviewHeaderComponent } from "../inspection-overview-header/inspection-overview-header.component";
import { InspectionOverviewSignOffComponent } from "../inspection-overview-sign-off/inspection-overview-sign-off.component";
import { InspectionOverviewStatsWrapperComponent } from "../inspection-overview-stats/inspection-overview-stats-wrapper/inspection-overview-stats-wrapper.component";

interface CombinedInspectionData {
  categories: CategoryEnhanced[];
  items: ItemEnhanced[];
  actions: ActionEnhanced[];
  inspection: InspectionEnhanced;
  signatures: SignatureEnhanced[];
}

@Component({
  selector: "inspection-overview-wrapper",
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    InspectionOverviewHeaderComponent,
    InspectionOverviewActionsComponent,
    InspectionOverviewContentsComponent,
    InspectionOverviewSignOffComponent,
    InspectionOverviewStatsWrapperComponent,
    InspectionOverviewDetailsComponent,
    LoadingSpinnerComponent,
  ],
  templateUrl: "./inspection-overview-wrapper.component.html",
  styleUrl: "./inspection-overview-wrapper.component.scss",
})
export class InspectionOverviewWrapperComponent implements OnInit, OnDestroy {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  @Input({ required: true }) set folderId(value: string) {
    this._folderId = value;
  }

  get folderId(): string {
    return this._folderId;
  }

  @Input({ required: true }) set inspectionId(value: string) {
    this._inspectionId = value;
  }

  get inspectionId(): string {
    return this._inspectionId;
  }

  // Services
  private inspectionDetailService = inject(InspectionDetailService);
  private inspectionCategoriesListService = inject(InspectionCategoriesListService);
  public inspectionItemsListService = inject(inspectionItemsListService);
  private actionsListService = inject(ActionsListService);
  public chartsService = inject(ChartsService);
  private siteDetailService = inject(SiteDetailService);
  private signOffListService = inject(SignOffListService);
  private limitationManagerService = inject(LimitationManagerService);
  private router = inject(Router);
  private authService = inject(AuthService);

  // Properties
  public user!: User;
  public _workspaceId!: string;
  public _folderId!: string;
  public _inspectionId!: string;
  public inspection$!: Observable<InspectionEnhanced>;
  public items$!: Observable<ItemEnhanced[]>;
  public categories$!: Observable<CategoryEnhanced[]>;
  public actions$!: Observable<ActionEnhanced[]>;
  public site$!: Observable<SiteEnhanced>;
  public signatures$!: Observable<SignatureEnhanced[]>;
  public chartsAssignees: ChartsAssigneeResults[] = [];
  public actionResults: ChartsActionResults[] = [];
  public chartsResults: ChartsTestResults[] = [];
  public showSiteInfo: boolean = false;
  private overallScore: number = 0;
  public combinedData$!: Observable<CombinedInspectionData>;
  private destroy$ = new Subject<void>();

  constructor() {
    this.user = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.getInspectionDoc();
    this.getSignaturesList();
    this.initializeDataStreams();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Get Inspection Doc
   */
  private async getInspectionDoc(): Promise<void> {
    try {
      this.inspection$ = this.inspectionDetailService.getInspectionDoc$(this.workspaceId, this.inspectionId).pipe(
        filter((data) => !!data),
        map((data) => {
          const inspectionDate = data.date?.toDate();
          return { ...data, inspectionDate };
        })
      );
    } catch (error) {
      alert(error);
    }
  }

  /**
   * Get Signatures List
   */
  private async getSignaturesList(): Promise<void> {
    this.signatures$ = this.signOffListService.getSignaturesList$(this.workspaceId, this.inspectionId).pipe(filter((data) => !!data));
  }

  /**
   * Get Statistics
   */
  private initializeDataStreams(): void {
    this.categories$ = this.inspectionCategoriesListService.getEnabledCategories$(this.workspaceId, this.inspectionId).pipe(
      filter((data) => !!data),
      takeUntil(this.destroy$),
      shareReplay(1)
    );

    this.inspection$ = this.inspectionDetailService.getInspectionDoc$(this.workspaceId, this.inspectionId).pipe(
      filter((data) => !!data),
      map((data) => ({
        ...data,
        inspectionDate: data.date?.toDate(),
      })),
      takeUntil(this.destroy$)
    );

    this.signatures$ = this.signOffListService.getSignaturesList$(this.workspaceId, this.inspectionId).pipe(
      filter((data) => !!data),
      takeUntil(this.destroy$)
    );

    this.items$ = combineLatest([this.categories$, this.inspectionItemsListService.getInspectionTests$(this.workspaceId, this.inspectionId)]).pipe(
      map(([categories, items]) => items.filter((item) => categories.some((cat) => cat.id === item.category_id))),
      takeUntil(this.destroy$),
      shareReplay(1)
    );
    // Actions depend on filtered items
    this.actions$ = this.items$.pipe(
      switchMap((items) => this.actionsListService.getInspectionActions$(this.workspaceId, this.inspectionId).pipe(map((actions) => actions.filter((action) => items.some((item) => item.id === action.item_id))))),
      tap((data) => {
        this.filterActionByAssignee(data as ChartsAssigneeAction[]);
        this.filterActionResults(data);
      }),
      takeUntil(this.destroy$)
    );

    // Combine all streams and execute side effects once
    this.combinedData$ = combineLatest({
      categories: this.categories$,
      items: this.items$,
      actions: this.actions$,
      inspection: this.inspection$,
      signatures: this.signatures$,
    }).pipe(
      tap((data) => {
        this.setFilteredTestsAndCounts(data.items);
        this.filterChartsTestResultsAndUpdateOverallScore(data.items);
      }),
      takeUntil(this.destroy$)
    );
  }

  /**
   * Filter Charts Test Results
   * @param items
   */
  private async filterChartsTestResultsAndUpdateOverallScore(items: ItemEnhanced[]): Promise<void> {
    const results = this.chartsService.filterChartsTestResults(items);
    this.chartsResults = results.testResults;
    this.overallScore = results.overallScore;
    await this.inspectionDetailService.updateInspectionOverallScore(this.workspaceId, this.inspectionId, this.overallScore);
  }

  /**
   * Filter Action By Assignee
   * @param actions
   */
  private filterActionByAssignee(actions: ChartsAssigneeAction[]): void {
    this.chartsAssignees = this.chartsService.filterActionByAssignee(actions);
  }

  /**
   * Filter Action Results
   * @param actions
   */
  private filterActionResults(actions: ActionEnhanced[]): void {
    this.actionResults = this.chartsService.filterActionResults(actions);
  }

  /**
   * Set Filtered Tests And Counts
   */
  private setFilteredTestsAndCounts(filteredTests: ItemEnhanced[]): void {
    this.inspectionItemsListService.totalTests = filteredTests.length;
    this.inspectionItemsListService.totalTestsWithAnswers = filteredTests.filter((test) => test.answered === true).length;
    this.inspectionItemsListService.percentageOfAnsweredTests = ((this.inspectionItemsListService.totalTestsWithAnswers / this.inspectionItemsListService.totalTests) * 100) | 0;
  }

  /**
   * Navigate To Supplementary
   * @param supplementaryType
   */
  public async navigateToSupplementary(supplementaryType: SupplementaryType): Promise<void> {
    const featureAction: keyof RolePermissions = "inspection_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    limitationResult ? this.routeToSupplementaryEditor(supplementaryType) : (this.limitationManagerService.overlay_limitationManager = true);
  }

  /**
   * Route To Supplementary Editor
   * @param supplementaryType
   */
  routeToSupplementaryEditor(supplementaryType: SupplementaryType): void {
    this.router.navigate(["/", "workspace", this.workspaceId, "folders", this.folderId, "inspections", this.inspectionId, supplementaryType]);
  }

  /**
   * Edit Details
   */
  public async editDetails(): Promise<void> {
    const featureAction: keyof RolePermissions = "inspection_create_update";
    const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
    limitationResult ? this.routeToInspectionDetails() : (this.limitationManagerService.overlay_limitationManager = true);
  }

  /**
   * Route To Inspection Details
   */
  routeToInspectionDetails(): void {
    const route = [`/workspace/${this.workspaceId}/folders/${this.folderId}/inspections/${this.inspectionId}/details`];
    this.router.navigate(route);
  }

  /**
   * Toggle Site
   * @param siteId
   */
  public async toggleSite(siteId: string): Promise<void> {
    if (!this.site$) {
      this.site$ = this.siteDetailService.getSiteDoc$(this.workspaceId, siteId).pipe(filter((data) => !!data));
      this.showSiteInfo = true;
      return;
    }
    this.showSiteInfo = !this.showSiteInfo;
  }
}
