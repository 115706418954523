import { inject, Injectable } from "@angular/core";
import { User } from "cip";
import { orderBy, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CollectionsService } from "src/app/core/services/collections/collections.service";
import { FirestoreUtilsService } from "src/app/core/services/firestore/firestore-utils.service";
import { LastEventService } from "src/app/core/services/last-event/last-event.service";
import { TemplateCategoryEnhanced } from "src/app/models/category/template-category.model";
import { BatchOperation } from "src/app/models/utils/batch";

@Injectable({
  providedIn: "root",
})
export class TemplateCategoriesListService {
  // Services
  private collectionsService = inject(CollectionsService);
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private lastEventService = inject(LastEventService);

  /**
   * Get Categories List
   * @param workspaceId
   * @param templateId
   */
  public getCategoriesList$(workspaceId: string, templateId: string): Observable<TemplateCategoryEnhanced[]> {
    const path = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const queryConstraints = [where("is_deleted", "==", false), orderBy("order", "asc")];
    return this.firestoreUtilsService.getCollectionData<TemplateCategoryEnhanced>(path, queryConstraints);
  }

  /**
   * Get Enabled Categories
   * @param workspaceId
   * @param templateId
   */
  public getEnabledCategories$(workspaceId: string, templateId: string): Observable<TemplateCategoryEnhanced[]> {
    const path = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const queryConstraints = [where("is_deleted", "==", false), where("enabled", "==", true), orderBy("order", "asc")];
    return this.firestoreUtilsService.getCollectionData<TemplateCategoryEnhanced>(path, queryConstraints);
  }

  /**
   * Delete Batched Categories
   * @param workspaceId
   * @param templateId
   * @param Categories
   * @param user
   */
  async deleteBatchedCategories(workspaceId: string, templateId: string, batchedCategories: TemplateCategoryEnhanced[], allCategories: TemplateCategoryEnhanced[], user: User) {
    const path = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const operations: BatchOperation[] = [];

    // Add delete operations for each signature in batchedCategories
    batchedCategories.forEach((category) => {
      const doc = `${path}/${category.id}`;
      const lastEvent = this.lastEventService.lastEvent("deleted", user);
      const obj = { is_deleted: true, last_event: lastEvent };
      operations.push({
        type: "update",
        documentPath: doc,
        data: obj,
      });
    });

    // Filter out the Categories that are in the batchedCategories to get the remaining ones
    const remainingCategories = allCategories.filter((existingCategory) => !batchedCategories.some((batchedCategory) => batchedCategory.id === existingCategory.id));

    // Add update operations to reorder the remaining Categories
    remainingCategories.forEach((remainingCategory, index) => {
      const updatePath = `${path}/${remainingCategory.id}`;
      operations.push({
        type: "update",
        documentPath: updatePath,
        data: { order: index },
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Reorder Categories
   * @param categories
   * @param workspaceId
   * @param templateId
   * @param user
   */
  async reorderCategories(categories: TemplateCategoryEnhanced[], workspaceId: string, templateId: string, user: User) {
    const path = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    let index = 0;

    const operations: BatchOperation[] = [];

    categories.forEach((category: TemplateCategoryEnhanced) => {
      const doc = `${path}/${category.id}`;
      const lastEvent = this.lastEventService.lastEvent("changed", user);

      operations.push({
        type: "update",
        documentPath: doc,
        data: {
          order: index,
          last_event: lastEvent,
        },
      });

      index = index + 1;
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Enable Disable Categories
   * @param workspaceId
   * @param templateId
   * @param categories
   * @param enabled
   * @param user
   */
  async enableDisableCategories(workspaceId: string, templateId: string, categories: TemplateCategoryEnhanced[], enabled: boolean, user: User): Promise<void> {
    const path = this.collectionsService.templateCategoriesCol(workspaceId, templateId);
    const operations: BatchOperation[] = [];

    categories.forEach((category: TemplateCategoryEnhanced) => {
      const doc = `${path}/${category.id}`;
      const lastEvent = this.lastEventService.lastEvent("changed", user);
      const obj = { enabled: enabled, last_event: lastEvent };

      operations.push({
        type: "update",
        documentPath: doc,
        data: obj,
      });
    });

    try {
      await this.firestoreUtilsService.batchWrite(operations);
    } catch (error) {
      throw error;
    }
  }
}
