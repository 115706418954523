// Generated by CoffeeScript 2.4.1
(function () {
  // Represents a list of string entries
  var XMLDOMStringList;
  module.exports = XMLDOMStringList = function () {
    class XMLDOMStringList {
      // Initializes a new instance of `XMLDOMStringList`
      // This is just a wrapper around an ordinary
      // JS array.

      // `arr` the array of string values
      constructor(arr) {
        this.arr = arr || [];
      }

      // Returns the indexth item in the collection.

      // `index` index into the collection
      item(index) {
        return this.arr[index] || null;
      }

      // Test if a string is part of this DOMStringList.

      // `str` the string to look for
      contains(str) {
        return this.arr.indexOf(str) !== -1;
      }
    }
    ;

    // Returns the number of strings in the list.
    Object.defineProperty(XMLDOMStringList.prototype, 'length', {
      get: function () {
        return this.arr.length;
      }
    });
    return XMLDOMStringList;
  }.call(this);
}).call(this);