import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "manage-template-edit-details",
  standalone: true,
  imports: [ReactiveFormsModule, DatePipe],
  templateUrl: "./manage-template-edit-details.component.html",
  styleUrl: "./manage-template-edit-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateEditDetailsComponent {
  @Input({ required: true }) templateForm!: FormGroup;
}
