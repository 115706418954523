import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryEnhanced } from 'src/app/models/category/category.model';
import { BreadcrumbsComponent } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { SearchBarComponent } from 'src/app/shared/search-bar/search-bar.component';

@Component({
  selector: 'inspection-categories-list-header',
  standalone: true,
  imports: [SearchBarComponent, NgClass, BreadcrumbsComponent],
  templateUrl: './inspection-categories-list-header.component.html',
  styleUrl: './inspection-categories-list-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionCategoriesListHeaderComponent {
  @Input({ required: true }) categories!: CategoryEnhanced[];
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) folderId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) editMode!: boolean;
  @Input({ required: true }) toggleDisabled!: boolean;
  @Input() categoriesBulkEditArray: CategoryEnhanced[] | null = [];
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() toggleNewCategoryOutput = new EventEmitter<void>();
  @Output() toggleEditModeOutput = new EventEmitter<void>();
  @Output() bulkDeleteCategoriesOutput = new EventEmitter<void>();
  @Output() bulkEnableOrDisableCategoriesOutput = new EventEmitter<void>();
  @Output() toggleCategoryEnabledOutput = new EventEmitter<CategoryEnhanced>();

  /**
   * On Search
   * @param searchTerm
   */
  public onSearch(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  /**
   * Toggle Disabled Categories
   */
  public toggleDisabledCategories(): void {
    this.toggleCategoryEnabledOutput.emit();
  }

  /**
   * Bulk Delete Categories
   */
  public bulkDeleteCategories(): void {
    this.bulkDeleteCategoriesOutput.emit();
  }

  /**
   * Bulk Enable Or Disable Categories
   */
  public bulkEnableOrDisableCategories(): void {
    this.bulkEnableOrDisableCategoriesOutput.emit();
  }

  /**
   * Toggle Edit Mode
   */
  public toggleEditMode(): void {
    this.toggleEditModeOutput.emit();
  }

  /**
   * Toggle New Category
   */
  public toggleNewCategory(): void {
    this.toggleNewCategoryOutput.emit();
  }
}
