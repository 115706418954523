import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AccountMenuComponent } from "../account-menu/account-menu.component";

@Component({
  selector: "account-wrapper",
  standalone: true,
  imports: [RouterModule, AccountMenuComponent],
  templateUrl: "./account-wrapper.component.html",
  styleUrl: "./account-wrapper.component.scss",
})
export class AccountWrapperComponent {
  @Input({ required: true }) set workspaceId(value: string) {
    this._workspaceId = value;
  }
  get workspaceId(): string {
    return this._workspaceId;
  }

  // Properties
  private _workspaceId!: string;
}
