import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "test-fail-pass",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
  templateUrl: "./test-fail-pass.component.html",
  styleUrl: "./test-fail-pass.component.scss",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestFailPassComponent {
  @Input({ required: true }) testForm!: FormGroup;
  public answerType = [
    { title: "Fail", value: "fail" },
    { title: "Pass", value: "pass" },
  ];
}
