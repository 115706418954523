<!-- Dummy breadcrumb element-->
<div class="px-6 pb-2"></div>
<!-- Header -->
<div class="page-header-wrapper">
  <div class="md:flex md:items-center md:justify-between">
    <div class="min-w-0 flex-1">
      <h2 class="page-header">Connected Devices</h2>
    </div>
  </div>
</div>

<!-- Content -->
<div class="grid grid-cols-1 gap-y-8">
  <div class="content-card-wrapper">
    <div class="px-6">
      <div class="content-grid">
        <connected-devices />
      </div>
    </div>
  </div>
</div>
