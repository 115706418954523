import { inject, Injectable } from "@angular/core";
import { Auth, authState, signOut, User } from "@angular/fire/auth";
import { Database, get, objectVal, onDisconnect, onValue, ref, remove, set, update } from "@angular/fire/database";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { filter, map, of, Subscription, switchMap, tap } from "rxjs";
import { appStorage } from "../util/app-storage";
import { AuthService } from "./auth.service";
import { FirestoreUtilsService } from "./firestore/firestore-utils.service";

interface DeviceDataSent {
  device: string;
  date_connected: number | typeof import("@angular/fire/database").serverTimestamp;
}

interface DeviceDataReceived {
  device: string;
  date_connected: number;
  action?: string;
}

interface DeviceDataProcessed {
  id: string;
  device: string;
  date_connected: Date;
}

interface DeviceInfo {
  browser: string;
  deviceType: string;
  os: string;
}

interface SnapshotAction<T> {
  type: string;
  key: string | null;
  payload: {
    key: string | null;
    val(): T;
  };
}

@Injectable({
  providedIn: "root",
})
export class ConnectedDevicesService {
  // Services
  private firestoreUtilsService = inject(FirestoreUtilsService);
  private db = inject(Database);
  private auth = inject(Auth);
  private deviceService = inject(DeviceDetectorService);
  private router = inject(Router);
  private authService = inject(AuthService);

  // Properties
  public uniqueId!: string;
  public connectedDevices: DeviceDataProcessed[] = [];
  private deviceInfo: DeviceInfo;
  private deviceListSubscription!: Subscription;
  public overlay_connectedDevices = false;
  private didUserSignOut = false;

  constructor() {
    this.deviceInfo = this.getDeviceInfo();
    this.initializeService();
  }

  get deviceCount(): number {
    return this.connectedDevices.length;
  }

  get isUnderConnectionLimit(): boolean {
    return this.deviceCount < 4;
  }

  private initializeService(): void {
    try {
      this.userStatusUpdatedInDB().subscribe();
      this.deviceListSubscription = new Subscription();
      this.subscribeToDeviceList();
    } catch (error) {
      console.error("Error in initializeService:", error);
    }
  }

  private getDeviceInfo(): DeviceInfo {
    try {
      const { browser, deviceType, os } = this.deviceService.getDeviceInfo();
      return { browser, deviceType, os };
    } catch (error) {
      console.error("Error in getDeviceInfo:", error);
      return { browser: "Unknown", deviceType: "Unknown", os: "Unknown" };
    }
  }

  private userStatusUpdatedInDB() {
    const connection = objectVal(ref(this.db, ".info/connected")).pipe(map((connected) => (connected ? "online" : "offline")));

    return authState(this.auth).pipe(
      switchMap((user) => (user ? connection.pipe(map((status) => ({ user, status }))) : of({ user: null, status: "offline" }))),
      filter(({ user, status }) => user !== null && status === "online"),
      tap(() => {
        this.didUserSignOut = false;
        this.setUserPresenceInDb();
      })
    );
  }

  private async setUserPresenceInDb(): Promise<void> {
    try {
      const user = this.auth.currentUser;
      if (!user) return;

      const uniqueId = appStorage.getCipAuthUniqueSessionId();
      const sessionTabId = this.getOrCreateSessionTabId();

      if (uniqueId) {
        this.setupChildRemovedListener(user, uniqueId);
      } else if (!this.didUserSignOut) {
        this.handleNewSession(user, sessionTabId);
      }
    } catch (error) {
      console.error("Error in setUserPresenceInDb:", error);
    }
  }

  private getOrCreateSessionTabId(): string {
    try {
      const sessionTabId = sessionStorage.getItem("sessionTabId") || this.firestoreUtilsService.createFirestoreId();
      sessionStorage.setItem("sessionTabId", sessionTabId);
      return sessionTabId;
    } catch (error) {
      console.error("Error in getOrCreateSessionTabId:", error);
      return this.firestoreUtilsService.createFirestoreId();
    }
  }

  private handleNewSession(user: any, sessionTabId: string): void {
    try {
      const setLock = () => {
        localStorage.setItem("cipAuthUniqueSessionLock", "locked");
        localStorage.setItem("cipAuthUniqueSessionLockTab", sessionTabId);
      };

      const removeLock = () => {
        if (localStorage.getItem("cipAuthUniqueSessionLockTab") === sessionTabId) {
          localStorage.removeItem("cipAuthUniqueSessionLock");
          localStorage.removeItem("cipAuthUniqueSessionLockTab");
        }
      };

      if (!localStorage.getItem("cipAuthUniqueSessionLock")) {
        setLock();
        setTimeout(() => this.createNewSessionIfLockOwner(user, sessionTabId, removeLock), 500);
      }
    } catch (error) {
      console.error("Error in handleNewSession:", error);
    }
  }

  private createNewSessionIfLockOwner(user: any, sessionTabId: string, removeLock: () => void): void {
    try {
      if (localStorage.getItem("cipAuthUniqueSessionLockTab") === sessionTabId) {
        const sessionId = this.createNewSessionId();
        const deviceData = this.createDeviceDataStructure();
        this.setupDevicePresence(user, sessionId, deviceData, removeLock);
      }
    } catch (error) {
      console.error("Error in createNewSessionIfLockOwner:", error);
    }
  }

  private setupDevicePresence(user: any, sessionId: string, deviceData: DeviceDataSent, removeLock: () => void): void {
    try {
      const userStatusDatabaseRef = ref(this.db, `/status/${user.uid}/devices/${sessionId}`);
      onValue(ref(this.db, ".info/connected"), (snapshot) => {
        if (!snapshot.val()) return;

        onDisconnect(userStatusDatabaseRef)
          .set({
            device: null,
            date_connected: null,
            action: null,
          })
          .then(() => {
            set(userStatusDatabaseRef, deviceData);
            this.setupChildRemovedListener(user, sessionId);
            removeLock();
          })
          .catch((error) => {
            console.error("Error in setupDevicePresence onDisconnect:", error);
          });
      });

      this.setupBeforeUnloadListener();
    } catch (error) {
      console.error("Error in setupDevicePresence:", error);
    }
  }

  private setupBeforeUnloadListener(): void {
    try {
      const removeSessionIdFromStorage = () => {
        appStorage.removeCipAuthUniqueSessionId();
        window.removeEventListener("beforeunload", removeSessionIdFromStorage);
      };
      window.addEventListener("beforeunload", removeSessionIdFromStorage);
    } catch (error) {
      console.error("Error in setupBeforeUnloadListener:", error);
    }
  }

  private setupChildRemovedListener(user: any, uniqueId: string): void {
    try {
      if (user && !this.didUserSignOut) {
        const userStatusDatabaseRef = ref(this.db, `/status/${user.uid}/devices/${uniqueId}`);
        onValue(userStatusDatabaseRef, () => {
          if (!appStorage.getCipAuthUniqueSessionId()) {
            this.setUserPresenceInDb();
          }
        });
      }
    } catch (error) {
      console.error("Error in setupChildRemovedListener:", error);
    }
  }

  private createNewSessionId(): string {
    try {
      const id = this.firestoreUtilsService.createFirestoreId();
      appStorage.setCipAuthUniqueSessionId(id);
      return id;
    } catch (error) {
      console.error("Error in createNewSessionId:", error);
      return "";
    }
  }

  private createDeviceDataStructure(): DeviceDataSent {
    try {
      return {
        device: `${this.deviceInfo.browser} on ${this.deviceInfo.os} ${this.deviceInfo.deviceType}`,
        date_connected: Date.now(), // Replace firebase.database.ServerValue.TIMESTAMP
      };
    } catch (error) {
      console.error("Error in createDeviceDataStructure:", error);
      return { device: "Unknown", date_connected: Date.now() };
    }
  }

  private subscribeToDeviceList(): void {
    const cipAuthDeviceObjectRemoval = localStorage.getItem("cipAuthDeviceObjectRemoval");

    authState(this.auth).subscribe((user: User) => {
      if (user && !this.didUserSignOut) {
        if (!cipAuthDeviceObjectRemoval) {
          const userDevicesRef = ref(this.db, `status/${user.uid}/devices`);

          const unsubscribe = onValue(userDevicesRef, (snapshot) => {
            const objects: SnapshotAction<DeviceDataReceived>[] = [];

            snapshot.forEach((childSnapshot) => {
              objects.push({
                type: "value",
                key: childSnapshot.key,
                payload: {
                  key: childSnapshot.key,
                  val: () => childSnapshot.val(),
                },
              });
            });

            this.handleDeviceUpdates(objects);
          });

          // Convert the unsubscribe function to a Subscription
          this.deviceListSubscription = new Subscription(() => {
            unsubscribe();
          });
        }
      }
    });
  }

  private handleDeviceUpdates(objects: SnapshotAction<DeviceDataReceived>[]): void {
    try {
      this.connectedDevices = [];
      const deviceDataArray: DeviceDataProcessed[] = objects
        .map((element) => {
          const key = element.payload.key;
          const deviceData = element.payload.val() as DeviceDataReceived;
          const uniqueId = appStorage.getCipAuthUniqueSessionId();
          if (deviceData.action === "signout" && key === uniqueId) {
            this.removeDeviceFromDatabaseAndSignOut();
          }
          return key && deviceData.action !== "signout" ? this.processDeviceData(key, deviceData) : null;
        })
        .filter((device): device is DeviceDataProcessed => device !== null);
      this.connectedDevices = deviceDataArray.sort((a, b) => b.date_connected.getTime() - a.date_connected.getTime());
      this.overlay_connectedDevices = !this.isUnderConnectionLimit;
    } catch (error) {
      console.error("Error in handleDeviceUpdates:", error);
    }
  }

  private processDeviceData(key: string, device: DeviceDataReceived): DeviceDataProcessed {
    return {
      id: key,
      device: device.device,
      date_connected: new Date(device.date_connected),
    };
  }

  async removeDeviceFromDatabaseAndSignOut(): Promise<void> {
    console.log("Removing device from database and signing out");
    try {
      const user = this.auth.currentUser;
      console.log("user = ", user);
      if (!user) return;

      const uniqueId = appStorage.getCipAuthUniqueSessionId();
      if (!uniqueId) return;

      const sessionTabId = sessionStorage.getItem("sessionTabId");
      if (!sessionTabId) return;

      if (this.acquireSignOutLock(sessionTabId)) {
        console.log("sessionTabId = ", sessionTabId);
        localStorage.setItem("cipAuthDeviceObjectRemoval", "true");
        this.didUserSignOut = true;
        this.unsubscribeFromDeviceList();
        await remove(ref(this.db, `status/${user.uid}/devices/${uniqueId}`));
        await this.signOutAndRedirect();
        this.cleanupStorage();
      } else {
        await this.waitForSignOutCompletion();
      }
    } catch (error) {
      console.error("Error in removeDeviceFromDatabaseAndSignOut:", error);
    }
  }

  private acquireSignOutLock(sessionTabId: string): boolean {
    try {
      if (!localStorage.getItem("cipAuthSignOutLock")) {
        localStorage.setItem("cipAuthSignOutLock", "locked");
        localStorage.setItem("cipAuthSignOutLockTab", sessionTabId);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error in acquireSignOutLock:", error);
      return false;
    }
  }

  private async waitForSignOutCompletion(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkSignOutComplete = () => {
        if (!localStorage.getItem("cipAuthSignOutLock")) {
          resolve();
        } else {
          setTimeout(checkSignOutComplete, 100); // Check every 100ms
        }
      };
      checkSignOutComplete();
    });
  }

  private cleanupStorage(): void {
    appStorage.removeCipAuthUniqueSessionId();
    localStorage.removeItem("cipAuthSignOutLock");
    localStorage.removeItem("cipAuthSignOutLockTab");
    localStorage.removeItem("cipAuthUniqueSessionLock");
    localStorage.removeItem("cipAuthUniqueSessionLockTab");
    localStorage.removeItem("cipAuthDeviceObjectRemoval");
    this.resetConnectedDevicesArray();
  }

  private async signOutAndRedirect(): Promise<void> {
    console.log("Signing out and redirecting");
    try {
      await signOut(this.auth);
      this.router.navigate(["/signin"]);
      this.resetOverlays();
      this.didUserSignOut = false;
    } catch (error) {
      console.error("Error in signOutAndRedirect:", error);
    }
  }

  private resetOverlays(): void {
    try {
      this.overlay_connectedDevices = false;
      this.authService.overlay_signOut = false;
    } catch (error) {
      console.error("Error in resetOverlays:", error);
    }
  }

  async setSelectedDeviceActionToSignOut(deviceId: string): Promise<void> {
    try {
      const user = this.auth.currentUser;
      if (!user) return;

      const path = `/status/${user.uid}/devices/${deviceId}`;
      const userStatusDatabaseRef = ref(this.db, path);

      const snapshot = await get(userStatusDatabaseRef);
      if (snapshot.exists()) {
        await update(userStatusDatabaseRef, { action: "signout" });
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error in setSelectedDeviceActionToSignOut:", error);
    }
  }

  public unsubscribeFromDeviceList(): void {
    try {
      this.deviceListSubscription?.unsubscribe();
    } catch (error) {
      console.error("Error in unsubscribeFromDeviceList:", error);
    }
  }

  public resetConnectedDevicesArray(): void {
    this.connectedDevices = [];
  }
}
