import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { RoleDefault } from "src/app/models/role/role.model";

@Component({
  selector: "manage-roles-list-default",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./manage-roles-list-default.component.html",
  styleUrl: "./manage-roles-list-default.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRolesListDefaultComponent {
  @Input({ required: true }) roles!: RoleDefault[];
  @Input({ required: true }) workspaceId!: string;
}
