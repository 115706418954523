<div class="fixed inset-0 z-30 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex min-h-screen items-center justify-center text-center">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
    <div class="inline-block h-[600px] w-[785px] transform overflow-hidden rounded-lg bg-gray-50 text-left align-bottom shadow-xl transition-all dark:bg-zinc-800 sm:my-8 sm:align-middle">
      <div class="flex h-full flex-1 flex-col justify-between">
        <!-- Header Title & New -->
        <div class="flex flex-row items-center bg-white px-6 py-6">
          <h3 class="text-base font-semibold leading-6 text-text_Title" id="modal-title">Select Folder</h3>
        </div>

        <div class="flex h-full flex-1 overflow-hidden" [formGroup]="inspectionForm">
          <div class="w-full space-y-4 overflow-y-scroll overscroll-contain">
            @if (folders) {
              <!-- Search -->
              <div class="mt-4 flex w-full flex-col px-12 sm:flex-row" [ngClass]="folders.length > 0 ? 'justify-between' : 'justify-end'">
                @if (folders.length > 0) {
                  <search-bar [placeholder]="'Search Folders'" (searchTermChange)="onSearch($event)" />
                }
                <button type="button" class="btn btn-primary" (click)="toggleNewFolderForm()">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Folder
                </button>
              </div>

              <div class="px-6 py-8">
                <!-- Selected Folder -->
                @if (currentlySelectedFolder) {
                  <div class="mb-10 block">
                    <div class="mb-3 flex items-center justify-between">
                      <div class="container-header" style="padding-bottom: 0">Current Folder</div>
                    </div>
                    <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
                      <div class="mb-4">
                        <div class="list-row">
                          <div class="list-a group cursor-pointer border-none" (click)="folderSelection(currentlySelectedFolder)">
                            <div [ngStyle]="{ color: currentlySelectedFolder.colour }">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="ml-2.5 h-6 w-6 sm:ml-0">
                                <path
                                  d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
                              </svg>
                            </div>
                            <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                              <div class="min-w-0 flex-auto">
                                <p class="list-title">{{ currentlySelectedFolder.title }}</p>
                                <p class="list-meta">{{ currentlySelectedFolder.inspections_count }} {{ currentlySelectedFolder.inspections_count === 1 ? "Inspection" : "Inspections" }}</p>
                              </div>
                            </div>
                            <div class="flex items-center text-brand_Primary">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
                                <path
                                  fill-rule="evenodd"
                                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                <!-- New Selection -->
                <ul id="foldersList">
                  @if (folders.length > 0 && !allFoldersHidden) {
                    <div class="mb-3 flex items-center justify-between">
                      <div class="container-header" style="padding-bottom: 0">Select New Folder</div>
                    </div>
                  }
                  @if (allOtherFolders.length > 0) {
                    <div class="block w-full rounded-md bg-white px-6 shadow-sm dark:bg-gray-800 sm:px-8">
                      @for (folder of filteredFolders; track $index; let last = $last) {
                        @if (!folder.hidden) {
                          <li [ngClass]="last ? 'border-none' : 'border-b'">
                            <div class="list-row">
                              @if (inspectionForm.value.folder_id !== folder.id) {
                                <div class="list-a group cursor-pointer" (click)="folderSelection(folder)">
                                  <div [ngStyle]="{ color: folder.colour }">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="ml-2.5 h-6 w-6 sm:ml-0">
                                      <path
                                        d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
                                    </svg>
                                  </div>
                                  <div class="flex w-full flex-col gap-x-4 sm:flex-row">
                                    <div class="min-w-0 flex-auto">
                                      <p class="list-title">{{ folder.title }}</p>
                                      <p class="list-meta">{{ folder.inspections_count }} {{ folder.inspections_count === 1 ? "Inspection" : "Inspections" }}</p>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </li>
                        }
                      }
                    </div>
                  } @else {
                    <empty-list [list]="'folder'" />
                  }
                </ul>
              </div>

              @if (allFoldersHidden) {
                <search-empty [searchTerm]="searchTerm" />
              }
            } @else {
              <div class="flex h-full items-center justify-center">
                <loading-spinner />
              </div>
            }
          </div>
        </div>

        <!-- Actions -->
        <div class="flex justify-end bg-gray-100 px-6 py-6">
          <button type="button" class="btn btn-cancel" (click)="cancelButton()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
