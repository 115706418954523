import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { RolePermissions } from "cip";
import { LimitationManagerService } from "src/app/core/services/limitation-manager.service";
import { DropzoneDirective } from "../directives/dropzone.directive";

@Component({
  selector: "photo-dropzone",
  standalone: true,
  imports: [DropzoneDirective],
  templateUrl: "./photo-dropzone.component.html",
  styleUrl: "./photo-dropzone.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoDropzoneComponent implements OnInit {
  @Input({ required: true }) uploadSourceComponent!: "workspace" | "client" | "site" | "signature" | "test";
  @Input() photoLimitationManagerResult!: boolean;
  @Output() detectPhotosOutput = new EventEmitter<FileList>();

  // Services
  private limitationManagerService = inject(LimitationManagerService);

  // Properties
  public isHovering = false;
  public memberDoc = this.limitationManagerService.memberDoc;
  public doesUserHavePermissions!: boolean;
  public permissionErrorTitle = "";
  public permissionErrorDescription = "";

  ngOnInit(): void {
    if (this.uploadSourceComponent === "test" && !this.photoLimitationManagerResult) {
      this.setNoPermissionsError();
    } else {
      this.doesUserHavePermissions = true;
    }
  }

  private setNoPermissionsError(): void {
    if (this.memberDoc.value) {
      this.doesUserHavePermissions = false;
      const memberRole = this.memberDoc.value.role_title;
      const rolePermissions = this.limitationManagerService.filterRolePermissions("photo_create_update");
      this.permissionErrorTitle = "Permission Denied";
      this.permissionErrorDescription = `You are currently assigned the role of "${memberRole}" and do not have the permissions to perform this action. To complete this action, you require permissions for "${rolePermissions}". If you require access to this function you need to ask a Workspace Admin.`;
    }
  }

  /**
   * Toggle Hover
   */
  async toggleHover(event: boolean): Promise<void> {
    if (this.uploadSourceComponent === "test") {
      const featureAction: keyof RolePermissions = "photo_create_update";
      const limitationResult = await this.limitationManagerService.canUserPerformAction(featureAction);
      this.isHovering = limitationResult ? event : this.isHovering;
      if (!limitationResult) {
        this.limitationManagerService.overlay_limitationManager = true;
      }
    } else {
      this.isHovering = event;
    }
  }

  /**
   * Detect Photos
   * @param files
   */
  detectPhotos(files: FileList): void {
    this.detectPhotosOutput.emit(files);
  }

  /**
   * Detect Photos From Input
   * @param event
   */
  detectPhotosFromInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.detectPhotosOutput.emit(input.files);
    }
  }
}
