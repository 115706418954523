<!-- Header -->

@if (combinedData$ | async; as combinedData) {
  <manage-member-edit-header [workspaceId]="workspaceId" [member]="combinedData.member" [user]="user" />

  <div class="grid grid-cols-1 gap-y-8">
    <!-- Form State Top -->
    <div class="flex flex-row items-center justify-end px-6 sm:px-8">
      <form-state [pristine]="memberForm.pristine"></form-state>
      <button type="button" (click)="saveMember()" class="btn" [disabled]="memberForm.pristine" [ngClass]="memberForm.pristine ? 'btn-disabled' : 'btn-primary'">Save</button>
    </div>

    <!-- Details -->
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Details</h3>
      </div>
      <div class="content-card">
        <div class="content-grid gap-y-6">
          <manage-member-edit-details [memberForm]="memberForm" />
        </div>
      </div>
    </div>

    <!-- Role -->
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Role</h3>
      </div>
      <div class="content-card">
        <div class="content-grid gap-y-6">
          <role-field [currentRole]="currentRole" [memberDoc]="memberDoc" [infoViewMemberEditor]="infoViewMemberEditor" [form]="memberForm" (toggleRoleSelectorOutput)="canUserEditMember()" />
        </div>
      </div>
    </div>

    <!-- Permissions -->
    <div class="content-card-wrapper">
      <div class="content-card-header">
        <h3>Permissions</h3>
      </div>
      <div class="content-card">
        <div class="content-grid gap-y-6">
          <permissions-grid [roleForm]="memberForm" [isDisabled]="true" [infoViewPermissionsEditor]="infoViewPermissionsEditor" />
        </div>
      </div>
    </div>

    <!-- Form State Bottom -->
    <div class="flex flex-row items-center justify-between px-6 sm:px-8">
      @if (memberForm.value.role_id !== "Owner") {
        <button type="button" class="btn btn-destructive mr-3" aria-label="Remove Member" (click)="canUserRemoveMember()">Remove Member</button>
      }
      <div class="flex flex-1 flex-row items-center justify-end">
        <form-state [pristine]="memberForm.pristine"></form-state>
        <button type="button" (click)="saveMember()" class="btn" [disabled]="memberForm.pristine" [ngClass]="memberForm.pristine ? 'btn-disabled' : 'btn-primary'">Save</button>
      </div>
    </div>
  </div>
} @else {
  <loading-spinner />
}

@if (overlay_roleSelector) {
  <role-selector-wrapper
    [defaultRoles]="defaultRoles"
    [customRoles]="customRoles"
    [currentRole]="currentRole"
    [infoViewPermissionsEditor]="infoViewPermissionsEditor"
    [overlay_roleForm]="overlay_roleForm"
    [roleForm]="roleForm"
    (toggleRoleSelectorOverlayOutput)="toggleRoleSelectorOverlay()"
    (selectRoleOutput)="selectRole($event)"
    (toggleRoleFormOverlayOutput)="toggleRoleFormOverlay()"
    (saveRoleOutput)="saveRole()" />
}

@if (overlay_removeMember()) {
  <delete-overlay [deleteOverlayButton]="'Remove'" [deleteOverlayTitle]="deleteOverlayTitle" [deleteOverlayDescription]="deleteOverlayDescription" (cancelButtonClicked)="toggleRemoveMemberOverlay(false)" (deleteButtonClicked)="removeMember()" />
}
