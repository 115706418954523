import { inject, Injectable } from '@angular/core';

import { User, WriteEvent, WriteEventType } from 'cip';
import { serverTimestamp, WithFieldValue } from 'firebase/firestore';
import { FirestoreUtilsService } from '../firestore/firestore-utils.service';

@Injectable({
  providedIn: 'root',
})
export class LastEventService {
  private firestoreUtilsService = inject(FirestoreUtilsService);

  public lastEvent(type: WriteEventType, user: User): WithFieldValue<WriteEvent> {
    const newId = this.firestoreUtilsService.createFirestoreId();
    const lastEvent: WithFieldValue<WriteEvent> = {
      platform: 'web',
      id: newId,
      type: type,
      date: serverTimestamp(),
      author_id: user.user_id,
      author_name: `${user.name_first} ${user.name_last}`,
    };

    return lastEvent;
  }
}
