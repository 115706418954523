<audit-logs-list
  [auditLogsArray]="auditLogsArray"
  [selectedAuditFilterType]="selectedAuditFilterType"
  [sortDropdown]="sortDropdown"
  [workspaceId]="workspaceId"
  [auditFilters]="auditFilters"
  [totalActivities]="totalActivities"
  [queriedActivities]="queriedActivities"
  (toggleSortDropdownOutput)="toggleSortDropdown()"
  (closeSortDropdownOutput)="closeSortDropdown()"
  (getAllAuditLogsOutput)="getAllAuditLogs()"
  (queryAuditLogsWithTypeOutput)="queryAuditLogsWithType($event)"
  (queryNextBatchOfActivitiesOutput)="queryNextBatchOfActivities()" />
