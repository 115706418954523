import { DatePipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";
import { EmptyListComponent } from "src/app/shared/empty-list/empty-list.component";
import { SearchBarComponent } from "src/app/shared/search-bar/search-bar.component";
import { SearchEmptyComponent } from "src/app/shared/search-empty/search-empty.component";

@Component({
  selector: "manage-members-invites-list",
  standalone: true,
  imports: [NgClass, SearchBarComponent, SearchEmptyComponent, EmptyListComponent, RouterModule, DatePipe],
  templateUrl: "./manage-members-invites-list.component.html",
  styleUrl: "./manage-members-invites-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageMembersInvitesListComponent {
  @Input({ required: true })
  set invites(value: InviteEnhanced[]) {
    this._invites = value;
    this.updateFilteredInvitesForSearchResults();
  }

  get invites(): InviteEnhanced[] {
    return this._invites;
  }

  @Input({ required: true }) workspaceId!: string;
  private _invites: InviteEnhanced[] = [];
  public filteredInvites: Array<InviteEnhanced> = [];
  public allInvitesHidden: boolean = false;
  public searchTerm: string = "";

  /**
   * On Search
   * @param searchTerm
   */
  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.updateFilteredInvitesForSearchResults();
  }

  /**
   * Apply Search
   */
  updateFilteredInvitesForSearchResults(): void {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredInvites = this.invites.map((invite) => ({
      ...invite,
      hidden: !invite.name.toLowerCase().includes(lowerSearchTerm),
    }));
    this.allInvitesHidden = this.filteredInvites.every((invite) => invite.hidden);
  }
}
