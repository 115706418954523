import { JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InfoViewStringsService } from 'src/app/core/services/strings/info-view-strings.service';
import { PhotoType } from 'src/app/models/photo/photo-type';
import { SinglePhotoUpload } from 'src/app/models/photo/photo-upload.model';
import { InfoViewFilter, InfoViewType } from 'src/app/models/strings/strings.model';
import { DropzoneDirective } from 'src/app/shared/directives/dropzone.directive';
import { InfoViewComponent } from 'src/app/shared/info-view/info-view.component';
import { PhotoDropzoneComponent } from 'src/app/shared/photo-dropzone/photo-dropzone.component';
import { PhotoPreviewComponent } from 'src/app/shared/photo-preview/photo-preview.component';
import { PhotoUploadSingleComponent } from 'src/app/shared/photo-upload-single/photo-upload-single.component';
import { InspectionSignoffEditDetailsComponent } from '../inspection-signoff-edit-details/inspection-signoff-edit-details.component';

@Component({
  selector: 'inspection-signoff-new-overlay',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, InspectionSignoffEditDetailsComponent, InfoViewComponent, JsonPipe, DropzoneDirective, PhotoUploadSingleComponent, PhotoDropzoneComponent, PhotoPreviewComponent],
  templateUrl: './inspection-signoff-new-overlay.component.html',
  styleUrl: './inspection-signoff-new-overlay.component.scss',
})
export class InspectionSignoffNewOverlayComponent {
  @Input({ required: true }) signatureForm!: FormGroup;
  @Input({ required: true }) workspaceId!: string;
  @Input({ required: true }) inspectionId!: string;
  @Input({ required: true }) uploadFileArray: SinglePhotoUpload[] = [];
  @Input({ required: true }) overlay_uploadPhotos: boolean = false;
  @Input({ required: true }) photoPreview: boolean = false;
  @Input({ required: true }) newSignatureId!: string;

  @Output() detectPhotosOutput = new EventEmitter<FileList>();
  @Output() removePhotoFromArrayOutput = new EventEmitter<string>();
  @Output() returnedPhotoOutput = new EventEmitter<PhotoType>();

  @Output() toggleNewSignatureOverlayOutput = new EventEmitter<void>();
  @Output() saveSignatureOutput = new EventEmitter<void>();

  // Services
  private infoViewStringsService = inject(InfoViewStringsService);

  // Properties
  public infoViewSignatureEditor: InfoViewFilter = this.infoViewStringsService.getInfoView(InfoViewType.SignatureEditor);

  /**
   * Save Signature
   */
  public saveSignature(): void {
    this.saveSignatureOutput.emit();
  }

  /**
   * Detects photos
   * @param files
   */
  public detectPhotos(files: FileList): void {
    this.detectPhotosOutput.emit(files);
  }

  /**
   * Remove image from array
   * @param id
   */
  public removePhotoFromArray(id: string): void {
    this.removePhotoFromArrayOutput.emit(id);
  }

  /**
   * Patch form with photos and save doc
   * @param photos
   */
  public async patchFormWithPhotosAndSaveDoc(photos: PhotoType) {
    this.returnedPhotoOutput.emit(photos);
  }

  /**
   * Toggles the new signature overlay
   */
  public toggleNewSignatureOverlay(): void {
    this.toggleNewSignatureOverlayOutput.emit();
  }
}
