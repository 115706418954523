<div class="flex h-auto lg:h-screen">
  <div class="subMenu-wrapper">
    <div class="space-y-8 py-10">
      <div>
        <div class="px-4 pb-2 text-base font-semibold leading-6 text-gray-900">Settings</div>
        <ul class="subMenu-ul">
          @if (limitationResult) {
            <li>
              <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'workspace']" [routerLinkActive]="['active']" class="subMenu-a group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                </svg>

                <div>
                  <span class="subMenu-title"> Edit Workspace </span>
                  <p class="subMenu-description">Details for this Workspace</p>
                </div>
              </a>
            </li>
          }
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'members']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Members </span>
                <p class="subMenu-description">Anyone that is able to View or Edit information inside the Workspace</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'roles']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Roles </span>
                <p class="subMenu-description">Define Permissions for Workspace Members</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'themes']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Themes </span>
                <p class="subMenu-description">Configure how Reports look for all Members</p>
              </div>
            </a>
          </li>
          @if (memberRole === "Owner") {
            <li>
              <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'delete-workspace']" [routerLinkActive]="['active']" class="subMenu-a group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                </svg>

                <div>
                  <span class="subMenu-title"> Delete Workspace </span>
                  <p class="subMenu-description">Permanently deletes all data from the Workspace</p>
                </div>
              </a>
            </li>
          }
          @if (memberRole !== "Owner") {
            <li>
              <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'leave-workspace']" [routerLinkActive]="['active']" class="subMenu-a group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                </svg>

                <div>
                  <span class="subMenu-title"> Leave Workspace </span>
                  <p class="subMenu-description">/** TODO **/</p>
                </div>
              </a>
            </li>
          }
        </ul>
      </div>
      <div>
        <div class="px-4 pb-2 text-base font-semibold leading-6 text-gray-900">Inspection Data</div>
        <ul class="subMenu-ul">
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'assignees']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Assignees </span>
                <p class="subMenu-description">Companies or People responsible for Actions</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'clients']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Clients </span>
                <p class="subMenu-description">Recepients of the Inspection Reports</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'sites']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
              </svg>

              <div>
                <span class="subMenu-title"> Sites </span>
                <p class="subMenu-description">Locations for Inspections</p>
              </div>
            </a>
          </li>
          <li>
            <a [routerLink]="['/', 'workspace', workspaceId, 'manage', 'templates']" [routerLinkActive]="['active']" class="subMenu-a group">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex h-5 w-5 flex-shrink-0 text-brand_Primary">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
              </svg>

              <div>
                <span class="subMenu-title"> Templates </span>
                <p class="subMenu-description">Predefined collections of Categories and Items</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
