export enum AlertType {
  AcceptInvite = "acceptInvite",
  ChangeWorkspace = "changeWorkspace",
  CompleteIncompleteActions = "completeIncompleteActions",
  ConfirmTemplate = "confirmTemplate",
  DeleteAccount = "deleteAccount",
  DeleteAction = "deleteAction",
  DeleteActions = "deleteActions",
  DeleteCategories = "deleteCategories",
  DeleteCategory = "deleteCategory",
  DeleteClient = "deleteClient",
  DeleteClients = "deleteClients",
  DeleteClientImage = "deleteClientImage",
  DeleteFolder = "deleteFolder",
  DeleteInspection = "deleteInspection",
  DeleteItem = "deleteItem",
  DeleteItems = "deleteItems",
  DeleteLocation = "deleteLocation",
  DeleteRole = "deleteRole",
  DeleteSignature = "deleteSignature",
  DeleteSignatures = "deleteSignatures",
  DeleteSignaturePhoto = "DeleteSignaturePhoto",
  DeleteSite = "deleteSite",
  DeleteSites = "deleteSites",
  DeleteSitePhoto = "deleteSitePhoto",
  DeleteTestImage = "deleteTestImage",
  DeleteTemplate = "deleteTemplate",
  DeleteTemplateCategory = "deleteTemplateCategory",
  DeleteTemplateCategories = "deleteTemplateCategories",
  DeleteTemplateItem = "deleteTemplateItem",
  DeleteTemplateItems = "deleteTemplateItems",
  DeleteTheme = "deleteTheme",
  DeleteThemes = "deleteThemes",
  DeleteWorkspace = "deleteWorkspace",
  DeleteWorkspacePhoto = "deleteWorkspacePhoto",
  EnableDisableCategories = "enableDisableCategories",
  EnableDisableItems = "enableDisableItems",
  LeaveWorkspace = "leaveWorkspace",
  RemoveAssignee = "removeAssignee",
  RemoveAssignees = "removeAssignees",
  RemoveLocation = "removeLocation",
  RemoveMember = "removeMember",
  RevokeInvite = "revokeInvite",
  SignOut = "signOut",
  TransferOwnership = "transferOwnership",
}
export enum OperationType {
  DuplicateInspection = "duplicateInspection",
  DuplicateCategory = "duplicateCategory",
  DuplicateItem = "duplicateItem",
  DuplicateTemplate = "duplicateTemplate",
  Reinspection = "reinspection",
  CreateInspectionFromTemplate = "createInspectionFromTemplate",
  CreateCategoriesFromTemplate = "createCategoriesFromTemplate",
  SaveInspectionAsTemplate = "saveInspectionAsTemplate",
  DuplicateTemplateCategory = "duplicateTemplateCategory",
  DuplicateTemplateItem = "duplicateTemplateItem",
}
export enum InfoViewType {
  AddYourName = "addYourName",
  SubscriptionViewer = "subscriptionViewer",
  SignIn = "signIn",
  EmailLinkSuccess = "emailLinkSuccess",
  EmailLink = "emailLink",
  ItemEditorTest = "itemEditorTest",
  ItemEditorQuestion = "itemEditorQuestion",
  SignatureEditor = "signatureEditor",
  MemberEditor = "memberEditor",
  TemplateItemEditor = "templateItemEditor",
  ThemeEditor = "themeEditor",
  WorkspaceListInvited = "workspaceListInvited",
  LeaveWorkspace1 = "leaveWorkspace1",
  LeaveWorkspace2 = "leaveWorkspace2",
  DeleteWorkspace1 = "deleteWorkspace1",
  DeleteWorkspace2 = "deleteWorkspace2",
  OwnershipEditor1 = "ownershipEditor1",
  OwnershipEditor2 = "ownershipEditor2",
  ProfileEditor = "profileEditor",
  DeleteAccount1 = "deleteAccount1",
  DeleteAccount2 = "deleteAccount2",
  EmailEditor1 = "emailEditor1",
  EmailEditor2 = "emailEditor2",
  SubscriptionViewerCancelLinkWarning = "subscriptionViewerCancelLinkWarning",
  CacheEditor1 = "cacheEditor1",
  CacheEditor2 = "cacheEditor2",
  SupplementaryEditor = "supplementaryEditor",
  InternalNotes = "internalNotes",
  DuplicateItem = "duplicateItem",
  ImportTemplate = "importTemplate",
  CreateInspectionFromTemplate = "createInspectionFromTemplate",
  InviteEditor = "inviteEditor",
  InviteEditorMember = "inviteEditorMember",
  CustomRoleEditor = "customRoleEditor",
  PermissionsEditor = "permissionsEditor",
  ConnectedDevices = "connectedDevices",
  AssigneeEditorReport = "assigneeEditorReport",
  Subscription = "subscription",
}

export enum ToolTipType {
  InspectionDetailsSite = "inspectionDetailsSite",
  InspectionDetailsClient = "inspectionDetailsClient",
  InspectionDetailsFolder = "inspectionDetailsFolder",
  WorkspaceOwnership = "workspaceOwnership",
  RoleDefault = "roleDefault",
  RoleCustom = "roleCustom",
  CategoryNotes = "categoryNotes",
}

export interface AlertFilter {
  title: string;
  description: string;
}

export interface OperationFilter {
  title: string;
  description: string;
  failure: string;
  running: string;
  success: string;
}

export interface InfoViewFilter {
  type: InfoViewTypeOptions;
  message: string;
}

export interface ToolTipFilter {
  title: string;
}

export type InfoViewTypeOptions = "error" | "info" | "success" | "warning";
