<header>
  <div class="px-6 pb-2">
    <nav aria-label="Breadcrumb">
      <ol role="list" class="flex items-center">
        <li>
          <div class="flex items-center">
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'templates']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Templates</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'templates', templateId, 'overview']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Template Overview</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'templates', templateId, 'categories']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Template Categories</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/workspace', workspaceId, 'manage', 'templates', templateId, 'categories', categoryId, 'items']" class="text-sm font-medium text-text_Subtitle hover:text-gray-700">Template Items</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="page-header-wrapper">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="page-header">Edit {{ item.mode === "test" ? "Test" : "Question" }}</h2>
      </div>

      @if (itemId !== "new") {
        <div class="mt-4 flex flex-col sm:mt-0 sm:flex-row md:ml-4 md:mt-0">
          <button type="button" class="btn btn-secondary" (click)="duplicateItem()">Duplicate Item</button>
        </div>
      }
    </div>
  </div>
</header>
