import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TemplateItemEnhanced } from "src/app/models/item/template-item.model";

@Component({
  selector: "manage-template-items-list-items-mode",
  standalone: true,
  imports: [],
  templateUrl: "./manage-template-items-list-items-mode.component.html",
  styleUrl: "./manage-template-items-list-items-mode.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTemplateItemsListItemsModeComponent {
  @Input({ required: true }) item!: TemplateItemEnhanced;
}
