import { DatePipe, NgStyle, NgTemplateOutlet } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { CategoryEnhanced } from "src/app/models/category/category.model";
import { ReportsService } from "../../services/reports.service";
import { ReportContentActionsComponent } from "../report-content-actions/report-content-actions.component";
import { ReportContentPhotosComponent } from "../report-content-photos/report-content-photos.component";

@Component({
  selector: "report-content-items",
  standalone: true,
  imports: [NgStyle, NgTemplateOutlet, DatePipe, ReportContentActionsComponent, ReportContentPhotosComponent],
  templateUrl: "./report-content-items.component.html",
  styleUrl: "./report-content-items.component.scss",
})
export class ReportContentItemsComponent {
  @Input({ required: true }) category!: CategoryEnhanced;
  public reportsService = inject(ReportsService);
}
