import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { InviteEnhanced } from "src/app/models/invite/invite-accept-decline.model";

@Component({
  selector: "manage-role-assigned-invites",
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: "./manage-role-assigned-invites.component.html",
  styleUrl: "./manage-role-assigned-invites.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRoleAssignedInvitesComponent {
  @Input({ required: true }) assignedInvites!: InviteEnhanced[];

  // Properties
  public assignedInvitesAccordion = false;

  /**
   * Display Assigned Invites
   */
  displayAssignedInvites(): void {
    this.assignedInvitesAccordion = !this.assignedInvitesAccordion;
  }
}
