import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ThemeStyleType } from "cip";
import { ThemeStyleEnhanced } from "src/app/models/theme/theme-style.model";
import { ManageThemeEditSettingsColoursComponent } from "../manage-theme-edit-settings-colours/manage-theme-edit-settings-colours.component";
import { ManageThemeEditSettingsCustomisationsComponent } from "../manage-theme-edit-settings-customisations/manage-theme-edit-settings-customisations.component";
import { ManageThemeEditSettingsFontsComponent } from "../manage-theme-edit-settings-fonts/manage-theme-edit-settings-fonts.component";
import { ManageThemeEditSettingsToggleChartsCoverComponent } from "../toggles/manage-theme-edit-settings-toggle-charts-cover/manage-theme-edit-settings-toggle-charts-cover.component";
import { ManageThemeEditSettingsToggleDateComponent } from "../toggles/manage-theme-edit-settings-toggle-date/manage-theme-edit-settings-toggle-date.component";
import { ManageThemeEditSettingsToggleFrontCoverComponent } from "../toggles/manage-theme-edit-settings-toggle-front-cover/manage-theme-edit-settings-toggle-front-cover.component";
import { ManageThemeEditSettingsToggleOtherOptionsComponent } from "../toggles/manage-theme-edit-settings-toggle-other-options/manage-theme-edit-settings-toggle-other-options.component";
import { ManageThemeEditSettingsTogglePhotoComponent } from "../toggles/manage-theme-edit-settings-toggle-photo/manage-theme-edit-settings-toggle-photo.component";
import { ManageThemeEditSettingsToggleSiteCoverComponent } from "../toggles/manage-theme-edit-settings-toggle-site-cover/manage-theme-edit-settings-toggle-site-cover.component";

@Component({
  selector: "manage-theme-edit-settings-wrapper",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ManageThemeEditSettingsToggleChartsCoverComponent,
    ManageThemeEditSettingsToggleFrontCoverComponent,
    ManageThemeEditSettingsToggleOtherOptionsComponent,
    ManageThemeEditSettingsTogglePhotoComponent,
    ManageThemeEditSettingsToggleSiteCoverComponent,
    ManageThemeEditSettingsToggleDateComponent,
    ManageThemeEditSettingsFontsComponent,
    ManageThemeEditSettingsColoursComponent,
    ManageThemeEditSettingsCustomisationsComponent,
  ],
  templateUrl: "./manage-theme-edit-settings-wrapper.component.html",
  styleUrl: "./manage-theme-edit-settings-wrapper.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsWrapperComponent {
  @Input({ required: true }) themeSettingsForm!: FormGroup;
  @Input({ required: true }) themeId!: string;
  @Input({ required: true }) themeStylesByType: { [key in ThemeStyleType]?: ThemeStyleEnhanced[] } = {};
  @Output() toggleDeleteOverlayOutput = new EventEmitter<void>();

  /**
   * Toggles the delete overlay
   */
  public toggleDeleteOverlay(): void {
    this.toggleDeleteOverlayOutput.emit();
  }
}
