<form [formGroup]="themeSettingsForm" class="px-4">
  <div class="border-b border-gray-200 py-6">
    <h3 class="-my-3 flow-root">
      <button type="button" class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false" (click)="toggleContentOptions()">
        <span class="font-medium text-gray-900">Charts Cover</span>
        <span class="ml-6 flex items-center">
          @if (!toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
          }
          @if (toggleContent) {
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
            </svg>
          }
        </span>
      </button>
    </h3>
    @if (toggleContent) {
      <div class="pt-6">
        <div class="space-y-4">
          <div class="flex items-center">
            <input id="charts_included" name="details_site" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="charts_included" />
            <label for="charts_included" class="ml-3 cursor-pointer text-sm text-text_Title">Include</label>
          </div>
          <div class="flex items-center">
            <input id="charts_score" name="charts_score" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="charts_score" />
            <label for="charts_score" class="ml-3 cursor-pointer text-sm text-text_Title">Score</label>
          </div>
          <div class="flex items-center">
            <input id="charts_assignee" name="charts_assignee" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="charts_assignee" />
            <label for="charts_assignee" class="ml-3 cursor-pointer text-sm text-text_Title">Assignees</label>
          </div>
          <div class="flex items-center">
            <input id="charts_action" name="charts_action" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-brand_Primary focus:ring-brand_Primary" formControlName="charts_action" />
            <label for="charts_action" class="ml-3 cursor-pointer text-sm text-text_Title">Actions</label>
          </div>
        </div>
      </div>
    }
  </div>
</form>
