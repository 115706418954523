import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ThemeStyleType } from "cip";
import { ThemeStyleEnhanced } from "src/app/models/theme/theme-style.model";
import { ThemeStylesAndSettingsService } from "../../../../services/theme-styles-and-settings.service";

@Component({
  selector: "manage-theme-edit-settings-theme-style-action",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./manage-theme-edit-settings-theme-style-action.component.html",
  styleUrl: "./manage-theme-edit-settings-theme-style-action.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageThemeEditSettingsThemeStyleActionComponent {
  @Input() themeSettingsForm!: FormGroup;
  @Input() themeStylesByType: { [key in ThemeStyleType]?: ThemeStyleEnhanced[] } = {};
  private themeStylesAndSettingsService = inject(ThemeStylesAndSettingsService);

  updateCustomThemeStyles() {
    this.themeStylesAndSettingsService.clearStyles("styles_actionCSS");
    const { styles_action } = this.themeSettingsForm.value;
    const allThemeStyles = Object.values(this.themeStylesByType).flatMap((typeArray) => typeArray);
    for (const data of allThemeStyles) {
      if (styles_action === data.id) {
        this.themeStylesAndSettingsService.applyActionStyles(data.css);
      }
    }
  }
}
