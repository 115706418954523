<form [formGroup]="testForm">
  <div class="content-card-wrapper">
    <div class="content-card-header flex justify-between">
      <h3 class="capitalize">{{ currentCategoryOrder }}.{{ testForm.value.order + 1 }} {{ testForm.value.mode }}</h3>
      <div class="flex flex-row items-center">
        <button type="button" class="btn-secondary-small-default cursor-pointer" (click)="enableOrDisableTest()">
          {{ testForm.value.enabled ? 'Disable Test' : 'Enable Test' }}
        </button>

        @if (!testForm.value.enabled) {
          <div class="ml-3">
            <tooltip [toolTipText]="'This ' + testForm.value.mode + ' is disabled. It will not be visible on the Report.'" />
          </div>
        }
      </div>
    </div>
    <div class="content-card px-6 py-5">
      <p class="text-lg font-semibold text-text_Title">{{ testForm.value.title }}</p>
    </div>
  </div>
</form>
